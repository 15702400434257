import { Formik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as Yup from 'yup';

import { Form as HelixForm } from '@headway/helix/Form';
import { theme } from '@headway/helix/theme';
import { SubmitListener } from '@headway/ui/form/SubmitListener';

import {
  getInitialTemplateValues,
  passesConditionCheck,
  Renderer,
} from '../renderer/renderer';
import { FormFile, FormValues } from '../schema/schema.types';
import {
  Component,
  FormResponse,
  Form as FormType,
  Section,
} from '../schema/schema.types';
import { isElementASection } from '../schema/utils';
import ErrorBoundary from './ErrorBoundary';
import {
  addRuntimeErrorToSet,
  getRuntimeErrorMessage,
  removeRuntimeErrorFromSet,
  runtimeErrorSetHasError,
  RuntimeErrorType,
} from './errorMessage';
import {
  createYupSchema,
  getValidationType,
  TemplateErrorConfig,
} from './errors';

export interface FormRef {
  submitForm: () => void;
  getValues: () => any;
  _internal: any;
}

interface FormProps {
  id?: string;
  form: FormFile;
  state?: Object;
  response?: FormResponse;
  disabled?: boolean;
  onSubmit?: (response: FormResponse) => void;
  autoSubmit?: boolean;
  readOnly?: boolean;

  onRuntimeErrors?: (errors: string[]) => void;
}

/**
 *
 * Formik wrapper around the form builder's renderer. Exposes submission and component level
 * error handling.
 *
 */
export const Form = {
  /**
   *
   * This exposes submission logic to parent components, allowing the submit button to be separate
   * from the form rendering itself.
   *
   * @param ref - Ref to the Form.View component
   */
  Submit: async (ref?: React.MutableRefObject<FormRef | null>) => {
    if (!ref || !ref.current) {
      throw new Error('No mounted form to submit');
    }

    ref.current.submitForm();
  },

  /**
   *
   * Formik wrapper around the renderer which handles setting initialState, error handling,
   * and submission logic.
   *
   * Exposes defined functions in the forms ref.
   *
   */
  View: forwardRef<FormRef, FormProps>(
    (
      {
        id,
        form,
        state,
        response,
        disabled,
        onSubmit,
        autoSubmit,
        readOnly,
        onRuntimeErrors,
      }: FormProps,
      ref
    ) => {
      const rendererRef = React.useRef<any>();
      const [runtimeErrors, setRuntimeErrors] = React.useState<
        Set<RuntimeErrorType>
      >(new Set());

      useEffect(() => {
        if (runtimeErrors.size > 0) {
          const errors: string[] = Array.from(runtimeErrors).map((error) => {
            return getRuntimeErrorMessage(error);
          });

          onRuntimeErrors && onRuntimeErrors(errors);
        }
      }, [runtimeErrors]);

      useImperativeHandle(
        ref,
        (): FormRef => {
          return {
            submitForm: () => rendererRef.current.submitForm(),
            getValues: () => rendererRef.current.values,
            _internal: {
              form,
              rendererFormik: () => rendererRef.current,
            },
          };
        },
        []
      );

      return (
        <ErrorBoundary
          onError={(error) => {
            // Log this into the form-builder error channels
            addRuntimeErrorToSet(
              runtimeErrors,
              RuntimeErrorType.UNKNOWN_RUNTIME_FAILURE
            );

            // Lets the sentry wrapper catch this and log the full stack trace
            console.error(error);
          }}
        >
          <Formik
            innerRef={rendererRef}
            enableReinitialize
            validationSchema={() =>
              Yup.lazy((values: FormValues) => {
                let schema = {};
                try {
                  form['form'].forEach(
                    (sectionOrComponent: Section | Component) => {
                      if (isElementASection(sectionOrComponent)) {
                        sectionOrComponent?.components?.forEach((component) => {
                          if (
                            !passesConditionCheck(values, component.conditions)
                          ) {
                            return;
                          }
                          if (component.validations) {
                            const config: TemplateErrorConfig = {
                              id: component.id,
                              type: component.type,
                              validations: component.validations,
                              validationType: getValidationType(component),
                            };
                            if (
                              passesConditionCheck(values, component.conditions)
                            ) {
                              schema = createYupSchema(schema, config);
                            }
                          }
                        });
                      } else {
                        if (sectionOrComponent.validations) {
                          const config: TemplateErrorConfig = {
                            id: sectionOrComponent.id,
                            type: sectionOrComponent.type,
                            validations: sectionOrComponent.validations,
                            validationType:
                              getValidationType(sectionOrComponent),
                          };

                          if (
                            passesConditionCheck(
                              values,
                              sectionOrComponent.conditions
                            )
                          ) {
                            schema = createYupSchema(schema, config);
                          }
                        }
                      }
                    }
                  );

                  if (
                    runtimeErrorSetHasError(
                      runtimeErrors,
                      RuntimeErrorType.FAILED_CREATING_YUP_VALIDATION_SCHEMA
                    )
                  ) {
                    setRuntimeErrors(
                      removeRuntimeErrorFromSet(
                        runtimeErrors,
                        RuntimeErrorType.FAILED_CREATING_YUP_VALIDATION_SCHEMA
                      )
                    );
                  }
                } catch (e) {
                  setRuntimeErrors(
                    addRuntimeErrorToSet(
                      runtimeErrors,
                      RuntimeErrorType.FAILED_CREATING_YUP_VALIDATION_SCHEMA
                    )
                  );
                }
                return Yup.object().shape(schema);
              })
            }
            initialValues={
              response ? response : { ...getInitialTemplateValues(form.form) }
            }
            onSubmit={(values) => (onSubmit ? onSubmit(values) : undefined)}
          >
            <HelixForm
              id={id}
              css={{
                ...theme.reset,
              }}
            >
              {autoSubmit && <SubmitListener />}
              <Renderer
                form={form}
                injectedData={state ?? {}}
                disabled={disabled}
                readOnly={readOnly}
                runtimeErrors={runtimeErrors}
                setRuntimeErrors={setRuntimeErrors}
              />
            </HelixForm>
          </Formik>
        </ErrorBoundary>
      );
    }
  ),
};
