import { useContext } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Modal } from '@headway/helix/Modal';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

import { AttestationFillForClient } from './AttestationFillForClient';
import {
  FillOutAssessmentForClientModalContextProvider,
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';
import { SelectAssessments } from './SelectAssessments';

interface FillOutAssessmentForClientModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  clientId: number;
}

interface FillOutAssessmentModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  client: UserRead;
}

const FillOutAssessmentModal: React.FC<FillOutAssessmentModalProps> = ({
  isOpen,
  onDismiss,
  client,
}) => {
  const { currentStep } = useContext(FillOutAssessmentModalContext);
  const clientName = formatPatientName(client);

  return (
    <Modal
      variant="fullscreen"
      layout="contained"
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={`Fill out assessments with ${clientName}`}
    >
      {currentStep === ManagementAssessmentStep.SELECT_ASSESSMENTS && (
        <SelectAssessments onDismiss={onDismiss} />
      )}
      {currentStep === ManagementAssessmentStep.ATTESTATION && (
        <AttestationFillForClient clientName={clientName} />
      )}
    </Modal>
  );
};

export const FillOutAssessmentForClientModal: React.FC<
  FillOutAssessmentForClientModalProps
> = ({ isOpen, onDismiss, clientId }) => {
  const { data: client } = useUser({ userId: clientId });

  if (!client) {
    return null;
  }

  return (
    <FillOutAssessmentForClientModalContextProvider>
      <FillOutAssessmentModal
        isOpen={isOpen}
        onDismiss={onDismiss}
        client={client}
      />
    </FillOutAssessmentForClientModalContextProvider>
  );
};
