import React, { ReactNode } from 'react';

import { HeadlineText, HeadlineTextProps } from './HeadlineText';
import { theme } from './theme';

export interface BrandTextProps {
  children: ReactNode;
  variant?: keyof (typeof theme)['typography']['brand'];
}

const variantToHeadline: Record<
  NonNullable<BrandTextProps['variant']>,
  NonNullable<HeadlineTextProps['variant']>
> = {
  header: 'H3',
  section: 'H4',
};

/**
 * @deprecated
 * Use HeadlineText variants instead
 */
export const BrandText = ({ children, variant = 'header' }: BrandTextProps) => {
  return (
    <HeadlineText variant={variantToHeadline[variant]}>{children}</HeadlineText>
  );
};
