import { useQuery } from '@tanstack/react-query';

import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { logException } from '@headway/shared/utils/sentry';

export const useIntakeCompletionStatus = (providerId: number) => {
  const {
    data: isIntakeIncomplete,
    isLoading: isIntakeIncompleteLoading,
    isError: isIntakeIncompleteError,
  } = useQuery(
    ['latest-questionnaire', providerId],
    async () => {
      const latestQuestionnaireObject =
        await getLatestQuestionnaire(providerId);
      return (
        latestQuestionnaireObject?.selectedQuestionnaire?.completedOn === null
      );
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        logException(error);
      },
    }
  );

  return {
    isIntakeIncomplete,
    isIntakeIncompleteLoading,
    isIntakeIncompleteError,
  };
};
