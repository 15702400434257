import { Shapes } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconShapes: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={Shapes}
    ref={ref}
  />
));

IconShapes.variants = ['outlined', 'filled'];
IconShapes.defaultVariant = 'outlined';
