import { Controller, useFormContext } from 'react-hook-form';

import { RichTextArea } from '@headway/helix/RichTextArea';

import { SchemaComponent } from '.';
import { getInstructionalText } from '../../utils';

export const FormRichTextArea = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, subTitle, placeholder } = element;
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      disabled={disabled}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <RichTextArea
          label={title}
          name={id}
          key={id}
          optionalityText={isOptional ? 'Optional' : ''}
          instructionalText={getInstructionalText(subTitle, { requiresEdit })}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    />
  );
};
