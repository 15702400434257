import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PriceTableResponse } from '@headway/api/models/PriceTableResponse';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';
import { isStatusInNetwork } from '@headway/shared/utils/insuranceUtils';

export interface UseProviderPriceTableQueryKeyArgs {
  provider: ProviderRead | undefined;
  patient: UserRead | undefined;
  insuranceStatus?: PatientInsuranceOrEAPStatus;
  latestEligibilityLookup?: EligibilityLookupRead;
  isAnthemEAPUser?: boolean;
}
export const getUseProviderPriceTableQueryKey = ({
  provider,
  patient,
}: Pick<UseProviderPriceTableQueryKeyArgs, 'provider' | 'patient'>): [
  string,
  number | undefined,
  number | undefined,
] => ['provider-price-table', provider?.id, patient?.id];

const {
  useSingleQuery: useProviderPriceTable,
  useListQuery: useProviderPriceTableList,
} = createBasicApiHooks<
  UseProviderPriceTableQueryKeyArgs,
  [string, number | undefined, number | undefined],
  PriceTableResponse
>(
  getUseProviderPriceTableQueryKey,
  ({ provider, patient }) =>
    ProviderApi.getProviderPriceTable(provider!.id, patient!.id),
  ({
    provider,
    patient,
    insuranceStatus,
    latestEligibilityLookup,
    isAnthemEAPUser,
  }) => {
    const isInNetwork = isStatusInNetwork(insuranceStatus);
    const isInNoDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_OUTAGE;
    const isInOldDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_OUTAGE;

    return (
      !!(provider?.id, patient?.id) &&
      ((isInNetwork && latestEligibilityLookup !== undefined) ||
        (isInNetwork && isAnthemEAPUser) ||
        isInNoDataOutage ||
        isInOldDataOutage)
    );
  },
  () => 'Failed to fetch provider price table'
);

export { useProviderPriceTable, useProviderPriceTableList };
