import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQueries, UseQueryOptions } from '@headway/shared/react-query';

export type UseSessionDetailsEditabilityQueryKey = [string, string | undefined];
export type UseSessionDetailsEditabilityOptions<T> = UseQueryOptions<
  SessionDetailsEditabilityStatus,
  unknown,
  T,
  UseSessionDetailsEditabilityQueryKey
>;
export const getUseSessionDetailsEditabilityQueryKey = (
  providerEventVirtualId: string | undefined
): [string, string | undefined] => [
  'provider-event-session-details-editability',
  providerEventVirtualId,
];

export const useSessionDetailsEditability = <
  T = SessionDetailsEditabilityStatus,
>(
  providerEventVirtualId: string | undefined,
  options?: UseSessionDetailsEditabilityOptions<T>
) => useSessionDetailsEditabilityList([{ providerEventVirtualId, options }])[0];

export const useSessionDetailsEditabilityList = <
  T = SessionDetailsEditabilityStatus,
>(
  queryArgs: Array<{
    providerEventVirtualId: string | undefined;
    options?: UseSessionDetailsEditabilityOptions<T>;
  }>
) =>
  useQueries({
    queries: queryArgs.map(({ providerEventVirtualId, options }) => ({
      ...(options || {}),
      queryKey: getUseSessionDetailsEditabilityQueryKey(providerEventVirtualId),
      queryFn: () =>
        ProviderEventApi.getSessionDetailsEditability(providerEventVirtualId!),
      enabled: options?.enabled !== false && !!providerEventVirtualId,
    })),
  });
