import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { LEARN_ABOUT_GETTING_PAID_LINK } from '@headway/shared/constants/zendesk';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import {
  formatDate,
  isDateInPast,
  parseDate,
} from '@headway/shared/utils/insuranceStatus';

export const FirstPaymentEligibleGuidanceCard = ({
  patient,
  providerId,
}: {
  patient: UserRead | undefined;
  providerId: number;
}) => {
  const {
    isLoading: isLoadingMatchingProviderFrontEndCarrier,
    data: matchingProviderFrontEndCarrier,
  } = useMatchingProviderFrontEndCarrierQuery(
    {
      providerId: providerId,
      patientUserId: patient?.id,
      includeHiddenCarriers: true,
    },
    {
      enabled: !!providerId,
    }
  );

  if (
    isLoadingMatchingProviderFrontEndCarrier ||
    !matchingProviderFrontEndCarrier
  ) {
    return null;
  }

  const firstPaymentEligibleDate = parseDate(
    matchingProviderFrontEndCarrier.firstPaymentEligibleDate
  );

  if (!firstPaymentEligibleDate || isDateInPast(firstPaymentEligibleDate))
    return null;

  return (
    <div css={{ marginBottom: theme.spacing.x11 }}>
      <GuidanceCard variant="warning" layout="vertical">
        <BodyText>
          Reminder: You’ll be paid once you’ve been added to{' '}
          {matchingProviderFrontEndCarrier.frontEndCarrier.name}’s systems. This
          should be done by {formatDate(firstPaymentEligibleDate)}.
        </BodyText>

        <Link href={LEARN_ABOUT_GETTING_PAID_LINK} target="_blank">
          Learn about getting paid.
        </Link>
      </GuidanceCard>
    </div>
  );
};
