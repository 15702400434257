import { ClipboardText } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconClipboardText: Icon = forwardRef(
  (props, ref: React.Ref<SVGSVGElement>) => (
    <IconBase
      phosphorIcon={ClipboardText}
      {...props}
      variants={['outlined', 'filled']}
      ref={ref}
    />
  )
);

IconClipboardText.variants = ['outlined', 'filled'];
IconClipboardText.defaultVariant = 'outlined';
