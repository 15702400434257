import moment from 'moment';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { theme } from '@headway/helix/theme';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';

import { isPast } from '../../events/util/events';

export interface AppointmentHasNotHappenedYetCardProps {
  event?: ProviderEventRead;
}

export const AppointmentHasNotHappenedYetCard = ({
  event,
}: AppointmentHasNotHappenedYetCardProps) => {
  const [dismissCantConfirmYetBanner, setDismissCantConfirmYetBanner] =
    useLocalStorage('dismissCantConfirmYetBanner');

  if (!event || isPast(event) || dismissCantConfirmYetBanner) {
    return null;
  }

  return (
    <div css={{ paddingBottom: theme.spacing.x8 }}>
      <GuidanceCard variant="info">
        <BodyText>
          You can finish confirming this session after the scheduled end time,{' '}
          {moment(event.endDate).format('hh:mm A')} on{' '}
          {moment(event.endDate).format('MMMM DD')}.
        </BodyText>
        <Button
          variant="link"
          onPress={() => setDismissCantConfirmYetBanner('true')}
        >
          Dismiss
        </Button>
      </GuidanceCard>
    </div>
  );
};
