import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  ADNM8Submission,
  ADNM8SubmissionAssessmentType,
} from '@headway/api/models/ADNM8Submission';
import { BodyText } from '@headway/helix/BodyText';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextArea } from '@headway/helix/TextArea';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { PdfText } from '../pdf';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const ADNM8_OPTIONS: OptionsSchema = [
  {
    displayText: 'Never',
    value: 1,
  },
  {
    displayText: 'Rarely',
    value: 2,
  },
  {
    displayText: 'Sometimes',
    value: 3,
  },
  {
    displayText: 'Often',
    value: 4,
  },
];

const ADNM8_QUESTIONS: QuestionsSchema = [
  {
    key: 'ADNM8_1',
    questionText: 'I have to think about the stressful situation repeatedly.',
  },
  {
    key: 'ADNM8_2',
    questionText:
      'I have to think about the stressful situation a lot and this is a great burden to me.',
  },
  {
    key: 'ADNM8_3',
    questionText:
      'Since the stressful situation, I find it difficult to concentrate on certain things.',
  },
  {
    key: 'ADNM8_4',
    questionText:
      "I constantly get memories of the stressful situation and can't do anything to stop them.",
  },
  {
    key: 'ADNM8_5',
    questionText:
      'My thoughts often revolve around anything related to the stressful situation.',
  },
  {
    key: 'ADNM8_6',
    questionText:
      'Since the stressful situation, I do not like going to work or carrying out the necessary tasks in everyday life.',
  },
  {
    key: 'ADNM8_7',
    questionText:
      'Since the stressful situation, I can no longer sleep properly.',
  },
  {
    key: 'ADNM8_8',
    questionText:
      'Overall, the stressful situation affected me strongly in my personal relationships, my leisure activities, or other important areas of life.',
  },
];

const NON_SCORABLE_INSTRUCTION =
  'Below is a list of stressful life events. Please select the events that have happened during the past two years and are currently a strong burden to you, or have burdened you in the past six months.  You can select as many events as applicable.';

const SCORABLE_INSTRUCTION =
  'Below you will find various statements about which reactions these types of events can trigger. Please indicate how often the respective statement applies to you (“never” to “often”).';

const OTHER_STRESSFUL_EVENT_LABEL =
  'Any other stressful event (please indicate)';

const MOST_STRAINING_EVENT_LABEL =
  'The events you have just indicated can have numerous consequences for our well-being and behavior. Please indicate the most straining event(s) below';

const ADNM8_VALIDATION_SCHEMA = Yup.object().shape({
  ...ADNM8_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  ),
  stressfulEvents: Yup.array(Yup.string()),
  otherStressfulEvents: Yup.string().optional(),
  mostStrainingEvent: Yup.string().test(
    'len',
    'Must be 100 words or fewer',
    (val) => !val || val.split(/\S+/g).length <= 100
  ),
});

const ADNM8_CHECKBOX_OPTIONS = {
  divorce_separation: 'Divorce / separation',
  family_conflicts: 'Family conflicts',
  conflicts_in_working_life: 'Conflicts in working life',
  conflicts_with_neighbors: 'Conflicts with neighbors',
  illness_of_loved_one: 'Illness of a loved one',
  death_of_loved_one: 'Death of a loved one',
  adjustment_due_to_retirement: 'Adjustment due to retirement',
  unemployment: 'Unemployment',
  too_much_too_little_work: 'Too much / too little work',
  time_pressure: 'Pressure to meet deadlines / time pressure',
  moving_to_new_home: 'Moving to a new home',
  financial_problems: 'Financial problems',
  own_serious_illness: 'Own serious illness',
  serious_accident: 'Serious accident',
  assault: 'Assault',
  termination_of_important_leisure_activity:
    'Termination of an important leisure activity',
};

type ADNMCheckboxOption = keyof typeof ADNM8_CHECKBOX_OPTIONS;

const ADNM8Checkboxes = ({ variant }: { variant: 'large' | 'small' }) => {
  const Text = variant === 'large' ? BodyText : SubBodyText;
  return (
    <>
      {Object.entries(ADNM8_CHECKBOX_OPTIONS).map(([option, displayText]) => (
        <Checkbox key={option} value={option}>
          <Text>{displayText}</Text>
        </Checkbox>
      ))}
    </>
  );
};

type ADNM8FormValues = {
  // Maps numerical values to strings because checkboxes only accept strings.
  [key in keyof Omit<
    ADNM8Submission,
    'assessmentType'
  >]: ADNM8Submission[key] extends number ? string : ADNM8Submission[key];
};

export const ADNM8AssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: ADNM8FormValues) => {
    const {
      stressfulEvents,
      otherStressfulEvents,
      mostStrainingEvent,
      ...scorableEntries
    } = values;
    return onSubmit({
      assessmentType: ADNM8SubmissionAssessmentType.ADNM8,
      stressfulEvents,
      otherStressfulEvents,
      mostStrainingEvent,
      ...mapValues(scorableEntries, Number),
    });
  };

  return (
    <Formik<ADNM8FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={ADNM8_VALIDATION_SCHEMA}
      initialValues={{
        ADNM8_1: '',
        ADNM8_2: '',
        ADNM8_3: '',
        ADNM8_4: '',
        ADNM8_5: '',
        ADNM8_6: '',
        ADNM8_7: '',
        ADNM8_8: '',
      }}
    >
      <SafeFormikForm id={id} className="flex flex-col gap-6">
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{NON_SCORABLE_INSTRUCTION}</b>
          <FormControl component={CheckboxGroup} name="stressfulEvents">
            <ADNM8Checkboxes variant="large" />
          </FormControl>
          <FormControl
            component={TextField}
            label={OTHER_STRESSFUL_EVENT_LABEL}
            name="otherStressfulEvents"
          />
          <FormControl
            component={TextArea}
            label={MOST_STRAINING_EVENT_LABEL}
            name="mostStrainingEvent"
          />
        </section>
        <section className="flex flex-col gap-6">
          <b>{SCORABLE_INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={ADNM8_QUESTIONS}
            options={ADNM8_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyADNM8Assessment = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  const nonScorableResponseJson = response?.nonScorableResponseJson;

  return (
    <div className="flex flex-col gap-6">
      <section className="flex flex-col gap-6">
        <SubBodyText>
          <b id="adnm8-non-scorable-label">{NON_SCORABLE_INSTRUCTION}</b>
        </SubBodyText>
        <CheckboxGroup
          readonly
          name="nonScorableResponseJson.stressfulEvents"
          defaultValue={
            nonScorableResponseJson &&
            (nonScorableResponseJson as any).stressful_events
          }
          aria-labelledby="adnm8-non-scorable-label"
        >
          <ADNM8Checkboxes variant="small" />
        </CheckboxGroup>
        {scorableResponseJson ? (
          <>
            <div>
              <SubBodyText>
                <b>{OTHER_STRESSFUL_EVENT_LABEL}:</b>
              </SubBodyText>
              <br />
              <SubBodyText>
                {(nonScorableResponseJson as any).other_stressful_events || (
                  <span className="text-system-gray">(No response)</span>
                )}
              </SubBodyText>
            </div>
            <div className="whitespace-pre-line">
              {}
              <SubBodyText>
                <b>{MOST_STRAINING_EVENT_LABEL}:</b>
              </SubBodyText>
              <br />
              <SubBodyText>
                {(nonScorableResponseJson as any).most_straining_event || (
                  <span className="text-system-gray">(No response)</span>
                )}
              </SubBodyText>
            </div>
          </>
        ) : (
          <>
            <TextField
              readonly
              name="nonScorableResponseJson.otherStressfulEvents"
              label={OTHER_STRESSFUL_EVENT_LABEL}
            />
            <TextArea
              readonly
              name="nonScorableResponseJson.mostStrainingEvent"
              label={MOST_STRAINING_EVENT_LABEL}
            />
          </>
        )}
      </section>
      <section className="flex flex-col gap-6">
        <SubBodyText>
          <b>{SCORABLE_INSTRUCTION}</b>
        </SubBodyText>
        <ReadonlyScorableQuestionList
          questions={ADNM8_QUESTIONS}
          options={ADNM8_OPTIONS}
          response={scorableResponseJson}
        />
      </section>
    </div>
  );
};

export const PdfADNM8Assessment = ({ response }: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  const nonScorableResponseJson = response?.nonScorableResponseJson;

  const stressfulEvents =
    nonScorableResponseJson &&
    (nonScorableResponseJson as any).stressful_events;

  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <View>
        <PdfText style={{ fontWeight: 'medium' }}>
          {NON_SCORABLE_INSTRUCTION}
        </PdfText>
        <PdfText style={{ color: theme.color.system.gray }}>
          {stressfulEvents
            ? stressfulEvents
                .map(
                  (stressful_event: ADNMCheckboxOption) =>
                    ADNM8_CHECKBOX_OPTIONS[stressful_event]
                )
                .join(', ')
            : '(No response)'}
        </PdfText>
      </View>
      <View>
        <PdfText style={{ fontWeight: 'medium' }}>
          {OTHER_STRESSFUL_EVENT_LABEL}
        </PdfText>
        <PdfText style={{ color: theme.color.system.gray }}>
          {(nonScorableResponseJson &&
            (nonScorableResponseJson as any).other_stressful_events) ||
            '(No response)'}
        </PdfText>
      </View>
      <View>
        <PdfText style={{ fontWeight: 'medium' }}>
          {MOST_STRAINING_EVENT_LABEL}
        </PdfText>
        <PdfText style={{ color: theme.color.system.gray }}>
          {(nonScorableResponseJson &&
            (nonScorableResponseJson as any).most_straining_event) ||
            '(No response)'}
        </PdfText>
      </View>
      <PdfText style={{ fontWeight: 'medium' }}>{SCORABLE_INSTRUCTION}</PdfText>
      <PdfScorableQuestionList
        questions={ADNM8_QUESTIONS}
        options={ADNM8_OPTIONS}
        response={scorableResponseJson}
      />
    </View>
  );
};
