import clsx from 'clsx';
import React from 'react';

type HelixForegroundColor =
  | 'foreground/primary'
  | 'foreground/secondary'
  | 'foreground/disabled'
  | 'foreground/compliance'
  | 'foreground/compliance-secondary'
  | 'foreground/compliance-tertiary'
  | 'foreground/info'
  | 'foreground/warning'
  | 'foreground/warning-secondary'
  | 'foreground/danger'
  | 'foreground/danger-secondary'
  | 'foreground/inverted-primary';

type ContentTextVariant =
  | 'page-title'
  | 'section-title'
  | 'section-title/medium'
  | 'body-large'
  | 'body-large/medium'
  | 'body'
  | 'body/medium'
  | 'body-small'
  | 'body-small/medium'
  | 'caption'
  | 'caption/medium';

export type ContentTextProps = {
  children: React.ReactNode;
  variant?: ContentTextVariant;

  color?: HelixForegroundColor;
};

const VARIANT_CLASSNAMES: Record<ContentTextVariant, string> = {
  'page-title': 'hlx-typography-content-page-title',
  'section-title': 'hlx-typography-content-section-title',
  'section-title/medium': 'hlx-typography-content-section-title-medium',
  'body-large': 'hlx-typography-content-body-large',
  'body-large/medium': 'hlx-typography-content-body-large-medium',
  body: 'hlx-typography-content-body',
  'body/medium': 'hlx-typography-content-body-medium',
  'body-small': 'hlx-typography-content-body-small',
  'body-small/medium': 'hlx-typography-content-body-small-medium',
  caption: 'hlx-typography-content-caption',
  'caption/medium': 'hlx-typography-content-caption-medium',
};

const COLOR_VAR_MAP: Record<HelixForegroundColor, string> = {
  'foreground/primary': 'var(--hlx-color-foreground-primary)',
  'foreground/secondary': 'var(--hlx-color-foreground-secondary)',
  'foreground/disabled': 'var(--hlx-color-foreground-disabled)',
  'foreground/compliance': 'var(--hlx-color-foreground-compliance)',
  'foreground/compliance-secondary':
    'var(--hlx-color-foreground-compliance-secondary)',
  'foreground/compliance-tertiary':
    'var(--hlx-color-foreground-compliance-tertiary)',
  'foreground/info': 'var(--hlx-color-foreground-info)',
  'foreground/warning': 'var(--hlx-color-foreground-warning)',
  'foreground/warning-secondary':
    'var(--hlx-color-foreground-warning-secondary)',
  'foreground/danger': 'var(--hlx-color-foreground-danger)',
  'foreground/danger-secondary': 'var(--hlx-color-foreground-danger-secondary)',
  'foreground/inverted-primary': 'var(--hlx-color-foreground-inverted-primary)',
};

export const ContentText = ({
  variant = 'body',
  children,
  color = 'foreground/primary',
}: ContentTextProps) => {
  return (
    <span
      className={clsx(VARIANT_CLASSNAMES[variant ?? 'body'])}
      style={{
        color: COLOR_VAR_MAP[color],
      }}
    >
      {children}
    </span>
  );
};
