import { Cell, Column, Row } from '@react-stately/table';
import { AriaLabelingProps, CollectionChildren } from '@react-types/shared';
import { Sortable, SortDescriptor, SortDirection } from '@react-types/shared';
import React, { useMemo } from 'react';
import { useRef } from 'react';
import {
  AriaTableCellProps,
  AriaTableColumnHeaderProps,
  AriaTableProps,
  GridRowProps,
  mergeProps,
  useFocusRing,
  useHover,
  usePress,
  useTable,
  useTableCell,
  useTableColumnHeader,
  useTableHeaderRow,
  useTableRow,
  useTableRowGroup,
} from 'react-aria';
import {
  ColumnProps,
  TableBody,
  TableBodyProps,
  TableHeader,
  TableHeaderProps,
  TableState,
  useTableState,
} from 'react-stately';
import { Simplify } from 'type-fest';

import { theme } from './theme';

type TableProps<T> = AriaLabelingProps &
  Pick<AriaTableProps<T>, 'onRowAction'> &
  Sortable & {
    /** Keys for which row actions should be disabled. */
    disabledKeys?: Set<string>;
    /** Children are expected to be a TableHeader and TableBody, which contain Columns and Rows, respectively. */
    children?: [
      React.ReactElement<TableHeaderProps<T>>,
      React.ReactElement<TableBodyProps<T>>,
    ];
  };

/**
 * A simple table based on react-aria's useTable and useTableState hooks. These hooks allow much
 * richer functionality than is used here, including sorting, filtering, and selection, but this is
 * intentionally omitted until a use case is shown.
 */
function Table<T extends object>(props: TableProps<T>) {
  const state = useTableState({ ...props, selectionMode: 'none' });
  const ref = useRef<HTMLTableElement>(null);
  const { collection } = state;
  const handleRowAction = (key: React.Key) => {
    if (props.onRowAction && !props.disabledKeys?.has(String(key))) {
      props.onRowAction(key);
    }
  };
  // Omit onMouseDown because it overrides text highlighting; the callback is only used to support
  // selection, which we don't implement anyway.
  const {
    gridProps: { onMouseDown, ...gridProps },
  } = useTable(
    { ...props, onRowAction: props.onRowAction && handleRowAction },
    state,
    ref
  );

  return (
    <table {...gridProps} ref={ref} className="hlx-table">
      <TableRowGroup type="thead">
        {collection.headerRows.map((headerRow) => (
          <TableHeaderRow key={headerRow.key} item={headerRow} state={state}>
            {[...headerRow.childNodes].map((column) => (
              <TableColumnHeader
                key={column.key}
                column={column}
                state={state}
              />
            ))}
          </TableHeaderRow>
        ))}
      </TableRowGroup>
      <TableRowGroup type="tbody">
        {[...collection.body.childNodes].map((row) => (
          <TableRow
            key={row.key}
            item={row}
            state={state}
            hasRowAction={!!props.onRowAction}
          >
            {[...row.childNodes].map((cell) => (
              <TableCell key={cell.key} cell={cell} state={state} />
            ))}
          </TableRow>
        ))}
      </TableRowGroup>
    </table>
  );
}

interface TableRowGroupProps {
  type: 'tbody' | 'thead';
  children: React.ReactNode;
}

function TableRowGroup({ type: Element, children }: TableRowGroupProps) {
  let { rowGroupProps } = useTableRowGroup();
  return <Element {...rowGroupProps}>{children}</Element>;
}

interface TableHeaderRowProps<T> {
  item: GridRowProps<T>['node'];
  state: TableState<T>;
  children: React.ReactNode;
}

function TableHeaderRow<T extends object>({
  item,
  state,
  children,
}: TableHeaderRowProps<T>) {
  let ref = useRef<HTMLTableRowElement>(null);
  let { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr {...rowProps} ref={ref}>
      {children}
    </tr>
  );
}

interface TableColumnHeaderProps<T> {
  column: AriaTableColumnHeaderProps<T>['node'];
  state: TableState<T>;
}

function TableColumnHeader<T extends object>({
  column,
  state,
}: TableColumnHeaderProps<T>) {
  const ref = useRef<HTMLTableCellElement>(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );

  const isSortedAscending =
    state.sortDescriptor?.column === column.key &&
    state.sortDescriptor?.direction === 'ascending';
  const isSortedDescending =
    state.sortDescriptor?.column === column.key &&
    state.sortDescriptor?.direction === 'descending';

  const { isFocusVisible, focusProps } = useFocusRing();
  const columnProps = column.props as HelixColumnProps<unknown>;
  return (
    <th
      {...mergeProps(columnHeaderProps, focusProps)}
      colSpan={column.colspan}
      style={{
        width: columnProps?.width ?? undefined,
        maxWidth: columnProps?.maxWidth ?? undefined,
        minWidth: columnProps?.minWidth ?? undefined,
        textAlign: columnProps?.align || 'left',
      }}
      data-focused={isFocusVisible}
      data-sortable={column.props.allowsSorting}
      ref={ref}
    >
      <span>
        {column.rendered}
        {column.props.allowsSorting && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <g>
              <path
                fill={
                  isSortedAscending
                    ? theme.color.foreground.primary
                    : theme.color.foreground.disabled
                }
                d="M8 10.5l4-4 4 4H8z"
              ></path>
              <path
                fill={
                  isSortedDescending
                    ? theme.color.foreground.primary
                    : theme.color.foreground.disabled
                }
                d="M16 13.5l-4 4-4-4h8z"
              ></path>
            </g>
          </svg>
        )}
      </span>
    </th>
  );
}

interface TableRowProps<T> {
  item: GridRowProps<T>['node'];
  children: React.ReactNode;
  state: TableState<T>;
  hasRowAction: boolean;
}

function TableRow<T extends object>({
  item,
  children,
  state,
  hasRowAction,
}: TableRowProps<T>) {
  let ref = useRef<HTMLTableRowElement>(null);
  let { rowProps } = useTableRow(
    {
      node: item,
    },
    state,
    ref
  );
  const isRowActionDisabled =
    hasRowAction && state.disabledKeys.has(String(item.key));
  let { isFocusVisible, focusProps } = useFocusRing();
  let { hoverProps, isHovered } = useHover({ isDisabled: isRowActionDisabled });
  let { pressProps, isPressed } = usePress({ isDisabled: isRowActionDisabled });

  return (
    <tr
      data-focused={isFocusVisible}
      data-hovered={isHovered}
      data-pressed={isPressed}
      data-pressable={hasRowAction}
      {...mergeProps(
        rowProps,
        focusProps,
        ...(hasRowAction ? [hoverProps, pressProps] : [])
      )}
      ref={ref}
    >
      {children}
    </tr>
  );
}

interface TableCellProps<T> {
  cell: AriaTableCellProps['node'];
  state: TableState<T>;
}

function TableCell<T extends object>({ cell, state }: TableCellProps<T>) {
  let ref = useRef<HTMLTableCellElement>(null);
  let { gridCellProps } = useTableCell({ node: cell }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();
  const columnProps = cell.column?.props as
    | HelixColumnProps<unknown>
    | undefined;

  return (
    <td
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        width: columnProps?.width ?? undefined,
        maxWidth: columnProps?.maxWidth ?? undefined,
        minWidth: columnProps?.minWidth ?? undefined,
        textAlign: columnProps?.align || 'left',
      }}
      data-focused={isFocusVisible}
      ref={ref}
    >
      {cell.rendered}
    </td>
  );
}

/**
 * Below, we re-export react-stately collection components with typings altered to reflect
 * Helix-supported features.
 */

type HelixColumnProps<T> = Simplify<
  Pick<
    ColumnProps<T>,
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'textValue'
    | 'isRowHeader'
    | 'allowsSorting'
  > & {
    children: React.ReactNode;
    align?: 'left' | 'center' | 'right';
  }
>;
const HelixColumn = Column as <T>(props: HelixColumnProps<T>) => JSX.Element;
interface HelixRowProps {
  children:
    | React.ReactElement<HelixCellProps>
    | Array<React.ReactElement<HelixCellProps>>;
}
const HelixRow = Row as (props: HelixRowProps) => JSX.Element;

interface HelixCellProps {
  children: React.ReactNode;
}
const HelixCell = Cell as (props: HelixCellProps) => JSX.Element;

interface HelixTableHeaderProps<T> {
  children:
    | React.ReactElement<HelixColumnProps<T>>
    | Array<React.ReactElement<HelixColumnProps<T>>>;
}
const HelixTableHeader = TableHeader as <T>(
  props: HelixTableHeaderProps<T>
) => JSX.Element;

type HelixTableBodyProps<T> = Pick<TableBodyProps<T>, 'children'>;
const HelixTableBody = TableBody as <T>(
  props: HelixTableBodyProps<T>
) => JSX.Element;

export {
  HelixColumn as Column,
  HelixRow as Row,
  HelixCell as Cell,
  HelixTableHeader as TableHeader,
  HelixTableBody as TableBody,
  Table,
};

export type {
  HelixColumnProps as ColumnProps,
  HelixRowProps as RowProps,
  HelixCellProps as CellProps,
  HelixTableHeaderProps as TableHeaderProps,
  HelixTableBodyProps as TableBodyProps,
  TableProps,
  SortDirection,
  SortDescriptor,
};
