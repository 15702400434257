import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { isLoginCredentialsProviderTask } from '@headway/shared/constants/providerTaskType';
import { PASSWORD_COLLECTION_PROVIDER_TASKS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { CONTACT_FORM_URL } from 'views/InsuranceStatus/ProviderTasks/constants';
import { LoginCredentialsTaskModal } from 'views/InsuranceStatus/ProviderTasks/LoginCredentialsTaskModal';

const GenericProviderTaskModal = ({
  activeProviderTask,
  onDismiss,
}: {
  activeProviderTask: ProviderTaskRead;
  onDismiss: () => void;
}) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      title={`Action required: ${activeProviderTask?.title}`}
    >
      <ModalContent>
        <BodyText>
          <div className="grid gap-y-4">
            <div>{activeProviderTask?.description}</div>
            <div>{<b>Here's what to do: </b>}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(activeProviderTask?.statusDescription || ''),
              }}
            ></div>
          </div>
        </BodyText>
      </ModalContent>
      <ModalFooter>
        <LinkButton variant="primary" href={CONTACT_FORM_URL} target="_blank">
          Submit information to Headway
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};

export const ProviderTaskModal = ({
  activeProviderTask,
  onDismiss,
  refetchTasks,
}: {
  activeProviderTask: ProviderTaskRead | undefined;
  onDismiss: () => void;
  refetchTasks: () => void;
}) => {
  const isPasswordCollectionEnabled = useFlag(
    PASSWORD_COLLECTION_PROVIDER_TASKS,
    false
  );
  if (!activeProviderTask) {
    return null;
  }
  return (
    <>
      {isPasswordCollectionEnabled &&
      activeProviderTask.taskType &&
      isLoginCredentialsProviderTask(activeProviderTask.taskType) ? (
        <LoginCredentialsTaskModal
          activeProviderTask={activeProviderTask}
          taskType={activeProviderTask.taskType}
          onDismiss={onDismiss}
          refetchTasks={refetchTasks}
        />
      ) : (
        <GenericProviderTaskModal
          activeProviderTask={activeProviderTask}
          onDismiss={onDismiss}
        />
      )}
    </>
  );
};
