import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M210.667 85.333a7.04 7.04 0 0 0-6.933.213L172.48 105.6V93.44c0-6.08-4.907-10.987-10.987-10.987h-104A10.97 10.97 0 0 0 46.506 93.44v70.187c0 6.08 4.907 10.987 10.987 10.987h103.893c6.08 0 10.987-4.907 10.987-10.987v-12.16l31.253 20.053c1.067.747 2.347 1.067 3.627 1.067 1.173 0 2.24-.32 3.307-.853 2.24-1.173 3.52-3.52 3.52-5.973V91.2c0-2.453-1.387-4.8-3.52-5.973zm-46.933 78.4a2.37 2.37 0 0 1-2.347 2.347H57.493a2.37 2.37 0 0 1-2.347-2.347V93.547a2.37 2.37 0 0 1 2.347-2.347h103.893a2.37 2.37 0 0 1 2.347 2.347zm41.813-1.28-33.067-21.227v-25.28l33.067-21.227z" />,
  ],
]);
export const IconVideoChat: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="40 60 180 140" {...props} weights={weights} />
));

IconVideoChat.variants = ['outlined'];
IconVideoChat.defaultVariant = 'outlined';
