import { PrivacyTip } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

import { UserRead } from '@headway/api/models/UserRead';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { TELEHEALTH_LOCATIONS_SIGMUND } from '@headway/shared/FeatureFlags/flagNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import {
  PatientAddressFormV2,
  PatientAddressFormV2ImplProps,
} from './PatientAddressFormV2';

export interface AddressModalV2Props {
  open: boolean;
  patientUser: UserRead;
  onClose: () => void;
  onSuccess: PatientAddressFormV2ImplProps['onSuccess'];
  isActiveAddress?: boolean;
}

export const AddressModalV2 = ({
  open,
  patientUser,
  onClose,
  onSuccess,
  isActiveAddress = true,
}: AddressModalV2Props) => {
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isTelehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );

  const patientFirstName = formatPatientName(patientUser, {
    firstNameOnly: true,
  });

  const modalTitle = isMSCEnabled
    ? `Add location for ${patientFirstName}`
    : 'New Address';
  return (
    <Modal title={modalTitle} isOpen={open} onDismiss={onClose}>
      <ModalContent>
        <Alert
          color="info"
          icon={<PrivacyTip />}
          css={{ marginBottom: theme.space.base }}
        >
          {isMSCEnabled
            ? `We need to know where ${patientFirstName} is located to submit a claim. You must be licensed and credentialed on Headway in the state where your client is located during each session.`
            : `We ask for a home address because the insurance company requires residence verification for eligibility and claims. We will not use this information otherwise, nor will we share it with anyone besides the insurance and provider.`}
        </Alert>
        <PatientAddressFormV2
          user={patientUser}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_ID as string}
          onSuccess={onSuccess}
          isActiveAddress={isActiveAddress}
          showAddressType={isMSCEnabled || isTelehealthLocationsEnabled}
          requireAddressType={isTelehealthLocationsEnabled}
        />
      </ModalContent>
    </Modal>
  );
};
