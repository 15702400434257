import { FormikProps } from 'formik';
import { MutableRefObject } from 'react';

import { PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES } from '@headway/shared/constants/cptCodes';
import { CptCodeOption } from '@headway/ui/CPTCodeInput';
import { DiagnosisCodeOption } from '@headway/ui/DiagnosisCodeInput';

export const validateAndSubmit = async (
  ref: MutableRefObject<FormikProps<any> | undefined>
) => {
  if (!ref.current) {
    return;
  }

  const form = ref.current;
  const validationErrors = await form.validateForm();
  await form.submitForm();

  return {
    success: Object.keys(validationErrors).length === 0,
    errors: validationErrors,
    values: form.values,
  };
};

export const isBillingAddOnPsychotherapy = (cptCodes: string[]) =>
  cptCodes.some((code) => PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES.includes(code));

export const getMatchingCodeOptionsInOrder = (
  codes: string[],
  options: DiagnosisCodeOption[] | CptCodeOption[]
) => {
  return codes.reduce((acc: DiagnosisCodeOption[] | CptCodeOption[], code) => {
    const matchingOption = options.find(
      (o: DiagnosisCodeOption | CptCodeOption) => o.value === code
    );
    if (matchingOption) {
      acc.push(matchingOption);
    }
    return acc;
  }, []);
};

export const isStringArray = (value: any) => {
  return (
    Array.isArray(value) && value.every((item) => typeof item === 'string')
  );
};

// Recursive function to get paths of an object, accounting for nested objects
export const getObjectPaths = (obj: any, prefix = ''): string[] => {
  return Object.keys(obj).reduce((paths, key) => {
    const path = prefix ? `${prefix}.${key}` : key;
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // Recursively gather paths if the property is a nested object
      return paths.concat(getObjectPaths(obj[key], path));
    } else {
      return paths.concat(path);
    }
  }, [] as string[]);
};
