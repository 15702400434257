import { NumberCircleSeven } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNumberCircleSeven: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={NumberCircleSeven}
    defaultVariant="filled"
    ref={ref}
  />
));

IconNumberCircleSeven.variants = ['filled'];
IconNumberCircleSeven.defaultVariant = 'filled';
