import { css } from '@emotion/react';
import { useProvider, useProviderPatient } from 'hooks';
import moment from 'moment';
import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { BodyText } from '@headway/helix/BodyText';
import { IconCreditCard } from '@headway/helix/icons/CreditCard';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPrice } from '@headway/shared/utils/payments';

import { InsuranceStatusGuidanceCard } from './InsuranceStatusGuidanceCard';

interface BillingMethodInfoProps {
  clientId: number;
  onOpenContactFormInsuranceIssues: () => void;
  onOpenInsuranceModal: () => void;
  onClickOpenBillingMethodModal: () => void;
  onOpenNoDataOutageVerificationInProgressModal: () => void;
  onOpenOldDataOutageVerificationInProgressModal: () => void;
  onOpenPlanIneligibleAlertModal: () => void;
  onClickOpenInsuranceIneligibilityExplainerModal: () => void;
  wasInNoDataOutage: boolean;
  wasInOldDataOutage: boolean;
}

export const BillingMethodInfo = ({
  clientId,
  onOpenContactFormInsuranceIssues,
  onOpenInsuranceModal,
  onClickOpenBillingMethodModal,
  onClickOpenInsuranceIneligibilityExplainerModal,
  onOpenNoDataOutageVerificationInProgressModal,
  onOpenOldDataOutageVerificationInProgressModal,
  onOpenPlanIneligibleAlertModal,
  wasInNoDataOutage,
  wasInOldDataOutage,
}: BillingMethodInfoProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });
  if (!client || !providerPatient?.billingTypeDefault) {
    return null;
  }

  const { billingTypeDefault, selfPayRateDefault } = providerPatient;
  const insurance = client.activeUserInsurance;

  const memberName = insurance
    ? `${insurance?.firstName} ${insurance?.lastName}`
    : undefined;
  const insuranceInfo: Array<[string, string | undefined]> = [
    ['Carrier', insurance?.billingFrontEndCarrierName],
    ['Group number', insurance?.groupNumber],
    ['Member name', memberName],
    [
      'Date of birth',
      insurance?.dob && moment(insurance.dob).format('MM/DD/YYYY'),
    ],
    [
      'Member ID',
      insurance?.correctedMemberId
        ? insurance?.correctedMemberId
        : insurance?.memberId,
    ],
  ];

  return (
    <div css={billingMethodInfoCss.container}>
      <div css={billingMethodInfoCss.billingType}>
        {billingTypeDefault === BillingType.SELF_PAY ? (
          <>
            <IconCreditCard />
            <BodyText>Private Pay</BodyText>
          </>
        ) : (
          <>
            <IconCreditCardCheck />
            <BodyText>Insurance</BodyText>
          </>
        )}
      </div>
      {billingTypeDefault === BillingType.SELF_PAY ? (
        <BodyText>
          <b>{formatPrice(selfPayRateDefault)}</b> per session
        </BodyText>
      ) : (
        <>
          <InsuranceStatusGuidanceCard
            clientId={clientId}
            providerId={provider.id}
            onOpenInsuranceModal={onOpenInsuranceModal}
            onClickOpenBillingMethodModal={onClickOpenBillingMethodModal}
            onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
            onOpenPlanIneligibleAlertModal={onOpenPlanIneligibleAlertModal}
            onOpenNoDataOutageVerificationInProgressModal={
              onOpenNoDataOutageVerificationInProgressModal
            }
            onOpenOldDataOutageVerificationInProgressModal={
              onOpenOldDataOutageVerificationInProgressModal
            }
            onClickOpenInsuranceIneligibilityExplainerModal={
              onClickOpenInsuranceIneligibilityExplainerModal
            }
            wasInNoDataOutage={wasInNoDataOutage}
            wasInOldDataOutage={wasInOldDataOutage}
          />

          <dl css={billingMethodInfoCss.insuranceInfo}>
            {insuranceInfo.map(([label, value]) => (
              <React.Fragment key={label}>
                <dt>
                  <BodyText>
                    <b>{label}</b>
                  </BodyText>
                </dt>
                <dd>
                  <BodyText>{value || '—'}</BodyText>
                </dd>
              </React.Fragment>
            ))}
          </dl>
          <BodyText>
            Make sure this information matches that of the person being seen,
            even if they're a minor.
          </BodyText>
        </>
      )}
    </div>
  );
};

const billingMethodInfoCss = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.x5,
    overflowX: 'auto',
  }),
  billingType: css({
    border: `1px solid ${theme.color.system.borderGray}`,
    borderRadius: '6px',
    padding: theme.spacing.x4,
    display: 'flex',
    gap: theme.spacing.x3,
    alignItems: 'center',
  }),
  insuranceInfo: css({
    display: 'grid',
    gridTemplateColumns:
      'minmax(min-content, 136px) auto minmax(min-content, 136px) auto',
    gap: theme.spacing.x3,
    dd: { margin: 0 },
    overflowX: 'auto',
  }),
};
