import { isValidMemberId } from './insuranceUtils';
import { getCarrierMemberIdData } from './memberIdValidationData';

interface MemberIdPattern {
  prefix?: string;
  suffix?: string;
  frequency: number;
}

const MIN_PREFIX_SUFFIX_FREQUENCY = 0.05 as const;
const NUM_PREFIX_SUFFIX_EXAMPLES = 8 as const;
const HIGH_CONFIDENCE_THRESHOLD = 0.95 as const;

export const formatMemberIdPatterns = (
  items: MemberIdPattern[],
  isPrefix: boolean
): string => {
  return items
    .filter((item) => (isPrefix ? item.prefix : item.suffix))
    .filter((item) => item.frequency >= MIN_PREFIX_SUFFIX_FREQUENCY)
    .slice(0, NUM_PREFIX_SUFFIX_EXAMPLES)
    .map((item) => (isPrefix ? item.prefix! : item.suffix!))
    .join(', ');
};

export const analyzeFormats = (
  formats: Array<{ format: string; frequency: number }>
) => {
  let minLength = Infinity;
  let maxLength = 0;
  let hasLetters = false;
  let hasNumbers = false;
  let hasHyphen = false;

  formats.forEach(({ format }) => {
    const length = format.length;
    minLength = Math.min(minLength, length);
    maxLength = Math.max(maxLength, length);

    if (format.includes('a')) hasLetters = true;
    if (format.includes('d')) hasNumbers = true;
    if (format.includes('h')) hasHyphen = true;
  });

  return { minLength, maxLength, hasLetters, hasNumbers, hasHyphen };
};

export const checkMemberIdFormat = (
  memberId: string,
  formats: Array<{ format: string; frequency: number }>
): boolean => {
  return formats.some(({ format }) => {
    const regex = new RegExp(
      '^' +
        format
          .replace(/a/g, '[A-Za-z]')
          .replace(/d/g, '\\d')
          .replace(/[hs]/g, (match): string => (match === 'h' ? '-' : ' ')) +
        '$'
    );
    return regex.test(memberId);
  });
};

export const checkHighConfidencePatterns = (
  memberId: string,
  prefixes: MemberIdPattern[],
  suffixes: MemberIdPattern[],
  formats: Array<{ format: string; frequency: number }>
): {
  isValid: boolean;
  invalidPrefix: boolean;
  invalidSuffix: boolean;
  invalidFormat: boolean;
  invalidMemberId: boolean;
} => {
  const upperMemberId = memberId.toUpperCase();

  const highConfidencePrefixes = prefixes.filter(
    (p) => p.frequency >= HIGH_CONFIDENCE_THRESHOLD
  );
  const highConfidenceSuffixes = suffixes.filter(
    (s) => s.frequency >= HIGH_CONFIDENCE_THRESHOLD
  );
  const validPrefix =
    highConfidencePrefixes.length === 0 ||
    highConfidencePrefixes.some((p) =>
      upperMemberId.startsWith(p.prefix!.toUpperCase())
    );
  const validSuffix =
    highConfidenceSuffixes.length === 0 ||
    highConfidenceSuffixes.some((s) =>
      upperMemberId.endsWith(s.suffix!.toUpperCase())
    );
  // Calculate the sum of our format percentages
  // If the sum of our format percentages is above HIGH_CONFIDENCE_THRESHOLD,
  // validate against them all as its likely the member id should match at least one of them.
  // Otherwise, just validate against individual high confidence formats.
  const formatSum = formats.reduce((acc, f) => acc + f.frequency, 0);
  const highConfidenceFormats = formats.filter(
    (f) => f.frequency >= HIGH_CONFIDENCE_THRESHOLD
  );
  const validFormat =
    formatSum >= HIGH_CONFIDENCE_THRESHOLD
      ? checkMemberIdFormat(memberId, formats)
      : highConfidenceFormats.length === 0 ||
        checkMemberIdFormat(memberId, highConfidenceFormats);

  const invalidMemberId = !isValidMemberId(memberId);
  return {
    isValid: validPrefix && validSuffix && validFormat && !invalidMemberId,
    invalidPrefix: !validPrefix,
    invalidSuffix: !validSuffix,
    invalidFormat: !validFormat,
    invalidMemberId: invalidMemberId,
  };
};

const getCharacterTypesString = (
  hasLetters: boolean,
  hasNumbers: boolean,
  hasHyphen: boolean
): string => {
  if (hasLetters && hasNumbers && hasHyphen)
    return 'letters, numbers, and a hyphen';
  if (hasLetters && hasNumbers) return 'letters and numbers';
  if (hasLetters && hasHyphen) return 'letters and a hyphen';
  if (hasNumbers && hasHyphen) return 'numbers and a hyphen';
  if (hasLetters) return 'only letters';
  if (hasNumbers) return 'only numbers';
  return '';
};

export const getValidationResult = (
  memberId: string,
  frontEndCarrierId: number,
  anthemPatientFacingCarrierConsolidationEnabled: boolean
): null | {
  invalidPrefix: boolean;
  invalidSuffix: boolean;
  invalidFormat: boolean;
  formattedPrefixes: string;
  formattedSuffixes: string;
  minLength: number;
  maxLength: number;
  characterTypes: string;
} => {
  if (!memberId || !frontEndCarrierId) return null;

  const memberIdData = getCarrierMemberIdData(
    frontEndCarrierId,
    anthemPatientFacingCarrierConsolidationEnabled
  );

  if (!memberIdData) return null;

  const { prefixes, suffixes, formats } = memberIdData;

  const highConfidenceCheck = checkHighConfidencePatterns(
    memberId,
    prefixes,
    suffixes,
    formats
  );

  const isError = !highConfidenceCheck.isValid;
  const isWarning = !checkMemberIdFormat(memberId, formats);
  if (!isError && !isWarning) return null;

  const formattedPrefixes = formatMemberIdPatterns(prefixes, true);
  const formattedSuffixes = formatMemberIdPatterns(suffixes, false);
  const { minLength, maxLength, hasLetters, hasNumbers, hasHyphen } =
    analyzeFormats(formats);
  return {
    invalidPrefix: highConfidenceCheck.invalidPrefix,
    invalidSuffix: highConfidenceCheck.invalidSuffix,
    invalidFormat: highConfidenceCheck.invalidFormat,
    formattedPrefixes,
    formattedSuffixes,
    minLength,
    maxLength,
    characterTypes: getCharacterTypesString(hasLetters, hasNumbers, hasHyphen),
  };
};
