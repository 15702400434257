import clsx from 'clsx';
import { ReactNode } from 'react';

import { ContentText, ContentTextProps } from './ContentText';

export interface BodyTextProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'white';
}

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const BodyText = ({ children, color = 'textBlack' }: BodyTextProps) => {
  return (
    <ContentText
      variant="body"
      color={LEGACY_COLOR_STYLE_MAP[color ?? 'textBlack']}
    >
      {children}
    </ContentText>
  );
};
const LEGACY_COLOR_STYLE_MAP: Record<
  NonNullable<BodyTextProps['color']>,
  ContentTextProps['color']
> = {
  textBlack: 'foreground/primary',
  gray: 'foreground/secondary',
  white: 'foreground/inverted-primary',
};
