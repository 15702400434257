import { ArrowDown } from '@phosphor-icons/react';
import { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconArrowDown: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    defaultVariant="outlined"
    variants={['outlined', 'filled']}
    phosphorIcon={ArrowDown}
    ref={ref}
  />
));

IconArrowDown.variants = ['outlined', 'filled'];
IconArrowDown.defaultVariant = 'outlined';
