import { css } from '@emotion/react';
import { Warning } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';

type MSCGuardrailApplicationalModalProps = {
  restrictionDate?: string;
  showModal: boolean;
};

export const MSCGuardrailApplicationModal: React.FC<
  React.PropsWithChildren<MSCGuardrailApplicationalModalProps>
> = ({ restrictionDate, showModal }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      if (showModal) {
        setOpen(true);
      }
    }
  }, [showModal]);
  return (
    <>
      <Modal
        isOpen={open}
        onDismiss={onClose}
        title={
          <div css={title}>
            <Warning />A note on starting this application
          </div>
        }
      >
        <>
          <ModalContent>
            <BodyText>
              <div css={section}>
                We recently shared that we'll need to block session
                confirmations after {restrictionDate} for states where you're
                not credentialed on Headway. This application allows you to get
                credentialed and keep seeing those clients through Headway. That
                said, please note the following:
              </div>
              <div css={highlightedOptions}>
                <div>
                  <div>
                    <IconCreditCardCheck />
                  </div>
                  <div>
                    <BodyText>
                      <strong>You must already be licensed</strong> in the state
                      for this credentialing application to be approved.
                    </BodyText>
                  </div>
                </div>
                <div>
                  <div>
                    <IconCreditCardCheck />
                  </div>
                  <div>
                    <BodyText>
                      <strong>
                        You'll be blocked from seeing patients in any new state
                      </strong>{' '}
                      as soon as you begin this application due to compliance
                      regulations, but you may reach out to our team below to
                      request a temporary exception.
                    </BodyText>
                  </div>
                </div>
                <div>
                  <div>
                    <IconCreditCardCheck />
                  </div>
                  <div>
                    <BodyText>
                      <strong>You'll be able to see these clients again</strong>{' '}
                      once you're approved.
                    </BodyText>
                  </div>
                </div>
              </div>
              <div>
                Questions?{' '}
                <Link
                  href={CREDENTIALING_REQS_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more here
                </Link>{' '}
                or{' '}
                <Link
                  href="https://headway.co/contact?tags=patient-location-modal-sep-2024"
                  target="_blank"
                >
                  reach out to our team
                </Link>
                .
              </div>
            </BodyText>
          </ModalContent>
          <ModalFooter>
            <Button variant="primary" onPress={onClose}>
              Got it
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

const highlightedOptions = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};
  padding: ${theme.spacing.x4} ${theme.spacing.x6};
  background-color: ${theme.color.hue.lightYellow};
  border-radius: 0.5rem;

  div {
    display: flex;
    gap: 0.5rem;
  }
  margin-bottom: ${theme.spacing.x4};
`;
const title = css`
  display: flex;
  align-items: center;
  color: ${theme.color.system.textBlack};
  svg {
    margin-right: ${theme.spacing.x2};
  }
`;
const section = css`
  margin-bottom: ${theme.spacing.x4};
`;
