import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

import { AssessmentResultPdfASRSFooterContent } from './AssessmentResultsPdfASRSFooterContent';

export const AssessmentResultPdfFooterContent = ({
  assessmentType,
}: {
  assessmentType: PatientAssessmentType;
}) => {
  if (assessmentType === PatientAssessmentType.ASRS) {
    return <AssessmentResultPdfASRSFooterContent />;
  }
  return null;
};
