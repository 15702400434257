import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import moment from 'moment';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { getProviderActiveAndLiveStates } from '@headway/shared/utils/providers';

const moduleEventName: Record<string, string> = Object.entries(
  ProviderModule
).reduce(
  (modules, module) => ({
    ...modules,
    [module[0]]: startCase(toLower(module[1])),
  }),
  {}
);

export const getEventNameFromEnum = (enumMember: ProviderModule) => {
  return moduleEventName[enumMember];
};

export const getLDAttributes = (
  user?: UserRead,
  provider?: ProviderRead,
  providerQuestionnaireId?: number,
  impersonatingUserId?: number
) => {
  return {
    // gemini.link(web/packages/feature-flags/src/context/kinds.ts)
    // @ts-ignore group_practice, leaving for potential legacy issues. Not sure if it's needed
    isInGroupPractice: !!user?.group_practice || !!provider?.groupPracticeId,
    groupPracticeId: provider?.groupPracticeId,
    providerState: provider?.providerLicenseState?.state,
    activeProviderStatesCount: provider?.activeProviderLicenseStates.length,
    liveProviderStatesCount: provider
      ? getProviderActiveAndLiveStates(provider).length
      : undefined,
    providerId: provider?.id,
    salesLeadId: provider?.salesLeadId,
    isPrescriber: provider?.isPrescriber,
    createdOn:
      provider?.createdOn && moment(provider?.providerLicenseState?.createdOn),
    providerEarliestActiveLiveOn:
      provider?.earliestActiveLiveOn && moment(provider?.earliestActiveLiveOn),
    impersonatingUserId: impersonatingUserId,
    providerQuestionnaireId,
    HEADWAY_APP: 'SIGMUND',
    // gemini.endlink
  } as Omit<LDSingleKindContext, 'email' | 'key'>;
};
