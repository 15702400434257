import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSessionDetailsEditability } from '~/legacy/hooks/useSessionDetailsEditability';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { useUser } from '@headway/shared/hooks/useUser';

import { isDetailsConfirmed, isPast } from '../../../events/util/events';
import { AppointmentConfirmationModalFormV2Values } from '../AppointmentConfirmationModalV2';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from '../stores/ProgressNotesContextV2';
import { useTreatmentPlanRequirementWarning } from '../TreatmentPlanRequirementWarning';

export const useButtonsConfig = () => {
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const progressNoteTemplate = useWatch({
    control,
    name: 'progressNote.template',
  });
  const { progressNoteState, progressNoteType, documentRemediation, event } =
    useContext(ProgressNoteContext);

  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId ?? undefined
  );
  const { data: patient } = useUser({
    userId: event?.patientUserId!,
  });
  const showTreatmentPlanRequirementWarning =
    useTreatmentPlanRequirementWarning(patient!);

  if (!event) return {};

  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  if (isPast(event)) {
    // Past appointment being scheduled
    switch (progressNoteType) {
      case ProgressNoteType.UPLOAD:
        // Upload note
        switch (progressNoteState) {
          case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
            // Upload note - editing a free text as addendum
            if (
              documentRemediation &&
              documentRemediation.status ===
                DocumentRemediationStatus.NEEDS_REVIEW
            ) {
              return {
                primaryCTA: 'Sign and submit addendum',
                secondaryCTA: 'Submit without changes',
              };
            } else {
              return {
                primaryCTA: 'Sign and submit addendum',
                secondaryCTA: 'Cancel',
              };
            }
          default:
            // Upload note - default (no addendum)
            return {
              primaryCTA: isDetailsConfirmed(event)
                ? 'Modify session'
                : 'Confirm session',
              primaryDisabled: !canUpdateSessionDetails,
            };
        }
      case ProgressNoteType.NONE:
        // Note saved elsewhere
        return {
          primaryCTA: canUpdateSessionDetails
            ? isDetailsConfirmed(event)
              ? 'Modify session'
              : 'Confirm session'
            : undefined,
          primaryDisabled: !canUpdateSessionDetails,
        };
      default:
        // Template Note
        switch (progressNoteState) {
          case ProgressNoteState.ADDENDUM_EDITING:
            // Template note - editing an addendum
            return {
              primaryCTA: 'Submit addendum',
              secondaryCTA: 'Cancel',
            };
          case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
            // Template note - editing a free text as addendum
            if (
              documentRemediation &&
              documentRemediation.status ===
                DocumentRemediationStatus.NEEDS_REVIEW
            ) {
              return {
                primaryCTA: 'Sign and submit addendum',
                secondaryCTA: 'Submit without changes',
              };
            } else {
              return {
                primaryCTA: 'Sign and submit addendum',
                secondaryCTA: 'Cancel',
              };
            }
          case ProgressNoteState.EDITING:
            // Template note - default (no addendum / not saved as draft / not signed)
            return {
              primaryCTA: 'Sign and submit',
              primaryDisabled: !progressNoteTemplate,
              secondaryCTA: 'Save draft',
              secondaryDisabled: !progressNoteTemplate,
            };
          case ProgressNoteState.SAVED_FOR_LATER:
            // Template note - saved as draft
            return {
              primaryCTA: isDetailsConfirmed(event)
                ? 'Modify session'
                : 'Confirm session',
              primaryDisabled: !canUpdateSessionDetails,
            };
          case ProgressNoteState.SIGNED:
            // Template note - note is signed -> time to confirm / modify session
            return {
              primaryCTA: isDetailsConfirmed(event)
                ? 'Modify session'
                : 'Confirm session',
              primaryDisabled:
                !canUpdateSessionDetails || showTreatmentPlanRequirementWarning,
              secondaryCTA: showTreatmentPlanRequirementWarning
                ? 'Create treatment plan'
                : undefined,
            };
        }
    }
  } else {
    // Future appointment being scheduled
    switch (progressNoteState) {
      case ProgressNoteState.EDITING:
        // Whatever note - default (no addendum / not saved as draft / not signed)
        return {
          primaryCTA:
            progressNoteType === ProgressNoteType.NONE
              ? 'Close and confirm later'
              : 'Save draft and close',
        };
    }
  }
  return {};
};
