/**
 * These are the Helix low level primitives. They are used to build the rest of the design system
 * but should not be used directly. Both the [MARKETING] and [WEB] themes are built on top of
 * them.
 */

export const primitives = {
  beige: {
    '100': '#FFFFF7',
    '200': '#FFFBE6',
    '300': '#FCEFC5',
  },
  blue: {
    '100': '#E8F3FF',
    '200': '#D1E7FE',
    '300': '#A2D0FE',
    '400': '#74B8FD',
    '500': '#45A1FD',
    '600': '#1789FC',
    '700': '#126ECA',
    '800': '#0E5297',
    '900': '#093765',
    '1000': '#051B32',
  },
  compliance: {
    '100': '#E7F7F0',
    '500': '#A1DDC1',
    '600': '#13AA65',
    '800': '#0B663D',
  },
  danger: {
    '100': '#FEEEEC',
    '300': '#FFBEB5',
    '500': '#FD5139',
    '800': '#9E2515',
    '900': '#7C1D12',
    '1000': '#611510',
  },
  gray: {
    '100': '#FFFFFF',
    '200': '#F8F8F8',
    '300': '#F2F2F2',
    '400': '#EBEBEB',
    '500': '#DADCE0',
    '600': '#CFCFCF',
    '700': '#BEBEBE',
    '800': '#717171',
    '850': '#4D4C4D',
    '900': '#353535',
    '1000': '#212326',
  },
  green: {
    '100': '#E7F7F0',
    '200': '#D0EEE0',
    '300': '#A1DDC1',
    '350': '#87D2B0',
    '400': '#71CCA3',
    '500': '#42BB84',
    '600': '#13AA65',
    '700': '#0F8851',
    '800': '#0B663D',
    '900': '#084428',
    '950': '#052E1B',
    '1000': '#042214',
  },
  info: {
    '100': '#E8F3FF',
    '300': '#A2D0FE',
    '600': '#1789FC',
    '800': '#0E5297',
  },
  maroon: {
    '100': '#FFF0EB',
    '200': '#FFCEBD',
    '300': '#E08A6C',
    '400': '#B84F2A',
    '500': '#AB3B13',
    '600': '#912702',
    '700': '#782002',
    '800': '#5F1800',
    '900': '#451201',
    '1000': '#2B0C01',
  },
  peach: {
    '100': '#FFF5F1',
    '200': '#FFCEBD',
    '300': '#FFD5C6',
    '400': '#FFC1A9',
    '500': '#FFAC8D',
    '600': '#FF9F7C',
    '700': '#CC795A',
    '800': '#995B43',
    '900': '#663C2D',
    '1000': '#331E16',
  },
  warning: {
    '100': '#FFF6E9',
    '300': '#FFDEB0',
    '600': '#F09E29',
    '800': '#925600',
  },
  yellow: {
    '100': '#FFF9E6',
    '200': '#FEEDB3',
    '300': '#FDE281',
    '400': '#FCDC67',
    '500': '#FBD035',
    '600': '#FFC700',
    '700': '#C89D02',
    '800': '#967601',
    '900': '#644E01',
    '1000': '#322700',
  },
};

export const colors = {
  background: {
    primary: primitives.gray['100'],
    secondary: primitives.gray['200'],
    tertiary: primitives.gray['400'],
    quartenary: primitives.gray['500'],
    disabled: primitives.gray['700'],
    'compliance-light': primitives.green['100'],
    compliance: primitives.compliance['800'],
    'compliance-hover': primitives.green['900'],
    'compliance-active': primitives.green['950'],
    selected: primitives.green['200'],
    'selected-hover': primitives.green['300'],
    'selected-active': primitives.green['350'],
    info: primitives.info['100'],
    'info-hover': primitives.info['300'],
    warning: primitives.warning['100'],
    'warning-hover': primitives.warning['300'],
    'danger-light': primitives.danger['100'],
    danger: primitives.danger['800'],
    'danger-hover': primitives.danger['900'],
    'danger-active': primitives.danger['1000'],
    'inverted-primary': primitives.gray['1000'],
    'inverted-secondary': primitives.gray['850'],
    'inverted-tertiary': primitives.gray['800'],
    beige: primitives.beige['100'],
    'beige-secondary': primitives.beige['200'],
    'beige-tertiary': primitives.beige['300'],
  },
  foreground: {
    primary: primitives.gray['900'],
    secondary: primitives.gray['800'],
    disabled: primitives.gray['700'],
    compliance: primitives.green['600'],
    'compliance-secondary': primitives.green['800'],
    'compliance-tertiary': primitives.green['900'],
    info: primitives.info['600'],
    'info-secondary': primitives.info['800'],
    warning: primitives.warning['600'],
    'warning-secondary': primitives.warning['800'],
    danger: primitives.danger['500'],
    'danger-secondary': primitives.danger['800'],
    'inverted-primary': primitives.gray['100'],
    'brand-maroon': primitives.maroon['800'],
  },
  border: {
    primary: primitives.gray['500'],
    secondary: primitives.gray['800'],
    tertiary: primitives.gray['900'],
    focus: primitives.green['800'],
    info: primitives.blue['800'],
    warning: primitives.warning['800'],
    danger: primitives.danger['800'],
    disabled: primitives.gray['300'],
  },
  pairings: {
    'green-1': {
      foreground: primitives.green['800'],
      background: primitives.green['200'],
    },
    'green-2': {
      foreground: primitives.green['600'],
      background: primitives.beige['100'],
    },
    'green-3': {
      foreground: primitives.green['600'],
      background: primitives.beige['200'],
    },
    'green-4': {
      foreground: primitives.green['600'],
      background: primitives.beige['300'],
    },
    'peach-1': {
      foreground: primitives.peach['900'],
      background: primitives.peach['500'],
    },
    'peach-2': {
      foreground: primitives.peach['900'],
      background: primitives.peach['300'],
    },
    'blue-1': {
      foreground: primitives.blue['900'],
      background: primitives.blue['300'],
    },
    'yellow-1': {
      foreground: primitives.yellow['900'],
      background: primitives.yellow['200'],
    },
    'maroon-1': {
      foreground: primitives.maroon['800'],
      background: primitives.beige['100'],
    },
    'maroon-2': {
      foreground: primitives.maroon['800'],
      background: primitives.beige['200'],
    },
    'maroon-3': {
      foreground: primitives.maroon['800'],
      background: primitives.beige['300'],
    },
  },
};
