import { pdf as renderPdf } from '@react-pdf/renderer';

import {
  AssessmentResultsPdf,
  AssessmentResultsPdfProps,
} from './AssessmentResultsPdf';

export const generateAssessmentResultsPdf = async (
  params: AssessmentResultsPdfProps
) => {
  const renderedPdf = renderPdf(<AssessmentResultsPdf {...params} />);
  const blob = await renderedPdf.toBlob();

  return blob;
};
