import { css } from '@emotion/react';
import { DeleteTwoTone, SaveAltTwoTone } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';

import { ProviderPatientAttachmentRead } from '@headway/api/models/ProviderPatientAttachmentRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { BodyText } from '@headway/helix/BodyText';
import { IconButton as HelixIconButton } from '@headway/helix/IconButton';
import { IconFileText } from '@headway/helix/icons/FileText';
import { IconTrash } from '@headway/helix/icons/Trash';
import { theme as helixTheme } from '@headway/helix/theme';
import { UploadedFile, uploadFilesToS3 } from '@headway/shared/utils/upload';
import { Dropzone, LogoLoader } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { FileInfo } from '../../utils/types';

export type Attachment<T = number> =
  | (Omit<
      ProviderPatientAttachmentRead,
      'providerPatientId' | 'createdOn' | 'id'
    > & { id: T })
  | (Omit<
      ProviderTreatmentPlanAttachmentRead,
      'providerTreatmentPlanId' | 'createdOn' | 'id'
    > & { id: T });

type AttachmentsListProps<T = number> = {
  attachments: Attachment<T>[];
  onAddAttachments?: (files: UploadedFile[]) => Promise<void>;
  onDeleteAttachment?: (attachmentId: T) => void;
  onDownloadAttachment: (attachment: Attachment<T>) => Promise<void>;
  provider: ProviderRead;
  accept?: string | string[];
  disabled?: boolean;
  isDeleteDisabled?: boolean;
  hideDropzone?: boolean;
  variant?: 'helix' | 'mui';
};

export const AttachmentsList = <T extends number | string>({
  attachments,
  onDownloadAttachment,
  onDeleteAttachment,
  onAddAttachments,
  provider,
  accept,
  disabled,
  isDeleteDisabled,
  hideDropzone,
  variant = 'mui',
}: AttachmentsListProps<T>) => {
  const [uploadingAttachments, setUploadingAttachments] = React.useState(false);

  const onDropFiles = async (files: FileInfo[]) => {
    setUploadingAttachments(true);
    const processedFiles = await uploadFilesToS3(
      files,
      UserUploadTypes.PROVIDER_UPLOADS,
      provider.id
    );
    const successfullyProcessedFiles = processedFiles.filter((file) => !!file);
    if (onAddAttachments) {
      await onAddAttachments(successfullyProcessedFiles);
    }
    setUploadingAttachments(false);
  };

  return variant === 'helix' ? (
    <div css={helixAttachmentsCss.container}>
      {!hideDropzone && (
        <Dropzone
          height={60}
          onDrop={onDropFiles}
          variant={variant}
          accept={accept}
          disabled={disabled}
        />
      )}
      {attachments && (
        <AttachmentsListHelixList
          attachments={attachments}
          onDownloadAttachment={onDownloadAttachment}
          {...(isDeleteDisabled
            ? {}
            : { onDeleteAttachment: onDeleteAttachment })}
          loading={uploadingAttachments}
        />
      )}
    </div>
  ) : (
    <div>
      <div
        css={{
          width: 'fit-content',
          '> *': {
            marginBottom: theme.space.xs,
          },
        }}
      >
        {attachments.map((attachment, idx) => {
          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              key={idx}
            >
              <span>{attachment.name}</span>
              <div css={{ marginLeft: theme.space.base, display: 'flex' }}>
                <IconButton
                  size="small"
                  disableFocusRipple
                  onClick={() => onDownloadAttachment(attachment)}
                >
                  <SaveAltTwoTone />
                </IconButton>
                {onDeleteAttachment && (
                  <IconButton
                    size="small"
                    disabled={isDeleteDisabled}
                    disableFocusRipple
                    onClick={() => onDeleteAttachment(attachment.id)}
                  >
                    <DeleteTwoTone />
                  </IconButton>
                )}
              </div>
            </div>
          );
        })}
        {uploadingAttachments && <LogoLoader size={25} />}
      </div>
      <Dropzone height={75} onDrop={onDropFiles} />
    </div>
  );
};

interface AttachmentsListHelixListProps<T> {
  attachments: Attachment<T>[];
  onDeleteAttachment?: (attachmentId: T) => void;
  onDownloadAttachment?: (attachment: Attachment<T>) => Promise<void>;
  loading?: boolean;
}

export const AttachmentsListHelixList = <T extends number | string>({
  attachments,
  onDownloadAttachment,
  onDeleteAttachment,
  loading,
}: AttachmentsListHelixListProps<T>) => (
  <ul css={helixAttachmentsCss.list}>
    {attachments.map((attachment, idx) => {
      return (
        <li css={helixAttachmentsCss.listItem} key={idx}>
          <div css={helixAttachmentsCss.listLabel}>
            <IconFileText width={30} height={30} />
            <BodyText>{attachment.name}</BodyText>
          </div>
          {onDownloadAttachment && (
            <HelixIconButton
              aria-label={`Download ${attachment.name}`}
              onPress={() => onDownloadAttachment(attachment)}
            >
              <SaveAltTwoTone />
            </HelixIconButton>
          )}
          {onDeleteAttachment && (
            <HelixIconButton
              aria-label={`Delete ${attachment.name}`}
              onPress={() => onDeleteAttachment(attachment.id)}
            >
              <IconTrash />
            </HelixIconButton>
          )}
        </li>
      );
    })}
    {loading && (
      <div css={helixAttachmentsCss.listItem}>
        <div css={helixAttachmentsCss.spinner}>
          <CircularProgress color="inherit" size={24} />
        </div>
      </div>
    )}
  </ul>
);

const helixAttachmentsCss = {
  container: css({
    borderRadius: helixTheme.spacing.x1,
    border: `1px solid ${helixTheme.color.system.borderGray}`,
    padding: helixTheme.spacing.x2,
    width: '100%',
  }),
  list: css({
    padding: 0,
    margin: 0,
  }),
  listItem: css({
    display: 'flex',
    gap: helixTheme.spacing.x2,
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 'auto',
    },
    padding: helixTheme.spacing.x2,
  }),
  listLabel: css({
    display: 'flex',
    alignItems: 'center',
    gap: helixTheme.spacing.x2,
  }),
  spinner: css({
    textAlign: 'center',
  }),
};
