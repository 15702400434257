import {
  CalendarDate,
  DateValue,
  GregorianCalendar,
  TimeFields,
} from '@internationalized/date';
import { Alert, Tooltip } from '@mui/material';
import { TimeValue } from '@react-types/datepicker';
import { useExistingVerificationRequest } from 'hooks';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { BillingType } from '@headway/api/models/BillingType';
import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';
import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderAppointmentUpdate } from '@headway/api/models/ProviderAppointmentUpdate';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { Checkbox } from '@headway/helix/Checkbox';
import { Item } from '@headway/helix/collections';
import { CurrencyField } from '@headway/helix/CurrencyField';
import { DatePickerField } from '@headway/helix/DatePickerField';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconInfo } from '@headway/helix/icons/Info';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Section, Select } from '@headway/helix/Select';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';
import { TimeField } from '@headway/helix/TimeField';
import {
  CONTROLLED_SUBSTANCE_DISPLAY_NAMES,
  MAX_DOSAGE,
} from '@headway/shared/constants/controlledSubstances';
import {
  CONTROLLED_SUBSTANCE_DATA_COLLECTION,
  MULTI_STATE_CREDENTIALING_BETA,
  PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
  TELEHEALTH_LOCATIONS_SIGMUND,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { CptCodeOption } from '@headway/ui/CPTCodeInput';
import { DiagnosisCodeOption } from '@headway/ui/DiagnosisCodeInput';
import { FormRow } from '@headway/ui/form';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { useFindPastSessionsQueryKeyArgs } from 'hooks/useFindPastSessionsQueryKeyArgs';
import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';
import { convertTimeFieldsToDateString } from 'utils/date';
import { NullableKeys } from 'utils/types';
import { isPast } from 'views/Calendar/events/util/events';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';
import { getAuditResult } from 'views/Home/utils';

import { AppointmentConfirmationModalFormV2Values } from '../../AppointmentConfirmationModalV2';
import { AppointmentContext } from '../../stores/AppointmentContext';
import { ProgressNoteContext } from '../../stores/ProgressNotesContextV2';
import { isBillingAddOnPsychotherapy } from '../../util';
import { CPTCodeComboBoxV2 } from '../FormComponents/CptCodeComboBoxV2';
import { DiagnosisCodeComboBoxV2 } from '../FormComponents/DiagnosisCodeComboBoxV2';
import { InsuranceStatusBanner } from '../FormComponents/InsuranceStatusBanner';
import { InsuranceStatusBannerForWarning } from '../FormComponents/InsuranceStatusBannerForWarning';
import { PatientAddressDropdownHelixV2 } from '../FormComponents/PatientAddressDropdownHelixV2';
import { TelehealthLocationsInputsV2 } from '../FormComponents/TelehealthLocationsInputsV2';
import { TemplateInfo } from '../ProgressNotes/templateView/schemaRenderer/versions/types';
import { ControlledSubstanceCombobox } from './ControlledSubstanceCombobox';
import {
  CodingError,
  CodingErrorLocation,
  useMiscodingResultsQuery,
  ValidationWarning,
  ValidationWarningLocation,
} from './FormValidation/miscoding';
import {
  checkIfExactDurationIsRequired,
  validationSchema,
} from './FormValidation/validationSchema';
import { ValidationWarningAlert } from './FormValidation/ValidationWarningAlert';
import { NonEditableSessionDetailsSection } from './NonEditableSessionDetailsSection';

export type SessionDetailsFormV2Props = {
  provider: ProviderRead;
  patient: UserRead;
  onOpenContactFormInsuranceIssues: () => void;
  onNewLocationClicked: () => void;
};

export type SessionDetailsFormV2Values = {
  providerAddressId?: number | null;
  appointmentLocationPatientAddressId?: number | null;
  startDate: string;
  endDate: string;
  duration?: string;
  exactStartTime?: TimeFields;
  exactEndTime?: TimeFields;
  billingType?: string;
  patientResponsibilityAmount?: number;
  cptCodes?: any[]; // Replace any[] with a specific type, if known
  diagnosisCodes?: any[]; // Replace any[] with a specific type, if known
  telehealth?: boolean;
  telehealthPlatform?: TelehealthPlatform;
  telehealthProviderState?: UnitedStates;
  telehealthAttestation?: boolean;
  didPrescribeControlledSubstance?: boolean;
  controlledSubstanceAttestation: {
    prescribedSubstances?: string[];
    notes?: string;
    exceedsRecommendedDosage?: boolean;
  };
  prescriberPsychotherapyStartTime?: TimeFields;
  prescriberPsychotherapyEndTime?: TimeFields;
};

export const isSessionDetailsFormValueTelehealth = (
  providerAddressId: SessionDetailsFormV2Values['providerAddressId']
) => providerAddressId === -1;

export const isFormValueTelehealthAndBilledWithInsuranceOrEAP = (
  providerAddressId: SessionDetailsFormV2Values['providerAddressId'],
  billingType: SessionDetailsFormV2Values['billingType']
) =>
  isSessionDetailsFormValueTelehealth(providerAddressId) &&
  (billingType === BillingType.INSURANCE || billingType === BillingType.EAP);

export const convertFormValuesToProviderEventUpdate = (
  rawValues: SessionDetailsFormV2Values
) => {
  return {
    startDate: new Date(rawValues.startDate).toISOString(),
    endDate: moment(rawValues.startDate)
      .add(rawValues.duration, 'minutes')
      .toISOString(),
    ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
      ? {
          providerAddressId: null,
          telehealth: true,
        }
      : {
          providerAddressId: rawValues.providerAddressId
            ? rawValues.providerAddressId
            : null,
          telehealth: false,
        }),
    providerAppointment: {
      billingType: rawValues.billingType,
      patientResponsibilityAmount:
        rawValues.billingType === BillingType.SELF_PAY
          ? rawValues.patientResponsibilityAmount
          : undefined,
      cptCodes: rawValues?.cptCodes?.map((code: CptCodeOption) => code?.value),
      diagnosisCodes: rawValues?.diagnosisCodes?.map(
        (code: DiagnosisCodeOption) => code?.value
      ),
      exactStartTime: rawValues.exactStartTime
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.exactStartTime
          )
        : undefined,
      exactEndTime: rawValues.exactEndTime
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.exactEndTime
          )
        : undefined,
      // convert the form representation of address and telehealth
      ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
        ? {
            providerAddressId: null,
            telehealth: true,
            appointmentLocationPatientAddressId:
              rawValues.appointmentLocationPatientAddressId,
            telehealthPlatform: rawValues.telehealthPlatform,
            telehealthProviderState: rawValues.telehealthProviderState,
            telehealthAttestation: rawValues.telehealthAttestation,
          }
        : {
            providerAddressId: rawValues.providerAddressId
              ? rawValues.providerAddressId
              : null,
            telehealth: false,
            appointmentLocationPatientAddressId: null,
            telehealthPlatform: null,
            telehealthProviderState: null,
            telehealthAttestation: null,
          }),
      ...(rawValues.didPrescribeControlledSubstance
        ? {
            controlledSubstanceAttestation:
              rawValues.controlledSubstanceAttestation,
          }
        : rawValues.didPrescribeControlledSubstance === false
        ? {
            controlledSubstanceAttestation: {
              prescribedSubstances: [],
              notes: null,
              exceedsRecommendedDosage: null,
            },
          }
        : {}),
      prescriberPsychotherapyStartTime:
        !rawValues.prescriberPsychotherapyStartTime
          ? undefined
          : isBillingAddOnPsychotherapy(
              rawValues.cptCodes?.map((code) => code.value) || []
            )
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.prescriberPsychotherapyStartTime
            )
          : null,
      prescriberPsychotherapyEndTime: !rawValues.prescriberPsychotherapyEndTime
        ? undefined
        : isBillingAddOnPsychotherapy(
            rawValues.cptCodes?.map((code) => code.value) || []
          )
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.prescriberPsychotherapyEndTime
          )
        : null,
    } as NullableKeys<ProviderAppointmentUpdate> as ProviderAppointmentUpdate,
  } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate;
};

function momentToDateValue(momentDate: Moment): DateValue {
  // Create a CalendarDate directly from Moment’s year, month, and day
  return new CalendarDate(
    new GregorianCalendar(),
    momentDate.year(),
    momentDate.month() + 1, // Moment months are 0-indexed, CalendarDate months are 1-indexed
    momentDate.date()
  );
}

function dateValueToMoment(dateValue: DateValue): Moment {
  return moment({
    year: dateValue.year,
    month: dateValue.month - 1, // Moment months are 0-indexed
    day: dateValue.day,
  });
}

export const getSessionDetailsValidationSchema = (
  provider: ProviderRead,
  progressNoteType: ProgressNoteType,
  selectedTemplate?: TemplateInfo,
  exactDurationRequired?: boolean,
  isMSCOrGuardrailRestrictionEnabled?: boolean,
  isTelehealthLocationsEnabled?: boolean,
  isControlledSubstanceDataCollectionEnabled?: boolean,
  isPrescriberPsychotherapyTimesEnabled?: boolean
) => {
  return validationSchema(
    provider,
    progressNoteType,
    selectedTemplate,
    exactDurationRequired,
    isMSCOrGuardrailRestrictionEnabled,
    isTelehealthLocationsEnabled,
    isControlledSubstanceDataCollectionEnabled,
    isPrescriberPsychotherapyTimesEnabled
  );
};

export const SessionDetailsFormV2 = ({
  provider,
  patient,
  onOpenContactFormInsuranceIssues,
  onNewLocationClicked,
}: SessionDetailsFormV2Props) => {
  const {
    updateAppointmentAddressState,
    setValuesForModifySessionState,
    ...appointmentContext
  } = useContext(AppointmentContext);
  const { progressNoteType, selectedTemplate, documentRemediation, event } =
    useContext(ProgressNoteContext);
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const sessionDetailsValues: SessionDetailsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'sessionDetails',
    });
  const [patientHasCignaEapAuthorization, setPatientHasCignaEapAuthorization] =
    useState<boolean>(false);

  const { providerAddresses } = useContext(ProviderAddressContext);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isTelehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );

  const prescriberPsychotherapyTimesCarriers = useFlag(
    PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
    []
  );

  const eventDatePart = moment(event?.startDate).format('YYYY-MM-DD');
  const fiveMinuteIntervals = useMemo(() => {
    const baseDate = moment(eventDatePart).startOf('day');
    return Array.from({ length: 288 }, (_, index) =>
      baseDate
        .clone()
        .add(index * 5, 'minutes')
        .toDate()
    );
  }, [eventDatePart]);

  const commonTimes = [15, 30, 45, 60];
  const allTimes = useMemo(() => {
    const commonTimes_ = [15, 30, 45, 60];
    const excludedTimes = new Set(commonTimes_);
    return Array.from({ length: 24 }, (_, idx) => (idx + 1) * 5).filter(
      (time) => !excludedTimes.has(time)
    );
  }, []);

  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: event?.providerAppointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  // We set the location to null each time the provider
  // opens up the modal to assure that they pick the corect
  // location when they confirm the session.
  // If the session is confirmed, we use the value stored in the DB.
  const getLocationFieldValue = () => {
    /*if (pickedLocation) {
      return pickedLocation;
    }*/

    if (
      event?.providerAppointment?.status !==
      ProviderAppointmentStatus.DETAILS_CONFIRMED
    ) {
      return undefined;
    }

    return event?.telehealth
      ? -1
      : event?.providerAddressId ||
          providerAddresses.filter(
            (address: ProviderAddressRead) => address.isActive
          )[0]?.id;
  };

  const pastSessionsQueryKeyArgs = useFindPastSessionsQueryKeyArgs({
    providerId: provider.id,
    clientId: patient.id,
    limit: 10,
  });
  const { data: hasExistingVerificationRequest } =
    useExistingVerificationRequest(patient);
  const { data: lastConfirmedTelehealthAppointment } = useFindProviderEvents(
    pastSessionsQueryKeyArgs,
    {
      select: ({ data }) =>
        data?.find((appointment) => appointment.telehealth === true)
          ?.providerAppointment,
      enabled:
        (isMSCEnabled ||
          isMSCGuardrailRestriction ||
          isTelehealthLocationsEnabled) &&
        !event?.providerAppointment?.appointmentLocationPatientAddressId &&
        event?.providerAppointment?.status ===
          ProviderAppointmentStatus.SCHEDULED,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        logException(error);
      },
    }
  );

  //Only prepopulate patient address for telehealth appts
  const initialProviderAddressId = event?.telehealth
    ? -1
    : getLocationFieldValue();
  const initialAppointmentLocationPatientAddressId =
    event?.providerAppointment?.status === ProviderAppointmentStatus.SCHEDULED
      ? event?.providerAppointment?.appointmentLocationPatientAddressId ??
        lastConfirmedTelehealthAppointment?.appointmentLocationPatientAddressId
      : getLocationFieldValue() === -1
      ? event?.providerAppointment?.appointmentLocationPatientAddressId
      : undefined;

  useEffect(() => {
    updateAppointmentAddressState(
      initialProviderAddressId,
      initialAppointmentLocationPatientAddressId
    );
  }, [
    initialProviderAddressId,
    initialAppointmentLocationPatientAddressId,
    updateAppointmentAddressState,
  ]);

  const matchingCarrierId = matchingProviderFrontEndCarrier?.frontEndCarrierId;
  const isPrescriberPsychotherapyTimesEnabled =
    prescriberPsychotherapyTimesCarriers?.includes(matchingCarrierId);

  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );

  const { data: miscodingResults } = useMiscodingResultsQuery(
    sessionDetailsValues?.cptCodes || [],
    sessionDetailsValues?.diagnosisCodes || [],
    sessionDetailsValues?.exactStartTime,
    sessionDetailsValues?.exactEndTime,
    patient,
    provider,
    event!,
    sessionDetailsValues?.prescriberPsychotherapyStartTime,
    sessionDetailsValues?.prescriberPsychotherapyEndTime
  );
  const validationWarnings = miscodingResults?.warnings;
  const appointmentCodingErrors = miscodingResults?.errors;

  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  const shouldRequirePrescriberPsychotherapyTimes =
    isPrescriberPsychotherapyTimesEnabled &&
    provider.isPrescriber &&
    isBillingAddOnPsychotherapy(
      sessionDetailsValues?.cptCodes?.map((code) => code.value) || []
    );

  // ensure psychotherapy time window is within appointment window by requiring exact appointment start and end time
  const isExactDurationRequired =
    !!isDocumentationRequired ||
    checkIfExactDurationIsRequired(progressNoteType, selectedTemplate) ||
    shouldRequirePrescriberPsychotherapyTimes;

  const documentNeedsReviewRemediation =
    documentRemediation &&
    documentRemediation.status === DocumentRemediationStatus.NEEDS_REVIEW;
  const auditResultPerRemediation = getAuditResult(documentRemediation);

  const conflictingSessionError =
    appointmentCodingErrors?.length! > 0
      ? appointmentCodingErrors?.filter(
          (error) => error.preferredLocation === 'SESSION_TIME'
        )
      : [];

  // Filter out overlapping conflicting session errors so it does not show in the diagnosis code errors
  // Filter out coding cardinality errors as they will show up in field level errors
  const cardinalityErrorTypes = [
    CodingEnforcementErrorType.NO_CPT_CODES,
    CodingEnforcementErrorType.NO_DIAGNOSIS_CODES,
  ];
  const filteredAppointmentCodingErrors = appointmentCodingErrors?.filter(
    (error) =>
      error.preferredLocation !== 'SESSION_TIME' &&
      !cardinalityErrorTypes.includes(error.type)
  );

  const shouldShowAppointmentCodingErrors =
    filteredAppointmentCodingErrors?.length! > 0 &&
    isPast(event!) &&
    (sessionDetailsValues?.cptCodes?.length ||
      sessionDetailsValues?.diagnosisCodes?.length);

  // If every coding error is related to CPT codes, then show it underneath the CPT code input; otherwise show it underneath the DX code input.
  const appointmentCodingErrorsLocation =
    filteredAppointmentCodingErrors?.every(
      (error) => error.preferredLocation === CodingErrorLocation.CPT_CODES
    )
      ? CodingErrorLocation.CPT_CODES
      : CodingErrorLocation.DIAGNOSIS_CODES;

  const getValidationWarningAlert = (
    validationWarning: ValidationWarning,
    key: number
  ) =>
    hasExistingVerificationRequest ? (
      <></>
    ) : (
      <ValidationWarningAlert
        onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
        validationWarning={validationWarning}
        id={key}
      />
    );

  if (documentNeedsReviewRemediation || auditResultPerRemediation)
    return (
      <NonEditableSessionDetailsSection
        sessionDate={moment(sessionDetailsValues?.startDate).format(
          'MMM Do, YYYY'
        )}
        sessionStartTime={moment(sessionDetailsValues?.startDate).format(
          'h:mma'
        )}
        sessionDuration={`${moment(sessionDetailsValues?.endDate).diff(
          sessionDetailsValues?.startDate,
          'minutes'
        )} minutes`}
        sessionExactStartTime={moment(
          sessionDetailsValues?.exactStartTime ??
            sessionDetailsValues?.startDate
        ).format('h:mma')}
        sessionExactStopTime={moment(
          sessionDetailsValues?.exactEndTime ?? sessionDetailsValues?.endDate
        ).format('h:mma')}
        sessionLocation={
          sessionDetailsValues?.providerAddressId === -1
            ? 'Virtual (Telehealth)'
            : providerAddresses.filter(
                (item) => item.id === sessionDetailsValues?.providerAddressId
              )[0].address
        }
        sessionCptCodes={sessionDetailsValues?.cptCodes}
        sessionDiagnosisCodes={sessionDetailsValues?.diagnosisCodes}
      />
    );

  return (
    <>
      {conflictingSessionError && conflictingSessionError.length > 0 && (
        <div css={{ marginBottom: theme.spacing.x6 }}>
          <GuidanceCard variant="error">
            <BodyText>
              <strong>Conflicting session during this time: </strong>
              {conflictingSessionError[0].message}
            </BodyText>
          </GuidanceCard>
        </div>
      )}
      {hasExistingVerificationRequest &&
        validationWarnings?.filter((item) => item.showVerificationEntryPoint)
          .length! > 0 && (
          <Alert
            color="warning"
            severity="error"
            css={{ marginBottom: theme.spacing.x1 }}
          >
            We’re still verifying benefits related to this session. We suggest
            waiting to hear back from us before confirming session details in
            case {patient?.activeUserInsurance?.billingFrontEndCarrierName}{' '}
            denies the claim.
          </Alert>
        )}
      {
        <div className="mt-8 flex flex-col gap-5">
          <FormRow>
            <Controller
              control={control}
              disabled={!canUpdateSessionDetails}
              name={'sessionDetails.startDate'}
              render={({ field: { onChange, onBlur, value } }) => (
                <DatePickerField
                  name={'sessionDetails.startDate'}
                  label="Date"
                  onChange={(dateValue: DateValue) =>
                    onChange(dateValueToMoment(dateValue))
                  }
                  onBlur={onBlur}
                  value={value ? momentToDateValue(moment(value)) : undefined}
                />
              )}
            />
            <Controller
              control={control}
              disabled={!canUpdateSessionDetails}
              name={'sessionDetails.startDate'}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  selectionMode="single"
                  label={'Scheduled start time'}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.startDate'}
                  menuWidth="stretch"
                  onBlur={onBlur}
                  selectedKeys={
                    value ? new Set([moment(value).toISOString()]) : new Set()
                  }
                  onSelectionChange={(selected) => {
                    const selectedDate = Array.isArray(selected)
                      ? moment(selected[0]).toISOString()
                      : moment(Array.from(selected)[0]).toISOString();
                    onChange(selectedDate);
                  }}
                >
                  {fiveMinuteIntervals.map((interval) => {
                    const intervalDate = moment(interval);
                    const label = intervalDate.format('h:mma');
                    const key = intervalDate.toISOString();

                    return (
                      <Item key={key} textValue={label}>
                        {label}
                      </Item>
                    );
                  })}
                </Select>
              )}
            />
            <Controller
              control={control}
              disabled={!canUpdateSessionDetails}
              name={'sessionDetails.duration'}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  selectionMode="single"
                  label={'Scheduled duration'}
                  name={'sessionDetails.duration'}
                  menuWidth="stretch"
                  disabled={!canUpdateSessionDetails}
                  onBlur={onBlur}
                  selectedKeys={value ? new Set([value]) : new Set()}
                  onSelectionChange={(selected) => {
                    const selectedValue =
                      selected instanceof Set && selected.size > 0
                        ? Array.from(selected)[0]
                        : '';
                    onChange(selectedValue);
                  }}
                >
                  <Section title="Common times">
                    {commonTimes.map((time) => (
                      <Item key={time.toString()} textValue={`${time} minutes`}>
                        {time} minutes
                      </Item>
                    ))}
                  </Section>
                  <Section title="All times">
                    {allTimes.map((time) => (
                      <Item key={time.toString()} textValue={`${time} minutes`}>
                        {time} minutes
                      </Item>
                    ))}
                  </Section>
                </Select>
              )}
            />
          </FormRow>
          <div data-intercom-target="Exact Session Time">
            <FormRow>
              <div
                data-testid="actualStartTime"
                css={{ width: '100%', position: 'relative' }}
              >
                <Controller
                  control={control}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.exactStartTime'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TimeField
                      label={'Actual start time'}
                      disabled={!canUpdateSessionDetails}
                      name={'sessionDetails.exactStartTime'}
                      helpText="Enter the actual start time, not just what was booked. Eg 10:02 AM"
                      optionalityText={
                        isExactDurationRequired ? '' : 'Optional'
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as TimeValue | undefined}
                    />
                  )}
                />
                {canUpdateSessionDetails && (
                  <div
                    css={{
                      position: 'absolute',
                      top: '-3px',
                      ...(isExactDurationRequired
                        ? { left: '100px' }
                        : { left: '160px' }),
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      title="Start/stop times help ensure compliance with coding standards"
                      arrow
                    >
                      <div css={{ width: 'inherit', height: 'inherit' }}>
                        <IconInfo />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div
                data-testid="actualStopTime"
                css={{ width: '100%', position: 'relative' }}
              >
                <Controller
                  control={control}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.exactEndTime'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TimeField
                      label={'Actual stop time'}
                      disabled={!canUpdateSessionDetails}
                      name={'sessionDetails.exactEndTime'}
                      helpText="Enter the actual stop time, not just what was booked. Eg 10:58 AM"
                      optionalityText={
                        isExactDurationRequired ? '' : 'Optional'
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as TimeValue | undefined}
                    />
                  )}
                />
                {canUpdateSessionDetails && (
                  <div
                    css={{
                      position: 'absolute',
                      top: '-3px',
                      ...(isExactDurationRequired
                        ? { left: '100px' }
                        : { left: '160px' }),
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      title="Start/stop times help ensure compliance with coding standards"
                      arrow
                    >
                      <div css={{ width: 'inherit', height: 'inherit' }}>
                        <IconInfo />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </FormRow>
          </div>
          <Controller
            control={control}
            disabled={!canUpdateSessionDetails}
            name={'sessionDetails.providerAddressId'}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                selectionMode="single"
                label={'Session Location'}
                disabled={!canUpdateSessionDetails}
                name={'sessionDetails.providerAddressId'}
                menuWidth="stretch"
                onBlur={onBlur}
                helpText={
                  "Remember to confirm the client's identity, location and physical safety at the start of the session"
                }
                onSelectionChange={(items: Set<string>) => {
                  const selectedValue = parseInt(Array.from(items)[0]);
                  onChange(selectedValue);
                }}
                selectedKeys={value ? [value.toString()] : []}
              >
                {providerAddresses
                  .filter(
                    (address) => !!address.streetLine1 && address.isActive
                  )
                  .map((address) => (
                    <Item
                      key={address.id}
                      textValue={`${address.streetLine1}, ${address.city}, ${address.state}`}
                    >
                      {address.streetLine1}, {address.city}, {address.state}
                    </Item>
                  ))
                  .concat(
                    <Item key={-1} textValue="Virtual (Telehealth)">
                      Virtual (Telehealth)
                    </Item>
                  )}
              </Select>
            )}
          />
          {isTelehealthLocationsEnabled &&
            isFormValueTelehealthAndBilledWithInsuranceOrEAP(
              sessionDetailsValues?.providerAddressId,
              sessionDetailsValues?.billingType
            ) && (
              <TelehealthLocationsInputsV2
                canUpdateSessionDetails={canUpdateSessionDetails}
              />
            )}
          {(isMSCEnabled ||
            isMSCGuardrailRestriction ||
            isTelehealthLocationsEnabled) &&
            isFormValueTelehealthAndBilledWithInsuranceOrEAP(
              sessionDetailsValues?.providerAddressId,
              sessionDetailsValues?.billingType
            ) && (
              <PatientAddressDropdownHelixV2
                patient={patient}
                canUpdateSessionDetails={canUpdateSessionDetails}
                onNewLocationClicked={onNewLocationClicked}
              />
            )}

          {(isMSCEnabled || isMSCGuardrailRestriction) && (
            <InsuranceStatusBanner
              patient={patient}
              sessionDetailsFormValues={sessionDetailsValues}
            />
          )}
          {isMSCGuardrailWarning && (
            <InsuranceStatusBannerForWarning
              patient={patient}
              sessionDetailsFormValues={sessionDetailsValues}
            />
          )}
          {isTelehealthLocationsEnabled &&
            isFormValueTelehealthAndBilledWithInsuranceOrEAP(
              sessionDetailsValues?.providerAddressId,
              sessionDetailsValues?.billingType
            ) && (
              <Controller
                control={control}
                disabled={!canUpdateSessionDetails}
                name={'sessionDetails.telehealthAttestation'}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    disabled={!canUpdateSessionDetails}
                    name={'sessionDetails.telehealthAttestation'}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                  >
                    I have assessed that {patientFirstName} is in a safe,
                    private, and known location.
                  </Checkbox>
                )}
              />
            )}
          {sessionDetailsValues?.billingType === BillingType.SELF_PAY && (
            <Controller
              control={control}
              disabled={!canUpdateSessionDetails}
              name={'sessionDetails.patientResponsibilityAmount'}
              render={({ field: { onChange, onBlur, value } }) => (
                <CurrencyField
                  label={'Session Rate'}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.patientResponsibilityAmount'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          )}
          <CPTCodeComboBoxV2
            name={'sessionDetails.cptCodes'}
            patient={patient}
            provider={provider}
            patientHasCignaEapAuthorization={patientHasCignaEapAuthorization}
            searchable
            selectionMode="multiple"
            disabled={!canUpdateSessionDetails}
          />
          {isPrescriberPsychotherapyTimesEnabled &&
            provider.isPrescriber &&
            isBillingAddOnPsychotherapy(
              sessionDetailsValues?.cptCodes?.map((code) => code.value) || []
            ) && (
              <FormRow>
                <Controller
                  control={control}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.prescriberPsychotherapyStartTime'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TimeField
                      label={'Psychotherapy start time'}
                      disabled={!canUpdateSessionDetails}
                      name={'sessionDetails.prescriberPsychotherapyStartTime'}
                      helpText="When the psychotherapy portion of the session began"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as TimeValue | undefined}
                    />
                  )}
                />
                <Controller
                  control={control}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.prescriberPsychotherapyEndTime'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TimeField
                      label={'Psychotherapy stop time'}
                      disabled={!canUpdateSessionDetails}
                      name={'sessionDetails.prescriberPsychotherapyEndTime'}
                      helpText="When the psychotherapy portion of the session ended"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as TimeValue | undefined}
                    />
                  )}
                />
              </FormRow>
            )}
          {shouldShowAppointmentCodingErrors
            ? appointmentCodingErrorsLocation ===
                CodingErrorLocation.CPT_CODES &&
              filteredAppointmentCodingErrors && (
                <MiscodingErrorsGuidanceCard
                  errors={filteredAppointmentCodingErrors}
                />
              )
            : null}

          <DiagnosisCodeComboBoxV2
            helpText={
              'Select multiple if applicable, with the primary diagnosis selected first'
            }
            name="sessionDetails.diagnosisCodes"
            searchable
            selectionMode="multiple"
            disabled={!canUpdateSessionDetails}
          />

          {validationWarnings
            ? validationWarnings
                .filter(
                  ({ location }) => location === ValidationWarningLocation.CODES
                )
                .map((warning, i) => getValidationWarningAlert(warning, i))
            : null}
          {shouldShowAppointmentCodingErrors
            ? appointmentCodingErrorsLocation ===
                CodingErrorLocation.DIAGNOSIS_CODES &&
              filteredAppointmentCodingErrors && (
                <MiscodingErrorsGuidanceCard
                  errors={filteredAppointmentCodingErrors}
                />
              )
            : null}
          {validationWarnings
            ? validationWarnings
                .filter(
                  ({ location }) =>
                    location === ValidationWarningLocation.SUBMIT
                )
                .map((warning, i) => getValidationWarningAlert(warning, i))
            : null}
          {isControlledSubstanceDataCollectionEnabled &&
            provider.isPrescriber && (
              <>
                <Controller
                  control={control}
                  disabled={!canUpdateSessionDetails}
                  name={'sessionDetails.didPrescribeControlledSubstance'}
                  render={({ field: { onChange, onBlur } }) => (
                    <RadioGroup
                      name="sessionDetails.didPrescribeControlledSubstance"
                      onBlur={onBlur}
                      label={
                        <>
                          Were any controlled substances prescribed for the
                          client?
                          <span className="ml-1">
                            <GlossaryTerm term="Why Headway is asking for this">
                              To protect you, your patients, and Headway as a
                              network, collecting this information helps us
                              ensure compliant practices. It also allows us to
                              identify increased risk of scrutiny by the DEA so
                              that we can support you. This information is not a
                              part of the official patient medical record. All
                              clinical care provided must continue to be
                              documented in your session notes.
                            </GlossaryTerm>
                          </span>
                        </>
                      }
                      onChange={(value) => {
                        onChange(value === 'true' ? true : false);
                      }}
                      value={String(
                        sessionDetailsValues?.didPrescribeControlledSubstance
                      )}
                    >
                      <Radio value="false">No</Radio>
                      <Radio value="true">
                        Yes, the client received a prescription for a new
                        substance, had an updated dosage, or had their existing
                        prescription renewed/maintained this session (or since
                        last confirmed session)
                      </Radio>
                    </RadioGroup>
                  )}
                />
                {sessionDetailsValues?.didPrescribeControlledSubstance && (
                  <>
                    <Controller
                      name="sessionDetails.controlledSubstanceAttestation.prescribedSubstances"
                      disabled={!canUpdateSessionDetails}
                      control={control}
                      render={({ field: { onChange, onBlur } }) => (
                        <ControlledSubstanceCombobox
                          onBlur={onBlur}
                          label="Which substance(s)?"
                          name="sessionDetails.controlledSubstanceAttestation.prescribedSubstances"
                          onSelectionChange={(keys: Set<string>) => {
                            onChange(Array.from(keys));
                          }}
                          selectedKeys={
                            new Set(
                              sessionDetailsValues
                                ?.controlledSubstanceAttestation
                                ?.prescribedSubstances
                            )
                          }
                        />
                      )}
                    />
                    {!!sessionDetailsValues?.controlledSubstanceAttestation
                      ?.prescribedSubstances &&
                      sessionDetailsValues?.controlledSubstanceAttestation?.prescribedSubstances.filter(
                        (substance) => substance !== ControlledSubstance.OTHER
                      ).length > 0 && (
                        <div>
                          <CaptionText>
                            <strong>
                              Highest{' '}
                              <GlossaryTerm term="recommended dose">
                                <div>
                                  Dosage amounts shown are based on clinical
                                  review of existing FDA approved maximum daily
                                  dosages and expert consensus recommendations
                                  for management of neuropsychiatric conditions.
                                </div>
                                <br />
                                <div>
                                  Prescribing above the dosages indicated may
                                  increase risk of adverse events and should be
                                  done with caution, clinical reasoning, and
                                  informed concent appropriately documented.
                                </div>
                              </GlossaryTerm>{' '}
                              per day
                            </strong>
                          </CaptionText>
                          <ul className="hlx-typography-subbody mb-0 mt-1 pl-6">
                            {sessionDetailsValues?.controlledSubstanceAttestation?.prescribedSubstances
                              .filter(
                                (substance) =>
                                  substance !== ControlledSubstance.OTHER
                              )
                              .map((substance) => (
                                <li key={substance}>
                                  {
                                    CONTROLLED_SUBSTANCE_DISPLAY_NAMES[
                                      substance as Exclude<
                                        ControlledSubstance,
                                        ControlledSubstance.OTHER
                                      >
                                    ]
                                  }
                                  :{' '}
                                  {
                                    MAX_DOSAGE[
                                      substance as Exclude<
                                        ControlledSubstance,
                                        ControlledSubstance.OTHER
                                      >
                                    ]
                                  }
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    <Controller
                      name="sessionDetails.controlledSubstanceAttestation.exceedsRecommendedDosage"
                      disabled={!canUpdateSessionDetails}
                      control={control}
                      render={({ field: { onChange, onBlur } }) => (
                        <RadioGroup
                          name="sessionDetails.controlledSubstanceAttestation.exceedsRecommendedDosage"
                          label="Did the client receive a prescription of any controlled substance with a higher dose per day than the recommended amount?"
                          onBlur={onBlur}
                          onChange={(value) => {
                            onChange(value === 'true' ? true : false);
                          }}
                          value={String(
                            sessionDetailsValues?.controlledSubstanceAttestation
                              ?.exceedsRecommendedDosage
                          )}
                        >
                          <Radio value="false">No</Radio>
                          <Radio value="true">Yes (provide explanation)</Radio>
                        </RadioGroup>
                      )}
                    />
                    <Controller
                      disabled={!canUpdateSessionDetails}
                      name="sessionDetails.controlledSubstanceAttestation.notes"
                      render={({ field: { onBlur, onChange, value } }) => (
                        <TextArea
                          value={value}
                          onBlur={onBlur}
                          name="sessionDetails.controlledSubstanceAttestation.notes"
                          label="Include frequency, dosage, and any other relevant notes for each substance"
                          onChange={onChange}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}
        </div>
      }
    </>
  );
};

const MiscodingErrorsGuidanceCard = ({ errors }: { errors: CodingError[] }) => (
  <div>
    <GuidanceCard key={'error'} variant={'error'}>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ ...theme.typography.list }}>
          Please fix the following{' '}
          {errors.length > 1 ? `${errors.length} errors` : 'error'}:
        </span>
        <ul>
          {errors.map((error, idx) => (
            <li key={idx}>{error.message}</li>
          ))}
        </ul>
      </div>
    </GuidanceCard>
  </div>
);
