import React from 'react';

import { TextArea } from '@headway/helix/TextArea';

import { SchemaComponent } from '.';
import { getInstructionalText } from '../../utils';
import { ProgressNoteComponentMetadata } from '../versions/3/types';
import { FormFieldWrapper } from './wrapper/FormFieldWrapper';

const MemoizedTextArea = React.memo(TextArea);

const EXTENDED_HEIGHT_ROWS = 6;
const MAX_ROWS = 25;

export const FormTextArea: React.FC<SchemaComponent> = ({
  element: { id, title, placeholder, subTitle, description, metadata = {} },
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}) => {
  const { extendedTextArea } = metadata as ProgressNoteComponentMetadata;
  const sizing = React.useMemo(
    () => ({
      max: MAX_ROWS,
      min: extendedTextArea ? EXTENDED_HEIGHT_ROWS : undefined,
    }),
    [extendedTextArea]
  );

  return (
    <FormFieldWrapper name={id} disabled={disabled}>
      {({ onChange, onBlur, value, validation, disabled }) => (
        <MemoizedTextArea
          label={title}
          name={id}
          key={id}
          optionalityText={isOptional ? 'Optional' : ''}
          placeholder={placeholder}
          instructionalText={getInstructionalText(subTitle, { requiresEdit })}
          helpText={description}
          sizing={sizing}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          aria-label="Progress-notes text area"
          validation={validation}
          disabled={disabled}
        />
      )}
    </FormFieldWrapper>
  );
};
