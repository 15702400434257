import { css } from '@emotion/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireType } from '@headway/api/models/ProviderQuestionnaireType';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { theme } from '@headway/helix/theme';
import { IdentifyFlagsUser } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { logException } from '@headway/shared/utils/sentry';

import { isAdminImpersonatingProviderUser } from 'utils/access';
import { getLDAttributes } from 'utils/analytics';

import { Header } from '../components/Header/Header';
import { ProviderSelect } from '../components/Header/ProviderSelect';
import { useAuthStore } from '../stores/AuthStore';
import { QuestionnaireV2 } from '../views/IntakeQuestionnaireV2/QuestionnaireV2';

export function QuestionnaireV2Layout() {
  const auth = useAuthStore();
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<ProviderQuestionnaireReadV2>();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const createNewQuestionnaire =
    (queryParams.get('newQuestionnaire') || '') === 'true';
  const [doResetQuestionnaire, setDoResetQuestionnaire] = useState<boolean>(
    createNewQuestionnaire
  );
  const fetchUserData = async () => {
    await auth.fetchMe();
    await refetchQuestionnaire();
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const { data: recentQuestionnaires, refetch: refetchQuestionnaire } =
    useQuery(
      ['recentQuestionnaire'],
      async () => {
        if (!selectedQuestionnaire && createNewQuestionnaire) {
          await ProviderQuestionnaireApi.createProviderQuestionnaire({
            providerId: auth.provider.id,
            type: ProviderQuestionnaireType.RECREDENTIALING,
          }).catch(logException);
        }
        const data = await getLatestQuestionnaire(auth.provider.id);

        const selectedPq = data.selectedQuestionnaire;
        if (!selectedPq || !selectedPq.rawData) {
          return data;
        }

        const rawData: ProviderQuestionnaireRawData = selectedPq.rawData;

        // If the CAQH password is 'XXXXX', that means that it is redacted because the user doesn't have
        // permission to see it. In this case, clear the CAQH password field so that it doesn't show up
        // in the UI and thus requires the user to re-input
        if (
          rawData &&
          Object.keys(rawData).includes('caqhPassword') &&
          rawData.caqhPassword === 'XXXXX'
        ) {
          rawData.caqhPassword = '';
        }
        return data;
      },
      {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setIsRefreshing(false);
          setSelectedQuestionnaire(data.selectedQuestionnaire);
        },
      }
    );

  useEffect(() => {
    if (recentQuestionnaires && queryParams.has('newQuestionnaire')) {
      queryParams.delete('newQuestionnaire');
      navigate(
        {
          search: queryParams.toString(),
        },
        { replace: true }
      );
    }
  }, [navigate, queryParams, recentQuestionnaires]);

  const refreshCaqhToIntakeMapping = async (caqhNumber?: number) => {
    setIsRefreshing(true);
    if (!selectedQuestionnaire || !caqhNumber) return {};
    await ProviderQuestionnaireApi.mapProviderDataByCaqhProviderId(
      selectedQuestionnaire?.id,
      {
        caqh_provider_id: caqhNumber,
      }
    );

    return await refetchQuestionnaire();
  };

  const ldUserAttributes = getLDAttributes(
    auth.user,
    auth.provider,
    selectedQuestionnaire?.id,
    auth.impersonatingUser?.id
  );

  React.useLayoutEffect(() => {
    const body = document.body;

    body.classList.add('helix');

    return () => {
      body.classList.remove('helix');
    };
  }, []);

  const { data: recredSummary } = useQuery(['getRecredSummary'], () =>
    ProviderQuestionnaireApi.getProviderQuestionnaireRecredSummary(
      auth.provider.id
    )
  );

  if (
    !isAdminImpersonatingProviderUser(auth.user, auth.impersonatingUser) &&
    selectedQuestionnaire?.completedOn
  ) {
    return <Navigate replace to="/insurance-status" />;
  }

  if (!selectedQuestionnaire || !recentQuestionnaires) {
    return null;
  }

  return (
    <>
      <IdentifyFlagsUser user={auth.user} userAttributes={ldUserAttributes} />
      <div className="helix" css={layoutWrapperStyle}>
        <Header
          startComponent={<ProviderSelect />}
          providerQuestionnaires={recentQuestionnaires.providerQuestionnaires}
          selectedQuestionnaire={selectedQuestionnaire}
          updateSelectedQuestionnaire={(q) => setSelectedQuestionnaire(q)}
        />
        <div
          css={{
            marginTop: '50px',
            height: 'calc(100vh - 50px)',
          }}
        >
          <QuestionnaireV2
            existingQuestionnaireData={selectedQuestionnaire}
            refreshCaqhToIntakeMapping={refreshCaqhToIntakeMapping}
            refetchQuestionnaire={refetchQuestionnaire}
            isRefreshing={isRefreshing}
            doResetQuestionnaire={doResetQuestionnaire}
            setDoResetQuestionnaire={setDoResetQuestionnaire}
            recredSummary={recredSummary}
          />
        </div>
      </div>
    </>
  );
}

const layoutWrapperStyle = css({
  backgroundColor: theme.color.system.white,
});
