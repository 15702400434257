import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M157.44 90.027c-9.067 0-17.493 2.453-25.067 6.507l-26.56-46.081c-2.133-3.733-8.32-3.733-10.453 0l-53.973 93.44c-1.067 1.813-1.067 4.16 0 6.08a6.14 6.14 0 0 0 5.227 2.987h58.133c4.48 25.067 26.347 44.267 52.693 44.267 29.547 0 53.653-24 53.653-53.653s-24-53.653-53.653-53.653zM51.2 144.213l49.387-85.44 24.533 42.453c-12.8 9.813-21.227 25.067-21.227 42.453v.64H51.2zm61.333-.64c0-14.08 6.72-26.56 16.96-34.88l20.48 35.413H112.64v-.64zm44.907 44.907c-21.547 0-39.573-15.253-43.947-35.627h40.96c2.133 0 4.16-1.173 5.227-2.987s1.067-4.16 0-6.08l-23.04-39.893c6.187-3.307 13.227-5.333 20.693-5.333 24.747 0 44.907 20.16 44.907 44.907s-20.16 44.907-44.907 44.907z" />,
  ],
]);
export const IconModality: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="38 50 175 156" {...props} weights={weights} />
));

IconModality.variants = ['outlined'];
IconModality.defaultVariant = 'outlined';
