/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ControlledSubstance {
    METHYLPHENIDATE_IR_AKA_RITALIN_IR = 'METHYLPHENIDATE_IR_AKA_RITALIN_IR',
    METHYLPHENIDATE_ER_AKA_CONCERTA = 'METHYLPHENIDATE_ER_AKA_CONCERTA',
    METHYLPHENIDATE_LA_AKA_RITALIN_LA = 'METHYLPHENIDATE_LA_AKA_RITALIN_LA',
    METHYLPHENIDATE_SR_AKA_RITALIN_SR = 'METHYLPHENIDATE_SR_AKA_RITALIN_SR',
    METHYLPHENIDATE_ER_AKA_METADATE_ER = 'METHYLPHENIDATE_ER_AKA_METADATE_ER',
    METHYLPHENIDATE_CD_AKA_METADATE_CD = 'METHYLPHENIDATE_CD_AKA_METADATE_CD',
    METHYLPHENIDATE_IR_AKA_METHYLIN_IR = 'METHYLPHENIDATE_IR_AKA_METHYLIN_IR',
    METHYLPHENIDATE_ER_AKA_METHYLIN_ER = 'METHYLPHENIDATE_ER_AKA_METHYLIN_ER',
    METHYLPHENIDATE_ER_BIPHASIC_AKA_ADHANSIA_XR = 'METHYLPHENIDATE_ER_BIPHASIC_AKA_ADHANSIA_XR',
    METHYLPHENIDATE_ER_AKA_APTENSIO_XR = 'METHYLPHENIDATE_ER_AKA_APTENSIO_XR',
    METHYLPHENIDATE_ER_ODT_AKA_COTEMPLA_XR_ODT = 'METHYLPHENIDATE_ER_ODT_AKA_COTEMPLA_XR_ODT',
    METHYLPHENIDATE_ER_DR_AKA_JORNAY_PM = 'METHYLPHENIDATE_ER_DR_AKA_JORNAY_PM',
    METHYLPHENIDATE_ER_CHEWABLE_AKA_QUILLICHEW_ER = 'METHYLPHENIDATE_ER_CHEWABLE_AKA_QUILLICHEW_ER',
    METHYLPHENIDATE_ER_ORAL_SUSPENSION_AKA_QUILLIVANT_XR = 'METHYLPHENIDATE_ER_ORAL_SUSPENSION_AKA_QUILLIVANT_XR',
    METHYLPHENIDATE_CR_AKA_RELEXXII = 'METHYLPHENIDATE_CR_AKA_RELEXXII',
    DEXMETHYLPHENIDATE_IR_AKA_FOCALIN_IR = 'DEXMETHYLPHENIDATE_IR_AKA_FOCALIN_IR',
    DEXMETHYLPHENIDATE_ER_AKA_FOCALIN_XR = 'DEXMETHYLPHENIDATE_ER_AKA_FOCALIN_XR',
    METHYLPHENIDATE_PATCH_AKA_DAYTRANA = 'METHYLPHENIDATE_PATCH_AKA_DAYTRANA',
    AMPHETAMINE_DEXTROAMPHETAMINE_IR_AKA_ADDERALL_IR = 'AMPHETAMINE_DEXTROAMPHETAMINE_IR_AKA_ADDERALL_IR',
    AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_ADDERALL_XR = 'AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_ADDERALL_XR',
    AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_MYDAYIS = 'AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_MYDAYIS',
    LISDEXAMFETAMINE_AKA_VYVANSE = 'LISDEXAMFETAMINE_AKA_VYVANSE',
    DEXTROAMPHETAMINE_IR_AKA_DEXEDRINE = 'DEXTROAMPHETAMINE_IR_AKA_DEXEDRINE',
    DEXTROAMPHETAMINE_ER_AKA_DEXEDRINE_ER = 'DEXTROAMPHETAMINE_ER_AKA_DEXEDRINE_ER',
    DEXTROAMPHETAMINE_SR_AKA_SPANSULE = 'DEXTROAMPHETAMINE_SR_AKA_SPANSULE',
    DEXTROAMPHETAMINE_PATCH_AKA_XELSTRYM = 'DEXTROAMPHETAMINE_PATCH_AKA_XELSTRYM',
    AMPHETAMINE_ER_ODT_AKA_ADZENYS_XR_ODT = 'AMPHETAMINE_ER_ODT_AKA_ADZENYS_XR_ODT',
    AMPHETAMINE_ER_AKA_DYANAVEL_XR = 'AMPHETAMINE_ER_AKA_DYANAVEL_XR',
    AMPHETAMINE_ODT_AKA_EVEKEO_ODT = 'AMPHETAMINE_ODT_AKA_EVEKEO_ODT',
    AMPHETAMINE_IR_AKA_EVEKEO = 'AMPHETAMINE_IR_AKA_EVEKEO',
    AMPHETAMINE_ER_AKA_ADZENYS_XR = 'AMPHETAMINE_ER_AKA_ADZENYS_XR',
    DEXTROAMPHETAMINE_IR_AKA_ZENZEDI = 'DEXTROAMPHETAMINE_IR_AKA_ZENZEDI',
    DEXTROAMPHETAMINE_IR_SOLUTION_AKA_PROCENTRA = 'DEXTROAMPHETAMINE_IR_SOLUTION_AKA_PROCENTRA',
    ESKETAMINE_AKA_SPRAVATO = 'ESKETAMINE_AKA_SPRAVATO',
    SERDEXMETHYLPHENIDATE_DEXMETHYLPHENIDATE_AKA_AZSTARYS = 'SERDEXMETHYLPHENIDATE_DEXMETHYLPHENIDATE_AKA_AZSTARYS',
    MODAFINIL_AKA_PROVIGIL = 'MODAFINIL_AKA_PROVIGIL',
    ARMODAFINIL_AKA_NUVIGIL = 'ARMODAFINIL_AKA_NUVIGIL',
    DIAZEPAM_AKA_VALIUM = 'DIAZEPAM_AKA_VALIUM',
    ALPRAZOLAM_AKA_XANAX = 'ALPRAZOLAM_AKA_XANAX',
    LORAZEPAM_AKA_ATIVAN = 'LORAZEPAM_AKA_ATIVAN',
    CHLORDIAZEPOXIDE_AKA_LIBRIUM = 'CHLORDIAZEPOXIDE_AKA_LIBRIUM',
    CLONAZEPAM_AKA_KLONOPIN = 'CLONAZEPAM_AKA_KLONOPIN',
    TRIAZOLAM_AKA_HALCION = 'TRIAZOLAM_AKA_HALCION',
    TEMAZEPAM_AKA_RESTORIL = 'TEMAZEPAM_AKA_RESTORIL',
    OXAZEPAM_AKA_SERAX = 'OXAZEPAM_AKA_SERAX',
    ESTAZOLAM_AKA_PROSOM = 'ESTAZOLAM_AKA_PROSOM',
    PREGABALIN_AKA_LYRICA = 'PREGABALIN_AKA_LYRICA',
    GABAPENTIN_AKA_NEURONTIN = 'GABAPENTIN_AKA_NEURONTIN',
    ZOLPIDEM_AKA_AMBIEN = 'ZOLPIDEM_AKA_AMBIEN',
    ZOLPIDEM_CR_AKA_AMBIEN_CR = 'ZOLPIDEM_CR_AKA_AMBIEN_CR',
    ZOLPIDEM_SL_AKA_INTERMEZZO = 'ZOLPIDEM_SL_AKA_INTERMEZZO',
    ZOLPIDEM_SL_AKA_EDLUAR = 'ZOLPIDEM_SL_AKA_EDLUAR',
    ZALEPLON_AKA_SONATA = 'ZALEPLON_AKA_SONATA',
    SUVOREXANT_AKA_BELSOMRA = 'SUVOREXANT_AKA_BELSOMRA',
    ESZOPICLONE_AKA_LUNESTA = 'ESZOPICLONE_AKA_LUNESTA',
    ZOPICLONE_AKA_IMOVANE = 'ZOPICLONE_AKA_IMOVANE',
    BUPRENORPHINE_NALOXONE_SL_AKA_SUBOXONE = 'BUPRENORPHINE_NALOXONE_SL_AKA_SUBOXONE',
    BUPRENORPHINE_SL_AKA_SUBUTEX = 'BUPRENORPHINE_SL_AKA_SUBUTEX',
    OTHER = 'OTHER'
}
