import { css } from '@emotion/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { CreateEmergencyContactRequestBody } from '@headway/api/models/CreateEmergencyContactRequestBody';
import { EmergencyContactRead } from '@headway/api/models/EmergencyContactRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { EmergencyContactApi } from '@headway/api/resources/EmergencyContactApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { AddPlus } from '@headway/icons/dist/AddPlus';
import { YUP_PHONE_MATCH } from '@headway/shared/constants/format';
import {
  getUseEmergencyContactsQueryKey,
  useEmergencyContacts,
} from '@headway/shared/hooks/useEmergencyContact';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { maskPhoneNumber } from '@headway/shared/utils/strings';
import { Modal } from '@headway/ui/Modal';

import { notifyError } from './utils/notify';

const MAX_EMERGENCY_CONTACTS = 2;

interface EmergencyContactRowProps {
  patientUser: UserRead;
  emergencyContact: EmergencyContactRead;
}

export const EmergencyContactRow = ({
  patientUser,
  emergencyContact,
}: EmergencyContactRowProps) => {
  const queryClient = useQueryClient();

  const deleteEmergencyContactMutation = useMutation(
    async () => {
      await EmergencyContactApi.deleteEmergencyContact(
        patientUser.id,
        emergencyContact.id
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getUseEmergencyContactsQueryKey(patientUser.id),
        });
      },
      onError: (err) => {
        logException(err);
        notifyError('There was an error removing this emergency contact.');
      },
    }
  );

  return (
    <div>
      <div css={emergencyContactRowCss}>
        <div>
          <BodyText>
            <strong>Name</strong>
          </BodyText>
          <div className="canTextOverflow">
            <BodyText>{emergencyContact.fullName}</BodyText>
          </div>
        </div>

        <div>
          <BodyText>
            <strong>Phone number</strong>
          </BodyText>
          <BodyText>{maskPhoneNumber(emergencyContact.phone)}</BodyText>
        </div>

        <div>
          <BodyText>
            <strong>Relationship</strong>
          </BodyText>
          <div className="canTextOverflow">
            <BodyText>{emergencyContact.relationship}</BodyText>
          </div>
        </div>
        <Button
          variant="link"
          size="medium"
          onPress={async () => {
            await deleteEmergencyContactMutation.mutate();
          }}
          disabled={deleteEmergencyContactMutation.isLoading}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export const emergencyContactSchema = (appIsSigmund: boolean) =>
  Yup.object({
    name: Yup.string().required(
      appIsSigmund
        ? "You must enter the name of your client's contact"
        : 'You must enter the name of your contact'
    ),
    phone: Yup.string()
      .required(
        appIsSigmund
          ? 'You must add a phone number for this contact'
          : 'You must add a phone number for your contact'
      )
      .matches(YUP_PHONE_MATCH, {
        message: appIsSigmund
          ? 'Phone number must be 10 digits'
          : 'Phone number must be 10 digits',
      }),
    relationship: Yup.string().required(
      appIsSigmund
        ? "You must indicate this person's relationship to the client"
        : 'You must indicate your relationship to your contact'
    ),
  });

interface EmergencyContactFormProps {
  patientUser: UserRead;
  setIsAddingContact: (isAddingContact: boolean) => void;
  appIsSigmund: boolean;
  provider?: ProviderRead;
}

export const EmergencyContactForm = ({
  patientUser,
  setIsAddingContact,
  appIsSigmund,
  provider,
}: EmergencyContactFormProps) => {
  const queryClient = useQueryClient();

  const createEmergencyContactMutation = useMutation(
    async (values: CreateEmergencyContactRequestBody) => {
      await EmergencyContactApi.createEmergencyContact(patientUser.id, values);
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUseEmergencyContactsQueryKey(patientUser.id),
        });
        setIsAddingContact(false);
      },
      onError: (err) => {
        logException(err);
        notifyError('There was an error adding this emergency contact.');
      },
    }
  );

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        relationship: '',
      }}
      validationSchema={emergencyContactSchema(appIsSigmund)}
      onSubmit={async (values) => {
        await createEmergencyContactMutation.mutate({
          patientId: patientUser.id,
          fullName: values.name,
          phone: values.phone,
          relationship: values.relationship,
        });

        if (appIsSigmund && provider) {
          trackEvent({
            name: 'Save Emergency Contact Button Clicked',
            properties: {
              providerId: provider?.id,
            },
          });
        }
      }}
    >
      <Form name="emergencyContactForm" css={emergencyContactFormCss}>
        <FormControl component={TextField} name="name" label="Name" />
        <FormControl
          component={PhoneNumberField}
          name="phone"
          label="Phone number"
        />
        <FormControl
          component={TextField}
          name="relationship"
          label="Relationship to client"
        />
        <div css={emergencyContactFormCtaCss}>
          <div>
            <Button
              variant="secondary"
              type="reset"
              onPress={() => setIsAddingContact(false)}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              type="submit"
              disabled={createEmergencyContactMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

type EmergencyContactModalProps = {
  patientUser: UserRead;
  open: boolean;
  onClose: () => void;
  appIsSigmund: boolean;
  provider?: ProviderRead;
};

export const EmergencyContactModal = ({
  patientUser,
  open,
  onClose,
  appIsSigmund,
  provider,
}: EmergencyContactModalProps) => {
  const isBelowTabletMedia = useMediaQuery(theme.__futureMedia.below('tablet'));

  const { data: emergencyContacts } = useEmergencyContacts(patientUser.id);

  const numEmergencyContacts = (emergencyContacts ?? []).length;
  const noEmergencyContactsCompleted = numEmergencyContacts == 0;
  const emergencyContactsCompleted =
    numEmergencyContacts == MAX_EMERGENCY_CONTACTS;

  const [isAdding, setIsAdding] = useState(
    appIsSigmund ? false : noEmergencyContactsCompleted
  );

  const handleAddNewEmergencyContact = () => {
    if (appIsSigmund && provider) {
      trackEvent({
        name: 'Add Emergency Contact Button Clicked',
        properties: {
          providerId: provider.id,
        },
      });
    }

    setIsAdding(true);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        appIsSigmund
          ? patientUser.displayFirstName + "'s emergency contacts"
          : 'Add emergency contacts'
      }
      dialogContentStyle={emergencyContactModalCss}
    >
      <div
        css={{
          ...theme.stack.vertical,
        }}
      >
        {appIsSigmund && (
          <div css={sigmundTextCss}>
            <div>
              <BodyText>
                Your client can also update their emergency contacts from their
                Headway Account page.
              </BodyText>
            </div>

            {noEmergencyContactsCompleted && !isAdding && (
              <div>
                <BodyText color="gray">None Provided</BodyText>
              </div>
            )}
          </div>
        )}

        {numEmergencyContacts > 0 && (
          <div>
            {emergencyContacts?.map((contact) => (
              <div css={emergencyContactRowInModalCss}>
                <EmergencyContactRow
                  patientUser={patientUser}
                  emergencyContact={contact}
                />
              </div>
            ))}
          </div>
        )}
        {!emergencyContactsCompleted &&
          (!isAdding && (appIsSigmund || !noEmergencyContactsCompleted) ? (
            <div css={addLinkButtonCss}>
              <Button
                variant="link"
                size="medium"
                onPress={handleAddNewEmergencyContact}
              >
                <AddPlus />
                Add a new emergency contact
              </Button>
            </div>
          ) : (
            <div
              css={
                (isBelowTabletMedia || !noEmergencyContactsCompleted) && {
                  paddingTop: theme.spacing.x5,
                }
              }
            >
              <EmergencyContactForm
                patientUser={patientUser}
                setIsAddingContact={() => setIsAdding(false)}
                appIsSigmund={appIsSigmund}
                provider={appIsSigmund && provider ? provider : undefined}
              />
            </div>
          ))}
      </div>
    </Modal>
  );
};

export const emergencyContactRowCss = css`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-top: ${theme.spacing.x3};
  padding-bottom: ${theme.spacing.x3};

  & > div {
    display: flex;
    flex-direction: column;
  }

  ${theme.__futureMedia.above('tablet')} {
    & > div {
      width: 25%;

      .canTextOverflow {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ${theme.__futureMedia.below('tablet')} {
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 75%;
      margin-bottom: ${theme.spacing.x3};
    }
  }
`;

const emergencyContactModalCss = css`
  ${theme.__futureMedia.above('tablet')} {
    padding: ${theme.spacing.x5};
  }
  ${theme.__futureMedia.below('tablet')} {
    padding: 0 ${theme.spacing.x5} ${theme.spacing.x5} ${theme.spacing.x5};
  }
`;

const sigmundTextCss = css`
  & > div {
    ${theme.__futureMedia.above('tablet')} {
      &:first-child {
        margin-bottom: ${theme.spacing.x5};
      }
    }

    ${theme.__futureMedia.below('tablet')} {
      margin-top: ${theme.spacing.x5};
    }
  }
`;

const emergencyContactRowInModalCss = css`
  &:first-child {
    border-bottom: 1px solid ${theme.color.system.borderGray};
  }

  ${theme.__futureMedia.below('tablet')} {
    padding-bottom: ${theme.spacing.x4};

    &:nth-child(2) {
      padding-top: ${theme.spacing.x4};
    }
  }
`;

const addLinkButtonCss = css`
  padding: ${theme.spacing.x5} 0 ${theme.spacing.x1} 0;
  row-gap: 20px;
  & > Button {
    display: flex;
    justify-content: center;
    gap: ${theme.spacing.x1};
  }
`;

const doneButtonCss = css`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing.x5};
`;

const emergencyContactFormCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
`;

const emergencyContactFormCtaCss = css`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.x2};
`;
