import { isEmpty, omitBy, reduce } from 'lodash';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import {
  ConsolidatedRates,
  ConsolidatedRatesByState,
  Rates as RatesType,
} from '@headway/shared/types/rates';

export function excludeNonPanelableRates(
  provider: ProviderRead,
  rates: ConsolidatedRatesByState,
  carriersById: {
    [p: number]: FrontEndCarrierRead;
  },
  providerPanelabilityEvaluation: ProviderPanelabilityEvaluation,
  deselectedPfecIds: number[]
): ConsolidatedRatesByState {
  const deselectedPfecStateCarriers: { [state: string]: string[] } =
    deselectedPfecIds.reduce(
      (acc, pfecId) => {
        const pfec = provider.providerFrontEndCarriers?.find(
          (pfec: ProviderFrontEndCarrierNested) => pfec.id === pfecId
        );
        if (pfec) {
          acc[pfec.providerLicenseState.state] = [
            ...(acc[pfec.providerLicenseState.state] || []),
            pfec.frontEndCarrier.name,
          ];
        }
        return acc;
      },
      {} as {
        [state: string]: string[];
      }
    );

  const panelabilityStatusByStateAndCarrierName =
    providerPanelabilityEvaluation?.stateInsuranceCarrierPanelabilityEvaluations?.reduce(
      (acc, { state, frontEndCarrierId, finalPanelabilityStatus }) => {
        if (!frontEndCarrierId) {
          // this shouldn't really happen
          return acc;
        }
        if (!acc[state]) {
          acc[state] = {};
        }
        const frontEndCarrierName = carriersById[frontEndCarrierId]?.name;
        if (frontEndCarrierName) {
          acc[state][frontEndCarrierName] = finalPanelabilityStatus;
        }
        return acc;
      },
      {} as {
        [state: string]: {
          [frontEndCarrierName: string]: PanelabilityStatus;
        };
      }
    );
  // ConsolidatedRatesByState is a 4 level deep object. we need to filter out rates based on the state key at the
  // outermost layer and the carrier key at the innermost layer to get the panelability status based on state + carrier
  return reduce<ConsolidatedRatesByState, ConsolidatedRatesByState>(
    rates,
    (consolidatedRatesByStateAcc, consolidatedRates, state) => {
      const consolidatedRatesExcludingNonPanelable = reduce<
        ConsolidatedRates,
        ConsolidatedRates
      >(
        consolidatedRates,
        (consolidatedRatesAcc, ratesMap, providerType) => {
          const ratesMapExcludingNonPanelable = reduce<RatesType, RatesType>(
            ratesMap,
            (ratesAcc, ratesByCarrier, cptCode) => {
              const ratesByCarrierExcludingNonPanelable = omitBy(
                ratesByCarrier,
                (_, carrierName) =>
                  panelabilityStatusByStateAndCarrierName?.[state]?.[
                    carrierName
                  ] === PanelabilityStatus.NOT_PANELABLE ||
                  deselectedPfecStateCarriers[state]?.includes(carrierName)
              );
              if (!isEmpty(ratesByCarrierExcludingNonPanelable)) {
                return {
                  ...ratesAcc,
                  [cptCode]: ratesByCarrierExcludingNonPanelable,
                };
              } else {
                return ratesAcc;
              }
            },
            {}
          );
          if (!isEmpty(ratesMapExcludingNonPanelable)) {
            return {
              ...consolidatedRatesAcc,
              [providerType]: ratesMapExcludingNonPanelable,
            };
          } else {
            return consolidatedRatesAcc;
          }
        },
        {}
      );
      if (!isEmpty(consolidatedRatesExcludingNonPanelable)) {
        return {
          ...consolidatedRatesByStateAcc,
          [state]: consolidatedRatesExcludingNonPanelable,
        };
      } else {
        return consolidatedRatesByStateAcc;
      }
    },
    {}
  );
}
