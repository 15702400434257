import { Circle } from '@phosphor-icons/react';
import { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconCircle: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    defaultVariant="outlined"
    phosphorIcon={Circle}
    ref={ref}
  />
));

IconCircle.variants = ['outlined'];
IconCircle.defaultVariant = 'outlined';
