import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M199.2 236.8c-1.6 0-3.2-.8-4.8-1.6l-65.6-48-65.6 48c-3.2 2.4-7.2 2.4-9.6 0-3.2-2.4-4-5.6-3.2-9.6L75.2 148 9.6 100c-3.2-2.4-4-5.6-3.2-9.6.8-3.2 4-5.6 8-5.6H96L120 8c.8-3.2 4-5.6 8-5.6s7.2 2.4 8 5.6l24.8 77.6h81.6c4 0 7.2 2.4 8 5.6s0 7.2-3.2 9.6l-65.6 48 24.8 77.6c.8 3.2 0 7.2-3.2 9.6-.8 0-2.4.8-4 .8M28 98.4l61.6 44.8-24 72.8 61.6-44.8 61.6 44.8-24-72.8 61.6-44.8H152l-24-72.8-24 72.8z" />,
  ],
]);
export const IconSpecialty: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="0 0 256 240" {...props} weights={weights} />
));

IconSpecialty.variants = ['outlined'];
IconSpecialty.defaultVariant = 'outlined';
