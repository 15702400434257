import { useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';

import { AppointmentConfirmationModalFormV2Values } from '../AppointmentConfirmationModalV2';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from '../stores/ProgressNotesContextV2';
import { useProgressNotesValidationSchema } from './validationSchemas/useProgressNotesValidationSchema';
import { useSessionDetailsValidationSchema } from './validationSchemas/useSessionDetailsValidationSchema';

export const useValidationSchema =
  (): Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values> => {
    const { progressNoteType, progressNoteState } =
      useContext(ProgressNoteContext);
    const sessionDetailsValidationSchema = useSessionDetailsValidationSchema();
    const progressNotesValidationSchema = useProgressNotesValidationSchema();

    return useMemo(() => {
      // Initialize schema as an object of the correct type
      let schema: Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values> =
        Yup.object({
          sessionDetails: Yup.object(),
          progressNote: Yup.object(),
          attachments: Yup.array(),
          addendums: Yup.object(),
          attestation: Yup.boolean(),
        }) as Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values>;

      if (
        progressNoteType === ProgressNoteType.TEMPLATE &&
        progressNoteState === ProgressNoteState.EDITING
      ) {
        // If template and editing
        // => validationSchema focus on progressNotes only | [Sign and submit]
        schema = schema.concat(progressNotesValidationSchema);
      } else if (
        (progressNoteType === ProgressNoteType.UPLOAD &&
          progressNoteState !== ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT) ||
        progressNoteType === ProgressNoteType.NONE ||
        (progressNoteType === ProgressNoteType.TEMPLATE &&
          progressNoteState === ProgressNoteState.SAVED_FOR_LATER) ||
        (progressNoteType === ProgressNoteType.TEMPLATE &&
          progressNoteState === ProgressNoteState.SIGNED)
      ) {
        // If upload and not editing free text for addendum
        // or if note saved elsewhere
        // or if template and drafted note
        // or if template and signed note
        // => validationSchema focus on sessionDetails only | [Modify session / Confirm session]
        schema = schema.concat(sessionDetailsValidationSchema);
      }
      return schema;
    }, [
      progressNoteType,
      progressNoteState,
      progressNotesValidationSchema,
      sessionDetailsValidationSchema,
    ]);
  };
