import { AriaButtonProps } from '@react-types/button';
import clsx from 'clsx';
import React from 'react';
import { mergeProps, useButton } from 'react-aria';
import { Simplify } from 'type-fest';

import { IconInfo } from './icons/Info';
import { IconWarningCircle } from './icons/WarningCircle';
import { Tooltip, TooltipTrigger } from './Tooltip';
import { InteractionProps, useInteraction } from './useInteraction';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type InfoIconTipButtonProps = Simplify<
  InteractionProps &
    PickAndConfigure<
      AriaButtonProps<'button'>,
      'onPress' | { children: 'children' }
    >
>;

const InfoIconTipButton = React.forwardRef<
  HTMLButtonElement,
  InfoIconTipButtonProps
>(({ ...props }, ref) => {
  const { ariaProps, hoverProps, focusProps, isHovered, isFocusVisible } =
    useInteraction(props);
  const { buttonProps } = useButton(
    { ...ariaProps },
    ref as React.RefObject<HTMLButtonElement>
  );

  return (
    <button
      className={clsx('hlx-tooltip-icon-button', {
        'focus-ring': isFocusVisible,
      })}
      type="button"
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      ref={ref}
    >
      {props.children}
    </button>
  );
});

export type InfoIconTipProps = {
  size?: 'medium' | 'large';
  children?: React.ReactNode;
};

export const InfoIconTip = ({
  size = 'medium',
  ...props
}: InfoIconTipProps) => {
  return (
    <TooltipTrigger>
      <InfoIconTipButton>
        <IconInfo
          width={size === 'medium' ? 20 : 30}
          height={size === 'medium' ? 20 : 30}
        />
      </InfoIconTipButton>
      <Tooltip>{props.children}</Tooltip>
    </TooltipTrigger>
  );
};

// todo(nf): export these from Tooltip.tsx so that users
// via import { InfoIconTip, ErrorIconTip } from '@headway/helix/Tooltip';
export const ErrorIconTip = ({
  size = 'medium',
  ...props
}: InfoIconTipProps) => {
  return (
    <TooltipTrigger>
      <InfoIconTipButton>
        <IconWarningCircle
          width={size === 'medium' ? 20 : 30}
          height={size === 'medium' ? 20 : 30}
        />
      </InfoIconTipButton>
      <Tooltip>{props.children}</Tooltip>
    </TooltipTrigger>
  );
};
