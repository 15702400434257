import React from 'react';

import { Badge } from '@headway/helix/Badge';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { theme } from '@headway/helix/theme';

const EAPInfo = () => {
  return (
    <div css={{ marginTop: theme.spacing.x2 }}>
      <b>What you should do before the session</b>
      <NumberedList>
        <NumberedListItem>
          Please double check this client still has remaining EAP sessions,
          otherwise you won’t be able to confirm the details or receive your
          fee.
        </NumberedListItem>
        <NumberedListItem>
          Be aware that 99404 is the only eligible CPT code for this session
        </NumberedListItem>
        <NumberedListItem>
          This session is not applicable for any late cancellation policy, no
          cancellation fee will be charged
        </NumberedListItem>
      </NumberedList>
    </div>
  );
};

export default EAPInfo;
