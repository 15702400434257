import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <>
      <path
        d="M9.5 10.2202C4.4 6.09617 1 6.2221 1 6.2221V15.3989C5.98981 15.5249 9.5 19.098 9.5 19.098C9.5 19.098 13.0102 15.5249 18 15.3989V6.2221C18 6.2221 14.6157 6.09617 9.5 10.2202Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M9.50062 6.91531C11.1611 6.91531 12.5071 5.56926 12.5071 3.90883C12.5071 2.24839 11.1611 0.902344 9.50062 0.902344C7.84019 0.902344 6.49414 2.24839 6.49414 3.90883C6.49414 5.56926 7.84019 6.91531 9.50062 6.91531Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
    </>,
  ],
  [
    'fill',
    <>
      <path
        d="M9.5 10.2202C4.4 6.09617 1 6.2221 1 6.2221V15.3989C5.98981 15.5249 9.5 19.098 9.5 19.098C9.5 19.098 13.0102 15.5249 18 15.3989V6.2221C18 6.2221 14.6157 6.09617 9.5 10.2202Z"
        fill="currentColor"
      />
      <path
        d="M9.50062 6.91531C11.1611 6.91531 12.5071 5.56926 12.5071 3.90883C12.5071 2.24839 11.1611 0.902344 9.50062 0.902344C7.84019 0.902344 6.49414 2.24839 6.49414 3.90883C6.49414 5.56926 7.84019 6.91531 9.50062 6.91531Z"
        fill="currentColor"
      />
    </>,
  ],
]);
export const IconCeu: Icon = forwardRef((props, ref) => (
  <IconBase
    ref={ref}
    viewBox="0 0 19 20"
    fill="none"
    {...props}
    weights={weights}
  />
));

IconCeu.variants = ['outlined', 'filled'];
IconCeu.defaultVariant = 'outlined';
