import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '.';
import { FormFieldWrapper } from './wrapper/FormFieldWrapper';

const MemoizedTextArea = React.memo(TextArea);

export const FormTextAreaWithCheckbox = ({
  element,
  isOptional = false,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, defaultResponse } = element;
  const contentRef = useRef<HTMLDivElement>(null);

  const { control, setValue } = useFormContext();
  const watchedValue = useWatch({
    control,
    name: id,
  });

  const isCheckboxSelected = defaultResponse === watchedValue;
  const [textAreaValue, setTextAreaValue] = useState(
    isCheckboxSelected ? '' : watchedValue
  );

  const [isOpen, setIsOpen] = useState(!isCheckboxSelected);
  const [height, setHeight] = useState<number | undefined>(
    isOpen ? undefined : 0
  );

  useEffect(() => {
    if (!height || !isOpen || !contentRef.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(contentRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(contentRef.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div css={sectionContainerCss}>
      <div css={sectionHeaderContainerCss}>
        <div css={sectionHeaderTitleContainerCss}>
          <div className="hlx-text-area-root" css={textAreaOverrideCss}>
            <div className="hlx-text-area-descriptors">
              {title && (
                <label
                  className="hlx-text-area-label"
                  css={{
                    color: disabled
                      ? `${theme.color.system.disabledGray} !important`
                      : 'inherit',
                  }}
                >
                  {title}
                </label>
              )}
              {subTitle && (
                <div
                  className="hlx-text-area-instructional-text"
                  id={subTitle}
                  css={{
                    color: disabled
                      ? `${theme.color.system.disabledGray} !important`
                      : 'inherit',
                  }}
                >
                  {subTitle}
                </div>
              )}
            </div>
          </div>
        </div>
        {defaultResponse && (
          <div css={checkBoxGroupOverrideCss}>
            <CheckboxGroup
              name={`${id}-checkbox`}
              value={isCheckboxSelected ? [defaultResponse] : []}
              disabled={disabled}
              aria-label="Progress-notes checkbox-group with checkbox"
            >
              <Checkbox
                value={defaultResponse}
                disabled={disabled}
                onChange={(checked) => {
                  if (checked) {
                    setIsOpen(false);
                    setValue(id, defaultResponse);
                  } else {
                    setIsOpen(true);
                    setValue(id, '');
                  }
                  setTextAreaValue('');
                }}
                checked={isCheckboxSelected}
                aria-label="Progress-notes checkbox-group with checkbox item"
              >
                {defaultResponse}
              </Checkbox>
            </CheckboxGroup>
          </div>
        )}
      </div>
      <div css={sectionContentContainerCss} style={{ height }}>
        <div ref={contentRef}>
          <div>
            <FormFieldWrapper name={id} disabled={disabled}>
              {({ onChange, onBlur, validation, disabled }) => (
                <MemoizedTextArea
                  key={id}
                  name={id}
                  placeholder={placeholder}
                  disabled={disabled}
                  onChange={(value) => {
                    setTextAreaValue(value);
                    onChange(value);
                  }}
                  onBlur={onBlur}
                  value={textAreaValue}
                  validation={validation}
                  optionalityText={isOptional ? 'Optional' : ''}
                  aria-label="Progress-notes text area with checkbox"
                />
              )}
            </FormFieldWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

const checkBoxGroupOverrideCss = css`
  div {
    margin-bottom: 0 !important;
  }
`;
const textAreaOverrideCss = css`
  grid-row-gap: 0 !important;
`;
const sectionContainerCss = css`
  display: flex;
  flex-direction: column;
`;
const sectionHeaderContainerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const sectionHeaderTitleContainerCss = css`
  display: flex;
  width: fit-content;
  flex-direction: column;
`;
const sectionContentContainerCss = css`
  overflow: hidden;
  transition: height 0.6s ease-in-out;
`;
