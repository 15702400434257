import { ElectronicSignatureRoles } from '@headway/api/models/ElectronicSignatureRoles';
import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';

import { AddressInputData } from '../../renderer/components/AddressInput.types';
import { EmbedVideoData } from '../../renderer/components/EmbedVideo.types';
import { HelloSignData } from '../../renderer/components/HelloSign.types';
import {
  LocationSelectData,
  LocationType,
  SelectType,
} from '../../renderer/components/LocationSelect.types';
import {
  ComponentMap,
  FormComponentBlockDeclaration,
} from '../../schema/schema.types';
import { FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE } from '../edit/Validation';

const infoToolboxIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 9V7.2C8 7.08954 8.08954 7 8.2 7L12 7M16 9V7.2C16 7.08954 15.9105 7 15.8 7L12 7M12 7L12 17M12 17H10M12 17H14" stroke="black" stroke-width="2" stroke-linecap="round"/>
  </svg>
`;
export const complicatedFormToolboxIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 19V19C9.13623 19 8.20435 19 7.46927 18.6955C6.48915 18.2895 5.71046 17.5108 5.30448 16.5307C5 15.7956 5 14.8638 5 13V12C5 9.19108 5 7.78661 5.67412 6.77772C5.96596 6.34096 6.34096 5.96596 6.77772 5.67412C7.78661 5 9.19108 5 12 5H13.5C14.8956 5 15.5933 5 16.1611 5.17224C17.4395 5.56004 18.44 6.56046 18.8278 7.83886C19 8.40666 19 9.10444 19 10.5V10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 13V16M16 19V16M19 16H16M16 16H13" stroke="black" stroke-width="2" stroke-linecap="round"/>
    <path d="M6.5 17.5L17.5 6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.9919 10.5H19.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9919 19H11.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 13L13 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const formToolboxIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3535 9.31802L12.7678 7.90381C13.5488 7.12276 14.8151 7.12276 15.5962 7.90381C16.3772 8.68486 16.3772 9.95119 15.5962 10.7322L14.182 12.1464M11.3535 9.31802L7.96729 12.7043C7.40889 13.2627 7.02826 13.9739 6.87339 14.7482L6.69798 15.6253C6.55803 16.325 7.17495 16.942 7.87467 16.802L8.75175 16.6266C9.52612 16.4717 10.2373 16.0911 10.7957 15.5327L14.182 12.1464M11.3535 9.31802L14.182 12.1464" stroke="black" stroke-width="2"/>
    <line x1="15" y1="17" x2="19" y2="17" stroke="black" stroke-width="2" stroke-linecap="round"/>
  </svg>
`;

export const blockDefinitions: {
  [key: string]: FormComponentBlockDeclaration<keyof typeof ComponentMap>;
} = {
  paragraph: {
    formComponent: 'paragraph',
    toolboxName: 'Paragraph',
    defaultState: {
      textContent: '',
    },
    hasSideEdit: false,
  },
  infoGuidanceCard: {
    formComponent: 'infoGuidanceCard',
    toolboxName: 'Guidance card',
    toolboxIcon: infoToolboxIcon,
    defaultState: {
      htmlContent: '<p>some html here</p>',
      readonly: true,
    },
    requiredDefaultStateChange: {
      properties: ['htmlContent', 'title'],
      bounds: 'atLeastOne',
    },
  },
  infoList: {
    formComponent: 'infoList',
    toolboxName: 'List',
    toolboxIcon: infoToolboxIcon,
    defaultState: {
      title: '',
      readonly: true,
      options: ['Add informational list options here!'],
    },
    requiredDefaultStateChange: {
      properties: ['options'],
      bounds: 'all',
    },
  },
  infoOrderedList: {
    formComponent: 'infoOrderedList',
    toolboxName: 'Ordered list',
    toolboxIcon: infoToolboxIcon,
    defaultState: {
      title: '',
      readonly: true,
      options: ['Add informational list options here!'],
    },
    requiredDefaultStateChange: {
      properties: ['options'],
      bounds: 'all',
    },
  },
  infoParagraph: {
    formComponent: 'infoParagraph',
    toolboxName: 'Text',
    toolboxIcon: infoToolboxIcon,
    defaultState: {
      textContent: 'Add some paragraph content',
      htmlContent: '',
      readonly: true,
    },
    requiredDefaultStateChange: {
      properties: ['textContent', 'htmlContent'],
      bounds: 'atLeastOne',
    },
  },
  infoTitle: {
    formComponent: 'infoTitle',
    toolboxName: 'Title',
    toolboxIcon: infoToolboxIcon,
    defaultState: {
      title: 'Info title',
      readonly: true,
    },
    requiredDefaultStateChange: {
      properties: ['title'],
      bounds: 'all',
    },
  },
  checkbox: {
    formComponent: 'checkbox',
    toolboxName: 'Checkbox',
    defaultState: {
      title: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['title'],
      bounds: 'all',
    },
  },
  checklist: {
    formComponent: 'checklist',
    toolboxName: 'Checklist',
    defaultState: {
      title: '',
      options: [''],
      style: {
        numberOfColumms: 2,
      },
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['options'],
      bounds: 'all',
    },
  },
  date: {
    formComponent: 'date',
    toolboxName: 'Date',
    toolboxIcon: formToolboxIcon,
    defaultState: {
      title: 'Untitled Date',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['title'],
      bounds: 'all',
    },
  },
  dropdown: {
    formComponent: 'dropdown',
    toolboxName: 'Dropdown',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      options: [''],
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['options', 'title'],
      bounds: 'all',
    },
  },
  dropdownMulti: {
    formComponent: 'dropdownMulti',
    toolboxName: 'Dropdown multi',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      options: [''],
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['title', 'options'],
      bounds: 'all',
    },
  },
  dropdownDiagnosis: {
    formComponent: 'dropdownDiagnosis',
    toolboxName: 'Diagnosis',
    defaultState: {
      title: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
  },
  issues: {
    formComponent: 'issues',
    toolboxName: 'Issues Checklist',
    defaultState: {
      title: '',
    },
  },
  longFreeText: {
    formComponent: 'longFreeText',
    toolboxName: 'Long free text',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      description: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
  },
  longFreeTextWithCheckbox: {
    formComponent: 'longFreeTextWithCheckbox',
    toolboxName: 'Long free text with checkbox',
    defaultState: {
      title: 'Default Title',
      subTitle: '',
      placeholder: '',
      description: '',
      defaultResponse: 'Default Response',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['title', 'defaultResponse'],
      bounds: 'all',
    },
  },
  radio: {
    formComponent: 'radio',
    toolboxName: 'Radio',
    defaultState: {
      title: '',
      options: [''],
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['options'],
      bounds: 'all',
    },
  },
  richFreeText: {
    formComponent: 'richFreeText',
    toolboxName: 'Rich free text',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
  },
  select: {
    formComponent: 'select',
    toolboxName: 'Select',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      options: [''],
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['options', 'title'],
      bounds: 'all',
    },
  },
  shortFreeText: {
    formComponent: 'shortFreeText',
    toolboxName: 'Short free text',
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
  },
  acknowledgeAndSign: {
    formComponent: 'acknowledgeAndSign',
    toolboxName: 'Acknowledge and sign',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: '',
      subTitle: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    requiredDefaultStateChange: {
      properties: ['title', 'subTitle'],
      bounds: 'all',
    },
  },
  addressInput: {
    formComponent: 'addressInput',
    toolboxName: 'Address Input',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    data: [
      {
        propDisplayName: 'Total # of Allowed Addresses',
        propName: AddressInputData.maxAddressNumber,
        isNumber: true,
        isMappedToInjectedData: false,
        isRequired: true,
        value: '',
      },
    ],
  },
  embedVideo: {
    formComponent: 'embedVideo',
    toolboxName: 'Embed Video',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: '',
      subTitle: '',
    },
    data: [
      {
        propDisplayName: 'Add a youtube url',
        propName: EmbedVideoData.videoLink,
        isMappedToInjectedData: false,
        isRequired: true,
        value: '',
      },
    ],
  },

  helloSign: {
    formComponent: 'helloSign',
    toolboxName: 'Hello Sign',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: 'Release Form',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    data: [
      {
        propDisplayName: 'clientId',
        propName: HelloSignData.helloSignSdkClientId,
        isNumber: false,
        isMappedToInjectedData: true,
        isRequired: true,
        value: '',
      },
      {
        propDisplayName: 'onSuccessMessage',
        propName: HelloSignData.onSuccessMessage,
        isNumber: false,
        isMappedToInjectedData: false,
        isRequired: false,
        value: '',
      },
      {
        propDisplayName: 'helloSignEmbedded',
        propName: HelloSignData.helloSignEmbedded,
        isNumber: false,
        isMappedToInjectedData: true,
        isRequired: false,
        value: '',
      },
      {
        propDisplayName: 'userId',
        propName: HelloSignData.userId,
        isNumber: true,
        isMappedToInjectedData: true,
        isRequired: false,
        value: '',
      },
      {
        propDisplayName: 'email',
        propName: HelloSignData.email,
        isNumber: false,
        isMappedToInjectedData: true,
        isRequired: true,
        value: '',
      },
      {
        propDisplayName: 'name',
        propName: HelloSignData.name,
        isNumber: false,
        isMappedToInjectedData: true,
        isRequired: true,
        value: '',
      },
      {
        propDisplayName: 'role',
        propName: HelloSignData.role,
        options: Object.entries(ElectronicSignatureRoles).map(
          (type) => type[1]
        ),
        isNumber: false,
        isMappedToInjectedData: false,
        isRequired: true,
        value: '',
      },
      {
        propDisplayName: 'templateIds',
        propName: HelloSignData.templateIds,
        isUserGenerated: true,
        isNumber: false,
        isMappedToInjectedData: false,
        isRequired: true,
        isList: true,
        value: '',
      },
    ],
  },
  locationSelect: {
    formComponent: 'locationSelect',
    toolboxName: 'Location Select',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: '',
      subTitle: '',
      placeholder: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    data: [
      {
        propDisplayName: 'Should they select a state or country',
        propName: LocationSelectData.locationSelectType,
        isMappedToInjectedData: false,
        isRequired: true,
        options: Object.entries(LocationType).map((type) => type[1]),
        value: '',
      },
      {
        propDisplayName: 'Can they select multiple options',
        propName: LocationSelectData.comboBoxSelectionType,
        options: Object.entries(SelectType).map((type) => type[1]),
        isMappedToInjectedData: false,
        isRequired: true,
        value: '',
      },
    ],
  },
  upload: {
    formComponent: 'upload',
    toolboxName: 'Upload',
    toolboxIcon: complicatedFormToolboxIcon,
    defaultState: {
      title: '',
      subTitle: '',
      validations: [
        { type: 'required', params: [FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE] },
      ],
    },
    data: [
      {
        propDisplayName: 'Upload Owner ID',
        propName: 'uploadOwnerId',
        isMappedToInjectedData: true,
        isRequired: true,
        value: '',
      },
      {
        propDisplayName: 'Upload Type',
        propName: 'uploadType',
        isMappedToInjectedData: false,
        isRequired: true,
        options: Object.entries(UserUploadTypes).map((entry) => entry[0]),
        optionType: 'single',
        value: '',
      },
      {
        propDisplayName: 'Accepted file types',
        propName: 'acceptedFileTypes',
        isMappedToInjectedData: false,
        isRequired: true,
        options: [
          'application/pdf',
          'application/vnd.apple.pages',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/plain',
          'image/jpeg',
          'image/png',
        ],
        optionType: 'multiple',
        value: '',
      },
    ],
  },
};
