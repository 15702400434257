import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { Item, Select } from '@headway/helix/Select';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { formatPatientName } from '@headway/shared/utils/patient';
import { FormRow } from '@headway/ui/form';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';

import { AppointmentContext } from '../../stores/AppointmentContext';
import { SessionDetailsFormV2Values } from '../SessionDetails/SessionDetailsFormV2';

// gemini.link(web/apps/sigmund/app/legacy/views/Calendar/components/AppointmentConfirmation/Forms/FormComponents/PatientAddressDropdown.tsx)
export const PatientAddressDropdownHelixV2 = ({
  patient,
  canUpdateSessionDetails,
  onNewLocationClicked,
}: {
  patient: UserRead;
  canUpdateSessionDetails: boolean;
  onNewLocationClicked: () => void;
}) => {
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const { updateAppointmentAddressState } = useContext(AppointmentContext);
  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient.id,
  });
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();
  const { control } = useFormContext();
  const sessionDetailsValues: SessionDetailsFormV2Values = useWatch({
    control,
    name: 'sessionDetails',
  });

  const patientAddressesOnFileText = `Your client must be physically located (during your session) in a state where you are credentialed with their insurance plan on Headway. ${
    patientAddresses?.length === 0 && isMSCGuardrailWarning
      ? `Starting after ${restrictionDate}, we'll block telehealth session confirmation if there is a mismatch.`
      : ''
  }`;

  return (
    <FormRow>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name="sessionDetails.appointmentLocationPatientAddressId"
            disabled={!canUpdateSessionDetails}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                name="sessionDetails.appointmentLocationPatientAddressId"
                label={`Where did ${patientFirstName} join the telehealth session from?`}
                menuWidth="stretch"
                placeholder="Select a location"
                helpText={patientAddressesOnFileText}
                selectionMode="single"
                onBlur={onBlur}
                selectedKeys={
                  sessionDetailsValues.appointmentLocationPatientAddressId
                    ? [
                        sessionDetailsValues.appointmentLocationPatientAddressId.toString(),
                      ]
                    : []
                }
                onSelectionChange={(items: Set<string>) => {
                  const selectedValue = parseInt(Array.from(items)[0]);
                  onChange(selectedValue);
                  updateAppointmentAddressState(
                    sessionDetailsValues?.providerAddressId,
                    value
                  );
                }}
                disabled={!canUpdateSessionDetails}
              >
                {patientAddresses?.map((address: PatientAddressRead) => (
                  <Item
                    key={address.id}
                    textValue={`${
                      address.addressType
                        ? `${addressTypeDisplayNames[address.addressType]}: `
                        : ''
                    }${address.streetLine1}${
                      address.streetLine2 ? ` ${address.streetLine2}` : ''
                    }, ${address.city}, ${
                      statesToDisplayNames[address.state]
                    } ${address.zipCode}`}
                  >
                    {address.addressType &&
                      `${addressTypeDisplayNames[address.addressType]}: `}
                    {address.streetLine1}
                    {address.streetLine2 && ` ${address.streetLine2}`},{' '}
                    {address.city}, {statesToDisplayNames[address.state]}{' '}
                    {address.zipCode}
                  </Item>
                ))}
              </Select>
            )}
          />
        </div>
        <div style={{ padding: 10 }} />
        <div
          className={'hlx-combobox-actions button'}
          style={{ paddingTop: 20 }}
        >
          <Button
            size="large"
            variant={'secondary'}
            onPress={onNewLocationClicked}
          >
            + New location
          </Button>
        </div>
      </div>
    </FormRow>
  );
};
