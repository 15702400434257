/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { GetGroupPracticePatientsRequest } from '../models/GetGroupPracticePatientsRequest';
import { GetSessionsByProviderResponse } from '../models/GetSessionsByProviderResponse';
import { GroupPracticeRead } from '../models/GroupPracticeRead';
import { GroupPracticeUpdate } from '../models/GroupPracticeUpdate';
import { GroupPracticeUserRead } from '../models/GroupPracticeUserRead';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderBillingAccountRead } from '../models/ProviderBillingAccountRead';
import { ProviderRead } from '../models/ProviderRead';
import { UserRead } from '../models/UserRead';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class GroupPracticeApiResource {

    /**
     * Get Group Practice Billing Account
     * 
     * @param groupPracticeId 
     
     */
    public getGroupPracticeBillingAccount(groupPracticeId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderBillingAccountRead> {
        const apiRoute = '/group-practice/{group_practice_id}/billing-account';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Group Practice Patients
     * 
     * @param groupPracticeId 
     * @param getGroupPracticePatientsRequest 
     
     */
    public getGroupPracticePatients(groupPracticeId: number, body: GetGroupPracticePatientsRequest, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/group-practice/{group_practice_id}/patients';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Group Practice User By Provider Id
     * 
     * @param groupPracticeId 
     * @param providerId 
     
     */
    public getGroupPracticeUserByProviderId(groupPracticeId: number, providerId: number, axiosConfig?: AxiosRequestConfig): Promise<GroupPracticeUserRead> {
        const apiRoute = '/group-practice/{group_practice_id}/providers/{provider_id}/user';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId))
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Group Practice Users
     * 
     * @param groupPracticeId 
     
     */
    public getGroupPracticeUsers(groupPracticeId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/group-practice/{group_practice_id}/users';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Providers In Group Practice
     * 
     * @param groupPracticeId 
     * @param isArchived 
     * @param isActive 
     
     */
    public getProvidersInGroupPractice(groupPracticeId: number, query?: { is_archived?: boolean, is_active?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderRead>> {
        const apiRoute = '/group-practice/{group_practice_id}/providers';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Sessions By Provider
     * 
     * @param groupPracticeId 
     * @param date 
     * @param timezone 
     
     */
    public getSessionsByProvider(groupPracticeId: number, query?: { date: string, timezone: string }, axiosConfig?: AxiosRequestConfig): Promise<GetSessionsByProviderResponse> {
        const apiRoute = '/group-practice/{group_practice_id}/provider-events/get-sessions-by-provider';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Patch Group Practice
     * 
     * @param groupPracticeId 
     * @param groupPracticeUpdate 
     
     */
    public patchGroupPractice(groupPracticeId: number, body: GroupPracticeUpdate, axiosConfig?: AxiosRequestConfig): Promise<GroupPracticeRead> {
        const apiRoute = '/group-practice/{group_practice_id}';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Is Archived
     * 
     * @param groupPracticeId 
     * @param providerId 
     * @param isArchived 
     
     */
    public updateProviderIsArchived(groupPracticeId: number, providerId: number, isArchived: boolean, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/group-practice/{group_practice_id}/provider/{provider_id}/archive/{is_archived}';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId))
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'is_archived}', String(isArchived));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const GroupPracticeApi = new GroupPracticeApiResource();
