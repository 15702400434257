import { toJS } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useDownloadBlob } from '~/legacy/hooks/useDownloadBlob';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { logException } from '@headway/shared/utils/sentry';
import { PdfStateMessages, usePdfToasts } from '@headway/ui/pdf';

import { groupAndSortAssessments } from '../helpers/utils';
import { AssessmentResultsPdfProps } from './AssessmentResultsPdf';
import { AssessmentResultsPdfHeaderProps } from './AssessmentResultsPdfHeader';
import { generateAssessmentResultsPdf } from './generateAssessmentResultsPdf';

export type UseAssessmentResultPdfBaseParams =
  Partial<AssessmentResultsPdfHeaderProps> & {
    // called when new pdf will be generated, or existing pdf will be downloaded
    // (useful for e.g. analytics)
    onTrigger?: (assessments?: PatientAssessmentRead[]) => void;
  };

type UseAssessmentResultsPdfParams = UseAssessmentResultPdfBaseParams & {
  assessments?: PatientAssessmentRead[];
  filename?: string;
  toastMessages: PdfStateMessages;
};

type UseAssessmentResultsPdfOverrides = { loading?: boolean; error?: unknown };

export function useAssessmentResultsPdf(
  {
    client,
    provider,
    assessments,
    filename,
    toastMessages,
    onTrigger,
  }: UseAssessmentResultsPdfParams,
  {
    loading: overrideLoading,
    error: overrideError,
  }: UseAssessmentResultsPdfOverrides = {}
) {
  const [triggered, setTriggered] = useState(false);

  const sortedAssessments = useMemo(
    () => (assessments ? groupAndSortAssessments(assessments) : undefined),
    [assessments]
  );

  const [{ value: pdf, error: pdfError, loading: pdfLoading }, generatePdf] =
    useAsyncFn(generateAssessmentResultsPdf);

  const startGeneratingPdf = useCallback(
    (params: AssessmentResultsPdfProps) => {
      if (!pdfLoading) {
        generatePdf(params);
      }
    },
    [generatePdf, pdfLoading]
  );

  const [{ downloading }, download] = useDownloadBlob();

  const loading = overrideLoading ?? pdfLoading;
  const error = overrideError ?? pdfError;
  usePdfToasts({ loading, downloading, error: !!error }, toastMessages);

  useEffect(() => {
    if (error) {
      logException(error);
    }
  }, [error]);

  useEffect(() => {
    if (!triggered || !!pdf) {
      return;
    }

    if (!client || !provider || !sortedAssessments) {
      throw new Error('Trying to generate PDF without required data');
    }

    // provider is sometimes actually a mobx observable
    // (needs to be serializable to be passed to web worker)
    const serializableProvider = toJS(provider);

    startGeneratingPdf({
      client,
      provider: serializableProvider,
      assessments: sortedAssessments,
    });
  }, [client, provider, sortedAssessments, pdf, triggered, startGeneratingPdf]);

  useEffect(() => {
    if (!triggered || !pdf) {
      return;
    }

    if (!filename) {
      throw new Error('Invalid PDF filename');
    }

    download(pdf, filename);
    setTriggered(false);
  }, [filename, pdf, triggered, download]);

  const trigger = useCallback(() => {
    if (triggered) {
      return;
    }

    setTriggered(true);
    onTrigger?.(assessments);
  }, [triggered, onTrigger, assessments]);

  return [{ loading, pdf, error }, trigger] as const;
}
