import type { AriaButtonProps } from '@react-types/button';
import React from 'react';
import { AriaListBoxOptions, useButton } from 'react-aria';

import { Button } from '../Button';
import { ComboBoxMultiSelectState } from '../ComboBox';
import { IconButton } from '../IconButton';
import { IconX } from '../icons/X';
import { IconXCircle } from '../icons/XCircle';
import { SearchField } from '../SearchField';
import { MultiSelectState } from '../Select';

interface MobilePickerDialogProps<T> extends AriaListBoxOptions<T> {
  children: React.ReactNode;
  state: MultiSelectState<T> | ComboBoxMultiSelectState<T>;
  /**
   * Whether to display a search field in the menu on mobile. This is useful when the list of options is
   * very long and the user needs to be able to filter the options.
   **/
  searchable?: boolean;

  title?: React.ReactNode;
}

export function MobilePickerDialog<T>({
  children,
  state,
  title,
  searchable,
}: MobilePickerDialogProps<T>) {
  const [isSearchActive, setSearchActive] = React.useState(false);

  React.useEffect(() => {
    // when a value is (de)selected, deactivate the search field
    setSearchActive(false);
  }, [state.selectionManager.selectedKeys]);

  return (
    <div className="hlx-panel">
      {!isSearchActive && (
        <div className="hlx-panel-header">
          {title && <div className="hlx-panel-title">{title}</div>}
          <IconButton
            aria-label={'Close'}
            onPress={() => {
              state.close();
            }}
            variant="transparent"
          >
            <IconX />
          </IconButton>
        </div>
      )}

      {searchable && (
        <div className="hlx-panel-filter" data-active={isSearchActive}>
          <SearchField
            aria-label="Search"
            placeholder="Search"
            value={state.inputValue}
            onChange={(value) => {
              state.setInputValue(value);
              setSearchActive(true);
            }}
            onFocus={() => {
              setSearchActive(true);
            }}
            onClear={() => {
              state.setInputValue('');
            }}
          />
          {isSearchActive && (
            <Button
              variant="link"
              onPress={() => {
                state.setInputValue('');
                setSearchActive(false);
                const first = state.collection.getFirstKey();
                if (first) {
                  state.selectionManager.setFocusedKey(first);
                }
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
      <div className="hlx-panel-content">{children}</div>
      {state.selectionMode === 'multiple' && (
        <div className="hlx-panel-footer">
          <Button
            onPress={() => {
              state.close();
            }}
          >
            Save
          </Button>
          <Button
            variant="link"
            onPress={() => {
              state.setSelectedKeys(new Set());
            }}
          >
            Clear all
          </Button>
        </div>
      )}
    </div>
  );
}

const ClearButton = React.forwardRef<
  HTMLButtonElement,
  AriaButtonProps<'button'>
>((props, ref) => {
  let { buttonProps } = useButton(
    props,
    ref as React.RefObject<HTMLButtonElement>
  );
  return (
    <button
      type="button"
      className="hlx-clear-field-trigger"
      {...buttonProps}
      ref={ref}
    >
      <IconXCircle />
    </button>
  );
});
