import { Link, LinkProps, Text, TextProps } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

import { combinePdfStyles, pdfStyles } from '../style';

export const PdfText = ({
  style = {},
  ...props
}: PropsWithChildren<TextProps>) => (
  <Text style={combinePdfStyles(pdfStyles.text, style)} {...props} />
);

export const PdfLink = ({
  style = {},
  ...props
}: PropsWithChildren<LinkProps>) => (
  <Link style={combinePdfStyles(pdfStyles.text, style)} {...props} />
);
