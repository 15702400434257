import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM10.72 9.47c0 .73.57 1.22 2.34 1.67 1.76.46 3.65 1.22 3.66 3.42-.01 1.61-1.21 2.48-2.73 2.77V19h-2.34v-1.7c-1.5-.31-2.76-1.27-2.86-2.97h1.72c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.72-.39-1.41-2.34-1.87-2.17-.52-3.66-1.42-3.66-3.21 0-1.51 1.21-2.49 2.72-2.81V5h2.33v1.71c1.62.4 2.44 1.63 2.49 2.97h-1.71c-.04-.98-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43z"
        fill="currentColor"
      />
    </>,
  ],
]);
export const IconMonetization: Icon = forwardRef((props, ref) => (
  <IconBase
    ref={ref}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
    weights={weights}
  />
));
IconMonetization.defaultVariant = 'outlined';
IconMonetization.variants = ['outlined'];
