/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum SessionUnconfirmableReason {
    ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS = 'ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS',
    ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE = 'ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE',
    BILLING_TYPE_NOT_ELIGIBLE = 'BILLING_TYPE_NOT_ELIGIBLE',
    DOES_NOT_ACCEPT_PATIENT_INSURANCE = 'DOES_NOT_ACCEPT_PATIENT_INSURANCE',
    EXPIRED_LICENSE = 'EXPIRED_LICENSE',
    INCOMPLETE_W9 = 'INCOMPLETE_W9',
    NO_ANTHEM_EAP_FOUND = 'NO_ANTHEM_EAP_FOUND',
    NO_LICENSE_FOUND_FOR_STATE = 'NO_LICENSE_FOUND_FOR_STATE',
    NOT_AN_APPOINTMENT = 'NOT_AN_APPOINTMENT',
    PAST_DEADLINE_TO_CONFIRM = 'PAST_DEADLINE_TO_CONFIRM',
    PATIENT_HAS_BLOCKING_FREEZE = 'PATIENT_HAS_BLOCKING_FREEZE',
    PATIENT_MISSING_ACTIVE_INSURANCE = 'PATIENT_MISSING_ACTIVE_INSURANCE',
    PATIENT_MISSING_DEFAULT_PAYMENT_METHOD = 'PATIENT_MISSING_DEFAULT_PAYMENT_METHOD',
    PATIENT_MISSING_REQUIRED_FORMS = 'PATIENT_MISSING_REQUIRED_FORMS',
    PATIENT_MISSING_REQUIRED_INFO = 'PATIENT_MISSING_REQUIRED_INFO',
    PATIENT_NOT_CLAIM_READY = 'PATIENT_NOT_CLAIM_READY',
    PROVIDER_MISSING_ACTIVE_BILLING_ACCOUNT = 'PROVIDER_MISSING_ACTIVE_BILLING_ACCOUNT',
    PROVIDER_STRIPE_NOT_VERIFIED = 'PROVIDER_STRIPE_NOT_VERIFIED',
    SESSION_NOT_ALLOWED_TO_BE_EDITED = 'SESSION_NOT_ALLOWED_TO_BE_EDITED'
}
