import { TextField } from '@headway/helix/TextField';

import { SchemaComponent } from '.';
import { getInstructionalText } from '../../utils';
import { FormFieldWrapper } from './wrapper/FormFieldWrapper';

export const FormTextField = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, optionalityText } = element;

  return (
    <FormFieldWrapper name={id} disabled={disabled}>
      {({ onChange, onBlur, value, validation, disabled }) => (
        <TextField
          label={title}
          name={id}
          key={id}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          optionalityText={
            isOptional ? (optionalityText ? optionalityText : 'Optional') : ''
          }
          placeholder={placeholder}
          instructionalText={getInstructionalText(subTitle, { requiresEdit })}
          validation={validation}
          disabled={disabled}
        />
      )}
    </FormFieldWrapper>
  );
};
