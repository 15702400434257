import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="m37.338 197.222 64-64.102L144 175.782l90.662-101.965-15.04-15.04L144 143.782l-42.662-42.662-80 80.102z" />,
  ],
]);
export const IconShowChart: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="0 0 256 256" {...props} weights={weights} />
));

IconShowChart.variants = ['outlined'];
IconShowChart.defaultVariant = 'outlined';
