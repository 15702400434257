import { ReactNode } from 'react';

import { ContentText, ContentTextProps } from './ContentText';

export interface CaptionTextProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'red' | 'green';
}

const COLOR_MAP: Record<
  NonNullable<CaptionTextProps['color']>,
  ContentTextProps['color']
> = {
  textBlack: 'foreground/primary',
  gray: 'foreground/secondary',
  red: 'foreground/danger',
  green: 'foreground/compliance-secondary',
};

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const CaptionText = ({
  children,
  color = 'textBlack',
}: CaptionTextProps) => {
  return (
    <ContentText variant="caption" color={COLOR_MAP[color]}>
      {children}
    </ContentText>
  );
};
