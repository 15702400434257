import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderWizardRead } from '@headway/api/models/ProviderWizardRead';
import { ProviderWizardApi } from '@headway/api/resources/ProviderWizardApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button as HelixButton } from '@headway/helix/Button';
import { IconButton } from '@headway/helix/IconButton';
import { IconX } from '@headway/helix/icons/X';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection } from '@headway/helix/Page';
import { ProgressBar } from '@headway/helix/Progress';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { PROVIDER_REFERRAL_PROGRAM_AWARENESS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';
import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';
import { getEventNameFromEnum } from 'utils/analytics';
import { ONBOARDING_MODULE_STATUSES_CACHE_KEY } from 'utils/cacheKeys';
import {
  CLICKED_PORT_PATIENTS_LOCAL_STORAGE_KEY,
  PROVIDER_CLOSED_ONBOARDING_CARD_LOCAL_STORAGE_KEY,
} from 'utils/localStorage';

import { providerCompletedRequiredOnboardingModules } from './utils';

/**
 * Determines the display order of all provider modules (lower numbers appear higher in the list).
 */
const ONBOARDING_MODULE_ORDER: { [module in ProviderModule]: number } = {
  [ProviderModule.PROFILE]: 1,
  [ProviderModule.AVAILABILITY]: 2,
  [ProviderModule.PAYMENT]: 3,
  [ProviderModule.PORT_PATIENTS]: 4,
};

interface ModuleDisplayData {
  linkText: string;
  description: string;
  route: string;
  /** Optional alternate route to use when the module is already completed. */
  completedRoute?: string;
}

export const OnboardingCard = () => {
  const statusesQuery = useOnboardingModuleStatuses();
  const auth = useAuthStore();
  const provider = useProvider();
  const shouldSkipIntake = useFlag('skipIntake');
  const onboardingStatuses = statusesQuery.data || [];

  let displayedOnboardingStatuses = [...onboardingStatuses].sort(
    (a, b) =>
      ONBOARDING_MODULE_ORDER[a.module] - ONBOARDING_MODULE_ORDER[b.module]
  );
  const isProviderGroupPracticeMember =
    !!provider.groupPracticeId &&
    !auth?.user?.group_practice?.roles?.includes('Owner');

  // If provider is a group practice member (not GP admin) hide payment
  // module since they don't have access to add this info
  if (isProviderGroupPracticeMember) {
    displayedOnboardingStatuses = displayedOnboardingStatuses.filter(
      (status) => status.module !== ProviderModule.PAYMENT
    );
  }
  const numCompletedOnboardingModules = displayedOnboardingStatuses.filter(
    ({ isComplete }) => isComplete
  ).length;

  const { earliestActiveLiveOn } = provider;
  const isProviderCredentialed = earliestActiveLiveOn
    ? moment(earliestActiveLiveOn) < moment()
    : false;
  const isMobileView = useMediaQuery(theme.__futureMedia.below('tablet'));

  const setProviderClosedOnboardingCard = useLocalStorage(
    PROVIDER_CLOSED_ONBOARDING_CARD_LOCAL_STORAGE_KEY
  )[1];

  let showReferralProgramTask =
    useFlag(PROVIDER_REFERRAL_PROGRAM_AWARENESS, 'Control') !== 'Control';

  return (
    <PageSection>
      {isMobileView && (
        <Alert icon={<WarningAmberIcon />} color="warning">
          For an optimal experience, view this page on a desktop.
        </Alert>
      )}
      <div className="rounded border border-system-borderGray p-8">
        <h3 className="mt-0 flex justify-between">
          <SectionHeader>Welcome to Headway!</SectionHeader>
          {providerCompletedRequiredOnboardingModules(
            onboardingStatuses,
            isProviderGroupPracticeMember
          ) && (
            <IconButton
              variant="transparent"
              size="medium"
              onPress={() => setProviderClosedOnboardingCard('true')}
              aria-label="Dismiss welcome card"
            >
              <IconX />
            </IconButton>
          )}
        </h3>
        <div className="mb-5">
          <BodyText color="gray">Start setting up your practice now.</BodyText>
        </div>

        <ProgressBar
          aria-label="Practice setup progress bar"
          valueLabel={`${(
            ((numCompletedOnboardingModules + 1) /
              (displayedOnboardingStatuses.length + 1)) *
            100
          ).toFixed()}% Complete`}
          value={numCompletedOnboardingModules + 1}
          minValue={0}
          maxValue={displayedOnboardingStatuses.length + 1}
        />
        {/**
         * The intake form is a fake module that we hardcode to "complete" because providers must
         * finish the intake form before seeing this card.
         */}
        {!shouldSkipIntake && (
          <OnboardingTaskItem>
            <CheckCircle />
            <BodyText>
              <b>Complete your intake form</b> so we can bring you in-network
              with insurance
            </BodyText>

            {isProviderCredentialed && (
              <LinkButton
                variant="link"
                to="/insurance-status"
                component={Link}
                size="medium"
                elementType="a"
              >
                View Progress
              </LinkButton>
            )}
          </OnboardingTaskItem>
        )}
        {displayedOnboardingStatuses.map(({ module, wizard, isComplete }) => {
          return (
            <ModuleTaskStatus
              key={module}
              module={module}
              wizard={wizard}
              isComplete={isComplete}
              providerId={provider.id}
            />
          );
        })}
        {showReferralProgramTask && (
          <OnboardingTaskItem>
            <IconShareNetwork />
            <span>
              <OnboardingTaskLink variant="brand" to="/refer-a-provider">
                Refer a provider
              </OnboardingTaskLink>
              <BodyText>
                <span>&nbsp;-&nbsp;</span>
                Help more providers accept insurance
              </BodyText>
            </span>
            <Badge variant="neutral">Optional</Badge>
          </OnboardingTaskItem>
        )}
      </div>
    </PageSection>
  );
};

function IconShareNetwork() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={24}
      height={24}
      className="text-system-gray"
    >
      <path fill="none" d="M0 0H256V256H0z"></path>
      <circle
        cx="64"
        cy="128"
        r="32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <circle
        cx="176"
        cy="200"
        r="32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <circle
        cx="176"
        cy="56"
        r="32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M149.09 73.3L90.91 110.7"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M90.91 145.3L149.09 182.7"
      ></path>
    </svg>
  );
}

export const captureAnalyticsEvent = (
  module: ProviderModule,
  isInProgress: boolean,
  isComplete: boolean,
  screenName?: string
) => {
  if (
    isComplete ||
    ![ProviderModule.PROFILE, ProviderModule.AVAILABILITY].includes(module)
  )
    return;

  let name = `Setup ${
    getEventNameFromEnum(module) as 'Availability' | 'Profile'
  } `;
  if (isInProgress) {
    return trackEvent({
      name: (name + 'Step') as 'Setup Availability Step' | 'Setup Profile Step',
      properties: {
        screenName: screenName || 'Home',
        stepName: 'resumed',
      },
    });
  } else {
    return trackEvent({
      name: (name + 'Started') as
        | 'Setup Availability Started'
        | 'Setup Profile Started',
      properties: {
        screenName: screenName || 'Home',
      },
    });
  }
};

const moduleDisplayData: { [module in ProviderModule]: ModuleDisplayData } = {
  [ProviderModule.PROFILE]: {
    linkText: 'Create your profile',
    description: 'to help prospective clients learn about you before booking',
    route: '/onboarding/profile',
    completedRoute: '/settings/profile',
  },
  [ProviderModule.AVAILABILITY]: {
    linkText: 'Build your calendar',
    description: 'by sharing your working hours and availability',
    route: '/onboarding/availability',
    completedRoute: '/settings/calendar',
  },
  [ProviderModule.PAYMENT]: {
    linkText: 'Connect your bank information',
    description: 'to receive automatic payments for sessions',
    route: '/settings/billing?product_tour_id=334103',
  },
  [ProviderModule.PORT_PATIENTS]: {
    linkText: 'Add any of your current clients',
    description: 'to see them through Headway',
    route: '/clients?product_tour_id=334036',
  },
};

interface ModuleTaskStatusProps {
  module: ProviderModule;
  wizard?: ProviderWizardRead;
  isComplete: boolean;
  providerId: number;
}

function ModuleTaskStatus({
  module,
  wizard,
  isComplete,
  providerId,
}: ModuleTaskStatusProps) {
  const queryClient = useQueryClient();
  const [
    wasPortPatientClicked,
    setPortPatientClicked,
    removePortPatientClicked,
  ] = useLocalStorage(CLICKED_PORT_PATIENTS_LOCAL_STORAGE_KEY);

  const { linkText, description, route, completedRoute } =
    moduleDisplayData[module];
  const isInProgress =
    !isComplete && wizard?.currentStep != null && wizard?.completedOn == null;

  const completePortPatient = async (wizard?: ProviderWizardRead) => {
    const completedOn = new Date().toISOString();
    try {
      if (!wizard) {
        await ProviderWizardApi.createProviderWizard({
          providerId,
          module: ProviderModule.PORT_PATIENTS,
          completedOn,
        });
      } else {
        await ProviderWizardApi.updateProviderWizard(wizard.id, {
          completedOn,
        });
      }

      await queryClient.invalidateQueries([
        ONBOARDING_MODULE_STATUSES_CACHE_KEY,
      ]);

      removePortPatientClicked();
    } catch (err) {
      notifyError('There was a problem skipping this step');
      logException(err);
    }
  };

  return (
    <OnboardingTaskItem>
      {isComplete ? (
        <CheckCircle />
      ) : (
        <RadioButtonUnchecked className="text-system-gray" />
      )}
      <span>
        <OnboardingTaskLink
          variant={isComplete ? 'default' : 'brand'}
          to={isComplete && completedRoute ? completedRoute : route}
          onClick={() => {
            captureAnalyticsEvent(module, isInProgress, isComplete);
            if (
              module === ProviderModule.PORT_PATIENTS &&
              !wasPortPatientClicked &&
              !isComplete
            ) {
              setPortPatientClicked('true');
            }
          }}
          {...(route.includes('/onboarding')
            ? { target: '_blank', rel: 'noopener noreferrer' }
            : {})}
        >
          {linkText}
        </OnboardingTaskLink>
        <BodyText>
          <span>&nbsp;-&nbsp;</span>
          {description}
        </BodyText>
      </span>
      {isInProgress && <Badge variant="positive">In progress</Badge>}
      {module === ProviderModule.PORT_PATIENTS &&
        wasPortPatientClicked &&
        !isComplete && (
          <HelixButton
            variant="link"
            size="medium"
            onPress={() => completePortPatient(wizard)}
          >
            Skip For Now
          </HelixButton>
        )}
    </OnboardingTaskItem>
  );
}

interface OnboardingTaskItemProps {
  children: React.ReactNode;
}

function OnboardingTaskItem(props: OnboardingTaskItemProps) {
  return (
    <div className="mt-1 flex items-center py-2" data-testid="onboardingModule">
      <div className="flex items-center gap-2">{props.children}</div>
    </div>
  );
}

type LinkProps = React.ComponentProps<typeof Link> & {
  variant: 'brand' | 'default';
};

function OnboardingTaskLink(props: LinkProps) {
  return (
    <Link
      to={props.to}
      css={{
        color:
          props.variant === 'brand'
            ? theme.color.system.green
            : theme.color.system.textBlack,
        fontWeight: 'bold',
        '&:hover': {
          color:
            props.variant === 'default'
              ? theme.color.system.gray
              : theme.color.system.green,
          textDecoration: 'underline',
        },
      }}
    >
      {props.children}
    </Link>
  );
}
