import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';

export const lateEntryReasonDisplayNames: {
  [key in ProviderProgressNoteLateEntryReason]: string;
} = {
  [ProviderProgressNoteLateEntryReason.SUPERVISION_NEEDED]:
    'Supervision needed/required',
  [ProviderProgressNoteLateEntryReason.CONSULTATION]: 'Consultation needed',
  [ProviderProgressNoteLateEntryReason.TIME_CONSTRAINTS]: 'Time constraints',
  [ProviderProgressNoteLateEntryReason.PERSONAL_LIFE_EVENT]:
    'Personal life event',
  [ProviderProgressNoteLateEntryReason.PROVIDER_ILLNESS]:
    'Provider illness / injury',
  [ProviderProgressNoteLateEntryReason.TECH_ERROR]:
    'Technological error / issues preventing timely submission',
  [ProviderProgressNoteLateEntryReason.ADDITIONAL_INFO]:
    'Waiting for client to provide additional information',
  [ProviderProgressNoteLateEntryReason.OTHER]: 'Other',
};
