import * as React from 'react';
import type { SVGProps } from 'react';

export const WavingHand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#000"
      d="M18.64 7.374 17.018 4.56a2.625 2.625 0 0 0-3.585-.96 2.6 2.6 0 0 0-.844.781l-1.633-2.82a2.625 2.625 0 0 0-4.545 0 2.625 2.625 0 0 0-3.897 3.374l.149.259a2.625 2.625 0 0 0-2.06 3.928l3.749 6.499a8.2 8.2 0 0 0 5.009 3.843 8.25 8.25 0 0 0 9.28-12.094zm-.624 5.872a6.75 6.75 0 0 1-12.365 1.628l-3.75-6.5A1.125 1.125 0 0 1 3.85 7.25l1.803 3.124a.75.75 0 1 0 1.299-.75L3.812 4.186A1.125 1.125 0 1 1 5.76 3.061l2.927 5.063a.75.75 0 1 0 1.298-.75L7.71 3.436a1.125 1.125 0 1 1 1.948-1.125L12.79 7.74a4.5 4.5 0 0 0-.519 5.682.75.75 0 1 0 1.241-.844 3 3 0 0 1 .686-4.078.75.75 0 0 0 .2-.975l-.627-1.088a1.126 1.126 0 0 1 1.264-1.676 1.13 1.13 0 0 1 .683.551l1.623 2.813a6.7 6.7 0 0 1 .675 5.12zM15.273.971a.75.75 0 0 1 .914-.535 5.6 5.6 0 0 1 3.425 2.625l.03.054a.75.75 0 1 1-1.298.75l-.03-.054A4.1 4.1 0 0 0 15.8 1.89a.75.75 0 0 1-.528-.918m-9.69 19.246a.75.75 0 0 1-1.053.125 11.2 11.2 0 0 1-2.775-3.218.75.75 0 0 1 1.3-.75 9.7 9.7 0 0 0 2.403 2.786.75.75 0 0 1 .125 1.057"
    />
  </svg>
);
