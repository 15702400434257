import { BoxArrowUp } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconBoxArrowUp: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={BoxArrowUp}
    ref={ref}
  />
));

IconBoxArrowUp.variants = ['outlined', 'filled'];
IconBoxArrowUp.defaultVariant = 'outlined';
