/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum LoginCredentialsType {
    CAQH = 'CAQH',
    MEDICAID_AK = 'MEDICAID_AK',
    MEDICAID_AL = 'MEDICAID_AL',
    MEDICAID_AR = 'MEDICAID_AR',
    MEDICAID_AZ = 'MEDICAID_AZ',
    MEDICAID_CA = 'MEDICAID_CA',
    MEDICAID_CO = 'MEDICAID_CO',
    MEDICAID_CT = 'MEDICAID_CT',
    MEDICAID_DC = 'MEDICAID_DC',
    MEDICAID_DE = 'MEDICAID_DE',
    MEDICAID_FL = 'MEDICAID_FL',
    MEDICAID_GA = 'MEDICAID_GA',
    MEDICAID_HI = 'MEDICAID_HI',
    MEDICAID_IA = 'MEDICAID_IA',
    MEDICAID_ID = 'MEDICAID_ID',
    MEDICAID_IL = 'MEDICAID_IL',
    MEDICAID_IN = 'MEDICAID_IN',
    MEDICAID_KS = 'MEDICAID_KS',
    MEDICAID_KY = 'MEDICAID_KY',
    MEDICAID_LA = 'MEDICAID_LA',
    MEDICAID_MA = 'MEDICAID_MA',
    MEDICAID_MD = 'MEDICAID_MD',
    MEDICAID_ME = 'MEDICAID_ME',
    MEDICAID_MI = 'MEDICAID_MI',
    MEDICAID_MN = 'MEDICAID_MN',
    MEDICAID_MO = 'MEDICAID_MO',
    MEDICAID_MS = 'MEDICAID_MS',
    MEDICAID_MT = 'MEDICAID_MT',
    MEDICAID_NC = 'MEDICAID_NC',
    MEDICAID_ND = 'MEDICAID_ND',
    MEDICAID_NE = 'MEDICAID_NE',
    MEDICAID_NH = 'MEDICAID_NH',
    MEDICAID_NJ = 'MEDICAID_NJ',
    MEDICAID_NM = 'MEDICAID_NM',
    MEDICAID_NV = 'MEDICAID_NV',
    MEDICAID_NY = 'MEDICAID_NY',
    MEDICAID_OH = 'MEDICAID_OH',
    MEDICAID_OK = 'MEDICAID_OK',
    MEDICAID_OR = 'MEDICAID_OR',
    MEDICAID_PA = 'MEDICAID_PA',
    MEDICAID_PR = 'MEDICAID_PR',
    MEDICAID_RI = 'MEDICAID_RI',
    MEDICAID_SC = 'MEDICAID_SC',
    MEDICAID_SD = 'MEDICAID_SD',
    MEDICAID_TN = 'MEDICAID_TN',
    MEDICAID_TX = 'MEDICAID_TX',
    MEDICAID_UT = 'MEDICAID_UT',
    MEDICAID_VA = 'MEDICAID_VA',
    MEDICAID_VI = 'MEDICAID_VI',
    MEDICAID_VT = 'MEDICAID_VT',
    MEDICAID_WA = 'MEDICAID_WA',
    MEDICAID_WI = 'MEDICAID_WI',
    MEDICAID_WV = 'MEDICAID_WV',
    MEDICAID_WY = 'MEDICAID_WY'
}
