import { View } from '@react-pdf/renderer';
import { useMemo } from 'react';

import { ASRSScoreSummary } from '@headway/api/models/ASRSScoreSummary';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { theme } from '@headway/helix/theme';
import { PdfText } from '@headway/ui/pdf';
import { getOrdinal } from '@headway/ui/utils/numbers';

import {
  getAssessmentScoreDescriptor,
  getScoreRepresentationForAssessmentType,
  isASRSInvestigationWarranted,
} from '../helpers/utils';

type AssessmentResultPdfASRSInsightsProps = {
  score?: number;
  subscores: ASRSScoreSummary;
};

export const AssessmentResultPdfASRSInsights = ({
  score,
  subscores,
}: AssessmentResultPdfASRSInsightsProps) => {
  const assessmentResults = useMemo(() => {
    const insight =
      typeof score === 'number'
        ? getAssessmentScoreDescriptor(PatientAssessmentType.ASRS, score)
        : undefined;

    const investigationWarranted = isASRSInvestigationWarranted(subscores)
      ? 'Investigation warranted'
      : undefined;

    const scoreRepresentation =
      typeof score === 'number'
        ? getScoreRepresentationForAssessmentType(
            score,
            PatientAssessmentType.ASRS
          )
        : undefined;
    const percentile =
      typeof scoreRepresentation === 'number'
        ? `${scoreRepresentation}${getOrdinal(scoreRepresentation)} percentile`
        : undefined;

    return [insight, investigationWarranted, percentile]
      .filter((s) => s !== undefined)
      .join(', ');
  }, [score, subscores]);

  if (!assessmentResults) {
    return null;
  }

  const {
    inattentiveSubscaleRawScore,
    inattentiveSubscalePercentageScore,
    motorHyperactiveImpulsiveSubscaleRawScore,
    motorHyperactiveImpulsiveSubscalePercentageScore,
    verbalHyperactiveImpulsiveSubscaleRawScore,
    verbalHyperactiveImpulsiveSubscalePercentageScore,
  } = subscores;

  return (
    <View style={{ gap: 4 }}>
      <View>
        <PdfText style={{ fontWeight: 'medium' }}>Result:</PdfText>
        <PdfText>{assessmentResults}</PdfText>
      </View>
      <View style={{ paddingLeft: 16 }}>
        <PdfText style={{ color: theme.color.system.gray }}>
          Inattentive subscale: {inattentiveSubscaleRawScore} of 9,{' '}
          {parseFloat((inattentiveSubscalePercentageScore * 100).toFixed(2))}%
        </PdfText>
        <PdfText style={{ color: theme.color.system.gray }}>
          Hyperactive / impulsive (Motor):{' '}
          {motorHyperactiveImpulsiveSubscaleRawScore} of 5,{' '}
          {parseFloat(
            (motorHyperactiveImpulsiveSubscalePercentageScore * 100).toFixed(2)
          )}
          %
        </PdfText>
        <PdfText style={{ color: theme.color.system.gray }}>
          Hyperactive / impulsive (Verbal subscale):{' '}
          {verbalHyperactiveImpulsiveSubscaleRawScore} of 4,{' '}
          {parseFloat(
            (verbalHyperactiveImpulsiveSubscalePercentageScore * 100).toFixed(2)
          )}
          %
        </PdfText>
      </View>
    </View>
  );
};
