// Note: these flag strings should be camelCased, even though in the
//       LaunchDarkly UI and in python they are kebab-cased.

export const ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT =
  'allowGroupPracticeToConfigureOwnBillingAccount';
export const ALWAYS_USE_GP_BILLING_ACCOUNT =
  'alwaysUseGroupPracticeBillingAccount';
export const PATIENT_COLLECTIONS_APPOINTMENT_MENU_PARTIALLY_PAID_BADGE =
  'patientCollectionsAppointmentMenuPartiallyPaidBadge';
export const PHI_GLASS_BREAK = 'updatedAuthzFlow';
export const APPOINTMENT_INVOICE_EVENTS = 'showInvoiceEventsInAtlas';
export const EDIT_PROVIDER_PAYMENT_FROM_NEW_API =
  'editProviderPaymentFromNewApi';
export const USE_ZENDESK_OUTAGE_BANNER = 'useZendeskOutageBanner';
export const TEMP_DISABLE_CONTACT_FORM = 'tempDisableContactForm'; // string that is the banner text
export const FORETHOUGHT_LIVE_CHAT_ENABLED = 'forethoughtLivechatEnabled';
export const LIVE_CHAT_INSURANCE_VERIFICATION =
  'insuranceVerificationViaLivechat';
export const SIGMUND_EMERGENCY_CONTACTS = 'sigmundEmergencyContacts';
export const PROVIDER_PAYMENT_TOOL_WITH_NEW_FIELDS =
  'providerPaymentToolWithNewFields';

export const SITE_MESSAGING_OPPS = 'siteMessagingOpps';
export const ALLOW_IMPERSONATOR_TICKET_SUBMISSION =
  'allowImpersonatorTicketSubmission';

export const SHOW_MESSAGING_UI_FILTERING_BAR = 'showMessageThreadFilteringBar';
export const OPTIMIZED_MESSAGES_VIEW = 'optimizedMessagesView';

export const SHOW_PROVIDER_MESSAGING_UI_BADGE_DOT =
  'showProviderMessagingBadgeDot';

export const MULTI_STATE_CREDENTIALING_BETA = 'multiStateCredentialingBeta';
export const MULTI_STATE_CREDENTIALING_ENTRY_POINTS = 'mscEntryPoints';
export const MULTI_STATE_CREDENTIALING_ONBOARDING = 'mscOnboardingAccess';
export const MULTI_STATE_CREDENTIALING_GUARDRAIL_ROLLOUT =
  'multiStateCredentialingGuardrailRollout';
export const ENABLE_COI_DROPZONE_INSURANCE_STATUS_PAGE = `enableCoiDropzoneInsuranceStatusPage`;
export const MULTI_STATE_CREDENTIALING_MODIFY_PROVIDERS =
  'multiStateCredentialingModifyProviders';
export const MULTI_STATE_CREDENTIALING_AGORA = 'multiStateCredentialingAgora';
export const MULTI_STATE_CREDENTIALING_SEARCH = 'multiStateCredentialingSearch';
export const HIDE_IS_INSURANCE_ACTIVE_OVERRIDE =
  'hideIsInsuranceActiveOverride';
export const FRONT_END_CARRIERS_EXCLUSION_LIST =
  'frontEndCarriersExclusionList';
export const ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION =
  'anthemPatientFacingCarrierConsolidation';

export const HIDE_COMM_PATIENT_OVERRIDE = 'hideCommPatientOverride';

export const AUXILIARY_CONTACT_FORM_BANNER = 'auxiliaryContactFormBanner';

export const TELEHEALTH_LOCATIONS = 'telehealthLocations';

export const TELEHEALTH_LOCATIONS_PROVIDER_STATES =
  'telehealthLocationsProviderStates';
export const TELEHEALTH_LOCATIONS_SIGMUND = 'telehealthLocationsSigmund';
export const ASSESSMENTS_PREVIEW_PANEL = 'assessmentsPreviewPanel';
export const PATIENT_ASSESSMENTS_DATA_VIZ = 'patientAssessmentsDataViz';
export const NQF_OPT_IN = 'nqfOptIn';
export const SDOH = 'socialDeterminantsOfHealth';
export const PROMS_DOWNLOADS = 'promsDownloads';
export const FILL_OUT_PROMS_ON_BEHALF_OF_CLIENT =
  'fillOutPromsOnBehalfOfClient';

export const PATIENTS_TO_HOLD_ON_AUTOMATED_CHARGING =
  'patientsToHoldOnAutomatedCharging';

export const CLOUD_FLARE_IMAGE_TRANSFORM = 'enableCloudflareLoader';

export const REFERRAL_OFFER_AMOUNT = 'referralOfferAmount';
export const EXTOLE_PHASE_2_REFERRAL_PROGRAM = 'extolePhase2ReferralProgram';

export const BENEFITS_PAGE_REVAMP_M2 = 'benefitsPageRevampM2';
export const BENEFITS_PAGE_REVAMP_M2_SERVER_SIDE = 'benefits-page-revamp-m2';
export const NEW_EXIT_OUTAGE_TO_INELIGIBLE_EXPERIENCE =
  'newExitOutageToIneligibleExperience';
export const ACTIVE_CLAIM_FILING_CLEARINGHOUSES_FLAG =
  'activeClaimFilingClearinghouses';

export const PROGRESS_NOTES_SIGNATURE_RESTRICTIONS =
  'progressNotesSignatureRestrictions';
export const PROGRESS_NOTES_LATE_ENTRY = 'progressNotesLateEntry';

export const LICENSE_UPLOAD_DISABLED = 'licenseUploadDisabled';

export const MM_TREATMENT_PLAN = 'mmTreatmentPlanRequirement';

export const EMAIL_HISTORY_VIEWER = 'emailHistoryViewer';

// this group started as a hard exclusion group, but later we launched them
// when national autopay launched
export const LAVENDER_AND_OTHER_AUTOPAY_SPECIAL_GROUPS =
  'excludeFromRequiredAutopay';

export const DEA_INTAKE_FORM_UPDATES = 'deaIntakeFormUpdates';

export const USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS =
  'usePatientRematchingForProviderCancellations';

export const MAX_SESSIONS_OVERRIDE = 'maxSessionsOverride';

export const ENABLE_NO_TELEHEALTH_EXPERIENCE = 'enableNoTelehealthExperience';

export const USE_BENEFITS_MAX_FOR_CLAIM_READINESS =
  'useBenefitsMaxForClaimReadiness';

export const MAX_SESSIONS_EXPERIENCE = 'maxSessionsExperience';

export const PROVIDER_REFERRAL_PROGRAM_AWARENESS =
  'providerReferralProgramAwareness';

export const REACT_QUERY_STALE_TIME = 'reactQueryStaleTime';

export const PPR_FRONTEND_CARRIERS = 'pprCarriers';
export const PROGRESS_NOTES_REQUIREMENTS_REFACTOR =
  'progressNotesFormRequirementsRefactor';

export const STEP_DOWN_MODAL_EXPERIMENT_CALIFORNIA = 'stepDownModalExperiment';
export const STEP_DOWN_MODAL_EXPERIMENT_PENNSYLVANIA =
  'stepDownModalExperimentPa';
export const STEP_UP_MODAL_EXPERIMENT_NEW_YORK = 'stepUpModalExperimentNy';
export const ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP = 'acceptAnthemBankOfAmericaEap';

export const NEW_HUMAN_INPUT_ERROR_PRODUCT_EXPERIENCE =
  'newHumanInputErrorProductExperience';
export const ENABLE_OCR_INSURANCE_CHECKOUT_FLOW =
  'enableOcrInsuranceCheckoutFlow';

export const PROVIDER_DOCUMENT_AUDIT_RESULTS = 'providerDocumentAuditResults';
export const PATIENT_EXPERIMENTATION_TEST = 'patientExperimentationTest';

export const ENABLE_PROVIDER_PANELABILITY_INTAKE_FORM_V2 =
  'enableProviderPanelabilityIntakeFormV2';

export const ENABLE_PROVIDER_PANELABILITY_FOR_NEW_PROVIDERS =
  'enableProviderPanelabilityForNewProviders';
export const ENABLE_PANELABILITY_DELETE_PFECS = 'enablePanelabilityDeletePfecs';

export const CONTROLLED_SUBSTANCE_DATA_COLLECTION =
  'controlledSubstanceDataCollection';

export const PRESCRIBER_PSYCHOTHERAPY_TIMES = 'prescriberPsychotherapyTimes';
export const PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS =
  'prescriberPsychotherapyTimesCarriers';
export const STEP_DOWN_CARE_PILOT_ELIGIBLE = 'stepDownCarePilotEligible';
export const STEP_DOWN_CARE_PILOT_EXPERIENCE = 'stepDownCarePilotExperience';
export const MANAGE_TEAM_ACCESS = 'manageTeamAccess';

export const PROMS_ONLY_RATE_BOOST = 'promsOnlyRateBoost';

export const USE_AUTH0_IN_PATIENT = 'useAuth0InPatient';

export const PASSWORD_COLLECTION_PROVIDER_TASKS =
  'passwordCollectionProviderTasks';
export const CAQH_PASSWORDS_USE_NEW_TABLE = 'caqhPasswordsUseNewTable';

export const SHOW_SAUTEES_EXPERIENCE = 'showSauteesExperience';
export const SSPLAT_UPDATE_FLOW = 'ssplatUpdateFlow';

export const USE_H2_2024_ISSUE_TYPE_TAXONOMY = 'useH22024IssueTypeTaxonomy';
export const SESSION_CONFIRMATION_FLOW_REVAMP = 'sessionConfirmationFlowRevamp';
export const SHOW_NEW_CARRIERS_STATES_EXPERIENCE =
  'showNewCarriersStatesExperience';

export const ENABLE_NEW_GET_CONFIRMABLE_SESSIONS =
  'enableNewGetConfirmableSessions';

export const REMOVE_PHONE_CONSULT_PAYMENT_REQUIREMENT =
  'removePhoneConsultPaymentRequirement';
export const PS_CVR_ONBOARDING = 'psCvrOnboarding';

export const AGORA_PATIENT_CHECKOUT_REDESIGN = 'agoraPatientCheckoutRedesign';

export const PATIENT_MFA_ENABLED = 'patientMfaEnabled';
export const USE_NEW_SELF_PAY_ELIGIBILITY_HELPER =
  'useNewSelfPayEligibilityHelper';
