import { BookOpen } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconBookOpen: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={BookOpen}
    ref={ref}
  />
));

IconBookOpen.variants = ['outlined', 'filled'];
IconBookOpen.defaultVariant = 'outlined';
