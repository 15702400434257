import { createAuth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/browser';
import axiosModule from 'axios';
import { configure as configureMobx } from 'mobx';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';

import { axios as apiAxios, configureMambaUrl } from '@headway/api';
import { UserApi } from '@headway/api/resources/UserApi';
import '@headway/helix/assets/helix.css';
import { withFlagsProvider } from '@headway/shared/FeatureFlags/react';
import { logException } from '@headway/shared/utils/sentry';

import SigmundAuthApi from 'api/AuthApi';
import { axios } from 'api/axios.config';
import { isAdminImpersonatingProviderUser } from 'utils/access';
import {
  addAuth0TokenToConfig,
  Auth0ClientContext,
  redirectToAuth0Login,
  redirectToAuth0Logout,
  redirectToMustResetPassword,
} from 'utils/auth';
import { enableAPIMocking } from 'utils/mockApi/mockApiBrowser';

import App from './App.client';
import './index.scss';
import { initializeAuthStore } from './stores/AuthStore';
import './utils/polyfills';
import { axios as contactInformationApiAxios } from './views/Patients/bulkPatientPorting/resources/ContactInformationApi';

/**
 * Configure mobx to behave as it did before mobx 6
 * See step 6 in https://mobx.js.org/migrating-from-4-or-5.html#getting-started
 */
configureMobx({ enforceActions: 'never' });
configureMambaUrl(process.env.REACT_APP_API_URL);

const SENTRY_DSN = process.env.REACT_APP_SENTRY_PUBLIC_DSN;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    integrations: [Sentry.metrics.metricsAggregatorIntegration()],
  });
}

if (process.env.NODE_ENV === 'development') {
  enableAPIMocking();
}

/** @param {{injectTokenIntoAllRequests: boolean, client?: import('@auth0/auth0-spa-js').Auth0Client}} params */
function addAxiosInterceptors({ injectTokenIntoAllRequests, client }) {
  const start = performance.now();
  const responseInterceptors = [
    (response) => response,
    (error) => {
      // redirect 401s to the login page
      if (
        client &&
        error.response?.status === 401 &&
        window.location.pathname.indexOf('/auth/login') === -1
      ) {
        redirectToAuth0Login(client);
      }
      // redirect 423s to mandatory password reset
      if (
        error.response?.status === 423 &&
        error.response?.data?.message === 'User must reset credentials' &&
        window.location.pathname.indexOf('/auth/reset-password') === -1
      ) {
        redirectToMustResetPassword(client);
      }
      return Promise.reject(error);
    },
  ];

  const requestInterceptors = [
    async (config) => {
      config.headers = config.headers || {};

      if (injectTokenIntoAllRequests) {
        config = await addAuth0TokenToConfig(config, client);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  ];

  // add the logout interceptor to each axios instance we use
  // eventually we can remove axiosModule and axios once we
  // migrate to only using @headway/api

  // Add all instances of Axios (including custom written axios modules) across our system
  // Some API endpoints do not use the codegen due to uploading a csv, add those in here as well
  const modules = [axios, axiosModule, apiAxios, contactInformationApiAxios];

  modules.map((m) => {
    return m.interceptors.request.use(...requestInterceptors);
  });

  modules.map((m) => {
    return m.interceptors.response.use(...responseInterceptors);
  });

  const end = performance.now();
  Sentry.metrics.distribution('add_axios_interceptors_time', end - start, {
    unit: 'millisecond',
  });
}

async function initAndRender() {
  const startInitTime = performance.now();

  let isAuth0Authenticated = null;

  const createAuth0ClientStart = performance.now();

  const auth0Client = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_SIGMUND_URL + '/auth/callback',
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'default openid offline_access',
    },
    useRefreshTokensFallback: true,
    useRefreshTokens: true,
  });

  const createAuth0ClientEnd = performance.now();
  Sentry.metrics.distribution(
    'create_auth0_client_time',
    createAuth0ClientEnd - createAuth0ClientStart,
    {
      unit: 'millisecond',
    }
  );

  const isSpoofing = async () => {
    // Before hitting Auth0, check if we're spoofed from Atlas
    try {
      const meResp = await SigmundAuthApi.me();
      const impersonatingUser = await UserApi.getOriginalUserMe();
      return isAdminImpersonatingProviderUser(meResp.data, impersonatingUser);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        // An uncaught 401 redirects to the login page, but in this context,
        // a 401 simply means we are not spoofing, so catch it and do nothing
      } else if (e.response && e.response.status === 403) {
        // 403 can happen during registration before completing onboarding
      } else {
        // Unexpected error!
        logException(e);
      }
      return false;
    }
  };

  if (
    (window.location.pathname !== '/auth/login' &&
      (new URLSearchParams(window.location.search).has('token') ||
        (await isSpoofing()))) ||
    window.location.pathname === '/auth/reset-password'
  ) {
    addAxiosInterceptors({ injectTokenIntoAllRequests: false });
  } else {
    const checkIsAuthenticatedStart = performance.now();

    isAuth0Authenticated = await auth0Client.isAuthenticated();

    const checkIsAuthenticatedEnd = performance.now();
    Sentry.metrics.distribution(
      'check_is_auth0_authenticated_time',
      checkIsAuthenticatedEnd - checkIsAuthenticatedStart,
      {
        tags: { isAuth0Authenticated: isAuth0Authenticated },
        unit: 'millisecond',
      }
    );

    if (window.location.pathname === '/auth/callback') {
      const handleCallbackStart = performance.now();

      let result = {};
      try {
        result = await auth0Client.handleRedirectCallback();
      } catch (e) {
        // We're seeing "Invalid state" errors here. We don't know the exact
        // behavior leading to this, but we can repro by autocompleting
        // a headway URL from the browser with an old query param code.
        // Logout and redirect back to login to hopefully recover the user.
        try {
          // If the user is already logged in just pretend the error didn't happen.
          await auth0Client.getTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scopes: 'default openid offline_access',
            },
          });
        } catch {
          logException(e);
          await redirectToAuth0Logout(auth0Client);
          return;
        }
      }
      const destination =
        (result && result.appState && result.appState.returnTo) ||
        window.location.origin;
      window.history.replaceState({}, document.title, destination);

      const handleCallbackEnd = performance.now();
      Sentry.metrics.distribution(
        'handle_auth0_redirect_callback_time',
        handleCallbackEnd - handleCallbackStart,
        {
          unit: 'millisecond',
        }
      );
    } else if (!isAuth0Authenticated) {
      // Redirect to Auth0 login if the user is not authenticated yet.
      // Check for the Redirect Query Key and save that to the State for Redirection post login
      await redirectToAuth0Login(auth0Client);
      return;
    }
    addAxiosInterceptors({
      injectTokenIntoAllRequests: true,
      client: auth0Client,
    });
  }

  const isAuthenticated = await auth0Client.isAuthenticated();
  if (isAuthenticated) {
    /**
     * By this point we've authenticated and are sure to have a valid
     * user token.  We can eagerly fetch our user info and configure
     * our feature flags client.
     */
    const authStore = initializeAuthStore();
    await authStore.fetchMe();
  }

  const container = document.getElementById('root');

  const endInitTime = performance.now();
  Sentry.metrics.distribution(
    'sigmund_app_init_time',
    endInitTime - startInitTime,
    {
      tags: {
        isAuth0Authenticated: isAuth0Authenticated,
      },
      unit: 'millisecond',
    }
  );

  const FlagsProvider = await withFlagsProvider({
    clientSideID: import.meta.env.REACT_APP_FLAGS_CLIENT_ID,
  });

  const sigmund = (
    <FlagsProvider>
      <Auth0ClientContext.Provider value={auth0Client}>
        <App includeBrowserRouter />
      </Auth0ClientContext.Provider>
    </FlagsProvider>
  );

  const root = createRoot(container);
  root.render(sigmund);

  const endRenderTime = performance.now();
  Sentry.metrics.distribution(
    'sigmund_app_init_and_render_time',
    endRenderTime - startInitTime,
    {
      tags: {
        isAuth0Authenticated: isAuth0Authenticated,
      },
      unit: 'millisecond',
    }
  );
}

initAndRender();
