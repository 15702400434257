import { useContext, useEffect, useMemo, useState } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';

import { ProgressNotesFormV2Values } from '../../Forms/ProgressNotes/ProgressNotesFormV2';
import { ProgressNoteContext } from '../../stores/ProgressNotesContextV2';

export const useProgressNotesInitialValues = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { progressNotes, isProgressNotesLoading, event, isEventLoading } =
    useContext(ProgressNoteContext);

  const isLoading = isEventLoading || isProgressNotesLoading;

  const initialValues: ProgressNotesFormV2Values = useMemo(() => {
    const noteJson = progressNotes?.noteJson as {
      templateInfo?: { id: number; version: number };
      response?: Record<string, any>;
    };

    return {
      progressNoteType:
        !progressNotes &&
        event?.providerAppointment?.progressNoteType === ProgressNoteType.NONE
          ? undefined
          : event?.providerAppointment?.progressNoteType || undefined,
      template: noteJson?.templateInfo
        ? `${noteJson.templateInfo.id}-${noteJson.templateInfo.version}`
        : undefined,
      previousNote: undefined,
      lateEntryReason: progressNotes?.lateEntryReason ?? undefined,
      lateEntryOtherReason: progressNotes?.lateEntryOtherReason ?? undefined,
      ...noteJson?.response,
    };
  }, [event, progressNotes]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return { isInitialized: isInitialized, initialValues: initialValues };
};
