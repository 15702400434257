import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect } from 'react';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import clinicalLeadHeadshotImage from 'assets/img/michael_heckendorn.png';
import { useProviderPrice } from 'hooks/useProviderPrice';

interface InsuranceOutageToEligibleAlertModalProps {
  client: UserRead;
  open: boolean;
  onClose: () => void;
}

const getPriceString = (min: number | undefined, max: number | undefined) => {
  if (max == null || min == null) {
    return 'Could not be calculated';
  }

  if (min === max) {
    return `$${min.toFixed(2)}`;
  }

  return `$${min.toFixed(2)} - $${max.toFixed(2)}`;
};

export const InsuranceOutageToEligibleAlertModal = ({
  client,
  open,
  onClose,
}: InsuranceOutageToEligibleAlertModalProps) => {
  const provider = useProvider();

  const userInsurance = client.activeUserInsurance;

  const findAppointmentHeldDuringInsuranceOutage = useQuery(
    ['FindAppointmentsHeldInInsuranceOutage', client],
    () => {
      return ProviderEventApi.getEvents({
        event_types: [ProviderEventType.APPOINTMENT],
        patient_user_id: client.id,
        provider_id: provider.id,
        expand_estimated_prices: true,
        limit: 1,
        date_range_start: moment(
          userInsurance?.latestOutageActivatedOn
        ).toISOString(),
        date_range_end: moment(
          userInsurance?.latestOutageDeactivatedOn
        ).toISOString(),
        appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
        order: 'desc',
        order_by: 'start_date',
      });
    },
    { staleTime: Infinity, enabled: open }
  );

  const anyPastConfirmedAppointments = useQuery(
    ['AnyPastConfirmedAppointments', client],
    () => {
      return ProviderEventApi.getEvents({
        event_types: [ProviderEventType.APPOINTMENT],
        patient_user_id: client.id,
        provider_id: provider.id,
        limit: 1,
        date_range_start: moment(
          userInsurance?.latestOutageActivatedOn
        ).toISOString(),
        date_range_end: moment().toISOString(),
        appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
        order: 'desc',
        order_by: 'start_date',
      });
    },
    { staleTime: Infinity, enabled: open }
  );

  const { data: currentPriceEstimate, isLoading: isProviderPriceLoading } =
    useProviderPrice({ provider, client }, { enabled: open });
  const originalPrice =
    findAppointmentHeldDuringInsuranceOutage.data?.data[0]?.providerAppointment
      ?.initialPatientResponsibilityAmount;

  const priceString = getPriceString(
    currentPriceEstimate?.firstSessionMinPrice,
    currentPriceEstimate?.firstSessionMaxPrice
  );

  const frontEndCarrierName = userInsurance?.billingFrontEndCarrierName;
  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  let updatedCostText = `${clientFirstName}’s updated cost estimate is ${priceString}. They’ll pay this cost for future sessions, typically until they reach their deductible.`;
  let updatedCostTextVariant = 'default';
  if (!currentPriceEstimate?.firstSessionMaxPrice) {
    updatedCostTextVariant = 'missing_new_price_estimate';
  } else if (!originalPrice) {
    updatedCostTextVariant = 'missing_original_price_estimate';
  } else if (currentPriceEstimate?.firstSessionMaxPrice > originalPrice) {
    updatedCostText = `${clientFirstName}’s updated cost estimate is ${priceString}, which is higher than the initial estimate. They’ll need to wait for ${frontEndCarrierName}’s final confirmation, but we’ll usually send a second bill in 10-45 days. This will also be their estimated cost for sessions going forward.`;
    updatedCostTextVariant = 'new_price_higher_than_original_price_estimate';
  } else if (currentPriceEstimate?.firstSessionMaxPrice < originalPrice) {
    updatedCostText = `${clientFirstName}’s updated cost estimate is ${priceString}, which is lower than the initial estimate. They’ll need to wait for ${frontEndCarrierName}’s final confirmation, but we can usually refund in 10-45 days. This will also be their estimated cost for sessions going forward.`;
    updatedCostTextVariant = 'new_price_lower_than_original_price_estimate';
  }

  const shouldShowSkeleton =
    isProviderPriceLoading ||
    findAppointmentHeldDuringInsuranceOutage.isLoading ||
    anyPastConfirmedAppointments.isLoading;
  const shouldShowNothing = !client;
  useEffect(() => {
    if (!shouldShowSkeleton && !shouldShowNothing && userInsurance && open)
      trackPageView({
        name: 'Verification Completed Banner Viewed',
        properties: {
          providerId: provider.id,
          patientUserId: client.id,
          userInsuranceId: `${userInsurance.id}`,
          prelimPricingType: `${userInsurance.latestOutageType}`,
          copyVariant: updatedCostTextVariant,
        },
      });
  }, [
    shouldShowSkeleton,
    shouldShowNothing,
    userInsurance,
    provider.id,
    client.id,
    updatedCostTextVariant,
    open,
  ]);

  if (shouldShowNothing) {
    return null;
  }

  if (shouldShowSkeleton) {
    return <Skeleton variant="rectangular" height={40} />;
  }

  return (
    <Modal
      title={
        <div
          css={{
            display: 'flex',
          }}
        >
          <IconCheckCircle
            color={theme.color.system.green}
            height={theme.spacing.x7}
            css={{
              marginRight: theme.spacing.x2,
            }}
          />
          {clientFirstName}’s benefits are verified
        </div>
      }
      isOpen={open}
      onDismiss={onClose}
      data-testId={'planEligibleAlertModal'}
    >
      <ModalContent>
        <div css={greetingText}>
          <BodyText>Hi {provider.displayFirstName},</BodyText>
        </div>
        <div css={section}>
          <BodyText>
            Thanks for your patience. Our benefits verification system is back
            online, so we’ve been able to get an updated session cost estimate
            for {clientFirstName}. Moving forward:
          </BodyText>
        </div>
        <div css={priceEstimateContainer}>
          <IconCreditCardCheck />
          <div css={priceEstimateContent}>
            <BodyText>{updatedCostText}</BodyText>
          </div>
        </div>
        <div css={sectionHeader}>Next steps</div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                {clientFirstName}’s payment method will be charged after your
                session.
              </BodyText>
            </li>
            <li>
              <BodyText>
                {clientFirstName}'s insurer will review their claim. Although
                uncommon, their insurer may adjust the final cost based on plan
                details, like whether they’ve met their deductible.
              </BodyText>
            </li>
            {anyPastConfirmedAppointments.data?.data[0]
              ?.providerAppointment && (
              <li>
                <BodyText>
                  If they were overcharged, we’ll refund them the difference. If
                  they were undercharged, we’ll send them an email with a link
                  to review a second bill.
                </BodyText>
              </li>
            )}
          </ul>
        </div>
        <div css={section}>
          <BodyText>Thanks for your understanding!</BodyText>
        </div>
        <div css={section}>
          <div css={footer}>
            <div>
              <img
                src={clinicalLeadHeadshotImage}
                alt="Clinical Lead Headshot"
                css={clinicalLeadHeadshot}
              />
            </div>
            <div css={signature}>
              <div css={signatureName}>Michael and team</div>
              <BodyText>Clinical Lead at Headway</BodyText>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div css={footerButton}>
          <Button onPress={onClose} variant="primary" size="large">
            Got it
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const greetingText = css`
  margin-bottom: ${theme.spacing.x4};
`;
const sectionHeader = css`
  font-family: PostGrotesk, Roboto, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: ${theme.spacing.x4};
  gap: 16px;
`;
const section = css`
  margin-bottom: ${theme.spacing.x4};
`;
const sectionList = css`
  margin-top: ${theme.spacing.x1};
  padding-left: ${theme.spacing.x6};
`;
const footer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: ${theme.spacing.x3};
`;
const priceEstimateContainer = css`
  display: flex;
  padding: ${theme.spacing.x4} ${theme.spacing.x6};
  flex-wrap: wrap;
  gap: ${theme.spacing.x3};
  background-color: ${theme.color.hue.lightGreen};
  border-radius: 10px;
  margin-bottom: ${theme.spacing.x4};
`;
const priceEstimateContent = css`
  flex: 1;
  display: flex;
  box-sizing: border-box;
`;
const signatureName = css`
  font-weight: bold;
  font-size: 17px;
  display: block;
`;
const clinicalLeadHeadshot = css`
  width: 48px;
  height: 48px;
`;
const signature = css`
  display: flex;
  flex-direction: column;
`;
const footerButton = css`
  text-align: right;
`;
