import { MinusSquare } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconMinusSquare: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={MinusSquare}
    defaultVariant="filled"
    {...props}
    ref={ref}
  />
));

IconMinusSquare.variants = ['filled'];
IconMinusSquare.defaultVariant = 'filled';
