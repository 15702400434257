import moment from 'moment';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { useQuery } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';

export interface FrozenWaivedSessionMaxHitModalProps {
  patient: UserRead;
  provider: ProviderRead;
  open: boolean;
  onClose: () => void;
  onUpdateInsuranceClick?: () => void;
}

const now = moment();

export const FrozenWaivedSessionMaxHitModal = ({
  patient,
  provider,
  open,
  onClose,
  onUpdateInsuranceClick,
}: FrozenWaivedSessionMaxHitModalProps) => {
  const formattedProviderFirstName = formatPatientName(provider, {
    firstNameOnly: true,
  });
  const frontEndCarrierName = patient.activeUserInsurance?.frontEndCarrierName;

  const { data: mostRecentAppointmentData, isLoading: appointmentIsLoading } =
    useQuery(
      ['FindMostRecentAppointment', patient.id],
      () => {
        return ProviderEventApi.getEvents({
          event_types: [ProviderEventType.APPOINTMENT],
          patient_user_id: patient.id,
          provider_id: provider.id,
          expand_estimated_prices: false,
          limit: 1,
          date_range_end: now.toISOString(),
          appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
          order: 'desc',
          order_by: 'start_date',
        });
      },
      { staleTime: Infinity }
    );

  if (appointmentIsLoading) {
    return null;
  }

  const mostRecentAppointmentEvent = mostRecentAppointmentData?.data[0];

  return (
    <Modal
      isDismissable={false}
      title={
        <div className="flex font-bold">
          <IconWarningCircle
            color={theme.color.primary.red}
            height={theme.spacing.x7}
          />
          <span className="ml-3">
            Your client’s {frontEndCarrierName || 'insurance'} plan is
            out-of-network
          </span>
        </div>
      }
      isOpen={open}
    >
      <ModalContent>
        <div className="mb-4">
          <BodyText>Hi {formattedProviderFirstName},</BodyText>
        </div>
        <div className="mb-4">
          <BodyText>
            As you know, we recently learned that your client’s{' '}
            {frontEndCarrierName || 'insurance'} plan is out-of-network with
            Headway. To help ensure that they could keep seeing you we offered
            you some sessions at no cost. Their most recent session
            {mostRecentAppointmentEvent &&
              ` on ${moment(mostRecentAppointmentEvent.startDate).format(
                'MM/DD/YYYY'
              )}`}{' '}
            was their final waived session.
          </BodyText>
        </div>

        <BodyText>
          <div className="mb-4 font-bold">What can they do next? </div>
        </BodyText>

        <div className="mb-4">
          <BodyText>
            If they’d like to continue attending sessions, you can ask them to
            switch to private pay.
          </BodyText>
        </div>

        <BodyText>
          <div className="hlx-typography-body mb-4 font-bold">
            Will Headway ever accept their {frontEndCarrierName || 'insurance'}{' '}
            plan?
          </div>{' '}
        </BodyText>

        <div className="mb-4">
          <BodyText>
            We’re actively working to expand our network and will reach out to
            let you know if our status changes with{' '}
            {frontEndCarrierName || 'this insurance'}. In the meantime, don’t
            hesitate to{' '}
            <Link href="/contact?tags=frozen-waived-session-max-hit">
              reach out
            </Link>{' '}
            with any questions.
          </BodyText>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="text-right">
          {onUpdateInsuranceClick && (
            <span className="mr-4">
              <Button
                onPress={() => {
                  onUpdateInsuranceClick();
                  onClose();
                }}
                variant="secondary"
                size="large"
              >
                Update insurance
              </Button>
            </span>
          )}
          <Button
            onPress={() => {
              onClose();
            }}
            variant="primary"
            size="large"
          >
            Got it
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
