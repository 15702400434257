import type { SnackbarKey } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import { closeToast, notifyError, notifyInfo } from '@headway/ui/utils/notify';

type PdfStates = {
  loading: boolean;
  downloading: boolean;
  error: boolean;
};

export type PdfStateMessages = Required<{
  [K in keyof PdfStates]: string;
}>;

// delay in milliseconds before the "loading" state message appears,
// to skip showing it when the loading is quick enough
const PDF_LOADING_DELAY = 2000;

export const usePdfToasts = (
  { loading, downloading, error }: PdfStates,
  messages: PdfStateMessages
) => {
  const [loadingToast, setLoadingToast] = useState<SnackbarKey>();
  const [loadingTimeout, setLoadingTimeout] = useState<number>();

  const showLoadingToast = useCallback(() => {
    if (loadingToast) {
      return;
    }

    const toastKey = notifyInfo(messages.loading, {
      preventDuplicate: true,
      persist: true,
      onClose: () => {
        setLoadingToast(undefined);
      },
    });

    setLoadingToast(toastKey);
  }, [loadingToast, messages.loading]);

  const scheduleLoadingToast = useCallback(() => {
    if (loadingTimeout) {
      return;
    }

    const timeout = window.setTimeout(() => {
      showLoadingToast();
      setLoadingTimeout(undefined);
    }, PDF_LOADING_DELAY);

    setLoadingTimeout(timeout);
  }, [loadingTimeout, showLoadingToast]);

  const closeLoadingToast = useCallback(() => {
    if (loadingToast) {
      closeToast(loadingToast);
    }

    if (loadingTimeout) {
      window.clearTimeout(loadingTimeout);
    }
  }, [loadingTimeout, loadingToast]);

  useEffect(() => {
    loading ? scheduleLoadingToast() : closeLoadingToast();
  }, [loading, scheduleLoadingToast, closeLoadingToast]);

  useEffect(() => {
    if (downloading) {
      notifyInfo(messages.downloading, { preventDuplicate: true });
    }
  }, [downloading, messages.downloading]);

  useEffect(() => {
    if (error) {
      notifyError(messages.error, { preventDuplicate: true });
    }
  }, [error, messages.error]);
};
