import { flatMap } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { useMedicareOrMedicaid } from '~/legacy/hooks/useMedicareOrMedicaid';

import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { PROGRESS_NOTES_LATE_ENTRY } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import {
  createYupSchema,
  TemplateErrorConfig,
} from '../../Forms/ProgressNotes/templateView/errors';
import { NoteJson } from '../../Forms/ProgressNotes/templateView/schemaRenderer/versions/types';
import {
  createComponentErrorMap,
  getTemplates,
} from '../../Forms/ProgressNotes/templateView/utils';
import { ProgressNoteComponentMetadata } from '../../Forms/ProgressNotes/types';
import { ProgressNoteContext } from '../../stores/ProgressNotesContextV2';
import { useProviderProgressNotesErrors } from '../utils/useProviderProgressNotesErrors';

export const useProgressNotesValidationSchema = () => {
  const { patient, event, progressNotes, selectedPreviousNote } =
    useContext(ProgressNoteContext);

  const progressNotesLateEntryEnabled = useFlag(
    PROGRESS_NOTES_LATE_ENTRY,
    false
  );
  const isMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const hoursSinceEndofAppointment = event?.endDate
    ? Math.floor(
        (new Date().valueOf() - new Date(event.endDate).valueOf()) /
          (1000 * 3600)
      )
    : 0;

  const showLateEntrySection = useMemo(
    () =>
      progressNotesLateEntryEnabled &&
      ((isMedicareOrMedicaid && hoursSinceEndofAppointment >= 48) ||
        (!isMedicareOrMedicaid && hoursSinceEndofAppointment >= 72)),
    [
      progressNotesLateEntryEnabled,
      isMedicareOrMedicaid,
      hoursSinceEndofAppointment,
    ]
  );

  const { data: progressNotesErrors } = useProviderProgressNotesErrors(
    {
      providerAppointmentId: event?.providerAppointment?.id!,
      noteJson: JSON.stringify({
        templateInfo: {
          id: (progressNotes?.noteJson as NoteJson)?.templateInfo.id,
          name: (progressNotes?.noteJson as NoteJson)?.templateInfo.name,
          version: (progressNotes?.noteJson as NoteJson)?.templateInfo.version,
          schemaVersion: (progressNotes?.noteJson as NoteJson)?.templateInfo
            .schemaVersion,
        },
        response: {},
      }),
      noteJsonPrefilledFrom: selectedPreviousNote,
    },
    {
      enabled: !!progressNotes,
    }
  );

  const lateEntrySchema = useMemo(() => {
    return showLateEntrySection
      ? {
          lateEntryReason: Yup.string()
            .nullable()
            .required('Please select a reason for late entry'),
          lateEntryOtherReason: Yup.string()
            .nullable()
            .when('lateEntryReason', {
              is: ProviderProgressNoteLateEntryReason.OTHER,
              then: Yup.string().nullable().required('Please provide a reason'),
            }),
        }
      : undefined;
  }, [showLateEntrySection]);

  const templates = useMemo(
    () => getTemplates<ProgressNoteComponentMetadata>(),
    []
  );
  const getTemplate = useCallback(
    (id?: number, version?: number) =>
      id !== undefined && version !== undefined && templates
        ? templates.find(
            (t) =>
              t.templateInfo.id === id && t.templateInfo.version === version
          )
        : undefined,
    [templates]
  );

  const progressNoteSchema = useMemo(() => {
    if (
      progressNotesErrors &&
      progressNotesErrors.length > 0 &&
      (progressNotes?.noteJson as NoteJson)?.templateInfo
    ) {
      const template = getTemplate(
        (progressNotes?.noteJson as NoteJson)?.templateInfo.id,
        (progressNotes?.noteJson as NoteJson)?.templateInfo.version
      );
      const componentErrorMap = createComponentErrorMap(
        progressNotesErrors,
        template!
      );
      const componentErrorMapAsArray = flatMap(
        componentErrorMap,
        (val: TemplateErrorConfig, key) => ({
          id: key,
          type: val?.type,
          validationType: val?.validationType,
          validations: val?.validations,
        })
      );

      return componentErrorMapAsArray.reduce(createYupSchema, {});
    }
    return undefined;
  }, [progressNotesErrors, progressNotes?.noteJson, getTemplate]);

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          progressNote: Yup.object().shape({
            ...progressNoteSchema,
            ...lateEntrySchema,
          }),
        })
        .required(),
    [progressNoteSchema, lateEntrySchema]
  );

  return schema;
};
