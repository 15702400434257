import React from 'react';

import { Avatar } from '@headway/helix/Avatar';
import { Badge } from '@headway/helix/Badge';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { ListRow } from '@headway/helix/List';
import { ListHeader } from '@headway/helix/ListHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';

export enum TaskIntent {
  ALERT = 'alert',
  INFORM = 'inform',
}

interface TaskProps {
  icon: React.ReactNode;
  listHeaderText: React.ReactNode | string;
  subBodyText: string;
  secondaryAction: React.ReactNode;
  primaryAction: React.ReactNode;
  badgeText?: React.ReactNode;
  children?: React.ReactNode;
  childrenVerticalSpacing?: string;
  taskIntent?: TaskIntent;
}

export const Task = ({
  icon,
  listHeaderText,
  subBodyText,
  secondaryAction,
  primaryAction,
  badgeText,
  children,
  childrenVerticalSpacing,
  taskIntent = TaskIntent.INFORM,
}: TaskProps) => {
  const padding = childrenVerticalSpacing || `${theme.spacing.x3} 0`;
  const avatarBackgroundColor =
    taskIntent === TaskIntent.ALERT
      ? theme.color.hue.lightBlue
      : theme.color.system.lightGray;

  return (
    <ListRow>
      <div
        css={{
          padding: padding,
          ...theme.stack.horizontal,
          alignItems: 'center',
        }}
      >
        <div
          css={{
            ...theme.stack.horizontal,
            flexBasis: '60%',
            marginRight: theme.spacing.x3,
          }}
        >
          <div css={{ marginRight: theme.spacing.x4, alignSelf: 'center' }}>
            <Avatar
              backgroundColor={avatarBackgroundColor}
              size="medium"
              aria-hidden={true}
              children={icon}
            />
          </div>
          <div css={{ ...theme.stack.vertical }}>
            <ListHeader>{listHeaderText}</ListHeader>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <SubBodyText>{subBodyText}</SubBodyText>
              {badgeText && (
                <div css={{ marginLeft: theme.spacing.x2 }}>
                  <Badge variant="negative" icon={IconWarningCircle}>
                    {badgeText}
                  </Badge>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          css={{
            columnGap: theme.spacing.x4,
            flexBasis: '40%',
            ...theme.stack.horizontal,
          }}
        >
          <div
            css={{
              flexBasis: '110px',
              textAlign: 'center',
              ...theme.stack.vertical,
            }}
          >
            {secondaryAction}
          </div>
          <div
            css={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            {primaryAction}
          </div>
        </div>
      </div>
      {children}
    </ListRow>
  );
};
