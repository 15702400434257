import { CustomerType } from '@headway/api/models/CustomerType';
import { InsuranceVerificationSecondaryIssueType } from '@headway/api/models/InsuranceVerificationSecondaryIssueType';
import { PaymentsBonusesSecondaryIssueType } from '@headway/api/models/PaymentsBonusesSecondaryIssueType';
import { ProviderIssueType } from '@headway/api/models/ProviderIssueType';

const BASE_URL = `${process.env.REACT_APP_MAIN_URL}/contact`;
export const getVerifyInsuranceBenefitsProviderRedirectUrl = () => {
  let queryStringProvider = new URLSearchParams();
  queryStringProvider.append(
    'submitterType',
    CustomerType.PROVIDER_ON_BEHALF_OF_CLIENT
  );
  queryStringProvider.append(
    'primaryIssueType',
    ProviderIssueType.CLIENT_BILLING_INSURANCE_ISSUES
  );
  queryStringProvider.append(
    'secondaryIssueType',
    InsuranceVerificationSecondaryIssueType.INACTIVE_INSURANCE_DETAILS
  );

  return `${BASE_URL}?${queryStringProvider.toString()}`;
};

export function getProductIssuesContactLink(errorMessage: string) {
  return (
    `${BASE_URL}?` +
    `submitterType=${CustomerType.PROVIDER_ON_BEHALF_OF_SELF}&` +
    `primaryIssueType=${ProviderIssueType.PRODUCT_ISSUES}&` +
    `subject=Application Crashed&` +
    `description=Error Message: "${errorMessage}"`
  );
}

export function getDisputePriceEstimateRedirectUrl(clientId: number) {
  return (
    `${BASE_URL}?` +
    `submitterType=${CustomerType.PROVIDER_ON_BEHALF_OF_CLIENT}&` +
    `primaryIssueType=${ProviderIssueType.CLIENT_BILLING_INSURANCE_ISSUES}&` +
    `secondaryIssueType=${InsuranceVerificationSecondaryIssueType.INCORRECT_INSURANCE_DETAILS}&` +
    `clientId=${clientId}`
  );
}

export function getSessionPayoutContactLink() {
  return `${BASE_URL}?submitterType=${CustomerType.PROVIDER_ON_BEHALF_OF_SELF}&primaryIssueType=${ProviderIssueType.PAYMENTS_OR_BONUSES}&secondaryIssueType=${PaymentsBonusesSecondaryIssueType.SESSION_PAYOUTS}`;
}
