import { useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { mergeProps, useFocusRing, useHover, useLink } from 'react-aria';

import { IconCalendarDots } from './icons/CalendarDots';
import { IconCeu } from './icons/Ceu';
import { IconEnvelopeSimple } from './icons/EnvelopeSimple';
import { IconGear } from './icons/Gear';
import { IconHeadwayAcademy } from './icons/HeadwayAcademy';
import { IconHouse } from './icons/House';
import { IconIdentificationCard } from './icons/IdentificationCard';
import { IconMoney } from './icons/Money';
import { IconNote } from './icons/Note';
import { IconQuestion } from './icons/Question';
import { IconUser } from './icons/User';
import { IconUserPlus } from './icons/UserPlus';
import { IconUsers } from './icons/Users';
import { LinkProps } from './Link';

type NavigationLinkProps<T extends 'a' | React.JSXElementConstructor<any>> =
  LinkProps<T> & {
    icon: React.ComponentType;
  };

function NavigationLink<T extends 'a' | React.JSXElementConstructor<any>>({
  component: Component = 'a',
  icon: Icon,
  elementType,
  ...props
}: NavigationLinkProps<T>) {
  const ref = React.useRef<HTMLAnchorElement>(null);

  const [content, decoration] = React.Children.toArray(props.children);
  const decorationId = useSlotId([Boolean(decoration)]);

  const { linkProps, isPressed } = useLink(
    {
      elementType: elementType,
      isDisabled: props.disabled,
      ...props,
      'aria-label': props['aria-label'],
      'aria-describedby':
        [decorationId, props['aria-describedby']].filter(Boolean).join(' ') ||
        undefined,
    },
    ref
  );
  const { hoverProps, isHovered } = useHover({ isDisabled: props.disabled });
  const { focusProps, isFocused, isFocusVisible } = useFocusRing({
    autoFocus: props.autoFocus,
  });

  return (
    <Component
      className={clsx('hlx-navigation-link', {
        'focus-ring': isFocusVisible,
        focused: isFocused && !props.disabled,
      })}
      data-hlx-hovered={isHovered}
      data-hlx-pressed={isPressed}
      data-hlx-disabled={props.disabled}
      {...props}
      {...mergeProps(linkProps, hoverProps, focusProps)}
      ref={ref}
    >
      <span className="hlx-navigation-link-icon" aria-hidden>
        <Icon />
      </span>
      {content}
      <div id={decorationId}>{decoration}</div>
    </Component>
  );
}

interface NavigationProps {
  children: React.ReactNode[];
  /**
   * Affects the layout of the component within its container. 'default' is a fixed width, while
   * 'full-width' fills the container.
   */
  layout?: 'default' | 'full-width';
}

function Navigation(props: NavigationProps) {
  return (
    <nav className="hlx-navigation" data-hlx-layout={props.layout || 'default'}>
      <ul className="hlx-navigation-list">
        {React.Children.map(props.children, (child) => {
          if (!child) {
            return null;
          }

          if (!React.isValidElement(child)) {
            throw new Error('Navigation children must be valid elements');
          }

          if (child.type === NavigationDivider) {
            return child;
          }

          return <li>{child}</li>;
        })}
      </ul>
    </nav>
  );
}

function NavigationDivider() {
  return (
    <li role="presentation">
      <hr className="hlx-navigation-divider" />
    </li>
  );
}

export function NavigationIconCalendar() {
  return (
    <>
      <IconCalendarDots variant="outlined" />
      <IconCalendarDots variant="filled" />
    </>
  );
}
export function NavigationIconClients() {
  return (
    <>
      <IconUsers variant="outlined" />
      <IconUsers variant="filled" />
    </>
  );
}
export function NavigationIconCredentialForm() {
  return (
    <>
      <IconNote variant="outlined" />
      <IconNote variant="filled" />
    </>
  );
}
export function NavigationIconDashboard() {
  return (
    <>
      <IconHouse variant="outlined" />
      <IconHouse variant="filled" />
    </>
  );
}
export function NavigationIconHelpCenter() {
  return (
    <>
      <IconQuestion variant="outlined" />
      <IconQuestion variant="filled" />
    </>
  );
}
export function NavigationIconAcademy() {
  return (
    <>
      <IconHeadwayAcademy variant="outlined" />
      <IconHeadwayAcademy variant="filled" />
    </>
  );
}
export function NavigationIconInsuranceStatus() {
  return (
    <>
      <IconIdentificationCard variant="outlined" />
      <IconIdentificationCard variant="filled" />
    </>
  );
}
export function NavigationIconMessages() {
  return (
    <>
      <IconEnvelopeSimple variant="outlined" />
      <IconEnvelopeSimple variant="filled" />
    </>
  );
}
export function NavigationIconPayments() {
  return (
    <>
      <IconMoney variant="outlined" />
      <IconMoney variant="filled" />
    </>
  );
}
export function NavigationIconPerson() {
  return (
    <>
      <IconUser variant="outlined" />
      <IconUser variant="filled" />
    </>
  );
}
export function NavigationIconReferral() {
  return (
    <>
      <IconUserPlus variant="outlined" />
      <IconUserPlus variant="filled" />
    </>
  );
}
export function NavigationIconSettings() {
  return (
    <>
      <IconGear />
      <IconGear variant="filled" />
    </>
  );
}

export function NavigationIconCEU() {
  return (
    <>
      <IconCeu />
      <IconCeu variant="filled" />
    </>
  );
}

export { Navigation, NavigationDivider, NavigationLink };
