import { HandWaving } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconHandWaving: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={HandWaving}
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    ref={ref}
  />
));

IconHandWaving.variants = ['outlined', 'filled'];
IconHandWaving.defaultVariant = 'outlined';
