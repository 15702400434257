import { CalendarDots } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconCalendarDots: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    phosphorIcon={CalendarDots}
    ref={ref}
  />
));

IconCalendarDots.variants = ['outlined', 'filled'];
IconCalendarDots.defaultVariant = 'outlined';
