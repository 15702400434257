import React, { ReactNode } from 'react';

import { theme } from './theme';

export interface HeadlineTextProps {
  children: ReactNode;
  // variant?: keyof (typeof theme)['typography']['headline'];
  variant?: 'H2' | 'H3' | 'H4' | 'H5';
}

const variantToClassName: Record<
  NonNullable<HeadlineTextProps['variant']>,
  string
> = {
  H2: 'hlx-typography-headline-h2',
  H3: 'hlx-typography-headline-h3',
  H4: 'hlx-typography-headline-h4',
  H5: 'hlx-typography-headline-h5',
};

export const HeadlineText = ({
  children,
  variant = 'H2',
}: HeadlineTextProps) => {
  return <span className={variantToClassName[variant]}>{children}</span>;
};
