import { createCalendar } from '@internationalized/date';
import { useSlotId } from '@react-aria/utils';
import { DatePickerProps, DateValue } from '@react-types/datepicker';
import React from 'react';
import { FocusRing, mergeProps, useDateField } from 'react-aria';
import { useDateFieldState } from 'react-stately';

import { DATA } from '../consts';
import { IconCalendarDots } from '../icons/CalendarDots';
import { useAssertFormParentEffect } from '../useAssertFormParentEffect';
import { FormInputProps, useFormInput } from '../useFormInput';
import { EditableDateTimeSegment, LiteralDateTimeSegment } from './common';

type DateFieldProps = {
  optionalityText?: React.ReactNode;
} & FormInputProps<DateValue> &
  Pick<DatePickerProps<DateValue>, 'minValue' | 'maxValue'>;

function DateField(props: DateFieldProps) {
  let { disabled, readonly } = props;

  const rootRef = React.useRef<HTMLDivElement>(null);

  const { ariaProps, hoverProps, rootProps, isFocused, focusProps } =
    useFormInput({
      ...props,
      isTextInput: true,
    });

  let state = useDateFieldState({
    ...props,
    ...ariaProps,
    onChange: (value: DateValue) => {
      props.onChange?.(value);
    },
    isDisabled: disabled,
    isReadOnly: readonly,
    hideTimeZone: true,
    granularity: 'day',
    locale: 'en-US',
    createCalendar,
  });

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  let controlRef = React.useRef(null);
  let { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useDateField(
      {
        ...props,
        ...ariaProps,
        hideTimeZone: true,
        isDisabled: disabled,
        isReadOnly: readonly,
        granularity: 'day',
      },
      state,
      controlRef
    );

  useAssertFormParentEffect(controlRef, 'DateField', props.name);

  return (
    <div ref={rootRef} className="hlx-date-field-root" {...rootProps}>
      <div className="hlx-date-field-label" {...labelProps}>
        {props.label}
      </div>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
        within={true}
      >
        <div
          ref={controlRef}
          className="hlx-date-field-control"
          {...mergeProps(
            fieldProps,
            hoverProps,
            focusProps,
            {
              [DATA.FOCUSED]: isFocused,
            },
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string }
          )}
        >
          {state.segments.map((segment, i) => {
            if (segment.type === 'literal') {
              return <LiteralDateTimeSegment key={i} segment={segment} />;
            }

            return (
              <EditableDateTimeSegment
                key={i}
                segment={segment}
                state={state}
              />
            );
          })}
        </div>
      </FocusRing>

      <div className="hlx-date-field-adornment">
        <IconCalendarDots />
      </div>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-date-field-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-date-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-date-field-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
}

export { DateField };
