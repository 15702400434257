import { Table } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconTable: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={Table}
    ref={ref}
  />
));

IconTable.variants = ['outlined', 'filled'];
IconTable.defaultVariant = 'outlined';
