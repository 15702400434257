import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseSessionDetailsConfirmabilityKeyArgs {
  providerEventVirtualId: string | undefined;
  shouldCheckProviderPatientSpecificDetails: boolean;
}
export type UseSessionDetailsConfirmabilityQueryKey = [
  string,
  string | undefined,
  boolean,
];

export const getUseSessionDetailsConfirmabilityKey = ({
  providerEventVirtualId,
  shouldCheckProviderPatientSpecificDetails,
}: UseSessionDetailsConfirmabilityKeyArgs): UseSessionDetailsConfirmabilityQueryKey => [
  'session-details-confirmability',
  providerEventVirtualId,
  shouldCheckProviderPatientSpecificDetails,
];

const {
  useSingleQuery: useSessionDetailsConfirmability,
  useListQuery: useSessionDetailsConfirmabilityList,
} = createBasicApiHooks(
  getUseSessionDetailsConfirmabilityKey,
  ({ providerEventVirtualId, shouldCheckProviderPatientSpecificDetails }) =>
    ProviderEventApi.getSessionDetailsConfirmability(providerEventVirtualId!, {
      should_check_provider_patient_specific_details:
        shouldCheckProviderPatientSpecificDetails,
    }),
  ({ providerEventVirtualId }) => {
    return !!providerEventVirtualId;
  },
  () => 'Failed to fetch session details confirmability'
);

export { useSessionDetailsConfirmability, useSessionDetailsConfirmabilityList };
