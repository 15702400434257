import { AccountBalance, CreditCard } from '@mui/icons-material';
import React from 'react';

import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';
import { Badge } from '@headway/helix/Badge';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { Amex } from '@headway/icons/dist/Amex';
import { Discover } from '@headway/icons/dist/Discover';
import { Mastercard } from '@headway/icons/dist/Mastercard';
import { Visa } from '@headway/icons/dist/Visa';
import { theme as legacyTheme } from '@headway/ui/theme';

const CreditCardBrandIcon = ({ brand }: { brand?: string }) => {
  switch (brand) {
    case 'American Express':
      return <Amex aria-label="American Express" height={24} width={36} />;
    case 'Visa':
      return <Visa aria-label="Visa" height={24} width={36} />;
    case 'MasterCard':
      return <Mastercard aria-label="MasterCard" height={24} width={36} />;
    case 'Discover':
      return <Discover aria-label="Discover" height={24} width={36} />;
    default:
      return <CreditCard />;
  }
};

interface FinancialAccountProps {
  userPaymentMethod: UserPaymentMethodRead;
  variant?: 'mui' | 'helix';
  disabled?: boolean;
}

export const FinancialAccount: React.FC<
  React.PropsWithChildren<FinancialAccountProps>
> = ({ userPaymentMethod, variant = 'mui', disabled, children }) => {
  const { type, isDefault } = userPaymentMethod;
  const brand = userPaymentMethod.card?.brand;
  const bankName = userPaymentMethod.bankAccount?.bankName;
  const name =
    type === UserPaymentMethodType.CARD
      ? brand || 'Credit card'
      : type === UserPaymentMethodType.BANK_ACCOUNT
      ? bankName || 'Bank account'
      : 'Account';
  const last4 =
    type === UserPaymentMethodType.CARD
      ? userPaymentMethod.card?.last4
      : userPaymentMethod.bankAccount?.last4;
  const verificationStatus = userPaymentMethod.bankAccount?.verificationStatus;
  const isHsaOrFsaOrHra = !!userPaymentMethod.dateAttestedToFsaHsaHraFunding;

  const cardHasPreAuthError =
    !!userPaymentMethod.card && !userPaymentMethod.isVerified;

  return (
    <div
      className={`${
        disabled ? 'bg-system-backgroundGray' : ''
      } border-system-borderGray flex items-center justify-between gap-x-2 rounded border border-solid px-5 py-3`}
    >
      <div className="flex items-center gap-2 overflow-hidden">
        {userPaymentMethod.isVerified && (
          <div className="flex h-6 items-center">
            <IconCheckCircle
              size={20}
              css={{
                color:
                  variant === 'helix'
                    ? theme.color.system.green
                    : legacyTheme.color.primary,
              }}
              width={20}
            />
          </div>
        )}
        <div
          className={`flex items-center justify-center text-center ${
            variant === 'helix' ? 'text-black' : 'text-gray'
          }`}
        >
          {userPaymentMethod.type === UserPaymentMethodType.CARD ? (
            <CreditCardBrandIcon brand={brand} aria-hidden />
          ) : (
            <div className="border-system-borderGray flex h-6 w-9 items-center justify-center rounded border-[1px] border-solid px-2 py-[2px]">
              <AccountBalance
                fontSize="inherit"
                className="text-system-textBlack h-4 w-4"
                aria-hidden
              />
            </div>
          )}
        </div>
        <div className="flex w-full flex-1 flex-col flex-wrap justify-center gap-1 overflow-hidden text-start">
          <div
            className={`flex w-full items-center gap-x-2 ${
              variant === 'helix'
                ? 'hlx-typography-body'
                : 'font-sans text-base'
            } ${disabled ? 'text-system-gray' : ''}`}
          >
            <div className="flex w-full flex-wrap items-center gap-x-1">
              <span className="truncate font-bold">{name}</span> ending in{' '}
              {last4}
              {isDefault && <Badge variant="neutral">Default</Badge>}
            </div>
          </div>
          {isHsaOrFsaOrHra && <SubBodyText>HSA, FSA or HRA card</SubBodyText>}
        </div>
        {verificationStatus ===
        BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION ? (
          <Badge variant="warning">Pending automatic verification</Badge>
        ) : verificationStatus ===
          BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION ? (
          <Badge variant="warning">Pending manual verification</Badge>
        ) : verificationStatus ===
            BankAccountVerificationStatus.VERIFICATION_FAILED ||
          cardHasPreAuthError ? (
          <Badge variant="negative" icon={IconWarningCircle}>
            Verification failed
          </Badge>
        ) : null}
      </div>
      {children}
    </div>
  );
};
