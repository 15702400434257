import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserFreezeApi } from '@headway/api/resources/UserFreezeApi';
import { useQuery } from '@headway/shared/react-query';

import { PROVIDER_USER_FREEZES_CACHE_KEY } from '../utils/cacheKeys';

export interface UseProviderUserFreezes {
  freezeReasonsByUser: { [userId: string]: UserFreezeReason[] };
  isLoading: boolean;
  isError: boolean;
}

export const useProviderUserFreezes = (
  providerUserId: number
): UseProviderUserFreezes => {
  const { data, isLoading, isError } = useQuery(
    [PROVIDER_USER_FREEZES_CACHE_KEY, providerUserId],
    async () => await UserFreezeApi.getActiveFreezesForPatients(providerUserId)
  );

  const freezeReasonsByUser = data;
  return {
    freezeReasonsByUser: freezeReasonsByUser || {},
    isLoading: isLoading,
    isError: isError,
  };
};
