import { ErrorTwoTone } from '@mui/icons-material';
import { Alert } from '@mui/material';

import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';

export interface ReferalIntakeCallAlertProps {
  clientId: number;
}

export const ReferalIntakeCallAlert = ({
  clientId,
}: ReferalIntakeCallAlertProps) => {
  const { data: client } = useUser({
    userId: clientId,
  });

  const clientFirstName = client?.firstName;

  return (
    <Alert
      color="warning"
      icon={<ErrorTwoTone />}
      css={{ marginTop: theme.spacing.x3 }}
    >
      <div className={'font-bold'}>Missing items for a full session</div>
      <div>
        While not required for a phone consultation, {clientFirstName}'s payment
        information and consent forms must be added before their first full
        session. We have reached out to {clientFirstName} to add these items.
      </div>
    </Alert>
  );
};
