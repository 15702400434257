import moment from 'moment';
import { useState } from 'react';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ASRSScoreSummary } from '@headway/api/models/ASRSScoreSummary';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconCheck } from '@headway/helix/icons/Check';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import { theme } from '@headway/helix/theme';
import { UseQueryResult } from '@headway/shared/react-query';
import { checkExhaustive } from '@headway/shared/utils/types';
import { getOrdinal } from '@headway/ui/utils/numbers';

import { usePatientAssessmentScores } from 'hooks/usePatientAssessmentScores';
import { useProvider } from 'hooks/useProvider';

import { TIME_SCALE_TO_DURATION } from '../helpers/constants';
import {
  AssessmentBadgeInfo,
  SelectedAssessmentInfo,
  TimeScale,
} from '../helpers/types';
import {
  getBadgeVariantForAssessmentScoreDiff,
  getLineChartXDomain,
  getLineChartXTicks,
  getLineChartYDomain,
  getScoreRepresentationForAssessmentType,
  getVariantForAssessmentResult,
  indicatesSiRisk,
} from '../helpers/utils';
import { NegativeGlyph } from './glyphs/NegativeGlyph';
import { PositiveGlyph } from './glyphs/PositiveGlyph';
import { SafetyRiskGlyph } from './glyphs/SafetyRiskGlyph';
import { WarningGlyph } from './glyphs/WarningGlyph';
import { ResultBadges } from './InsightBadges';

interface ScoreDiffProps {
  assessmentType: PatientAssessmentType;
  prevScore: number;
  currentScore: number;
}

/** Text which displays the difference between two assessment scores. */
export const ScoreDiff = ({
  assessmentType,
  prevScore,
  currentScore,
}: ScoreDiffProps) => {
  const diff =
    getScoreRepresentationForAssessmentType(currentScore, assessmentType) -
    getScoreRepresentationForAssessmentType(prevScore, assessmentType);
  const diffRepresentation = getScoreRepresentationForAssessmentType(
    diff,
    assessmentType
  );

  const variant = getBadgeVariantForAssessmentScoreDiff(assessmentType, diff);
  if (!(variant === 'negative' || variant === 'positive')) {
    return null;
  }
  return (
    <CaptionText color={variant === 'negative' ? 'red' : 'green'}>
      <b>
        {diffRepresentation === 0
          ? ''
          : diffRepresentation > 0
          ? `↑ ${diffRepresentation}`
          : `↓ ${-diffRepresentation}`}
      </b>
    </CaptionText>
  );
};
interface AssessmentLineChartProps {
  providerPatientId: number;
  clientId: number;
  assessmentType: PatientAssessmentType;
  onAssessmentSelected: (
    selectedAssessmentInfo: SelectedAssessmentInfo
  ) => void;
  addInitiallyVisibleAssessmentIds: (
    assessmentIdsByType: Set<number>,
    assessmentType: PatientAssessmentType
  ) => void;
}

interface LineChartDatum {
  id: number;
  score: number;
  safetyRisk: boolean;
  completedOn: Date;
  assessmentType: PatientAssessmentType;
  subscores?: ASRSScoreSummary;
  prevScore?: number;
}

const TIME_SCALE_DISPLAY_ORDER = [
  TimeScale.ALL,
  TimeScale.ONE_MONTH,
  TimeScale.THREE_MONTHS,
  TimeScale.SIX_MONTHS,
  TimeScale.ONE_YEAR,
];

const TIME_SCALE_TO_DISPLAY_NAME: { [scale in TimeScale]: string } = {
  [TimeScale.ALL]: 'All',
  [TimeScale.ONE_MONTH]: '1 month',
  [TimeScale.THREE_MONTHS]: '3 months',
  [TimeScale.SIX_MONTHS]: '6 months',
  [TimeScale.ONE_YEAR]: '1 year',
};

const tickFormatter = (timestamp: number) =>
  moment(timestamp).format('MMM').toUpperCase();

export const AssessmentLineChart = ({
  providerPatientId,
  clientId,
  assessmentType,
  onAssessmentSelected,
  addInitiallyVisibleAssessmentIds,
}: AssessmentLineChartProps) => {
  const [timeScale, setTimeScale] = useState<TimeScale>(TimeScale.ALL);
  // id of assessment being hovered over
  const [activeAssessmentId, setActiveAssessmentId] = useState<
    number | undefined
  >(undefined);
  const [
    hasCalledAddInitiallyVisibleAssessmentIds,
    setHasCalledAddInitiallyVisibleAssessmentIds,
  ] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  // API automatically sorts by completedOn in ascending order
  const { data: chartData }: UseQueryResult<LineChartDatum[]> =
    usePatientAssessmentScores(
      {
        providerPatientId,
        assessmentType,
      },
      {
        select: (data) =>
          data.map((assessment, idx) => ({
            id: assessment.id,
            score: assessment.score,
            safetyRisk: !!assessment.safetyRisk,
            completedOn: new Date(assessment.completedOn),
            assessmentType: assessment.assessmentType,
            // this check is required to prevent prevScores in between data points
            prevScore: idx >= 1 ? data[idx - 1].score : undefined,
            subscores: assessment.subscores,
          })),
      }
    );
  const provider = useProvider();

  if (!chartData?.length) {
    return null;
  }

  const assessmentIdsByType = new Set(chartData.map((datum) => datum.id));
  if (
    !hasCalledAddInitiallyVisibleAssessmentIds &&
    assessmentIdsByType.size > 0
  ) {
    setHasCalledAddInitiallyVisibleAssessmentIds(true);
    addInitiallyVisibleAssessmentIds(assessmentIdsByType, assessmentType);
  }

  // Filter out time scales that are functionally the same as "All"
  const selectableTimeScales = TIME_SCALE_DISPLAY_ORDER.filter((scale) => {
    const minDate = moment()
      .subtract(TIME_SCALE_TO_DURATION[scale])
      .startOf('day');
    return (
      !!minDate &&
      (scale === TimeScale.ALL ||
        moment(minDate).isAfter(chartData[0]?.completedOn))
    );
  });

  const minDate =
    timeScale === TimeScale.ALL
      ? undefined
      : moment().subtract(TIME_SCALE_TO_DURATION[timeScale]).startOf('day');
  const visibleChartData = minDate
    ? chartData.filter(({ completedOn }) =>
        moment(completedOn).isSameOrAfter(minDate)
      )
    : chartData;

  const domain = getLineChartXDomain(
    visibleChartData[0]?.completedOn.valueOf(),
    visibleChartData[visibleChartData.length - 1]?.completedOn.valueOf(),
    timeScale
  );
  const ticks: number[] = getLineChartXTicks(domain);

  const mostRecentAssessment = visibleChartData[visibleChartData.length - 1];

  const AssessmentResultsTooltip: React.FC<any> = ({ active, payload }) => {
    const chartDatum: LineChartDatum = payload[0]
      ? payload[0].payload
      : undefined;

    if (!chartDatum || !active) {
      return null;
    }
    const {
      score,
      completedOn,
      assessmentType,
      prevScore,
      subscores,
      safetyRisk,
    } = chartDatum;

    const scoreRepresentation = getScoreRepresentationForAssessmentType(
      score,
      assessmentType
    );

    const badgeInfo: AssessmentBadgeInfo = {
      assessmentType: assessmentType,
      score: score,
      subscores: subscores,
    };

    return (
      <div
        css={{ boxShadow: theme.elevation.light }}
        className="flex flex-col gap-y-1 rounded bg-system-white p-2"
      >
        <CaptionText color="gray">
          {moment(completedOn).format('MMM D, YYYY')}
        </CaptionText>

        <div className="flex flex-row gap-x-1">
          <CaptionText>
            <b>
              {assessmentType === PatientAssessmentType.ASRS
                ? `Percentile: ${scoreRepresentation}${getOrdinal(
                    scoreRepresentation
                  )}`
                : `Score: ${scoreRepresentation}`}{' '}
            </b>
          </CaptionText>

          {prevScore && (
            <ScoreDiff
              assessmentType={assessmentType}
              prevScore={prevScore}
              currentScore={score}
            />
          )}
        </div>
        <div className="flex flex-col gap-y-1 pt-3">
          {indicatesSiRisk(assessmentType, safetyRisk) && (
            <Badge variant="negative">Safety risk</Badge>
          )}
          <ResultBadges assessment={badgeInfo} />
        </div>
      </div>
    );
  };

  const handleDotClick = (payload: LineChartDatum) => {
    if (visibleChartData.length === 0) {
      return;
    }
    onAssessmentSelected({
      id: payload.id,
      previousScore: payload.prevScore,
    });
  };

  const handleDotHover = (assessmentId: number) => {
    if (activeAssessmentId !== assessmentId) {
      setActiveAssessmentId(assessmentId);
    }
  };

  return (
    <>
      {selectableTimeScales.length > 1 && (
        <div className="mb-6 ml-4">
          <MenuTrigger menuWidth="small">
            <Button variant="secondary">
              <div className="flex items-center gap-2">
                <span>Time: {TIME_SCALE_TO_DISPLAY_NAME[timeScale]}</span>
                <IconCaretDown size="1em" />
              </div>
            </Button>
            <Menu
              onAction={(key) => {
                setTimeScale(key as TimeScale);
              }}
            >
              {selectableTimeScales.map((scale) => (
                <MenuItem
                  key={scale}
                  textValue={`${timeScale === scale ? 'Selected: ' : ''}${
                    TIME_SCALE_TO_DISPLAY_NAME[scale]
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <span
                      className={timeScale === scale ? 'visible' : 'invisible'}
                    >
                      <IconCheck aria-hidden />
                    </span>
                    <BodyText>{TIME_SCALE_TO_DISPLAY_NAME[scale]}</BodyText>
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </MenuTrigger>
        </div>
      )}
      <ResponsiveContainer
        width="100%"
        height={268}
        className="overflow-hidden"
      >
        <LineChart
          data={visibleChartData}
          onClick={(e) => {
            isTooltipOpen &&
              handleDotClick(e.activePayload && e.activePayload[0].payload);
          }}
          style={{ cursor: 'pointer' }}
          onMouseLeave={() => setActiveAssessmentId(undefined)}
        >
          <XAxis
            type="number"
            dataKey="completedOn"
            tickFormatter={tickFormatter}
            domain={domain}
            ticks={ticks}
            interval="equidistantPreserveStart"
            tickLine={{
              stroke: theme.color.system.borderGray,
              strokeWidth: '2px',
            }}
            stroke={theme.color.system.borderGray}
            tick={{
              fill: theme.color.system.gray,
              ...theme.typography.caption.regular,
            }}
            tickMargin={4}
          />
          <YAxis
            tickCount={
              assessmentType === PatientAssessmentType.WHODAS_2 ? 6 : 7
            }
            type="number"
            tickLine={false}
            strokeWidth={0}
            domain={getLineChartYDomain(assessmentType)}
            tick={{
              fill: theme.color.system.gray,
              ...theme.typography.caption.regular,
            }}
            width={40}
          />
          <CartesianGrid
            vertical={false}
            stroke={theme.color.system.borderGray}
          />
          {visibleChartData.length > 0 && isTooltipOpen && (
            <Tooltip
              content={<AssessmentResultsTooltip />}
              cursor={{ stroke: '#788BFF', strokeDasharray: '2 4' }}
              isAnimationActive={false}
            />
          )}
          <Line
            type="linear"
            dataKey="score"
            stroke="#788BFF"
            strokeWidth={2}
            dot={(props) => (
              <Dot
                assessmentType={assessmentType}
                setIsTooltipOpen={setIsTooltipOpen}
                isMostRecentAssessment={
                  props.payload.id === mostRecentAssessment.id
                }
                {...props}
              />
            )}
            activeDot={(props: any) => {
              handleDotHover(props.payload.id);
              return (
                <Dot
                  assessmentType={assessmentType}
                  setIsTooltipOpen={setIsTooltipOpen}
                  {...props}
                  active
                />
              );
            }}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

interface DotProps {
  cx: number;
  cy: number;
  assessmentType: PatientAssessmentType;
  payload: LineChartDatum;
  setIsTooltipOpen: (toChange: boolean) => {};
  active?: boolean;
  isMostRecentAssessment: boolean;
}

export const Dot = ({
  cx,
  cy,
  assessmentType,
  payload,
  active,
  setIsTooltipOpen,
  isMostRecentAssessment,
}: DotProps) => {
  if (payload.safetyRisk) {
    return (
      <SafetyRiskGlyph
        cx={cx}
        cy={cy}
        active={active}
        setIsTooltipOpen={setIsTooltipOpen}
        isMostRecentAssessment={isMostRecentAssessment}
      />
    );
  }
  const scoreForVariant =
    assessmentType === PatientAssessmentType.ASRS && payload.subscores
      ? payload.subscores.partA_score
      : payload.score;
  const variant = getVariantForAssessmentResult(
    assessmentType,
    scoreForVariant
  );
  switch (variant) {
    case 'positive':
      return (
        <PositiveGlyph
          cx={cx}
          cy={cy}
          active={active}
          setIsTooltipOpen={setIsTooltipOpen}
          isMostRecentAssessment={isMostRecentAssessment}
        />
      );
    case 'warning':
      return (
        <WarningGlyph
          cx={cx}
          cy={cy}
          active={active}
          setIsTooltipOpen={setIsTooltipOpen}
          isMostRecentAssessment={isMostRecentAssessment}
        />
      );
    case 'negative':
      return (
        <NegativeGlyph
          cx={cx}
          cy={cy}
          active={active}
          setIsTooltipOpen={setIsTooltipOpen}
          isMostRecentAssessment={isMostRecentAssessment}
        />
      );
    default:
      checkExhaustive(variant);
  }
};
