import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { useProvider } from 'hooks';
import { useEffect, useState } from 'react';

import { ProviderMetrics } from '@headway/api/models/ProviderMetrics';
import { ProviderMetricsTimePeriod } from '@headway/api/models/ProviderMetricsTimePeriod';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { Avatar } from '@headway/helix/Avatar';
import { IconCalendarDots } from '@headway/helix/icons/CalendarDots';
import { IconMoney } from '@headway/helix/icons/Money';
import { IconUsers } from '@headway/helix/icons/Users';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { Fire } from '@headway/icons/dist/Fire';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';

type TimePeriodOptions = { [key: string]: ProviderMetricsTimePeriod };

const timePeriodOptions: TimePeriodOptions = {
  '0': ProviderMetricsTimePeriod.LAST_30_DAYS,
  '1': ProviderMetricsTimePeriod.YEAR_TO_DATE,
};

export const PracticeAtAGlance = () => {
  const provider = useProvider();
  const isSmallScreen = useMediaQuery('(max-width: 900px)');

  const [timePeriodKey, setTimePeriodKey] = useState<string>('0');
  const [checkYearMetrics, setCheckYearMetrics] = useState(false);
  const [hideComponent, setHideComponent] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);

  // called whenever timePeriodKey changes
  const fetchMetrics = async ({
    timePeriodKey,
  }: {
    timePeriodKey: string;
  }): Promise<ProviderMetrics> => {
    return ProviderApi.getProviderMetrics(provider.id, {
      time_period: timePeriodOptions[timePeriodKey],
    })
      .then((providerMetrics) => {
        return providerMetrics;
      })
      .catch((error) => {
        throw error;
      });
  };

  // fetch metrics for selected time period
  const { isLoading: providerMetricsLoading, data: providerMetrics } = useQuery(
    ['providerMetrics', provider.id, timePeriodKey], // sets timePeriodKey as a query key
    () => fetchMetrics({ timePeriodKey }),
    {
      onSuccess: (providerMetrics) => {
        if (timePeriodKey === '0') {
          if (Object.values(providerMetrics).every((value) => value === 0)) {
            setCheckYearMetrics(true);
          } else {
            setComponentLoading(false);
          }
        }
      },
      cacheTime: 0,
    }
  );

  // check if year metrics are all zero (called only when 30 day metrics are all zero)
  const { isLoading: yearMetricsLoading } = useQuery(
    ['checkYearMetrics', provider.id],
    () => fetchMetrics({ timePeriodKey: '1' }),
    {
      onSuccess: (yearMetrics) => {
        if (Object.values(yearMetrics).every((value) => value === 0)) {
          setHideComponent(true);
        } else {
          setTimePeriodKey('1');
        }
      },
      enabled: checkYearMetrics,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!providerMetricsLoading && !yearMetricsLoading) {
      setComponentLoading(false);
    }
  }, [providerMetricsLoading, yearMetricsLoading]);

  if (hideComponent) {
    return null;
  }

  if (componentLoading) {
    return (
      <PageSection>
        <Skeleton variant="rectangular" height={185} />
      </PageSection>
    );
  }

  return (
    <PageSection>
      <div css={paagCss.header}>
        <SectionHeader>Practice Highlights</SectionHeader>
        <div css={paagCss.select}>
          <Select
            name="selectTimePeriod"
            selectionMode="single"
            aria-label="time period selection"
            items={Object.entries(timePeriodOptions)}
            selectedKeys={timePeriodKey}
            menuWidth="small"
            onSelectionChange={([newTimePeriodKey]) => {
              if (!newTimePeriodKey) {
                return;
              }
              setTimePeriodKey(newTimePeriodKey);
              trackEvent({
                name: 'Practice at a Glance Toggle Button Clicked',
                properties: {
                  providerId: provider.id,
                  toggleValueSelection: timePeriodOptions[newTimePeriodKey],
                },
              });
            }}
          >
            {(item) => {
              return (
                <Item key={item[0]} textValue={item[1]}>
                  {item[1]}
                </Item>
              );
            }}
          </Select>
        </div>
      </div>
      <div
        css={[paagCss.dashboard, isSmallScreen && paagCss.verticalDashboard]}
      >
        <div
          css={[
            paagCss.dashboardItem,
            { borderLeft: 'none', paddingLeft: '10px' },
          ]}
        >
          <div className="flex">
            <div css={paagCss.iconContainer}>
              <Avatar
                backgroundColor={theme.color.hue.lightGreen}
                size="large"
                aria-hidden={true}
                children={
                  <IconMoney size={32} color={theme.color.system.green} />
                }
              />
            </div>
            <div css={paagCss.metricContainer}>
              <SubBodyText>Income earned</SubBodyText>
              {providerMetricsLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{ backgroundColor: 'white' }}
                  height={48}
                />
              ) : (
                providerMetrics && (
                  <div css={paagCss.metric}>
                    ${Math.floor(providerMetrics.incomeEarned).toLocaleString()}
                  </div>
                )
              )}
              {providerMetricsLoading || timePeriodKey === '0'
                ? null
                : providerMetrics &&
                  timePeriodKey === '1' &&
                  providerMetrics.lifetimePayout !==
                    providerMetrics.incomeEarned &&
                  providerMetrics.lifetimePayout !== 0 && (
                    <div
                      css={[
                        theme.typography.subbody.regular,
                        { display: 'flex', alignItems: 'center' },
                      ]}
                    >
                      <div css={{ paddingTop: '4px' }}>
                        <Fire />
                      </div>
                      <div
                        css={{
                          color: theme.color.system.green,
                          marginLeft: '2px',
                        }}
                      >
                        $
                        {Math.floor(
                          providerMetrics.lifetimePayout
                        ).toLocaleString()}
                      </div>
                      <div css={{ marginLeft: '4px' }}>lifetime payout</div>
                    </div>
                  )}
            </div>
          </div>
        </div>
        <div
          css={[
            paagCss.dashboardItem,
            isSmallScreen && paagCss.verticalGridItem,
          ]}
        >
          <div className="flex">
            <div css={paagCss.iconContainer}>
              <Avatar
                backgroundColor={theme.color.hue.lightGreen}
                size="large"
                aria-hidden={true}
                children={
                  <IconCalendarDots
                    size={32}
                    color={theme.color.system.green}
                  />
                }
              />
            </div>
            <div css={paagCss.metricContainer}>
              <SubBodyText>Confirmed sessions</SubBodyText>
              {providerMetricsLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{ backgroundColor: 'white' }}
                  height={48}
                />
              ) : (
                providerMetrics && (
                  <div css={paagCss.metric}>
                    {Math.floor(
                      providerMetrics.confirmedSessions
                    ).toLocaleString()}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div
          css={[
            paagCss.dashboardItem,
            isSmallScreen && paagCss.verticalGridItem,
          ]}
        >
          <div className="flex">
            <div css={paagCss.iconContainer}>
              <Avatar
                backgroundColor={theme.color.hue.lightGreen}
                size="large"
                aria-hidden={true}
                children={
                  <IconUsers size={32} color={theme.color.system.green} />
                }
              />
            </div>
            <div css={paagCss.metricContainer}>
              <SubBodyText>Clients seen</SubBodyText>
              {providerMetricsLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{ backgroundColor: 'white' }}
                  height={48}
                />
              ) : (
                providerMetrics && (
                  <div css={paagCss.metric}>
                    {Math.floor(providerMetrics.clientsSeen).toLocaleString()}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

const paagCss = {
  container: css({
    alignItems: 'center',
    justifyContent: 'center',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  select: css({
    paddingBottom: '10px',
  }),
  selectTimePeriod: css({
    outlineColor: theme.color.system.textBlack,
    backgroundColor: theme.color.system.backgroundGray,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
  }),
  dashboard: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
  }),
  verticalDashboard: css({
    flexDirection: 'column',
  }),
  dashboardItem: css({
    marginBottom: theme.spacing.x1,
    borderLeft: `1.25px solid ${theme.color.system.borderGray}`,
    paddingBottom: theme.spacing.x3,
    width: '100%',
  }),
  iconContainer: css({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    height: '56px',
  }),
  metricContainer: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5px',
    gap: '4px',
  }),
  metric: css({
    fontWeight: 600,
    fontSize: '38px',
    color: 'black',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.76px',
  }),
  verticalGridItem: css({
    padding: theme.spacing.x4,
    borderLeft: 'none',
    borderTop: `1.25px solid ${theme.color.system.borderGray}`,
  }),
};
