import React from 'react';

import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';
import { Button } from '@headway/helix/Button';
import { GuidanceCard, GuidanceCardProps } from '@headway/helix/GuidanceCard';
import { isLoginCredentialsProviderTask } from '@headway/shared/constants/providerTaskType';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  PASSWORD_COLLECTION_PROVIDER_TASKS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  PayerStatesMap,
  PayerStatus,
  PayerStatusMap,
} from '@headway/shared/types/payerStatus';
import { TaskStatusMap } from '@headway/shared/utils/insuranceStatus';
import { convertListToSentence } from '@headway/shared/utils/strings';

const TaskGuidanceCard = ({
  actionRequiredText,
  onPressCta,
  ctaText = 'Learn more',
}: {
  actionRequiredText: string;
  onPressCta: () => void;
  ctaText?: string;
}) => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      <>
        <span>
          <b>Action Required:</b> {actionRequiredText}
        </span>
        <Button onPress={onPressCta} variant="link">
          {ctaText}
        </Button>
      </>
    </GuidanceCard>
  );
};

const CoiTaskGuidanceCard = ({
  setActiveCoiModal,
}: {
  setActiveCoiModal: (arg: boolean) => void;
}) => {
  return (
    <TaskGuidanceCard
      actionRequiredText="There’s an issue with your malpractice insurance."
      onPressCta={() => {
        setActiveCoiModal(true);
      }}
      ctaText={'Upload here'}
    />
  );
};

const getGenericActionRequiredText = (task: ProviderTaskRead) =>
  `We need more information on your ${task?.title} to finish getting you credentialed. We’ll share an updated ETA as soon as we can.`;

const LoginCredentialsTaskGuidanceCard = ({
  task,
  setActiveProviderTask,
}: {
  task: ProviderTaskRead;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
}) => {
  return (
    <TaskGuidanceCard
      actionRequiredText={
        task.statusDescription ?? getGenericActionRequiredText(task)
      }
      onPressCta={() => {
        setActiveProviderTask(task);
      }}
      ctaText="Submit login"
    />
  );
};

const GenericTaskGuidanceCard = ({
  task,
  setActiveProviderTask,
}: {
  task: ProviderTaskRead;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
}) => {
  return (
    <TaskGuidanceCard
      actionRequiredText={
        task.statusDescription ?? getGenericActionRequiredText(task)
      }
      onPressCta={() => {
        setActiveProviderTask(task);
      }}
    />
  );
};

export const ProviderTaskGuidanceCards = ({
  tasks,
  setActiveProviderTask,
  setActiveCoiModal,
}: {
  tasks: { taskStatusMap: TaskStatusMap } | undefined;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
  setActiveCoiModal: (arg: boolean) => void;
}) => {
  const isPasswordCollectionEnabled = useFlag(
    PASSWORD_COLLECTION_PROVIDER_TASKS,
    false
  );
  const actionNeededTasks = tasks?.taskStatusMap?.ACTION_NEEDED ?? [];
  const actionNeededHighPriorityTasks =
    tasks?.taskStatusMap?.ACTION_NEEDED_HIGH_PRIORITY ?? [];
  const allActionNeededTasks =
    actionNeededHighPriorityTasks.concat(actionNeededTasks);
  const hasCoiTask = allActionNeededTasks.some(
    (task) => task.taskType === ProviderTaskType.CERTIFICATE_OF_INSURANCE
  );
  const nonCoiTasks = allActionNeededTasks.filter(
    (task) => task.taskType !== ProviderTaskType.CERTIFICATE_OF_INSURANCE
  );

  return (
    <div className="grid gap-y-4">
      {nonCoiTasks?.map((task) => (
        <>
          {isPasswordCollectionEnabled &&
          task.taskType &&
          isLoginCredentialsProviderTask(task.taskType) ? (
            <LoginCredentialsTaskGuidanceCard
              task={task}
              setActiveProviderTask={setActiveProviderTask}
            />
          ) : (
            <GenericTaskGuidanceCard
              task={task}
              setActiveProviderTask={setActiveProviderTask}
            />
          )}
        </>
      ))}
      {hasCoiTask && (
        <CoiTaskGuidanceCard setActiveCoiModal={setActiveCoiModal} />
      )}
    </div>
  );
};

const shouldShowAppliedGuidanceCard = (payerStatusMap: PayerStatusMap) => {
  const totalAppliedCarriers =
    payerStatusMap.get(PayerStatus.APPLIED)?.size ?? 0;
  const totalCompleteCarriers =
    payerStatusMap.get(PayerStatus.COMPLETE)?.size ?? 0;

  let totalCarriers = 0;
  for (let carriers of payerStatusMap.values()) {
    totalCarriers += carriers.size;
  }

  return !!(
    totalAppliedCarriers &&
    totalAppliedCarriers + totalCompleteCarriers === totalCarriers
  );
};

const convertPayerStatesMapToStrings = (
  payerStatesMap: PayerStatesMap,
  isMscBetaEnabled: boolean
): string[] => {
  if (!isMscBetaEnabled) {
    return [convertListToSentence(Array.from(payerStatesMap.keys()))];
  }
  return Array.from(payerStatesMap.entries()).map(([payerName, states]) => {
    const stateDisplayNames = states.map(
      (state) => statesToDisplayNames[state]
    );
    return `${payerName} in ${convertListToSentence(stateDisplayNames)}`;
  });
};

type PayerStatusGuidanceCardProps = {
  variant: GuidanceCardProps['variant'];
  payerStatesMap: PayerStatesMap;
  isMSCBetaEnabled: boolean;
  copyBeforePayers: string;
  endOfSentencePunctuationForOnePayer: string;
  copyAfterPayers: string;
};

const PayerStatusGuidanceCard = ({
  variant,
  payerStatesMap,
  isMSCBetaEnabled,
  copyBeforePayers,
  endOfSentencePunctuationForOnePayer,
  copyAfterPayers,
}: PayerStatusGuidanceCardProps) => {
  const payerStateStrings = convertPayerStatesMapToStrings(
    payerStatesMap,
    isMSCBetaEnabled
  );
  const shouldDisplayList = payerStateStrings.length > 1;
  return (
    <GuidanceCard variant={variant}>
      <span>
        {copyBeforePayers}
        {shouldDisplayList ? (
          <>
            :
            <ul css={{ margin: 0 }}>
              {payerStateStrings.map((payerStateString, index) => (
                <li key={index}>{payerStateString}</li>
              ))}
            </ul>
          </>
        ) : (
          <>
            {' '}
            {payerStateStrings[0]}
            {endOfSentencePunctuationForOnePayer}{' '}
          </>
        )}
        {copyAfterPayers}
      </span>
    </GuidanceCard>
  );
};

type GuidanceCardsProps = {
  payerStatusMap: PayerStatusMap;
  taskStatusMap?: TaskStatusMap;
};

export const GuidanceCards = ({
  payerStatusMap,
  taskStatusMap,
}: GuidanceCardsProps) => {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  return (
    <>
      {payerStatusMap.has(PayerStatus.PAYMENT_READY) && (
        <PayerStatusGuidanceCard
          variant="info"
          payerStatesMap={payerStatusMap.get(PayerStatus.PAYMENT_READY)!}
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="Your first payment date is now confirmed with"
          endOfSentencePunctuationForOnePayer="!"
          copyAfterPayers="You’ll be paid on the 15th and the last day of each month from
            there."
        />
      )}

      {payerStatusMap.has(
        PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED
      ) && (
        <PayerStatusGuidanceCard
          variant="error"
          payerStatesMap={
            payerStatusMap.get(
              PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED
            )!
          }
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="Finish your application to start seeing clients with"
          endOfSentencePunctuationForOnePayer="."
          copyAfterPayers="We just need a few pieces of information."
        />
      )}

      {payerStatusMap.has(PayerStatus.CREDENTIALING_DELAYED) && (
        <PayerStatusGuidanceCard
          variant="warning"
          payerStatesMap={
            payerStatusMap.get(PayerStatus.CREDENTIALING_DELAYED)!
          }
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="The credentialing process has been delayed with"
          endOfSentencePunctuationForOnePayer="."
          copyAfterPayers="Thanks for your patience--you don’t need to do anything. We’re
          working on getting you credentialed as soon as possible."
        />
      )}

      {payerStatusMap.has(PayerStatus.APPOINTMENT_READY) ? (
        <PayerStatusGuidanceCard
          variant="info"
          payerStatesMap={payerStatusMap.get(PayerStatus.APPOINTMENT_READY)!}
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="You can now take appointments with"
          endOfSentencePunctuationForOnePayer="!"
          copyAfterPayers="We’ve added estimated dates for your first payment."
        />
      ) : (
        shouldShowAppliedGuidanceCard(payerStatusMap) && (
          <GuidanceCard variant="info">
            We’re working with your insurance companies to review your files.
            There’s nothing you need to do.
          </GuidanceCard>
        )
      )}
    </>
  );
};
