import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PatientFormSchemaNestedRead } from '@headway/api/models/PatientFormSchemaNestedRead';
import { PatientFormSchemaRead } from '@headway/api/models/PatientFormSchemaRead';
import { PatientFormSchemaType } from '@headway/api/models/PatientFormSchemaType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { PatientFormSchemaApi } from '@headway/api/resources/PatientFormSchemaApi';
import { Form as FormBuilderForm } from '@headway/form-builder';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Divider } from '@headway/helix/Divider';
import { Form, FormStickyFooter } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { ListHeader } from '@headway/helix/ListHeader';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';

import { BigRadio } from '../../components/BigRadio/BigRadio';
import { BigRadioGroup } from '../../components/BigRadio/BigRadioGroup';
import { PracticePoliciesUpload } from './PracticePolicies/PracticePoliciesUpload';

type PracticePolicyEntryMethod = 'template' | 'manual' | 'upload';

export interface PracticePoliciesFormValues {
  policyManual?: string;
  policyTemplate?: string;
  entryMethod: PracticePolicyEntryMethod;
  attachmentPolicyS3ObjectKey?: string;
  attachmentPolicyName?: string;
}

const validationSchema = Yup.object().shape({
  entryMethod: Yup.string().oneOf(['manual', 'template', 'upload']).required(),
  policyManual: Yup.string().when('entryMethod', {
    is: 'manual',
    then: Yup.string().required('Some content is required.'),
    otherwise: Yup.string().nullable(),
  }),
  policyTemplate: Yup.string().when('entryMethod', {
    is: 'template',
    then: Yup.string().required('Some content is required.'),
    otherwise: Yup.string().nullable(),
  }),
  attachmentPolicyS3ObjectKey: Yup.string().when('entryMethod', {
    is: 'upload',
    then: Yup.string().required('A file is required.'),
    otherwise: Yup.string().nullable(),
  }),
});

interface PracticePoliciesProps {
  AuthStore: any;
}
export function PracticePolicies({ AuthStore }: PracticePoliciesProps) {
  const query = useQuery(
    [
      'practice-policies',
      {
        owner: AuthStore.provider.id,
      },
    ],
    async () => {
      return await PatientFormSchemaApi.findPatientFormSchemasByOwnerAndFormType(
        AuthStore.provider.id,
        PatientFormSchemaType.PRACTICE_POLICIES
      );
    },
    {
      enabled: !!AuthStore.provider?.id,
    }
  );

  const [activePolicy] = query.data ?? [];

  const createPolicyMutation = useMutation(
    async (values: PracticePoliciesFormValues) => {
      const acknowledgeAndSignJson = {
        id: crypto.randomUUID(),
        type: 'acknowledgeAndSign',
        title: 'I have read and agree to the above document',
        subTitle:
          'By clicking the checkbox I acknowledge I have read the information provided above. By clicking the button, “Sign and acknowledge” I am signing and agreeing to the terms outlined in the document above.',
        isRequired: true,
        minLength: false,
        isRequiredErrorMessage: 'Required',
        validations: [{ type: 'required', params: ['Required'] }],
      };

      if (
        values.entryMethod === 'manual' ||
        values.entryMethod === 'template'
      ) {
        const asFormSchema = {
          info: { id: 1, name: 'Practice policies', version: 1 },
          form: [
            {
              id: crypto.randomUUID(),
              type: 'richFreeText',
              readOnly: true,
              defaultContent:
                values.entryMethod === 'manual'
                  ? values.policyManual
                  : values.policyTemplate,
            },
            acknowledgeAndSignJson,
          ],
        };

        return await PatientFormSchemaApi.createPatientFormSchema({
          name: 'Practice policies',
          formSchemaJson: asFormSchema,
          formType: PatientFormSchemaType.PRACTICE_POLICIES,
          ownerProviderId: AuthStore.provider.id,
        });
      } else if (values.entryMethod === 'upload') {
        const asFormSchemaUpload = {
          info: { id: 1, name: 'Practice policies', version: 1 },
          form: [acknowledgeAndSignJson],
        };

        return await PatientFormSchemaApi.createPatientFormSchema({
          name: 'Practice policies',
          formType: PatientFormSchemaType.PRACTICE_POLICIES,
          ownerProviderId: AuthStore.provider.id,
          formSchemaJson: asFormSchemaUpload,
          attachmentName: values.attachmentPolicyName,
          attachmentS3ObjectKey: values.attachmentPolicyS3ObjectKey,
        });
      }
    },
    {
      async onSuccess() {
        if (activePolicy) {
          await PatientFormSchemaApi.deletePatientFormSchema(activePolicy.id);
          query.refetch();
        }

        setView('send-policies');
      },
    }
  );

  const sendPolicyMutation = useMutation(
    async () => {
      return await PatientFormApi.sendIntakeFormEmailToAllActivePatients(
        AuthStore.provider.id
      );
    },
    {
      async onSuccess() {
        setMode('view');
        setView('view');
        toasts.add('Your practice policies were sent to your clients', {
          variant: 'positive',
        });
      },
    }
  );

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [view, setView] = React.useState<'view' | 'send-policies'>('view');

  useEffect(() => {
    if (view === 'send-policies') {
      trackEvent({
        name: 'Send Practice Policy Modal Viewed',
        properties: {
          providerId: AuthStore.provider.id,
        },
      });
    }
  }, [view]);

  let existingContent: string | undefined = undefined;
  let existingAttachmentS3ObjectKey: string | undefined = undefined;
  let existingAttachmentName: string | undefined = undefined;

  if (query.isLoading) {
    return (
      <PageSection>
        <Skeleton variant="rectangular" height={100} />
      </PageSection>
    );
  }

  if (mode === 'view' && activePolicy) {
    return (
      <ActivePracticePolicy
        provider={AuthStore.provider}
        policy={activePolicy}
        onEdit={() => {
          setMode('edit');
        }}
        onDelete={() => {
          query.refetch();
        }}
      />
    );
  }

  // todo(nf): type these form objects
  if (activePolicy?.attachmentS3ObjectKey) {
    existingAttachmentS3ObjectKey = activePolicy.attachmentS3ObjectKey;
    existingAttachmentName = activePolicy.attachmentName;
    // @ts-expect-error
  } else if (activePolicy?.formSchemaJson?.form) {
    existingContent = formBuilderToRichTextAreaHtml(
      // @ts-expect-error
      activePolicy?.formSchemaJson?.form
    );
  }

  return (
    <Formik
      key={activePolicy?.id ?? 'new'}
      initialValues={{
        policyManual: existingContent,
        policyTemplate: DEFAULT_HEADWAY_PRACTICE_POLICY,
        attachmentPolicyS3ObjectKey: existingAttachmentS3ObjectKey,
        attachmentPolicyName: existingAttachmentName,
        entryMethod: activePolicy
          ? getPolicyEntryMethod(activePolicy)
          : ('manual' as PracticePolicyEntryMethod),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        createPolicyMutation.mutate(values);
        trackEvent({
          name: 'Practice Policy Save Changes Button Clicked',
          properties: {
            providerId: AuthStore.provider.id,
            practicePolicyType: values.entryMethod,
          },
        });
      }}
    >
      {({ values }) => {
        const { entryMethod } = values;

        return (
          <div
            css={{
              display: 'grid',
              gridTemplateRows: '1fr',
              alignItems: 'start',
              flex: 1,
            }}
          >
            <PageSection>
              <div>
                <div>
                  <BodyText>
                    <strong>Choose format to add your practice policies</strong>
                  </BodyText>
                </div>

                <div>
                  <FormControl
                    component={BigRadioGroup}
                    aria-label="Choose format to add your practice policies"
                    name="entryMethod"
                  >
                    <div
                      css={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        alignItems: 'center',
                        gap: theme.spacing.x4,
                      }}
                    >
                      <BigRadio value="manual">Add custom policies</BigRadio>
                      <BigRadio value="template">
                        Use a Headway template
                      </BigRadio>
                      <BigRadio value="upload">Upload a document</BigRadio>
                    </div>
                  </FormControl>
                </div>
              </div>
              <Form id="practice-policies">
                {entryMethod === 'manual' || entryMethod === 'template' ? (
                  <FormControl
                    key={entryMethod}
                    component={RichTextArea}
                    extensions={[
                      'bold',
                      'italic',
                      'underline',
                      'bulletList',
                      'orderedList',
                      'heading',
                    ]}
                    height={15}
                    name={
                      entryMethod === 'manual'
                        ? 'policyManual'
                        : 'policyTemplate'
                    }
                    aria-label="Practice policies"
                  />
                ) : (
                  <PracticePoliciesUpload policy={activePolicy} />
                )}
              </Form>
            </PageSection>
            <FormStickyFooter>
              {activePolicy && (
                <Button
                  variant="secondary"
                  onPress={() => {
                    setMode('view');
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button type="submit" form="practice-policies" variant="primary">
                {activePolicy ? 'Save changes' : 'Save'}
              </Button>
            </FormStickyFooter>
            {view === 'send-policies' && (
              <Modal
                title="Send your policies to clients to review and sign"
                isOpen={true}
                onDismiss={() => {
                  setView('view');
                  setMode('view');
                  query.refetch();
                }}
              >
                <ModalContent>
                  <form
                    id="send-policies"
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendPolicyMutation.mutate();
                      setView('view');
                      setMode('view');
                      query.refetch();
                      trackEvent({
                        name: 'Send Practice Policy to All Patients Button Clicked',
                        properties: {
                          providerId: AuthStore.provider.id,
                        },
                      });
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        gap: theme.spacing.x1,
                        marginBottom: theme.spacing.x3,
                      }}
                    >
                      <IconCheckCircle
                        css={{
                          color: theme.color.system.green,
                        }}
                        width={20}
                      />
                      <BodyText>
                        <strong>You've updated your practice policies.</strong>
                      </BodyText>
                    </div>
                    <BodyText>
                      Would you like to send your policies to all of your active
                      clients to review and sign?
                    </BodyText>
                    <p css={{ marginTop: theme.spacing.x6 }}>
                      <BodyText>
                        You also have the option to send policies to individual
                        clients by going to the "Intake Forms" section of your
                        client’s profile and resending your practice policies
                        there.
                      </BodyText>
                    </p>
                  </form>
                </ModalContent>
                <ModalFooter>
                  <Button form="send-policies" type="submit" variant="primary">
                    Send to all active clients
                  </Button>
                  <Button
                    onPress={() => {
                      setView('view');
                      setMode('view');
                      query.refetch();
                      trackEvent({
                        name: "Don't Send Practice Policy to All Patients Button Clicked",
                        properties: {
                          providerId: AuthStore.provider.id,
                        },
                      });
                    }}
                    variant="secondary"
                  >
                    Don't send
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        );
      }}
    </Formik>
  );
}

interface ActivePracticePolicyProps {
  onEdit: () => void;
  onDelete: () => void;
  policy: PatientFormSchemaNestedRead;
  provider: ProviderRead;
}

function ActivePracticePolicy({
  policy,
  onDelete,
  onEdit,
  provider,
}: ActivePracticePolicyProps) {
  const queryClient = useQueryClient();
  const [view, setView] = React.useState<'view' | 'confirm-delete'>('view');
  const formRef = React.useRef();

  const deletePolicyMutation = useMutation(
    async () => {
      return await PatientFormSchemaApi.deletePatientFormSchema(policy.id);
    },
    {
      async onSuccess() {
        onDelete();
        toasts.add('Your practice policies have been deleted', {
          variant: 'positive',
        });

        await queryClient.invalidateQueries({
          queryKey: ['patient-forms'],
        });
      },
    }
  );

  return (
    <>
      <PageSection
        css={{
          '& .hlx-divider': {
            marginInline: `-${theme.spacing.x4} !important`,
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing.x2,
          }}
        >
          <div>
            <h2>
              <SectionHeader>Practice Policies</SectionHeader>
            </h2>
            <BodyText>
              Your practice policies were last saved on{' '}
              {new Date(
                policy.updatedOn ?? policy.createdOn!
              ).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
            </BodyText>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: theme.spacing.x2,
              alignItems: 'center',
            }}
          >
            <Button
              onPress={() => {
                onEdit();
              }}
              variant="primary"
            >
              Edit
            </Button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setView('confirm-delete');
              }}
            >
              <Button type="submit" variant="secondary">
                Delete
              </Button>
            </form>
          </div>
        </div>
        <Divider />
        {policy.attachmentS3ObjectKey ? (
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <iframe
              title={policy.attachmentLink}
              src={`${policy.attachmentLink}#view=fitH`}
              css={{
                backgroundColor: 'white',
                height: '1000px',
                marginTop: theme.spacing.x6,
              }}
            />
          </div>
        ) : (
          <div>
            <div>
              <h2>
                <ListHeader>Provider info</ListHeader>
              </h2>
              <dl css={definitionListCss}>
                <dt>Name:&nbsp;</dt>
                <dd>{provider.displayName}</dd>
                <dt>Email:&nbsp;</dt>
                <dd>{provider.email}</dd>
                <dt>Phone number:&nbsp;</dt>
                <dd>{provider.phone}</dd>
              </dl>
            </div>
            <FormBuilderForm.View
              readOnly={true}
              key={policy.id}
              ref={formRef}
              form={policy.formSchemaJson}
            />
          </div>
        )}
      </PageSection>
      {view === 'confirm-delete' && (
        <Modal
          title="Delete these policies?"
          isOpen={true}
          onDismiss={() => {
            setView('view');
          }}
        >
          <ModalContent>
            <form
              id="delete-policy"
              onSubmit={(e) => {
                e.preventDefault();
                deletePolicyMutation.mutate();
                setView('view');
              }}
            >
              <BodyText>
                Are you sure you’d like to delete your practice policies?
              </BodyText>
            </form>
          </ModalContent>
          <ModalFooter>
            <Button form="delete-policy" type="submit" variant="primary">
              {deletePolicyMutation.isLoading ? 'Deleting...' : 'Yes'}
            </Button>
            <Button
              onPress={() => {
                setView('view');
              }}
              variant="secondary"
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

const DEFAULT_HEADWAY_PRACTICE_POLICY = `

    <h2>Appointments and Cancellations</h2>
    <p>Appointments are scheduled in advance, at a cadence we agree on, based on your goals, treatment needs, and our mutual availability. Payments for each appointment will be made through Headway by debit or credit card or ACH transfer.</p>
    <p>You may cancel appointments in advance without charge, as long as I receive notice far enough in advance. For appointment no-shows or last-minute cancellations, you may be charged a fee. Please reach out to me directly for my latest policy on the cancellation cutoff period and fees.</p>

    <h2>Availability and After-Hours Emergencies</h2>
    <p>Providers check for voice mail messages during normal business hours. Messages left outside of normal hours of operation will be picked up the next business day. If you are experiencing suicidal or homicidal thoughts, are in crisis, or need immediate help, please call 911 or go to the nearest emergency department.</p>

    <h2>Contacting Me</h2>
    <p>I am often not immediately available by telephone. I do not answer my phone when I am with clients or otherwise unavailable. At these times, you may leave a message on my confidential voicemail and I will return your call once I’ve reviewed your chart, but it may take a day or two for non-urgent matters. I will make every attempt to inform you in advance of planned absences, and provide you with the name and phone number of the mental health professional covering my practice. If I need to cancel an appointment at the last-minute, I will reach out as soon as possible and reschedule, or have a member of my staff connect with you.</p>

    <h2>Discharge Process</h2>
    <p>There are several reasons why we may eventually end our professional relationship. You may decide you would prefer to work with a different provider. I may reach the conclusion you would be better served working with someone else. Regardless of the case, I will first discuss with you the reasons for discharging, and if you request, provide you with a list of other qualified providers. I will also extend the discharge process length if necessary based on your treatment needs, including continuing to provide emergency support for a time-limited period after you have been notified of the end of our treatment relationship.</p>
    <p>Please note that ongoing failure to pay for treatment, attend sessions, or communicate with me in a respectful and timely manner can also result in discharge from my practice. In these instances, to ensure you have continued access to care, I will still make every reasonable effort to get in touch with you and provide referrals to a new provider before I consider our relationship ended.</p>

 `;

function getPolicyEntryMethod(
  policy: PatientFormSchemaRead
): PracticePolicyEntryMethod {
  if (policy.attachmentS3ObjectKey) {
    return 'upload';
  }

  const form = policy.formSchemaJson as { form: FormBuilderComponent[] };

  const richFreeTextElement = form.form.find(
    (item) => item.type === 'richFreeText'
  );

  const content = richFreeTextElement?.defaultContent;

  if (content === DEFAULT_HEADWAY_PRACTICE_POLICY) {
    return 'template';
  }

  return 'manual';
}

type FormBuilderComponent = {
  id: string;
  type: string;
} & Record<string, any>;

function formBuilderToRichTextAreaHtml(form: FormBuilderComponent[]): string {
  const richFreeTextElement = form.find((item) => item.type === 'richFreeText');

  if (richFreeTextElement) {
    return richFreeTextElement.defaultContent;
  }

  return '';
}

const definitionListCss = css`
  & dt {
    display: inline-block;
    font-weight: bold;
  }
  & dd {
    display: inline;
    margin: 0;
    padding: 0;

    &:after {
      content: '';
      display: block;
    }
  }
`;
