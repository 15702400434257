import { useProvider } from 'hooks';
import { useEffect, useState } from 'react';

import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { VerificationType } from '@headway/api/models/VerificationType';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { ProviderQuestionnaireVerificationApi } from '@headway/api/resources/ProviderQuestionnaireVerificationApi';
import { useQuery } from '@headway/shared/react-query';
import { UploadedFile } from '@headway/shared/utils/upload';

import { CoiErrorType } from 'utils/questionnaireVerificationHelpers';

import { CoiBadDocument } from './CoiBadDocument';
import { CoiExpiringSoon, CoiExpiringSoonSecond } from './CoiExpiringSoon';
import { CoiIneligible } from './CoiIneligible';
import { CoiLoading } from './CoiLoading';
import { CoiOcrFailed } from './CoiOcrFailed';
import { CoiSuccess } from './CoiSuccess';
import { CoiPathwayType, ErrorObject, MalpracticeValuesType } from './CoiUtils';

/* This component contains the business logic responsible for polling the /refresh endpoint and rendering an appropriate pathway */

export const CoiPathway = ({
  file,
  verificationId,
  secondFileDropped,
  uploadedFileObject,
  coiPathway,
  setCoiPathway,
}: {
  file: File;
  verificationId: number;
  secondFileDropped: boolean;
  uploadedFileObject: UploadedFile[];
  coiPathway: CoiPathwayType;
  setCoiPathway: (arg: CoiPathwayType) => void;
}) => {
  const provider = useProvider();
  const callSetCoiPathway = (pathway: CoiPathwayType) => {
    if (coiPathway === CoiPathwayType.SUBMITTED) {
      return true;
    }
    setCoiPathway(pathway);
    return true;
  };
  const [isLoadingOcr, setisLoadingOcr] = useState<boolean>(true);
  if (coiPathway === CoiPathwayType.INITIAL) {
    setCoiPathway(CoiPathwayType.LOADING);
  }
  const [textractResponse, setTextractResponse] =
    useState<ProviderQuestionnaireVerificationRead>();
  const [textractErrors, setTextractErrors] = useState<ErrorObject[]>();
  const [malpracticeMinimums, setMalpracticeMinimums] =
    useState<MalpracticeMinimums>();

  const eligibilityErrors: CoiErrorType[] = [
    CoiErrorType.BadNameInsured,
    CoiErrorType.BadCoverageType,
    CoiErrorType.BadExpirationDate,
    CoiErrorType.BadEffectiveDate,
    CoiErrorType.BadPerClaimLimit,
    CoiErrorType.BadAggregateLimit,
    CoiErrorType.BadOccupation,
  ];

  const { data } = useQuery(
    ['file', file],
    async () => {
      const pqv =
        await ProviderQuestionnaireVerificationApi.refreshProviderQuestionnaireVerificationByIds(
          [verificationId]
        );
      if (pqv?.[0].responseJson?.jobStatus === 'COI_VALIDATION_COMPLETE') {
        setisLoadingOcr(false);
        setTextractResponse(pqv?.[0]);
        setTextractErrors(pqv?.[0]?.responseJson.errorsFound as ErrorObject[]);
      } else {
        setisLoadingOcr(true);
      }

      return pqv;
    },
    {
      refetchInterval: isLoadingOcr ? 3000 : undefined,
      notifyOnChangeProps: ['data'],
    }
  );

  const isSuccess = () => {
    if (textractErrors?.length === 0) {
      callSetCoiPathway(CoiPathwayType.SUCCESS);
      return true;
    }
    return false;
  };

  const isBadDocument = () => {
    if (
      textractErrors?.find(
        (error) => error.errorType === CoiErrorType.BadDocument
      )
    ) {
      callSetCoiPathway(CoiPathwayType.BAD_DOCUMENT);
      return true;
    }
    return false;
  };

  const isIneligibleCoi = () => {
    if (
      textractErrors?.find((error) =>
        eligibilityErrors.includes(error.errorType)
      )
    ) {
      callSetCoiPathway(CoiPathwayType.INELIGIBLE);
      return true;
    }
    return false;
  };

  const isCoiExpiringSoon = () => {
    if (
      textractErrors?.find(
        (error) => error.errorType === CoiErrorType.BadExpiringSoon
      )
    ) {
      callSetCoiPathway(CoiPathwayType.EXPIRING_SOON);
      return true;
    }
    return false;
  };

  const isFailedOcr = () => {
    if (
      textractErrors?.find(
        (error) => error.errorType === CoiErrorType.FailedOcr
      )
    ) {
      callSetCoiPathway(CoiPathwayType.OCR_FAILED);
      return true;
    }
    return false;
  };

  useEffect(() => {
    const getMalpracticeMinimums = async () => {
      const response =
        await ProviderQuestionnaireApi.getProviderMalpracticeMinimums({
          provider_id: provider.id,
        });
      setMalpracticeMinimums(response);
    };

    getMalpracticeMinimums();
  }, [provider.id]);

  return (
    <div>
      {isLoadingOcr ? (
        <CoiLoading />
      ) : isSuccess() ? (
        <CoiSuccess
          coiVerification={textractResponse}
          coiPathway={coiPathway}
        />
      ) : isBadDocument() ? (
        <CoiBadDocument />
      ) : isIneligibleCoi() ? (
        <CoiIneligible coiVerification={textractResponse} />
      ) : isCoiExpiringSoon() ? (
        !secondFileDropped ? (
          <CoiExpiringSoon
            coiVerification={textractResponse}
            firstFileDropped={uploadedFileObject[0]}
            coiPathway={coiPathway}
            setCoiPathway={setCoiPathway}
          />
        ) : (
          <CoiExpiringSoonSecond coiVerification={textractResponse} />
        )
      ) : isFailedOcr() && malpracticeMinimums ? (
        <CoiOcrFailed coiPathway={coiPathway} />
      ) : null}
    </div>
  );
};
