import { useCallback, useContext, useState } from 'react';

import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { ContentText } from '@headway/helix/ContentText';
import { ModalContent } from '@headway/helix/Modal';

import JournalImage from 'assets/img/journal.png';

import {
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';

export const AttestationFillForClient = ({
  clientName,
}: {
  clientName: string;
}) => {
  const { setCurrentStep } = useContext(FillOutAssessmentModalContext);
  const [attestationChecked, setAttestationChecked] = useState<boolean>(false);
  const goToNextStep = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.FILL_OUT_ASSESSMENTS);
  }, [setCurrentStep]);

  const goBack = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.SELECT_ASSESSMENTS);
  }, [setCurrentStep]);

  return (
    <>
      <ModalContent>
        <div className="m-12 flex flex-col items-center">
          <div className="flex w-[690px] flex-col items-center gap-3">
            <img src={JournalImage} alt="journal" />
            <ContentText variant="section-title/medium">
              Before starting, please confirm the following:
            </ContentText>

            <Checkbox
              name="attestationCheckBox"
              onChange={() => setAttestationChecked(!attestationChecked)}
            >
              I attest that I am completing the following assessments with
              verbal consent during the session on behalf of {clientName}.
            </Checkbox>

            <div className="flex gap-2">
              <Button variant="secondary" onPress={goBack}>
                Go back
              </Button>
              <Button
                variant="primary"
                onPress={goToNextStep}
                disabled={!attestationChecked}
                type="submit"
              >
                Start assessments
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  );
};
