import { View } from '@react-pdf/renderer';
import { useMemo } from 'react';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PdfText } from '@headway/ui/pdf';

import {
  getAssessmentScoreDescriptor,
  getScoreRepresentationForAssessmentType,
  indicatesSiRisk,
} from '../helpers/utils';
import { AssessmentResultPdfASRSInsights } from './AssessmentResultsPdfASRSInsights';

type AssessmentResultsPdfInsightsProps = Pick<
  PatientAssessmentRead,
  'assessmentType' | 'score' | 'subscores' | 'safetyRisk'
>;

export const AssessmentResultsPdfInsights = ({
  assessmentType,
  score,
  subscores,
  safetyRisk,
}: AssessmentResultsPdfInsightsProps) => {
  const assessmentResults = useMemo(() => {
    const scoreRepresentation =
      typeof score === 'number'
        ? getScoreRepresentationForAssessmentType(score, assessmentType)
        : undefined;
    const insight =
      typeof score === 'number'
        ? getAssessmentScoreDescriptor(assessmentType, score)
        : undefined;
    // We intentionally do not show disability risk insights for WHODAS2 assessments
    const siRisk = indicatesSiRisk(assessmentType, safetyRisk);
    const risk = siRisk ? 'Safety risk' : undefined;

    return [scoreRepresentation, insight, risk]
      .filter((s) => s !== undefined)
      .join(', ');
  }, [score, assessmentType, safetyRisk]);

  if (!assessmentResults) {
    return null;
  }

  return (
    <>
      {assessmentType === PatientAssessmentType.ASRS ? (
        subscores ? (
          <AssessmentResultPdfASRSInsights
            score={score}
            subscores={subscores}
          />
        ) : null
      ) : (
        <View>
          <PdfText style={{ fontWeight: 'medium' }}>Result:</PdfText>
          <PdfText>{assessmentResults}</PdfText>
        </View>
      )}
    </>
  );
};
