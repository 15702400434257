import {
  createCalendar,
  DateValue,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  toCalendarDate,
} from '@internationalized/date';
import { useSlotId } from '@react-aria/utils';
import { DatePickerProps } from '@react-types/datepicker';
import clsx from 'clsx';
import React from 'react';
import {
  AriaButtonProps,
  CalendarProps,
  FocusRing,
  mergeProps,
  useButton,
  useCalendar,
  useDateField,
  useDatePicker,
  useFocusRing,
} from 'react-aria';
import {
  DatePickerState,
  useCalendarState,
  useDateFieldState,
  useDatePickerState,
} from 'react-stately';

import { DATA } from '../consts';
import { IconCaretDown } from '../icons/CaretDown';
import { useAssertFormParentEffect } from '../useAssertFormParentEffect';
import { FormInputProps, useFormInput } from '../useFormInput';
import { EditableDateTimeSegment, LiteralDateTimeSegment } from './common';
import {
  DatePickerCalendar,
  DatePickerCalendarDialog,
} from './DatePickerCalendarDialog';

type DatePickerFieldProps = {
  optionalityText?: React.ReactNode;
  preset?: (state: DatePickerState) => React.ReactElement;
} & FormInputProps<DateValue> &
  Pick<
    DatePickerProps<DateValue>,
    'minValue' | 'maxValue' | 'isDateUnavailable'
  >;

function DatePickerField(props: DatePickerFieldProps) {
  const { ariaProps, hoverProps, rootProps } = useFormInput({
    ...props,
    isTextInput: true,
  });

  let { isFocused, isFocusVisible, focusProps } = useFocusRing({
    within: true,
    isTextInput: true,
    autoFocus: props.autoFocus,
  });

  let { isFocused: isFocusedButton, focusProps: focusPropsButton } =
    useFocusRing({
      within: false,
      isTextInput: false,
      autoFocus: props.autoFocus,
    });

  const options = {
    ...ariaProps,
    minValue: props.minValue,
    maxValue: props.maxValue,
    isDateUnavailable: props.isDateUnavailable,
    onChange: (value: DateValue) => {
      props.onChange?.(value);
    },
    isDisabled: props.disabled,
    isReadOnly: props.readonly,
    hideTimeZone: true,
    granularity: 'day',
    shouldCloseOnSelect: true,
  } as const;

  const state = useDatePickerState(options);

  let controlRef = React.useRef(null);

  const {
    groupProps,
    labelProps,
    fieldProps,
    descriptionProps,
    errorMessageProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(options, state, controlRef);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  useAssertFormParentEffect(controlRef, 'DatePickerField', props.name);

  return (
    <div className="hlx-date-picker-field-root" {...rootProps}>
      <div className="hlx-date-picker-field-label" {...labelProps}>
        {props.label}
      </div>
      <div
        {...mergeProps(groupProps, hoverProps, focusProps, {
          [DATA.FOCUSED]: isFocused,
        })}
        ref={controlRef}
        className={clsx('hlx-date-picker-field-control-group', {
          focused: isFocused,
          'focus-ring': isFocusVisible && !isFocusedButton,
        })}
      >
        <DatePickerFieldControl
          {...mergeProps(
            fieldProps,
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string }
          )}
        />

        <CalendarTriggerButton {...mergeProps(buttonProps, focusPropsButton)}>
          <IconCaretDown size={16} />
        </CalendarTriggerButton>
      </div>
      {state.isOpen && (
        <DatePickerCalendarDialog
          state={state}
          triggerRef={controlRef}
          placement="bottom start"
          dialogProps={dialogProps}
        >
          {props.preset ? (
            <div className="hlx-date-picker-field-preset">
              {props.preset(state)}
            </div>
          ) : null}
          <CalendarView {...calendarProps} />
        </DatePickerCalendarDialog>
      )}

      {props.optionalityText && (
        <div
          id={optionalityId}
          className="hlx-date-picker-field-optionality-text"
        >
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-date-picker-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-date-picker-field-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
}

export function CalendarView(props: CalendarProps<DateValue>) {
  let state = useCalendarState({
    ...props,
    locale: 'en-US',
    createCalendar,
  });

  let { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );

  React.useEffect(() => {
    if (props.value) {
      state.setFocusedDate(toCalendarDate(props.value));
    }
  }, [props.value]);

  return (
    <DatePickerCalendar
      state={state}
      calendarProps={calendarProps}
      nextMonthButtonProps={nextButtonProps}
      prevMonthButtonProps={prevButtonProps}
    />
  );
}

interface DatePickerFieldControlProps {}

/**
 * The DatePickerFieldControl is the actual input element that is rendered
 * inside the DatePickerField. It is responsible for rendering the input
 * control that the user interacts with to trigger the CalendarView popover.
 */
function DatePickerFieldControl(props: DatePickerFieldControlProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let state = useDateFieldState({
    ...props,
    locale: 'en-US',
    createCalendar,
  });

  let { fieldProps } = useDateField(props, state, ref);

  return (
    <div ref={ref} className="hlx-date-picker-field-control" {...fieldProps}>
      {state.segments.map((segment, i) => {
        if (segment.type === 'literal') {
          return <LiteralDateTimeSegment key={i} segment={segment} />;
        }

        return (
          <EditableDateTimeSegment key={i} segment={segment} state={state} />
        );
      })}
    </div>
  );
}

const CalendarTriggerButton = React.forwardRef<
  HTMLButtonElement,
  AriaButtonProps<'button'>
>((props, ref) => {
  let { buttonProps } = useButton(
    props,
    ref as React.RefObject<HTMLButtonElement>
  );
  return (
    <FocusRing
      focusRingClass={'focus-ring'}
      focusClass="focused"
      autoFocus={props.autoFocus}
    >
      <button
        type="button"
        className="hlx-date-picker-field-trigger"
        {...buttonProps}
        ref={ref}
      >
        {props.children}
      </button>
    </FocusRing>
  );
});

export { DatePickerField };
