/**
 * Utility type transformations.
 */

// Picks selected object properties and makes them optional
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

/**
 * Function which can be called in the `default` case of a switch block to cause the compiler to
 * throw an error if the switch block does not exhaustively handle all the cases of its input type.
 * Example usage:
 *
 * function foo(val: 'a'|'b'|'c') {
 *   switch(val) {
 *     case 'a':
 *       console.log('a');
 *     case 'b':
 *       console.log('b');
 *     default:
 *       checkExhaustive(val); // Argument of type 'c' is not assignable to type 'never'
 *   }
 * }
 */
export function checkExhaustive(val: never): never {
  throw new Error('Unhandled switch case');
}

// taken from https://stackoverflow.com/a/58278753
export function isValidEnumValue<T extends object>(
  enumClass: T,
  value: unknown
): value is T[keyof T] {
  if (typeof enumClass !== 'object') {
    return false;
  }

  return Object.values(enumClass).includes(value as T[keyof T]);
}
