import * as React from 'react';
import type { SVGProps } from 'react';

export const AddPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={17}
    fill="none"
    viewBox="0 0 23 17"
    {...props}
  >
    <path
      fill="#000"
      d="M23 9.75a.75.75 0 0 1-.75.75h-1.5V12a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 1 1 0-1.5h1.5V7.5a.75.75 0 0 1 1.5 0V9h1.5a.75.75 0 0 1 .75.75m-5.426 5.517a.75.75 0 1 1-1.149.966c-1.886-2.247-4.48-3.483-7.3-3.483-2.821 0-5.414 1.236-7.3 3.483a.75.75 0 0 1-1.149-.966c1.4-1.667 3.143-2.85 5.078-3.485a6.375 6.375 0 1 1 6.741 0c1.936.634 3.678 1.818 5.08 3.485m-8.45-4.017A4.876 4.876 0 1 0 4.25 6.375a4.88 4.88 0 0 0 4.875 4.875"
    />
  </svg>
);
