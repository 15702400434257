import { CaretUp } from '@phosphor-icons/react';
import { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconCaretUp: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    defaultVariant="outlined"
    variants={['outlined', 'filled']}
    phosphorIcon={CaretUp}
    ref={ref}
  />
));

IconCaretUp.variants = ['outlined', 'filled'];
IconCaretUp.defaultVariant = 'outlined';
