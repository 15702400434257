import { UploadSimple } from '@phosphor-icons/react';
import { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconUploadSimple: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    defaultVariant="outlined"
    variants={['outlined', 'filled']}
    phosphorIcon={UploadSimple}
    ref={ref}
  />
));

IconUploadSimple.variants = ['outlined', 'filled'];
IconUploadSimple.defaultVariant = 'outlined';
