import debounce from 'lodash/debounce';
import React from 'react';
import Script from 'react-load-script';

import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { ComboBox, Item } from '@headway/helix/ComboBox';
import {
  DEFAULT_LARGE_ZOOM,
  DEFAULT_SMALL_RADIUS_MILES,
  DEFAULT_SMALL_ZOOM,
  MILES_PER_DEGREE,
} from '@headway/shared/utils/geography';

const AddressFilter = ({ placeType, handleLocationSelect, address }: any) => {
  const [google, setGoogle] = React.useState<{} | null>(null);
  const [error, setError] = React.useState(false);
  const [shouldLoadGoogle, setShouldLoadGoogle] = React.useState(false);
  const [autocompleteService, setAutocompleteService] = React.useState<
    any | null
  >(null);
  const [geocoder, setGeocoder] = React.useState<any | null>(null);
  const [locationSearchResults, setLocationSearchResults] = React.useState<
    any[]
  >([]);
  const [inputValue, setInputValue] = React.useState(address);
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${
    process.env.REACT_APP_GOOGLE_MAPS_API_ID as string
  }&v=3&libraries=places`;

  const onLoad = () => {
    if (window && window.google) {
      setGoogle(window.google);
      setError(false);
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      setGeocoder(new window.google.maps.Geocoder());
    }
  };

  const getLocationPredictions = debounce(async (searchTerm) => {
    if (!autocompleteService) {
      return;
    }

    try {
      await autocompleteService.getPlacePredictions(
        {
          input: searchTerm,
          types: [placeType],
          componentRestrictions: { country: 'us' },
          fields: ['address_components', 'geometry'],
        },
        (results: any) => {
          const formattedResults = results?.map((result: any) => {
            return {
              key: result?.place_id,
              textValue: result.structured_formatting.main_text,
              secondaryText: result.structured_formatting.secondary_text,
            };
          });
          setLocationSearchResults(formattedResults);
        }
      );
    } catch (err) {
      // do nothing
    }
  }, 200);

  const parseAddressComponents = (addressComponents: any) => {
    const componentForm: any = {
      street_number: 'short_name',
      route: 'long_name',
      sublocality_level_1: 'long_name',
      sublocality: 'long_name',
      locality: 'long_name',
      neighborhood: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
    };

    const addressObj: Record<string, string> = {};

    addressComponents.forEach((component: any) => {
      const addressType = component.types.find((type: string) =>
        componentForm.hasOwnProperty(type)
      );

      if (addressType) {
        const fieldType = componentForm[addressType];
        addressObj[addressType] = component[fieldType];
      }
    });

    return addressObj;
  };

  const getBoundary = (location: any) => {
    const minLocalityTypes = location.types;
    const smallLocale = [
      'street_address',
      'premise',
      'establishment',
      'neighborhood',
      'route',
    ];
    const lat = location.geometry.location.lat();
    const lon = location.geometry.location.lng();

    if (minLocalityTypes.some((t: any) => smallLocale.includes(t))) {
      return {
        lower_lat: lat - DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        lower_lon: lon - DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        upper_lat: lat + DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        upper_lon: lon + DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        zoom: DEFAULT_SMALL_ZOOM,
      };
    } else {
      const bounds = location.geometry.bounds;
      if (bounds) {
        const northEastBound = bounds.getNorthEast();
        const southWestBound = bounds.getSouthWest();
        return {
          upper_lat: northEastBound.lat(),
          upper_lon: northEastBound.lng(),
          lower_lat: southWestBound.lat(),
          lower_lon: southWestBound.lng(),
          zoom: DEFAULT_LARGE_ZOOM,
        };
      }
    }
  };

  const handleSelect = (place_id: string | null) => {
    if (place_id) {
      try {
        geocoder.geocode(
          {
            placeId: place_id,
          },
          (result: any) => {
            const addressComponents = result[0].address_components;
            const lat = result[0].geometry.location.lat();
            const lng = result[0].geometry.location.lng();
            const addressObj = parseAddressComponents(addressComponents);

            const street_number = addressObj.street_number || '';
            const street_name = addressObj.route;
            const city =
              addressObj.locality ||
              addressObj.sublocality ||
              addressObj.sublocality_level_1 ||
              addressObj.neighborhood;
            const state = addressObj.administrative_area_level_1;
            const zipCode = addressObj.postal_code;
            const formattedAddress = result[0].formatted_address;
            const boundary = getBoundary(result[0]);

            handleLocationSelect({
              streetLine1: `${street_number}${
                street_number ? ' ' : ''
              }${street_name}`,
              city,
              state,
              zipCode,
              lat,
              lng,
              formattedAddress,
              ...boundary,
            });
          }
        );
      } catch (err) {
        // do nothing
      }
    } else {
      handleLocationSelect({});
    }
  };

  React.useEffect(() => {
    if (window && window.google) {
      setGoogle(window.google);
      setError(false);
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      setGeocoder(new window.google.maps.Geocoder());
    } else {
      setShouldLoadGoogle(true);
    }
  }, []);

  React.useEffect(() => {
    // Reset input if address is cleared outside component
    if (!address) {
      setInputValue('');
    }
  }, [address]);

  return (
    <>
      {shouldLoadGoogle && (
        <Script
          url={googleMapURL}
          onError={() => setError(true)}
          onLoad={onLoad}
        />
      )}
      {!error && google && autocompleteService && (
        <ComboBox
          name="state"
          placeholder="Search an address"
          aria-label="Search an address"
          selectionMode="single"
          menuWidth="stretch"
          isTextInput={true}
          onSelectionChange={([selection]) => {
            if (selection) {
              handleSelect(selection);
            }
          }}
          onInputChange={async (value: string) => {
            if (!value) {
              handleSelect(null);
            } else {
              getLocationPredictions(value);
            }
            setInputValue(value);
          }}
          inputValue={inputValue}
          items={locationSearchResults}
        >
          {(option: any) => (
            <Item
              key={option?.key}
              textValue={`${option?.textValue}, ${option?.secondaryText}`}
            >
              <div className="flex flex-col">
                <BodyText>{option?.textValue}</BodyText>
                <CaptionText>{option?.secondaryText}</CaptionText>
              </div>
            </Item>
          )}
        </ComboBox>
      )}
    </>
  );
};

export default AddressFilter;
