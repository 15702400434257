import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="m212.373 108.16-80.747-60.587a6.08 6.08 0 0 0-7.253 0L43.52 108.16a6.016 6.016 0 0 0 .107 9.6l80.747 60.587c1.067.853 2.347 1.173 3.627 1.173s2.56-.427 3.627-1.173l80.747-60.587c1.493-1.173 2.453-2.987 2.453-4.8s-.853-3.733-2.453-4.8M128 170.24l-76.373-57.28L128 55.68l76.373 57.28zm53.333-6.933v5.867c0 3.733-1.813 7.36-4.8 9.6l-42.987 32.213a9.28 9.28 0 0 1-5.653 1.92c-2.027 0-3.947-.64-5.653-1.813l-42.987-32.213c-2.987-2.24-4.8-5.867-4.8-9.6v-5.867c0-2.347 1.92-4.373 4.373-4.373s4.373 1.92 4.373 4.373v5.867c0 1.067.533 2.027 1.28 2.667l42.987 32.213 43.84-32.213c.853-.64 1.28-1.6 1.28-2.667v-5.867c0-2.347 1.92-4.373 4.373-4.373s4.373 1.92 4.373 4.373zm19.52-21.227v40.533c0 2.453-1.92 4.373-4.373 4.373s-4.373-1.92-4.373-4.373V142.08c0-2.347 1.92-4.373 4.373-4.373s4.373 1.92 4.373 4.373" />,
  ],
]);
export const IconEducation: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="30 30 200 200" {...props} weights={weights} />
));

IconEducation.variants = ['outlined'];
IconEducation.defaultVariant = 'outlined';
