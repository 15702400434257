import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { EventFilterFunction } from '.';
import { isPatientOrAutoCancelledReferralAppointment } from '../util/events';

export interface FilterByExcludingCancelledPendingReferralsData {
  excludeCancelledPendingReferrals?: boolean;
}

export const filterByExcludingCancelledPendingReferrals: EventFilterFunction<
  FilterByExcludingCancelledPendingReferralsData
> = (
  event: ProviderEventRead,
  filterData: FilterByExcludingCancelledPendingReferralsData
) => {
  if (!filterData.excludeCancelledPendingReferrals) {
    return false;
  }

  return !!isPatientOrAutoCancelledReferralAppointment(event);
};
