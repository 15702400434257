import { css } from '@emotion/react';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { IconCalendarDots } from '@headway/helix/icons/CalendarDots';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconCaretUp } from '@headway/helix/icons/CaretUp';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { useQueryParam } from '@headway/shared/utils/queryParams';
import { Modal } from '@headway/ui';

import { useProviderPatient } from 'hooks/useProviderPatient';
import { useUpdateProviderMutation } from 'mutations/provider';

interface TreatmentPlanAdoptionModalFormValues {
  dontShowAgain: boolean;
}

interface TreatmentPlanAdoptionModalProps {
  open: boolean;
  clientId: number;
  onClose: () => void;
  isIntakeSession: boolean;
  provider: ProviderRead;
}

export const TreatmentPlanAdoptionModal = ({
  open,
  clientId,
  onClose,
  isIntakeSession,
  provider,
}: TreatmentPlanAdoptionModalProps) => {
  const [urlParam, setUrlParam] = useQueryParam('treatment-plan-modal');
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const { data: providerPatient } = useProviderPatient(
    {
      patientId: clientId,
      providerId: provider.id,
    },
    { enabled: open }
  );
  useEffect(() => {
    if (open && !urlParam) {
      setUrlParam('true', { replace: true });
    }
    if (!open && urlParam) {
      setUrlParam(undefined, { replace: true });
    }
  }, [open, urlParam, setUrlParam]);

  const { data: client } = useUser({ userId: clientId });
  const updateProvider = useUpdateProviderMutation();

  const saveOptOutStatus = async (
    values: TreatmentPlanAdoptionModalFormValues
  ) => {
    if (!values.dontShowAgain || !providerPatient) {
      return;
    }

    const eventProps = {
      providerId: provider.id,
      providerPatientId: providerPatient.id,
      patientUserId: clientId,
    };
    if (isIntakeSession) {
      trackEvent({
        properties: eventProps,
        name: 'Dont Show Again Treatment Plan Button Clicked',
      });
    } else {
      trackEvent({
        properties: eventProps,
        name: 'Dont Show Again Create or Upload Treatment Plan Button Clicked',
      });
    }
    await updateProvider.mutateAsync({
      providerId: provider.id,
      update: { optOutTreatmentPlanUpsell: values.dontShowAgain },
    });
  };

  const handleClose = (values: TreatmentPlanAdoptionModalFormValues) => {
    saveOptOutStatus(values);
    onClose();
  };

  const handleSubmit = async (values: TreatmentPlanAdoptionModalFormValues) => {
    if (!providerPatient) {
      return;
    }

    const eventProps = {
      providerId: provider.id,
      providerPatientId: providerPatient.id,
      patientUserId: clientId,
    };
    if (isIntakeSession) {
      trackEvent({
        properties: eventProps,
        name: 'Create Treatment Plan Button Clicked',
      });
    } else {
      trackEvent({
        properties: eventProps,
        name: 'Create or Upload Treatment Plan Button Clicked',
      });
    }
    await saveOptOutStatus(values);

    navigate(`/clients/${clientId}/clinical?treatmentPlan=true`);
  };

  const patientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  return (
    <Formik<TreatmentPlanAdoptionModalFormValues>
      initialValues={{ dontShowAgain: false }}
      validationSchema={Yup.object().shape({
        dontShowAgain: Yup.boolean().oneOf([true, false]),
      })}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Form id="treatment-plan-upsell-checkbox">
            <Modal
              open={open}
              onClose={() => handleClose(values)}
              title={
                isIntakeSession
                  ? 'Create a treatment plan?'
                  : 'Create or upload a treatment plan?'
              }
              actions={
                <Button
                  disabled={!providerPatient}
                  type="submit"
                  variant="primary"
                  form="treatment-plan-upsell-checkbox"
                >
                  {isIntakeSession
                    ? 'Create treatment plan'
                    : 'Create or upload treatment plan'}
                </Button>
              }
            >
              <div css={modalCss.container}>
                <div css={modalCss.icon}>
                  <IconCalendarDots size={48} />
                </div>
                <div css={modalCss.content}>
                  <BodyText>
                    {isIntakeSession
                      ? `Thanks for confirming your intake session with ${patientFirstName}. Looks like ${patientFirstName} doesn't have a treatment plan on file yet. Create a treatment plan now?`
                      : `Thanks for confirming your first session with ${patientFirstName} on Headway. Looks like ${patientFirstName} doesn't have a treatment plan on file yet. Upload an existing treatment plan or create a new one now?`}
                  </BodyText>
                  <div css={modalCss.showMore}>
                    <Button
                      variant="link"
                      onPress={() => setShowMore(!showMore)}
                      aria-expanded={showMore}
                      aria-controls="treatment-plan-adoption-modal-explanation"
                    >
                      <strong>Why are treatment plans important?</strong>
                    </Button>
                    {showMore ? (
                      <IconCaretUp size="1em" />
                    ) : (
                      <IconCaretDown size="1em" />
                    )}
                  </div>
                  <span
                    id="treatment-plan-adoption-modal-explanation"
                    hidden={!showMore}
                  >
                    <BodyText>
                      Treatment plans are a core aspect of client charts that
                      insurance companies use to confirm the services they pay
                      for. They establish the client's initial diagnosis, set
                      treatment objectives and serve as the basis for monitoring
                      progress in each session note.
                    </BodyText>
                  </span>
                  <FormControl component={Checkbox} name="dontShowAgain">
                    Don't show this again
                  </FormControl>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

const modalCss = {
  container: css({
    ...theme.stack.horizontal,
    gap: theme.spacing.x5,
  }),
  icon: css({
    flexGrow: 0,
    flexShrink: 0,
    'svg > path': {
      fill: theme.color.system.green,
    },
  }),
  content: css({
    ...theme.stack.vertical,
    gap: theme.spacing.x3,
  }),
  showMore: css({
    ...theme.stack.horizontal,
    gap: theme.spacing.x1,
    alignItems: 'center',
  }),
};
