import download from 'downloadjs';
import moment from 'moment';
import React from 'react';

import { AppointmentAuditApi } from '@headway/api/resources/AppointmentAuditApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { useMutation } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { base64ToArrayBuffer } from './utils';

interface ProgressNoteDownloadAgreementModalProps {
  providerId: number;
  clientId: number;
  providerAppointmentIds: number[];
  open: boolean;
  onClose: () => Promise<void>;
}

export const ProgressNoteDownloadAgreementModal = ({
  providerId,
  clientId,
  providerAppointmentIds,
  open,
  onClose,
}: ProgressNoteDownloadAgreementModalProps) => {
  const getAppointmentAuditDownloadMutation = useMutation(
    async () => {
      const progressNoteDownloads =
        await AppointmentAuditApi.getAppointmentAuditDownload({
          appointment_ids: providerAppointmentIds,
          provider_id: providerId,
          patient_id: clientId,
        });
      const currentDate = moment().format('YYYY-MM-DD');

      download(
        base64ToArrayBuffer(
          progressNoteDownloads.zipDownloadData.base64_encoded_bytes
        ),
        `note_export_${currentDate}.zip`,
        'application/zip'
      );

      trackEvent({
        name: 'Agree and Download Button Clicked',
        properties: {
          providerId: providerId,
          providerAppointmentIdList: providerAppointmentIds,
        },
      });
    },
    {
      onSuccess: () => {
        notifySuccess('Progress note download complete!');
      },
      onError: (err: any) => {
        notifyError(
          `Error downloading your client's progress notes - please try again!`
        );
        logException(err);
      },
    }
  );

  return (
    <Modal
      title="Agreement: Download note"
      isOpen={open}
      onDismiss={async () => {
        await onClose();
      }}
    >
      <ModalContent>
        <BodyText>
          <p>
            <strong>HIPAA reminders</strong>
          </p>
        </BodyText>
        <BodyText>
          <ul>
            <li>
              I understand the rules & regulations of HIPAA and how I should
              keep my client's records safe.
            </li>
            <li>
              I attest that it's my responsibility to follow any requests in the
              HIPAA release form, including any redaction requests.
            </li>
          </ul>
        </BodyText>
        <BodyText>
          <p>
            <strong>Insurance company chart review</strong>
          </p>
        </BodyText>
        <BodyText>
          If this is for a payer, please{' '}
          <Link
            href="https://headway.co/contact?submitterType=providing_mental_health_services&primaryIssueType=pit_compliane&secondaryIssueType=documentation__progress_notes_etc._"
            target="_blank"
          >
            send us the request here.
          </Link>
        </BodyText>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          disabled={getAppointmentAuditDownloadMutation.isLoading}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={getAppointmentAuditDownloadMutation.isLoading}
          onPress={async () => {
            await getAppointmentAuditDownloadMutation.mutateAsync();
            onClose();
          }}
        >
          Agree and download
        </Button>
      </ModalFooter>
    </Modal>
  );
};
