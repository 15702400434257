import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import {
  asyncWithLDProvider,
  initialize,
  LDProvider,
  useLDClient,
  useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';
import type {
  LDFlagSet,
  LDFlagValue,
  LDUser,
  ProviderConfig,
} from 'launchdarkly-react-client-sdk';
import React, {
  ComponentType,
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { UserRead } from '@headway/api/models/UserRead';

import { logException } from '../utils/sentry';

/**
 * Read more about our usage and best practices of Launch Darkly here:
 * https://findheadway.atlassian.net/wiki/spaces/EPD/pages/575307813/LaunchDarkly+Feature+Flags
 */

/**
 * LaunchDarkly Context Provider to initialize feature flag service. By default
 * we use the async provider to 1) ensure that the service is loaded before we
 * start using it, and 2) to prevent screen flickering if feature flags affect
 * what the user should see.
 * @param clientSideID - The only required parameter. The client-side ID for
 * LaunchDarkly environments can be found in account settings
 **/
const withFlagsProvider = (config: Omit<ProviderConfig, 'reactOptions'>) =>
  asyncWithLDProvider({
    ...config,
    reactOptions: { useCamelCaseFlagKeys: true },
  });

type IdentifyFlagsUserProps = {
  user?: Pick<UserRead, 'email' | 'id'>;
  userAttributes?: Omit<LDSingleKindContext, 'email' | 'key'>;
};

/**
 * Component that identifies the user to get their feature flags. You can
 * identify the user in `withFlagsProvider`, but typically the flags provider
 * is created before the user is logged in.
 * @param includeEmail - Send the user's email to LaunchDarkly
 * @param user - Authenticated user from our server
 * @param userAttributes - User info to send to LaunchDarkly. No PHI please!
 * For custom attributes, define a userAttributes.custom object { myKey: value }
 */
const IdentifyFlagsUser: FC<IdentifyFlagsUserProps> = ({
  user,
  userAttributes,
}) => {
  const flagsClient = useFlagsClient();

  useEffect(() => {
    async function identifyFlagUser() {
      if (!flagsClient || !user) {
        return;
      }

      try {
        const context = {
          key: user.id.toString(),
          kind: 'user',
          email: user.email,
          visitorId:
            (flagsClient.getContext() as LDSingleKindContext)?.visitorId ||
            flagsClient.getContext().key,
          ...userAttributes,
        };
        await flagsClient.identify(context);
      } catch (err) {
        // if we don't successfully load the flags for this user id
        // log exception and we'll have to make due with the defaults
        logException(err);
      }
    }

    identifyFlagUser();
    // reload identify if the user id changes
  }, [
    user?.id,
    userAttributes?.providerId,
    userAttributes?.activeProviderStatesCount,
    userAttributes?.liveProviderStatesCount,
    userAttributes?.visitorId,
    userAttributes?.providerQuestionnaireId,
  ]);

  return null;
};

type FlagsConsumerProps = {
  children: (flags: LDFlagSet) => ReactElement | null;
  flagRequests: LDFlagSet;
};

/**
 * Component with render props to get flag values. To get a single flag value,
 * use `FlagConsumer`.
 * @param flagRequests - Map of requested flag names to their default value. Default
 * values are overriden by retrieved flag values: { ...defaults, ...retrieved }
 * ```
 * <FlagsConsumer flagRequests={{ aggressiveText: undefined, otherFlag: false }}>
 *   {flags => (
 *     <button>
 *       {flags['aggressiveText'] ? 'CLICK ME' : 'Sign up'}
 *     </button>
 *   )}
 * </FlagsConsumer>
 * ```
 */
const FlagsConsumer: FC<FlagsConsumerProps> = ({
  children,
  flagRequests = {},
}) => {
  const flags: { [flagName: string]: any } = {};
  for (const flag in flagRequests) {
    flags[flag] = useFlag(flag, flagRequests[flag]);
  }

  return children({ ...flags });
};

/**
 * Hook to use a flag value in function components. To get all flags, use
 * `useFlags`.
 * This should be used for Sigmund and Atlas.
 * ```
 * const showNewFeature = useFlag('new-feature');
 * if (showNewFeature) { ... }
 * ```
 **/
const useFlag = (flag: string, defaultValue?: LDFlagValue) => {
  const { [flag]: flagValue } = useLDFlags();
  return flagValue === undefined ? defaultValue : flagValue;
};

/** Hook to use the LaunchDarkly client directly in function components. */
const useFlagsClient = useLDClient;

type FlagsBreakingVersionPromptProps = {
  flag: string;
  prompt: ComponentType<{ open: boolean }>;
};

/**
 * Renders the provided refresh prompt when the breaking version flag changes value.
 * @param flag - Name of the flag that keeps track of breaking versions for the app
 * @param prompt - Component that will render when breaking version changes to
 * prompt the user to refresh
 */
const FlagsBreakingVersionPrompt: FC<FlagsBreakingVersionPromptProps> = ({
  flag,
  prompt: Prompt,
}) => {
  const breakingVersion = useFlag(flag);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(flag)) {
      localStorage.setItem(flag, breakingVersion);
    } else if (
      breakingVersion &&
      localStorage.getItem(flag) !== breakingVersion.toString()
    ) {
      localStorage.setItem(flag, breakingVersion);
      setShowPrompt(true);
    }
  }, [breakingVersion]);

  return <Prompt open={showPrompt} />;
};

/**
 * Should only be used in class components where FlagsConsumer is not sufficient
 * @param Component - Name of the Component that needs the requested flags
 * @param flagRequests - Map of requested flag names to their default value. Default values are overriden by flag values retrieved from LaunchDarkly.
 */
const withFlags = (
  Component: React.ComponentClass<any, any>,
  flagRequests: LDFlagSet
) => {
  return (props: any) => {
    const flags: { [flagName: string]: any } = {};
    for (const flag in flagRequests) {
      flags[flag] = useFlag(flag, flagRequests[flag]);
    }

    return <Component {...props} flags={flags} />;
  };
};

export {
  FlagsBreakingVersionPrompt,
  FlagsConsumer,
  IdentifyFlagsUser,
  useFlag,
  useFlagsClient,
  withFlags,
  withFlagsProvider,
  LDProvider,
  initialize,
};

/**
 * Re-exported types from `launchdarkly-react-client-sdk`. Consumers should use
 * these types to avoid importing from `launchdarkly-react-client-sdk` directly
 * as it is an implementation detail of this file.
 */
export type { LDFlagSet, LDUser, LDFlagValue };
