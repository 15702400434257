import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import { ASRSAgeCategory } from '@headway/api/models/ASRSAgeCategory';
import { ASRSResponse } from '@headway/api/models/ASRSResponse';
import {
  ASRSSubmission,
  ASRSSubmissionAssessmentType,
} from '@headway/api/models/ASRSSubmission';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  ASRS_PART_A_KEY_TO_QUESTION_TEXT,
  ASRS_PART_B_KEY_TO_QUESTION_TEXT,
  ASRS_VALUE_TO_OPTION_TEXT,
} from '@headway/shared/constants/patientAssessments';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { PdfText } from '../pdf';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const ASRS_OPTIONS: OptionsSchema = [
  {
    displayText: ASRS_VALUE_TO_OPTION_TEXT[ASRSResponse.NEVER],
    value: ASRSResponse.NEVER,
  },
  {
    displayText: ASRS_VALUE_TO_OPTION_TEXT[ASRSResponse.RARELY],
    value: ASRSResponse.RARELY,
  },
  {
    displayText: ASRS_VALUE_TO_OPTION_TEXT[ASRSResponse.SOMETIMES],
    value: ASRSResponse.SOMETIMES,
  },
  {
    displayText: ASRS_VALUE_TO_OPTION_TEXT[ASRSResponse.OFTEN],
    value: ASRSResponse.OFTEN,
  },
  {
    displayText: ASRS_VALUE_TO_OPTION_TEXT[ASRSResponse.VERY_OFTEN],
    value: ASRSResponse.VERY_OFTEN,
  },
];

const ASRS_QUESTIONS_PART_A: QuestionsSchema = [
  {
    key: 'ASRS_1',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_1,
  },
  {
    key: 'ASRS_2',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_2,
  },
  {
    key: 'ASRS_3',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_3,
  },
  {
    key: 'ASRS_4',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_4,
  },
  {
    key: 'ASRS_5',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_5,
  },
  {
    key: 'ASRS_6',
    questionText: ASRS_PART_A_KEY_TO_QUESTION_TEXT.ASRS_6,
  },
];

const ASRS_QUESTIONS_PART_B: QuestionsSchema = [
  {
    key: 'ASRS_7',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_7,
  },
  {
    key: 'ASRS_8',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_8,
  },
  {
    key: 'ASRS_9',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_9,
  },
  {
    key: 'ASRS_10',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_10,
  },
  {
    key: 'ASRS_11',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_11,
  },
  {
    key: 'ASRS_12',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_12,
  },
  {
    key: 'ASRS_13',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_13,
  },
  {
    key: 'ASRS_14',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_14,
  },
  {
    key: 'ASRS_15',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_15,
  },
  {
    key: 'ASRS_16',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_16,
  },
  {
    key: 'ASRS_17',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_17,
  },
  {
    key: 'ASRS_18',
    questionText: ASRS_PART_B_KEY_TO_QUESTION_TEXT.ASRS_18,
  },
];

const AGE_QUESTION: QuestionsSchema = [
  {
    key: 'AGE',
    questionText: 'First, what is your age?',
  },
];
const AGE_OPTIONS: OptionsSchema = [
  {
    displayText: 'Younger than 18 years old',
    value: ASRSAgeCategory.UNDER_18,
  },
  {
    displayText: '18-29 years old',
    value: ASRSAgeCategory._18_TO_29,
  },
  {
    displayText: '30-39 years old',
    value: ASRSAgeCategory._30_TO_39,
  },
  {
    displayText: '40-49 years old',
    value: ASRSAgeCategory._40_TO_49,
  },
  {
    displayText: '50-64 years old',
    value: ASRSAgeCategory._50_TO_64,
  },
  {
    displayText: '65+ years old',
    value: ASRSAgeCategory._65_PLUS,
  },
];

const INSTRUCTION_TEXT =
  'Please answer the questions below, rating yourself on each of the criteria shown. As you answer each question, select the option that best describes how you have felt and conducted yourself over the PAST 6 MONTHS.';
const INSTRUCTION = <b>{INSTRUCTION_TEXT}</b>;

export const SOURCE_LABEL = 'Source:';
export const COPYRIGHT_INFO =
  'JB Schweitzer, et al. The Adult Self-Report Scale (ASRSv1.1). 85(3): Med Clin North Am. 757-777. 2001';
const COPYRIGHT = (
  <div className="flex flex-col gap-2">
    <SubBodyText>
      <strong>{SOURCE_LABEL}:</strong>
    </SubBodyText>
    <SubBodyText color="gray">{COPYRIGHT_INFO}</SubBodyText>
  </div>
);

type SectionTitleProps = {
  children: React.ReactNode;
};

const SectionTitle = ({ children }: SectionTitleProps) => {
  return (
    <div className="bg-system-backgroundGray w-fit rounded p-2">
      <SubBodyText>
        <b>{children}</b>
      </SubBodyText>
    </div>
  );
};

const ASRS_VALIDATION_SCHEMA = Yup.object().shape({
  AGE: Yup.string().required('Age is a required field'),
  ...[...ASRS_QUESTIONS_PART_A, ...ASRS_QUESTIONS_PART_B].reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().when('AGE', {
        is: (age: ASRSAgeCategory) => age != ASRSAgeCategory.UNDER_18,
        then: Yup.string().required('Select an option'),
      });
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  ),
});

type ASRSFormValues = {
  [key in keyof Omit<ASRSSubmission, 'assessmentType'>]: string;
};

export const ASRSAssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: ASRSFormValues) => {
    return onSubmit({
      assessmentType: ASRSSubmissionAssessmentType.ASRS,
      ...(mapValues(values, Number) as ASRSSubmission),
    });
  };

  return (
    <Formik<ASRSFormValues>
      onSubmit={innerOnSubmit}
      validationSchema={ASRS_VALIDATION_SCHEMA}
      initialValues={{
        AGE: '',
        ASRS_1: '',
        ASRS_2: '',
        ASRS_3: '',
        ASRS_4: '',
        ASRS_5: '',
        ASRS_6: '',
        ASRS_7: '',
        ASRS_8: '',
        ASRS_9: '',
        ASRS_10: '',
        ASRS_11: '',
        ASRS_12: '',
        ASRS_13: '',
        ASRS_14: '',
        ASRS_15: '',
        ASRS_16: '',
        ASRS_17: '',
        ASRS_18: '',
      }}
    >
      {({ values }) => (
        <SafeFormikForm id={id}>
          <ErrorScrollListener />
          <section className="flex flex-col gap-6">
            {INSTRUCTION}
            <ScorableQuestionListForm
              questions={AGE_QUESTION}
              options={AGE_OPTIONS}
            />
            {values.AGE && parseInt(values.AGE) === ASRSAgeCategory.UNDER_18 ? (
              <GuidanceCard variant="warning">
                This assessment is designed for adults 18 and older. We will let
                your provider know once you submit responses for this
                assessment.
              </GuidanceCard>
            ) : (
              <>
                <SectionTitle>Part A</SectionTitle>
                <ScorableQuestionListForm
                  questions={ASRS_QUESTIONS_PART_A}
                  options={ASRS_OPTIONS}
                />
                <SectionTitle>Part B</SectionTitle>
                <ScorableQuestionListForm
                  questions={ASRS_QUESTIONS_PART_B}
                  options={ASRS_OPTIONS}
                  offset={ASRS_QUESTIONS_PART_A.length}
                />
                {COPYRIGHT}
              </>
            )}
          </section>
        </SafeFormikForm>
      )}
    </Formik>
  );
};

export const ReadonlyASRSAssessment = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>{INSTRUCTION}</SubBodyText>
      <ReadonlyScorableQuestionList
        questions={AGE_QUESTION}
        options={AGE_OPTIONS}
        response={scorableResponseJson}
      />
      <SectionTitle>Part A</SectionTitle>
      <ReadonlyScorableQuestionList
        questions={ASRS_QUESTIONS_PART_A}
        options={ASRS_OPTIONS}
        response={scorableResponseJson}
      />
      <SectionTitle>Part B</SectionTitle>
      <ReadonlyScorableQuestionList
        questions={ASRS_QUESTIONS_PART_B}
        options={ASRS_OPTIONS}
        response={scorableResponseJson}
        offset={ASRS_QUESTIONS_PART_A.length}
      />
      {COPYRIGHT}
    </section>
  );
};

export const PdfASRSAssessment = ({ response }: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  if (
    scorableResponseJson &&
    (scorableResponseJson as ASRSSubmission)['AGE'] === ASRSAgeCategory.UNDER_18
  ) {
    return (
      <PdfText>
        The client indicated they are under 18 years old. The ASRS is designed
        only for adults 18+.
      </PdfText>
    );
  }

  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <PdfText style={{ fontWeight: 'medium' }}>{INSTRUCTION_TEXT}</PdfText>
      <PdfScorableQuestionList
        questions={AGE_QUESTION}
        options={AGE_OPTIONS}
        response={scorableResponseJson}
        isNumbered={false}
      />
      <PdfText style={{ fontWeight: 'medium' }}>Part A</PdfText>
      <PdfScorableQuestionList
        questions={ASRS_QUESTIONS_PART_A}
        options={ASRS_OPTIONS}
        response={scorableResponseJson}
      />
      <PdfText style={{ fontWeight: 'medium' }}>Part B</PdfText>
      <PdfScorableQuestionList
        questions={ASRS_QUESTIONS_PART_B}
        options={ASRS_OPTIONS}
        response={scorableResponseJson}
        offset={ASRS_QUESTIONS_PART_A.length}
      />
    </View>
  );
};
