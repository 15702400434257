import { useProvider } from 'hooks';
import React, { MutableRefObject, useContext, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { Attachment, AttachmentsList } from 'views/Patients/AttachmentsList';

import { AppointmentConfirmationModalFormV2Values } from '../../AppointmentConfirmationModalV2';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from '../../stores/ProgressNotesContext';

enum AddendumType {
  TEXT = 'TEXT',
  UPLOAD = 'UPLOAD',
}

export interface AddendumsFormV2Values {
  addendumHtml: string | undefined;
  attachments: Attachment<string>[];
  addendumType: AddendumType;
}

export const getAddendumsInitialValues = (): AddendumsFormV2Values => {
  return {
    addendumHtml: undefined,
    attachments: [],
    addendumType: AddendumType.TEXT,
  };
};

const onDownloadAttachment = async (attachment: Attachment<string>) => {
  if (!attachment.link || !attachment.name) {
    return;
  }

  try {
    await downloadFile({ link: attachment.link, name: attachment.name });
  } catch (err) {
    notifyError('There was an issue downloading your attachment');
    logException(err);
  }
};

export const AddendumEdit = ({
  progressNoteType,
  addendums,
  provider,
}: {
  progressNoteType: ProgressNoteType;
  addendums: ProviderAppointmentAddendumRead[];
  provider: ProviderRead;
}) => {
  const { user } = useAuthStore();
  const isImpersonatingProvider = isGroupAdminImpersonatingProvider(
    provider,
    user
  );
  const {
    control,
    formState: { errors },
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();
  const watchedValue: AddendumsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'addendums',
    });

  const [attachmentsList, setAttachmentsList] = useState<Attachment<string>[]>(
    []
  );

  /**
   * We want to restrict file type to only PDF with certain restrictions
   */
  const { allowedUploadTypes } = useContext(ProgressNoteContext);
  const allowedFileTypes =
    allowedUploadTypes ??
    'application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png';

  const addAttachments = async (files: UploadedFile[]) => {
    const fileAttachment = files.map((file, idx) => ({
      id: file.name,
      name: file.name,
      link: file.link,
      s3ObjectKey: file.s3ObjectKey,
    }));

    setAttachmentsList([...attachmentsList, ...fileAttachment]);
  };

  const onDeleteAttachment = (id: string) => {
    const filteredAttachmentList = attachmentsList.filter(
      (attachment) => attachment.name !== id
    );
    setAttachmentsList(filteredAttachmentList);
  };

  const renderAddendumContent = () => {
    switch (watchedValue.addendumType) {
      case AddendumType.TEXT:
        return (
          <>
            {progressNoteType === ProgressNoteType.UPLOAD ? (
              <div css={{ marginBottom: theme.spacing.x4 }}>
                {!isImpersonatingProvider ? (
                  <BodyText>
                    Reminder: To be compliant, documentation must be updated and
                    consistent across all locations where you’ve signed it.
                  </BodyText>
                ) : (
                  <BodyText>
                    Only the provider present at the session can write an
                    addendum
                  </BodyText>
                )}
              </div>
            ) : isImpersonatingProvider ? (
              <div css={{ marginBottom: theme.spacing.x4 }}>
                <SubBodyText>
                  Only the provider present at the session can write an addendum
                </SubBodyText>
              </div>
            ) : null}
            <Controller
              name="addendums.addendumHtml"
              control={control}
              disabled={isImpersonatingProvider}
              render={({ field: { onChange, onBlur, value } }) => (
                <RichTextArea
                  name="addendums.addendumHtml"
                  placeholder="Enter addendum notes..."
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </>
        );

      case AddendumType.UPLOAD:
        return (
          <>
            {progressNoteType === ProgressNoteType.UPLOAD ||
            progressNoteType === ProgressNoteType.TEMPLATE ? (
              <div css={{ marginBottom: theme.spacing.x4 }}>
                <BodyText>
                  Reminder: To be compliant, an addendum should add to, change
                  or clarify details, not remove them.
                </BodyText>
              </div>
            ) : null}
            <AttachmentsList
              provider={provider}
              attachments={watchedValue.attachments || []} // Default to an empty array
              onAddAttachments={addAttachments}
              onDownloadAttachment={onDownloadAttachment}
              onDeleteAttachment={onDeleteAttachment}
              accept={allowedFileTypes}
              variant={'helix'}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {
        {
          [ProgressNoteType.UPLOAD]: (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
              }}
            >
              <SectionHeader>Addendum {addendums.length + 1}</SectionHeader>
              <BodyText>
                Specify any additions or changes. You can write your addendum
                using free text or upload an existing addendum.
              </BodyText>
            </div>
          ),
          [ProgressNoteType.TEMPLATE]: (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: theme.spacing.x4,
              }}
            >
              <SectionHeader>Addendum {addendums.length + 1}</SectionHeader>
              {!isImpersonatingProvider && (
                <SubBodyText>
                  Specify any additions or changes, which will appear at the
                  bottom of your original note.
                </SubBodyText>
              )}
            </div>
          ),
          [ProgressNoteType.NONE]: null,
        }[progressNoteType]
      }
      {progressNoteType === ProgressNoteType.UPLOAD ||
      progressNoteType === ProgressNoteType.TEMPLATE ? (
        <div
          css={{
            marginBottom: theme.spacing.x4,
            marginTop: theme.spacing.x2,
          }}
        >
          <Controller
            name="addendums.addendumType"
            control={control}
            disabled={
              watchedValue.attachments.length > 0 ||
              (watchedValue.addendumHtml !== undefined &&
                watchedValue.addendumHtml !== '')
            }
            render={({ field: { onChange, onBlur, value } }) => (
              <BigRadioGroup
                name="addendums.addendumType"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                aria-label="Fill out a template or upload an existing note"
              >
                <div css={{ flex: 1 }}>
                  <BigRadio key="text" value={AddendumType.TEXT}>
                    Free Text
                  </BigRadio>
                </div>
                <div css={{ flex: 1 }}>
                  <BigRadio key="upload" value={AddendumType.UPLOAD}>
                    Upload Existing Addendum
                  </BigRadio>
                </div>
              </BigRadioGroup>
            )}
          />
        </div>
      ) : null}
      {renderAddendumContent()}
      {errors.attachments ? (
        <div
          css={{
            marginTop: '20px',
            color: theme.color.primary.red,
            ...theme.typography.caption.medium,
          }}
        >
          {/* Check if errors.attachments is an array */}
          {Array.isArray(errors.attachments) ? (
            errors.attachments.map((error, index) => (
              <div key={index}>{error.message}</div>
            ))
          ) : (
            <div>{errors.attachments.message}</div> // Handle case where it's a single error object
          )}
        </div>
      ) : null}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: theme.spacing.x4,
          paddingBottom: theme.spacing.x4,
        }}
      >
        <SectionHeader>Declaration</SectionHeader>
        <BodyText>
          By clicking "Sign and submit addendum" I attest that the content of
          this addendum is true and accurate.
        </BodyText>
      </div>
    </>
  );
};

export const AddendumsFormV2 = () => {
  const {
    progressNoteState,
    addendums,
    addendumContainerRef,
    progressNoteType,
  } = useContext(ProgressNoteContext);
  const provider = useProvider();

  return (
    <div
      css={{ marginBottom: theme.spacing.x6 }}
      ref={addendumContainerRef as MutableRefObject<HTMLDivElement>}
    >
      {addendums.map((addendum, idx) => {
        const type = addendum.addendumHtml
          ? AddendumType.TEXT
          : AddendumType.UPLOAD;
        return (
          <div css={{ marginBottom: theme.spacing.x4 }} key={addendum.id}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: theme.spacing.x2,
              }}
            >
              <SectionHeader color="disabledGray">
                Addendum {(idx + 1).toString()}
              </SectionHeader>
            </div>
            {
              {
                [AddendumType.TEXT]: (
                  <RichTextArea
                    data-testid={`addendumFreetext${idx + 1}`}
                    disabled={true}
                    name={`addendumFreetext${idx + 1}`}
                    value={addendum.addendumHtml}
                  />
                ),
                [AddendumType.UPLOAD]: (
                  <AttachmentsList
                    provider={provider}
                    attachments={addendum.attachments.map((a) => ({
                      name: a.name!,
                      id: a.name!,
                      link: a.link!,
                      s3ObjectKey: a.s3ObjectKey!,
                    }))}
                    disabled
                    hideDropzone
                    isDeleteDisabled
                    onDownloadAttachment={onDownloadAttachment}
                    accept="application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png"
                    variant={'helix'}
                  />
                ),
              }[type]
            }
          </div>
        );
      })}

      {progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT && (
        <AddendumEdit
          progressNoteType={progressNoteType}
          provider={provider}
          addendums={addendums}
        />
      )}
    </div>
  );
};
