import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconCalendarDots } from '@headway/helix/icons/CalendarDots';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { usePatientGeneralPriceEstimate } from '@headway/shared/hooks/usePatientGeneralPriceEstimate';

import { getDisputePriceEstimateRedirectUrl } from 'utils/contactFormLinks';

import { IconListCard } from '../../components/IconListCard/IconListCard';
import { getPriceString } from '../../utils/price';

interface NonInsuranceOutagePriceVerificationModalProps {
  isOpen: boolean;
  patient: UserRead;
  onDismiss: () => void;
  onUpdateInsuranceInformation?: () => void;
}

export const NonInsuranceOutagePriceVerificationModal = ({
  isOpen,
  patient,
  onDismiss,
  onUpdateInsuranceInformation,
}: NonInsuranceOutagePriceVerificationModalProps) => {
  const navigate = useNavigate();
  const provider = useProvider();
  const { data: estimatedPrice, isLoading: estimatedPriceIsLoading } =
    usePatientGeneralPriceEstimate(patient.id, { enabled: isOpen });

  const disputePriceEstimateRedirectUrl = getDisputePriceEstimateRedirectUrl(
    patient.id
  );

  return (
    <Modal
      title={<div css={title}>We're here to help: about price estimates</div>}
      onDismiss={onDismiss}
      isOpen={isOpen}
    >
      <ModalContent>
        <div css={insuranceBox}>
          <div css={insuranceTitle}>
            {estimatedPrice && !estimatedPriceIsLoading ? (
              <>
                <BodyText>
                  <strong>
                    With{' '}
                    {patient.activeUserInsurance?.billingFrontEndCarrierName},
                    your client’s estimated cost is{' '}
                    {getPriceString(estimatedPrice.min, estimatedPrice.max)} per
                    session.
                  </strong>
                </BodyText>
              </>
            ) : (
              <BodyText>
                <strong>
                  Here is your client’s current insurance information:
                </strong>
              </BodyText>
            )}
          </div>
          <div css={insuranceInfo}>
            {patient.activeUserInsurance?.memberId && (
              <div css={insuranceInfoItem}>
                <SubBodyText color="gray">Member ID</SubBodyText>
                <BodyText>
                  {patient.activeUserInsurance.correctedMemberId
                    ? patient.activeUserInsurance.correctedMemberId
                    : patient.activeUserInsurance.memberId}
                </BodyText>
              </div>
            )}
            {patient.activeUserInsurance?.groupNumber && (
              <div css={insuranceInfoItem}>
                <SubBodyText color="gray">Group number</SubBodyText>
                <BodyText>
                  {patient.activeUserInsurance.correctedGroupNumber
                    ? patient.activeUserInsurance.correctedGroupNumber
                    : patient.activeUserInsurance.groupNumber}
                </BodyText>
              </div>
            )}
          </div>

          <div css={{ gridColumn: 'span 2' }}>
            <SubBodyText>
              Wrong or outdated information?{' '}
              <Button
                variant="link"
                type="submit"
                size="medium"
                onPress={() => {
                  if (onUpdateInsuranceInformation) {
                    onUpdateInsuranceInformation();
                  } else {
                    navigate(`/clients/${patient.id}/billing?showBillingModal`);
                  }
                }}
              >
                Update insurance
              </Button>
            </SubBodyText>
          </div>
        </div>

        <div css={greetingText}>
          <BodyText>Hi {provider.displayFirstName},</BodyText>
        </div>
        <div css={section}>
          <BodyText>
            {patient.displayFirstName}’s session cost is our best estimate for
            how much they'll pay, based on what we know today about their
            insurance plan’s benefits and your typical services.
          </BodyText>
        </div>
        <div css={section}>
          <BodyText>
            We do our best to get this estimate right{'\u2014'}based on our
            relationships with insurance companies and experience with hundreds
            of different plan types. The final cost usually matches or comes
            close to this number.
          </BodyText>
        </div>
        <div css={section}>
          <BodyText>
            <strong>What you should expect next</strong>
          </BodyText>
        </div>
        <div css={section}>
          <IconListCard
            iconTextPairings={[
              [
                <IconCreditCardCheck />,
                <BodyText>
                  <strong>
                    {patient.displayFirstName}’s insurer will confirm the final
                    cost after you confirm your session.
                  </strong>{' '}
                  This process can take anywhere from 14-45 days.
                </BodyText>,
              ],
              [
                <IconCalendarDots />,
                <BodyText>
                  <strong>
                    If the final cost doesn't match our cost estimate,{' '}
                  </strong>
                  we'll refund their payment method with the difference, or send
                  a second bill if the confirmed cost is higher. There's nothing
                  else you need to do at this time.
                </BodyText>,
              ],
            ]}
          />
        </div>
        <div css={section}>
          <BodyText>
            Need an updated cost estimate sooner? You or your client can request
            the Headway team do a manual verification, which requires a bit more
            information from you, and will usually take 7-15 days.
          </BodyText>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          onPress={() => {
            onDismiss();
            window.open(disputePriceEstimateRedirectUrl, '_blank');
          }}
          variant="secondary"
          size="large"
        >
          Start manual verification
        </Button>
        <Button onPress={onDismiss} variant="primary" size="large">
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const title = css`
  display: flex;
  align-items: center;
  color: ${theme.color.system.textBlack};
  svg {
    margin-right: ${theme.spacing.x2};
  }
`;

const insuranceBox = css`
  border: 1px solid ${theme.color.system.borderGray};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x5};
  gap: ${theme.spacing.x5};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  align-items: flex-start;
`;

const insuranceTitle = css`
  display: flex;
  gap: ${theme.spacing.x2};
  grid-column: span 2;
`;

const insuranceInfo = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  grid-column: span 2;
`;

const insuranceInfoItem = css`
  display: grid;
  row-gap: ${theme.spacing.x1};
  flex-grow: 1;
  grid-column: span 1;
`;

const greetingText = css`
  margin-top: ${theme.spacing.x7};
  margin-bottom: ${theme.spacing.x4};
`;
const section = css`
  margin-bottom: ${theme.spacing.x8};
`;
