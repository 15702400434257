import { useProvider } from 'hooks';
import React, { useContext, useEffect, useState } from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  BCBSMA_VIRTUAL_NETWORK_LINK,
  BCBSNJ_VIRTUAL_NETWORK_LINK,
  CONTACT_FORM_LINK,
  SELF_PAY_LINK,
} from '@headway/shared/constants/zendesk';
import { SITE_MESSAGING_OPPS } from '@headway/shared/FeatureFlags/flagNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useUser } from '@headway/shared/hooks/useUser';
import { useUserAppointmentReadiness } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { trackPageView } from '@headway/shared/utils/analytics';
import {
  getHumanInputLookupIssues,
  getIneligibleLookupTypeFromReadinessIssues,
  hasHeadwaySourcedHumanInputError,
  isStatusInNetwork,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { ContactFormInsuranceAlerts } from '@headway/ui/InsuranceEligibilityAlerts';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { useClaimReadiness } from 'hooks/useClaimReadiness';
import {
  useInsuranceStatus,
  useInsuranceStatusList,
} from 'hooks/useInsuranceStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';
import { useAuthStore } from 'stores/AuthStore';
import { HumanErrorGuidanceCard } from 'views/Calendar/components/HumanErrorGuidanceCard';
import {
  getStatesByPatientInsuranceStatus,
  patientInsuranceStatusBannerPrefix,
  PatientInsuranceStatusToConfigMap,
  StatePatientInsuranceStatusDetails,
} from 'views/Patients/utils/patientInsuranceStatus';

import { FrozenWaivedSessionMaxHitModal } from './FrozenWaivedSessionMaxHitModal';
import { WaivedSessionsRemainingGuidanceCard } from './WaivedSessionsRemainingGuidanceCard';

interface InsuranceStatusGuidanceCardProps {
  clientId: number;
  providerId: number;
  onOpenInsuranceModal: () => void;
  onClickOpenBillingMethodModal: () => void;
  onOpenContactFormInsuranceIssues: () => void;
  onOpenNoDataOutageVerificationInProgressModal: () => void;
  onOpenOldDataOutageVerificationInProgressModal: () => void;
  onClickOpenInsuranceIneligibilityExplainerModal: () => void;
  onOpenPlanIneligibleAlertModal: () => void;
  wasInNoDataOutage: boolean;
  wasInOldDataOutage: boolean;
}

export const InsuranceStatusGuidanceCard = ({
  clientId,
  providerId,
  onOpenContactFormInsuranceIssues,
  onOpenInsuranceModal,
  onClickOpenBillingMethodModal,
  onClickOpenInsuranceIneligibilityExplainerModal,
  onOpenNoDataOutageVerificationInProgressModal,
  onOpenOldDataOutageVerificationInProgressModal,
  onOpenPlanIneligibleAlertModal,
  wasInNoDataOutage,
  wasInOldDataOutage,
}: InsuranceStatusGuidanceCardProps) => {
  const { user: providerUser } = useAuthStore();
  const provider = useProvider();
  const { data: client } = useUser({ userId: clientId });
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction, restrictionDate } =
    useMSCGuardrail();
  const isMSCEnabled =
    isMSCGuardrailWarning || isMSCGuardrailRestriction || isMSCBetaEnabled;
  const { insuranceStatus, isLoading: isLoadingInsuranceStatus } =
    useInsuranceStatus(
      client,
      client?.activeUserInsurance,
      undefined,
      undefined,
      isMSCEnabled
    );
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery({
      providerId,
      patientUserId: clientId,
      includeHiddenCarriers: true,
    });
  const [
    isFrozenForWaivedSessionsModalOpen,
    setIsFrozenForWaivedSessionsModalOpen,
  ] = useState<boolean>(false);

  // get patient insurance status for each possible state for Multi-State Credentialing
  const { data: patientAddresses } = usePatientAddresses({
    patientId: client?.id,
  });
  const { providerAddresses } = useContext(ProviderAddressContext);
  const patientAddressStates = patientAddresses
    ? patientAddresses?.map((patientAddress) => patientAddress.state)
    : [];
  const providerAddressStates = providerAddresses
    .map((address) => {
      if (address.state && address.isActive) {
        return abbreviationToStateEnum[address.state] as UnitedStates;
      }
      return undefined;
    })
    .filter((state): state is UnitedStates => state !== undefined);
  const plsStates: UnitedStates[] = provider.allProviderLicenseStates.map(
    (plsState) => plsState.state
  );
  const {
    insuranceStatuses: plsStatesInsuranceStatuses,
    isLoading: isPlsStatesInsuranceStatusesLoading,
  } = useInsuranceStatusList(
    plsStates.map((state) => ({
      isTelehealthAppointment: true,
      appointmentState: state,
      checkAppointmentState: true,
    })),
    client,
    client?.activeUserInsurance
  );
  const plsStatesToAdd: UnitedStates[] = plsStates.filter(
    (state, stateIndex) => {
      const stateInsuranceStatus = plsStatesInsuranceStatuses[stateIndex];
      return (
        stateInsuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
        stateInsuranceStatus ===
          PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK
      );
    }
  );

  const allStates = Array.from(
    new Set([
      ...patientAddressStates,
      ...providerAddressStates,
      ...plsStatesToAdd,
    ]).values()
  );

  const {
    insuranceStatuses: statePatientInsuranceStatuses,
    isLoading: isUseInsuranceStatusListLoading,
  } = useInsuranceStatusList(
    allStates.map((state) => ({
      isTelehealthAppointment:
        patientAddressStates.includes(state) &&
        !providerAddressStates.includes(state),
      appointmentState: state,
      checkAppointmentState: isMSCEnabled,
    })),
    client,
    client?.activeUserInsurance
  );
  let statesByPatientInsuranceStatus: {
    [key: string]: StatePatientInsuranceStatusDetails[];
  } = {};
  if (
    !isUseInsuranceStatusListLoading ||
    !isPlsStatesInsuranceStatusesLoading
  ) {
    statesByPatientInsuranceStatus = getStatesByPatientInsuranceStatus(
      allStates,
      statePatientInsuranceStatuses,
      patientAddressStates,
      providerAddressStates
    );
  }

  const isInNoDataOutage =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_OUTAGE;
  const isInOldDataOutage =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_OUTAGE;

  const { data: claimReadiness } = useClaimReadiness({ patientUser: client });

  const isHumanErrorBannerEnabled = !!claimReadiness?.requirements?.includes(
    UserClaimReadinessCheck.INSUFFICIENT_OR_INCORRECT_INFORMATION
  );

  const siteMessagingOpps = useFlag(SITE_MESSAGING_OPPS, false);
  const accumulatorsPresumedReset =
    client?.activeUserInsurance?.latestOutage?.accumulatorsPresumedReset;

  const userAppointmentReadinessQuery = useUserAppointmentReadiness({
    userId: clientId,
  });

  const humanInputLookupIssues =
    getHumanInputLookupIssues(userAppointmentReadinessQuery?.data) || [];

  const shouldShowNoContent =
    !client ||
    isLoadingInsuranceStatus ||
    !insuranceStatus ||
    userAppointmentReadinessQuery.isLoading;
  const shouldShowHumanInputErrorGuidanceCard =
    isHumanErrorBannerEnabled &&
    hasHeadwaySourcedHumanInputError(humanInputLookupIssues);

  const shouldShowInsuranceOutageGuidanceCard =
    isInNoDataOutage || isInOldDataOutage;
  const shouldTrackInsuranceOutageContentView =
    !!client?.activeUserInsurance &&
    !shouldShowHumanInputErrorGuidanceCard &&
    shouldShowInsuranceOutageGuidanceCard &&
    !shouldShowNoContent;

  useEffect(() => {
    if (
      (isInNoDataOutage || isInOldDataOutage) &&
      shouldTrackInsuranceOutageContentView
    ) {
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          providerId: providerId,
          patientUserId: client.id,
          userInsuranceId: `${client.activeUserInsurance!.id}`,
          prelimPricingType: `${client.activeUserInsurance!.latestOutageType}`,
          copyVariant: '',
          presumptiveAccumulatorsApplied: accumulatorsPresumedReset ?? [],
        },
      });
    }
  }, [
    client?.activeUserInsurance,
    shouldTrackInsuranceOutageContentView,
    isInNoDataOutage,
    isInOldDataOutage,
  ]);

  const shouldShowIneligibleAlertCard =
    (wasInNoDataOutage || wasInOldDataOutage) &&
    !isStatusInNetwork(insuranceStatus);

  const shouldTrackIneligibleAlertContentView =
    !!client?.activeUserInsurance &&
    !shouldShowHumanInputErrorGuidanceCard &&
    !shouldShowInsuranceOutageGuidanceCard &&
    shouldShowIneligibleAlertCard &&
    !shouldShowNoContent &&
    !!userAppointmentReadinessQuery.data?.insurance?.length;

  useEffect(() => {
    if (shouldTrackIneligibleAlertContentView) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: client.id,
          providerId: providerId,
          userInsuranceId: String(client.activeUserInsurance!.id),
          eligibilityLookupId:
            client!.activeUserInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType: getIneligibleLookupTypeFromReadinessIssues(
            userAppointmentReadinessQuery?.data?.insurance
          ),
        },
      });
    }
  }, [
    client,
    providerId,
    shouldTrackIneligibleAlertContentView,
    userAppointmentReadinessQuery?.data?.insurance,
  ]);

  if (shouldShowNoContent) {
    return null;
  }

  const { guidanceCardVariant, guidanceCardLayout, getExplanation } =
    PatientInsuranceStatusToConfigMap[insuranceStatus];

  const needsVerification =
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION ||
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.MATCHING_PENDING_VERIFICATION;

  const showSecondaryInsuranceLearnMoreLink =
    insuranceStatus === PatientInsuranceOrEAPStatus.SECONDARY_INSURANCE;

  const showExtraInfoForOON =
    insuranceStatus === PatientInsuranceOrEAPStatus.OON_FREEZE;

  const showUpdateInsuranceLinkForFreezeStatus =
    insuranceStatus === PatientInsuranceOrEAPStatus.COB_FREEZE_AWAITING_USER ||
    insuranceStatus === PatientInsuranceOrEAPStatus.TERMED_PLAN_FREEZE;

  const formattedClientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  let virtualNetworkHelpCenterLink = '';
  if (
    client.activeUserInsurance?.frontEndCarrierId ===
    FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS
  ) {
    virtualNetworkHelpCenterLink = BCBSMA_VIRTUAL_NETWORK_LINK;
  } else if (
    client.activeUserInsurance?.frontEndCarrierId ===
    FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY
  ) {
    virtualNetworkHelpCenterLink = BCBSNJ_VIRTUAL_NETWORK_LINK;
  }

  return (
    <>
      {insuranceStatus !==
        PatientInsuranceOrEAPStatus.WAIVED_SESSION_MAX_HIT_FREEZE && (
        <WaivedSessionsRemainingGuidanceCard patient={client} />
      )}
      {shouldShowHumanInputErrorGuidanceCard ? (
        <HumanErrorGuidanceCard
          patient={client}
          mismatchedFields={
            humanInputLookupIssues[0].patientMismatchInputFields || []
          }
          onOpenInsuranceModal={onClickOpenBillingMethodModal}
        />
      ) : shouldShowInsuranceOutageGuidanceCard ? (
        <GuidanceCard layout={guidanceCardLayout} variant={guidanceCardVariant}>
          <BodyText>
            {getExplanation(
              client,
              isMSCEnabled,
              undefined,
              matchingProviderFrontEndCarrier?.frontEndCarrier.name
            )}
          </BodyText>
          <Button
            size="large"
            variant="link"
            onPress={
              isInNoDataOutage
                ? onOpenNoDataOutageVerificationInProgressModal
                : isInOldDataOutage
                ? onOpenOldDataOutageVerificationInProgressModal
                : () => {}
            }
          >
            Learn more
          </Button>
        </GuidanceCard>
      ) : shouldShowIneligibleAlertCard ? (
        <GuidanceCard variant="error">
          <BodyText>
            {formattedClientFirstName}’s insurance plan is not eligible for
            in-network care on Headway
          </BodyText>
          <Button
            size="large"
            variant="link"
            onPress={onOpenPlanIneligibleAlertModal}
          >
            Learn more
          </Button>
        </GuidanceCard>
      ) : needsVerification ||
        !!client.activeUserInsurance?.latestEligibilityLookup?.outOfNetwork ? (
        <ContactFormInsuranceAlerts
          insurance={client.activeUserInsurance}
          insuranceLocation={'below'}
          contactUs
          isProvider
          providerId={providerId}
          providerUserId={providerUser?.id}
          baseUrl={process.env.REACT_APP_MAIN_URL || ''}
          onClickOpenBillingMethodModal={onClickOpenBillingMethodModal}
          onOpenInsuranceModal={onOpenInsuranceModal}
          onClickOpenInsuranceIneligibilityExplainerModal={
            onClickOpenInsuranceIneligibilityExplainerModal
          }
          isHumanErrorBannerEnabled={isHumanErrorBannerEnabled}
          isSiteMessagingOppsEnabled={siteMessagingOpps}
        />
      ) : !wasInNoDataOutage && !wasInOldDataOutage && isMSCEnabled ? (
        Object.keys(statesByPatientInsuranceStatus).map(
          (patientInsuranceStatus) => {
            // if patient insurance status is virtual only - just make it in network
            const patientInsuranceStatusEnum =
              patientInsuranceStatus === 'VIRTUAL_IN_NETWORK' ||
              patientInsuranceStatus === 'IN_PERSON_IN_NETWORK'
                ? PatientInsuranceOrEAPStatus.IN_NETWORK
                : statesByPatientInsuranceStatus[patientInsuranceStatus][0]
                    .patientInsuranceStatus;
            return (
              <GuidanceCard
                key={patientInsuranceStatus}
                layout={
                  PatientInsuranceStatusToConfigMap[patientInsuranceStatusEnum]
                    .guidanceCardLayout
                }
                variant={
                  PatientInsuranceStatusToConfigMap[patientInsuranceStatusEnum]
                    .guidanceCardVariant
                }
              >
                <div>
                  <span className="font-bold">
                    {patientInsuranceStatusBannerPrefix[
                      patientInsuranceStatus
                    ] ?? ''}
                  </span>
                  {PatientInsuranceStatusToConfigMap[
                    patientInsuranceStatusEnum
                  ].getExplanation(
                    client,
                    isMSCEnabled,
                    statesByPatientInsuranceStatus[patientInsuranceStatus],
                    undefined,
                    isMSCGuardrailWarning,
                    restrictionDate
                    // don't need to pass in matching PFEC for each state because we don't use it in the MSC case
                  )}
                  {showSecondaryInsuranceLearnMoreLink && (
                    <>
                      {' '}
                      <Link
                        href="https://findheadway.zendesk.com/hc/en-us/articles/8637725686036-Seeing-clients-with-secondary-insurance"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </Link>
                    </>
                  )}
                  {showExtraInfoForOON && (
                    <>
                      {' '}
                      When we’re able to accept their plan, they'll be able to
                      access their account.
                      <p />
                      <p>
                        You are welcome to see them via private pay on Headway.{' '}
                        <a
                          href={SELF_PAY_LINK}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more about private pay here
                        </a>
                        .
                      </p>
                      <p>
                        If their insurance information changes, please have them
                        reach out to our team via{' '}
                        <a href={CONTACT_FORM_LINK} target="_blank">
                          our contact form
                        </a>
                        .
                      </p>
                    </>
                  )}
                </div>
                <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {showUpdateInsuranceLinkForFreezeStatus && (
                    <Button
                      variant="link"
                      size="large"
                      onPress={onOpenInsuranceModal}
                    >
                      Update insurance
                    </Button>
                  )}
                  {insuranceStatus ===
                    PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK && (
                    <Link href={virtualNetworkHelpCenterLink} target="_blank">
                      Learn more
                    </Link>
                  )}
                  {insuranceStatus ===
                    PatientInsuranceOrEAPStatus.WAIVED_SESSION_MAX_HIT_FREEZE && (
                    <>
                      <Button
                        variant="link"
                        size="large"
                        onPress={() =>
                          setIsFrozenForWaivedSessionsModalOpen(true)
                        }
                      >
                        See details
                      </Button>
                      <FrozenWaivedSessionMaxHitModal
                        open={isFrozenForWaivedSessionsModalOpen}
                        patient={client}
                        provider={provider}
                        onUpdateInsuranceClick={onOpenInsuranceModal}
                        onClose={() => {
                          setIsFrozenForWaivedSessionsModalOpen(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </GuidanceCard>
            );
          }
        )
      ) : (
        !wasInNoDataOutage &&
        !wasInOldDataOutage &&
        !isMSCEnabled && (
          <GuidanceCard
            layout={guidanceCardLayout}
            variant={guidanceCardVariant}
          >
            <div>
              {getExplanation(
                client,
                isMSCEnabled,
                undefined,
                matchingProviderFrontEndCarrier?.frontEndCarrier.name
              )}
              {showSecondaryInsuranceLearnMoreLink && (
                <>
                  {' '}
                  <Link
                    href="https://findheadway.zendesk.com/hc/en-us/articles/8637725686036-Seeing-clients-with-secondary-insurance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </Link>
                </>
              )}
              {showExtraInfoForOON && (
                <>
                  {' '}
                  When we’re able to accept their plan, they'll be able to
                  access their account.
                  <p />
                  <p>
                    You are welcome to see them via private pay on Headway.{' '}
                    <a href={SELF_PAY_LINK} target="_blank" rel="noreferrer">
                      Learn more about private pay here
                    </a>
                    .
                  </p>
                  <p>
                    If their insurance information changes, please have them
                    reach out to our team via{' '}
                    <a href={CONTACT_FORM_LINK} target="_blank">
                      our contact form
                    </a>
                    .
                  </p>
                </>
              )}
            </div>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              {showUpdateInsuranceLinkForFreezeStatus && (
                <Button
                  variant="link"
                  size="large"
                  onPress={onOpenInsuranceModal}
                >
                  Update insurance
                </Button>
              )}
              {insuranceStatus ===
                PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK && (
                <Link href={virtualNetworkHelpCenterLink} target="_blank">
                  Learn more
                </Link>
              )}
            </div>
          </GuidanceCard>
        )
      )}
    </>
  );
};
