import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';

export const CONTROLLED_SUBSTANCE_DISPLAY_NAMES: {
  [key in ControlledSubstance]: string;
} = {
  [ControlledSubstance.METHYLPHENIDATE_IR_AKA_RITALIN_IR]:
    'Methylphenidate IR (aka Ritalin IR)',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_CONCERTA]:
    'Methylphenidate ER (aka Concerta)',
  [ControlledSubstance.METHYLPHENIDATE_LA_AKA_RITALIN_LA]:
    'Methylphenidate LA (aka Ritalin LA)',
  [ControlledSubstance.METHYLPHENIDATE_SR_AKA_RITALIN_SR]:
    'Methylphenidate SR (aka RItalin SR)',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_METADATE_ER]:
    'Methylphenidate ER (aka Metadate ER)',
  [ControlledSubstance.METHYLPHENIDATE_CD_AKA_METADATE_CD]:
    'Methylphenidate CD (aka Metadate CD)',
  [ControlledSubstance.METHYLPHENIDATE_IR_AKA_METHYLIN_IR]:
    'Methylphenidate IR (aka Methylin IR) ',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_METHYLIN_ER]:
    'Methylphenidate ER (aka Methylin ER)',
  [ControlledSubstance.METHYLPHENIDATE_ER_BIPHASIC_AKA_ADHANSIA_XR]:
    'Methylphenidate ER Biphasic (aka Adhansia XR)',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_APTENSIO_XR]:
    'Methylphenidate ER (aka Aptensio XR)',
  [ControlledSubstance.METHYLPHENIDATE_ER_ODT_AKA_COTEMPLA_XR_ODT]:
    'Methylphenidate ER- ODT (aka Cotempla XR-ODT)',
  [ControlledSubstance.METHYLPHENIDATE_ER_DR_AKA_JORNAY_PM]:
    'Methylphenidate ER, DR (aka Jornay PM)',
  [ControlledSubstance.METHYLPHENIDATE_ER_CHEWABLE_AKA_QUILLICHEW_ER]:
    'Methylphenidate ER chewable (aka QuilliChew ER)',
  [ControlledSubstance.METHYLPHENIDATE_ER_ORAL_SUSPENSION_AKA_QUILLIVANT_XR]:
    'Methylphenidate ER oral suspension (aka Quillivant XR)',
  [ControlledSubstance.METHYLPHENIDATE_CR_AKA_RELEXXII]:
    'Methylphenidate CR (aka Relexxii)',
  [ControlledSubstance.DEXMETHYLPHENIDATE_IR_AKA_FOCALIN_IR]:
    'Dexmethylphenidate IR (aka Focalin IR)',
  [ControlledSubstance.DEXMETHYLPHENIDATE_ER_AKA_FOCALIN_XR]:
    'Dexmethylphenidate ER (aka Focalin XR)',
  [ControlledSubstance.METHYLPHENIDATE_PATCH_AKA_DAYTRANA]:
    'Methylphenidate patch (aka Daytrana)',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_IR_AKA_ADDERALL_IR]:
    'Amphetamine-Dextroamphetamine IR (aka Adderall IR)',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_ADDERALL_XR]:
    'Amphetamine-Dextroamphetamine ER (aka Adderall XR)',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_MYDAYIS]:
    'Amphetamine-Dextroamphetamine ER (aka Mydayis)',
  [ControlledSubstance.LISDEXAMFETAMINE_AKA_VYVANSE]:
    'Lisdexamfetamine (aka Vyvanse)',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_DEXEDRINE]:
    'Dextroamphetamine IR (aka Dexedrine)',
  [ControlledSubstance.DEXTROAMPHETAMINE_ER_AKA_DEXEDRINE_ER]:
    'Dextroamphetamine ER (aka Dexedrine ER)',
  [ControlledSubstance.DEXTROAMPHETAMINE_SR_AKA_SPANSULE]:
    'Dextroamphetamine SR (aka Spansule)',
  [ControlledSubstance.DEXTROAMPHETAMINE_PATCH_AKA_XELSTRYM]:
    'Dextroamphetamine Patch (aka Xelstrym)',
  [ControlledSubstance.AMPHETAMINE_ER_ODT_AKA_ADZENYS_XR_ODT]:
    'Amphetamine ER-ODT (aka Adzenys XR- ODT)',
  [ControlledSubstance.AMPHETAMINE_ER_AKA_DYANAVEL_XR]:
    'Amphetamine ER (aka Dyanavel XR)',
  [ControlledSubstance.AMPHETAMINE_ODT_AKA_EVEKEO_ODT]:
    'Amphetamine ODT (aka Evekeo ODT)',
  [ControlledSubstance.AMPHETAMINE_IR_AKA_EVEKEO]:
    'Amphetamine IR (aka Evekeo)',
  [ControlledSubstance.AMPHETAMINE_ER_AKA_ADZENYS_XR]:
    'Amphetamine ER (aka Adzenys XR)',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_ZENZEDI]:
    'Dextroamphetamine IR (aka Zenzedi)',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_SOLUTION_AKA_PROCENTRA]:
    'Dextroamphetamine IR solution (aka ProCentra)',
  [ControlledSubstance.ESKETAMINE_AKA_SPRAVATO]: 'Esketamine (aka Spravato)',
  [ControlledSubstance.SERDEXMETHYLPHENIDATE_DEXMETHYLPHENIDATE_AKA_AZSTARYS]:
    'Serdexmethylphenidate-Dexmethylphenidate (aka Azstarys)',
  [ControlledSubstance.MODAFINIL_AKA_PROVIGIL]: 'Modafinil (aka Provigil)',
  [ControlledSubstance.ARMODAFINIL_AKA_NUVIGIL]: 'Armodafinil (aka Nuvigil)',
  [ControlledSubstance.DIAZEPAM_AKA_VALIUM]: 'Diazepam (aka Valium)',
  [ControlledSubstance.ALPRAZOLAM_AKA_XANAX]: 'Alprazolam (aka Xanax)',
  [ControlledSubstance.LORAZEPAM_AKA_ATIVAN]: 'Lorazepam (aka Ativan)',
  [ControlledSubstance.CHLORDIAZEPOXIDE_AKA_LIBRIUM]:
    'Chlordiazepoxide (aka Librium)',
  [ControlledSubstance.CLONAZEPAM_AKA_KLONOPIN]: 'Clonazepam (aka Klonopin)',
  [ControlledSubstance.TRIAZOLAM_AKA_HALCION]: 'Triazolam (aka Halcion)',
  [ControlledSubstance.TEMAZEPAM_AKA_RESTORIL]: 'Temazepam (aka Restoril)',
  [ControlledSubstance.OXAZEPAM_AKA_SERAX]: 'Oxazepam (aka Serax)',
  [ControlledSubstance.ESTAZOLAM_AKA_PROSOM]: 'Estazolam (aka ProSom)',
  [ControlledSubstance.PREGABALIN_AKA_LYRICA]: 'Pregabalin (aka Lyrica) ',
  [ControlledSubstance.GABAPENTIN_AKA_NEURONTIN]: 'Gabapentin (aka Neurontin)',
  [ControlledSubstance.ZOLPIDEM_AKA_AMBIEN]: 'Zolpidem (aka Ambien)',
  [ControlledSubstance.ZOLPIDEM_CR_AKA_AMBIEN_CR]:
    'Zolpidem CR (aka Ambien CR)',
  [ControlledSubstance.ZOLPIDEM_SL_AKA_INTERMEZZO]:
    'Zolpidem SL (aka Intermezzo)',
  [ControlledSubstance.ZOLPIDEM_SL_AKA_EDLUAR]: 'Zolpidem SL (aka Edluar)',
  [ControlledSubstance.ZALEPLON_AKA_SONATA]: 'Zaleplon (aka Sonata)',
  [ControlledSubstance.SUVOREXANT_AKA_BELSOMRA]: 'Suvorexant (aka Belsomra)',
  [ControlledSubstance.ESZOPICLONE_AKA_LUNESTA]: 'Eszopiclone (aka Lunesta)',
  [ControlledSubstance.ZOPICLONE_AKA_IMOVANE]: 'Zopiclone (aka Imovane)',
  [ControlledSubstance.BUPRENORPHINE_NALOXONE_SL_AKA_SUBOXONE]:
    'Buprenorphine-naloxone SL (aka Suboxone)',
  [ControlledSubstance.BUPRENORPHINE_SL_AKA_SUBUTEX]:
    'Buprenorphine SL (aka Subutex)',
  [ControlledSubstance.OTHER]: 'Other (please specify below)',
};

export const COMMONLY_PRESCRIBED: ControlledSubstance[] = [
  ControlledSubstance.METHYLPHENIDATE_IR_AKA_RITALIN_IR,
  ControlledSubstance.METHYLPHENIDATE_LA_AKA_RITALIN_LA,
  ControlledSubstance.METHYLPHENIDATE_ER_AKA_CONCERTA,
  ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_IR_AKA_ADDERALL_IR,
  ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_ADDERALL_XR,
  ControlledSubstance.LISDEXAMFETAMINE_AKA_VYVANSE,
  ControlledSubstance.DIAZEPAM_AKA_VALIUM,
  ControlledSubstance.ALPRAZOLAM_AKA_XANAX,
  ControlledSubstance.LORAZEPAM_AKA_ATIVAN,
  ControlledSubstance.CLONAZEPAM_AKA_KLONOPIN,
  ControlledSubstance.ZOLPIDEM_AKA_AMBIEN,
];

/**
 * Note that commonly prescribed substances are not included in the lists below, even if they
 * belong in that category.
 */

export const STIMULANTS: ControlledSubstance[] = [
  ControlledSubstance.METHYLPHENIDATE_SR_AKA_RITALIN_SR,
  ControlledSubstance.METHYLPHENIDATE_ER_AKA_METADATE_ER,
  ControlledSubstance.METHYLPHENIDATE_CD_AKA_METADATE_CD,
  ControlledSubstance.METHYLPHENIDATE_IR_AKA_METHYLIN_IR,
  ControlledSubstance.METHYLPHENIDATE_ER_AKA_METHYLIN_ER,
  ControlledSubstance.METHYLPHENIDATE_ER_BIPHASIC_AKA_ADHANSIA_XR,
  ControlledSubstance.METHYLPHENIDATE_ER_AKA_APTENSIO_XR,
  ControlledSubstance.METHYLPHENIDATE_ER_ODT_AKA_COTEMPLA_XR_ODT,
  ControlledSubstance.METHYLPHENIDATE_ER_DR_AKA_JORNAY_PM,
  ControlledSubstance.METHYLPHENIDATE_ER_CHEWABLE_AKA_QUILLICHEW_ER,
  ControlledSubstance.METHYLPHENIDATE_ER_ORAL_SUSPENSION_AKA_QUILLIVANT_XR,
  ControlledSubstance.METHYLPHENIDATE_CR_AKA_RELEXXII,
  ControlledSubstance.DEXMETHYLPHENIDATE_IR_AKA_FOCALIN_IR,
  ControlledSubstance.DEXMETHYLPHENIDATE_ER_AKA_FOCALIN_XR,
  ControlledSubstance.METHYLPHENIDATE_PATCH_AKA_DAYTRANA,
  ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_MYDAYIS,
  ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_DEXEDRINE,
  ControlledSubstance.DEXTROAMPHETAMINE_ER_AKA_DEXEDRINE_ER,
  ControlledSubstance.DEXTROAMPHETAMINE_SR_AKA_SPANSULE,
  ControlledSubstance.DEXTROAMPHETAMINE_PATCH_AKA_XELSTRYM,
  ControlledSubstance.AMPHETAMINE_ER_ODT_AKA_ADZENYS_XR_ODT,
  ControlledSubstance.AMPHETAMINE_ER_AKA_DYANAVEL_XR,
  ControlledSubstance.AMPHETAMINE_ODT_AKA_EVEKEO_ODT,
  ControlledSubstance.AMPHETAMINE_IR_AKA_EVEKEO,
  ControlledSubstance.AMPHETAMINE_ER_AKA_ADZENYS_XR,
  ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_ZENZEDI,
  ControlledSubstance.DEXTROAMPHETAMINE_IR_SOLUTION_AKA_PROCENTRA,
  ControlledSubstance.ESKETAMINE_AKA_SPRAVATO,
  ControlledSubstance.SERDEXMETHYLPHENIDATE_DEXMETHYLPHENIDATE_AKA_AZSTARYS,
  ControlledSubstance.MODAFINIL_AKA_PROVIGIL,
  ControlledSubstance.ARMODAFINIL_AKA_NUVIGIL,
];

export const BENZODIAZEPINES: ControlledSubstance[] = [
  ControlledSubstance.CHLORDIAZEPOXIDE_AKA_LIBRIUM,
  ControlledSubstance.TRIAZOLAM_AKA_HALCION,
  ControlledSubstance.TEMAZEPAM_AKA_RESTORIL,
  ControlledSubstance.OXAZEPAM_AKA_SERAX,
  ControlledSubstance.ESTAZOLAM_AKA_PROSOM,
];

export const NON_BENZODIAZEPINES: ControlledSubstance[] = [
  ControlledSubstance.PREGABALIN_AKA_LYRICA,
  ControlledSubstance.GABAPENTIN_AKA_NEURONTIN,
];

export const NON_BENZODIAZEPINE_HYPNOTICS: ControlledSubstance[] = [
  ControlledSubstance.ZOLPIDEM_CR_AKA_AMBIEN_CR,
  ControlledSubstance.ZOLPIDEM_SL_AKA_INTERMEZZO,
  ControlledSubstance.ZOLPIDEM_SL_AKA_EDLUAR,
  ControlledSubstance.ZALEPLON_AKA_SONATA,
  ControlledSubstance.SUVOREXANT_AKA_BELSOMRA,
  ControlledSubstance.ESZOPICLONE_AKA_LUNESTA,
  ControlledSubstance.ZOPICLONE_AKA_IMOVANE,
];

export const BUPRENORPHINE_DERIVATIVES: ControlledSubstance[] = [
  ControlledSubstance.BUPRENORPHINE_NALOXONE_SL_AKA_SUBOXONE,
  ControlledSubstance.BUPRENORPHINE_SL_AKA_SUBUTEX,
];

export const OTHER: ControlledSubstance[] = [ControlledSubstance.OTHER];

export const MAX_DOSAGE: {
  [key in Exclude<ControlledSubstance, ControlledSubstance.OTHER>]: string;
} = {
  [ControlledSubstance.METHYLPHENIDATE_IR_AKA_RITALIN_IR]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_CONCERTA]: '72mg',
  [ControlledSubstance.METHYLPHENIDATE_LA_AKA_RITALIN_LA]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_SR_AKA_RITALIN_SR]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_METADATE_ER]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_CD_AKA_METADATE_CD]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_IR_AKA_METHYLIN_IR]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_METHYLIN_ER]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_BIPHASIC_AKA_ADHANSIA_XR]: '100mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_AKA_APTENSIO_XR]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_ODT_AKA_COTEMPLA_XR_ODT]: '51.8mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_DR_AKA_JORNAY_PM]: '100mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_CHEWABLE_AKA_QUILLICHEW_ER]: '60mg',
  [ControlledSubstance.METHYLPHENIDATE_ER_ORAL_SUSPENSION_AKA_QUILLIVANT_XR]:
    '60mg',
  [ControlledSubstance.METHYLPHENIDATE_CR_AKA_RELEXXII]: '72mg',
  [ControlledSubstance.DEXMETHYLPHENIDATE_IR_AKA_FOCALIN_IR]: '40mg',
  [ControlledSubstance.DEXMETHYLPHENIDATE_ER_AKA_FOCALIN_XR]: '40mg',
  [ControlledSubstance.METHYLPHENIDATE_PATCH_AKA_DAYTRANA]: '30mg',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_IR_AKA_ADDERALL_IR]:
    '60mg',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_ADDERALL_XR]:
    '60mg',
  [ControlledSubstance.AMPHETAMINE_DEXTROAMPHETAMINE_ER_AKA_MYDAYIS]: '50mg',
  [ControlledSubstance.LISDEXAMFETAMINE_AKA_VYVANSE]: '70mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_DEXEDRINE]: '60mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_ER_AKA_DEXEDRINE_ER]: '60mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_SR_AKA_SPANSULE]: '60mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_PATCH_AKA_XELSTRYM]: '18mg',
  [ControlledSubstance.AMPHETAMINE_ER_ODT_AKA_ADZENYS_XR_ODT]: '18.8mg',
  [ControlledSubstance.AMPHETAMINE_ER_AKA_DYANAVEL_XR]: '20mg',
  [ControlledSubstance.AMPHETAMINE_ODT_AKA_EVEKEO_ODT]: '60mg',
  [ControlledSubstance.AMPHETAMINE_IR_AKA_EVEKEO]: '60mg',
  [ControlledSubstance.AMPHETAMINE_ER_AKA_ADZENYS_XR]: '18.8mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_AKA_ZENZEDI]: '40mg',
  [ControlledSubstance.DEXTROAMPHETAMINE_IR_SOLUTION_AKA_PROCENTRA]: '40mg',
  [ControlledSubstance.ESKETAMINE_AKA_SPRAVATO]: '84mg',
  [ControlledSubstance.SERDEXMETHYLPHENIDATE_DEXMETHYLPHENIDATE_AKA_AZSTARYS]:
    '52.3mg/10.4mg',
  [ControlledSubstance.MODAFINIL_AKA_PROVIGIL]: '400mg',
  [ControlledSubstance.ARMODAFINIL_AKA_NUVIGIL]: '250mg',
  [ControlledSubstance.DIAZEPAM_AKA_VALIUM]: '40mg',
  [ControlledSubstance.ALPRAZOLAM_AKA_XANAX]: '8mg',
  [ControlledSubstance.LORAZEPAM_AKA_ATIVAN]: '10mg',
  [ControlledSubstance.CHLORDIAZEPOXIDE_AKA_LIBRIUM]: '100mg',
  [ControlledSubstance.CLONAZEPAM_AKA_KLONOPIN]: '4mg',
  [ControlledSubstance.TRIAZOLAM_AKA_HALCION]: '0.5mg',
  [ControlledSubstance.TEMAZEPAM_AKA_RESTORIL]: '30mg',
  [ControlledSubstance.OXAZEPAM_AKA_SERAX]: '120mg',
  [ControlledSubstance.ESTAZOLAM_AKA_PROSOM]: '2mg',
  [ControlledSubstance.PREGABALIN_AKA_LYRICA]: '600mg',
  [ControlledSubstance.GABAPENTIN_AKA_NEURONTIN]: '3600mg',
  [ControlledSubstance.ZOLPIDEM_AKA_AMBIEN]: '10mg',
  [ControlledSubstance.ZOLPIDEM_CR_AKA_AMBIEN_CR]: '12.5mg',
  [ControlledSubstance.ZOLPIDEM_SL_AKA_INTERMEZZO]: '10mg',
  [ControlledSubstance.ZOLPIDEM_SL_AKA_EDLUAR]: '10mg',
  [ControlledSubstance.ZALEPLON_AKA_SONATA]: '20mg',
  [ControlledSubstance.SUVOREXANT_AKA_BELSOMRA]: '20mg',
  [ControlledSubstance.ESZOPICLONE_AKA_LUNESTA]: '3mg',
  [ControlledSubstance.ZOPICLONE_AKA_IMOVANE]: '7.5mg',
  [ControlledSubstance.BUPRENORPHINE_NALOXONE_SL_AKA_SUBOXONE]: '24mg',
  [ControlledSubstance.BUPRENORPHINE_SL_AKA_SUBUTEX]: '24mg',
};
