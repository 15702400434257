import { Send } from '@mui/icons-material';
import clsx from 'clsx';
import { first } from 'lodash';
import React from 'react';

import type { MessageRead } from '@headway/api/models/MessageRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Avatar } from '@headway/helix/Avatar';
import { IconButton } from '@headway/helix/IconButton';
import { IconCaretLeft } from '@headway/helix/icons/CaretLeft';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { BadgeDot } from './BadgeDot';

interface RecipientBubbleProps {
  message: MessageRead;
  sender: ProviderRead | UserRead;
  group?: string;
}

export function RecipientBubble({
  message,
  sender,
  group,
}: RecipientBubbleProps) {
  return (
    <div
      css={{
        '& + .hw-bubble-recipient .hlx-avatar': {
          visibility: 'hidden',
        },

        [`&[data-group="${group}"] + .hw-bubble-recipient[data-group="${group}"]`]:
          {
            '.hw-msg-bubble': {
              borderBottomLeftRadius: 8,
            },
            '.hw-msg-timestamp': {
              display: 'none',
            },
          },
      }}
      className="hw-bubble-recipient flex items-end gap-2 self-start"
      data-group={group}
    >
      <NameAvatar
        name={sender.displayFirstName + ' ' + sender.displayLastName}
      />
      <div className="space-y-1">
        <div
          className="hw-msg-bubble text-wrap hlx-typography-body bg-system-lightGray text-system-textBlack tablet:max-w-[40ch] min-w-0 max-w-[30ch] break-words rounded-[8px] rounded-bl-none px-3 py-2 [overflow-wrap:anywhere]"
          dangerouslySetInnerHTML={{
            __html: sanitize(message.content),
          }}
        />

        <div className="hw-msg-timestamp text-system-gray hlx-typography-subbody">
          {timestampFormatter.format(new Date(message.createdOn!))}
        </div>
      </div>
    </div>
  );
}

interface SenderBubbleProps {
  message: MessageRead;
  group?: string;
}
export function SenderBubble({ message, group }: SenderBubbleProps) {
  return (
    <div
      data-group={group}
      css={{
        [`&[data-group="${group}"] + .hw-bubble-sender[data-group="${group}"]`]:
          {
            '.hw-msg-bubble': {
              borderBottomRightRadius: 8,
            },
            '.hw-msg-timestamp': {
              display: 'none',
            },
          },
      }}
      className="hw-bubble-sender flex flex-col items-end space-y-1 self-end"
    >
      <div
        className="hw-msg-bubble hlx-typography-body bg-system-textBlack text-system-white tablet:max-w-[40ch] text-wrap min-w-0 max-w-[30ch] break-words rounded-[8px] rounded-br-none px-3 py-2 [overflow-wrap:anywhere]"
        dangerouslySetInnerHTML={{
          __html: sanitize(message.content),
        }}
      />

      <div className="hw-msg-timestamp text-system-gray hlx-typography-subbody">
        {timestampFormatter.format(new Date(message.createdOn!))}
      </div>
    </div>
  );
}

export function isSameSender(a: MessageRead, b: MessageRead) {
  const aSender = a.toProvider ? a.providerId : a.userId;
  const bSender = b.toProvider ? b.providerId : b.userId;

  return aSender === bSender;
}

export function getMessageGroup(message: MessageRead) {
  return timestampFormatter.format(new Date(message.createdOn!));
}

const timestampFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

function stringToHslColor(
  hueInput: string,
  saturation: number,
  lightness: number,
  alpha: number = 1
): string {
  let hash = 0;

  for (let i = 0; i < hueInput.length; i++) {
    hash = Math.abs(Math.sin(hueInput.charCodeAt(i) + hash) * 360);
  }

  return `hsla(${hash},${saturation}%,${lightness}%,${alpha})`;
}

interface NameAvatarProps {
  name: string;
}

function NameAvatar({ name }: NameAvatarProps) {
  const [first = '', last = ''] = name.split(' ');
  return (
    <Avatar
      backgroundColor={stringToHslColor(name, 70, 96)}
      label={name}
      size="small"
    >
      {first[0]}
      {last[0]}
    </Avatar>
  );
}

interface InlineContentProps {
  children: React.ReactNode;
}
export function InlineContent(props: InlineContentProps) {
  return (
    <div className="hlx-typography-body text-system-gray mx-auto flex items-center gap-1 px-4 py-3 text-center">
      {props.children}
    </div>
  );
}

interface MessageThreadListItemProps {
  messages: MessageRead[];
  name: string;
  onSelectRecipient: () => void;
  isSelected: boolean;
  isUnread: boolean;
}

export function MessageThreadRecipientItem({
  messages,
  name,
  onSelectRecipient,
  isSelected,
  isUnread,
}: MessageThreadListItemProps) {
  const latestMessage = first(messages);

  return (
    <button
      className={clsx(
        'text-system-textBlack grid w-full grid-cols-[auto_1fr_auto] items-center gap-4 border-none p-4 text-left',
        isSelected
          ? 'tablet:bg-system-lightGray bg-transparent'
          : 'tablet:hover:bg-system-backgroundGray'
      )}
      onClick={() => {
        onSelectRecipient();
      }}
    >
      <NameAvatar name={name} />

      <div className="flex flex-col items-start">
        <div className="hlx-typography-list-heading">{name}</div>
        {/* {latestMessage && (
          <div
            className={clsx(
              'hlx-typography-subbody grid w-full grid-cols-[1fr_auto_auto] items-center gap-2',
              {
                'font-bold': isUnread,
              }
            )}
          >
            <span
              className="line-clamp-1"
              dangerouslySetInnerHTML={{
                __html: sanitize(latestMessage?.content),
              }}
            />
            <span>•</span>
            <span>
              {new Date(latestMessage.createdOn!).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
              })}
            </span>
          </div>
        )} */}
      </div>
      {isUnread && <BadgeDot hidden={false} variant="positive" />}
    </button>
  );
}

interface MessageThreadProps {
  children: React.ReactNode;
}
export function MessageThread(props: MessageThreadProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          el.scrollTop = el.scrollHeight;
        }
      });
    });

    observer.observe(el, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={ref}
      className="flex h-full max-h-full w-full flex-col-reverse justify-start gap-1 overflow-auto overscroll-contain px-4"
    >
      {props.children}
    </div>
  );
}
interface MessageThreadHeaderProps {
  recipientName: string;
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  onBack: () => void;
}
export function MessageThreadHeader(props: MessageThreadHeaderProps) {
  return (
    <div className="border-system-borderGray bg-system-white flex items-center gap-2 border-0 border-b border-solid p-4">
      <div className="tablet:hidden block">
        <IconButton
          variant="transparent"
          onPress={() => {
            props.onBack();
          }}
        >
          <IconCaretLeft size="1em" />
        </IconButton>
      </div>
      <NameAvatar name={props.recipientName} />
      <div className="flex flex-col gap-1">
        <span>
          <div className="hlx-typography-section-heading text-system-textBlack">
            {props.heading}
          </div>
        </span>

        <div className="tablet:block hlx-typography-subbody text-system-gray hidden">
          {props.subheading}
        </div>
      </div>
    </div>
  );
}

interface ComposeMessageTextareaProps {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  disabled: boolean;
  submittable: boolean;
  placeholder?: string;
}

export function ComposeMessageTextarea({
  value,
  onChange,
  onFocus,
  disabled,
  submittable,
  placeholder,
}: ComposeMessageTextareaProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el) {
      const control = el.querySelector('textarea')!;
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      control.style.height = '0px';
      const scrollHeight = control.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a el will product an incorrect value.
      control.style.height = scrollHeight + 'px';
    }
  }, [value]);

  return (
    <div
      ref={ref}
      className="relative mx-4 my-3"
      css={{
        display: 'grid',
        gridTemplateRows: 'auto',

        '& button[type="submit"]': {
          position: 'absolute',
          right: 0,
          bottom: '0',
        },
        '& .hlx-text-area-root .hlx-text-area-control': {
          gridRow: '1',
          paddingRight: 48,
          minHeight: '36px',
          [theme.__futureMedia.above('tablet')]: {
            minHeight: 'unset',
            maxHeight: '20lh',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
        },
        '& .sizer': {
          content: `attr(data-value) ' '`,
          whiteSpace: 'pre-wrap',
          visibility: 'hidden',
        },
      }}
    >
      <TextArea
        name="message"
        aria-label="Message your provider"
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
      />
      <span data-value={value} aria-hidden className="sizer" />
      <IconButton
        aria-label="Send message"
        variant="transparent"
        type="submit"
        size="large"
        disabled={!submittable}
      >
        <Send />
      </IconButton>
    </div>
  );
}
