import { ProviderAppointmentStatusNotes } from '@headway/api/models/ProviderAppointmentStatusNotes';

// gemini.link(mamba/app/shared/models/provider_appointment.py)
export const PROVIDER_CANCELLATION_STATUS_NOTES = [
  ProviderAppointmentStatusNotes.PROVIDER_CANCELLATION,
  ProviderAppointmentStatusNotes.NOT_AWARE,
  ProviderAppointmentStatusNotes.NOT_AVAILABLE,
  ProviderAppointmentStatusNotes.RESCHEDULING,
  ProviderAppointmentStatusNotes.CLIENT_NOT_AVAILABLE,
  ProviderAppointmentStatusNotes.SEEING_OUTSIDE_HEADWAY,
  ProviderAppointmentStatusNotes.STOPPING_CARE,
  ProviderAppointmentStatusNotes.NOT_RIGHT_FIT,
  ProviderAppointmentStatusNotes.NOT_TAKING_NEW_CLIENTS,
  ProviderAppointmentStatusNotes.OTHER,
];
// gemini.endlink
