import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useProviderPatient } from '~/legacy/hooks';
import { getUsePatientAddressesQueryKey } from '~/legacy/hooks/usePatientAddresses';
import { AddressModalV2 } from '~/legacy/views/Patients/AddressModalV2';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { useMediaQuery } from '@headway/helix/utils';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';

import { isPast } from '../../events/util/events';
import { AppointmentAttestationFormV2 } from './AppointmentAttestationFormV2';
import { AppointmentConfirmationFooter } from './AppointmentConfirmationFooter';
import { AppointmentHasNotHappenedYetCard } from './AppointmentHasNotHappenedYetCard';
import AutoSaveListener from './AutoSaveListener';
import { ComplianceGuideModal } from './ComplianceGuideModal';
import { DiscardUnsavedChangesModal } from './DiscardUnsavedChangesModal';
import {
  AppointmentAttachmentFormV2,
  AppointmentAttachmentFormV2Values,
} from './Forms/AppointmentAttachmentFormV2';
import {
  AddendumsFormV2,
  AddendumsFormV2Values,
} from './Forms/ProgressNotes/AddendumsFormV2';
import { ProgressNotesHeaderV2 } from './Forms/ProgressNotes/components/ProgressNotesHeaderV2';
import {
  ProgressNotesFormV2,
  ProgressNotesFormV2Values,
} from './Forms/ProgressNotes/ProgressNotesFormV2';
import {
  SessionDetailsFormV2,
  SessionDetailsFormV2Values,
} from './Forms/SessionDetails/SessionDetailsFormV2';
import { SessionDetailsHeader } from './Forms/SessionDetails/SessionDetailsHeader';
import { useInitialValues } from './hooks/useInitialValues';
import { useValidationSchema } from './hooks/useValidationSchema';
import { ProgressNotesComplianceInfo } from './ProgressNotesComplianceInfo';
import { ProgressNoteSignedText } from './ProgressNoteSignedText';
import { ProgressNoteState } from './stores/ProgressNotesContext';
import { ProgressNoteContext } from './stores/ProgressNotesContextV2';
import { SubmitAddendumWithoutChangesModal } from './SubmitAddendumWithoutChangesModal';
import { TreatmentPlanRequirementWarning } from './TreatmentPlanRequirementWarning';

export interface AppointmentConfirmationModalV2Props {
  patient: UserRead;
  provider: ProviderRead;
  isOpen: boolean;
  onOpenContactFormInsuranceIssues: () => void;
  onClose: () => void;
  onProgressNoteUpdate?: () => void;
}

export interface AppointmentConfirmationModalFormV2Values {
  sessionDetails: SessionDetailsFormV2Values;
  progressNote: ProgressNotesFormV2Values;
  attachments: AppointmentAttachmentFormV2Values;
  addendums: AddendumsFormV2Values;
  attestation: boolean;
}

export const AppointmentConfirmationModalV2: React.FC<
  React.PropsWithChildren<AppointmentConfirmationModalV2Props>
> = ({
  patient,
  provider,
  isOpen,
  onOpenContactFormInsuranceIssues,
  onProgressNoteUpdate,
  onClose,
}) => {
  const [providerClosedComplianceModal, setProviderClosedComplianceModal] =
    useLocalStorage('providerClosedComplianceModal');
  const [openComplianceModal, setOpenComplianceModal] = useState(
    !providerClosedComplianceModal
  );
  const [openDiscardUnsavedChangesModal, setOpenDiscardUnsavedChangesModal] =
    useState(false);
  const [openSubmitWithoutChangesModal, setOpenSubmitWithoutChangesModal] =
    useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const { progressNoteState, event } = useContext(ProgressNoteContext);
  const queryClient = useQueryClient();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: patient.id,
  });

  const isSmallScreen = useMediaQuery('(max-width: 850px)');

  const onAddSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      getUsePatientAddressesQueryKey({ patientId: patient.id })
    );
    queryClient.invalidateQueries(getUseUserQueryKey({ userId: patient.id }));
    queryClient.invalidateQueries(['patient-insurance-status']);
  }, [queryClient, patient.id]);

  // const { buttonsConfig } = useFormConfiguration();
  /*const trackButtonClicked = (
    name:
      | 'Confirm Session Button Clicked'
      | 'Confirm Session Detail Page Exited'
      | 'Modify Session Button Clicked'
      | 'Progress Note Submit Addendum Button Clicked'
      | 'Save and Close Button Clicked'
      | 'Save and Complete Later Button Clicked'
      | 'Sign Note Button Clicked'
  ) => {
    const properties = {
      providerId: event.providerAppointment!.providerId!,
      patientUserId: event.patientUserId!,
      providerAppointmentId: event.providerAppointment!.id,
      prefillSelected: !!metadataInfo?.noteJsonPrefilledFrom,
      progressNoteId: progressNote?.id,
      progressNoteRadio: progressNoteType,
      selectedTemplate: (progressNote?.noteJson as NoteJson)?.templateInfo
        ?.name,
    };

    if (name === 'Confirm Session Button Clicked') {
      const fullProperties: ConfirmSessionButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
        selectedTemplate:
          progressNoteType === ProgressNoteType.TEMPLATE
            ? (progressNote?.noteJson as NoteJson)?.templateInfo?.name
            : undefined,
        telehealthAttestation:
          !!event.providerAppointment?.telehealthAttestation,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else if (name === 'Sign Note Button Clicked') {
      const fullProperties: SignNoteButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else {
      trackEvent({
        name,
        properties,
      });
    }
  };*/

  const { initialValues, isInitialized } = useInitialValues();
  const validationSchema = useValidationSchema();

  const methods = useForm<AppointmentConfirmationModalFormV2Values>({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      validationSchema
    ) as Resolver<AppointmentConfirmationModalFormV2Values>,
  });

  const { trigger, reset } = methods;
  const stringifiedValidationSchema = JSON.stringify(validationSchema);
  useEffect(() => {
    if (isInitialized) {
      reset(initialValues);
    }
  }, [isInitialized, reset, initialValues]);
  useEffect(() => {
    if (isInitialized) {
      trigger();
    }
  }, [trigger, stringifiedValidationSchema, isInitialized]);

  const handleClose = useCallback(() => {
    if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT) {
      setOpenDiscardUnsavedChangesModal(true);
    } else {
      //trackButtonClicked('Confirm Session Detail Page Exited');
      onClose();
    }
  }, [onClose, progressNoteState]);

  return (
    <>
      <FormProvider {...methods}>
        <form id="appointmentConfirmationModalForm">
          <Modal
            title={
              event && isPast(event)
                ? 'Confirm session details'
                : 'Session details'
            }
            onDismiss={handleClose}
            isOpen={isOpen}
            variant="fullscreen"
            layout={`${isSmallScreen ? 'contained' : 'full-bleed'}`}
            disableTrapFocus
          >
            <ModalContent css={{ justifyContent: 'center' }}>
              <div
                className={`${
                  isSmallScreen ? 'w-auto' : 'ml-auto mr-auto w-[850px]'
                }`}
              >
                <AutoSaveListener
                  isInitialized={isInitialized}
                  setIsAutoSaving={(value) => setIsAutoSaving(value)}
                />
                <SessionDetailsHeader patient={patient} />
                <ProgressNotesComplianceInfo />
                <SessionDetailsFormV2
                  provider={provider}
                  patient={patient}
                  onOpenContactFormInsuranceIssues={
                    onOpenContactFormInsuranceIssues
                  }
                  onNewLocationClicked={() => {
                    trackEvent({
                      name: 'Provider Add New Address Button Clicked',
                      properties: {
                        providerId: provider.id,
                        providerPatientId: patient.id,
                        providerAppointmentId: event?.providerAppointment?.id,
                      },
                    });
                    setOpenAddressModal(true);
                  }}
                />
                <Divider />
                <ProgressNotesHeaderV2
                //resetAttestation={resetAttestationState}
                />
                <ProgressNotesFormV2
                  patient={patient}
                  providerPatient={providerPatient}
                />
                <AppointmentAttachmentFormV2
                //onProgressNoteUpdate={onProgressNoteUpdate}
                />
                <AddendumsFormV2 />
                <AppointmentHasNotHappenedYetCard event={event} />
                <ProgressNoteSignedText />
                <AppointmentAttestationFormV2 patient={patient} />
                <TreatmentPlanRequirementWarning patient={patient} />
              </div>
              <ModalFooter>
                <AppointmentConfirmationFooter isAutoSaving={isAutoSaving} />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </form>
      </FormProvider>
      <AddressModalV2
        open={openAddressModal}
        patientUser={patient}
        isActiveAddress={false}
        onClose={() => {
          trackEvent({
            name: 'Provider Skip Telehealth Button Clicked',
            properties: {
              providerId: provider.id,
              providerPatientId: patient.id,
              providerAppointmentId: event?.providerAppointment?.id,
            },
          });
          setOpenAddressModal(false);
        }}
        onSuccess={() => {
          trackEvent({
            name: 'Provider Save and Verify Telehealth Button Clicked',
            properties: {
              providerId: provider.id,
              providerPatientId: patient.id,
              providerAppointmentId: event?.providerAppointment?.id,
            },
          });
          onAddSuccess();
          setOpenAddressModal(false);
        }}
      />
      <ComplianceGuideModal
        open={openComplianceModal}
        onClose={() => setOpenComplianceModal(false)}
        provider={provider}
        setProviderClosedComplianceModal={setProviderClosedComplianceModal}
      />
      <SubmitAddendumWithoutChangesModal
        open={openSubmitWithoutChangesModal}
        closeOnCancel={() => setOpenSubmitWithoutChangesModal(false)}
        closeOnSubmit={onClose}
        clientId={patient.id}
      />
      <DiscardUnsavedChangesModal
        open={openDiscardUnsavedChangesModal}
        closeOnCancel={() => setOpenDiscardUnsavedChangesModal(false)}
        closeOnSubmit={onClose}
      />
      {/*isTreatmentPlanRequired && (
        <ConfirmSessionOnDraftModal
          open={isConfirmSessionOnDraftModalOpened}
          onClose={() => setIsConfirmSessionOnDraftModalOpened(false)}
          onEdit={() => {
            updateProgressNoteState(ProgressNoteState.EDITING);
            setIsConfirmSessionOnDraftModalOpened(false);
          }}
        />
      )*/}
    </>
  );
};
