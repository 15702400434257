import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';
import { ProviderAvatar } from '~/legacy/components/Header/ProviderAvatar';
import {
  useGroupPracticeProviders,
  useGroupPracticeProvidersCache,
} from '~/legacy/hooks/useGroupPracticeProviders';
import { PanelLayout } from '~/legacy/layouts/PanelLayout';
import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
} from '~/legacy/mutations/utils';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListRow } from '@headway/helix/List';
import { SearchField } from '@headway/helix/SearchField';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import useFuzzy from '@headway/ui/form/useFuzzy';

interface ManageTeamContentProps {
  providers: ProviderRead[];
  onToggleArchived: (provider: ProviderRead) => void;
  /** If onProviderSelect is passed, it will be called in lieu of a navigation link. */
  onProviderSelect?: (provider: ProviderRead) => void;
  /** If true, includes a page title. */
  includeTitle?: boolean;
}

export const ManageTeam = () => {
  const { providers, toggleArchivedMutation } =
    useManageTeamDataFromReactQuery();

  return (
    <PanelLayout>
      <div className="pb-10 pt-5">
        <ManageTeamContent
          providers={providers || []}
          onToggleArchived={(provider) =>
            toggleArchivedMutation.mutate({ provider })
          }
          includeTitle
        />
      </div>
    </PanelLayout>
  );
};

export const ManageTeamContent = ({
  providers,
  onToggleArchived,
  onProviderSelect,
  includeTitle,
}: ManageTeamContentProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { search } = useFuzzy(providers || [], {
    keys: ['displayFirstName', 'displayLastName', 'prenomial', 'email'],
  });
  const filteredProviders =
    searchQuery && providers ? search(searchQuery) : providers;

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        {includeTitle && (
          <h1>
            <ContentText variant="page-title">Manage team</ContentText>
          </h1>
        )}
        <div
          className={clsx('grid flex-grow', {
            'max-w-[340px]': includeTitle,
          })}
        >
          <SearchField
            aria-label="Search for a provider"
            placeholder="Search for a provider..."
            onChange={(value: string) => setSearchQuery(value)}
          />
        </div>
      </div>
      {filteredProviders?.length ? (
        <ul className="mt-5 p-0">
          {filteredProviders.map((provider: ProviderRead) => (
            <ProviderListRow
              key={provider.id}
              provider={provider}
              onProviderSelect={onProviderSelect}
              onToggleArchived={onToggleArchived}
            />
          ))}
        </ul>
      ) : searchQuery ? (
        <div className="mt-10 text-center">
          <ContentText variant="body">
            No providers found. Try adjusting your search.
          </ContentText>
        </div>
      ) : null}
    </>
  );
};

const ProviderListRow = ({
  provider,
  onProviderSelect,
  onToggleArchived,
}: {
  provider: ProviderRead;
} & Pick<ManageTeamContentProps, 'onProviderSelect' | 'onToggleArchived'>) => {
  return (
    <ListRow key={provider.id}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <ProviderAvatar provider={provider} size="medium" />
          <div className="flex-col">
            <div className="flex items-center gap-2">
              {onProviderSelect ? (
                <Button
                  variant="link"
                  onPress={() => onProviderSelect(provider)}
                >
                  {getProviderDisplayFirstAndLastWithPrenomial(provider)}
                </Button>
              ) : (
                <LinkButton
                  variant="link"
                  target="_blank"
                  href={`/home?provider_id=${provider.id}`}
                >
                  {getProviderDisplayFirstAndLastWithPrenomial(provider)}
                </LinkButton>
              )}
              {provider.isArchived && <Badge variant="neutral">Archived</Badge>}
            </div>
            <ContentText variant="body-small">{provider.email}</ContentText>
          </div>
        </div>
        <Button variant="secondary" onPress={() => onToggleArchived(provider)}>
          {provider.isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
    </ListRow>
  );
};

/**
 * Fetches practice providers and provides mutations to toggle provider archived status via react-query.
 */
export const useManageTeamDataFromReactQuery = () => {
  const AuthStore = useAuthStore();
  const { data: providers } = useGroupPracticeProviders(
    {
      groupPracticeId: AuthStore.user?.group_practice?.id,
      query: { is_active: true },
    },
    {
      select: (providers) =>
        sortBy(providers, (provider) =>
          getProviderDisplayFirstAndLast(provider)
        ),
      refetchOnWindowFocus: false,
    }
  );

  const groupPracticeId = AuthStore.user!.group_practice!.id;
  const groupPracticeProvidersCache = useGroupPracticeProvidersCache();
  const toggleArchivedMutation = useMutationWithSideEffects(
    ({ provider }: { provider: ProviderRead }) =>
      GroupPracticeApi.updateProviderIsArchived(
        groupPracticeId,
        provider.id,
        !provider.isArchived
      ),
    {
      sideEffects: new SideEffectsBuilder<
        ProviderRead,
        unknown,
        { provider: ProviderRead }
      >().addOptimisticUpdate(
        groupPracticeProvidersCache,
        () => ({
          groupPracticeId,
          query: { is_active: true },
        }),
        ({ provider }, current) => {
          if (!current) {
            return undefined;
          }
          return current.map((p) =>
            p.id === provider.id
              ? { ...p, isArchived: !provider.isArchived }
              : p
          );
        }
      ),
    }
  );

  return {
    providers,
    toggleArchivedMutation,
  };
};
