import { Info } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconInfo: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={Info}
    {...props}
    defaultVariant="filled"
    variants={['outlined', 'filled']}
    ref={ref}
  />
));

IconInfo.variants = ['outlined', 'filled'];
IconInfo.defaultVariant = 'filled';
