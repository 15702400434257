/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ErrorCode {
    REQUIRED_APPLICATION_DATA_MISSING = '15',
    INPUT_ERRORS = '33',
    AUTHORIZATION_OR_ACCESS_RESTRICTION = '41',
    UNABLE_TO_RESPOND_AT_CURRENT_TIME = '42',
    INVALID_OR_MISSING_PROVIDER_IDENTIFICATION = '43',
    INVALID_OR_MISSING_PROVIDER_IDENTIFICATION_NUMBER = '48',
    PROVIDER_INELIGIBLE_FOR_INQUIRIES = '50',
    PROVIDER_NOT_ON_FILE = '51',
    SERVICE_DATES_NOT_WITHIN_PROVIDER_PLAN_ENROLLMENT = '52',
    INVALID_OR_MISSING_DATES_OF_SERVICE = '57',
    INVALID_OR_MISSING_DOB = '58',
    DATE_OF_BIRTH_FOLLOWS_DATES_OF_SERVICE = '60',
    DATE_OF_SERVICE_NOT_WITHIN_ALLOWABLE_INQUIRY_PERIOD = '62',
    DATE_OF_SERVICE_IN_FUTURE = '63',
    INVALID_OR_MISSING_PATIENT_ID = '64',
    INVALID_OR_MISSING_PATIENT_NAME = '65',
    PATIENT_NOT_FOUND = '67',
    DUPLICATE_PATIENT_ID_NUMBER = '68',
    PATIENT_DOB_DOES_NOT_MATCH_PATIENT_ON_DATABASE = '71',
    MISSING_OR_INVALID_SUBSCRIBER_OR_INSURED_ID = '72',
    INVALID_OR_MISSING_SUBSCRIBER_OR_INSURED_NAME = '73',
    INVALID_OR_MISSING_SUBSCRIBER_OR_INSURED_GENDER_CODE = '74',
    SUBSCRIBER_OR_INSURED_NOT_FOUND = '75',
    DUPLICATE_SUBSCRIBER_OR_INSURED_ID_NUMBER = '76',
    SUBSCRIBER_OR_INSURED_NOT_IN_GROUP_OR_PLAN_IDENTIFIED = '78',
    INVALID_PARTICIPANT_IDENTIFICATION = '79',
    NO_RESPONSE_RECEIVED_TRANSACTION_TERMINATED = '80',
    INVALID_OR_MISSING_PROVIDER_ADDRESS = '97',
    SEGMENT_HAS_DATA_ELEMENT_ERRORS = '999',
    INVALID_OR_MISSING_PROCEDURE_CODE = 'AG',
    PAYER_NAME_OR_IDENTIFIER_MISSING = 'T4'
}
