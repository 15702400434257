import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M313.224 193.717h-97.501l68.909-68.909a6.585 6.585 0 0 0-9.317-9.317L206.406 184.4V86.9c0-3.694-2.891-6.585-6.585-6.585s-6.585 2.891-6.585 6.585v106.657H86.9c-3.534 0-6.585 2.891-6.585 6.585s2.891 6.585 6.585 6.585h97.501l-68.909 68.909a6.585 6.585 0 0 0 9.317 9.317l68.909-68.909v97.501c0 3.534 2.891 6.585 6.585 6.585s6.585-2.891 6.585-6.585V206.888h106.657c3.534 0 6.585-2.891 6.585-6.585s-2.891-6.585-6.585-6.585z" />,
  ],
]);
export const IconGender: Icon = forwardRef((props, ref) => (
  <IconBase
    ref={ref}
    viewBox="67.765 75.294 256 256"
    {...props}
    weights={weights}
  />
));

IconGender.variants = ['outlined'];
IconGender.defaultVariant = 'outlined';
