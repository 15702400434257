import CircularProgress from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@headway/helix/Button';
import { IconCheck } from '@headway/helix/icons/Check';
import { theme } from '@headway/helix/theme';

import { useButtonsConfig } from './hooks/useButtonsConfig';
import {
  AppointmentContext,
  AppointmentState,
} from './stores/AppointmentContext';
import {
  ProgressNoteContext,
  ProgressNotePermissions,
} from './stores/ProgressNotesContext';

interface AutoSaveListenerLoaderProps {
  isAutoSaving?: boolean;
}

const AutoSaveListenerLoader = ({
  isAutoSaving,
}: AutoSaveListenerLoaderProps) => {
  const { notePermissions } = useContext(ProgressNoteContext);
  const { appointmentState } = useContext(AppointmentContext);
  return (
    <div className="mt-4">
      {appointmentState !== AppointmentState.DETAILS_CONFIRMED &&
        notePermissions.includes(ProgressNotePermissions.WRITE) && (
          <div className="flex flex-row">
            {isAutoSaving ? (
              <>
                <CircularProgress
                  css={{
                    color: theme.color.system.gray,
                    marginTop: '1px',
                  }}
                  size={16}
                />
                <p className="ml-2">Saving...</p>
              </>
            ) : (
              <>
                <IconCheck />
                <p className="ml-2">Saved</p>
              </>
            )}
          </div>
        )}
    </div>
  );
};

export interface AppointmentConfirmationFooterProps {
  isAutoSaving?: boolean;
}

export const AppointmentConfirmationFooter = ({
  isAutoSaving,
}: AppointmentConfirmationFooterProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const buttonsConfig = useButtonsConfig();

  return (
    <div className="flex flex-1 items-center justify-between px-4">
      <AutoSaveListenerLoader isAutoSaving={isAutoSaving} />
      <div className="flex gap-4">
        {buttonsConfig.secondaryCTA ? (
          <Button
            variant="secondary"
            disabled={buttonsConfig.secondaryDisabled || isSubmitting}
            data-testid="secondaryModalCta"
          >
            {buttonsConfig.secondaryCTA}
          </Button>
        ) : null}
        {buttonsConfig.primaryCTA ? (
          <Button
            form="appointmentConfirmationModalForm"
            variant="primary"
            disabled={buttonsConfig.primaryDisabled || isSubmitting}
            data-testid="primaryModalCta"
            type="submit"
          >
            {buttonsConfig.primaryCTA}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
