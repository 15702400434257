import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M202.453 98.133c0-25.28-20.587-45.867-45.867-45.867-11.947 0-22.72 4.693-30.933 12.267-8.213-7.467-18.987-12.267-30.933-12.267-25.28 0-45.867 20.587-45.867 45.867 0 11.84 4.693 22.613 12.053 30.72-7.467 8.107-12.053 18.88-12.053 30.72 0 25.28 20.587 45.867 45.867 45.867 11.947 0 22.72-4.693 30.933-12.267 8.213 7.467 18.987 12.267 30.933 12.267 25.28 0 45.867-20.587 45.867-45.867 0-11.84-4.693-22.613-12.053-30.72 7.467-8.107 12.053-18.88 12.053-30.72m-8.64 0c0 9.6-3.733 18.347-9.813 24.96-7.68-5.76-17.067-9.28-27.413-9.28-7.787 0-15.04 2.133-21.44 5.547 3.307-6.4 5.44-13.547 5.44-21.227 0-10.24-3.52-19.52-9.173-27.2a36.7 36.7 0 0 1 25.173-9.92c20.48 0 37.12 16.64 37.12 37.12zM125.76 180.16c-3.947-5.867-6.187-12.907-6.187-20.587s2.347-14.613 6.187-20.587c3.947 5.867 6.187 12.907 6.187 20.587s-2.347 14.613-6.187 20.587m0-102.507c3.947 5.867 6.187 12.907 6.187 20.587s-2.347 14.613-6.187 20.587c-3.947-5.867-6.187-12.907-6.187-20.587s2.347-14.613 6.187-20.587m51.733 51.307c-5.973 4.053-13.12 6.4-20.8 6.4s-14.933-2.347-20.8-6.4c5.973-4.053 13.12-6.4 20.8-6.4s14.933 2.347 20.8 6.4M94.827 61.013c9.707 0 18.56 3.84 25.173 9.92-5.653 7.573-9.173 16.96-9.173 27.2 0 7.68 2.133 14.827 5.44 21.227a45.44 45.44 0 0 0-21.44-5.547c-10.347 0-19.733 3.52-27.413 9.28-5.973-6.613-9.813-15.253-9.813-24.96 0-20.48 16.64-37.12 37.12-37.12zm20.8 67.947c-5.973 4.053-13.12 6.4-20.8 6.4s-14.933-2.347-20.8-6.4c5.973-4.053 13.12-6.4 20.8-6.4s14.933 2.347 20.8 6.4M57.6 159.68c0-9.6 3.733-18.347 9.813-24.96 7.68 5.76 17.067 9.28 27.413 9.28 7.787 0 15.04-2.133 21.44-5.547-3.307 6.4-5.44 13.547-5.44 21.227 0 10.24 3.52 19.52 9.173 27.2-6.613 6.08-15.467 9.92-25.173 9.92-20.48 0-37.12-16.64-37.12-37.12zm98.987 37.12c-9.707 0-18.56-3.84-25.173-9.92 5.653-7.68 9.173-16.96 9.173-27.2 0-7.68-2.133-14.827-5.44-21.227 6.4 3.413 13.653 5.547 21.44 5.547 10.347 0 19.733-3.52 27.413-9.28 5.973 6.613 9.813 15.253 9.813 24.96 0 20.48-16.64 37.12-37.12 37.12z" />,
  ],
]);
export const IconRace: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="49 52 155 155" {...props} weights={weights} />
));

IconRace.variants = ['outlined'];
IconRace.defaultVariant = 'outlined';
