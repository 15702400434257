import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { PayerStatus } from '@headway/shared/types/payerStatus';
import { formatDate } from '@headway/shared/utils/insuranceStatus';
import { PayerTimelineItem } from '@headway/ui/providers/PayerTimelineItem';
import { PayerTimelineItemStatus } from '@headway/ui/providers/PayerTimelineItemStatus';

interface GetCredentialedTimelineItemProps {
  payerStatus: PayerStatus;
  appointmentReadyDate?: Date;
  estimatedAppointmentReadyDate?: Date;
  payerQuestionnaire: PayerQuestionnaire | undefined;
}

export function GetCredentialedTimelineItem({
  payerStatus,
  appointmentReadyDate,
  estimatedAppointmentReadyDate,
  payerQuestionnaire,
}: GetCredentialedTimelineItemProps) {
  const payerQuestionnaireIsIncomplete = !!(
    payerStatus === PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED
  );
  let detail;
  let title = 'Get credentialed';
  let status = payerQuestionnaireIsIncomplete
    ? PayerTimelineItemStatus.CREDENTIALING_PENDING
    : PayerTimelineItemStatus.IN_PROGRESS;

  if (payerStatus === PayerStatus.CREDENTIALING_DELAYED_ACTION_NEEDED) {
    detail = 'ETA delayed';
  } else {
    if (appointmentReadyDate) {
      const formatted = formatDate(appointmentReadyDate);
      if (appointmentReadyDate > new Date()) {
        detail = `Estimated ${formatted}`;
      } else {
        detail = `Completed ${formatted}`;
      }
    } else if (estimatedAppointmentReadyDate) {
      detail = `Estimated ${formatDate(estimatedAppointmentReadyDate)}`;
    } else if (payerQuestionnaireIsIncomplete) {
      detail = '';
    } else {
      detail = 'In progress';
    }

    if (
      payerStatus === PayerStatus.APPOINTMENT_READY ||
      payerStatus === PayerStatus.PAYMENT_READY ||
      payerStatus === PayerStatus.COMPLETE
    ) {
      title = "You've been credentialed";
      status = PayerTimelineItemStatus.COMPLETE;
    }
  }
  return (
    <PayerTimelineItem
      title={title}
      body="Start taking appointments"
      status={status}
      detail={detail}
    />
  );
}
