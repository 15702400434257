import { ReactNode } from 'react';

import { ContentText } from './ContentText';

export interface ListHeaderProps {
  children: ReactNode;
}

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const ListHeader = ({ children }: ListHeaderProps) => {
  return <ContentText variant="body-large/medium">{children}</ContentText>;
};
