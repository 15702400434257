import { useCallback, useEffect, useState } from 'react';

export const useDownloadBlob = () => {
  const [downloading, setDownloading] = useState(false);

  const download = useCallback((blob: Blob, filename: string) => {
    setDownloading(true);

    const url = window.URL.createObjectURL(blob);

    const phantomDownloadLink = document.createElement('a');
    phantomDownloadLink.href = url;
    phantomDownloadLink.download = filename;

    document.body.appendChild(phantomDownloadLink);
    phantomDownloadLink.click();

    document.body.removeChild(phantomDownloadLink);
    URL.revokeObjectURL(url);
  }, []);

  useEffect(() => {
    // not possible to track actual download status, but flipping the state on
    // for a moment allows us to show a toast when the download starts
    if (downloading) {
      setDownloading(false);
    }
  }, [downloading]);

  return [{ downloading }, download] as const;
};
