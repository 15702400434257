import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  PCL5Submission,
  PCL5SubmissionAssessmentType,
} from '@headway/api/models/PCL5Submission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { PdfText } from '../pdf';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const PCL5_OPTIONS: OptionsSchema = [
  {
    displayText: 'Not at all',
    value: 0,
  },
  {
    displayText: 'A little bit',
    value: 1,
  },
  {
    displayText: 'Moderately',
    value: 2,
  },
  {
    displayText: 'Quite a bit',
    value: 3,
  },
  {
    displayText: 'Extremely',
    value: 4,
  },
];

const PCL5_QUESTIONS: QuestionsSchema = [
  {
    key: 'PCL5_1',
    questionText:
      'Repeated, disturbing, and unwanted memories of the stressful experience?',
  },
  {
    key: 'PCL5_2',
    questionText: 'Repeated, disturbing dreams of the stressful experience?',
  },
  {
    key: 'PCL5_3',
    questionText:
      'Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?',
  },
  {
    key: 'PCL5_4',
    questionText:
      'Feeling very upset when something reminded you of the stressful experience?',
  },
  {
    key: 'PCL5_5',
    questionText:
      'Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?',
  },
  {
    key: 'PCL5_6',
    questionText:
      'Avoiding memories, thoughts, or feelings related to the stressful experience?',
  },
  {
    key: 'PCL5_7',
    questionText:
      'Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?',
  },
  {
    key: 'PCL5_8',
    questionText:
      'Trouble remembering important parts of the stressful experience?',
  },
  {
    key: 'PCL5_9',
    questionText:
      'Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?',
  },
  {
    key: 'PCL5_10',
    questionText:
      'Blaming yourself or someone else for the stressful experience or what happened after it?',
  },
  {
    key: 'PCL5_11',
    questionText:
      'Having strong negative feelings such as fear, horror, anger, guilt, or shame?',
  },
  {
    key: 'PCL5_12',
    questionText: 'Loss of interest in activities that you used to enjoy?',
  },
  {
    key: 'PCL5_13',
    questionText: 'Feeling distant or cut off from other people?',
  },
  {
    key: 'PCL5_14',
    questionText:
      'Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?',
  },
  {
    key: 'PCL5_15',
    questionText:
      'Irritable behaviour, angry outbursts, or acting aggressively?',
  },
  {
    key: 'PCL5_16',
    questionText:
      'Taking too many risks or doing things that could cause you harm?',
  },
  {
    key: 'PCL5_17',
    questionText: 'Being “superalert” or watchful or on guard?',
  },
  {
    key: 'PCL5_18',
    questionText: 'Feeling jumpy or easily startled?',
  },
  {
    key: 'PCL5_19',
    questionText: 'Having difficulty concentrating?',
  },
  {
    key: 'PCL5_20',
    questionText: 'Trouble falling or staying asleep?',
  },
];

const INSTRUCTION = (
  <>
    Below is a list of problems and complaints that people sometimes have in
    response to stressful life experiences. How much you have been bothered by
    that problem <em>in the last month</em>.
  </>
);

const INSTRUCTION_PDF = (
  <PdfText style={{ fontWeight: 'medium' }}>
    Below is a list of problems and complaints that people sometimes have in
    response to stressful life experiences. How much you have been bothered by
    that problem{' '}
    <PdfText style={{ fontWeight: 'bold' }}>in the last month</PdfText>.
  </PdfText>
);

const PCL5_VALIDATION_SCHEMA = Yup.object().shape(
  PCL5_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type PCL5FormValues = {
  [key in keyof Omit<PCL5Submission, 'assessmentType'>]: string;
};

export const PCL5AssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: PCL5FormValues) => {
    return onSubmit({
      assessmentType: PCL5SubmissionAssessmentType.PCL5,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<PCL5FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={PCL5_VALIDATION_SCHEMA}
      initialValues={{
        PCL5_1: '',
        PCL5_2: '',
        PCL5_3: '',
        PCL5_4: '',
        PCL5_5: '',
        PCL5_6: '',
        PCL5_7: '',
        PCL5_8: '',
        PCL5_9: '',
        PCL5_10: '',
        PCL5_11: '',
        PCL5_12: '',
        PCL5_13: '',
        PCL5_14: '',
        PCL5_15: '',
        PCL5_16: '',
        PCL5_17: '',
        PCL5_18: '',
        PCL5_19: '',
        PCL5_20: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={PCL5_QUESTIONS}
            options={PCL5_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyPCL5Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={PCL5_QUESTIONS}
        options={PCL5_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </section>
  );
};

export const PdfPCL5Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      {INSTRUCTION_PDF}
      <PdfScorableQuestionList
        questions={PCL5_QUESTIONS}
        options={PCL5_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </View>
  );
};
