import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import Michael from '@headway/shared/assets/img/company/michael.png';
import {
  isMedicareAdvantage,
  isNonOptumManagedMedicaid,
  isOptumManagedMedicaid,
} from '@headway/shared/utils/postIntakeInfoCollection';
import { HumanTouchpoint } from '@headway/ui/HumanTouchpoint';

import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

import { ProviderFeeSchedule } from '../../../api/ProviderFeeScheduleApi';

export const RatesModalContent = ({
  frontEndCarrierName,
  isProviderCredentialed,
  incompletePayerQuestionnaire,
  providerFeeSchedule,
  setCurrentModalContent,
  isMedicareMedicaid,
}: {
  frontEndCarrierName: string;
  isProviderCredentialed: boolean;
  incompletePayerQuestionnaire: PayerQuestionnaire;
  providerFeeSchedule: ProviderFeeSchedule[];
  setCurrentModalContent: () => void;
  isMedicareMedicaid: boolean;
}) => {
  const { user, provider } = useAuthStore();
  const isPayerQuestionnaireInProgress =
    incompletePayerQuestionnaire.response.response !== null;

  const shouldRenderRates =
    hasRateAccess(provider, user) && !isProviderCredentialed;

  return (
    <>
      <ModalContent>
        <div className="grid gap-4">
          {isOptumManagedMedicaid(frontEndCarrierName) ? (
            <OptumManagedMedicaidModalContent
              frontEndCarrierName={frontEndCarrierName}
            />
          ) : isMedicareMedicaid ? (
            <MedicareModalContent frontEndCarrierName={frontEndCarrierName} />
          ) : isNonOptumManagedMedicaid(frontEndCarrierName) ||
            isMedicareAdvantage(frontEndCarrierName) ? (
            <MedicaidMedicareAdvantageModalContent
              provider={provider}
              frontEndCarrierName={frontEndCarrierName}
            />
          ) : isProviderCredentialed ? (
            <CredentialedProviderModalContent
              frontEndCarrierName={frontEndCarrierName}
            />
          ) : (
            <UncredentialedProviderModalContent
              frontEndCarrierName={frontEndCarrierName}
              isPayerQuestionnaireInProgress={isPayerQuestionnaireInProgress}
            />
          )}
          {shouldRenderRates && (
            <CollapsibleDisclosure
              label={`View ${frontEndCarrierName}'s rates`}
            >
              {providerFeeSchedule.length > 0 && (
                <div
                  css={{
                    display: 'grid',
                    maxHeight: '500px',
                    overflowY: 'scroll',
                  }}
                >
                  <Table aria-label={`${frontEndCarrierName}'s rates`}>
                    <TableHeader>
                      <Column>CPT Code</Column>
                      <Column>You will earn</Column>
                    </TableHeader>
                    <TableBody>
                      {providerFeeSchedule.map(
                        (row: ProviderFeeSchedule, idx: number) => (
                          <Row key={idx}>
                            <Cell>{row.cpt_code}</Cell>
                            <Cell>
                              {row.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </Cell>
                          </Row>
                        )
                      )}
                    </TableBody>
                  </Table>
                </div>
              )}
            </CollapsibleDisclosure>
          )}
          {(isMedicareAdvantage(frontEndCarrierName) ||
            isOptumManagedMedicaid(frontEndCarrierName) ||
            isNonOptumManagedMedicaid(frontEndCarrierName)) && (
            <HumanTouchpoint
              imageSources={[Michael]}
              primaryLabel="Michael Heckendorn"
              secondaryLabel="Headway Clinical Lead and LPC"
            />
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        {!isProviderCredentialed && (
          <Button
            variant="secondary"
            size="large"
            type="submit"
            onPress={setCurrentModalContent}
          >
            Decline
          </Button>
        )}
        <LinkButton
          size="large"
          type="submit"
          component={RouterLink}
          variant="primary"
          elementType="a"
          to={`/payer-questionnaire/${incompletePayerQuestionnaire.response.id}`}
        >
          {isProviderCredentialed
            ? 'Complete form'
            : isPayerQuestionnaireInProgress
            ? `Finish application`
            : isMedicareMedicaid || isMedicareAdvantage(frontEndCarrierName)
            ? 'Start Application'
            : `Apply now`}
        </LinkButton>
      </ModalFooter>
    </>
  );
};

const MedicareModalContent = ({
  frontEndCarrierName,
}: {
  frontEndCarrierName: string;
}) => (
  <>
    <BodyText>
      Apply now to start seeing {frontEndCarrierName} clients! We just need a
      few pieces of information.
    </BodyText>
    <BodyText>
      Have questions about seeing {frontEndCarrierName} clients on Headway?
      Learn more in our{' '}
      <Link
        href="https://help.headway.co/hc/en-us/articles/23697087897108"
        target="_blank"
        rel="noopener noreferrer"
      >
        Help Center.
      </Link>
    </BodyText>
  </>
);

const MedicaidMedicareAdvantageModalContent = ({
  provider,
  frontEndCarrierName,
}: {
  provider: ProviderRead;
  frontEndCarrierName: string;
}) => (
  <BodyText>
    <div className="grid gap-4">
      <p>Hi {provider.displayFirstName},</p>
      <p>
        Great news, you can now get credentialed with {frontEndCarrierName}!
      </p>
      <p>
        As a clinician myself, I know first-hand how overwhelming it can feel to
        accept Medicare and Medicaid plans. That’s why our team has invested in
        a seamless enrollment and onboarding process, comprehensive training,
        and time-saving, compliant, documentation templates to make working with
        government plans simple and stress-free.
      </p>
      <p>
        As always, you have full control over the clients you accept on Headway
        – but we would love for you to join us in expanding access to
        affordable, quality care.
      </p>
    </div>
  </BodyText>
);

const CredentialedProviderModalContent = ({
  frontEndCarrierName,
}: {
  frontEndCarrierName: string;
}) => (
  <>
    <BodyText>
      <p>
        We need more information from you to maintain your insurance status with{' '}
        {frontEndCarrierName}!
      </p>
    </BodyText>
    <BodyText>
      <p>
        In order to continue seeing clients and confirming sessions with this
        plan, you’ll need to complete this form.
      </p>
    </BodyText>
  </>
);

const UncredentialedProviderModalContent = ({
  frontEndCarrierName,
  isPayerQuestionnaireInProgress,
}: {
  frontEndCarrierName: string;
  isPayerQuestionnaireInProgress: boolean;
}) => (
  <>
    {isPayerQuestionnaireInProgress ? `Finish your application` : `Apply now`}{' '}
    to start seeing clients in the {frontEndCarrierName} network. We just need a
    few pieces of information.
  </>
);

const OptumManagedMedicaidModalContent = ({
  frontEndCarrierName,
}: {
  frontEndCarrierName: string;
}) => (
  <>
    <p>
      Great news, you can now get credentialed with {frontEndCarrierName} on
      Headway!
    </p>
    <p>
      <b>
        Enrolling with Optum Managed Medicaid will also allow you to expedite
        your credentialing timeline for Optum commercial plans, which includes
        United Healthcare, Oscar Health, and Oxford.{' '}
      </b>
      If you choose to sign up, we’ll get started on your enrollment right away:
      you’ll be able to have Optum commercial appointments in 60 days or less,
      and you’ll be able to have your first Medicaid appointment on Headway in
      early 2025.{' '}
    </p>
    <p>
      If you decline to enroll with Medicaid, you will remain on the Optum
      credentialing waitlist and we’ll reach out once these plans are available
      for you.
    </p>
    <p>
      As a clinician myself, I know first-hand how overwhelming it can feel to
      accept Medicaid. That’s why our team has invested in a seamless enrollment
      and onboarding process, comprehensive training, and time-saving,
      compliant, documentation templates to make working with Medicaid simple
      and stress-free.
    </p>
    <p>
      As always, you have full control over the clients you accept on Headway –
      but we would love for you to join us in expanding Medicaid access for
      those who face the greatest barriers to affordable, quality care.
    </p>
  </>
);
