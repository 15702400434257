import { ProviderAppointmentRead } from '@headway/api/models/ProviderAppointmentRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';

export const usePrescriberPsychotherapyTimesEnabled = (
  provider?: ProviderRead,
  patient?: UserRead,
  appointment?: ProviderAppointmentRead
) => {
  const prescriberPsychotherapyTimesCarriers = useFlag(
    PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
    []
  );
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: appointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  const matchingCarrierId = matchingProviderFrontEndCarrier?.frontEndCarrierId;
  return (
    prescriberPsychotherapyTimesCarriers?.includes(matchingCarrierId) &&
    provider?.isPrescriber
  );
};
