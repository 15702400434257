import { ArrowForward, Timelapse } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';

import { CaptionText } from '@headway/helix/CaptionText';
import { useUser } from '@headway/shared/hooks/useUser';

type eventVariant = 'pending' | 'scheduled';

export const HowReferralAppointmentsWorkVisual = ({
  clientId,
}: {
  clientId: number;
}) => {
  const { data: client } = useUser({
    userId: clientId,
  });

  const clientFirstName = client?.firstName;

  return (
    <aside className="hlx-typography-body flex flex-col gap-4 rounded-[6px] bg-system-backgroundGray p-3">
      <CaptionText>
        Once {clientFirstName} completes their tasks, we'll notify you and
        update the calendar
      </CaptionText>
      <div className="m-0 flex list-none flex-row items-center gap-2 p-0 text-center">
        <div className="group flex flex-1 flex-col gap-2">
          <PendingReferralEventBlock variant="pending" />
        </div>
        <div className="m-4">
          <ArrowForward />
        </div>
        <div className="group flex flex-1 flex-col gap-2">
          <PendingReferralEventBlock variant="scheduled" />
        </div>
      </div>
    </aside>
  );
};

const PendingReferralEventBlock = ({ variant }: { variant: eventVariant }) => {
  const eventBlockStyling = clsx(
    'relative my-2 flex flex-col items-start rounded p-2 ',
    variant === 'pending'
      ? 'bg-[#FFF8EE] border-dashed border border-[#7E5C00]'
      : 'bg-[#E8F4FD] border-solid border border-[#2C98EF]'
  );

  return (
    <div className={eventBlockStyling}>
      <div className="m-0 flex w-full flex-row justify-between">
        <p className="text m-0 flex-col items-start font-bold leading-none">
          Client name
        </p>
        {variant === 'pending' ? (
          <Timelapse
            css={{
              color: 'hsla(43.8, 200%, 24.7%)',
              fontSize: 'inherit',
              alignItems: 'start',
            }}
          />
        ) : null}
      </div>
      <p className="m-0">
        <CaptionText>Time</CaptionText>
      </p>
    </div>
  );
};
