import React from 'react';

import { LookupStatus } from '@headway/api/models/LookupStatus';
import { PatientMismatchInputField } from '@headway/api/models/PatientMismatchInputField';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';

import { useAuthStore } from 'stores/AuthStore';
import { getHumanInputErrorMismatchString } from 'utils/readinessIssue';

const HumanErrorGuidanceCard = ({
  patient,
  mismatchedFields,
  onOpenInsuranceModal,
}: {
  patient: UserRead;
  mismatchedFields: PatientMismatchInputField[];
  onOpenInsuranceModal?: () => void;
}) => {
  const { user } = useAuthStore();

  React.useEffect(() => {
    trackPageView({
      name: 'Inactive Insurance Messaging Viewed',
      properties: {
        lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
        userId: user.id,
        patientUserId: patient.id,
        confettiHumanInputErrorExperimentGroup: 'treatment',
        confettiHumanInputErrorSurfacedBy:
          mismatchedFields.length > 0 ? 'HW' : 'CHC',
      },
    });
  }, [patient.id, user.id]);

  return (
    <div css={{ marginTop: theme.spacing.x2 }}>
      <GuidanceCard variant="error" layout="vertical">
        {mismatchedFields.length > 0 ? (
          <>
            <SubBodyText>
              <strong>
                {getHumanInputErrorMismatchString(mismatchedFields)}.{' '}
              </strong>
              To confirm your session, please ensure the client's insurance
              details match the details on their insurance card. You can also
              ask your client to update if needed.
            </SubBodyText>
            {onOpenInsuranceModal ? (
              <Button
                variant="link"
                onPress={() => {
                  trackEvent({
                    name: 'Inactive Insurance Messaging CTA Clicked',
                    properties: {
                      lookupStatus:
                        LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                      userId: user.id,
                      patientUserId: patient.id,
                      confettiHumanInputErrorExperimentGroup: 'treatment',
                      confettiHumanInputErrorSurfacedBy:
                        mismatchedFields.length > 0 ? 'HW' : 'CHC',
                    },
                  });
                  onOpenInsuranceModal();
                }}
              >
                Update insurance information
              </Button>
            ) : (
              <LinkButton
                href={`/clients/${patient.id}/billing?showBillingModal`}
                variant="link"
                size="medium"
                onClick={() => {
                  trackEvent({
                    name: 'Inactive Insurance Messaging CTA Clicked',
                    properties: {
                      lookupStatus:
                        LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                      userId: user.id,
                      patientUserId: patient.id,
                      confettiHumanInputErrorExperimentGroup: 'treatment',
                      confettiHumanInputErrorSurfacedBy:
                        mismatchedFields.length > 0 ? 'HW' : 'CHC',
                    },
                  });
                }}
              >
                Update insurance information
              </LinkButton>
            )}
          </>
        ) : (
          <>
            {
              <SubBodyText>
                <strong>Did you type that correctly?</strong> Please check for
                typos and confirm the details match the details on the insurance
                card for the client (the person receiving care). If everything
                matches,{' '}
                {
                  <Link
                    onClick={() => {
                      trackEvent({
                        name: 'Contact Form Link Clicked',
                        properties: {
                          lookupStatus:
                            LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                          userId: user.id,
                          patientUserId: patient.id,
                          confettiHumanInputErrorExperimentGroup: 'treatment',
                          confettiHumanInputErrorSurfacedBy:
                            mismatchedFields.length > 0 ? 'HW' : 'CHC',
                        },
                      });
                    }}
                    href={`${process.env.REACT_APP_MAIN_URL}/contact`}
                    target="_blank"
                  >
                    request a manual verification
                  </Link>
                }
                .
              </SubBodyText>
            }
            <LinkButton
              variant="link"
              size="medium"
              href={`/clients/${patient.id}/billing?showBillingModal`}
              onClick={() => {
                trackEvent({
                  name: 'Inactive Insurance Messaging CTA Clicked',
                  properties: {
                    lookupStatus:
                      LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                    userId: user.id,
                    patientUserId: patient.id,
                    confettiHumanInputErrorExperimentGroup: 'treatment',
                    confettiHumanInputErrorSurfacedBy:
                      mismatchedFields.length > 0 ? 'HW' : 'CHC',
                  },
                });
              }}
            >
              Add patient to private pay
            </LinkButton>
          </>
        )}
      </GuidanceCard>
    </div>
  );
};

export { HumanErrorGuidanceCard };
