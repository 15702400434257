import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PdfDocument } from '@headway/ui/pdf';

import { AssessmentResultsPdfHeaderProps } from './AssessmentResultsPdfHeader';
import { AssessmentResultsPdfPage } from './AssessmentResultsPdfPage';

export type AssessmentResultsPdfProps = AssessmentResultsPdfHeaderProps & {
  assessments: PatientAssessmentRead[];
};

export const AssessmentResultsPdf = ({
  assessments,
  ...headerProps
}: AssessmentResultsPdfProps) => {
  return (
    <PdfDocument>
      {assessments.map((assessment) => (
        <AssessmentResultsPdfPage
          key={assessment.id}
          assessment={assessment}
          {...headerProps}
        />
      ))}
    </PdfDocument>
  );
};
