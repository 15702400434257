import React from 'react';
import { redirectToAuth0Logout, useAuth0Client } from '~/legacy/utils/auth';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { useMutation } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import { toaster } from '@headway/ui/ToastManager';

const ResetPassword: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const mustResetPassword = searchParams.get('must_reset') === 'true';
  const auth0Client = useAuth0Client();
  const onSubmit = useMutation(
    async () => {
      await AuthApi.auth0SendProviderResetPasswordEmail();
    },
    {
      onSuccess: () => {
        toaster.success('Email sent!');
      },
      onError: (error) => {
        toaster.error('Failed to send password reset email');
        logException(error);
      },
    }
  );
  const onLogout = useMutation(async () => {
    await redirectToAuth0Logout(auth0Client);
  });
  const defaultText = {
    title: 'Reset password',
    description: 'Continue and we’ll send you a link to reset your password.',
  };
  const mustResetPasswordStrings = {
    title: 'Reset password required',
    description:
      'As part of our security upgrade, we’re initiating a password reset for all Headway accounts.',
  };
  return (
    <div className="mx-auto">
      <div className="flex w-[400px] flex-col items-center justify-center rounded bg-system-white p-8 shadow-light">
        <div className="flex w-1/2 items-center justify-center pb-6">
          <HeadwayLogo color="green" text="headway" />
        </div>
        {onSubmit.isSuccess ? (
          <div className="pb-6 text-center">
            <SectionHeader>Please check your email</SectionHeader>
            <div className="mb-4 rounded p-2 text-system-white">
              <BodyText>
                We’ve sent an email to the address associated with your account.
                Please follow the instructions in it to reset your password.
              </BodyText>
            </div>
            <BodyText>
              Didn't get our email?{' '}
              <Button
                variant="link"
                onPress={() => {
                  onSubmit.reset();
                }}
              >
                Resend
              </Button>
            </BodyText>
          </div>
        ) : (
          <div className="pb-6 text-center">
            <SectionHeader>
              {mustResetPassword
                ? mustResetPasswordStrings.title
                : defaultText.title}
            </SectionHeader>
            <div className="mb-4 flex flex-col p-4">
              <div className="mb-6">
                <BodyText>
                  {mustResetPassword
                    ? mustResetPasswordStrings.description
                    : defaultText.description}
                </BodyText>
              </div>

              <div className="flex w-full flex-col gap-2">
                <Button
                  variant="primary"
                  type="button"
                  onPress={() => onSubmit.mutateAsync()}
                  disabled={onSubmit.isLoading || onLogout.isLoading}
                >
                  Reset password
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onPress={() => onLogout.mutateAsync()}
                  disabled={onLogout.isLoading}
                >
                  Log out instead
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
