import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path
      d="M189.44 56.299h-27.627V46.827a4.181 4.181 0 0 0 -8.352 0v9.461h-53.867V46.827a4.181 4.181 0 0 0 -8.363 0v9.461h-27.627A10.293 10.293 0 0 0 53.333 66.56v125.824c0 5.653 4.608 10.272 10.272 10.272H189.44c5.664 0 10.272 -4.608 10.272 -10.272V66.56a10.293 10.293 0 0 0 -10.272 -10.272zm1.92 136.096c0 1.067 -0.853 1.92 -1.92 1.92H63.616a1.92 1.92 0 0 1 -1.92 -1.92V66.56c0 -1.067 0.853 -1.92 1.92 -1.92h27.627v9.451a4.181 4.181 0 0 0 8.363 0v-9.461h53.867v9.461a4.181 4.181 0 0 0 8.352 0v-9.461h27.627c1.067 0 1.92 0.853 1.92 1.92v125.845m-27.061 -83.157 -38.144 46.923c-0.875 1.067 -2.133 1.707 -3.52 1.781h-0.235a4.8 4.8 0 0 1 -3.413 -1.429l-23.104 -23.093a4.181 4.181 0 0 1 5.909 -5.899l20.341 20.331 35.68 -43.893a4.16 4.16 0 0 1 5.867 -0.597 4.16 4.16 0 0 1 0.608 5.867z"
      fill="currentColor"
    />,
  ],
]);
export const IconAvailableThisWeek: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="45 42 162 162" {...props} weights={weights} />
));

IconAvailableThisWeek.variants = ['outlined'];
IconAvailableThisWeek.defaultVariant = 'outlined';
