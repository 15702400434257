import {
  formatDate,
  isDateInPast,
} from '@headway/shared/utils/insuranceStatus';
import { PayerTimelineItem } from '@headway/ui/providers/PayerTimelineItem';
import { PayerTimelineItemStatus } from '@headway/ui/providers/PayerTimelineItemStatus';

interface GetPaidTimelineItemProps {
  paymentReadyDate?: Date;
  appointmentReadyDate?: Date;
}

export function GetPaidTimelineItem({
  paymentReadyDate,
  appointmentReadyDate,
}: GetPaidTimelineItemProps) {
  let detail;
  let status = PayerTimelineItemStatus.PAYMENT_READY_PENDING;
  if (isDateInPast(appointmentReadyDate)) {
    status = PayerTimelineItemStatus.IN_PROGRESS;
  }
  if (paymentReadyDate) {
    const formatted = formatDate(paymentReadyDate);
    status = PayerTimelineItemStatus.IN_PROGRESS;

    if (isDateInPast(paymentReadyDate)) {
      detail = `Completed ${formatted}`;
      status = PayerTimelineItemStatus.COMPLETE;
    } else {
      detail = `Due ${formatted}`;
    }
  } else {
    detail = 'ETA coming soon';
  }

  return (
    <PayerTimelineItem
      status={status}
      title="Get paid"
      body="Start receiving payouts"
      detail={detail}
      drawLine={false}
    />
  );
}
