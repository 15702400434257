import { createContext, ReactNode, useState } from 'react';

export enum ManagementAssessmentStep {
  SELECT_ASSESSMENTS = 'SELECT_ASSESSMENTS',
  ATTESTATION = 'ATTESTATION',
  FILL_OUT_ASSESSMENTS = 'FILL_OUT_ASSESSMENTS',
  COMPLETE_ASSESSMENTS = 'COMPLETE_ASSESSMENTS',
  SCHEDULE_RECURRING = 'SCHEDULE_RECURRING',
}

export type FillOutAssessmentForClientContextType = {
  currentStep: ManagementAssessmentStep;
  setCurrentStep: (step: ManagementAssessmentStep) => void;
};

export const FillOutAssessmentModalContext =
  createContext<FillOutAssessmentForClientContextType>({
    currentStep: ManagementAssessmentStep.SELECT_ASSESSMENTS,
    setCurrentStep: () => {},
  });

export const FillOutAssessmentForClientModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [currentStep, setCurrentStep] = useState<ManagementAssessmentStep>(
    ManagementAssessmentStep.SELECT_ASSESSMENTS
  );

  const contextValue: FillOutAssessmentForClientContextType = {
    currentStep,
    setCurrentStep: (step: ManagementAssessmentStep) => {
      setCurrentStep(step);
    },
  };

  return (
    <FillOutAssessmentModalContext.Provider value={contextValue}>
      {children}
    </FillOutAssessmentModalContext.Provider>
  );
};
