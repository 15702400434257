import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M1.66797 16.6666V3.33325H18.3346V16.6666H1.66797ZM2.91797 6.85409H17.0846V4.58325H2.91797V6.85409ZM2.91797 9.54158V15.4166H17.0846V9.54158H2.91797ZM2.91797 15.4166V4.58325V15.4166Z" />,
  ],
]);
export const IconCreditCard: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="0 0 20 20" {...props} weights={weights} />
));

IconCreditCard.variants = ['outlined'];
IconCreditCard.defaultVariant = 'outlined';
