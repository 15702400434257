import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'fill',
    <path d="m122.432 256-36.736-36.736H39.898a26.11 26.11 0 0 1-19.174-7.923 26.11 26.11 0 0 1-7.924-19.175V27.098q0-11.251 7.936-19.174A26.11 26.11 0 0 1 39.885 0h165.069a26.11 26.11 0 0 1 19.174 7.936q7.936 7.91 7.936 19.162v165.069a26.11 26.11 0 0 1-7.936 19.174 26.11 26.11 0 0 1-19.174 7.936h-45.798zm-.832-74.816q6.528 0 11.098-4.57a15.1 15.1 0 0 0 4.57-11.11q0-6.528-4.57-11.11a15.1 15.1 0 0 0-11.098-4.557q-6.528 0-11.11 4.557a15.1 15.1 0 0 0-4.57 11.11q0 6.528 4.57 11.11 4.57 4.57 11.11 4.57m-11.52-45.862h23.462q0-5.082.486-8.64.474-3.584 1.971-6.848 1.485-3.277 3.789-6.182a77 77 0 0 1 6.4-6.925q9.715-9.715 14.029-16.781t4.326-16.358q0-16.218-10.867-25.856t-28.749-9.638q-16.717 0-28.378 8.576a44.8 44.8 0 0 0-16.256 22.938l21.222 8.243q2.176-7.616 8.026-12.749 5.862-5.12 14.054-5.12 7.475 0 12.365 4.096 4.902 4.096 4.902 10.688 0 4.838-3.238 10.33-3.238 5.478-10.061 11.469a60 60 0 0 0-8.474 8.512 43.5 43.5 0 0 0-5.286 8.525 35.8 35.8 0 0 0-2.906 9.523q-.806 5.043-.819 12.198" />,
  ],
]);
export const IconInsight: Icon = forwardRef((props, ref) => (
  <IconBase
    ref={ref}
    viewBox="0 0 256 256"
    {...props}
    defaultVariant="filled"
    weights={weights}
  />
));

IconInsight.variants = ['filled'];
IconInsight.defaultVariant = 'filled';
