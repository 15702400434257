import { Selection } from '@react-types/shared/src/selection';
import React, { useMemo } from 'react';

import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { CheckboxTree, CheckboxTreeItem } from '@headway/helix/CheckboxTree';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

type PayerTreeSelect = {
  id: number;
  name: string;
  childItems: { id: string; name: string; plsId: number; pfecId: number }[];
};
type PLSStateMap = { [key: number]: UnitedStates };

const getPayerTreeChildName = (carrierId: string, state: string) => {
  return carrierId + '-' + state;
};

const getPLSStateMap = (provider: ProviderRead): PLSStateMap => {
  return provider.activeProviderLicenseStates.reduce((mapping, pls) => {
    mapping[pls.id] = pls.state;
    return mapping;
  }, {} as PLSStateMap);
};

const getPayerTreeItems = (
  provider: ProviderRead,
  pfecs: ProviderFrontEndCarrierNested[] | undefined,
  separateVirtualNetworks: boolean | undefined
): PayerTreeSelect[] => {
  if (!pfecs) {
    return [];
  }

  const plsToStateMapping = getPLSStateMap(provider);

  // Reduces to an object that maps carrier id to a list of states that the carrier is available in
  const fecWithStates = pfecs.reduce(
    (mapping, pfec) => {
      const pfecInfo = {
        pfecId: pfec.id,
        state: plsToStateMapping[pfec.providerLicenseStateId],
        plsId: pfec.providerLicenseStateId,
      };
      let carrierId = pfec.frontEndCarrier.id;
      let carrierName = pfec.frontEndCarrier.name;
      if (separateVirtualNetworks && !!pfec.wrapNetwork) {
        carrierId = -carrierId; // kinda hacky, but we don't have actual separate carrier IDs for virtual networks
        carrierName = `${carrierName} (Virtual network)`;
      }
      if (mapping[carrierId]) {
        mapping[carrierId].pfecInfos.push(pfecInfo);
      } else {
        mapping[carrierId] = {
          carrierName,
          pfecInfos: [pfecInfo],
        };
      }

      return mapping;
    },
    {} as {
      [key: number]: {
        carrierName: string;
        pfecInfos: {
          pfecId: number;
          state: UnitedStates;
          plsId: number;
        }[];
      };
    }
  );

  // Creates our final object which the Helix CheckboxTree component expects
  return Object.entries(fecWithStates)
    .map(([carrierId, { carrierName, pfecInfos }]) => {
      return {
        id: parseInt(carrierId),
        name: carrierName,
        childItems: pfecInfos
          .map(({ pfecId, state, plsId }) => ({
            id: getPayerTreeChildName(carrierId, state),
            name: statesToDisplayNames[state],
            plsId,
            pfecId,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

const getPFECIdToPayerTreeIdMap = (
  payerTreeItems: PayerTreeSelect[]
): Map<number, string> => {
  return payerTreeItems.reduce((mapping, payer) => {
    payer.childItems.forEach((child) => {
      mapping.set(child.pfecId, child.id);
    });
    return mapping;
  }, new Map<number, string>());
};

const getPayerTreeIdToPFECIdMap = (
  payerTreeItems: PayerTreeSelect[]
): Map<string, number> => {
  return payerTreeItems.reduce((mapping, payer) => {
    payer.childItems.forEach((child) => {
      mapping.set(child.id, child.pfecId);
    });
    return mapping;
  }, new Map<string, number>());
};

type PFECCheckboxTreeProps = {
  ariaLabel: string;
  provider: ProviderRead;
  selectedPFECs: number[];
  onSelectedPFECsChange: (selectedPFECs: number[]) => void;
  providerFrontEndCarriers?: ProviderFrontEndCarrierNested[];
  separateVirtualNetworks?: boolean;
};

export const PFECCheckboxTree = ({
  ariaLabel,
  provider,
  selectedPFECs,
  onSelectedPFECsChange,
  providerFrontEndCarriers,
  separateVirtualNetworks,
}: PFECCheckboxTreeProps) => {
  const pfecs = providerFrontEndCarriers || provider.providerFrontEndCarriers;
  const payerTreeItems = useMemo(
    () => getPayerTreeItems(provider, pfecs, separateVirtualNetworks),
    [provider, pfecs, separateVirtualNetworks]
  );
  const pfecIdToPayerTreeIdMap = useMemo(
    () => getPFECIdToPayerTreeIdMap(payerTreeItems),
    [payerTreeItems]
  );
  const payerTreeIdToPFECIdMap = useMemo(
    () => getPayerTreeIdToPFECIdMap(payerTreeItems),
    [payerTreeItems]
  );

  const getSelectedKeys = (): (string | number)[] => {
    const keys: (string | number)[] = [];
    selectedPFECs.forEach((pfecId) => {
      const key = pfecIdToPayerTreeIdMap.get(pfecId);
      if (key) {
        keys.push(key);
      }
    });
    // need to include FEC ids so the outer FEC checkbox is checked appropriately
    pfecs?.forEach((pfec) => {
      let carrierId = pfec.frontEndCarrier.id;
      if (separateVirtualNetworks && !!pfec.wrapNetwork) {
        carrierId = -carrierId; // kinda hacky, but we don't have actual separate carrier IDs for virtual networks
      }
      if (selectedPFECs.includes(pfec.id) && !keys.includes(carrierId)) {
        keys.push(carrierId);
      }
    });
    return keys;
  };
  const selectedKeys = getSelectedKeys();

  const onSelectionChange = (selection: Selection) => {
    let newSelectedPFECs: number[];
    if (selection === 'all') {
      newSelectedPFECs = Array.from(payerTreeIdToPFECIdMap.values());
    } else {
      newSelectedPFECs = [];
      selection.forEach((key) => {
        if (typeof key === 'number') {
          // carrier ID instead of carrier-state string
          return;
        }

        const pfecId = payerTreeIdToPFECIdMap.get(key);
        if (pfecId) {
          newSelectedPFECs.push(pfecId);
        }
      });
    }
    onSelectedPFECsChange(newSelectedPFECs);
  };

  return (
    <CheckboxTree
      aria-label={ariaLabel}
      items={payerTreeItems}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
    >
      {(item) => (
        <CheckboxTreeItem
          childItems={item.childItems}
          textValue={item.name}
          item={item}
        >
          {item.name}
        </CheckboxTreeItem>
      )}
    </CheckboxTree>
  );
};
