import { CheckSquare } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconCheckSquare: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={CheckSquare}
    variants={['outlined', 'filled']}
    defaultVariant="filled"
    {...props}
    ref={ref}
  />
));

IconCheckSquare.variants = ['outlined', 'filled'];
IconCheckSquare.defaultVariant = 'filled';
