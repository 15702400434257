import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  ANCHORSubmission,
  ANCHORSubmissionAssessmentType,
} from '@headway/api/models/ANCHORSubmission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { PdfText } from '../pdf';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsByQuestionSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const QUESTION_PREFIX = 'Compared to a few months ago, ';

const ANCHOR_QUESTIONS: QuestionsSchema = [
  {
    questionText: QUESTION_PREFIX + 'how is your anxiety now?',
    key: 'ANCHOR_1',
  },
  {
    questionText: QUESTION_PREFIX + 'how is your depression now?',
    key: 'ANCHOR_2',
  },
  {
    questionText:
      QUESTION_PREFIX +
      'how would you rate your ability to carry out normal activities and roles now? (This includes activities at home, at work or school and in your community, and responsibilities as a parent, child, spouse, employee, friend, etc.)',
    key: 'ANCHOR_3',
  },
];

const ANCHOR_OPTIONS_BY_QUESTION: OptionsByQuestionSchema = {
  ANCHOR_1: [
    {
      displayText: 'A lot less',
      value: '1',
    },
    {
      displayText: 'A little less',
      value: '2',
    },
    {
      displayText: 'About the same',
      value: '3',
    },
    {
      displayText: 'A little more',
      value: '4',
    },
    {
      displayText: 'A lot more',
      value: '5',
    },
  ],
  ANCHOR_2: [
    {
      displayText: 'A lot better',
      value: '1',
    },
    {
      displayText: 'A little better',
      value: '2',
    },
    {
      displayText: 'About the same',
      value: '3',
    },
    {
      displayText: 'A little worse',
      value: '4',
    },
    {
      displayText: 'A lot worse',
      value: '5',
    },
  ],
  ANCHOR_3: [
    {
      displayText: 'A lot better',
      value: '1',
    },
    {
      displayText: 'A little better',
      value: '2',
    },
    {
      displayText: 'About the same',
      value: '3',
    },
    {
      displayText: 'A little worse',
      value: '4',
    },
    {
      displayText: 'A lot worse',
      value: '5',
    },
  ],
};

const INSTRUCTION =
  'Please respond to each question or statement by marking one item below.';

const ANCHOR_VALIDATION_SCHEMA = Yup.object().shape(
  ANCHOR_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type ANCHORFormValues = {
  [key in keyof Omit<ANCHORSubmission, 'assessmentType'>]: string;
};

export const ANCHORAssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: ANCHORFormValues) => {
    return onSubmit({
      assessmentType: ANCHORSubmissionAssessmentType.ANCHOR,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<ANCHORFormValues>
      onSubmit={innerOnSubmit}
      validationSchema={ANCHOR_VALIDATION_SCHEMA}
      initialValues={{
        ANCHOR_1: '',
        ANCHOR_2: '',
        ANCHOR_3: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={ANCHOR_QUESTIONS}
            optionsByQuestion={ANCHOR_OPTIONS_BY_QUESTION}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyANCHORAssessmentForm = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={ANCHOR_QUESTIONS}
        optionsByQuestion={ANCHOR_OPTIONS_BY_QUESTION}
        response={scorableResponseJson}
      />
    </section>
  );
};

export const PdfANCHORAssessment = (props: ReadonlyAssessmentProps) => {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <PdfText style={{ fontWeight: 'medium' }}>{INSTRUCTION}</PdfText>
      <PdfScorableQuestionList
        questions={ANCHOR_QUESTIONS}
        optionsByQuestion={ANCHOR_OPTIONS_BY_QUESTION}
        response={props.response?.scorableResponseJson}
      />
    </View>
  );
};
