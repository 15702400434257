import { ModalProps } from '@mui/material';
import { FormikProps } from 'formik';
import { useProvider } from 'hooks';
import { Moment } from 'moment';
import { useCallback, useRef, useState } from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { FeedbackSystemQuestionnaireRead } from '@headway/api/models/FeedbackSystemQuestionnaireRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserRead } from '@headway/api/models/UserRead';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useUser } from '@headway/shared/hooks/useUser';

import { useClaimReadiness } from 'hooks/useClaimReadiness';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { useProviderPatient } from 'hooks/useProviderPatient';
import { useAuthStore } from 'stores/AuthStore';
import { TreatmentPlanWithContext } from 'views/Clients/TreatmentPlan/TreatmentPlan';
import { Attachment } from 'views/Patients/AttachmentsList';

import { AppointmentConfirmationModalV2 } from './AppointmentConfirmationModalV2';
import { SessionDetailsFormValues } from './Forms/SessionDetails/SessionDetailsForm';
import { AppointmentContextProviderV2 } from './stores/AppointmentContextV2';
import { ProgressNoteContextProviderV2 } from './stores/ProgressNotesContextV2';
import { SwoopUpsellModal } from './SwoopUpsellModal';

type AppointmentConfirmationV2ImplProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  onClose: () => void;
  eventVirtualId: string | number;
  patient: UserRead;
  progressNoteOnly: boolean;
  onOpenContactFormInsuranceIssues: () => void;
  openAppointmentFeedbackModalOpen?: (
    res: FeedbackSystemQuestionnaireRead
  ) => void;
  openTreatmentPlanAdoptionModal?: (isIntakeSession: boolean) => void;
  onProgressNoteUpdate?: () => void;
  setSelectedEvent?: (event?: ConcreteProviderEventRead) => void;
};

const AppointmentConfirmationV2Impl = ({
  open,
  onClose,
  eventVirtualId,
  patient,
  progressNoteOnly,
  onOpenContactFormInsuranceIssues,
  openAppointmentFeedbackModalOpen,
  openTreatmentPlanAdoptionModal,
  onProgressNoteUpdate,
  setSelectedEvent,
}: AppointmentConfirmationV2ImplProps) => {
  const authStore = useAuthStore();

  const provider = useProvider();
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });

  const sessionDetailsRef = useRef<FormikProps<SessionDetailsFormValues>>();
  const sessionDetailsErrorRef = useRef();
  const attachmentRef =
    useRef<FormikProps<{ attachments: Attachment<string>[] }>>();
  const progressNoteRef = useRef<FormikProps<any>>();
  const attestationRef = useRef<FormikProps<any>>();

  const [dismissCantConfirmYetBanner, setDismissCantConfirmYetBanner] =
    useLocalStorage('dismissCantConfirmYetBanner');

  const [providerClosedComplianceModal, setProviderClosedComplianceModal] =
    useLocalStorage('providerClosedComplianceModal');

  const [openComplianceModal, setOpenComplianceModal] = useState(
    !providerClosedComplianceModal
  );
  const [treatmentPlanModalIsOpened, setTreatmentPlanModalIsOpened] =
    useState(false);
  const [isSwoopUpsellModalOpened, setIsSwoopUpsellModalOpened] =
    useState(false);

  const [providerClosedConsiderHeadwayTemplateCard] = useLocalStorage(
    'providerClosedConsiderHeadwayTemplateCard'
  );

  const [onCloseTreatmentPlanModal, setOnCloseTreatmentPlanModal] = useState<
    (() => void) | undefined
  >(() => {});

  var saveRequestTimestamp = useRef<Moment>();
  const [isSelectedTemplateFreeText, setIsSelectedTemplateFreeText] =
    useState(false);
  const [isSelectedNoteUpload, setIsSelectedNoteUpload] = useState(false);

  const { data: claimReadiness } = useClaimReadiness({ patientUser: patient });

  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerId,
    patientId: event?.patientUserId,
  });

  progressNoteOnly =
    progressNoteOnly ||
    !!claimReadiness?.requirements?.includes(
      UserClaimReadinessCheck.INSUFFICIENT_OR_INCORRECT_INFORMATION
    );

  const onModalClose = useCallback(async () => {
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, authStore, onClose]);

  const openTreatmentPlanModal = () => {
    setTreatmentPlanModalIsOpened(true);
  };

  // Checks that the legal language has been attested,
  // by accessing the attestation form
  const checkAttestation = async () => {
    if (!attestationRef.current) {
      return false;
    }

    await attestationRef.current.submitForm();
    return attestationRef.current?.isValid ?? false;
  };

  const resetAttestationState = async () => {
    if (!attestationRef.current) {
      return;
    }
    attestationRef.current.resetForm();
  };

  // If the open value switches to false we return null to make sure that the appointment context and
  // the progress note context fully unmount since its not part of the unmount logic of the Modal
  if (!open || !providerPatient) {
    return null;
  }

  if (!event) {
    return null;
  }

  return (
    <ProgressNoteContextProviderV2
      progressNoteRef={progressNoteRef}
      attachmentRef={attachmentRef}
      eventVirtualId={event.virtualId}
      patient={patient}
      onProgressNoteUpdate={() => {
        if (onProgressNoteUpdate) onProgressNoteUpdate();
      }}
    >
      <AppointmentContextProviderV2
        sessionDetailsRef={sessionDetailsRef}
        attachmentRef={attachmentRef}
        sessionDetailsErrorRef={sessionDetailsErrorRef}
        eventVirtualId={event.virtualId}
        patient={patient}
        setSelectedEvent={setSelectedEvent}
        onProgressNoteUpdate={() => {
          if (onProgressNoteUpdate) onProgressNoteUpdate();
        }}
      >
        <AppointmentConfirmationModalV2
          patient={patient}
          provider={provider}
          isOpen={open}
          onClose={onModalClose}
          onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
          onProgressNoteUpdate={onProgressNoteUpdate}
        />
        <TreatmentPlanWithContext
          isEditing={treatmentPlanModalIsOpened}
          setIsEditing={setTreatmentPlanModalIsOpened}
          providerPatient={providerPatient}
          onCloseTreatmentPlanModal={onCloseTreatmentPlanModal}
        />
        <SwoopUpsellModal
          open={isSwoopUpsellModalOpened}
          onClose={() => {
            // close the entire modal which will cascade-close all the other modals opened on top
            onModalClose();
          }}
          patient={patient}
          eventVirtualId={event.virtualId}
        />
      </AppointmentContextProviderV2>
    </ProgressNoteContextProviderV2>
  );
};

interface AppointmentConfirmationV2Props
  extends Omit<AppointmentConfirmationV2ImplProps, 'patient'> {
  patientId: number;
}

export const AppointmentConfirmationV2 = ({
  patientId,
  ...props
}: AppointmentConfirmationV2Props) => {
  const { data: patient, isLoading: isPatientLoading } = useUser({
    userId: patientId,
  });

  if (isPatientLoading || !patient) {
    return null;
  }

  return <AppointmentConfirmationV2Impl patient={patient} {...props} />;
};
