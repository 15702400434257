import React from 'react';

import { IconInfo } from './icons/Info';
import { IconWarningCircle } from './icons/WarningCircle';

export interface BannerProps {
  /** The main text of the banner. */
  children: React.ReactNode;
  /** Affects the styling of the banner.  */
  variant: 'neutral' | 'info' | 'success' | 'warning' | 'error';
  /**
   * Adornment that should appear next to the main text of the banner. Right now, the only
   * HDS-approved adornment is a Button with the "Link" variant.
   */
  adornment?: React.ReactNode;
}

function Banner({ variant, children, adornment }: BannerProps) {
  return (
    <div className="hlx-banner" data-hlx-variant={variant}>
      {variant === 'warning' || variant === 'error' ? (
        <IconWarningCircle className="hlx-banner-icon" aria-hidden={true} />
      ) : (
        <IconInfo className="hlx-banner-icon" aria-hidden={true} />
      )}
      <span className="hlx-banner-content">{children}</span>
      {adornment && <span className="hlx-banner-adornment">{adornment}</span>}
    </div>
  );
}

export { Banner };
