import { css } from '@emotion/react';
import React from 'react';

import { InsuranceReadinessApproachingMaxSessionsType } from '@headway/api/models/InsuranceReadinessApproachingMaxSessions';
import { InsuranceReadinessIssueHasNoTelehealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueHasNoTelehealthBenefits';
import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { InsuranceReadinessNoRemainingSessionsType } from '@headway/api/models/InsuranceReadinessNoRemainingSessions';
import { InsuranceReadinessRemainingSessionsType } from '@headway/api/models/InsuranceReadinessRemainingSessions';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { MAX_SESSIONS_EXPERIENCE } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getIneligibleLookupTypeFromReadinessIssues } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Modal } from '@headway/ui/Modal';

import clinicalLeadHeadshotImage from 'assets/img/michael_heckendorn.png';
import { PRIVATE_PAY_PROVIDER_HELP_CENTER_LINK } from 'constants/privatePay';

interface InsuranceIneligibilityExplainerModalProps {
  client: UserRead | undefined;
  provider: ProviderRead | undefined;
  open: boolean;
  onClose: () => void;
  issues: UserAppointmentReadiness['insurance'];
  onClickOpenBillingMethodModal?: () => void;
}

export const InsuranceIneligibilityExplainerModal = ({
  client,
  provider,
  open,
  onClose,
  issues,
  onClickOpenBillingMethodModal,
}: InsuranceIneligibilityExplainerModalProps) => {
  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });
  const maxSessionsExperience = useFlag(MAX_SESSIONS_EXPERIENCE, false);

  let includeUpdateInsuranceEligibilityButton = true;
  let includeAcknowledgeButton = false;
  let isError = true;

  React.useEffect(() => {
    if (
      open &&
      client &&
      provider &&
      client.activeUserInsurance &&
      issues.length
    ) {
      trackPageView({
        name: 'Ineligible Insurance Modal Viewed',
        properties: {
          patientUserId: client.id,
          providerId: provider.id,
          userInsuranceId: String(client.activeUserInsurance.id),
          eligibilityLookupId:
            client.activeUserInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType:
            getIneligibleLookupTypeFromReadinessIssues(issues),
        },
      });
    }
  }, [client, open, provider, issues]);

  if (!client) {
    return null;
  }
  if (!provider) {
    return null;
  }

  const providerFirstName = provider?.displayFirstName ?? provider?.firstName;

  const isOON = issues.find(
    (issue) =>
      issue.type === InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK
  );

  const isTerminated = issues.find(
    (issue) => issue.type === InsuranceReadinessIssueTerminatedType.TERMINATED
  );

  const isHasNoMentalHealthBenefits = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS
  );

  const isManualVerificationRequired = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED
  );

  const hasNoTelehealthBenefits = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueHasNoTelehealthBenefitsType.HAS_NO_TELEHEALTH_BENEFITS
  );

  const hasSessions = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessRemainingSessionsType.REMAINING_COVERED_SESSIONS
  );

  const hasNoRemainingSessions = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessNoRemainingSessionsType.NO_REMAINING_COVERED_SESSIONS
  );

  const approachingSessionLimit = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessApproachingMaxSessionsType.APPROACHING_MAX_SESSIONS
  );

  let titleContent;
  let bodyContent;
  let footerContent = () => (
    <div
      css={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'end',
        gap: 10,
      }}
    >
      {includeUpdateInsuranceEligibilityButton &&
        onClickOpenBillingMethodModal && (
          <div css={footerButton}>
            <Button
              onPress={() => {
                onClose();
                onClickOpenBillingMethodModal();
              }}
              variant={includeAcknowledgeButton ? 'secondary' : 'primary'}
              size="large"
            >
              Update insurance
            </Button>
          </div>
        )}
      {includeAcknowledgeButton && (
        <div css={footerButton}>
          <Button onPress={onClose} variant="primary" size="large">
            Got it
          </Button>
        </div>
      )}
    </div>
  );

  const fecName = client.activeUserInsurance?.billingFrontEndCarrierName;
  const fecDisplay = fecName || 'their insurance company';

  if (isOON) {
    titleContent = `${clientFirstName}'s plan is not eligible for in-network care`;
    bodyContent = (
      <>
        <strong>
          {clientFirstName}'s plan is not eligible for in-network care,
        </strong>{' '}
        based on the details we have today.{' '}
        <a
          href="https://headway.co/clients/understanding-health-insurance"
          target="_blank"
        >
          Learn more about insurance benefits here.
        </a>
        <p />
        <strong>What you can do</strong>
        <ul>
          <li>
            Check {clientFirstName}'s insurance details or ask them to verify
            them to make sure they match their most recent plan. Sometimes this
            is all it takes to resolve the issue.
          </li>
          <li>
            Ask {clientFirstName} if they're okay switching to private pay. They
            can keep seeing you through Headway by paying full cost per session.
          </li>
        </ul>
        <strong>If something still seems wrong</strong>
        <ul>
          <li>
            Have {clientFirstName} contact {fecDisplay} to get a reference
            number confirming their eligibility for care, then contact Headway
            with this number in hand.{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/21556524888596"
              target="_blank"
            >
              Learn more
            </a>
          </li>
        </ul>
        We've shared this same information with {clientFirstName}, and we
        appreciate your patience. Thanks for being a Headway provider!
      </>
    );
  }

  if (isHasNoMentalHealthBenefits) {
    titleContent = `${clientFirstName}'s plan doesn't include mental health coverage`;
    bodyContent = (
      <>
        <strong>
          {clientFirstName}'s plan does not include mental health coverage,
        </strong>{' '}
        based on the details we have today.{' '}
        <a
          href="https://headway.co/clients/understanding-health-insurance"
          target="_blank"
        >
          Learn more about insurance benefits here.
        </a>
        <p />
        <strong>What you can do</strong>
        <ul>
          <li>
            Check {clientFirstName}'s insurance details or ask them to verify
            them to make sure they match their most recent plan. Sometimes this
            is all it takes to resolve the issue.
          </li>
          <li>
            Ask {clientFirstName} if they're okay switching to private pay. They
            can keep seeing you through Headway by paying full cost per session.
          </li>
        </ul>
        <strong>If something still seems wrong</strong>
        <ul>
          <li>
            Have {clientFirstName} contact {fecDisplay} to get a reference
            number confirming their eligibility for care, then contact Headway
            with this number in hand.{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/21556524888596"
              target="_blank"
            >
              Learn more
            </a>
          </li>
        </ul>
        We've shared this same information with {clientFirstName}, and we
        appreciate your patience. Thanks for being a Headway provider!
      </>
    );
  }

  if (hasSessions && maxSessionsExperience) {
    isError = false;
    includeUpdateInsuranceEligibilityButton = false;
    includeAcknowledgeButton = true;
    titleContent = 'This plan has a maximum sessions limit';
    bodyContent = (
      <>
        <p>
          This plan is eligible for care on Headway, and{' '}
          <strong>
            has about{' '}
            {
              client.activeUserInsurance?.latestEligibilityLookup
                ?.remainingCoveredSessions
            }{' '}
            sessions remaining for this plan year
          </strong>
          . Note the exact number may be different, depending on whether any
          recent claims are still being processed.
        </p>
        <strong>What to keep in mind</strong>
        <ul>
          <li>
            Consider adjusting your session frequency based on the total covered
            sessions.
          </li>
          <li>
            If your client would like to attend more sessions in a single year,
            ask them if they'd consider using{' '}
            <a href={PRIVATE_PAY_PROVIDER_HELP_CENTER_LINK}>private pay</a>.
            You'll have the option to update this setting from the{' '}
            <strong>Billing</strong> tab once they reach the maximum number of
            sessions.
          </li>
        </ul>
        We've shared this same information with {clientFirstName}. Thanks for
        being a Headway provider!
      </>
    );
  }

  if (approachingSessionLimit && maxSessionsExperience) {
    isError = false;
    includeUpdateInsuranceEligibilityButton = false;
    includeAcknowledgeButton = true;
    titleContent =
      "Only a few sessions remaining covered by your client's plan";
    bodyContent = (
      <>
        <p>
          Your client has used most of the covered sessions for this plan year.
          Their insurer can confirm their exact number of remaining sessions,
          but our records suggest they fewer than 4 left.
        </p>
        <strong>What you can do</strong>
        <ul>
          <li>
            If they'd like to attend more sessions after exceeding the maximum,
            ask your client if they'd consider using{' '}
            <a href={PRIVATE_PAY_PROVIDER_HELP_CENTER_LINK}>private pay</a>.
            You'll have the option to update this setting from the{' '}
            <strong>Billing</strong> tab once they reach the maximum number of
            sessions.
          </li>
          <li>Wait for the plan year to reset.</li>
        </ul>
        We've shared this same information with {clientFirstName}. Thanks for
        being a Headway provider!
      </>
    );
  }

  if (hasNoRemainingSessions && maxSessionsExperience) {
    includeUpdateInsuranceEligibilityButton = true;
    includeAcknowledgeButton = true;
    titleContent = "No remaining sessions covered by client's plan";
    bodyContent = (
      <>
        <p>
          While this plan is eligible for care on Headway, your client has
          already used all the covered sessions for this plan year.
        </p>
        <strong>What you can do</strong>
        <ul>
          <li>
            Ask your client if they'll consider{' '}
            <a href={PRIVATE_PAY_PROVIDER_HELP_CENTER_LINK}>private pay</a>,
            where there is no limit to the total number of sessions. You can
            update this setting from the <strong>Billing</strong> tab.
          </li>
          <li>Wait for the plan year to reset.</li>
        </ul>
        We've shared this same information with {clientFirstName}. Thanks for
        being a Headway provider!
      </>
    );
  }

  if (isTerminated) {
    titleContent = `${clientFirstName}'s plan is no longer active`;
    bodyContent = (
      <>
        <strong>{clientFirstName}'s plan is no longer active,</strong> based on
        the details we have today.{' '}
        <a
          href="https://headway.co/clients/understanding-health-insurance"
          target="_blank"
        >
          Learn more about insurance benefits here.
        </a>
        <p />
        <strong>What you can do</strong>
        <ul>
          <li>
            Check {clientFirstName}'s insurance details or ask them to verify
            them to make sure they match their most recent plan. Sometimes this
            is all it takes to resolve the issue.
          </li>
          <li>
            Ask {clientFirstName} if they're okay switching to private pay. They
            can keep seeing you through Headway by paying full cost per session.
          </li>
        </ul>
        <strong>If something still seems wrong</strong>
        <ul>
          <li>
            Have {clientFirstName} contact {fecDisplay} to get a reference
            number confirming their eligibility for care, then contact Headway
            with this number in hand.{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/21556524888596"
              target="_blank"
            >
              Learn more
            </a>
          </li>
        </ul>
        We've shared this same information with {clientFirstName}, and we
        appreciate your patience. Thanks for being a Headway provider!
      </>
    );
  }

  if (isManualVerificationRequired) {
    titleContent = `${clientFirstName}'s benefits need to be manually verified`;
    bodyContent = (
      <>
        <strong>
          We can't automatically verify {clientFirstName}'s benefits
        </strong>{' '}
        based on the details we have today.{' '}
        <a
          href="https://headway.co/clients/understanding-health-insurance"
          target="_blank"
        >
          Learn more about insurance benefits here.
        </a>
        <p />
        <strong>Next steps</strong>
        <ul>
          <li>
            Make sure {clientFirstName}'s insurance details match their current
            insurance card exactly. Sometimes a quick update is all it takes to
            resolve the issue.
          </li>
          <li>
            Request a manual verification from Headway.{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/21556524888596"
              target="_blank"
            >
              Contact our team
            </a>{' '}
            to get started.
          </li>
        </ul>
        We've shared this same information with {clientFirstName}. Please note
        it can take 7-15 days to manually verify insurance, but rest assured my
        team will do our best to resolve the issue as quickly as possible!
      </>
    );
  }

  if (hasNoTelehealthBenefits) {
    includeAcknowledgeButton = true;
    titleContent = `${clientFirstName}'s plan doesn't include telehealth benefits`;
    bodyContent = (
      <>
        <div css={section}>
          <strong>
            {clientFirstName}'s plan doesn't cover virtual sessions
            (telehealth),
          </strong>{' '}
          based on the details we have today. <p />
        </div>
        <div css={section}>
          We've let them know, and suggested they choose one of two options:
          <p />
          1. Switch to in-person sessions, which is covered by their plan
          <p />
          2. Attend virtual sessions and use{' '}
          <a href={PRIVATE_PAY_PROVIDER_HELP_CENTER_LINK} target="_blank">
            private pay
          </a>{' '}
          instead of insurance
          <p />
        </div>
        <div css={section}>
          If they decide to do an in-person session instead, simply choose this
          option when you confirm the session. To use private pay, update your
          client’s insurance details before the session.
          <p />
        </div>
        <strong>If something still seems wrong</strong>
        <ul>
          <li>
            Have {clientFirstName} contact {fecDisplay} to get a reference
            number confirming their eligibility for care, then contact Headway
            with this number in hand.{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/21556524888596"
              target="_blank"
            >
              Learn more
            </a>
          </li>
        </ul>
      </>
    );
  }

  return (
    <Modal
      title={
        <div css={title}>
          <IconWarningCircle
            color={
              isError ? theme.color.primary.red : theme.color.primary.yellow
            }
            height={theme.spacing.x7}
            css={{
              marginRight: theme.spacing.x2,
            }}
          />
          {titleContent}
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <div css={greetingText}>
          <BodyText>Hi {providerFirstName},</BodyText>
        </div>
        <div css={section}>
          <BodyText>{bodyContent}</BodyText>
        </div>
        <div css={section}>
          <div css={footer}>
            <div>
              <img
                alt="Clinical Lead Headshot"
                src={clinicalLeadHeadshotImage}
                css={clinicalLeadHeadshot}
              />
            </div>
            <div css={signature}>
              <div css={signatureName}>Michael and team</div>
              <BodyText>Clinical Lead at Headway</BodyText>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>{footerContent()}</ModalFooter>
    </Modal>
  );
};

const title = css`
  display: flex;
  color: ${theme.color.system.textBlack};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 133.333% */
  letter-spacing: -0.21px;
`;
const greetingText = css`
  margin-top: ${theme.spacing.x4};
  margin-bottom: ${theme.spacing.x4};
`;
const section = css`
  margin-bottom: ${theme.spacing.x8};
`;
const footer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: ${theme.spacing.x3};
`;
const signatureName = css`
  font-weight: bold;
  font-size: 17px;
  display: block;
`;
const clinicalLeadHeadshot = css`
  width: 48px;
  height: 48px;
`;
const signature = css`
  display: flex;
  flex-direction: column;
`;
const footerButton = css`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  gap: 10px;
`;
