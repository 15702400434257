import { EnvelopeSimple } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconEnvelopeSimple: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={EnvelopeSimple}
    {...props}
    variants={['filled', 'outlined']}
    ref={ref}
  />
));

IconEnvelopeSimple.variants = ['filled', 'outlined'];
IconEnvelopeSimple.defaultVariant = 'outlined';
