import { User } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconUser: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={User}
    {...props}
    variants={['outlined', 'filled']}
    ref={ref}
  />
));

IconUser.variants = ['outlined', 'filled'];
IconUser.defaultVariant = 'outlined';
