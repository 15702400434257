import React from 'react';

import { IssuesCheckList } from '@headway/ui';

import { SchemaComponent } from '../../schema/schema.types';
import { getValue } from '../../view/utils';
import { ViewComponent } from './ViewComponent';

export const Issues = ({
  element,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title } = element;

  if (readOnly) {
    const selectedIssues = getValue(id);
    return <ViewComponent title={'Issues'} value={selectedIssues} />;
  }

  return <IssuesCheckList name={id} disabled={disabled} label={title} />;
};
