import { useMedicareOrMedicaid } from '~/legacy/hooks/useMedicareOrMedicaid';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

interface ConfirmSessionOnDraftModalProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  patientId: number;
}

export const ConfirmSessionOnDraftModal = ({
  open,
  onClose,
  onEdit,
  patientId,
}: ConfirmSessionOnDraftModalProps) => {
  const isPatientMedicareOrMedicaid = useMedicareOrMedicaid(patientId);
  const isMMTreatmentPlanRequirementEnabled: boolean = useFlag(
    'mmTreatmentPlanRequirement',
    false
  );
  const isMedicareOrMedicaid =
    isMMTreatmentPlanRequirementEnabled && isPatientMedicareOrMedicaid;

  return (
    <Modal
      title="You must complete this note to confirm the session"
      onDismiss={onClose}
      isOpen={open}
    >
      <ModalContent>
        {isMedicareOrMedicaid ? (
          <GuidanceCard variant="compliance">
            <BodyText>
              To meet compliance standards for Medicare and Medicaid plans,
              you're required to complete this documentation on Headway.
            </BodyText>
            <LinkButton
              variant="link"
              href="https://help.headway.co/hc/en-us/articles/23697087897108-Working-with-Original-Medicare-and-Medicare-Advantage-on-Headway#h_01HPT125JNGBXT7BGR8XF43XGK"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about documentation requirements
            </LinkButton>
          </GuidanceCard>
        ) : (
          <GuidanceCard variant="compliance">
            <BodyText>
              To meet compliance standards for sessions impacted by the current
              audit, you're required to complete this documentation on Headway.
            </BodyText>
            <LinkButton
              variant="link"
              href="https://help.headway.co/hc/en-us/articles/24735863229844-Writing-compliant-documentation"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about documentation requirements
            </LinkButton>
          </GuidanceCard>
        )}
        <div css={{ marginTop: theme.spacing.x6 }}>
          <BodyText>
            You saved the note as a draft. To confirm the session, please
            complete all required fields.
          </BodyText>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onClose} variant="secondary" size="large">
          Cancel
        </Button>
        <Button variant="primary" size="large" onPress={onEdit}>
          Edit note
        </Button>
      </ModalFooter>
    </Modal>
  );
};
