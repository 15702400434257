import { Auth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/browser';
import { InternalAxiosRequestConfig } from 'axios';
import React from 'react';

import { logException } from '@headway/shared/utils/sentry';

export const addAuth0TokenToConfig = async (
  config: InternalAxiosRequestConfig,
  client: Auth0Client
): Promise<InternalAxiosRequestConfig> => {
  try {
    // Fetch Auth0 Token, Either from Auth0 Cache or from Auth0's Server
    const token = await client.getTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scopes: 'default openid offline_access',
      },
    });
    // Manually set Authorization header
    config.headers['authorization'] = `bearer ${token}`;
  } catch (err) {
    logException(err);
    await redirectToAuth0Login(client);
  }

  return config;
};

export const redirectToAuth0Login = async (client: Auth0Client) => {
  const start = performance.now();

  // after login, return to the current page
  const returnTo = window.location.pathname + window.location.search;

  await client.loginWithRedirect({
    appState: { returnTo },
  });

  const end = performance.now();
  Sentry.metrics.distribution('redirect_to_auth_login_time', end - start, {
    unit: 'millisecond',
  });
};

export const redirectToAuth0Logout = async (client: Auth0Client) => {
  const start = performance.now();

  client
    .logout({
      logoutParams: { returnTo: process.env.REACT_APP_SIGMUND_URL },
    })
    .catch((err) => {
      console.error('Exception while logging out Auth0 User', err);
    });

  const end = performance.now();
  Sentry.metrics.distribution('redirect_to_auth_logout_time', end - start, {
    unit: 'millisecond',
  });
};

export const redirectToMustResetPassword = async () => {
  window.location.href = `/auth/reset-password?must_reset=true`;
};

export const Auth0ClientContext = React.createContext<Auth0Client | null>(null);

export function useAuth0Client(): Auth0Client {
  const client = React.useContext(Auth0ClientContext);
  if (!client) {
    throw new Error(
      'useAuth0Client must be used within an Auth0ClientContext.Provider'
    );
  }
  return client;
}
