import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M204.373 74.56v81.6c0 2.347-1.92 4.373-4.373 4.373s-4.373-1.92-4.373-4.373V77.44H75.413c-2.347 0-4.373-1.92-4.373-4.373s1.92-4.373 4.373-4.373h123.093c3.2 0 5.76 2.56 5.76 5.76zm-23.147 23.147v80.107c0 3.2-2.56 5.76-5.76 5.76H53.76c-3.2 0-5.76-2.56-5.76-5.76V97.707c0-3.2 2.56-5.76 5.76-5.76h121.707c3.2 0 5.76 2.56 5.76 5.76m-8.64 2.88H56.64v74.347h115.84v-74.347z" />,
  ],
]);
export const IconLicenseType: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="45 60 162 130" {...props} weights={weights} />
));

IconLicenseType.variants = ['outlined'];
IconLicenseType.defaultVariant = 'outlined';
