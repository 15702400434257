import { getLocalTimeZone, today } from '@internationalized/date';
import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PanelLayout } from '~/legacy/layouts/PanelLayout';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { ContentText } from '@headway/helix/ContentText';
import { LinkButton } from '@headway/helix/LinkButton';
import { Pagination } from '@headway/helix/Pagination';
import { SearchField } from '@headway/helix/SearchField';
import useFuzzy from '@headway/ui/form/useFuzzy';

import { SessionDisclosure } from './SessionDisclosure';

const PROVIDERS_PER_PAGE = 5;

/**
 * To better facilitate an eventual Remix migration, we use a fake "loader" so we can structure
 * code as if we were receiving server-side data.
 */
const useFauxLoaderData = () => {
  const [searchParams] = useSearchParams();
  const timezone = getLocalTimeZone();
  // Memoize the current date so it doesn't change until the component unmounts.
  const currentDate = useMemo(() => today(timezone).toString(), [timezone]);
  const selectedDate = searchParams.get('date') || currentDate;
  const AuthStore = useAuthStore();
  const groupPracticeId = AuthStore.user?.group_practice?.id;

  const { data: sessionsByProvider } = useQuery(
    ['practice-billing', selectedDate, timezone],
    () => {
      return GroupPracticeApi.getSessionsByProvider(groupPracticeId, {
        date: selectedDate,
        timezone,
      });
    },
    {
      select: (result) => result.summariesByProvider,
    }
  );

  if (!sessionsByProvider) {
    return null;
  }

  return {
    selectedDate,
    sessionsByProvider,
  };
};

export const Billing = () => {
  const data = useFauxLoaderData();

  return (
    <PanelLayout>{data ? <BillingContent data={data} /> : null}</PanelLayout>
  );
};

/** Exported for testing only. */
export const BillingContent = ({
  data,
}: {
  data: NonNullable<ReturnType<typeof useFauxLoaderData>>;
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const providerSessionData = Object.values(data.sessionsByProvider).map(
    (sessions) => ({
      provider: {
        id: sessions[0].providerId,
        displayFirstName: sessions[0].providerDisplayFirstName,
        displayLastName: sessions[0].providerDisplayLastName,
        prenomial: sessions[0].providerPrenomial,
      },
      sessions,
    })
  );

  const sortedProviderSessionData = sortBy(
    providerSessionData,
    ({ sessions }) =>
      // Providers with unconfirmed sessions appear first. Then, sort alphabetically. Providers
      // with no sessions are already filtered from the API.
      !sessions?.find(
        (sessionSummary) =>
          sessionSummary.providerAppointmentStatus ===
          ProviderAppointmentStatus.SCHEDULED
      ),
    ({ provider }) => provider.displayLastName,
    ({ provider }) => provider.displayFirstName
  );

  const { search } = useFuzzy(sortedProviderSessionData, {
    keys: [
      'provider.displayLastName',
      'provider.displayFirstName',
      'provider.prenomial',
    ],
  });

  const sortedFilteredProviderSessionData = searchQuery
    ? search(searchQuery)
    : sortedProviderSessionData;

  const providerSessionDataPerPage = chunk(
    sortedFilteredProviderSessionData,
    PROVIDERS_PER_PAGE
  );

  useEffect(() => {
    // Ensure the page is within bounds. This might be needed if data refreshes.
    if (page >= providerSessionDataPerPage.length) {
      setPage(providerSessionDataPerPage.length - 1);
    }
  }, [providerSessionDataPerPage.length, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    headerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
    handlePageChange(0);
  };

  return (
    <div className="flex-grow pb-10">
      <div
        className="flex scroll-mt-10 justify-between gap-5 py-5"
        ref={headerRef}
      >
        <h1>
          <ContentText variant="page-title">Billing</ContentText>
        </h1>
        {/** TODO(GP-16): Add datepicker */}
        <div className="grid max-w-[340px] flex-grow">
          <SearchField
            aria-label="Provider search"
            placeholder="Search for a provider..."
            onChange={handleSearchQueryChange}
          />
        </div>
      </div>
      {providerSessionData.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="space-y-5">
          {sortedFilteredProviderSessionData.length === 0 &&
            searchQuery !== '' && (
              <div className="mt-5 text-center">
                <ContentText variant="body">
                  No providers found. Try adjusting your search.
                </ContentText>
              </div>
            )}
          {(providerSessionDataPerPage[page] || []).map(
            ({ provider, sessions }) => (
              <SessionDisclosure
                key={provider.id}
                provider={provider}
                sessions={sessions}
              />
            )
          )}
          {providerSessionDataPerPage.length > 1 && (
            <div className="mt-5 flex justify-center px-4 py-2">
              <Pagination
                page={page + 1}
                onPageChange={(newPage) => handlePageChange(newPage - 1)}
                totalPages={providerSessionDataPerPage.length}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EmptyState = () => (
  <div className="flex flex-col items-center gap-4 rounded bg-system-backgroundGray py-8">
    <div className="text-center">
      <ContentText variant="body/medium">No sessions scheduled</ContentText>
      <div className="mt-2 max-w-[330px]">
        <ContentText color="foreground/secondary">
          Add sessions to bill, and we'll cover submitting the claims and
          charging your clients.
        </ContentText>
      </div>
    </div>

    <div className="flex flex-row">
      <LinkButton variant="secondary" href="/practice/billing/add-sessions">
        + Bill new sessions
      </LinkButton>
    </div>
  </div>
);
