import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M191.573 146.347V97.813c0-6.613-3.84-12.373-10.027-14.933a15.89 15.89 0 0 0-17.6 3.52l-81.28 81.28c-2.24 2.24-5.333 2.773-8.107 1.6a7.2 7.2 0 0 1-4.587-6.933v-48.533c9.707-2.027 16.96-10.667 16.96-20.907 0-11.733-9.6-21.333-21.333-21.333s-21.333 9.6-21.333 21.333c0 10.24 7.36 18.88 16.96 20.907v48.533c0 6.613 3.84 12.373 10.027 14.933a16.2 16.2 0 0 0 6.293 1.28c4.16 0 8.213-1.6 11.307-4.8l81.28-81.28c2.24-2.24 5.333-2.773 8.107-1.6 2.88 1.173 4.587 3.733 4.587 6.933v48.533c-9.707 2.027-16.96 10.667-16.96 20.907 0 11.733 9.6 21.333 21.333 21.333s21.333-9.6 21.333-21.333c0-10.24-7.36-18.88-16.96-20.907M52.906 93.013c0-6.933 5.653-12.693 12.693-12.693s12.693 5.653 12.693 12.693-5.653 12.693-12.693 12.693-12.693-5.653-12.693-12.693m134.293 86.933c-6.933 0-12.693-5.653-12.693-12.693s5.653-12.693 12.693-12.693 12.693 5.653 12.693 12.693-5.653 12.693-12.693 12.693" />,
  ],
]);
export const IconLifeExperience: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="44 46 166 166" {...props} weights={weights} />
));

IconLifeExperience.variants = ['outlined'];
IconLifeExperience.defaultVariant = 'outlined';
