import { useCallback, useEffect, useMemo, useState } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useAllPatientAssessments } from './useAllPatientAssessments';
import {
  UseAssessmentResultPdfBaseParams,
  useAssessmentResultsPdf,
} from './useAssessmentResultsPdf';

type UseAllAssessmentsPdfParams = UseAssessmentResultPdfBaseParams & {
  providerPatient?: ProviderPatientRead;
};

const ALL_ASSESSMENTS_PDF_MESSAGES = {
  loading: "Preparing your download... please don't refresh the page",
  downloading: 'Your assessments are downloading',
  error:
    'Error when preparing download. Please refresh the page and try again.',
};

export const useAllAssessmentsPdf = ({
  providerPatient,
  ...params
}: UseAllAssessmentsPdfParams) => {
  // Track separate loading state to include time for querying for assessments,
  // and avoid flash of not-loading between query end and pdf generation start
  const [loading, setLoading] = useState(false);

  const filename = useMemo(() => {
    return params.client
      ? `${formatPatientName(params.client).replaceAll(
          ' ',
          '_'
        )}_All_Assessments.pdf`
      : undefined;
  }, [params.client]);

  const [{ assessments, error }, queryAssessments] =
    useAllPatientAssessments(providerPatient);

  const [pdfState, triggerPdf] = useAssessmentResultsPdf(
    {
      ...params,
      assessments,
      filename,
      toastMessages: ALL_ASSESSMENTS_PDF_MESSAGES,
    },
    { error, loading }
  );

  useEffect(() => {
    if (pdfState.error) {
      setLoading(false);
    }
  }, [pdfState.error]);

  useEffect(() => {
    if (!loading) {
      return;
    }

    if (!assessments) {
      queryAssessments();
      return;
    }

    triggerPdf();

    if (pdfState.pdf) {
      setLoading(false);
    }
  }, [assessments, loading, pdfState.pdf, queryAssessments, triggerPdf]);

  const trigger = useCallback(() => {
    setLoading(true);
  }, []);

  return [pdfState, trigger] as const;
};
