import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AppointmentConfirmationModalFormV2Values } from '~/legacy/views/Calendar/components/AppointmentConfirmation/AppointmentConfirmationModalV2';

import { ValidationState } from '@headway/helix/forms';

import { getValidationProps } from '../../../utils';

interface FormFieldWrapperProps {
  name: string;
  disabled?: boolean;
  children: (props: {
    onChange: (value: any) => void;
    onBlur: () => void;
    value: any;
    validation: ValidationState;
    disabled?: boolean;
    name: string;
  }) => React.ReactElement;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  name,
  disabled,
  children,
}) => {
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();

  return (
    <Controller
      name={`progressNote.${name}`}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) =>
        children({
          onChange,
          onBlur,
          value,
          validation: getValidationProps(error),
          disabled,
          name: `progressNote.${name}`,
        })
      }
    />
  );
};
