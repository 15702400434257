import React from 'react';

import { ContentText, ContentTextProps } from './ContentText';

export interface SubBodyTextProps {
  children: React.ReactNode;
  color?: 'textBlack' | 'gray' | 'disabledGray' | 'red' | 'white';
}

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const SubBodyText = ({
  children,
  color = 'textBlack',
}: SubBodyTextProps) => {
  return (
    <ContentText variant="body-small" color={COLOR_MAP[color]}>
      {children}
    </ContentText>
  );
};

const COLOR_MAP: Record<
  NonNullable<SubBodyTextProps['color']>,
  ContentTextProps['color']
> = {
  textBlack: 'foreground/primary',
  gray: 'foreground/secondary',
  disabledGray: 'foreground/disabled',
  red: 'foreground/danger',
  white: 'foreground/inverted-primary',
};
