import { Font } from '@react-pdf/renderer';

import PostGroteskBold from '@headway/shared/assets/fonts/PostGrotesk-Bold.woff';
import PostGrotesk from '@headway/shared/assets/fonts/PostGrotesk-Book.woff';
import PostGroteskLight from '@headway/shared/assets/fonts/PostGrotesk-Light.woff';
import PostGroteskMedium from '@headway/shared/assets/fonts/PostGrotesk-Medium.woff';

export const registerPdfFonts = () => {
  Font.register({
    family: 'PostGrotesk',
    fonts: [
      { src: PostGrotesk },
      { src: PostGroteskMedium, fontWeight: 'medium' },
      { src: PostGroteskLight, fontWeight: 'light' },
      { src: PostGroteskBold, fontWeight: 'bold' },
    ],
  });
};
