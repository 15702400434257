import { ShareNetwork } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconShareNetwork: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={ShareNetwork}
    ref={ref}
  />
));

IconShareNetwork.variants = ['outlined', 'filled'];
IconShareNetwork.defaultVariant = 'outlined';
