import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  ISISubmission,
  ISISubmissionAssessmentType,
} from '@headway/api/models/ISISubmission';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { PdfText } from '@headway/ui/pdf';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsByQuestionSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const ISI_OPTIONS_BY_QUESTION: OptionsByQuestionSchema = {
  ISI_1: [
    {
      displayText: 'None',
      value: '0',
    },
    {
      displayText: 'Mild',
      value: '1',
    },
    {
      displayText: 'Moderate',
      value: '2',
    },
    {
      displayText: 'Severe',
      value: '3',
    },
    {
      displayText: 'Very severe',
      value: '4',
    },
  ],
  ISI_2: [
    {
      displayText: 'None',
      value: '0',
    },
    {
      displayText: 'Mild',
      value: '1',
    },
    {
      displayText: 'Moderate',
      value: '2',
    },
    {
      displayText: 'Severe',
      value: '3',
    },
    {
      displayText: 'Very severe',
      value: '4',
    },
  ],
  ISI_3: [
    {
      displayText: 'None',
      value: '0',
    },
    {
      displayText: 'Mild',
      value: '1',
    },
    {
      displayText: 'Moderate',
      value: '2',
    },
    {
      displayText: 'Severe',
      value: '3',
    },
    {
      displayText: 'Very severe',
      value: '4',
    },
  ],
  ISI_4: [
    {
      displayText: 'Very satisfied',
      value: '0',
    },
    {
      displayText: 'Satisfied',
      value: '1',
    },
    {
      displayText: 'Moderately satisfied',
      value: '2',
    },
    {
      displayText: 'Dissatisfied',
      value: '3',
    },
    {
      displayText: 'Very dissatisfied',
      value: '4',
    },
  ],
  ISI_5: [
    {
      displayText: 'Not at all noticeable',
      value: '0',
    },
    {
      displayText: 'A little',
      value: '1',
    },
    {
      displayText: 'Somewhat',
      value: '2',
    },
    {
      displayText: 'Much',
      value: '3',
    },
    {
      displayText: 'Very much noticeable',
      value: '4',
    },
  ],
  ISI_6: [
    {
      displayText: 'Not at all worried',
      value: '0',
    },
    {
      displayText: 'A little',
      value: '1',
    },
    {
      displayText: 'Somewhat',
      value: '2',
    },
    {
      displayText: 'Much',
      value: '3',
    },
    {
      displayText: 'Very much worried',
      value: '4',
    },
  ],
  ISI_7: [
    {
      displayText: 'Not at all interfering',
      value: '0',
    },
    {
      displayText: 'A little',
      value: '1',
    },
    {
      displayText: 'Somewhat',
      value: '2',
    },
    {
      displayText: 'Much',
      value: '3',
    },
    {
      displayText: 'Very much interfering',
      value: '4',
    },
  ],
};

const ISI_QUESTIONS: QuestionsSchema = [
  { key: 'ISI_1', questionText: 'Difficulty Falling Asleep' },
  { key: 'ISI_2', questionText: 'Difficulty Staying Asleep' },
  { key: 'ISI_3', questionText: 'Problems Waking Up Too Early' },
  {
    key: 'ISI_4',
    questionText:
      'How SATISFIED/DISSATISFIED are you with your CURRENT sleep pattern?',
  },
  {
    key: 'ISI_5',
    questionText:
      'How NOTICEABLE to others do you think your sleep problem is in terms of impairing the quality of your life?',
  },
  {
    key: 'ISI_6',
    questionText:
      'How WORRIED/DISTRESSED are you about your current sleep problem?',
  },
  {
    key: 'ISI_7',
    questionText:
      'To what extent do you consider your sleep problem to INTERFERE with your daily functioning (e.g. daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.) CURRENTLY?',
  },
];

const INSTRUCTION =
  'Please rate the severity of your insomnia problem(s) over the past 2 weeks.';

const ISI_VALIDATION_SCHEMA = Yup.object().shape(
  ISI_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type ISIFormValues = {
  [key in keyof Omit<ISISubmission, 'assessmentType'>]: string;
};

export const ISIAssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: ISIFormValues) => {
    return onSubmit({
      assessmentType: ISISubmissionAssessmentType.ISI,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<ISIFormValues>
      onSubmit={innerOnSubmit}
      validationSchema={ISI_VALIDATION_SCHEMA}
      initialValues={{
        ISI_1: '',
        ISI_2: '',
        ISI_3: '',
        ISI_4: '',
        ISI_5: '',
        ISI_6: '',
        ISI_7: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={ISI_QUESTIONS}
            optionsByQuestion={ISI_OPTIONS_BY_QUESTION}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyISIAssessment = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={ISI_QUESTIONS}
        optionsByQuestion={ISI_OPTIONS_BY_QUESTION}
        response={scorableResponseJson}
      />
    </section>
  );
};

export const PdfISIAssessment = (props: ReadonlyAssessmentProps) => {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <PdfText style={{ fontWeight: 'medium' }}>{INSTRUCTION}</PdfText>
      <PdfScorableQuestionList
        questions={ISI_QUESTIONS}
        optionsByQuestion={ISI_OPTIONS_BY_QUESTION}
        response={props.response?.scorableResponseJson}
      />
    </View>
  );
};
