import { Box, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import { omitBy, uniq } from 'lodash';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { Practice } from '@headway/api/models/Practice';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { BodyText } from '@headway/helix/BodyText';
import { PageHeader } from '@headway/helix/PageHeader';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import {
  abbreviationToStateEnum,
  statesToDisplayNames,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import {
  useIsPanelabilityEnabledForNewProviders,
  useIsPanelabilityIntakeFormEnabled,
} from '@headway/shared/hooks/useIsPanelabilityIntakeFormEnabled';
import { useRegionalRates } from '@headway/shared/hooks/useRegionalRates';
import { useQuery, useQueryClient } from '@headway/shared/react-query';
import { ConsolidatedRatesByState } from '@headway/shared/types/rates';
import { isExistingProvider } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { IroncladAgreementCheckbox } from 'components/IroncladAgreementCheckbox';
import { getQueryKeyAndSigner } from 'hooks/useIroncladAgreementInfo';
import { useProviderPanelabilityEvaluation } from 'hooks/useProviderPanelabilityEvaluation';
import { useRates } from 'hooks/useRates';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess, isGroupAdmin } from 'utils/access';
import { getListFormatter } from 'utils/formatters';
import { excludeNonPanelableRates } from 'views/IntakeQuestionnaireV2/steps/Rates/excludeNonPanelableRates';
import {
  GroupPracticeAdminRatesDisclaimer,
  GroupPracticeProviderGuidanceCard,
} from 'views/IntakeQuestionnaireV2/steps/Rates/GroupPracticeProviderGuidanceCard';
import { RatesTableContainer } from 'views/LegalV2/Rates/RatesContainer';
import { RatesInfoButtonAndModal } from 'views/LegalV2/Rates/RatesInfoButtonAndModal';
import { PanelabilitySummary } from 'views/Panelability/PanelabilitySummary';

import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import {
  getIsGPNonAdminAddingNewStates,
  getNewlySelectedPracticeStates,
} from '../../utils/helpers';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';

const getRateLocationInfo = (
  mailingZip: string | undefined,
  practiceLocation: Practice | undefined
) => {
  if (practiceLocation) {
    return {
      is_telehealth: false,
      zip_code: practiceLocation.zip,
    };
  } else {
    return {
      is_telehealth: true,
      zip_code: mailingZip,
    };
  }
};

export const Rates = () => {
  const { provider, providerQuestionnaire } = useQuestionnaireContext();
  const { carriersById } = useFrontEndCarriers();
  const authStore = useAuthStore();
  const formik = useFormikContext();
  const queryClient = useQueryClient();
  const shouldUseIroncladAmendments = useFlag('ironcladProviderAmendments');

  const providerSelectedPracticeStates =
    providerQuestionnaire.rawData?.providerSelectedPracticeStates;
  const practice = providerQuestionnaire.rawData?.practice;
  const stateOfResidency = providerQuestionnaire.rawData?.stateOfResidency;

  const shouldUseProviderPanelability = useIsPanelabilityIntakeFormEnabled(
    providerSelectedPracticeStates,
    provider
  );

  const isProviderPanelabilityEnabledForNewProviders =
    useIsPanelabilityEnabledForNewProviders(
      providerSelectedPracticeStates,
      provider
    );

  const isProviderExisting = isExistingProvider(provider);
  const isGroupPractice = !!authStore.user.group_practice;
  const {
    rates: currentRates,
    carriers: providerFeeScheduleCarriers,
    isLoading,
  } = useRates(provider, isGroupPractice);
  const { data: rateInfo } = useRegionalRates({
    regionalRateRequests:
      providerSelectedPracticeStates?.map((state) => {
        const primaryPracticeLocation = practice?.find(
          (p) =>
            abbreviationToStateEnum[p.state] === state &&
            p.isPrimaryPracticeForState
        );

        return {
          state,
          ...getRateLocationInfo(
            providerQuestionnaire.rawData?.zip,
            primaryPracticeLocation
          ),
        };
      }) ?? [],
    carriersById,
    providerType: provider.providerType!,
    providerResidenceState: stateOfResidency
      ? (abbreviationToStateEnum[stateOfResidency] as UnitedStates)
      : undefined,
    isLiveProvider:
      !!provider.earliestActiveLiveOn &&
      new Date(provider.earliestActiveLiveOn).getTime() < Date.now(),
  });
  const { data: currentCarriers, isLoading: isCarriersLoading } = useQuery(
    ['Provider ID', provider.id],
    async () => {
      const response =
        await ProviderFrontEndCarrierApi.getProviderFrontEndCarriers({
          provider_id: provider.id,
        });
      return response.map((item) => item.frontEndCarrier.name);
    }
  );
  const {
    data: providerPanelabilityEvaluation,
    isLoading: isProviderPanelabilityEvaluationLoading,
  } = useProviderPanelabilityEvaluation(
    { providerQuestionnaire },
    { enabled: shouldUseProviderPanelability }
  );

  useEffect(() => {
    queryClient.invalidateQueries(
      getQueryKeyAndSigner(
        provider,
        authStore.user,
        shouldUseIroncladAmendments
      )
    );
  }, []);

  const currentRatesWithRemovedEmpty: ConsolidatedRatesByState = currentRates
    ? omitBy(currentRates, (value) => Object.keys(value).length === 0)
    : {};
  let rates: ConsolidatedRatesByState;
  let carriers: string[] = [];
  if (isProviderExisting) {
    rates = Object.assign(
      {},
      rateInfo?.rates ?? {},
      currentRatesWithRemovedEmpty
    );
    carriers =
      uniq(
        rateInfo?.names
          .map(([name, carrier]) =>
            currentCarriers?.includes(name) ? carrier : ''
          )
          .filter(Boolean)
      ) ?? [];
  } else {
    rates = currentRates;
    carriers = providerFeeScheduleCarriers;
  }

  if (
    (shouldUseProviderPanelability && isProviderExisting) ||
    (isProviderPanelabilityEnabledForNewProviders && !isProviderExisting)
  ) {
    rates = excludeNonPanelableRates(
      provider,
      rates,
      carriersById,
      providerPanelabilityEvaluation ?? {
        stateInsuranceCarrierPanelabilityEvaluations: [],
      },
      providerQuestionnaire.rawData?.deselectedPfecIds || []
    );
  }

  const newlySelectedPracticeStates = getNewlySelectedPracticeStates(
    provider,
    providerQuestionnaire.rawData?.providerSelectedPracticeStates ?? []
  );

  const isGpNonAdminAddingNewStates = getIsGPNonAdminAddingNewStates(
    provider,
    authStore.user,
    newlySelectedPracticeStates
  );

  const listFormatter = getListFormatter('en-US');

  const formattedStatesList = listFormatter.format(
    newlySelectedPracticeStates.map(
      (state: UnitedStates) => statesToDisplayNames[state]
    )
  );

  return (
    <>
      <div className="flex flex-col items-center p-4 ">
        <div className="flex max-w-[1000px] flex-col ">
          <div
            css={{
              marginTop: theme.spacing.x8,
              marginBottom: theme.spacing.x5,
            }}
          >
            <PageHeader>Insurance plans and rates</PageHeader>
          </div>
          {shouldUseProviderPanelability && (
            <>
              <PanelabilitySummary
                provider={provider}
                user={authStore.user}
                providerQuestionnaire={providerQuestionnaire}
                providerPanelabilityEvaluation={providerPanelabilityEvaluation}
                isProviderPanelabilityEvaluationLoading={
                  isProviderPanelabilityEvaluationLoading
                }
              />
              <Box height={theme.spacing.x5} />
            </>
          )}
          {hasRateAccess(provider, authStore.user) && (
            <>
              <h2
                css={{
                  marginTop: theme.spacing.x8,
                  marginBottom: theme.spacing.x2,
                }}
              >
                <SectionHeader>
                  {isGroupAdmin(authStore.user) ? `Group Rates` : `Your rates`}
                </SectionHeader>
              </h2>
              <p>
                <BodyText>
                  These are your enhanced rates based on what we’ve learned
                  about your practice.
                </BodyText>
              </p>
              <p css={{ marginBottom: theme.spacing.x4 }}>
                <RatesInfoButtonAndModal />
              </p>
              <div className="items-center">
                {Object.keys(rates).length > 0 &&
                !isLoading &&
                !isCarriersLoading ? (
                  <div className="relative min-w-[100px] max-w-[1000px]">
                    <RatesTableContainer
                      rates={rates}
                      user={authStore?.user}
                      provider={provider}
                      carriers={carriers}
                    />
                  </div>
                ) : (
                  <div className="mb-4">
                    <Skeleton variant="rectangular" width={1000} height={500} />
                  </div>
                )}
              </div>
            </>
          )}
          {isProviderExisting && (
            <>
              {isGpNonAdminAddingNewStates ? (
                <GroupPracticeProviderGuidanceCard
                  isProviderExisting={isProviderExisting}
                  formattedStatesList={formattedStatesList}
                />
              ) : (
                <div className="mt-12 grid gap-2">
                  <h2>
                    <SectionHeader>Headway Agreement</SectionHeader>
                  </h2>
                  {isGroupAdmin(authStore.user) &&
                    newlySelectedPracticeStates.length && (
                      <GroupPracticeAdminRatesDisclaimer
                        providerDisplayFirstName={provider.displayFirstName}
                        formattedStatesList={formattedStatesList}
                      />
                    )}
                  <IroncladAgreementCheckbox
                    formik={formik}
                    agreementFieldName="providerAgreementAttested"
                    isAdminAcceptingNewStateAddendums={
                      !!newlySelectedPracticeStates.length
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const stepConfig: QuestionnaireV2Step = {
  title: 'Insurance plans and rates',
  Component: Rates,
  css: {
    mainContainer: { flex: '1 1 auto' },
    stepContainer: {},
    formikContainer: {},
    submissionContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing.x9,
      marginBottom: theme.spacing.x10,
    },
    submissionButtonContainer: {
      width: '1000px',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing.x4,
      gap: theme.spacing.x4,
      [theme.__futureMedia.below('desktop')]: {
        maxWidth: '1000px',
        width: '100%',
      },
    },
  },

  onBeforeSubmit: (values) => {
    // We don't want to store the attestation in rawData
    delete (values as any).providerAgreementAttested;

    return values;
  },
  getFormMeta: (context) => {
    const validationSchema = Yup.object().shape({
      providerAgreementAttested: isExistingProvider(context.provider)
        ? Yup.bool().oneOf([true], 'Must confirm attestation!')
        : Yup.bool().notRequired(),
    });

    return {
      validationSchema,
      initialValue: yupSchemaToDefaultValue(validationSchema),
    };
  },
};
