import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { BodyText } from '@headway/helix/BodyText';
import { Item } from '@headway/helix/collections';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconMoney } from '@headway/helix/icons/Money';
import { LinkButton } from '@headway/helix/LinkButton';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { PageHeader } from '@headway/helix/PageHeader';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { theme } from '@headway/helix/theme';
import { PiggyBank } from '@headway/icons/dist/provider/PiggyBank';
import {
  PROMS_ONLY_RATE_BOOST,
  PROVIDER_REFERRAL_PROGRAM_AWARENESS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useInsuranceStatus } from '@headway/shared/hooks/useInsuranceStatus';
import { PayerStatus } from '@headway/shared/types/payerStatus';
import { trackEvent } from '@headway/shared/utils/analytics';
import { convertListToSentence } from '@headway/shared/utils/strings';

import { EmptyView } from 'components/EmptyView';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';
import { useProvider } from 'hooks/useProvider';
import { useProviderPayments } from 'hooks/useProviderPayments';
import { PanelLayout } from 'layouts/PanelLayout';
import {
  INCENTIVE_BONUS_AMOUNT_DOLLARS,
  RateBoostBannerCopyVariant,
} from 'views/Incentives/constants';

import DeniedPaymentsTab from './DeniedPaymentsTab';
import HistoryTab from './HistoryTab';
import UpcomingTab from './UpcomingTab';
import UpcomingTabWithIncentives from './UpcomingTabWithIncentives';

export default function Payments() {
  const navigate = useNavigate();
  const location = useLocation();
  const provider = useProvider();
  const onboardingStatuses = useOnboardingModuleStatuses();
  const { payerStatusMap } = useInsuranceStatus(provider);

  const path = location.pathname;

  const isPausedPaymentsFlagEnabled = useFlag('pausedPayments', false);
  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  let showReferralProgramAwarenessBanner =
    useFlag(PROVIDER_REFERRAL_PROGRAM_AWARENESS, 'Control') !== 'Control';

  const [
    isViewIncentiveCardGuidanceCard,
    setIsViewingIncentiveCardGuidanceCard,
  ] = useState(false);
  const {
    data: providerEnrollmentSummary,
    isLoading: isEnrollmentSummaryLoading,
  } = useProviderIncentiveProgramEnrollment({
    providerId: provider.id,
  });

  const { data: deniedPayments } = useProviderPayments(
    {
      providerId: provider?.id,
      providerPaymentStatusEnums: [ProviderPaymentStatus.DENIED],
      isPausedPaymentsFlagEnabled,
    },
    { refetchOnWindowFocus: false }
  );
  const hasDeniedPayments =
    isPausedPaymentsFlagEnabled &&
    deniedPayments &&
    deniedPayments.data.length > 0;

  const isPaymentSetup = onboardingStatuses?.data?.find(
    ({ module }) => module === ProviderModule.PAYMENT
  )?.isComplete;

  const showIncentiveGuidanceCard =
    providerEnrollmentSummary?.isProviderEnrolled &&
    !payerStatusMap.has(PayerStatus.APPOINTMENT_READY) &&
    isPaymentSetup;

  useEffect(() => {
    if (showIncentiveGuidanceCard) {
      trackEvent({
        name: 'Rate Boost Banner Viewed',
        properties: {
          copyVariant: RateBoostBannerCopyVariant.LARGE_GUIDANCE_CARD,
        },
      });
    }
  }, [showIncentiveGuidanceCard]);

  if (!isPaymentSetup) {
    const comingSoonMessage = {
      description: (
        <>
          Complete your account setup and connect your bank info to receive
          referrals and bimonthly session payments.
          <br />
          <br />
          You'll be able to keep track of your payments on this page.
        </>
      ),
      cta: '',
    };

    const hasCompletedModule = onboardingStatuses?.data?.find(
      ({ isComplete }) => isComplete === true
    );
    const hasInProgressModule = onboardingStatuses?.data?.find(
      ({ wizard }) => wizard?.currentStep != null && wizard?.completedOn == null
    );

    if (hasCompletedModule || hasInProgressModule) {
      comingSoonMessage.cta = 'Continue set up';
    } else {
      comingSoonMessage.cta = 'Set up now';
    }

    return (
      <PanelLayout>
        <EmptyView
          title="Payments coming soon"
          description={comingSoonMessage.description}
          action={<LinkButton href="/home">{comingSoonMessage.cta}</LinkButton>}
        />
      </PanelLayout>
    );
  }

  const currentTab = path.match(/\/payments\/(\w+)$/)?.[1];
  if (
    (currentTab !== 'upcoming' &&
      currentTab !== 'history' &&
      currentTab !== 'denied') ||
    (currentTab === 'denied' && !hasDeniedPayments)
  ) {
    return <Navigate replace to="/payments/upcoming" />;
  }

  const isProviderRateEligible =
    !isEnrollmentSummaryLoading &&
    providerEnrollmentSummary &&
    providerEnrollmentSummary?.totalIncentiveAmountEarnedInCents > 0;

  let banner = null;

  if (payerStatusMap.has(PayerStatus.APPOINTMENT_READY)) {
    banner = (
      <div css={{ margin: theme.spacing.x4 }}>
        <GuidanceCard variant="warning">
          {`Reminder: You’ll be paid once you’ve been added to ${convertListToSentence(
            Array.from(
              payerStatusMap.get(PayerStatus.APPOINTMENT_READY)!.keys()
            )
          )}’s systems. This is why you’ll see a delay in your upcoming payment dates.`}
        </GuidanceCard>
      </div>
    );
  } else if (
    !payerStatusMap.has(PayerStatus.APPOINTMENT_READY) &&
    providerEnrollmentSummary?.isProviderEnrolled
  ) {
    banner = (
      <div className="flex max-w-[843px] flex-col gap-3 rounded border-[1px] border-solid border-primary-blue bg-hue-lightBlue px-4 py-3">
        <div className="m-2 flex flex-grow flex-col gap-5">
          <div className="flex justify-between">
            <div className="align-center flex gap-3">
              <PiggyBank />
              <div className="max-w-[500px]">
                <BodyText>
                  <strong>
                    {isProviderRateEligible
                      ? `You've earned ${providerEnrollmentSummary?.numAptsMetRequirements} rate boosts ${
                          promsOnlyRateBoostEnabled
                            ? 'for the upcoming payment period'
                            : 'so far!'
                        }`
                      : promsOnlyRateBoostEnabled
                      ? `Send assessments to unlock a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} session rate boost:`
                      : `Meet both criteria below to unlock a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} session rate boost:`}
                  </strong>
                </BodyText>
              </div>
            </div>
            <LinkButton
              variant="link"
              onPress={() =>
                setIsViewingIncentiveCardGuidanceCard(
                  !isViewIncentiveCardGuidanceCard
                )
              }
            >
              <strong>
                {isViewIncentiveCardGuidanceCard ? 'Hide' : 'View'}
              </strong>
            </LinkButton>
          </div>
          {isViewIncentiveCardGuidanceCard && (
            <div>
              <BodyText>
                <strong>
                  {isProviderRateEligible
                    ? promsOnlyRateBoostEnabled
                      ? `You’re earning a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost for every session`
                      : `Get $${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boosts for any sessions meeting both criteria below:`
                    : promsOnlyRateBoostEnabled
                    ? `Earn a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost for every session`
                    : 'Unlock rate boosts by meeting both criteria below:'}
                </strong>
                {promsOnlyRateBoostEnabled ? (
                  isProviderRateEligible ? (
                    <p className="mt-2">
                      <BodyText>
                        When your client completes an assessment once every 30
                        days, you earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                        <strong>each and every session</strong> with that client
                        — on top of your standard rate.
                      </BodyText>
                    </p>
                  ) : (
                    <p className="mt-2">
                      <BodyText>
                        Have your client complete an assessment once every 30
                        days and earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                        <strong>each and every session</strong> with that client
                        — on top of your standard rate.
                      </BodyText>
                    </p>
                  )
                ) : (
                  <NumberedList>
                    <NumberedListItem>
                      A client in Michigan completes a Headway assessment 30
                      days before or after a session
                    </NumberedListItem>
                    <NumberedListItem>
                      You upload a copy of your note on Headway or use Headway
                      templates for that session
                    </NumberedListItem>
                  </NumberedList>
                )}
                <div className="pt-5">
                  <LinkButton
                    variant="primary"
                    href={'/incentives/rate-boost'}
                    target="_blank"
                    rel="noreferrer"
                    onPress={() =>
                      trackEvent({
                        name: 'Learn More Rate Boost Button Clicked',
                      })
                    }
                  >
                    Learn more
                  </LinkButton>
                </div>
              </BodyText>
            </div>
          )}
        </div>
      </div>
    );
  } else if (showReferralProgramAwarenessBanner) {
    banner = (
      <GuidanceCard variant="neutral" layout="horizontal" icon={<IconMoney />}>
        <BodyText>
          <strong>Refer a provider: </strong>
          For every provider you refer, you get $350 and they get $100. Terms
          apply.
        </BodyText>
        <LinkButton
          component={Link}
          elementType="a"
          to="/refer-a-provider"
          variant="link"
        >
          Learn more
        </LinkButton>
      </GuidanceCard>
    );
  }

  return (
    <PanelLayout>
      <div css={paymentsCss.container}>
        <div css={paymentsCss.header}>
          <PageHeader>Payments</PageHeader>
          <BodyText>
            You'll get paid in bulk on the 15th and the last day of the month.{' '}
            <LinkButton
              href="https://findheadway.zendesk.com/hc/en-us/articles/4416374825748-Getting-paid"
              target="_blank"
              rel="noreferrer"
              variant="link"
            >
              Learn more
            </LinkButton>
          </BodyText>
        </div>

        {banner}
        {hasDeniedPayments ? (
          <Tabs
            selectedKey={currentTab}
            onSelectionChange={(key) =>
              navigate(`/payments/${key}`, { replace: true })
            }
          >
            <TabList>
              <Item key="upcoming">Upcoming</Item>
              <Item key="history">History</Item>
              <Item key="denied">Denied payments</Item>
            </TabList>
            <TabPanels>
              <Item textValue="Upcoming" key="upcoming">
                {providerEnrollmentSummary?.isProviderEnrolled ? (
                  <UpcomingTabWithIncentives />
                ) : (
                  <UpcomingTab />
                )}
              </Item>
              <Item textValue="History" key="history">
                <HistoryTab />
              </Item>
              <Item textValue="Denied" key="denied">
                <DeniedPaymentsTab />
              </Item>
            </TabPanels>
          </Tabs>
        ) : (
          <Tabs
            selectedKey={currentTab}
            onSelectionChange={(key) =>
              navigate(`/payments/${key}`, { replace: true })
            }
          >
            <TabList>
              <Item key="upcoming">Upcoming</Item>
              <Item key="history">History</Item>
            </TabList>
            <TabPanels>
              <Item textValue="Upcoming" key="upcoming">
                {providerEnrollmentSummary?.isProviderEnrolled ? (
                  <UpcomingTabWithIncentives />
                ) : (
                  <UpcomingTab />
                )}
              </Item>
              <Item textValue="History" key="history">
                <HistoryTab />
              </Item>
            </TabPanels>
          </Tabs>
        )}
      </div>
    </PanelLayout>
  );
}

const paymentsCss = {
  container: css({
    ...theme.stack.vertical,
    paddingTop: theme.spacing.x5,
    paddingBottom: theme.spacing.x8,
    gap: theme.spacing.x5,
  }),
  header: css({
    ...theme.stack.vertical,
    gap: theme.spacing.x2,
  }),
};
