import { ShieldPlus } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconShieldPlus: Icon = forwardRef(
  (props, ref: React.Ref<SVGSVGElement>) => (
    <IconBase
      phosphorIcon={ShieldPlus}
      {...props}
      defaultVariant="filled"
      variants={['filled', 'outlined']}
      ref={ref}
    />
  )
);

IconShieldPlus.variants = ['filled', 'outlined'];
IconShieldPlus.defaultVariant = 'filled';
