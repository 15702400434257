import { css, cx } from '@emotion/css';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { UserRead } from '@headway/api/models/UserRead';
import { Checkbox } from '@headway/helix/Checkbox';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';

import { ProgressNoteState } from './stores/ProgressNotesContext';
import { ProgressNoteContext } from './stores/ProgressNotesContextV2';

export const getAppointmentAttestationInitialValues = () => {
  return false;
};

export const AppointmentAttestationFormV2 = ({
  patient,
}: {
  patient: UserRead;
  //progressNoteOnly: boolean;
}) => {
  const { progressNoteType, progressNoteState, event } =
    useContext(ProgressNoteContext);

  const { control } = useFormContext();

  const patientPayer =
    patient?.activeUserInsurance?.billingFrontEndCarrierName || 'insurance';

  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  // We don't need the attestation if we are editing a template,
  // writing an addendum, or if the time to edit session details has passed
  if (
    (progressNoteType === ProgressNoteType.TEMPLATE &&
      (progressNoteState === ProgressNoteState.EDITING ||
        progressNoteState === ProgressNoteState.ADDENDUM_EDITING)) ||
    progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT ||
    !canUpdateSessionDetails
  ) {
    return null;
  }

  /*return (
    <Formik
      initialValues={{ attestation: false }}
      validationSchema={Yup.object().shape({
        attestation: Yup.boolean()
          .oneOf([true], 'Box must be checked to continue')
          .required(),
      })}
      onSubmit={(_values: { attestation: boolean }) => {}}
    >
      {() => {
        return (
          <Form>
            {progressNoteType === ProgressNoteType.TEMPLATE &&
            progressNoteState === ProgressNoteState.ADDENDUM_EDITING ? (
              <div className={cx(attestationContainerCss)}>
                <SectionHeader>Declaration</SectionHeader>
                <div
                  id="attestation"
                  data-testid="attestationCheckbox"
                  className={cx(checkboxContainerFlagCss)}
                >
                  <FormControl component={Checkbox} name="attestation">
                    {`I understand that compliant notes are required for payment. My notes are
                    true, accurate and meet ${patientPayer}'s requirements, and I'll provide supplemental information
                    on request within 3 business days. Further, I attest that I have not made modifications to the original note content and have included only new information in the addendum.`}
                  </FormControl>
                </div>
              </div>
            ) : (
              <div className={cx(attestationContainerCss)}>
                <SectionHeader>Declaration</SectionHeader>
                <div
                  id="attestation"
                  data-testid="attestationCheckbox"
                  className={cx(checkboxContainerFlagCss)}
                >
                  <FormControl component={Checkbox} name="attestation">
                    {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide ${
                    progressNoteType === ProgressNoteType.NONE
                      ? 'my notes'
                      : 'supplemental information'
                  }
                  on request within 3 business days.`}
                  </FormControl>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );*/
  return (
    <div>
      {progressNoteType === ProgressNoteType.TEMPLATE &&
      progressNoteState === ProgressNoteState.ADDENDUM_EDITING ? (
        <div className={cx(attestationContainerCss)}>
          <SectionHeader>Declaration</SectionHeader>
          <div
            id="attestation"
            data-testid="attestationCheckbox"
            className={cx(checkboxContainerFlagCss)}
          >
            <Controller
              name="attestation"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  name="attestation"
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                >
                  {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide supplemental information
                  on request within 3 business days. Further, I attest that I have not made modifications to the original note content and have included only new information in the addendum.`}
                </Checkbox>
              )}
            />
          </div>
        </div>
      ) : (
        <div className={cx(attestationContainerCss)}>
          <SectionHeader>Declaration</SectionHeader>
          <div
            id="attestation"
            data-testid="attestationCheckbox"
            className={cx(checkboxContainerFlagCss)}
          >
            <Controller
              name="attestation"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  name="attestation"
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                >
                  {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide ${
                    progressNoteType === ProgressNoteType.NONE
                      ? 'my notes'
                      : 'supplemental information'
                  }
                  on request within 3 business days.`}
                </Checkbox>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const attestationContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: theme.spacing.x5,
});

const checkboxContainerFlagCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing.x5,
});
