import uniq from 'lodash/uniq';

import {
  CarrierNames,
  ConsolidatedRatesByState,
  FeeSchedule,
  RatesWithState,
} from '../types/rates';

export const getCarrierPlanDescription = (rate: FeeSchedule) => {
  return `${rate.front_end_carrier.name}${
    rate.plan_type ? ` ${rate.plan_type} plans` : ''
  }`;
};

export function getCarriers(ratesWithStates: RatesWithState[]) {
  return uniq(
    ratesWithStates
      .map((ratesWithState) =>
        ratesWithState.rates.map((rate) => getCarrierPlanDescription(rate))
      )
      .flat()
  );
}

export function getCarriersNames(ratesWithStates: RatesWithState[]) {
  return uniq(
    ratesWithStates
      .map((ratesWithState) =>
        ratesWithState.rates.map((rate) => [
          rate.front_end_carrier.name,
          getCarrierPlanDescription(rate),
        ])
      )
      .flat()
  ) as CarrierNames[];
}

/**
 * Consolidates the rates with state information structuring it as: state -> provider type -> cpt_code
 *
 * For a group practice this assumes that the rates for a specific payer in a state for each provider type is the same for all
 * providers in the group.
 */
export function consolidateRates(ratesWithState: RatesWithState[]) {
  return ratesWithState.reduce<ConsolidatedRatesByState>(
    (acc, rateWithState) => {
      const currentStateRates = acc[rateWithState.state] || {};
      const currentProviderType = rateWithState.providerType;

      const updatedStateRate = rateWithState.rates.reduce((acc, rate) => {
        const currentRatesForProviderType = acc[currentProviderType] || {};
        const currentCPTCodePlanAmounts =
          currentRatesForProviderType[rate.cpt_code] || {};

        return {
          ...acc,
          [currentProviderType]: {
            ...currentRatesForProviderType,
            [rate.cpt_code]: {
              ...currentCPTCodePlanAmounts,
              [getCarrierPlanDescription(rate)]: rate.amount,
            },
          },
        };
      }, currentStateRates);

      return {
        ...acc,
        [rateWithState.state]: updatedStateRate,
      };
    },
    {}
  );
}
