import {
  IconWeight,
  IconBase as PhosphorIconBase,
  Icon as PhosphorIconType,
} from '@phosphor-icons/react';
import React, { forwardRef } from 'react';
import { ComponentPropsWithoutRef, RefAttributes } from 'react';

import type { IconVariant } from './types';

export type IconBaseProps = ComponentPropsWithoutRef<'svg'> &
  RefAttributes<SVGSVGElement> & {
    /**
     * (Optional) Phosphor icon component if icon is part of phosphor library
     */
    phosphorIcon?: PhosphorIconType;
    /**
     * (Optional) The alt text for the icon
     */
    alt?: string;
    /**
     * (Optional) The color of the icon
     */
    color?: string;
    /**
     * (Optional) The size of the icon (px or em/rem)
     */
    size?: string | number;
    /**
     * (Optional) If using a custom icon, the weights (paths) of the icon
     */
    weights?: Map<IconWeight, JSX.Element>;
    /**
     * (Optional) The default variant of the icon (default: outlined)
     */
    defaultVariant?: IconVariant;
    /**
     * (Optional) The available variants of the icon
     * If not provided, the icon will only be available in the default variant
     * If provided, the icon will be available in the specified variants
     * and the default variant (options: 'outlined' | 'filled')
     */
    variants?: IconVariant[];
    /**
     * (Optional) The variant of the icon to render
     */
    variant?: IconVariant;
  };

const VARIANT_TO_WEIGHT: Record<IconVariant, IconWeight> = {
  outlined: 'regular',
  filled: 'fill',
};

export const IconBase = forwardRef<SVGSVGElement, IconBaseProps>(
  (
    {
      phosphorIcon: PhosphorIcon,
      weights,
      variants,
      variant,
      defaultVariant = 'outlined',
      ...props
    },
    ref
  ) => {
    const availableWeights = weights
      ? Array.from(weights.keys())
      : variants?.map((variant) => VARIANT_TO_WEIGHT[variant]) || [
          VARIANT_TO_WEIGHT[defaultVariant],
        ];

    if (props.string == 'IconCalendar') {
      console.log(availableWeights);
    }

    const weight = variant
      ? availableWeights.find(
          (weight) => weight === VARIANT_TO_WEIGHT[variant]
        ) || VARIANT_TO_WEIGHT[defaultVariant]
      : VARIANT_TO_WEIGHT[defaultVariant];

    if (PhosphorIcon) {
      return (
        <PhosphorIcon
          ref={ref}
          size={20}
          {...props}
          weight={weight}
          aria-hidden={!props.alt}
        />
      );
    }

    if (!weights || weights.size === 0) {
      throw new Error(
        'IconBase requires either a phosphorIcon or weights prop'
      );
    }

    return (
      <PhosphorIconBase
        ref={ref}
        size={20}
        {...props}
        weights={weights!}
        weight={weight}
        aria-hidden={!props.alt}
      />
    );
  }
);
