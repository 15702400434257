import { FrameCorners } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconFrameCorners: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={FrameCorners}
    ref={ref}
  />
));

IconFrameCorners.variants = ['outlined', 'filled'];
IconFrameCorners.defaultVariant = 'outlined';
