import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M192.907 199.189c1.045 1.045 2.091 1.045 3.136 1.045 1.056 0 2.101 -0.523 3.147 -1.045a4.117 4.117 0 0 0 0 -5.76l-67.061 -67.072V32.565a4.48 4.48 0 0 0 -4.192 -4.192 4.48 4.48 0 0 0 -4.192 4.192v95.36c0 1.056 0.523 2.101 1.045 3.147zM154.133 105.397c-1.045 0 -2.101 -0.533 -3.147 -1.045a4.107 4.107 0 0 1 0 -5.76l40.875 -40.875a4.117 4.117 0 0 1 5.76 0 4.107 4.107 0 0 1 0 5.76l-40.875 40.875c0 1.045 -1.045 1.045 -2.613 1.045m11.52 26.72h57.643a4.48 4.48 0 0 0 4.192 -4.192 4.48 4.48 0 0 0 -4.192 -4.192H165.653a4.48 4.48 0 0 0 -4.192 4.192c0 2.101 2.101 4.192 4.192 4.192M101.205 105.397c-1.045 0 -2.091 -0.533 -3.136 -1.045L57.195 63.467a4.107 4.107 0 0 1 0 -5.76 4.107 4.107 0 0 1 5.76 0l40.875 40.864a4.107 4.107 0 0 1 0 5.76c-0.533 1.067 -1.568 1.067 -2.624 1.067m-68.64 26.72h57.643a4.128 4.128 0 0 0 4.192 -4.192 4.48 4.48 0 0 0 -4.192 -4.192H32.565a4.48 4.48 0 0 0 -4.192 4.192c0 2.101 2.101 4.192 4.192 4.192m27.776 67.595c-1.056 0 -2.101 -0.523 -3.147 -1.045a4.107 4.107 0 0 1 0 -5.76l40.875 -40.875a4.107 4.107 0 0 1 5.76 0 4.107 4.107 0 0 1 0 5.76l-40.875 40.875a4.053 4.053 0 0 1 -2.613 1.045m63.403 23.573c0 2.101 2.091 4.203 4.192 4.203a4.48 4.48 0 0 0 4.192 -4.192V165.653a4.48 4.48 0 0 0 -4.192 -4.192 4.48 4.48 0 0 0 -4.192 4.192z" />,
  ],
]);
export const IconYearsOfExperience: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="28 28 200 200" {...props} weights={weights} />
));

IconYearsOfExperience.variants = ['outlined'];
IconYearsOfExperience.defaultVariant = 'outlined';
