import { useProvider } from 'hooks';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import {
  AddOrUpdateTreatmentPlanButtonClickedEvent,
  CompleteAndSignDraftButtonClickedEvent,
  CreateTreatmentPlanOrUploadExistingButtonClickedEvent,
  ShowTreatmentPlanButtonClickedEvent,
} from '@headway/avo';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import {
  PROGRESS_NOTES_LATE_ENTRY,
  PROGRESS_NOTES_SIGNATURE_RESTRICTIONS,
  PROMS_ONLY_RATE_BOOST,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { isDetailsConfirmed } from 'views/Calendar/events/util/events';
import RateBoostGuidanceCard from 'views/Incentives/RateBoostGuidanceCard';

import { AppointmentConfirmationModalFormV2Values } from '../../AppointmentConfirmationModalV2';
import {
  ProgressNotePermissions,
  ProgressNoteState,
} from '../../stores/ProgressNotesContext';
import { ProgressNoteContext } from '../../stores/ProgressNotesContextV2';
import { useProgressNoteRequirements } from './components/requirements/ProgressNoteRequirements';
import { ProgressNotesLateEntryInputV2 } from './ProgressNotesLateEntryInputV2';
import {
  GenericTemplate,
  NoteJson,
  TemplateInfo,
  TemplateValues,
} from './templateView/schemaRenderer/versions/types';
import Template, {
  getInitialTemplateValues,
} from './templateView/templateView';
import {
  alphabeticallySortTemplates,
  applyTemplateRolloutFilter,
  getTemplates,
} from './templateView/utils';
import { ProgressNoteComponentMetadata } from './types';
import { getRecommendedTemplateForCPTCode } from './utils';

interface ProgressNotesFormV2Props {
  patient: UserRead;
  providerPatient?: ProviderPatientRead;
}

export type ProgressNotesFormV2Values = {
  template?: string;
  progressNoteType?: ProgressNoteType;
  previousNote?: number;
  lateEntryReason?: ProviderProgressNoteLateEntryReason;
  lateEntryOtherReason?: string;
  [key: string]: any;
};

export type TemplateAnalyticsTypes =
  | AddOrUpdateTreatmentPlanButtonClickedEvent
  | CompleteAndSignDraftButtonClickedEvent
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent
  | ShowTreatmentPlanButtonClickedEvent;
export type TemplateAnalyticsNames =
  | AddOrUpdateTreatmentPlanButtonClickedEvent['name']
  | CompleteAndSignDraftButtonClickedEvent['name']
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent['name']
  | ShowTreatmentPlanButtonClickedEvent['name'];

const createTemplateIdAndVersionFormValue = (id: number, version: number) =>
  `${id}-${version}`;

export const getTemplateIdAndVersionFromFormValue = (formValue?: string) => {
  if (!formValue?.includes('-')) {
    return [undefined, undefined];
  }

  try {
    const [id, version] = formValue.split('-');
    return [parseInt(id), parseInt(version)];
  } catch {
    throw new Error('Failed getting template id and version from form value');
  }
};

export const getProgressNotesInitialValues = (
  progressNoteType?: ProgressNoteType,
  selectedTemplate?: TemplateInfo,
  selectedPreviousNote?: number,
  progressNote?: ProviderProgressNoteRead,
  template?: GenericTemplate<unknown>
): ProgressNotesFormV2Values => {
  const initialTemplateValues = template
    ? getInitialTemplateValues(template)
    : {};
  return {
    progressNoteType,
    template: selectedTemplate
      ? createTemplateIdAndVersionFormValue(
          selectedTemplate.id,
          selectedTemplate.version
        )
      : null,
    previousNote: selectedPreviousNote ?? null,
    lateEntryReason: progressNote?.lateEntryReason ?? null,
    lateEntryOtherReason: progressNote?.lateEntryOtherReason ?? null,
    ...initialTemplateValues,
  };
};

export const ProgressNotesFormV2 = ({
  patient,
  providerPatient,
}: ProgressNotesFormV2Props) => {
  const { user } = useAuthStore();
  const provider = useProvider();
  const isGroupPracticeAdmin = isGroupAdminImpersonatingProvider(
    provider,
    user
  );

  const {
    progressNotes,
    isProgressNotesLoading,
    previousProgressNotes,
    notePermissions,
    progressNoteState,
    selectedTemplate,
    setMetadataInfo,
    progressNoteErrors,
    setSelectedPreviousNote,
    event,
  } = useContext(ProgressNoteContext);

  const { control, reset, setValue } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const progressNotesValues: ProgressNotesFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'progressNote',
    });
  const resetForm = (defaultValues: any) => {
    reset(defaultValues);
  };

  const progressNotesTemplatesFF = useFlag('progressNoteTemplates', false);
  const signatureRestrictionsEnabled = useFlag(
    PROGRESS_NOTES_SIGNATURE_RESTRICTIONS,
    false
  );

  const progressNotesLateEntryEnabled = useFlag(
    PROGRESS_NOTES_LATE_ENTRY,
    false
  );
  const [initialTemplateValues, setInitialTemplateValues] = useState({});
  const [showCopyPasteBanner, setShowCopyPasteBanner] = useState(false);
  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  const { allowedProgressNoteTypes, ...progressNoteRequirements } =
    useProgressNoteRequirements({
      patient,
      event,
    });

  const templates = useMemo(
    () => getTemplates<ProgressNoteComponentMetadata>(),
    []
  );

  const prefillFormValues = (
    template: GenericTemplate<ProgressNoteComponentMetadata>,
    values: TemplateValues
  ) => {
    setInitialTemplateValues(getInitialTemplateValues(template, values));
  };

  // Decide if we should disable note types
  const shouldAllowProgressNoteType = useCallback(
    (type: ProgressNoteType) => {
      if (!allowedProgressNoteTypes) return true;

      return allowedProgressNoteTypes.includes(type);
    },
    [allowedProgressNoteTypes]
  );

  const shouldAllowTemplatedNote = useMemo(() => {
    const isNoteSignedElsewhere =
      isDetailsConfirmed(event) &&
      event?.providerAppointment?.progressNoteType === ProgressNoteType.NONE;

    if (signatureRestrictionsEnabled && isNoteSignedElsewhere) {
      return false;
    }

    return shouldAllowProgressNoteType(ProgressNoteType.TEMPLATE);
  }, [shouldAllowProgressNoteType, event, signatureRestrictionsEnabled]);

  const [
    providerClosedConsiderHeadwayTemplateCard,
    setProviderClosedConsiderHeadwayTemplateCard,
  ] = useLocalStorage('providerClosedConsiderHeadwayTemplateCard');

  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  useEffect(() => {
    if (progressNoteErrors?.length > 0) {
      let first = progressNoteErrors[0];
      const element = document.querySelector(`[name="${first.component_id}"]`);
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [progressNoteErrors]);

  const getTemplate = useCallback(
    (id?: number, version?: number) =>
      id !== undefined && version !== undefined && templates !== undefined
        ? templates?.find((t) => {
            return (
              t.templateInfo.id === id && t.templateInfo.version === version
            );
          })
        : undefined,
    [templates]
  );

  /*useEffect(() => {
    if (selectedTemplate) {
      const templateKey = `${selectedTemplate.id}-${selectedTemplate.version}`;
      setValue('progressNote.template', templateKey);
    }
  }, [selectedTemplate, setValue]);*/

  /*useEffect(() => {
    if (progressNote) {
      // Type noteJson to include templateInfo and response properties
      const noteJson = progressNote.noteJson as {
        templateInfo?: { id: number; version: number };
        response?: Record<string, any>;
      };

      // Initialize the template value in the form if templateInfo exists
      if (noteJson.templateInfo) {
        const templateKey = `${noteJson.templateInfo.id}-${noteJson.templateInfo.version}`;
        setValue('progressNote.template', templateKey);
      }

      if (noteJson.response) {
        // Create a dynamic set of keys from `ProgressNotesFormV2Values`
        const excludedKeys = new Set<keyof ProgressNotesFormV2Values>(
          Object.keys({} as ProgressNotesFormV2Values) as Array<
            keyof ProgressNotesFormV2Values
          >
        );

        // Filter out keys from `noteJson.response` that are in `excludedKeys`
        const dynamicKeys = Object.keys(noteJson.response).filter(
          (key) => !excludedKeys.has(key as keyof ProgressNotesFormV2Values)
        );

        // Update each dynamic key individually
        dynamicKeys.forEach((key) => {
          setValue(`progressNote.${key}`, noteJson.response![key]);
        });
      }
    }
  }, [progressNote, setValue]);*/

  // If we have a progressNote, initialize our state
  /*useEffect(() => {
    if (!progressNote || isEqual(progressNote.noteJson, {})) {
      return;
    }

    const noteJson = progressNote?.noteJson as NoteJson;
    if (noteJson) {
      const template = getTemplate(
        noteJson.templateInfo.id,
        noteJson.templateInfo.version
      );
      if (template) {
        setSelectedTemplate(template.templateInfo);
        //isCurrentlyFreeTextTemplate(template);
        getPreviousProgressNotes(
          template.templateInfo.id,
          patient.id,
          provider.id
        );
        prefillFormValues(template, noteJson.response);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressNote?.id, getTemplate]);*/

  const {
    isLoading: isLoadingPreviousAppointmentSession,
    data: providerEventsData,
  } = useQuery(
    ['getPreviousAppointmentSession'],
    async () => {
      return Promise.all(
        (previousProgressNotes || []).map((prevNote) => {
          return ProviderEventApi.getEvents({
            provider_id: prevNote.providerId,
            patient_user_id: prevNote.patientId,
            provider_appointment_ids: [prevNote?.providerAppointmentId],
            expand_estimated_prices: false,
          });
        })
      );
    },
    {
      enabled: !!previousProgressNotes?.length,
    }
  );

  /*const setTemplate = (
    value: string | undefined,
    setFieldValue?: (field: string, value: any) => void
  ) => {
    if (setFieldValue) {
      setFieldValue('progressNote.template', value);
      setFieldValue('progressNote.previousNote', null);
    }

    if (!value) {
      return;
    }

    const template = getTemplate(
      ...getTemplateIdAndVersionFromFormValue(value)
    );

    if (!template) {
      return;
    }

    getPreviousProgressNotes(
      template?.templateInfo.id,
      patient.id,
      provider.id
    );

    setSelectedTemplate(template.templateInfo);
    //isCurrentlyFreeTextTemplate(template);
    setSelectedPreviousNote(undefined);
    setProgressNoteErrors([]);
    setMetadataInfo({
      noteJsonPrefilledFrom: undefined,
    });

    setInitialTemplateValues(getInitialTemplateValues(template));
  };*/

  const sendTemplateAnalyticsEvent = useCallback(
    (trackingEventName: TemplateAnalyticsNames) => {
      trackEvent({
        name: trackingEventName,
        properties: {
          providerId: provider.id!,
          providerAppointmentId: event?.providerAppointment?.id!,
          progressNoteId: progressNotes?.id!,
        },
      });
    },
    [event, provider.id, progressNotes?.id]
  );

  const sendHeadwayTemplateAnalyticsEvent = (
    progressNoteType: ProgressNoteType
  ) => {
    if (provider?.id && event?.providerAppointment?.id && progressNoteType) {
      trackEvent({
        name: 'Use Headway Template Modal Click',
        properties: {
          providerId: provider?.id,
          providerAppointmentId: event?.providerAppointment.id,
          progressNoteRadio: progressNoteType,
        },
      });
    }
  };

  const hoursSinceEndofAppointment = event?.endDate
    ? Math.floor(
        (new Date().valueOf() - new Date(event.endDate).valueOf()) /
          (1000 * 3600)
      )
    : 0;

  const isMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const showLateEntrySection =
    progressNotesLateEntryEnabled &&
    ((isMedicareOrMedicaid && hoursSinceEndofAppointment >= 48) ||
      (!isMedicareOrMedicaid && hoursSinceEndofAppointment >= 72));

  // Only show the show rate boost banner + badges if every other banners or
  // requirements are not shown and if the appointment has not been confirmed
  // yet (more priority to other banners and flows)
  const showRateBoostInfo =
    providerEnrollmentSummary?.isProviderEnrolled &&
    !showLateEntrySection &&
    !isDetailsConfirmed(event) &&
    !progressNoteRequirements.banner &&
    !allowedProgressNoteTypes;

  let filteredTemplateList = alphabeticallySortTemplates(
    applyTemplateRolloutFilter(
      templates,
      progressNotesTemplatesFF,
      selectedTemplate
        ? {
            id: selectedTemplate.id,
            version: selectedTemplate.version,
          }
        : undefined
    )
  );
  if (progressNoteRequirements.templateFilter) {
    filteredTemplateList =
      progressNoteRequirements.templateFilter(filteredTemplateList);
  }

  const isFormDisabled = useMemo(
    () =>
      (progressNoteState !== ProgressNoteState.EDITING &&
        progressNoteState !== ProgressNoteState.ADDENDUM_EDITING) ||
      !notePermissions.includes(ProgressNotePermissions.WRITE),
    [progressNoteState, notePermissions]
  );

  const selectedCptCodes = useWatch({
    control,
    name: 'sessionDetails.cptCodes',
  }) as string[];

  const template = useMemo(() => {
    return getTemplate(
      ...getTemplateIdAndVersionFromFormValue(progressNotesValues?.template)
    );
  }, [progressNotesValues?.template, getTemplate]);

  const isNoteSavedElsewhereTemplate =
    progressNotesValues?.progressNoteType === ProgressNoteType.NONE;

  const isUploadTemplate =
    progressNotesValues?.progressNoteType === ProgressNoteType.UPLOAD;

  const isIncompleteUploadOrNoteTemplate =
    isUploadTemplate || isNoteSavedElsewhereTemplate;

  const isIncompleteFreeTextTemplate =
    progressNotesValues?.template &&
    template?.templateInfo.name.toLowerCase().includes('free') &&
    progressNoteState !== ProgressNoteState.SIGNED &&
    progressNoteState !== ProgressNoteState.SAVED_FOR_LATER &&
    progressNoteState !== ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT;

  //  Determines if any template input has happened by comparing
  //  the response to the initial value

  const isTemplateVisible = useMemo(() => {
    return (
      !isIncompleteFreeTextTemplate &&
      providerClosedConsiderHeadwayTemplateCard &&
      progressNotesValues?.template
    );
  }, [
    isIncompleteFreeTextTemplate,
    providerClosedConsiderHeadwayTemplateCard,
    progressNotesValues?.template,
  ]);

  const currentTemplate = useMemo(() => {
    if (!isTemplateVisible) return null;

    const templateIdVersion = getTemplateIdAndVersionFromFormValue(
      progressNotesValues?.template
    );
    return getTemplate(...templateIdVersion);
  }, [isTemplateVisible, progressNotesValues?.template, getTemplate]);

  /*if (selectedTemplate && !templateInputHappened) {
    const { template, progressNoteType, previousNote, ...response } =
      progressNoteValues;

    if (template) {
      const fullTemplate = getTemplate(
        ...getTemplateIdAndVersionFromFormValue(template)
      );
      if (fullTemplate) {
        const noteJson = createNoteJsonObject(
          fullTemplate as GenericTemplate<unknown>,
          response
        );
        if (
          !isEqual(noteJson.response, {}) &&
          !isEqual(noteJson.response, getInitialTemplateValues(fullTemplate))
        ) {
          setTemplateInputHappened(true);
        }
      }
    }
  }*/

  const sessionConfirmationDate =
    event?.providerAppointment?.sessionDetailsConfirmedOn &&
    new Date(
      event.providerAppointment.sessionDetailsConfirmedOn
    ).toLocaleDateString([], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  const showNoteSavedElsewhereSessionConfirmation =
    isDetailsConfirmed(event) &&
    event?.providerAppointment?.progressNoteType === ProgressNoteType.NONE;

  const showProgressNoteBanners =
    !isProgressNotesLoading &&
    progressNoteState === ProgressNoteState.EDITING &&
    ((showRateBoostInfo && !promsOnlyRateBoostEnabled) ||
      progressNoteRequirements.banner);

  const shouldShowProgressNoteTypeController = useMemo(
    () =>
      progressNoteState === ProgressNoteState.EDITING &&
      !progressNoteRequirements.preventSwitching,
    [progressNoteState, progressNoteRequirements.preventSwitching]
  );

  const shouldShowProgressNoteType = React.useMemo(() => {
    return (type: ProgressNoteType) => {
      if (!progressNoteRequirements.hiddenProgressNoteTypes) return true;
      return !progressNoteRequirements.hiddenProgressNoteTypes.includes(type);
    };
  }, [progressNoteRequirements.hiddenProgressNoteTypes]);

  // Memoize BigRadio options to avoid re-rendering
  const radioOptions = useMemo(() => {
    return [
      {
        key: 'template',
        value: ProgressNoteType.TEMPLATE,
        label: (
          <div>
            <strong>Fill out template</strong>
            <p>Create a structured, compliant note</p>
            {showRateBoostInfo && !promsOnlyRateBoostEnabled && (
              <Badge variant="info">Rate boost qualified</Badge>
            )}
          </div>
        ),
        disabled: !shouldAllowTemplatedNote,
        shouldShow: shouldShowProgressNoteType(ProgressNoteType.TEMPLATE),
      },
      {
        key: 'upload',
        value: ProgressNoteType.UPLOAD,
        label: (
          <div>
            <strong>Upload existing note</strong>
            <p>Use your own format or a note signed elsewhere</p>
            {showRateBoostInfo && !promsOnlyRateBoostEnabled && (
              <Badge variant="info">Rate boost qualified</Badge>
            )}
          </div>
        ),
        disabled: !shouldAllowProgressNoteType(ProgressNoteType.UPLOAD),
        shouldShow: shouldShowProgressNoteType(ProgressNoteType.UPLOAD),
      },
      {
        key: 'none',
        value: ProgressNoteType.NONE,
        label: (
          <div>
            <strong>My note is saved elsewhere</strong>
            <p>Confirm your note exists outside of Headway</p>
          </div>
        ),
        disabled: !shouldAllowProgressNoteType(ProgressNoteType.NONE),
        shouldShow: shouldShowProgressNoteType(ProgressNoteType.NONE),
      },
    ];
  }, [
    showRateBoostInfo,
    promsOnlyRateBoostEnabled,
    shouldAllowTemplatedNote,
    shouldAllowProgressNoteType,
    shouldShowProgressNoteType,
  ]);

  //console.log(useWatch({ control, name: 'progressNote.progressNoteType' }));

  const MemoizedBigRadioGroup = React.memo(BigRadioGroup);
  const MemoizedBigRadio = React.memo(BigRadio);

  return (
    <div>
      <div css={{ marginBottom: theme.spacing.x6 }}>
        {showProgressNoteBanners && (
          <div className="mb-5">
            {progressNoteRequirements.banner ? (
              progressNoteRequirements.banner
            ) : showRateBoostInfo && !promsOnlyRateBoostEnabled ? (
              <RateBoostGuidanceCard />
            ) : null}
          </div>
        )}

        <div className="flex flex-col gap-5">
          {shouldShowProgressNoteTypeController && (
            <Controller
              name="progressNote.progressNoteType"
              control={control}
              disabled={
                progressNoteState !== ProgressNoteState.EDITING ||
                isProgressNotesLoading
              }
              render={({ field: { onChange, onBlur, value } }) => (
                <MemoizedBigRadioGroup
                  name="progressNote.progressNoteType"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  aria-label="Fill out a template or upload an existing note"
                >
                  {radioOptions.map(
                    (option) =>
                      option.shouldShow && (
                        <MemoizedBigRadio
                          key={option.key}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MemoizedBigRadio>
                      )
                  )}
                </MemoizedBigRadioGroup>
              )}
            />
          )}
          {isIncompleteUploadOrNoteTemplate &&
            !providerClosedConsiderHeadwayTemplateCard &&
            progressNotesValues?.progressNoteType && (
              <CreateHeadwayTemplateGuidanceCard
                setProviderClosedConsiderHeadwayTemplateCard={
                  setProviderClosedConsiderHeadwayTemplateCard
                }
                provider={provider}
                sendHeadwayTemplateAnalyticsEvent={
                  sendHeadwayTemplateAnalyticsEvent
                }
                progressNoteType={progressNotesValues?.progressNoteType}
              />
            )}
          {(isUploadTemplate ||
            (isNoteSavedElsewhereTemplate && !signatureRestrictionsEnabled)) &&
            providerClosedConsiderHeadwayTemplateCard &&
            !(progressNoteRequirements.banner && isGroupPracticeAdmin) && (
              <SuggestionGuidanceCard isUploadOrNote={true} />
            )}
          {signatureRestrictionsEnabled &&
            providerClosedConsiderHeadwayTemplateCard &&
            !showNoteSavedElsewhereSessionConfirmation &&
            progressNoteState === ProgressNoteState.EDITING &&
            isNoteSavedElsewhereTemplate && (
              <GuidanceCard
                variant="compliance"
                title="Important compliance requirements when saving your notes elsewhere"
              >
                <BodyText>
                  By choosing <strong>My note is saved elsewhere</strong>, you
                  acknowledge two things:
                </BodyText>
                <ol className="m-0 pl-4">
                  <li>
                    <BodyText>
                      You have a copy of the note you can provide at a later
                      date if requested.
                    </BodyText>
                  </li>
                  <li>
                    <BodyText>
                      You will no longer be able to fill out a note template
                      later (this ensures only one signed copy of the note
                      exists, which is important for compliance).
                    </BodyText>
                  </li>
                </ol>
              </GuidanceCard>
            )}
          {signatureRestrictionsEnabled &&
            showNoteSavedElsewhereSessionConfirmation &&
            progressNoteState === ProgressNoteState.EDITING &&
            isNoteSavedElsewhereTemplate && (
              <div>
                <BodyText>
                  {sessionConfirmationDate ? (
                    <>
                      On <strong>{sessionConfirmationDate},</strong> you{' '}
                    </>
                  ) : (
                    'You '
                  )}
                  confirmed this session and indicated your note was saved
                  elsewhere. You may upload that document by selecting{' '}
                  <strong>Upload existing note</strong> above.
                </BodyText>
              </div>
            )}
          {progressNotesValues?.progressNoteType ===
            ProgressNoteType.TEMPLATE &&
            templates !== undefined &&
            templates.length > 0 && (
              <>
                <div
                  css={{
                    ...theme.stack.horizontal,
                    gap: theme.spacing.x2,
                    [theme.__futureMedia.phone]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <div
                    css={{
                      flexGrow: 1,
                      [theme.__futureMedia.phone]: { width: '100%' },
                    }}
                  >
                    <Controller
                      name="progressNote.template"
                      disabled={
                        progressNoteState !== ProgressNoteState.EDITING ||
                        !notePermissions.includes(
                          ProgressNotePermissions.WRITE
                        ) ||
                        !notePermissions.includes(
                          ProgressNotePermissions.READ
                        ) ||
                        isProgressNotesLoading
                      }
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          name="progressNote.template"
                          placeholder="Choose template"
                          label="Template"
                          selectionMode="single"
                          menuWidth="stretch"
                          onBlur={onBlur}
                          helpText={
                            progressNotesValues?.template &&
                            getTemplateIdAndVersionFromFormValue(
                              progressNotesValues?.template
                            )[0] ===
                              getRecommendedTemplateForCPTCode(
                                progressNotesTemplatesFF,
                                selectedCptCodes
                              )?.id
                              ? 'This is the best template for the CPT code you selected'
                              : ''
                          }
                          selectedKeys={value ? [value] : []}
                          onSelectionChange={(keys) => {
                            const [value] = keys;
                            //console.log('value', value);
                            //setCanRecommendTemplate(false);
                            // if (templateHasChanges) {
                            //   setNextTemplateType(value);
                            // } else {
                            //   setTemplate(value, setFieldValue);
                            // }
                            //setTemplate(value, setFieldValue);
                            console.log('value poupou', value);
                            onChange(value);
                            setValue('progressNote.previousNote', undefined);
                          }}
                          items={filteredTemplateList.map((template) => ({
                            key: `${template.templateInfo.id}-${template.templateInfo.version}`,
                            name: template.templateInfo.name,
                          }))}
                        >
                          {(template) => (
                            <Item textValue={template.name}>
                              <div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: theme.spacing.x2,
                                }}
                              >
                                {template.name}{' '}
                                {getTemplateIdAndVersionFromFormValue(
                                  template.key
                                )[0] ===
                                getRecommendedTemplateForCPTCode(
                                  progressNotesTemplatesFF,
                                  selectedCptCodes
                                )?.id ? (
                                  <Badge variant="info">Recommended</Badge>
                                ) : null}
                              </div>
                            </Item>
                          )}
                        </Select>
                      )}
                    />
                  </div>
                  <div
                    css={{
                      flexGrow: 0,
                      [theme.__futureMedia.phone]: { width: '100%' },
                    }}
                  >
                    {!isLoadingPreviousAppointmentSession && (
                      <Select
                        name="progressNote.previousNote"
                        label="Prefill from previous session"
                        placeholder="Select session"
                        selectionMode="single"
                        menuWidth="stretch"
                        disabled={
                          progressNoteState !== ProgressNoteState.EDITING ||
                          !previousProgressNotes ||
                          previousProgressNotes.length <= 0
                        }
                        selectedKeys={
                          progressNotesValues?.previousNote
                            ? [progressNotesValues?.previousNote]
                            : []
                        }
                        onSelectionChange={(keys) => {
                          const [value] = keys;

                          setValue(
                            'progressNote.previousNote',
                            parseInt(value)
                          );
                          setSelectedPreviousNote(parseInt(value));
                          setMetadataInfo({
                            noteJsonPrefilledFrom: parseInt(value),
                          });

                          const previousValues = (
                            previousProgressNotes?.find(
                              (note) => note.id.toString() === value?.toString()
                            )?.noteJson as NoteJson
                          )?.response as TemplateValues;

                          const template = progressNotesValues?.template
                            ? getTemplate(
                                ...getTemplateIdAndVersionFromFormValue(
                                  progressNotesValues?.template
                                )
                              )
                            : undefined;

                          // Prefill form with previous values
                          if (previousValues) {
                            if (template) {
                              // const newValues = getInitialTemplateValues(
                              //   template,
                              //   progressNoteValues
                              // );
                              // resetForm({
                              //   values: {
                              //     ...initialValues,
                              //     ...newValues,
                              //   },
                              // });
                              prefillFormValues(template, previousValues);
                            }
                          } else {
                            // Reset form and clear values
                            if (template) {
                              const resetValues =
                                getInitialTemplateValues(template);
                              resetForm({
                                values: {
                                  ...resetValues,
                                },
                              });
                              prefillFormValues(template, resetValues);
                            }
                          }
                        }}
                        items={previousProgressNotes ?? []}
                      >
                        {(note) => {
                          const previousAppt = providerEventsData?.find(
                            (prevEvent) => {
                              return (
                                prevEvent?.data[0]?.providerAppointment?.id ===
                                note.providerAppointmentId
                              );
                            }
                          );

                          const date = new Date(
                            previousAppt?.data[0]?.startDate || note.lastSavedOn
                          );
                          return (
                            <Item>
                              {`${date.toLocaleDateString([], {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                              })} at ${date.toLocaleTimeString([], {
                                hour: 'numeric',
                                minute: 'numeric',
                              })}`}
                            </Item>
                          );
                        }}
                      </Select>
                    )}
                  </div>
                </div>
                {showLateEntrySection && !!progressNotesValues?.template && (
                  <ProgressNotesLateEntryInputV2
                    disabled={isFormDisabled}
                    isMedicareOrMedicaid={isMedicareOrMedicaid}
                    showBanner={progressNoteState === ProgressNoteState.EDITING}
                    noteSavedElsewhereIsAllowed={
                      !allowedProgressNoteTypes ||
                      allowedProgressNoteTypes.length === 0 ||
                      allowedProgressNoteTypes.includes(ProgressNoteType.NONE)
                    }
                  />
                )}
                {isIncompleteFreeTextTemplate &&
                  !providerClosedConsiderHeadwayTemplateCard &&
                  progressNotesValues?.progressNoteType && (
                    <CreateHeadwayTemplateGuidanceCard
                      setProviderClosedConsiderHeadwayTemplateCard={
                        setProviderClosedConsiderHeadwayTemplateCard
                      }
                      provider={provider}
                      sendHeadwayTemplateAnalyticsEvent={
                        sendHeadwayTemplateAnalyticsEvent
                      }
                      progressNoteType={progressNotesValues?.progressNoteType}
                    />
                  )}
                {isIncompleteFreeTextTemplate &&
                  providerClosedConsiderHeadwayTemplateCard && (
                    <SuggestionGuidanceCard isUploadOrNote={false} />
                  )}
                {showCopyPasteBanner && isIncompleteFreeTextTemplate && (
                  <GuidanceCard variant={'warning'}>
                    You pasted your note. If you already signed this note
                    elsewhere, please upload the original note instead. Your
                    original signature and timestamp are important for meeting
                    insurance compliance standards.
                  </GuidanceCard>
                )}
                {!notePermissions.includes(ProgressNotePermissions.READ) ? (
                  <SubBodyText>
                    To protect client privacy, you do not have permission to
                    read this progress note
                  </SubBodyText>
                ) : !notePermissions.includes(ProgressNotePermissions.WRITE) ? (
                  <SubBodyText>
                    Only the provider present at the session can write a
                    progress note
                  </SubBodyText>
                ) : null}
                {currentTemplate && providerPatient && (
                  <Template
                    template={currentTemplate}
                    event={event}
                    patient={patient}
                    providerPatient={providerPatient}
                    sendTemplateAnalytics={sendTemplateAnalyticsEvent}
                    disabled={isFormDisabled}
                  />
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const CreateHeadwayTemplateGuidanceCard = ({
  setProviderClosedConsiderHeadwayTemplateCard,
  provider,
  sendHeadwayTemplateAnalyticsEvent,
  progressNoteType,
}: {
  setProviderClosedConsiderHeadwayTemplateCard: (changeTo: string) => void;
  provider: ProviderRead;
  sendHeadwayTemplateAnalyticsEvent: (
    progressNoteType: ProgressNoteType
  ) => void;
  progressNoteType: ProgressNoteType;
}) => (
  <GuidanceCard
    title={'For your next session, I recommend a Headway template'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        <div css={{ marginTop: theme.spacing.x4 }}>
          Headway's structured templates help you save time and meet insurance
          compliance standards. In fact, we've seen that progress notes created
          with Headway templates{' '}
          <strong>meet all insurance requirements 3 times more often</strong>{' '}
          than notes created using free text.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          Prefer to keep your notes organized on another platform? You can
          download your notes created with Headway templates and upload them to
          a HIPAA compliant record-keeping platform.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          If you're interested in using Headway templates for your next session
          and have any questions, please feel free to{' '}
          <Link
            href="https://calendly.com/office-hours-h2k"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                name: 'Schedule a Meeting Link Clicked',
                properties: {
                  providerId: provider.id,
                },
              });
            }}
          >
            schedule a meeting
          </Link>{' '}
          with a member of our team.
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
    <Button
      onPress={() => {
        sendHeadwayTemplateAnalyticsEvent(progressNoteType);
        setProviderClosedConsiderHeadwayTemplateCard('true');
      }}
    >
      OK
    </Button>
  </GuidanceCard>
);

const SuggestionGuidanceCard = ({
  isUploadOrNote,
}: {
  isUploadOrNote?: boolean;
}) => (
  <GuidanceCard
    title={'A few items we recommend you double check'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        We recommend double checking that your current note includes the
        information below. These are the items that we've seen insurance
        companies most commonly flag during audits:
      </>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <ol css={{ paddingLeft: theme.spacing.x4 }}>
          <li>
            <strong>Reference the client's current diagnosis,</strong> including
            what information continues to support it or why it has changed
          </li>
          <li>
            <strong>Reference the treatment plan,</strong> with commentary on
            progress toward the overall goal
            <ol type="a">
              <li>
                "Scott's treatment plan lays out a goal to reduce anxiety at
                work and in his social life/environments. Progress today:
                regressing."
              </li>
            </ol>
          </li>
          <li>
            <strong>Indicate whether symptoms</strong> have improved, maintained
            or escalated
          </li>
          {isUploadOrNote && (
            <>
              <li>
                <strong>Include a risk assessment,</strong> and if a risk is
                identified, a safety plan
              </li>

              <li>
                <strong>Include a mental status exam,</strong> with at least 3
                categories present
              </li>
            </>
          )}
        </ol>
      </div>
      <>
        <div css={{ marginTop: theme.spacing.x6 }}>
          <Link
            href="https://findheadway.zendesk.com/hc/en-us/articles/14367706608916"
            target="_blank"
            rel="noreferrer"
          >
            Our team has put together other commonly missed items here.
          </Link>
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
  </GuidanceCard>
);
