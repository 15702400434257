import { useFormContext, useWatch } from 'react-hook-form';
import { AppointmentConfirmationModalFormV2Values } from '~/legacy/views/Calendar/components/AppointmentConfirmation/AppointmentConfirmationModalV2';

import { isComponentRequired } from '../../../utils';
import { ComponentMap } from '../../componentsV2';
import { GenericTemplate } from '../types';
import { Component, ProgressNoteComponentMetadata, TemplateV3 } from './types';

type TemplateRenderedComponentV2Props<T> = {
  component: Component<T>;
  disabled?: boolean;
  template?: GenericTemplate<TemplateV3<T>>['template'];
};

const doesEnforcePrefillUniqueness = (
  componentType: keyof typeof ComponentMap
) =>
  componentType === 'shortFreeText' ||
  componentType === 'longFreeText' ||
  componentType === 'richFreeText';

export function TemplateRenderedComponentV2<T>({
  component,
  template,
  disabled,
}: TemplateRenderedComponentV2Props<T>) {
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const selectedCptCodes =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'sessionDetails.cptCodes',
    }) || [];

  const metadata = component.metadata as
    | ProgressNoteComponentMetadata
    | undefined;

  const isOptional = !isComponentRequired(selectedCptCodes, metadata);

  const { prefillFromLastSession, requirePrefillUniqueness = true } =
    metadata ?? {};

  const { id, type: componentType } = component;

  // Watch the current value for the given field id
  const value = useWatch({
    control,
    name: id as keyof AppointmentConfirmationModalFormV2Values,
  });

  // Watch the value of 'previousNote'
  const previousNote = useWatch({
    control,
    name: 'progressNote.previousNote',
  });

  // Access the initial value (if needed) from the form's defaultValues
  const { defaultValues } = control._formState;
  const initialValue =
    defaultValues?.[id as keyof AppointmentConfirmationModalFormV2Values];

  const requiresEdit =
    previousNote !== null &&
    prefillFromLastSession &&
    requirePrefillUniqueness &&
    doesEnforcePrefillUniqueness(componentType) &&
    value === initialValue &&
    !(isOptional && !value);

  const Component = ComponentMap[componentType];

  return (
    <Component
      isOptional={isOptional}
      template={template}
      disabled={disabled}
      element={component}
      requiresEdit={requiresEdit}
    />
  );
}
