import { useProvider } from 'hooks';
import moment from 'moment';
import React, { useEffect } from 'react';

import { AllPaymentMethodsExhaustedRetryCycleMetadata } from '@headway/api/models/AllPaymentMethodsExhaustedRetryCycleMetadata';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { PaymentMethodReadinessIssuePreAuthChargeFailedType } from '@headway/api/models/PaymentMethodReadinessIssuePreAuthChargeFailed';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { useUserPaymentMethods } from '@headway/shared/hooks/useUserPaymentMethod';
import { trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { FinancialAccount } from '@headway/ui';

import { useProviderPrice } from 'hooks/useProviderPrice';
import { getPriceString } from 'utils/price';
import {
  getUserPaymentsStatus,
  PatientPaymentStatus,
  PatientPaymentStatusToConfigMap,
} from 'views/Patients/utils/patientPaymentStatus';

interface PaymentStatusAlertBannerProps {
  paymentMethodStatus: PatientPaymentStatus;
  userAppointmentReadiness?: UserAppointmentReadiness;
  patientUser?: UserRead;
}

const PaymentStatusAlertBanner: React.FC<
  React.PropsWithChildren<PaymentStatusAlertBannerProps>
> = ({ paymentMethodStatus, userAppointmentReadiness, patientUser }) => {
  const patientFirstName = formatPatientName(patientUser, {
    firstNameOnly: true,
  });
  const { guidanceCardVariant, guidanceCardLayout, getExplanation } =
    PatientPaymentStatusToConfigMap[paymentMethodStatus];
  const explanation = getExplanation(patientUser);
  const patientPaymentMethodsAreNotVerified = (
    userAppointmentReadiness?.paymentMethod || []
  ).find(
    (issue) =>
      issue.type ===
      PaymentMethodReadinessIssuePreAuthChargeFailedType.PRE_AUTH_CHARGE_FAILED
  );
  const allPatientPaymentMethodsExhaustedRetryCycle = (
    userAppointmentReadiness?.paymentMethod || []
  ).find(
    (issue) =>
      issue.type ===
      PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
  );
  const sessionConfirmationDateOfAppointmentInTerminalFailure = (
    allPatientPaymentMethodsExhaustedRetryCycle?.metadata as AllPaymentMethodsExhaustedRetryCycleMetadata
  )?.sessionConfirmationDate;

  return (
    <div data-testid="paymentStatusAlertBanner">
      {allPatientPaymentMethodsExhaustedRetryCycle &&
        sessionConfirmationDateOfAppointmentInTerminalFailure && (
          <GuidanceCard variant="error" layout={'vertical'}>
            <div className={'hlx-typography-body'}>
              {patientFirstName} has previously unpaid sessions that need to be
              resolved before you can confirm any of their sessions held after{' '}
              {moment(
                sessionConfirmationDateOfAppointmentInTerminalFailure
              ).format('MMM D, YYYY')}
              . Please ask them to log in to their Billing page to pay the
              balance or add a new payment method.
            </div>
          </GuidanceCard>
        )}
      {patientPaymentMethodsAreNotVerified && (
        <div className={'mt-4'}>
          <GuidanceCard layout={'vertical'} variant={'error'}>
            <div className={'hlx-typography-body'}>
              {patientFirstName} does not have a valid payment method on file.
              Please make sure one is added or you will not be able to confirm
              any sessions for this client.
            </div>
          </GuidanceCard>
        </div>
      )}
      {paymentMethodStatus !== 'VERIFIED' && !!explanation && (
        <GuidanceCard layout={guidanceCardLayout} variant={guidanceCardVariant}>
          {explanation}
        </GuidanceCard>
      )}
    </div>
  );
};

interface PaymentInfoProps {
  clientId: number;
  userAppointmentReadiness?: UserAppointmentReadiness;
  openPaymentModal: () => void;
}

export const PaymentInfo = ({
  clientId,
  userAppointmentReadiness,
  openPaymentModal,
}: PaymentInfoProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: paymentMethods } = useUserPaymentMethods(clientId);
  const defaultPaymentMethod = paymentMethods?.find(
    (paymentMethod) => paymentMethod.isDefault
  );

  const shouldShowAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    clientId,
    provider.id,
    undefined
  );

  let paymentStatus: PatientPaymentStatus;
  paymentStatus = getUserPaymentsStatus(
    paymentMethods,
    shouldShowAnthemEAPExperience.data
  );

  useEffect(() => {
    if (paymentStatus === 'VERIFIED') {
      trackPageView({
        name: `Autopay Billing Model Enabled Banner Viewed`,
        properties: {
          providerId: provider.id,
          patientUserId: clientId,
          renderingLocation: 'Provider Client Page',
        },
      });
    }
  }, [paymentStatus, provider.id, clientId]);

  const { data: estimatedPrice } = useProviderPrice(
    { client, provider },
    {
      enabled: client && paymentStatus === 'VERIFIED',
    }
  );

  const formattedEstimatedPrice = estimatedPrice
    ? getPriceString(
        estimatedPrice.firstSessionMinPrice,
        estimatedPrice.firstSessionMaxPrice
      )
    : '';

  if (!client) {
    return null;
  }

  return (
    <PageSection>
      <h2 className={'flex items-center gap-2'}>
        <SectionHeader>Payment Method</SectionHeader>
        <span className={'ml-auto'}>
          <Button
            size="large"
            variant="link"
            onPress={() => openPaymentModal()}
          >
            {defaultPaymentMethod ? 'Update' : 'Add'}
          </Button>
        </span>
      </h2>
      {defaultPaymentMethod && (
        <FinancialAccount
          userPaymentMethod={defaultPaymentMethod}
          variant="helix"
        />
      )}
      <PaymentStatusAlertBanner
        patientUser={client}
        userAppointmentReadiness={userAppointmentReadiness}
        paymentMethodStatus={paymentStatus}
      />

      {paymentStatus === 'VERIFIED' && (
        <div className="flex flex-col gap-4">
          <p>
            {client.displayFirstName} will be automatically charged{' '}
            <span className="font-bold">{formattedEstimatedPrice}</span> after
            you’ve confirmed session details. If{' '}
            {client.activeUserInsurance?.billingFrontEndCarrierName ||
              'their insurer'}{' '}
            determines that they owe anything additional, we’ll notify{' '}
            {client.displayFirstName} and automatically charge their payment
            method on file.
          </p>
        </div>
      )}
    </PageSection>
  );
};
