import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import React from 'react';
import Script from 'react-load-script';

import { ZendeskApi } from '@headway/api/resources/ZendeskApi';
import { theme } from '@headway/helix/theme';

function showWidget() {
  zE('webWidget', 'show');
}

function hideWidget() {
  zE('webWidget', 'hide');
}

function addWidgetOnCloseCallback(callback) {
  zE('webWidget:on', 'close', callback);
}

function addWidgetOnChatStartCallback(callback) {
  zE('webWidget:on', 'chat:start', callback);
}

function addWidgetOnChatEndCallback(callback) {
  zE('webWidget:on', 'chat:end', callback);
}

function addWidgetOnUnreadMessageCallback(callback) {
  zE('webWidget:on', 'chat:unreadMessages', callback);
}

function openWidget() {
  zE('webWidget', 'open');
}

function closeWidget() {
  zE('webWidget', 'close');
}

function resetWidget() {
  zE('webWidget', 'reset');
}

function identifyWidget(name, email) {
  zE('webWidget', 'identify', {
    name,
    email,
  });
}

function updateWidgetSettings(settings) {
  zE('webWidget', 'updateSettings', settings);
}

export class ZendeskWidget extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    window.zESettings = merge(this.getDefaultSettings(), this.props.settings);
  }

  getDefaultSettings = () => {
    return {
      webWidget: {
        chat: {
          menuOptions: { emailTranscript: false },
        },
        authenticate: {
          chat: {
            jwtFn: (callback) => ZendeskApi.getToken().then(callback),
          },
        },
        launcher: {
          label: {
            '*': 'Contact us',
          },
        },
        color: {
          theme: theme.color.system.black,
          launcher: theme.color.system.black,
          launcherText: theme.color.system.white,
          button: theme.color.system.black,
          resultLists: theme.color.system.black,
          header: theme.color.system.black,
          articleLinks: theme.color.system.textBlack,
        },
        zIndex: 15, // below any layered Material UI elements https://material-ui.com/customization/z-index
        // also below Helix components due to chat hiding functional components https://therapymatch.slack.com/archives/C06UJSJJH2N/p1728579084756149
      },
    };
  };

  handleLoad = () => {
    this.setState({ isLoading: false }, () => {
      this.updateWidget();
      addWidgetOnCloseCallback(this.onClose);
      addWidgetOnChatStartCallback(this.onChatStart);
      addWidgetOnChatEndCallback(this.onChatEnd);
      addWidgetOnUnreadMessageCallback(this.onUnreadMessage);
    });
  };

  onClose = () => {
    if (this.props.onWidgetClose) {
      this.props.onWidgetClose();
    }

    closeWidget();
  };

  onChatStart = () => {
    if (this.props.onChatStart) {
      this.props.onChatStart();
    }
  };

  onChatEnd = () => {
    if (this.props.onChatEnd) {
      this.props.onChatEnd();
    }
    setTimeout(this.onClose, 1000 * 60 * 60 * 3); // 3 hours
  };

  onUnreadMessage = () => {
    if (this.props.onUnreadMessage) {
      this.props.onUnreadMessage();
    }
  };

  updateWidget = () => {
    const { isLoading } = this.state;
    const { name, email, hideWidgetLauncher, widgetOpen, settings } =
      this.props;

    // only call widget functions if it is loaded
    if (isLoading) {
      return;
    }

    if (name && email) {
      identifyWidget(name, email);
    } else {
      resetWidget();
    }

    // hide or show the launcher if it's controlled
    if (hideWidgetLauncher !== undefined) {
      if (hideWidgetLauncher) {
        hideWidget();
      } else {
        showWidget();
      }
    }

    // hide or show the widget if it's controlled
    if (widgetOpen !== undefined) {
      if (widgetOpen) {
        showWidget();
        openWidget();
      } else {
        closeWidget();
        hideWidget();
      }
    }

    updateWidgetSettings(merge(this.getDefaultSettings(), settings));
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.name !== prevProps.name ||
      this.props.email !== prevProps.email ||
      this.props.hideWidgetLauncher !== prevProps.hideWidgetLauncher ||
      this.props.widgetOpen !== prevProps.widgetOpen ||
      !isEqual(this.props.settings, prevProps.settings)
    ) {
      this.updateWidget();
    }
  }

  render() {
    return (
      <Script
        attributes={{ id: 'ze-snippet' }}
        url={`https://static.zdassets.com/ekr/snippet.js?key=${this.props.apiKey}`}
        onLoad={this.handleLoad}
      />
    );
  }
}
