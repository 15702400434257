import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { Modal } from '@headway/helix/Modal';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import {
  getIsProviderCredentialedWithFrontEndCarrier,
  isOptumManagedMedicaid,
} from '@headway/shared/utils/postIntakeInfoCollection';

import { useAuthStore } from 'stores/AuthStore';
import { isMedicareMedicaid } from 'utils/frontEndCarrier';

import { useProviderFeeSchedule } from '../../hooks/useProviderFeeSchedule';
import { OptOutModalContent } from './components/OptOutModalContent';
import { RatesModalContent } from './components/RatesModalContent';

export enum MODAL_CONTENT {
  PAYER_RATES = 'PAYER_RATES',
  PAYER_OPT_OUT = 'PAYER_OPT_OUT',
}

type PostIntakeInfoCollectionModalProps = {
  incompletePayerQuestionnaire: PayerQuestionnaire;
  refetchIncompletePayerQuestionnaires: () => void;
  providerFrontEndCarriers: ProviderFrontEndCarrierNested[];
};

export const PostIntakeInfoCollectionModal: React.FC<
  React.PropsWithChildren<PostIntakeInfoCollectionModalProps>
> = ({
  incompletePayerQuestionnaire,
  refetchIncompletePayerQuestionnaires,
  providerFrontEndCarriers,
}) => {
  const { provider } = useAuthStore();
  const [
    postIntakeRatesModalLastRenderedOn,
    setPostIntakeRatesModalLastRenderedOn,
  ] = useLocalStorage(
    'postIntakeRatesModalLastRenderedOn-' +
      incompletePayerQuestionnaire.response.id
  );
  const postIntakeInfoCollectionFlag = useFlag('postIntakeInfoCollection');

  const [open, setOpen] = useState(false);
  const [currentModalContent, setCurrentModalContent] = React.useState(
    MODAL_CONTENT.PAYER_RATES
  );

  const frontEndCarrierId =
    incompletePayerQuestionnaire.template.frontEndCarrierId;

  const isProviderCredentialed = getIsProviderCredentialedWithFrontEndCarrier(
    provider,
    frontEndCarrierId
  );

  const { data: providerFeeSchedule } = useProviderFeeSchedule({
    providerId: incompletePayerQuestionnaire.response.providerId,
    frontEndCarrierId,
    eligibilityLookup: undefined,
  });
  const [frontEndCarrierName, setFrontEndCarrierName] = useState<string>('');

  const getModalTitle = () => {
    if (isProviderCredentialed) {
      return 'Credentialing: We need more info from you';
    }

    if (currentModalContent === MODAL_CONTENT.PAYER_RATES) {
      if (isOptumManagedMedicaid(frontEndCarrierName)) {
        return `Speed up your United Healthcare credentialing by enrolling with ${frontEndCarrierName}`;
      }

      return `Enroll in ${frontEndCarrierName}!`;
    } else {
      return 'Are you sure you want to opt out of this network?';
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const determinedFECName =
      providerFeeSchedule && providerFeeSchedule.length > 0
        ? providerFeeSchedule[0].front_end_carrier.name
        : '';

    setFrontEndCarrierName(determinedFECName);

    if (!open) {
      const isInitialRender = postIntakeRatesModalLastRenderedOn === null;
      const isTwentyFourHoursSinceLastRender =
        !!postIntakeRatesModalLastRenderedOn &&
        moment().isAfter(
          moment(postIntakeRatesModalLastRenderedOn).add(24, 'hours')
        );
      const isCorrectTimeToRender =
        isInitialRender || isTwentyFourHoursSinceLastRender;

      if (isCorrectTimeToRender && postIntakeInfoCollectionFlag) {
        setPostIntakeRatesModalLastRenderedOn(moment().toISOString());
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [
    providerFeeSchedule,
    open,
    postIntakeInfoCollectionFlag,
    postIntakeRatesModalLastRenderedOn,
    setPostIntakeRatesModalLastRenderedOn,
  ]);

  return (
    <>
      {providerFeeSchedule && providerFeeSchedule.length > 0 && (
        <>
          <Modal isOpen={open} onDismiss={onClose} title={getModalTitle()}>
            {currentModalContent === MODAL_CONTENT.PAYER_RATES ? (
              <RatesModalContent
                frontEndCarrierName={frontEndCarrierName}
                isProviderCredentialed={isProviderCredentialed}
                incompletePayerQuestionnaire={incompletePayerQuestionnaire}
                providerFeeSchedule={providerFeeSchedule}
                setCurrentModalContent={() =>
                  setCurrentModalContent(MODAL_CONTENT.PAYER_OPT_OUT)
                }
                isMedicareMedicaid={isMedicareMedicaid(frontEndCarrierId)}
              />
            ) : (
              <OptOutModalContent
                frontEndCarrierId={
                  incompletePayerQuestionnaire.template.frontEndCarrierId
                }
                frontEndCarrierName={frontEndCarrierName}
                onClose={onClose}
                providerFrontEndCarriers={providerFrontEndCarriers}
                setCurrentModalContent={() =>
                  setCurrentModalContent(MODAL_CONTENT.PAYER_RATES)
                }
                refetchIncompletePayerQuestionnaires={
                  refetchIncompletePayerQuestionnaires
                }
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};
