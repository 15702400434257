import { css, cx } from '@emotion/css';
import React, { useContext, useState } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconDownloadSimple } from '@headway/helix/icons/DownloadSimple';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { Tooltip, TooltipTrigger } from '@headway/helix/Tooltip';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackEvent } from '@headway/shared/utils/analytics';

import { useProviderEvent } from 'hooks/useProviderEvent';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';
import { ProgressNoteDownloadAgreementModal } from 'views/Clients/ProgressNotesDownloads/ProgressNoteDownloadAgreementModal';

import {
  addendumAllowedCount,
  additionalAddendumAllowed,
  ProgressNoteContext,
  ProgressNoteState,
} from '../../../stores/ProgressNotesContext';
import { NoteJson } from '../templateView/schemaRenderer/versions/types';

export const ProgressNotesHeader = ({
  eventVirtualId,
  resetAttestation,
}: {
  eventVirtualId: string;
  resetAttestation: () => Promise<void>;
}) => {
  const {
    progressNoteState,
    updateProgressNoteState,
    progressNoteType,
    progressNote,
    metadataInfo,
    addendums,
    documentRemediation,
  } = useContext(ProgressNoteContext);
  const isAddendumsEnabled = useFlag('addendums', false);
  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpoofingAsMedicalRecordAuditor = isSpoofing
    ? isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles)
    : false;

  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });

  const [
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
  ] = useState<boolean>(false);

  const isInSubmittedState =
    progressNoteState === ProgressNoteState.SIGNED ||
    progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT;

  if (!event) {
    return null;
  }

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: theme.spacing.x2,
        }}
      >
        <div
          css={{
            display: 'flex',
            gap: theme.spacing.x2,
            paddingBottom: theme.spacing.x2,
            alignItems: 'center',
          }}
        >
          <SectionHeader>Progress Note</SectionHeader>
          {progressNoteType !== ProgressNoteType.NONE && (
            <div
              className={cx(chipCss, {
                [submittedCss]: isInSubmittedState,
              })}
            >
              <CaptionText>
                <strong>
                  {isInSubmittedState ? 'Submitted' : 'In Progress'}
                </strong>
              </CaptionText>
            </div>
          )}
        </div>
        {isAddendumsEnabled &&
          progressNoteType !== ProgressNoteType.NONE &&
          {
            [ProgressNoteState.SAVED_FOR_LATER]: (
              <Button
                variant="secondary"
                onPress={() => {
                  updateProgressNoteState(ProgressNoteState.EDITING);
                }}
              >
                Edit
              </Button>
            ),
            [ProgressNoteState.SIGNED]: (
              <div
                css={{
                  display: 'flex',
                  gap: theme.spacing.x2,
                  alignItems: 'center',
                }}
              >
                {event.providerAppointment!.status ===
                  ProviderAppointmentStatus.DETAILS_CONFIRMED && (
                  <Button
                    variant="secondary"
                    disabled={isSpoofing && !isSpoofingAsMedicalRecordAuditor}
                    onPress={() => {
                      setIsProgressNoteDownloadAgreementModalOpen(true);
                      trackEvent({
                        name: 'Download Progress Note Button Clicked',
                        properties: {
                          providerId: event.providerId,
                          providerAppointmentId: event.providerAppointment!.id,
                        },
                      });
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        gap: theme.spacing.x2,
                      }}
                    >
                      <IconDownloadSimple />
                      Download note
                    </div>
                  </Button>
                )}
                <TooltipTrigger>
                  <Button
                    variant="secondary"
                    disabled={
                      !additionalAddendumAllowed(addendums, documentRemediation)
                    }
                    onPress={() => {
                      updateProgressNoteState(
                        ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT
                      );
                      resetAttestation();
                      trackEvent({
                        name: 'Add Addendum Button Clicked',
                        properties: {
                          providerId: event.providerId,
                          providerAppointmentId: event.providerAppointment!.id,
                          patientUserId: event.patientUserId!,
                        },
                      });
                    }}
                  >
                    Add Addendum
                  </Button>
                  <Tooltip>
                    {additionalAddendumAllowed(addendums, documentRemediation)
                      ? `${addendumAllowedCount(
                          addendums,
                          documentRemediation
                        )} addendums remaining`
                      : 'No more addendums left'}
                  </Tooltip>
                </TooltipTrigger>
              </div>
            ),
            [ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT]: null,
            [ProgressNoteState.EDITING]: null,
            [ProgressNoteState.ADDENDUM_EDITING]: null,
            [ProgressNoteState.SIGN_REQUESTED]: null,
          }[progressNoteState]}
        {!isAddendumsEnabled &&
          progressNoteState !== ProgressNoteState.EDITING &&
          progressNoteState !== ProgressNoteState.ADDENDUM_EDITING && (
            <Button
              variant="secondary"
              onPress={() => {
                if (progressNote) {
                  trackEvent({
                    name: 'Progress Note Edit Button Clicked',
                    properties: {
                      providerId: progressNote?.providerId,
                      patientUserId: progressNote?.patientId,
                      providerAppointmentId:
                        progressNote?.providerAppointmentId,
                      prefillSelected: !!metadataInfo?.noteJsonPrefilledFrom,
                      progressNoteId: progressNote.id,
                      selectedTemplate: (progressNote?.noteJson as NoteJson)
                        ?.templateInfo?.name,
                    },
                  });
                }
                if (
                  progressNoteState === ProgressNoteState.SIGNED &&
                  progressNoteType === ProgressNoteType.TEMPLATE
                ) {
                  updateProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
                  return;
                }

                updateProgressNoteState(ProgressNoteState.EDITING);
              }}
            >
              Edit
            </Button>
          )}
      </div>
      <ProgressNoteDownloadAgreementModal
        open={isProgressNoteDownloadAgreementModalOpen}
        onClose={async () => setIsProgressNoteDownloadAgreementModalOpen(false)}
        providerId={event.providerId}
        clientId={event.patientUserId!}
        providerAppointmentIds={[event.providerAppointment!.id]}
      />
    </div>
  );
};

const chipCss = css({
  backgroundColor: theme.color.system.lightGray,
  color: theme.color.system.textBlack,
  borderRadius: '2px',
  padding: `0 4px`,
});

const submittedCss = css({
  backgroundColor: theme.color.hue.lightGreen,
  color: theme.color.system.green,
});
