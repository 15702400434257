import { inject } from 'mobx-react';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FlagsConsumer } from '@headway/shared/FeatureFlags/react';
import { ReferProvider } from '@headway/ui/providers/ReferProvider';
import { ReferralProgram } from '@headway/ui/providers/ReferralProgram';

const ReferralAccountComponent = () => {
  return <></>;
};

interface ReferAProviderProps {
  AuthStore?: any;
}

export const ReferAProvider: React.FC<
  React.PropsWithChildren<ReferAProviderProps>
> = inject('AuthStore')(({ AuthStore }) => {
  const [referralId] = useState(() => uuidv4());
  return (
    <>
      <FlagsConsumer flagRequests={{ extolePhase2ReferralProgram: false }}>
        {({ extolePhase2ReferralProgram }) =>
          extolePhase2ReferralProgram ? (
            <div className="isolate min-h-screen w-full bg-system-white pt-[50px]">
              <ReferralProgram
                referralId={referralId}
                AuthStore={AuthStore}
                ReferralAccountComponent={ReferralAccountComponent}
              />
            </div>
          ) : (
            <ReferProvider AuthStore={AuthStore} />
          )
        }
      </FlagsConsumer>
    </>
  );
});
