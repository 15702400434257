import { InsuranceOutageType } from '@headway/api/models/InsuranceOutageType';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { isUserSelfPayWithRespectToProvider } from '@headway/shared/utils/selfPay';

const isPatientInOutage = (
  latestOutageType: InsuranceOutageType,
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  const isSelfPay =
    patient &&
    !!providerId &&
    isUserSelfPayWithRespectToProvider(patient, providerId);
  let insurance;
  if (patientInsurance) {
    insurance = patientInsurance;
  } else if (patient && patient.activeUserInsurance) {
    insurance = patient.activeUserInsurance;
  } else {
    return false;
  }

  return (
    isSelfPay !== undefined &&
    !isSelfPay &&
    !!insurance.latestOutageActivatedOn &&
    !insurance.latestOutageDeactivatedOn &&
    insurance.latestOutageType === latestOutageType
  );
};

export const isPatientInNoDataOutage = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return (
    isPatientInOutage(
      InsuranceOutageType.NO_DATA_USE_FULL_COST,
      patientInsurance,
      patient,
      providerId
    ) ||
    isPatientInOutage(
      InsuranceOutageType.NO_DATA_USE_FLAT_FEE,
      patientInsurance,
      patient,
      providerId
    )
  );
};

export const isPatientInOldDataOutage = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return isPatientInOutage(
    InsuranceOutageType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance,
    patient,
    providerId
  );
};

export const wasPatientInOutage = (
  latestOutageType: InsuranceOutageType,
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  const isSelfPay =
    patient &&
    !!providerId &&
    isUserSelfPayWithRespectToProvider(patient, providerId);
  let insurance;
  if (patientInsurance) {
    insurance = patientInsurance;
  } else if (patient && patient.activeUserInsurance) {
    insurance = patient.activeUserInsurance;
  } else {
    return false;
  }

  return (
    isSelfPay !== undefined &&
    !isSelfPay &&
    !!insurance.latestOutageActivatedOn &&
    !!insurance.latestOutageDeactivatedOn &&
    insurance.latestOutageType === latestOutageType
  );
};

export const wasPatientInNoDataOutage = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return wasPatientInOutage(
    InsuranceOutageType.NO_DATA_USE_FULL_COST,
    patientInsurance,
    patient,
    providerId
  );
};

export const wasPatientInOldDataOutage = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return wasPatientInOutage(
    InsuranceOutageType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance,
    patient,
    providerId
  );
};
