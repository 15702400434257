import { Skeleton } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireType } from '@headway/api/models/ProviderQuestionnaireType';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconUploadSimple } from '@headway/helix/icons/UploadSimple';
import { LinkButton } from '@headway/helix/LinkButton';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { statesToDisplayNames } from '@headway/shared/constants/unitedStatesDisplayNames';
import { useIsPanelabilityIntakeFormEnabled } from '@headway/shared/hooks/useIsPanelabilityIntakeFormEnabled';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import blueArt from 'assets/img/blue-art.jpeg';
import { getProviderLicenseStatesPendingAgreement } from 'components/IroncladAmendmentsModal/helpers/utils';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdmin } from 'utils/access';
import { getListFormatter } from 'utils/formatters';
import {
  CAQH_WEBSITE_URL,
  CAQH_ZENDESK_ARTICLE,
} from 'views/IntakeQuestionnaireV2/utils/intakeQuestionnaireConstants';

import { OnBeforeSubmitError } from '../../OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { getLatestProviderQuestionnaireCaqhProfile } from '../../utils/getLatestProviderQuestionnaireCaqhProfile';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { CaqhErrorSections } from './CaqhErrorSections';

const CaqhErrorsFoundSection = ({
  formikHelpers,
}: {
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>;
}) => {
  const { providerQuestionnaire, refreshCaqhToIntakeMapping, isRefreshing } =
    useQuestionnaireContext();
  const latestProviderQuestionnaireCaqhProfile =
    getLatestProviderQuestionnaireCaqhProfile(providerQuestionnaire);

  return (
    <>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
        }}
      >
        <SectionHeader>
          <IconUploadSimple
            width="16"
            height="16"
            css={{ marginRight: theme.spacing.x1, rotate: '90deg' }}
          />
          Update {latestProviderQuestionnaireCaqhProfile?.errors?.length} items
          on the CAQH website
        </SectionHeader>

        <SubBodyText>
          Our next step is to make sure a few key details are up to date on the
          CAQH website. See the items you’ll need to update below. If you’re
          feeling stuck at any time, check out our Help Center guide, or reach
          out to your practice consultant.
        </SubBodyText>

        <SubBodyText>
          Once you’ve made the updates, make sure to click re-attest on the CAQH
          site, then refresh this page.
        </SubBodyText>
        <div>
          <LinkButton
            variant="primary"
            size="medium"
            href={CAQH_WEBSITE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <b>Go to CAQH website</b>
          </LinkButton>
        </div>
      </div>

      <CaqhErrorSections
        errors={latestProviderQuestionnaireCaqhProfile?.errors}
      />

      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x1,
        }}
      >
        <SectionHeader>
          <b>All fixed?</b>
        </SectionHeader>

        <div css={{ marginTop: theme.spacing.x2 }}>
          <SubBodyText>
            After you finish making the updates in CAQH:
          </SubBodyText>
          <SubBodyText>
            <ol css={{ marginTop: 0 }}>
              <li>Re-attest your CAQH profile.</li>
              <li>Recheck CAQH information in this form.</li>
            </ol>
          </SubBodyText>
        </div>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing.x2,
          }}
        >
          <Button
            size="medium"
            variant="primary"
            disabled={isRefreshing}
            onPress={async () => {
              try {
                await refreshCaqhToIntakeMapping(
                  Number(formikHelpers.values.caqhNumberFromNpi)
                );
              } catch (e: AnyTS4TryCatchUnknownError) {
                notifyError(
                  'We encountered an issue while syncing your CAQH account.'
                );
                logException(e);
              }
            }}
          >
            Recheck CAQH info
          </Button>
          <LinkButton
            variant="secondary"
            size="medium"
            href={CAQH_ZENDESK_ARTICLE}
            target="_blank"
            rel="noreferrer"
          >
            Need help? See our Help Center guide
          </LinkButton>
        </div>
      </div>
    </>
  );
};

const CaqhNoErrorsFoundSection = ({ isInitial }: { isInitial: boolean }) => {
  const { user } = useAuthStore();
  const { provider, providerQuestionnaire } = useQuestionnaireContext();
  const isCredentialing =
    providerQuestionnaire.type === ProviderQuestionnaireType.CREDENTIALING;

  const shouldUseProviderPanelability = useIsPanelabilityIntakeFormEnabled(
    providerQuestionnaire.rawData?.providerSelectedPracticeStates,
    provider
  );
  const { statesPendingAgreement } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates: true,
  });

  const providerLicenseStatesPendingAgreement =
    getProviderLicenseStatesPendingAgreement(provider, statesPendingAgreement);

  const isGPNonAdminAddingNewState =
    provider.groupPracticeId &&
    !isGroupAdmin(user) &&
    !!providerLicenseStatesPendingAgreement?.length;

  const listFormatter = getListFormatter('en-US');

  const InitialPageBodyText = () => (
    <BodyText>
      <p>
        <b>
          In the next few steps, we’ll pull as much info as we can from CAQH to
          save you time.
        </b>
      </p>
      <p css={{ margin: 0 }}>For each page...</p>
      <ul>
        <li>First, we’ll make sure the info is up to date.</li>
        <li>Next, we’ll have you add a few more details.</li>
      </ul>
    </BodyText>
  );
  const FinalPageBodyText = () => (
    <>
      <BodyText>
        <p>
          <b>
            {isGPNonAdminAddingNewState && !shouldUseProviderPanelability
              ? `Looks like you’re the first provider in your group practice to get credentialed in ${listFormatter.format(
                  providerLicenseStatesPendingAgreement.map(
                    (pls) => statesToDisplayNames[pls.state]
                  )
                )}`
              : 'Just double checking your CAQH one more time to make sure we didn’t miss anything.'}
          </b>
        </p>
        {isGPNonAdminAddingNewState && !shouldUseProviderPanelability ? (
          <p>
            Your group admin must sign the agreement for these states and submit
            your application on your behalf
            {isCredentialing
              ? ' before you can access your provider portal'
              : ''}
            . They can find the agreement when they login to see your
            application.
          </p>
        ) : isCredentialing || !isGroupAdmin(user) ? (
          <p>
            Next, submit your application and we’ll handle the paperwork from
            here.
          </p>
        ) : (
          <p>
            Next up, we’ll calculate your rates based on what you’ve told us
            about your practice.
          </p>
        )}
      </BodyText>

      {isGPNonAdminAddingNewState &&
        !isCredentialing &&
        !shouldUseProviderPanelability && (
          <LinkButton href={'/'}>Return to provider portal</LinkButton>
        )}
    </>
  );
  return (
    <div
      css={{
        ...theme.stack.horizontal,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        backgroundColor: theme.color.hue.lightYellow,
      }}
    >
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
          padding: `${theme.spacing.x8} ${theme.spacing.x10}`,
          maxWidth: '50%',
          [theme.__futureMedia.below('tablet')]: {
            maxWidth: '100%',
          },
        }}
      >
        <BrandText variant="section">
          <h2>Looks good!</h2>
          <h2>Your CAQH account is all set.</h2>
        </BrandText>
        {isInitial ? <InitialPageBodyText /> : <FinalPageBodyText />}
      </div>

      <div css={{ maxWidth: '30%' }}>
        <img
          src={blueArt}
          alt=""
          css={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectViewBox: 'inset(0% 20% 40% 0%)',
          }}
        />
      </div>
    </div>
  );
};

const InitialStepBody = ({ formikHelpers }: CustomComponentProps) => (
  <CaqhErrorsBody formikHelpers={formikHelpers} isInitial={true} />
);

const FinalStepBody = ({ formikHelpers }: CustomComponentProps) => (
  <CaqhErrorsBody formikHelpers={formikHelpers} isInitial={false} />
);

const CaqhErrorsBody = ({
  formikHelpers,
  isInitial,
}: {
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>;
  isInitial: boolean;
}) => {
  const { providerQuestionnaire, isRefreshing } = useQuestionnaireContext();
  const latestProviderQuestionnaireCaqhProfile =
    getLatestProviderQuestionnaireCaqhProfile(providerQuestionnaire);

  return (
    <>
      {!!latestProviderQuestionnaireCaqhProfile?.errors?.length ? (
        <>
          {isRefreshing ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <CaqhErrorsFoundSection formikHelpers={formikHelpers} />
          )}
        </>
      ) : (
        <CaqhNoErrorsFoundSection isInitial={isInitial} />
      )}
    </>
  );
};
const getCaqhErrorsStepConfg = (isInitial: boolean): QuestionnaireV2Step => ({
  title: 'CAQH Account Updates',
  description:
    'Please correct the following errors and add the following information to your CAQH account',
  Component: isInitial ? InitialStepBody : FinalStepBody,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    showConfirmationModal,
    context
  ) => {
    const latestProviderQuestionnaireCaqhProfile =
      getLatestProviderQuestionnaireCaqhProfile(context.providerQuestionnaire);
    if (!!latestProviderQuestionnaireCaqhProfile?.errors?.length) {
      setBeforeSubmitError(OnBeforeSubmitError.CaqhErrorsNeedToBeResolved);
      return false;
    }
    return true;
  },
  getFormMeta: ({ providerQuestionnaire }) => {
    const validationSchema = Yup.object();
    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
});
export const InitialCaqhErrorsStepConfig: QuestionnaireV2Step =
  getCaqhErrorsStepConfg(true);
export const FinalCaqhErrorsStepConfig: QuestionnaireV2Step =
  getCaqhErrorsStepConfg(false);

export const CaqhErrorsNeedToBeResolved = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>Please resolve the issues above before proceeding.</BodyText>
  </GuidanceCard>
);
