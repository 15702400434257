import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Item, Select } from '@headway/helix/Select';
import { telehealthPlatformDisplayNames } from '@headway/shared/constants/telehealthPlatformDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { SessionDetailsFormV2Values } from '../SessionDetails/SessionDetailsFormV2';

export const TelehealthLocationsInputsV2 = ({
  canUpdateSessionDetails,
}: {
  canUpdateSessionDetails?: boolean;
}) => {
  let telehealthPlatformOptions = [
    TelehealthPlatform.VIDEO,
    TelehealthPlatform.AUDIO_ONLY_NO_ACCESS,
    TelehealthPlatform.AUDIO_ONLY_REFUSAL,
  ];

  const { control } = useFormContext();
  const sessionDetailsValues: SessionDetailsFormV2Values = useWatch({
    control,
    name: 'sessionDetails',
  });

  // Account for deprecated telehealthPlatform values
  if (
    !canUpdateSessionDetails &&
    sessionDetailsValues.telehealthPlatform &&
    Object.values(TelehealthPlatform).includes(
      sessionDetailsValues.telehealthPlatform
    )
  ) {
    telehealthPlatformOptions = [
      sessionDetailsValues.telehealthPlatform as TelehealthPlatform,
    ];
  }

  return (
    <>
      <Controller
        control={control}
        name="sessionDetails.telehealthPlatform"
        disabled={!canUpdateSessionDetails}
        render={({ field: { onChange, onBlur, value } }) => (
          <RadioGroup
            name="telehealthPlatform"
            label="Type of telehealth session"
            disabled={!canUpdateSessionDetails}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          >
            {telehealthPlatformOptions.map((option) => (
              <Radio key={option} value={option}>
                {telehealthPlatformDisplayNames[option as TelehealthPlatform]}
              </Radio>
            ))}
          </RadioGroup>
        )}
      />
      <Controller
        control={control}
        name="sessionDetails.telehealthProviderState"
        disabled={!canUpdateSessionDetails}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            name="sessionDetails.telehealthProviderState"
            label="Where did you join the telehealth session from?"
            placeholder="Select a state"
            selectionMode="single"
            menuWidth="stretch"
            onBlur={onBlur}
            selectedKeys={
              value ? new Set([UnitedStates[value as UnitedStates]]) : new Set()
            }
            onSelectionChange={(items: Set<string>) => {
              const selectedValue =
                UnitedStates[Array.from(items)[0] as UnitedStates];
              onChange(selectedValue);
            }}
            disabled={!canUpdateSessionDetails}
          >
            {Object.keys(statesToDisplayNames).map((state) => (
              <Item key={state}>
                {statesToDisplayNames[state as UnitedStates]}
              </Item>
            ))}
          </Select>
        )}
      />
    </>
  );
};
