import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M166.4 46.72H89.493c-7.04 0 -12.8 5.76 -12.8 12.8v137.067c0 7.04 5.76 12.8 12.8 12.8h76.907c7.04 0 12.8 -5.76 12.8 -12.8V59.52c0 -7.04 -5.76 -12.8 -12.8 -12.8m4.16 149.867c0 2.24 -1.813 4.053 -4.16 4.053H89.493a4.053 4.053 0 0 1 -4.053 -4.053V59.52c0 -2.24 1.813 -4.053 4.053 -4.053h76.907c2.24 0 4.16 1.813 4.16 4.053zm-17.28 -9.387c0 2.347 -1.92 4.373 -4.373 4.373h-41.813c-2.347 0 -4.373 -1.92 -4.373 -4.373s1.92 -4.373 4.373 -4.373h41.813c2.347 0 4.373 1.92 4.373 4.373" />,
  ],
]);
export const IconPhone: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="48 46 160 164" {...props} weights={weights} />
));

IconPhone.variants = ['outlined'];
IconPhone.defaultVariant = 'outlined';
