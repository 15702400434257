import { IdentificationCard } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconIdentificationCard: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={IdentificationCard}
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    ref={ref}
  />
));

IconIdentificationCard.variants = ['outlined', 'filled'];
IconIdentificationCard.defaultVariant = 'outlined';
