import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { checkExhaustive } from '@headway/shared/utils/types';

import { PdfText } from '../pdf';
import {
  ADNM8AssessmentForm,
  PdfADNM8Assessment,
  ReadonlyADNM8Assessment,
} from './adnm8';
import {
  ANCHORAssessmentForm,
  PdfANCHORAssessment,
  ReadonlyANCHORAssessmentForm,
} from './anchor';
import {
  ASRSAssessmentForm,
  PdfASRSAssessment,
  ReadonlyASRSAssessment,
} from './asrs';
import {
  GAD7AssessmentForm,
  PdfGAD7Assessment,
  ReadonlyGAD7Assessment,
} from './gad7';
import {
  IESRAssessmentForm,
  PdfIESRAssessment,
  ReadonlyIESRAssessment,
} from './iesr';
import {
  ISIAssessmentForm,
  PdfISIAssessment,
  ReadonlyISIAssessment,
} from './isi';
import {
  PCL5AssessmentForm,
  PdfPCL5Assessment,
  ReadonlyPCL5Assessment,
} from './pcl5';
import {
  PdfPHQ9Assessment,
  PHQ9AssessmentForm,
  ReadonlyPHQ9Assessment,
} from './phq9';
import {
  PdfPROMISAssessment,
  PROMISAssessmentForm,
  ReadonlyPROMISAssessmentForm,
} from './promis';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';
import {
  PdfWHO5Assessment,
  ReadonlyWHO5Assessment,
  WHO5AssessmentForm,
} from './who5';
import {
  PdfWHODAS_2Assessment,
  ReadonlyWHODAS_2Assessment,
  WHODAS_2AssessmentForm,
} from './whodas_2';

interface AssessmentFormRendererProps extends AssessmentFormProps {
  assessmentType: PatientAssessmentType;
}

export const AssessmentFormRenderer = ({
  assessmentType,
  ...props
}: AssessmentFormRendererProps) => {
  switch (assessmentType) {
    case PatientAssessmentType.ADNM8:
      return <ADNM8AssessmentForm {...props} />;
    case PatientAssessmentType.ANCHOR:
      return <ANCHORAssessmentForm {...props} />;
    case PatientAssessmentType.ASRS:
      return <ASRSAssessmentForm {...props} />;
    case PatientAssessmentType.GAD7:
      return <GAD7AssessmentForm {...props} />;
    case PatientAssessmentType.IESR:
      return <IESRAssessmentForm {...props} />;
    case PatientAssessmentType.ISI:
      return <ISIAssessmentForm {...props} />;
    case PatientAssessmentType.PCL5:
      return <PCL5AssessmentForm {...props} />;
    case PatientAssessmentType.PHQ9:
      return <PHQ9AssessmentForm {...props} />;
    case PatientAssessmentType.PROMIS:
      return <PROMISAssessmentForm {...props} />;
    case PatientAssessmentType.WHO5:
      return <WHO5AssessmentForm {...props} />;
    case PatientAssessmentType.WHODAS_2:
      return <WHODAS_2AssessmentForm {...props} />;
    default:
      checkExhaustive(assessmentType);
  }
};

interface ReadonlyAssessmentRendererProps extends ReadonlyAssessmentProps {
  assessmentType: PatientAssessmentType;
}

export const ReadonlyAssessmentRenderer = ({
  assessmentType,
  ...props
}: ReadonlyAssessmentRendererProps) => {
  switch (assessmentType) {
    case PatientAssessmentType.ADNM8:
      return <ReadonlyADNM8Assessment {...props} />;
    case PatientAssessmentType.ANCHOR:
      return <ReadonlyANCHORAssessmentForm {...props} />;
    case PatientAssessmentType.ASRS:
      return <ReadonlyASRSAssessment {...props} />;
    case PatientAssessmentType.GAD7:
      return <ReadonlyGAD7Assessment {...props} />;
    case PatientAssessmentType.IESR:
      return <ReadonlyIESRAssessment {...props} />;
    case PatientAssessmentType.ISI:
      return <ReadonlyISIAssessment {...props} />;
    case PatientAssessmentType.PCL5:
      return <ReadonlyPCL5Assessment {...props} />;
    case PatientAssessmentType.PHQ9:
      return <ReadonlyPHQ9Assessment {...props} />;
    case PatientAssessmentType.PROMIS:
      return <ReadonlyPROMISAssessmentForm {...props} />;
    case PatientAssessmentType.WHO5:
      return <ReadonlyWHO5Assessment {...props} />;
    case PatientAssessmentType.WHODAS_2:
      return <ReadonlyWHODAS_2Assessment {...props} />;
    default:
      checkExhaustive(assessmentType);
  }
};

export const PdfAssessmentRenderer = ({
  assessmentType,
  ...props
}: ReadonlyAssessmentRendererProps) => {
  switch (assessmentType) {
    case PatientAssessmentType.ADNM8:
      return <PdfADNM8Assessment {...props} />;
    case PatientAssessmentType.ANCHOR:
      return <PdfANCHORAssessment {...props} />;
    case PatientAssessmentType.ASRS:
      return <PdfASRSAssessment {...props} />;
    case PatientAssessmentType.GAD7:
      return <PdfGAD7Assessment {...props} />;
    case PatientAssessmentType.IESR:
      return <PdfIESRAssessment {...props} />;
    case PatientAssessmentType.ISI:
      return <PdfISIAssessment {...props} />;
    case PatientAssessmentType.PCL5:
      return <PdfPCL5Assessment {...props} />;
    case PatientAssessmentType.PHQ9:
      return <PdfPHQ9Assessment {...props} />;
    case PatientAssessmentType.PROMIS:
      return <PdfPROMISAssessment {...props} />;
    case PatientAssessmentType.WHO5:
      return <PdfWHO5Assessment {...props} />;
    case PatientAssessmentType.WHODAS_2:
      return <PdfWHODAS_2Assessment {...props} />;
    default:
      checkExhaustive(assessmentType);
  }
};
