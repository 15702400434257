import { css, cx } from '@emotion/css';
import React, { useContext } from 'react';

import { Button } from '@headway/helix/Button';
import { IconCheck } from '@headway/helix/icons/Check';
import { IconX } from '@headway/helix/icons/X';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';

import { useProvider } from 'hooks/useProvider';

import { ProgressNoteContext } from '../../../stores/ProgressNotesContext';
import { DoAndDontData } from '../templateView/schemaRenderer/versions/2/types';
import { NoteJson } from '../templateView/schemaRenderer/versions/types';

interface DoAndDontProps {
  data: DoAndDontData;
}

export const DoAndDont = ({ data }: DoAndDontProps) => {
  const provider = useProvider();
  const [isOpen, setIsOpen] = React.useState(false);
  const { progressNote, metadataInfo } = useContext(ProgressNoteContext);

  return (
    <div className={cx(doAndDontContainerCss)}>
      <Button
        variant="link"
        onPress={() => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            trackEvent({
              name: 'Progress Notes Dos and Dont Button Clicked',
              properties: {
                providerId: progressNote?.providerId || provider.id,
                patientUserId: progressNote?.patientId || null,
                providerAppointmentId:
                  progressNote?.providerAppointmentId || null,
                prefillSelected: !!metadataInfo?.noteJsonPrefilledFrom,
                progressNoteId: progressNote?.id,
                progressNoteRadio: 'TEMPLATE',
                selectedTemplate: (progressNote?.noteJson as NoteJson)
                  ?.templateInfo?.name,
              },
            });
          }
        }}
      >
        {isOpen ? 'Hide dos and donts' : 'Show dos and donts'}
      </Button>
      {isOpen && (
        <div className={cx(doAndDontListsContainerCss)}>
          {data.do && data.do.length > 0 ? (
            <div className={cx(doAndDontSectionContainerCss)}>
              <SubBodyText>
                <strong>Do:</strong>
              </SubBodyText>
              {data.do.map((item: string) => (
                <div className={cx(doAndDontRowCss)}>
                  <IconCheck
                    width={20}
                    height={20}
                    color={theme.color.primary.brandGreen}
                  />
                  <SubBodyText>{item}</SubBodyText>
                </div>
              ))}
            </div>
          ) : null}
          {data.dont && data.dont.length > 0 ? (
            <div className={cx(doAndDontSectionContainerCss)}>
              <SubBodyText>
                <strong>Don't:</strong>
              </SubBodyText>
              {data.dont.map((item: string) => (
                <div className={cx(doAndDontRowCss)}>
                  <IconX
                    width={20}
                    height={20}
                    color={theme.color.primary.red}
                  />
                  <SubBodyText>{item}</SubBodyText>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const doAndDontContainerCss = css({
  marginTop: theme.spacing.x4,
  marginBottom: theme.spacing.x4,
});

const doAndDontListsContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.x3,
  marginTop: theme.spacing.x3,
});

const doAndDontSectionContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
});

const doAndDontRowCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.x2,
});
