import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { GetSessionsByProviderSessionSummary } from '@headway/api/models/GetSessionsByProviderSessionSummary';
import { IconClipboardText } from '@headway/helix/icons/ClipboardText';
import { IconVideoChat } from '@headway/helix/icons/VideoChat';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';

import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';

import { Task } from './Task';

interface SessionTaskProps {
  sessionSummary: GetSessionsByProviderSessionSummary;
}

export const SessionTask = ({ sessionSummary }: SessionTaskProps) => {
  const primaryCtaText: string = 'Confirm session details';
  const secondaryCtaText: string = 'View';
  const isIroncladBlockAppointmentConfirmation = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );

  const getBadgeText = () => {
    if (
      !shouldBlockProviderWithIroncladAgreement(
        isIroncladBlockAppointmentConfirmation
      )
    ) {
      return undefined;
    }

    const thirtyDaysAgo = moment().subtract(30, 'days');
    const twentyDaysAgo = moment().subtract(20, 'days');

    const isSessionDuringLastWeekToConfirm =
      moment(sessionSummary.startDate).isSameOrAfter(thirtyDaysAgo) &&
      moment(sessionSummary.startDate).isSameOrBefore(twentyDaysAgo);

    const numOfDaysLeftToConfirm =
      moment(sessionSummary.startDate).diff(thirtyDaysAgo, 'days') + 1;

    if (isSessionDuringLastWeekToConfirm && numOfDaysLeftToConfirm > 1) {
      return `${numOfDaysLeftToConfirm} days left to confirm`;
    } else if (
      isSessionDuringLastWeekToConfirm &&
      numOfDaysLeftToConfirm === 1
    ) {
      return 'Last day to confirm';
    } else {
      return '';
    }
  };

  return (
    <>
      {sessionSummary.patientUserId && (
        <Task
          key={`${sessionSummary.providerEventVirtualId}-session-task`}
          icon={<IconClipboardText />}
          listHeaderText={
            <>
              Past session with{' '}
              <Link href={`/clients/${sessionSummary.patientUserId}`}>
                {sessionSummary.patientFirstName}
              </Link>
              {sessionSummary.telehealth && (
                <IconVideoChat
                  css={{
                    verticalAlign: 'top',
                    marginLeft: theme.spacing.x1,
                  }}
                />
              )}
            </>
          }
          subBodyText={moment(sessionSummary.startDate).format(
            'dddd, MMMM Do [at] h:mm a'
          )}
          badgeText={getBadgeText()}
          secondaryAction={
            <LinkButton
              component={RouterLink}
              elementType="a"
              variant="secondary"
              size="medium"
              to={`/calendar?event_id=${
                sessionSummary.providerEventId
              }&start_date=${moment(sessionSummary.startDate).toISOString()}`}
            >
              {secondaryCtaText}
            </LinkButton>
          }
          primaryAction={
            <LinkButton
              component={RouterLink}
              elementType="a"
              variant="primary"
              size="medium"
              to={`/calendar?event_id=${
                sessionSummary.providerEventId
              }&start_date=${moment(
                sessionSummary.startDate
              ).toISOString()}&confirm_session=true`}
            >
              {primaryCtaText}
            </LinkButton>
          }
        />
      )}
    </>
  );
};
