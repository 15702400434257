import { Parachute } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconParachute: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={Parachute}
    ref={ref}
  />
));

IconParachute.variants = ['outlined', 'filled'];
IconParachute.defaultVariant = 'outlined';
