import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M5.70833 14.1667V14.2917H5.83333H11.5208H11.6458V14.1667V12.9167V12.7917H11.5208H5.83333H5.70833V12.9167V14.1667ZM5.70833 10.625V10.75H5.83333H14.1667H14.2917V10.625V9.37501V9.25001H14.1667H5.83333H5.70833V9.37501V10.625ZM5.70833 7.08334V7.20834H5.83333H14.1667H14.2917V7.08334V5.83334V5.70834H14.1667H5.83333H5.70833V5.83334V7.08334ZM2.375 17.5V17.625H2.5H17.5H17.625V17.5V2.50001V2.37501H17.5H12.085C11.995 1.90635 11.7635 1.5152 11.3925 1.20607C10.9945 0.874355 10.5285 0.708344 10 0.708344C9.47153 0.708344 9.00553 0.874355 8.60748 1.20607C8.23652 1.5152 8.005 1.90635 7.91503 2.37501H2.5H2.375V2.50001V17.5ZM16.125 3.87501V16.125H3.875V3.87501H16.125ZM10.422 3.08871C10.2981 3.21267 10.1591 3.27084 10 3.27084C9.84087 3.27084 9.70194 3.21267 9.57797 3.08871C9.454 2.96474 9.39583 2.8258 9.39583 2.66668C9.39583 2.50755 9.454 2.36862 9.57797 2.24465C9.70194 2.12068 9.84087 2.06251 10 2.06251C10.1591 2.06251 10.2981 2.12068 10.422 2.24465C10.546 2.36862 10.6042 2.50755 10.6042 2.66668C10.6042 2.8258 10.546 2.96474 10.422 3.08871Z" />,
  ],
]);
export const IconAssignment: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="0 0 20 20" {...props} weights={weights} />
));

IconAssignment.variants = ['outlined'];
IconAssignment.defaultVariant = 'outlined';
