import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useEligibilityLookup } from '../../hooks/useEligibilityLookup';

export interface InsuranceVerifyMatchProps {
  patient: UserRead;
  onSuccess?: () => void;
  onEdit?: () => void;
}
export const InsuranceVerifyMatch: React.FC<InsuranceVerifyMatchProps> = ({
  patient,
  onSuccess,
  onEdit,
}: InsuranceVerifyMatchProps) => {
  const userInsurance = patient?.activeUserInsurance;
  const provider = useProvider();
  const {
    data: eligibilityLookup,
    isLoading: eligibilityLookupQueryIsLoading,
  } = useEligibilityLookup({
    eligibilityLookupId:
      patient.activeUserInsurance?.latestEligibilityLookup?.id,
  });
  useEffect(() => {
    if (patient.activeUserInsurance)
      trackPageView({
        name: 'Add Insurance Fuzzy Match Suggestion Viewed',
        properties: {
          patientUserId: patient.id,
          providerId: provider.id,
          userInsuranceId: String(patient.activeUserInsurance.id),
        },
      });
  }, [patient.id, patient.activeUserInsurance, provider.id]);

  if (eligibilityLookupQueryIsLoading) {
    return null;
  }

  const formattedUserInsuranceName = formatPatientName({
    firstName: userInsurance?.firstName,
    lastName: userInsurance?.lastName,
  });
  const formattedLookupName = formatPatientName({
    firstName: eligibilityLookup?.patientFirstName,
    lastName: eligibilityLookup?.patientLastName,
  });

  return (
    <>
      <div className="mb-4">
        <BodyText>
          After searching{' '}
          {userInsurance?.frontEndCarrierName || "Your client's insurer"}
          's database, we found insurance information similar to what you
          entered. If this is correct, you can continue. Otherwise, go back and
          double-check your info against your client's insurance card.
        </BodyText>
      </div>
      <div className="mb-8 grid grid-cols-2 gap-4 rounded border border-system-borderGray p-6">
        <div>
          <BodyText>
            <span className="font-bold">You entered:</span>
          </BodyText>
        </div>
        <div>
          <BodyText>
            <span className="font-bold">We found:</span>
          </BodyText>
        </div>
        <div>
          <BodyText color="gray">Name</BodyText>
          <br />
          <BodyText>{formattedUserInsuranceName}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Name</BodyText>
          <br />
          <BodyText>{formattedLookupName}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Date of Birth</BodyText>
          <br />
          <BodyText>{moment(userInsurance?.dob).format('MM/DD/YYYY')}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Date of Birth</BodyText>
          <br />
          <BodyText>
            {moment(eligibilityLookup?.patientDateOfBirth).format('MM/DD/YYYY')}
          </BodyText>
        </div>
        <div>
          <BodyText color="gray">Member ID</BodyText>
          <br />
          <BodyText>{userInsurance?.memberId}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Member ID</BodyText>
          <br />
          <BodyText>{eligibilityLookup?.patientMemberId}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Group Number</BodyText>
          <br />
          <BodyText>{userInsurance?.groupNumber || '-'}</BodyText>
        </div>
        <div>
          <BodyText color="gray">Group Number</BodyText>
          <br />
          <BodyText>{eligibilityLookup?.patientGroupNumber || '-'}</BodyText>
        </div>
      </div>
      {onEdit && onSuccess && (
        <div className="text-right">
          <Button type="button" variant="secondary" onPress={onEdit}>
            Go back and edit
          </Button>
          <span className="ml-4">
            <Button
              type="button"
              variant="primary"
              onPress={() => {
                trackEvent({
                  name: 'Add Insurance Fuzzy Match Suggestion Accepted',
                  properties: {
                    patientUserId: patient.id,
                    providerId: provider.id,
                    userInsuranceId: String(patient?.activeUserInsurance?.id),
                  },
                });
                onSuccess();
              }}
            >
              Yes, this is right
            </Button>
          </span>
        </div>
      )}
    </>
  );
};
