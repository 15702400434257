import React, { useState } from 'react';

import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { ProgressBar } from '@headway/helix/Progress';

import { ExtoleUserResponse, trackCTAEvents } from '../hooks/extole';
import './provider-referral.css';

const ReferralTrackerSideSheet = ({
  referralList,
  extoleAccessToken,
  programLabel,
}: {
  referralList?: ExtoleUserResponse['friends'];
  extoleAccessToken: string;
  programLabel: string;
}) => {
  if (!referralList) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button size="large" variant="secondary" onPress={() => setIsOpen(true)}>
        Track your referrals
      </Button>
      <Modal
        id="referral-tracker-modal"
        variant="sidesheet"
        title="Track your referrals"
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          trackCTAEvents({
            eventName: 'referral_summary_modal_clicked',
            token: extoleAccessToken,
            programLabel,
          });
        }}
      >
        <ModalContent>
          <div className="flex flex-wrap">
            <div className="w-1/2 pl-2" key="referral_header">
              <ContentText variant="body-large/medium">Referral</ContentText>
            </div>
            <div className="w-1/3" key="status_header">
              <ContentText variant="body-large/medium">Status</ContentText>
            </div>
          </div>
          <Divider autoSpacing={false} />

          {referralList.map((referral, index) => (
            <>
              <div className="my-7 flex flex-wrap" key={index}>
                <div className="w-1/2 pl-2">
                  <div className="flex flex-row gap-2">
                    <div className="bg-system-disabledGray flex h-10 w-10 items-center justify-center rounded-full p-4">
                      {referral.initials}
                    </div>
                    <div>
                      <div className="hlx-typography-list-heading truncate">
                        {/* THIS NEEDS TO BE UPDATED AFTER EXTOLE PROVIDES FIRSTNAME */}
                        {formatName(
                          referral.firstNameOrEmail,
                          referral.initials
                        )}
                      </div>
                      <ContentText variant="body-small">
                        {/* THIS NEEDS TO BE UPDATED AFTER EXTOLE PROVIDES A DATE */}
                        Referred on {formatTimestamp(new Date())}
                      </ContentText>
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="flex flex-col">
                    <ContentText variant="body-small/medium">
                      {referral.status}
                    </ContentText>
                    {/* THIS ALSO NEEDS TO BE UPDATED WITH STATUS DETAILS */}
                    <ContentText variant="body-small">
                      {referral.status}
                    </ContentText>
                    <ProgressBarWrapper progress={2} />
                  </div>
                </div>
              </div>
              <Divider autoSpacing={false} />
            </>
          ))}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferralTrackerSideSheet;

const isValidEmail = (email: string) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

const formatName = (firstNameOrEmail: string, initials: string) => {
  if (isValidEmail(firstNameOrEmail)) {
    const [email, domain] = firstNameOrEmail.split('@');
    return `${email.slice(0, 1)}...@${domain}`;
  }
  if (initials.length > 1) {
    const lastInitials = initials[1];
    return `${firstNameOrEmail} ${lastInitials}`;
  }
  return firstNameOrEmail;
};

const formatTimestamp = (timestamp: Date) => {
  return timestamp.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

const ProgressBarWrapper = ({ progress }: { progress: 0 | 1 | 2 | 3 | 4 }) => {
  const mapProgress: { [key in 0 | 1 | 2 | 3 | 4]: number } = {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 100,
  };
  return (
    <div className="flex items-center gap-2">
      <ProgressBar value={mapProgress[progress]} />
      <ContentText variant="caption" color="foreground/secondary">
        {progress}/4
      </ContentText>
    </div>
  );
};
