import { Link } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconLink: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={Link}
    ref={ref}
  />
));

IconLink.variants = ['outlined', 'filled'];
IconLink.defaultVariant = 'outlined';
