import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useProvider } from 'hooks';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserCommunicationRead } from '@headway/api/models/UserCommunicationRead';
import { UserCommunicationType } from '@headway/api/models/UserCommunicationType';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { ResendEmailModal } from 'views/Patients/ResendEmailModal';

import { Task } from './Task';

interface ClientTaskProps {
  user: UserRead;
}

export const ClientTask = ({ user }: ClientTaskProps) => {
  const provider = useProvider();
  const findClientMissingInfoTask = (
    missingSchedulingInfoTypes:
      | Array<PatientMissingSchedulingInfoType>
      | undefined
  ) => {
    if (!missingSchedulingInfoTypes) return '';

    // We need to description to be in this order of priority:
    // insurance, payment, forms address
    let taskTypes = ['', '', '', ''];
    missingSchedulingInfoTypes.forEach((type) => {
      if (type === PatientMissingSchedulingInfoType.INSURANCE) {
        taskTypes[0] = 'insurance';
      } else if (type === PatientMissingSchedulingInfoType.BILLING) {
        taskTypes[1] = 'payment';
      } else if (type === PatientMissingSchedulingInfoType.FORMS) {
        taskTypes[2] = 'forms';
      } else if (type === PatientMissingSchedulingInfoType.ADDRESS) {
        taskTypes[3] = 'address';
      }
    });

    taskTypes = taskTypes.filter((type) => type);
    if (taskTypes.length < 4) {
      return taskTypes.join(' and ');
    }

    return `${taskTypes.slice(0, -1).join(', ')}, and ${
      taskTypes[taskTypes.length - 1]
    }`;
  };

  const {
    id,
    missingSchedulingInfoTypes,
    patientAccountInviteCommunications,
    providerPatients,
  } = user;

  const clientMissingInfoDescription = findClientMissingInfoTask(
    missingSchedulingInfoTypes
  );

  const isOnlyMissingForms =
    missingSchedulingInfoTypes?.length === 1 &&
    missingSchedulingInfoTypes?.includes(
      PatientMissingSchedulingInfoType.FORMS
    );

  const usePlural =
    missingSchedulingInfoTypes &&
    (missingSchedulingInfoTypes.length > 1 ||
      missingSchedulingInfoTypes.includes(
        PatientMissingSchedulingInfoType.FORMS
      ));

  const secondaryCtaText: string = isOnlyMissingForms
    ? 'View client'
    : 'Edit client';

  const providerPatient = (providerPatients as ProviderPatientRead[]).find(
    (providerPatient) => providerPatient.providerId === provider.id
  )!;

  return (
    <>
      <Task
        icon={<ErrorOutlineIcon />}
        listHeaderText={
          <>
            <Link component={RouterLink} elementType="a" to={`/clients/${id}`}>
              {formatPatientName(user, {
                firstNameOnly: true,
              })}
            </Link>
            's {clientMissingInfoDescription} need
            {usePlural ? null : 's'} attention
          </>
        }
        subBodyText="You won't be able to confirm sessions until this is completed."
        secondaryAction={
          <LinkButton href={`/clients/${id}`} variant="secondary" size="medium">
            {secondaryCtaText}
          </LinkButton>
        }
        primaryAction={
          <SendOrResendEmailButton
            user={user}
            provider={provider}
            providerPatient={providerPatient}
            patientAccountInviteCommunications={
              patientAccountInviteCommunications
            }
          />
        }
      />
    </>
  );
};

interface SendOrResendEmailButtonProps {
  user: UserRead;
  provider: ProviderRead;
  providerPatient: ProviderPatientRead;
  patientAccountInviteCommunications: UserCommunicationRead[];
}

const SendOrResendEmailButton = ({
  user,
  provider,
  providerPatient,
  patientAccountInviteCommunications,
}: SendOrResendEmailButtonProps) => {
  const queryClient = useQueryClient();
  const [isResendEmailOpen, setIsResendEmailOpen] = React.useState(false);

  const handleSendWelcomeEmailClicked = useMutation(
    async () => {
      await ProviderPatientApi.sendProviderPatientAccountInvite(
        providerPatient.id
      );
      await UserApi.updateUser(user.id, {
        isInvited: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'patientMissingSchedulingInfoForProvider',
          provider?.id,
        ]);

        notifySuccess(`Welcome email sent to ${formatPatientName(user)}`);
      },
      onError: (error: AnyTS4TryCatchUnknownError) => {
        notifyError(error.message);
      },
    }
  );

  const previousEmailSentEnoughTimeAgo = (
    communications: UserCommunicationRead[]
  ) => {
    const communicationsSorted = communications
      .filter(
        (item) =>
          item.communicationType ===
          UserCommunicationType.PATIENT_ACCOUNT_INVITE
      )
      .sort((a, b) => ((a.sentOn ?? 0) < (b.sentOn ?? 0) ? 1 : -1));

    if (communicationsSorted.length === 0) {
      // no patient account invite sent
      return true;
    }

    const lastInviteSentOn = moment
      .utc(communicationsSorted[0].sentOn)
      .local()
      .format();

    const duration = moment.duration(moment().diff(lastInviteSentOn));

    // if more than 3 days ago
    return duration.asHours() >= 72;
  };

  const hasPreviousInviteEmail = patientAccountInviteCommunications.length > 0;

  const handleCloseResendEmailModal = () => {
    setIsResendEmailOpen(false);
  };

  const primaryCtaText = hasPreviousInviteEmail
    ? 'Re-send email'
    : 'Send email';

  return (
    <>
      <Button
        variant="primary"
        size="medium"
        disabled={handleSendWelcomeEmailClicked.isLoading}
        onPress={() => {
          if (!hasPreviousInviteEmail) {
            handleSendWelcomeEmailClicked.mutateAsync();
          } else {
            if (
              previousEmailSentEnoughTimeAgo(patientAccountInviteCommunications)
            ) {
              handleSendWelcomeEmailClicked.mutateAsync();
            } else {
              setIsResendEmailOpen(true);
            }
          }
        }}
      >
        {primaryCtaText}
      </Button>
      <ResendEmailModal
        open={isResendEmailOpen}
        onClose={handleCloseResendEmailModal}
        patient={user}
        onSendEmail={() => handleSendWelcomeEmailClicked.mutateAsync()}
        onCancel={handleCloseResendEmailModal}
      />
    </>
  );
};
