import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useProviderProgressNotesErrorsQueryKeyArgs {
  providerAppointmentId: number;
  noteJson: string;
  noteJsonPrefilledFrom?: number;
}

export const getUseProviderProgressNotesErrorsQueryKey = ({
  providerAppointmentId,
  noteJson,
  noteJsonPrefilledFrom,
}: useProviderProgressNotesErrorsQueryKeyArgs): [
  string,
  number,
  string,
  number | undefined,
] => [
  'get-provider-progress-notes-errors',
  providerAppointmentId,
  noteJson,
  noteJsonPrefilledFrom,
];

const {
  useSingleQuery: useProviderProgressNotesErrors,
  useListQuery: useProviderProgressNotesErrorsList,
  useCachedQuery: useProviderProgressNotesErrorsCache,
} = createBasicApiHooks(
  getUseProviderProgressNotesErrorsQueryKey,
  ({ providerAppointmentId, noteJson, noteJsonPrefilledFrom }) =>
    ProviderProgressNotesApi.getProviderProgressNotesErrors({
      provider_appointment_id: providerAppointmentId,
      note_json: noteJson,
      note_json_prefilled_from: noteJsonPrefilledFrom,
    }),
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch provider progress notes errors for this appointment'
);

export {
  useProviderProgressNotesErrors,
  useProviderProgressNotesErrorsList,
  useProviderProgressNotesErrorsCache,
};
