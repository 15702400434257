import { ChatText } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconChatText: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={ChatText}
    ref={ref}
  />
));

IconChatText.variants = ['outlined', 'filled'];
IconChatText.defaultVariant = 'outlined';
