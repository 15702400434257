/**
 *  A library that provides hooks, providers, consumers, and other related helper
 * functions to interact with LaunchDarkly in React applications. This code will
 * typically only run in client-side JS/TS.
 *
 *  Read more about our usage and best practices of Launch Darkly here:
 * https://findheadway.atlassian.net/wiki/spaces/EPD/pages/575307813/LaunchDarkly+Feature+Flags
 *
 * @packageDocumentation
 */
import {
  asyncWithLDProvider,
  initialize,
  LDProvider,
  useLDClient as useFlagsClient,
  useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';
import type {
  LDFlagSet,
  LDFlagValue,
  ProviderConfig,
} from 'launchdarkly-react-client-sdk';
import React, { ComponentType, ReactElement, useEffect, useState } from 'react';

import { buildContext } from './context/builder';

/**
 * LaunchDarkly Context Provider to initialize feature flag service. By default
 * we use the async provider to 1) ensure that the service is loaded before we
 * start using it, and 2) to prevent screen flickering if feature flags affect
 * what the user should see.
 * @param config - The LaunchDarkly configuration. At a minimum, it must include clientSideID.
 **/
function withFlagsProvider(config: Omit<ProviderConfig, 'reactOptions'>) {
  asyncWithLDProvider({
    ...config,
    reactOptions: { useCamelCaseFlagKeys: true },
  });
}

type IdentifyFlagsContextProps = {
  context: ReturnType<typeof buildContext>;
};

/**
 * Component that identifies the user to get their feature flags. You can
 * identify the user in `withFlagsProvider`, but typically the flags provider
 * is created before the user is logged in.
 * @param context - A standard Headway<>LaunchDarkly context built from buildContext
 */
function IdentifyFlagsContext({ context }: IdentifyFlagsContextProps) {
  const flagsClient = useFlagsClient();

  useEffect(() => {
    async function identifyFlagContext() {
      if (!flagsClient) {
        return;
      }

      await flagsClient.identify(context);
    }

    identifyFlagContext();
    // reload identify if any of the data in the LaunchDarkly context changes
  }, [context]);

  return null;
}

type FlagsConsumerProps = {
  children: (flags: LDFlagSet) => ReactElement | null;
  flagRequests: LDFlagSet;
};

/**
 * Component with render props to get flag values. To get a single flag value,
 * use `FlagConsumer`.
 * @param flagRequests - Map of requested flag names to their default value. Default
 * values are overriden by retrieved flag values: { ...defaults, ...retrieved }
 *
 * @example
 * ```
 * <FlagsConsumer flagRequests={{ aggressiveText: undefined, otherFlag: false }}>
 *   {flags => (
 *     <button>
 *       {flags['aggressiveText'] ? 'CLICK ME' : 'Sign up'}
 *     </button>
 *   )}
 * </FlagsConsumer>
 * ```
 */
function FlagsConsumer({ children, flagRequests = {} }: FlagsConsumerProps) {
  const flags: { [flagName: string]: any } = {};
  for (const flag in flagRequests) {
    flags[flag] = useFlag(flag, flagRequests[flag]);
  }

  return children({ ...flags });
}

/**
 * Hook to use a flag value in function components. For class components, reference `FlagsConsumer`.
 *
 * @example
 * ```
 * const showNewFeature = useFlag('new-feature');
 * if (showNewFeature) { ... }
 * ```
 **/
function useFlag(flag: string, defaultValue?: LDFlagValue) {
  const { [flag]: flagValue } = useLDFlags();

  return flagValue === undefined ? defaultValue : flagValue;
}

type FlagsBreakingVersionPromptProps = {
  flag: string;
  prompt: ComponentType<{ open: boolean }>;
};

/**
 * Renders the provided refresh prompt when the breaking version flag changes value.
 * @param flag - Name of the flag that keeps track of breaking versions for the app
 * @param prompt - Component that will render when breaking version changes to
 * prompt the user to refresh
 */
function FlagsBreakingVersionPrompt({
  flag,
  prompt: Prompt,
}: FlagsBreakingVersionPromptProps) {
  const breakingVersion = useFlag(flag);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(flag)) {
      localStorage.setItem(flag, breakingVersion);
    } else if (
      breakingVersion &&
      localStorage.getItem(flag) !== breakingVersion.toString()
    ) {
      localStorage.setItem(flag, breakingVersion);
      setShowPrompt(true);
    }
  }, [breakingVersion]);

  return <Prompt open={showPrompt} />;
}

/**
 * Should only be used in class components where FlagsConsumer is not sufficient
 * @param Component - Name of the Component that needs the requested flags
 * @param flagRequests - Map of requested flag names to their default value. Default values are overriden by flag values retrieved from LaunchDarkly.
 */
function withFlags(
  Component: React.ComponentClass<any, any>,
  flagRequests: LDFlagSet
) {
  return (props: any) => {
    const flags: { [flagName: string]: any } = {};
    for (const flag in flagRequests) {
      flags[flag] = useFlag(flag, flagRequests[flag]);
    }

    return <Component {...props} flags={flags} />;
  };
}

export {
  FlagsBreakingVersionPrompt,
  FlagsConsumer,
  IdentifyFlagsContext,
  useFlag,
  useFlagsClient,
  withFlags,
  withFlagsProvider,
  LDProvider,
  initialize,
};
