import { LoginCredentialsType } from '@headway/api/models/LoginCredentialsType';
import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';

export type LoginCredentialsProviderTaskType = Extract<
  ProviderTaskType,
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_CAQH
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV
  | ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY
>;

const LOGIN_CREDENTIALS_COLLECTION_TASK_TYPES: Set<ProviderTaskType> = new Set([
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_CAQH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV,
  ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY,
]);

export function isLoginCredentialsProviderTask(
  providerTaskType: ProviderTaskType
): providerTaskType is LoginCredentialsProviderTaskType {
  return LOGIN_CREDENTIALS_COLLECTION_TASK_TYPES.has(providerTaskType);
}

const PROVIDER_TASK_TYPE_TO_LOGIN_CREDENTIALS_TASK_TYPE_MAP: Record<
  LoginCredentialsProviderTaskType,
  LoginCredentialsType
> = {
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_CAQH]:
    LoginCredentialsType.CAQH,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK]:
    LoginCredentialsType.MEDICAID_AK,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL]:
    LoginCredentialsType.MEDICAID_AL,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR]:
    LoginCredentialsType.MEDICAID_AR,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ]:
    LoginCredentialsType.MEDICAID_AZ,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA]:
    LoginCredentialsType.MEDICAID_CA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO]:
    LoginCredentialsType.MEDICAID_CO,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT]:
    LoginCredentialsType.MEDICAID_CT,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC]:
    LoginCredentialsType.MEDICAID_DC,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE]:
    LoginCredentialsType.MEDICAID_DE,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL]:
    LoginCredentialsType.MEDICAID_FL,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA]:
    LoginCredentialsType.MEDICAID_GA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI]:
    LoginCredentialsType.MEDICAID_HI,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA]:
    LoginCredentialsType.MEDICAID_IA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID]:
    LoginCredentialsType.MEDICAID_ID,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL]:
    LoginCredentialsType.MEDICAID_IL,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN]:
    LoginCredentialsType.MEDICAID_IN,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS]:
    LoginCredentialsType.MEDICAID_KS,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY]:
    LoginCredentialsType.MEDICAID_KY,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA]:
    LoginCredentialsType.MEDICAID_LA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA]:
    LoginCredentialsType.MEDICAID_MA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD]:
    LoginCredentialsType.MEDICAID_MD,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME]:
    LoginCredentialsType.MEDICAID_ME,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI]:
    LoginCredentialsType.MEDICAID_MI,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN]:
    LoginCredentialsType.MEDICAID_MN,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO]:
    LoginCredentialsType.MEDICAID_MO,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS]:
    LoginCredentialsType.MEDICAID_MS,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT]:
    LoginCredentialsType.MEDICAID_MT,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC]:
    LoginCredentialsType.MEDICAID_NC,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND]:
    LoginCredentialsType.MEDICAID_ND,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE]:
    LoginCredentialsType.MEDICAID_NE,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH]:
    LoginCredentialsType.MEDICAID_NH,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ]:
    LoginCredentialsType.MEDICAID_NJ,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM]:
    LoginCredentialsType.MEDICAID_NM,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV]:
    LoginCredentialsType.MEDICAID_NV,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY]:
    LoginCredentialsType.MEDICAID_NY,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH]:
    LoginCredentialsType.MEDICAID_OH,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK]:
    LoginCredentialsType.MEDICAID_OK,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR]:
    LoginCredentialsType.MEDICAID_OR,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA]:
    LoginCredentialsType.MEDICAID_PA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR]:
    LoginCredentialsType.MEDICAID_PR,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI]:
    LoginCredentialsType.MEDICAID_RI,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC]:
    LoginCredentialsType.MEDICAID_SC,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD]:
    LoginCredentialsType.MEDICAID_SD,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN]:
    LoginCredentialsType.MEDICAID_TN,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX]:
    LoginCredentialsType.MEDICAID_TX,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT]:
    LoginCredentialsType.MEDICAID_UT,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA]:
    LoginCredentialsType.MEDICAID_VA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI]:
    LoginCredentialsType.MEDICAID_VI,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT]:
    LoginCredentialsType.MEDICAID_VT,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA]:
    LoginCredentialsType.MEDICAID_WA,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI]:
    LoginCredentialsType.MEDICAID_WI,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV]:
    LoginCredentialsType.MEDICAID_WV,
  [ProviderTaskType.LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY]:
    LoginCredentialsType.MEDICAID_WY,
};

export function getLoginCredentialsTypeForProviderTaskType(
  providerTaskType: LoginCredentialsProviderTaskType
): LoginCredentialsType {
  return PROVIDER_TASK_TYPE_TO_LOGIN_CREDENTIALS_TASK_TYPE_MAP[
    providerTaskType
  ];
}
