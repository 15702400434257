import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M128 53.653c-40.96 0 -74.24 33.28 -74.24 74.24s33.28 74.24 74.24 74.24 74.24 -33.28 74.24 -74.24S168.96 53.653 128 53.653m65.387 69.973h-26.133c-0.747 -24.107 -7.147 -44.8 -16.96 -57.28 24 8.64 41.387 30.827 43.2 57.28zM128 193.6c-15.893 0 -29.44 -27.627 -30.507 -61.227h61.013c-1.067 33.6 -14.613 61.227 -30.507 61.227m-30.507 -69.973c1.067 -33.6 14.613 -61.227 30.507 -61.227s29.44 27.627 30.507 61.227zm8.32 -57.28C96 78.827 89.493 99.52 88.853 123.627H62.72a65.493 65.493 0 0 1 43.2 -57.28zm-43.2 66.027h26.133c0.747 24.107 7.147 44.8 16.96 57.28a65.707 65.707 0 0 1 -43.2 -57.28zm87.573 57.28c9.813 -12.48 16.32 -33.173 16.96 -57.28h26.133a65.493 65.493 0 0 1 -43.2 57.28z" />,
  ],
]);
export const IconLanguage: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="53 53 150 150" {...props} weights={weights} />
));

IconLanguage.variants = ['outlined'];
IconLanguage.defaultVariant = 'outlined';
