import moment from 'moment';

export const shouldBlockProviderWithIroncladAgreement = (
  isIroncladBlockAppointmentConfirmationEnabled: boolean,
  startDate?: Date
) => {
  return startDate
    ? isIroncladBlockAppointmentConfirmationEnabled &&
        moment(startDate) < moment().subtract(30, 'days')
    : isIroncladBlockAppointmentConfirmationEnabled;
};

export const getSignerId = (user: any, isGroupPractice: boolean) => {
  return isGroupPractice
    ? `group-${user.group_practice.id}`
    : (user?.id as string);
};
