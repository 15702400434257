import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'fill',
    <>
      <path fill="none" d="M0 0h256v256H0z" />
      <circle
        cx={128}
        cy={128}
        r={96}
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <circle cx={128} cy={128} r={38} fill="#fff" />
    </>,
  ],
]);
export const IconRadioSelected: Icon = forwardRef((props, ref) => (
  <IconBase
    ref={ref}
    viewBox="0 0 256 256"
    {...props}
    defaultVariant="filled"
    weights={weights}
  />
));

IconRadioSelected.variants = ['filled'];
IconRadioSelected.defaultVariant = 'filled';
