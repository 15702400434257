import intersection from 'lodash/intersection';

import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireType } from '@headway/api/models/ProviderQuestionnaireType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import {
  ENABLE_PANELABILITY_DELETE_PFECS,
  ENABLE_PROVIDER_PANELABILITY_FOR_NEW_PROVIDERS,
  ENABLE_PROVIDER_PANELABILITY_INTAKE_FORM_V2,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

// gemini.link(mamba/app/shared/services/panelability_flags_service.py)
export const useIsPanelabilityIntakeFormEnabled = (
  providerSelectedPracticeStates: UnitedStates[] | undefined,
  provider: ProviderRead
): boolean => {
  const providerPanelabilityFlag = useFlag(
    ENABLE_PROVIDER_PANELABILITY_INTAKE_FORM_V2,
    { enabled: false }
  );

  if (!providerPanelabilityFlag.enabled) {
    return false;
  }

  const nonLiveStates = provider.activeProviderLicenseStates
    .filter((license) => !license.liveOn)
    .map((pls) => pls.state);

  const newSelectedPracticeStates =
    providerSelectedPracticeStates?.filter((state) =>
      nonLiveStates.includes(state)
    ) ?? [];

  return (
    // NONE of the newSelectedPracticeStates can be in disabledStates
    intersection(
      newSelectedPracticeStates,
      providerPanelabilityFlag.disabledStates || []
    ).length === 0
  );
};

export const useIsPanelabilityEnabledForNewProviders = (
  providerSelectedPracticeStates: UnitedStates[] | undefined,
  provider: ProviderRead
): boolean => {
  const shouldUseProviderPanelability = useIsPanelabilityIntakeFormEnabled(
    providerSelectedPracticeStates,
    provider
  );
  const isNewProviderFlagEnabled = useFlag(
    ENABLE_PROVIDER_PANELABILITY_FOR_NEW_PROVIDERS
  );
  return (
    shouldUseProviderPanelability &&
    isNewProviderFlagEnabled &&
    !!providerSelectedPracticeStates?.length
  );
};

export const useIsPanelabilityDeletePFECsEnabled = (
  providerSelectedPracticeStates: UnitedStates[] | undefined,
  provider: ProviderRead,
  providerQuestionnaire: ProviderQuestionnaireReadV2
): boolean => {
  const shouldUseProviderPanelability = useIsPanelabilityIntakeFormEnabled(
    providerSelectedPracticeStates,
    provider
  );
  const isDeletePFECsFlagEnabled = useFlag(ENABLE_PANELABILITY_DELETE_PFECS);
  const isPanelabilityEnabledForNewProviders =
    useIsPanelabilityEnabledForNewProviders(
      providerSelectedPracticeStates,
      provider
    );
  const isQuestionnaireRecredOrIsNewProviderPanelabilityEnabled =
    providerQuestionnaire.type === ProviderQuestionnaireType.RECREDENTIALING ||
    isPanelabilityEnabledForNewProviders;
  return (
    shouldUseProviderPanelability &&
    isDeletePFECsFlagEnabled &&
    !!providerSelectedPracticeStates?.length &&
    isQuestionnaireRecredOrIsNewProviderPanelabilityEnabled
  );
};
// gemini.endlink
