import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';

import { SchemaComponent } from '.';
import { OtherTextField } from './OtherTextField';
import { FormFieldWrapper } from './wrapper/FormFieldWrapper';

export const FormRadio = ({
  element,
  isOptional = false,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, options } = element;

  return (
    <FormFieldWrapper name={id} disabled={disabled}>
      {({ onChange, onBlur, value, validation, disabled }) => (
        <RadioGroup
          label={title}
          name={id}
          key={id}
          optionalityText={isOptional ? 'Optional' : null}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          validation={validation}
        >
          {options.map((option: string, i: number) => {
            return option.includes('Other') ? (
              <OtherTextField
                id={id}
                optionType="single"
                option={option}
                template={template}
                disabled={disabled!}
              >
                {(value: any) => (
                  <Radio key={i} value={option} disabled={disabled}>
                    {option}
                  </Radio>
                )}
              </OtherTextField>
            ) : (
              <Radio key={i} value={option} disabled={disabled}>
                {option}
              </Radio>
            );
          })}
        </RadioGroup>
      )}
    </FormFieldWrapper>
  );
};
