import { ReactNode } from 'react';

import { ContentText, ContentTextProps } from './ContentText';

export interface SectionHeaderProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'disabledGray';
  weight?: 'medium' | 'regular';
}

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const SectionHeader = ({
  children,
  color = 'textBlack',
  weight = 'medium',
}: SectionHeaderProps) => {
  return (
    <ContentText
      variant={weight === 'medium' ? 'section-title/medium' : 'section-title'}
      color={COLOR_MAP[color]}
    >
      {children}
    </ContentText>
  );
};

const COLOR_MAP: Record<
  NonNullable<SectionHeaderProps['color']>,
  ContentTextProps['color']
> = {
  textBlack: 'foreground/primary',
  gray: 'foreground/secondary',
  disabledGray: 'foreground/disabled',
};
