import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="m205.696 94.027 -18.752 -29.525c-2.965 -4.651 -8.427 -7.541 -14.229 -7.541H83.285c-5.813 0 -11.264 2.88 -14.229 7.541L50.304 94.027a14.507 14.507 0 0 0 0 15.68l57.579 81.333c3.019 4.693 7.648 8.032 13.045 9.6h0.043q3.349 0.971 7.04 0.992 3.659 0 7.04 -0.992h0.032c5.397 -1.568 10.048 -4.907 13.045 -9.589l57.451 -81.173 0.117 -0.171a14.507 14.507 0 0 0 0 -15.68m-66.923 -29.013 20.832 32.821H96.405l20.832 -32.821zm-62.901 3.808c1.493 -2.357 4.341 -3.808 7.413 -3.808h24.395L86.827 97.835H57.451zM57.493 105.899h28.693l27.861 79.893zm73.28 87.413a17.067 17.067 0 0 1 -2.773 0.245q-1.429 -0.021 -2.795 -0.245L94.72 105.899h66.539zm11.179 -7.531 27.872 -79.893h28.693zm27.2 -87.947 -20.832 -32.821h24.395a8.747 8.747 0 0 1 7.413 3.808l18.421 29.013z" />,
  ],
]);
export const IconRareFind: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="48 48 160 162" {...props} weights={weights} />
));

IconRareFind.variants = ['outlined'];
IconRareFind.defaultVariant = 'outlined';
