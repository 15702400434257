import { Skeleton } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';

import { GetPaginatedUserReadResponse } from '@headway/api/models/GetPaginatedUserReadResponse';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserCommunicationRead } from '@headway/api/models/UserCommunicationRead';
import { UserCommunicationType } from '@headway/api/models/UserCommunicationType';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { getUsePatientsQueryKey } from 'hooks/usePatients';
import { useProvider } from 'hooks/useProvider';
import { ResendEmailModal } from 'views/Patients/ResendEmailModal';

const previousEmailSentEnoughTimeAgo = (
  communications: UserCommunicationRead[]
) => {
  const communicationsSorted = communications
    .filter(
      (item) =>
        item.communicationType === UserCommunicationType.PATIENT_ACCOUNT_INVITE
    )
    .sort((a, b) => ((a.sentOn ?? 0) < (b.sentOn ?? 0) ? 1 : -1));

  if (communicationsSorted.length === 0) {
    // no patient account invite sent
    return true;
  }

  const lastInviteSentOn = moment
    .utc(communicationsSorted[0].sentOn)
    .local()
    .format();

  const duration = moment.duration(moment().diff(lastInviteSentOn));

  // if more than 3 days ago
  return duration.asHours() >= 72;
};

export const sendPatientWelcomeEmail = async (
  user: UserRead,
  provider: ProviderRead
) => {
  const providerPatient = await ProviderPatientApi.getProviderPatientByIds({
    provider_id: provider.id,
    patient_user_id: user.id,
  });
  await ProviderPatientApi.sendProviderPatientAccountInvite(providerPatient.id);
  return await UserApi.updateUser(user.id, {
    isInvited: true,
  });
};

export interface ClientWelcomeEmailProps {
  client: UserRead;
  isArchivedPatient?: boolean;
  isPendingReferral?: boolean;
  page: number;
  search?: string;
  isLoading?: boolean;
}

export const ClientWelcomeEmail = ({
  client,
  isArchivedPatient = false,
  isPendingReferral = false,
  page,
  search,
  isLoading,
}: ClientWelcomeEmailProps) => {
  const provider = useProvider();
  const queryClient = useQueryClient();
  const [isResendEmailOpen, setIsResendEmailOpen] = React.useState(false);
  const isMobileView = useMediaQuery(theme.mediaQuery.mobile);
  const hasPreviousInviteEmail =
    client.patientAccountInviteCommunications.length > 0;

  const firstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  const patientsQueryKey = getUsePatientsQueryKey(provider.id, {
    hidden: isArchivedPatient,
    page,
    search,
  });

  const handleSendWelcomeEmailClicked = async (user: UserRead) => {
    try {
      const updatedUser = await sendPatientWelcomeEmail(user, provider);
      queryClient.setQueryData<GetPaginatedUserReadResponse>(
        patientsQueryKey,
        (patientsResult) => {
          if (!patientsResult?.data) {
            return { data: [updatedUser], totalCount: 1 };
          }
          return {
            ...patientsResult,
            data: patientsResult.data.map((patient) =>
              patient.id === user.id ? updatedUser : patient
            ),
          };
        }
      );
      queryClient.invalidateQueries(patientsQueryKey);
      notifySuccess(`Welcome email sent to ${formatPatientName(user)}`);
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(error.message);
    }
  };

  const handleCloseResendEmailModal = () => {
    setIsResendEmailOpen(false);
  };

  const handleSendWelcomeEmail = () => {
    if (!hasPreviousInviteEmail) {
      handleSendWelcomeEmailClicked(client);
    } else {
      if (
        previousEmailSentEnoughTimeAgo(
          client.patientAccountInviteCommunications
        )
      ) {
        handleSendWelcomeEmailClicked(client);
      } else {
        setIsResendEmailOpen(true);
      }
    }
  };

  const sendEmailInviteTextContent = useMemo(() => {
    if (isPendingReferral) {
      return `We'll notify you once ${firstName} is ready for a session.`;
    } else if (hasPreviousInviteEmail) {
      return `${firstName} hasn't completed setup tasks. Resend email to remind them.`;
    } else {
      return `Send setup invitation to ${firstName}`;
    }
  }, [firstName, hasPreviousInviteEmail, isPendingReferral]);

  return (
    <>
      <GuidanceCard
        variant="neutral"
        layout={isMobileView ? 'vertical' : 'horizontal'}
      >
        <div>{sendEmailInviteTextContent}</div>
        {!isPendingReferral && (
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="large"
              variant="link"
              onPress={handleSendWelcomeEmail}
              disabled={isLoading}
            >
              {hasPreviousInviteEmail ? 'Resend email' : 'Send email'}
            </Button>
          </div>
        )}
      </GuidanceCard>
      <ResendEmailModal
        open={isResendEmailOpen}
        onClose={handleCloseResendEmailModal}
        patient={client}
        onSendEmail={handleSendWelcomeEmailClicked}
        onCancel={handleCloseResendEmailModal}
      />
    </>
  );
};
