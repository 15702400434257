import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

export const GroupPracticeProviderGuidanceCard = ({
  isProviderExisting,
  formattedStatesList,
}: {
  isProviderExisting: boolean;
  formattedStatesList: string;
}) => {
  return (
    <div className="mt-12 grid gap-2">
      <h2>
        <SectionHeader>Headway Agreement</SectionHeader>
      </h2>
      <GuidanceCard variant="neutral" layout="vertical">
        <div>
          <p>
            <BodyText>
              <b>Your group admin must accept Headway agreement</b>
            </BodyText>
          </p>
          <p>
            <BodyText>
              Looks like you’re the first provider in your group practice to get
              credentialed in {formattedStatesList}! Your group admin must sign
              the agreement for these states and submit your application on your
              behalf before you can access your provider portal. They can find
              the agreement when they login to see your application.
            </BodyText>
          </p>
          {isProviderExisting && (
            <LinkButton variant="link" href="/">
              Go back to provider portal
            </LinkButton>
          )}
        </div>
      </GuidanceCard>
    </div>
  );
};

export const GroupPracticeAdminRatesDisclaimer = ({
  providerDisplayFirstName,
  formattedStatesList,
}: {
  providerDisplayFirstName: string;
  formattedStatesList: string;
}) => {
  return (
    <BodyText>
      <b>
        Looks like {providerDisplayFirstName} is the first provider in your
        group practice to get credentialed in {formattedStatesList}.
      </b>
      <p css={{ paddingTop: theme.spacing.x2 }}>
        As group admin, you'll need to accept the agreement for these states
        before submitting the credentialing application.
      </p>
    </BodyText>
  );
};
