import { AssignmentTwoTone } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useProvider } from 'hooks';
import React from 'react';

import { GetSessionsByProviderSessionSummary } from '@headway/api/models/GetSessionsByProviderSessionSummary';
import { Tooltip, VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useProviderConfirmableSessions } from 'hooks/useProviderConfirmableSessions';

import { DetailPopoverProps } from './DetailPopover';
import { TaskPopover, TaskPopoverProps } from './TaskPopover';

interface TaskProps {
  handleTaskConfirmDetailsClick: TaskPopoverProps['handleTaskConfirmDetailsClick'];
  handleTaskCancelClick: TaskPopoverProps['handleTaskCancelClick'];
}

export const Task: React.FC<React.PropsWithChildren<TaskProps>> = ({
  handleTaskConfirmDetailsClick,
  handleTaskCancelClick,
}) => {
  const [anchorEl, setAnchorEl] =
    React.useState<DetailPopoverProps['anchorEl']>(null);

  const provider = useProvider();

  const { data: sessionSummaries } = useProviderConfirmableSessions({
    providerId: provider.id,
  });

  const sessionTasks: GetSessionsByProviderSessionSummary[] =
    sessionSummaries || [];

  return (
    <>
      <TaskPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        sessionSummaries={sessionTasks || []}
        handleTaskConfirmDetailsClick={handleTaskConfirmDetailsClick}
        handleTaskCancelClick={handleTaskCancelClick}
      />
      <Badge badgeContent={sessionTasks.length} color="error">
        <Tooltip title="Tasks">
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            aria-label="Calendar tasks"
            data-testid="calendarTaskButton"
            size="large"
          >
            <AssignmentTwoTone css={{ fontSize: theme.fontSize.xl }} />
            <VisuallyHidden>Tasks</VisuallyHidden>
          </IconButton>
        </Tooltip>
      </Badge>
    </>
  );
};
