import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';

// gemini.link(mamba/app/shared/constants/front_end_carrier_identifier.py)
export const OPTUM_IDS = [
  FrontEndCarrierIdentifier.UNITED_HEALTHCARE,
  FrontEndCarrierIdentifier.OXFORD,
  FrontEndCarrierIdentifier.OSCAR_HEALTH,
];

// Does not include BCBA MA which has another network sharing program
export const BCBS_IDS = [
  FrontEndCarrierIdentifier.EMPIRE_BLUE_CROSS_BLUE_SHIELD,
  FrontEndCarrierIdentifier.CARE_FIRST_VIRGINIA,
  FrontEndCarrierIdentifier.REGENCE_GROUP_ADMINISTRATORS,
  FrontEndCarrierIdentifier.CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MARYLAND,
  FrontEndCarrierIdentifier.CREDENCE_BLUE_CROSS_BLUE_SHIELD,
].concat(Array.from(Array(60).keys()).map((i) => i + 24));

export const ANTHEM_IDS = [
  FrontEndCarrierIdentifier.EMPIRE_BLUE_CROSS_BLUE_SHIELD,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_CALIFORNIA,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_COLORADO,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_CONNECTICUT,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_GEORGIA,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_INDIANA,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_KENTUCKY,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_MAINE,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_MISSOURI,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEVADA,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEW_HAMPSHIRE,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_OHIO,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_VIRGINIA,
  FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_BLUE_SHIELD_WISCONSIN,
];

export const NON_CA_ANTHEM_IDS = ANTHEM_IDS.filter(
  (id) => id != FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_CALIFORNIA
);

export const ANTHEM_CARRIER_CONSOLIDATED_FOR_SEARCH_ID =
  FrontEndCarrierIdentifier.EMPIRE_BLUE_CROSS_BLUE_SHIELD;

export const KAISER_IDS = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_COLORADO,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_GEORGIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_MID_ATLANTIC,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHWEST,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
];

export const CARRIERS_REQUIRING_AUTHORIZATION = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
];

export const CARRIERS_SUPPRESSING_TRIAGE_MODALS = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.ANTHEM_EAP,
];

export const MEDICARE_ADVANTAGE_IDS = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.UNITED_HEALTHCARE_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA_MEDICARE_ADVANTAGE,
];
export const MEDICARE_ADVANTAGE_IDS_INN = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.UNITED_HEALTHCARE_MEDICARE_ADVANTAGE,
  FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA_MEDICARE_ADVANTAGE,
];
export const KAISER_MEDICARE_ADVANTAGE_IDS = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
];

export const KAISER_PERMANENTE_NORCAL_IDS = [
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA,
  FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE,
];

export const EAP_IDS = [FrontEndCarrierIdentifier.ANTHEM_EAP];

// gemini.endlink

export const KAISER_MEMBER_ID_PATTERN = {
  regex: /^[0-9]{10}|^[0-9]{9}$|^[0-9]{8}$|^[0-9]{7}$/,
  example: '1234567 or 23456789',
};
export const KAISER_NORTHERN_CA_MEMBER_ID_PATTERN = {
  regex: /^1100[0-9]{8}$/,
  example: '110012345678',
};
export const BCBS_MEMBER_ID_PATTERN = {
  regex: /^[a-zA-z]{3}[a-zA-Z0-9- ]*$/,
  example: 'ICN1234578 or BQN22244455001',
};
export const DEFAULT_MEMBER_ID_PATTERN = /^[a-zA-Z0-9- ]+$/;

export const CARRIER_REGEX_MAP: {
  [key: number]: { regex: RegExp; example?: string };
} = {
  // Headway Network
  [FrontEndCarrierIdentifier.AETNA]: {
    regex:
      /^[A-z0-9]{7,12}([-][0-9]{2})?$|^[0-9]{8,10}$|^[A-z0-9]{4}[- ][0-9]{5}([- ][0-9]{2})?$/,
    example: 'W123456789 or 0123456789',
  },
  [FrontEndCarrierIdentifier.CIGNA]: {
    regex:
      /^[Uu][0-9]{8}[- ][0-9]{2}$|^[Uu][0-9]{5,11}$|^[0-9]{8,11}$|^[Uu][0-9]{9}[a-z]$/,
    example: 'U23456789, U23456789 01, 123456789, or 123456789 01',
  },
  [FrontEndCarrierIdentifier.EMBLEM]: {
    regex: /^[0-9]{11}$|^[0-9]{10}$|^[0-9]{9}$/,
  },
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_COLORADO]:
    KAISER_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_GEORGIA]:
    KAISER_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_MID_ATLANTIC]:
    KAISER_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHWEST]:
    KAISER_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA]:
    KAISER_NORTHERN_CA_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE]:
    KAISER_NORTHERN_CA_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA]:
    KAISER_MEMBER_ID_PATTERN,
  [FrontEndCarrierIdentifier.MVP_HEALTHCARE]: {
    regex: /^(?:[0-9]|[A-Za-z])[0-9]{10}$/,
  },
  [FrontEndCarrierIdentifier.OSCAR_HEALTH]: {
    regex: /^OSC[0-9]{8,10}([-][0-9]{2})?$|^[0-9]{8,10}([-][0-9]{2})?$/i,
    example: 'OSC123456789 or 123456789',
  },
  [FrontEndCarrierIdentifier.OXFORD]: {
    regex: /^([0-9]{8,11})$/,
    example: '12345678900',
  },
  [FrontEndCarrierIdentifier.UNITED_HEALTHCARE]: {
    regex: /^[A-z0-9]{7,9}([0-9]{2})?$/,
    example: '123456789 or HP1234567-89',
  },
  [FrontEndCarrierIdentifier.AVMED]: {
    regex: /^[Aa][0-9]{8,10}$/,
    example: 'A01234567 or a1234567800',
  },
};
