import { WarningOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { keyBy, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { CreateEmergencyContactRequestBody } from '@headway/api/models/CreateEmergencyContactRequestBody';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { SourceAttribution } from '@headway/api/models/SourceAttribution';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { UserUpdate } from '@headway/api/models/UserUpdate';
import { EmailValidationApi } from '@headway/api/resources/EmailValidationApi';
import { EmergencyContactApi } from '@headway/api/resources/EmergencyContactApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { formatPhoneNumber } from '@headway/helix/formatters';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NO_AT_SIGN_MATCH,
  YUP_NO_AT_SIGN_MESSAGE,
  YUP_PHONE_MATCH,
} from '@headway/shared/constants/format';
import { SIGMUND_EMERGENCY_CONTACTS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useUserCache } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getErrorMessage } from '@headway/shared/utils/error';
import { logException } from '@headway/shared/utils/sentry';
import { Button as MuiButton } from '@headway/ui/Button';
import { FormRow } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { LogoLoader } from '@headway/ui/LogoLoader';
import { notifyError } from '@headway/ui/utils/notify';

import { useProviderPatientCache } from 'hooks/useProviderPatient';

import { HandleUserAndProviderPatientArgs } from './AddPatientModal';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';
import { EmergencyContactFormIntakeFlow } from './EmergencyContactFormIntakeFlow';

export type AddPatientFormValues = {
  firstName: string;
  lastName: string;
  hasDisplayNameChecked: boolean;
  displayFirstName?: string | undefined;
  displayLastName?: string | undefined;
  phone: string;
  email: string;
  sourceAttribution: SourceAttribution | undefined;
  otherSourceAttribution: string;
  contactName: string;
  contactPhone: string;
  contactRelationship: string;
};

const patientSourcedOptions: {
  [value in Exclude<SourceAttribution, SourceAttribution.BULK_IMPORT>]: string;
} = {
  [SourceAttribution.ALREADY_IN_CASELOAD]: 'They are already in my caseload',
  [SourceAttribution.REFERRAL_FROM_COLLEAGUE_OR_PHYSICIAN]:
    'Referral from a colleague or physician',
  [SourceAttribution.PSYCHOLOGY_TODAY]: 'Psychology Today',
  [SourceAttribution.KIWI_HEALTH]: 'Kiwi Health',
  [SourceAttribution.ZOCDOC]: 'Zocdoc',
  [SourceAttribution.GOOGLE_MY_BUSINESS]: 'Google My Business',
  [SourceAttribution.ALMA_SONDERMIND_GROW_PATH]:
    'Alma / Sondermind / Grow / Path',
  [SourceAttribution.MENTAL_HEALTH_MATCH]: 'Mental Health Match',
  [SourceAttribution.MY_OWN_WEBSITE]: 'My own website',
  [SourceAttribution.OTHER]: 'Other',
};

export type EmergencyContactCreating = {
  fullName: string;
  phone: string;
  relationship: string;
};

export const PatientDemographicsFormValidationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  displayFirstName: Yup.string().matches(
    YUP_NO_AT_SIGN_MATCH,
    YUP_NO_AT_SIGN_MESSAGE
  ),
  displayLastName: Yup.string().matches(
    YUP_NO_AT_SIGN_MATCH,
    YUP_NO_AT_SIGN_MESSAGE
  ),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  phone: Yup.string()
    .matches(YUP_PHONE_MATCH, {
      message: 'Phone number must be 10 digits',
      excludeEmptyString: true,
    })
    .nullable(),
  sourceAttribution: Yup.string().required('Field is required'),
  otherSourceAttribution: Yup.string().when('sourceAttribution', {
    is: SourceAttribution.OTHER,
    then: Yup.string().required('Field is required'),
  }),
  contactName: Yup.string(),
  contactPhone: Yup.string(),
  contactRelationship: Yup.string(),
});

export interface PatientDemographicsFormIntakeFlowProps {
  setClient: (client: UserRead) => void;
  setProviderPatient: (providerPatient: ProviderPatientRead) => void;
  onClientAdded: () => void;
}

export const PatientDemographicsFormIntakeFlow = ({
  setClient,
  setProviderPatient,
  onClientAdded,
}: PatientDemographicsFormIntakeFlowProps) => {
  const provider = useProvider();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isExistingProviderPatient, setIsExistingProviderPatient] =
    useState(false);
  const [displayNameCheckedCount, setDisplayNameCheckedCount] = useState(0);
  const [suggestedEmail, setSuggestedEmail] = useState<string | undefined>(
    undefined
  );
  const [existingUser, setExistingUser] = useState<UserRead | undefined>(
    undefined
  );

  const userCache = useUserCache();
  const providerPatientCache = useProviderPatientCache();

  const { isOpen, closeAddPatientModal, setCurrentStep } = useContext(
    AddPatientModalContext
  );

  const handleUserAndProviderPatient = ({
    user,
    providerPatient,
    hasDisplayNameChecked,
    displayNameCheckedCount,
  }: HandleUserAndProviderPatientArgs) => {
    setClient(user);
    setProviderPatient(providerPatient);
    userCache.set({ userId: user.id }, user);
    providerPatientCache.set(
      { providerId: providerPatient.providerId, patientId: user.id },
      providerPatient
    );
    if (user.activeUserInsuranceId) {
      setCurrentStep(AddPatientModalPage.EXISTING_INSURANCE);
    } else {
      setCurrentStep(AddPatientModalPage.BILLING_INFORMATION);
    }
    onClientAdded();
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        stepName: 'submitted',
        screenName: 'Add Patient',
        numPatientsSaved: 1,
        ctaButtonCopy: 'Save',
        patientsSaved: [user.id],
      },
    });
    const nameMatch =
      (user.displayFirstName === '' && user.displayLastName === '') ||
      (user.displayFirstName === user.firstName &&
        user.displayLastName === user.lastName);
    trackEvent({
      name: 'Add Patient Completed',
      properties: {
        differentNameCheckboxSelectionCount: displayNameCheckedCount,
        nameCheckbox: hasDisplayNameChecked,
        nameMatch: [nameMatch],
      },
    });
  };

  useEffect(() => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Add client information',
        stepName: 'BASIC_INFO',
      },
    });
    trackEvent({
      name: 'Add Patient Started',
      properties: {
        providerFlow: 'single_patient',
      },
    });
  }, []);

  const isCurrentClientOfProvider = (user: UserRead) => {
    if (!user.providerPatients) {
      return false;
    }
    const providerPatientsByProviderId = keyBy(
      user.providerPatients,
      'providerId'
    );

    return providerPatientsByProviderId[provider.id] ? true : false;
  };
  const [emergencyContactsCreated, setEmergencyContactsCreated] = useState<
    EmergencyContactCreating[]
  >([]);

  const checkEmailStatus = async (enteredEmail: string | undefined) => {
    if (enteredEmail) {
      setIsValidatingEmail(true);
      setIsExistingProviderPatient(false);
      await checkForExistingUser(enteredEmail);
      await validateEmail(enteredEmail);
    } else {
      setIsEmailValid(true);
    }
    setIsEmailChecked(true);
  };

  const checkForExistingUser = async (email: string) => {
    try {
      const existingUsers = await UserApi.findUsers({ email });
      if (existingUsers && existingUsers.length) {
        setIsExistingProviderPatient(
          isCurrentClientOfProvider(existingUsers[0])
        );
        setExistingUser(existingUsers[0] || undefined);
      }
    } catch (err) {
      logException(err);
    }
  };

  const validateEmail = async (email: string) => {
    try {
      const emailValidationResult = await EmailValidationApi.validateEmail({
        email,
      });
      setSuggestedEmail(emailValidationResult.suggestedEmail);
      setIsEmailValid(!!emailValidationResult.isValidEmail);
      setIsValidatingEmail(false);
    } catch (err) {
      setIsValidatingEmail(false);
      logException(err);
    }
  };

  const hasUnresolvedEmail = () => {
    return !isValidatingEmail && (!isEmailValid || suggestedEmail);
  };

  const addPatient = async (values: AddPatientFormValues) => {
    try {
      const {
        sourceAttribution,
        otherSourceAttribution,
        hasDisplayNameChecked,
        ...userCreate
      } = values;

      const userCreating = {
        ...omit(userCreate, [
          'contactName',
          'contactPhone',
          'contactRelationship',
        ]),
      };
      let user;

      // Set display name to legal name if hasDisplayNameChecked is unchecked.
      if (!hasDisplayNameChecked) {
        userCreating.displayFirstName = values.firstName;
        userCreating.displayLastName = values.lastName;
      }

      if (existingUser) {
        user = existingUser;
      } else {
        user = await UserApi.createUser({
          ...userCreating,
          inviteChannel: UserInviteChannel.PROVIDER_PORTAL,
        });
      }

      const providerPatientPayload = {
        providerId: provider.id,
        userId: user.id,
        providerLicenseStateId: provider.providerLicenseState.id,
        sourceAttribution: sourceAttribution,
        ...(sourceAttribution === SourceAttribution.OTHER
          ? {
              otherSourceAttribution: otherSourceAttribution,
            }
          : {}),
      };
      const createdProviderPatient =
        await ProviderPatientApi.createProviderPatient(providerPatientPayload);

      if (
        user.firstName !== values.firstName ||
        user.lastName !== values.lastName ||
        user.displayFirstName !== values.displayFirstName ||
        user.displayLastName !== values.displayLastName ||
        (user.phone ? user.phone !== values.phone : values.phone)
      ) {
        const updateUserData: UserUpdate = {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
        };
        if (hasDisplayNameChecked) {
          updateUserData.displayFirstName = values.displayFirstName;
          updateUserData.displayLastName = values.displayLastName;
        }
        user = await UserApi.updateUser(user.id, updateUserData);
      }

      handleUserAndProviderPatient({
        user,
        providerPatient: createdProviderPatient,
        hasDisplayNameChecked,
        displayNameCheckedCount,
      });

      for (const emergencyContactCreating of emergencyContactsCreated) {
        const emergencyContactData: CreateEmergencyContactRequestBody = {
          patientId: user.id,
          fullName: emergencyContactCreating.fullName,
          phone: emergencyContactCreating.phone,
          relationship: emergencyContactCreating.relationship,
        };
        await EmergencyContactApi.createEmergencyContact(
          user.id,
          emergencyContactData
        );
      }
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(getErrorMessage(error, 'Failed to add client.'));
      logException(error);
    }
  };

  const handlePatientDemographicsFormSubmit = async (
    values: AddPatientFormValues
  ) => {
    if (!values) {
      return;
    }

    if (!isEmailChecked) {
      await checkEmailStatus(values.email);
      if (!existingUser && !hasUnresolvedEmail()) {
        await addPatient(values);
      }
    } else {
      await addPatient(values);
    }
  };

  const sigmundEmergencyContacts = useFlag(SIGMUND_EMERGENCY_CONTACTS, false);

  const initialValues = {
    email: existingUser?.email ?? '',
    phone: formatPhoneNumber(existingUser?.phone ?? ''),
    firstName: existingUser?.firstName || '',
    lastName: existingUser?.lastName || '',
    displayFirstName: existingUser?.displayFirstName,
    displayLastName: existingUser?.displayLastName,
    hasDisplayNameChecked: false,
    sourceAttribution: undefined,
    otherSourceAttribution: '',
    contactName: '',
    contactPhone: '',
    contactRelationship: '',
  } as AddPatientFormValues;

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount={true}
      onSubmit={handlePatientDemographicsFormSubmit}
      initialValues={initialValues}
      validationSchema={PatientDemographicsFormValidationSchema}
    >
      {({ values, errors, handleBlur, setFieldValue, isSubmitting }) =>
        isSubmitting ? (
          <div className="mt-8 flex flex-col items-center gap-8">
            <SectionHeader>Saving...</SectionHeader>
            <div>
              <LogoLoader />
            </div>
          </div>
        ) : (
          <>
            <ModalContent>
              <SafeFormikForm
                id="patient-demographics-form"
                style={{
                  gap: 0,
                  height: '100%',
                }}
              >
                <PageSection>
                  <SectionHeader>Add client information</SectionHeader>
                  <div>
                    {existingUser && (
                      <div
                        css={{ marginBottom: theme.spacing.x8 }}
                        data-testid="email-already-exists-banner"
                      >
                        <GuidanceCard variant="info">
                          {isExistingProviderPatient ? (
                            <div>
                              This email address is in our system already — if
                              this is the right client, you can edit them{' '}
                              <Link
                                href={`/clients/${existingUser.id}`}
                                target="_blank"
                              >
                                here
                              </Link>
                              .
                            </div>
                          ) : (
                            <>
                              This client already has a Headway account, so you
                              don’t need to add any more details. Click
                              'Continue' to add the client to your practice.
                            </>
                          )}
                        </GuidanceCard>
                      </div>
                    )}
                    <FormRow>
                      <FormControl
                        component={TextField}
                        label="Legal first name"
                        name="firstName"
                        disabled={!!existingUser}
                      />
                      <FormControl
                        component={TextField}
                        label="Legal last name"
                        name="lastName"
                        disabled={!!existingUser}
                      />
                    </FormRow>
                    <SubBodyText color="gray">
                      Enter your client's name as it appears on their insurance
                      card so we can process claims smoothly
                    </SubBodyText>
                    <div css={{ marginTop: theme.spacing.x6 }}>
                      <FormControl
                        component={Checkbox}
                        name="hasDisplayNameChecked"
                        onChange={() =>
                          setDisplayNameCheckedCount(
                            displayNameCheckedCount + 1
                          )
                        }
                        disabled={!!existingUser}
                      >
                        <BodyText>My client goes by a different name</BodyText>
                      </FormControl>
                      {values.hasDisplayNameChecked && (
                        <div css={{ marginTop: theme.spacing.x4 }}>
                          <FormRow>
                            <FormControl
                              component={TextField}
                              label="First name"
                              name="displayFirstName"
                              disabled={!!existingUser}
                            />
                            <FormControl
                              component={TextField}
                              label="Last name"
                              name="displayLastName"
                              disabled={!!existingUser}
                            />
                          </FormRow>
                          <SubBodyText>
                            Enter the name your client goes by. This is how
                            we'll address them in communications from Headway,
                            and how they will appear in your portal.
                          </SubBodyText>
                        </div>
                      )}
                    </div>

                    <div
                      css={{
                        marginTop: theme.spacing.x4,
                        paddingBottom: theme.spacing.x8,
                        display: 'grid',
                        gap: theme.spacing.x4,
                      }}
                    >
                      <FormControl
                        component={TextField}
                        label="Email"
                        name="email"
                        onBlur={async (e: any) => {
                          if (!errors.email) {
                            await checkEmailStatus(e.target.value);
                          }
                          handleBlur(e);
                        }}
                        onChange={(e: any) => {
                          setExistingUser(undefined);
                        }}
                      />
                      {!errors.email &&
                        !existingUser &&
                        hasUnresolvedEmail() && (
                          <div css={{ marginTop: theme.spacing.x2 }}>
                            <Alert
                              color="warning"
                              icon={<WarningOutlined />}
                              css={{
                                marginBottom: theme.spacing.x2,
                              }}
                              action={
                                <React.Fragment>
                                  <MuiButton
                                    color="inherit"
                                    onClick={() => {
                                      if (suggestedEmail) {
                                        setFieldValue('email', suggestedEmail);
                                      }
                                      setSuggestedEmail(undefined);
                                      setIsEmailValid(true);
                                    }}
                                  >
                                    Yes
                                  </MuiButton>
                                  <MuiButton
                                    color="inherit"
                                    onClick={() => {
                                      if (!suggestedEmail) {
                                        setFieldValue('email', '');
                                      }
                                      setSuggestedEmail(undefined);
                                      setIsEmailValid(true);
                                    }}
                                  >
                                    No
                                  </MuiButton>
                                </React.Fragment>
                              }
                            >
                              {suggestedEmail ? (
                                <>
                                  We couldn't verify this email address. Did you
                                  mean <strong>{suggestedEmail}</strong>?
                                </>
                              ) : (
                                <>
                                  We couldn't validate this email address. Are
                                  you sure it's correct?
                                </>
                              )}
                            </Alert>
                          </div>
                        )}
                      <FormControl
                        component={PhoneNumberField}
                        label="Phone"
                        name="phone"
                        disabled={!!existingUser}
                      />
                      <FormControl
                        component={Select}
                        label="How did this client find you/your practice?"
                        selectionMode="single"
                        name="sourceAttribution"
                        menuWidth="stretch"
                        selectedKeys={
                          values.sourceAttribution
                            ? [values.sourceAttribution]
                            : []
                        }
                      >
                        {Object.keys(patientSourcedOptions).map((option) => (
                          <Item key={option}>
                            {
                              patientSourcedOptions[
                                option as Exclude<
                                  SourceAttribution,
                                  | SourceAttribution.BULK_IMPORT
                                  | SourceAttribution.OTHER
                                >
                              ]
                            }
                          </Item>
                        ))}
                      </FormControl>
                      {values.sourceAttribution === SourceAttribution.OTHER && (
                        <FormControl
                          component={TextField}
                          name="otherSourceAttribution"
                          label="Other (please specify):"
                        />
                      )}
                    </div>
                  </div>
                  {sigmundEmergencyContacts && !existingUser && (
                    <EmergencyContactFormIntakeFlow
                      provider={provider}
                      emergencyContactsCreated={emergencyContactsCreated}
                      setEmergencyContactsCreated={setEmergencyContactsCreated}
                    />
                  )}
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button variant="secondary" onPress={closeAddPatientModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                form="patient-demographics-form"
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </ModalFooter>
          </>
        )
      }
    </Formik>
  );
};
