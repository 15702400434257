import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M217.92 93.013c0 2.347 -1.92 4.373 -4.373 4.373h-10.56v37.547c0 7.573 -6.08 13.653 -13.653 13.653h-22.4l21.76 53.12c0.853 2.24 -0.107 4.8 -2.347 5.653q-0.8 0.32 -1.6 0.32c-1.707 0 -3.307 -1.067 -4.053 -2.667l-23.04 -56.427H98.56l-23.04 56.427c-0.64 1.707 -2.347 2.667 -4.053 2.667 -0.533 0 -1.067 0 -1.6 -0.32a4.235 4.235 0 0 1 -2.347 -5.653l21.76 -53.12H66.773c-7.573 0 -13.653 -6.08 -13.653 -13.653V97.387h-10.56c-2.347 0 -4.373 -1.92 -4.373 -4.373s1.92 -4.373 4.373 -4.373h13.76c2.987 0 5.547 2.453 5.547 5.547v40.747c0 2.773 2.24 5.013 5.013 5.013h122.773c2.773 0 5.013 -2.24 5.013 -5.013V94.187c0 -2.987 2.453 -5.547 5.547 -5.547h13.76c2.347 0 4.373 1.92 4.373 4.373zM77.76 122.347c2.347 0 4.373 -1.92 4.373 -4.373V59.2c0 -0.853 0.64 -1.493 1.493 -1.493h88.853c0.853 0 1.493 0.64 1.493 1.493v58.773c0 2.347 1.92 4.373 4.373 4.373s4.373 -1.92 4.373 -4.373V59.2a10.133 10.133 0 0 0 -10.133 -10.133H83.627a10.133 10.133 0 0 0 -10.133 10.133v58.773c0 2.347 1.92 4.373 4.373 4.373z" />,
  ],
]);
export const IconInPerson: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="38 98 180 60" {...props} weights={weights} />
));

IconInPerson.variants = ['outlined'];
IconInPerson.defaultVariant = 'outlined';
