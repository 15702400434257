import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { LinkButton } from '@headway/helix/LinkButton';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { logException } from '@headway/shared/utils/sentry';

import { getProductIssuesContactLink } from 'utils/contactFormLinks';

const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => (
  <div className="m-auto h-[100vh] pt-12 tablet:mt-12 tablet:h-auto">
    <div className="m-auto flex h-full flex-col gap-2 border-system-borderGray bg-system-white p-6 text-center tablet:h-auto tablet:max-w-[500px] tablet:rounded tablet:border tablet:border-solid">
      <SectionHeader>Oops! Something went wrong.</SectionHeader>
      <BodyText>Please contact us if this problem persists.</BodyText>
      <div className="mt-5 flex flex-col gap-2">
        <Button variant="brand" onPress={resetErrorBoundary}>
          Refresh page
        </Button>
        <LinkButton
          variant="secondary"
          href={getProductIssuesContactLink(error.message)}
          target="_blank"
        >
          Contact Headway Support
        </LinkButton>
      </div>
    </div>
  </div>
);

/**
 * Creates an error boundary that will catch any React render errors and display a fallback UI.
 *
 * @param providerId The current provider's id.
 */
export const ErrorBoundary = ({
  providerId,
  children,
}: {
  providerId?: string | number;
  children: React.ReactNode;
}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onReset={() => {
        window.location.reload();
      }}
      onError={(error) => {
        logException(error, {
          level: 'fatal',
          tags: { error_type: 'blank_screen' },
          user: { id: providerId },
        });
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
