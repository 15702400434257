import { Note } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNote: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={Note}
    {...props}
    variants={['filled', 'outlined']}
    ref={ref}
  />
));

IconNote.variants = ['filled', 'outlined'];
IconNote.defaultVariant = 'outlined';
