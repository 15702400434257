import { ButtonProps, Button as MuiButton } from '@mui/material';
import React, { FC } from 'react';

import { theme } from './theme';

export const Button: FC<React.PropsWithChildren<ButtonProps>> =
  React.forwardRef(({ sx, ...rest }, ref) => {
    return (
      <MuiButton
        ref={ref}
        disableElevation={true}
        sx={{
          fontFamily: theme.fontFamily.postGrotesk,
          fontWeight: theme.fontWeight.base,
          textTransform: 'none',
          borderRadius: '8px',
          '&.MuiButton-contained': {
            color: theme.color.white,
          },
          '&.MuiButton-sizeLarge': {
            fontSize: theme.fontSize.base,
          },
          '&.MuiButton-textPrimary:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          ...sx,
        }}
        {...rest}
      />
    );
  });
