import { ErrorTwoTone } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useProviderEvent } from '~/legacy/hooks/useProviderEvent';

import { theme } from '@headway/helix/theme';
import {
  getExternalPlatformChannelForEventChannel,
  getExternalPlatformDisplayName,
} from '@headway/shared/utils/externalPlatforms';

export interface PendingReferralAlertProps {
  eventId: number | string;
}

export const PendingReferralAlert = ({
  eventId,
}: PendingReferralAlertProps) => {
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventId,
  });

  let formattedEventStartTime;
  if (event && event.startDate) {
    const eventStartTimeObj = new Date(event.startDate);
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const eventWeekDay = eventStartTimeObj.toLocaleString('en-US', {
      weekday: 'long',
    });
    const eventStartTime = eventStartTimeObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: systemTimezone,
    });
    formattedEventStartTime = `${eventWeekDay} at ${eventStartTime}`;
  }

  const externalPlatformChannel = event?.channel
    ? getExternalPlatformChannelForEventChannel(event?.channel)
    : undefined;
  const isExternalPlatformAppointment = !!externalPlatformChannel;

  return (
    <Alert
      color="warning"
      icon={<ErrorTwoTone />}
      css={{ marginTop: theme.spacing.x3 }}
    >
      <div className={'font-bold'}>
        Pending{' '}
        {isExternalPlatformAppointment
          ? getExternalPlatformDisplayName(externalPlatformChannel)
          : 'referral'}{' '}
        session for {formattedEventStartTime}
      </div>
    </Alert>
  );
};
