import { Drawer } from '@mui/material';
import React from 'react';
import { useContext } from 'react';

import { IconButton } from '@headway/helix/IconButton';
import { IconX } from '@headway/helix/icons/X';
import { useMediaQuery } from '@headway/helix/utils';
import { VisuallyHidden } from '@headway/ui';
import {
  SolvvyController,
  SolvvyControllerContext,
  SolvvyControllerProvider,
} from '@headway/ui/Solvvy';
import { theme } from '@headway/ui/theme';

import { BannersContext } from 'layouts/utils';
import { calculateBannerHeight } from 'layouts/utils';

import { Navigation } from './Navigation';

function SidebarWithContext(
  props: SidebarProps & {
    solvvyController: SolvvyController | undefined;
  }
) {
  const {
    handleDrawerToggle,
    open,
    showCredForm,
    showPayerQuestionnaire,
    solvvyController,
    topOffset,
  } = props;

  const isUnderMedium = useMediaQuery(`(max-width: 960px)`);
  const links = (
    <Navigation
      showCredForm={showCredForm}
      showPayerQuestionnaire={showPayerQuestionnaire}
      solvvyController={solvvyController}
      handleDrawerToggle={handleDrawerToggle}
      fullWidth={isUnderMedium}
    />
  );

  return (
    <div>
      {isUnderMedium && (
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={open}
          css={{ ' .MuiPaper-root': { width: '100%' } }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className="flex flex-col items-end">
            <div className="pr-4 pt-4">
              <IconButton
                onPress={handleDrawerToggle}
                variant="transparent"
                size="large"
              >
                <IconX />
                <VisuallyHidden>Close</VisuallyHidden>
              </IconButton>
            </div>
            <div css={{ width: '100%' }}>{links}</div>
          </div>
        </Drawer>
      )}
      <div
        css={{
          position: 'fixed',
          left: 0,
          top: topOffset,
          width: 238,
          height: 'calc(100vh - 50px)',
          background: theme.color.white,
          display: isUnderMedium ? 'none' : 'grid',
          zIndex: 1,
        }}
      >
        {links}
      </div>
    </div>
  );
}

interface SidebarProps {
  handleDrawerToggle: () => void;
  open: boolean;
  showCredForm: boolean;
  showPayerQuestionnaire: boolean;
  solvvyController: SolvvyController | undefined;
  topOffset: number;
}

const Sidebar = (props: SidebarProps) => {
  const numBanners = useContext(BannersContext);
  const bannerOffset = calculateBannerHeight(numBanners + 1); // Add 1 for the Headway Header

  return (
    <SolvvyControllerProvider>
      <SolvvyControllerContext.Consumer>
        {({ controller: solvvyController }) => (
          <SidebarWithContext
            {...props}
            solvvyController={solvvyController}
            topOffset={bannerOffset}
          />
        )}
      </SolvvyControllerContext.Consumer>
    </SolvvyControllerProvider>
  );
};

export default Sidebar;
