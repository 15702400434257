import { NumberCircleThree } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNumberCircleThree: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={NumberCircleThree}
    defaultVariant="filled"
    ref={ref}
  />
));

IconNumberCircleThree.variants = ['filled'];
IconNumberCircleThree.defaultVariant = 'filled';
