import { makeObservable, observable } from 'mobx';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { identifyUser } from '@headway/shared/utils/analytics';
import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { notifyInfo } from '@headway/ui/utils/notify';

import { PROVIDER_VIEW_STORAGE_KEY } from 'utils/contstants';

import SigmundAuthApi from '../api/AuthApi';

let store = null;

class Store {
  constructor() {
    makeObservable(this, {
      user: observable,
      userRoles: observable,
      impersonatingUser: observable,
      impersonatingUserRoles: observable,
      provider: observable,
      isLoaded: observable,
      canViewPaymentInfo: observable,
    });
  }

  user = null;

  userRoles = null;

  // the user who is impersonating the session's user. Will equal
  // this.user if the session is not being impersonated.
  impersonatingUser = null;

  impersonatingUserRoles = null;

  provider = null;

  isLoaded = false;

  canViewPaymentInfo = false;

  latestProviderQuestionnaireId = null;

  setUser = async (user) => {
    this.user = user;
    await this.fetchMe();
  };

  fetchMe = async () => {
    let me = this.user;
    if (!me) {
      const res = await SigmundAuthApi.me();
      this.user = res.data;
      me = this.user;
    }

    this.userRoles = UserApi.getRoles(me.id);

    let providerId = undefined;
    if (me.group_practice?.id) {
      let providers = await GroupPracticeApi.getProvidersInGroupPractice(
        me.group_practice.id,
        { is_active: true }
      );

      const storedProviderView = parseInt(
        window.localStorage.getItem(PROVIDER_VIEW_STORAGE_KEY)
      );
      const storedProvider = providers.find(
        (provider) => provider.id === storedProviderView
      );

      // default to the provider in localstorage if it exists
      if (storedProviderView && storedProvider) {
        providerId = storedProvider.id;
        this.setProvider(storedProvider);
      } else {
        // otherwise default to the user's provider or the first provider
        const providerView = me.provider_id || providers[0].id;
        const provider = providers.find(
          (provider) => provider.id === providerView
        );
        providerId = provider.id;
        this.setProvider(provider);
      }
    } else if (me.provider_id) {
      const provider = await ProviderApi.getProvider(me.provider_id);
      providerId = provider.id;
      this.setProvider(provider);
    }

    this.impersonatingUser = await UserApi.getOriginalUserMe();

    this.impersonatingUserRoles = await UserApi.getRoles(
      this.impersonatingUser.id
    );

    this.isLoaded = true;

    let identifyUserTraits = {
      email: me.email,
      displayName: `${me.first_name} ${me.last_name}`,
      isLoggedIn: true,
    };
    if (providerId) {
      identifyUserTraits = {
        ...identifyUserTraits,
        providerId: providerId.toString(),
      };
    }
    // The id of the original token owner who is impersonating a provider user.
    // Not included if the session is not impersonated.
    if (this.impersonatingUser && this.impersonatingUser.id !== me.id) {
      identifyUserTraits = {
        ...identifyUserTraits,
        impersonatingUserId: this.impersonatingUser.id.toString(),
      };
    }

    identifyUser({
      id: me.id.toString(),
      isAuthenticated: true,
      traits: identifyUserTraits,
    });

    if (providerId) {
      try {
        // This is to get the Latest PQ to set for the purpose of Feature flagging based on PQ_id
        // The plan is to remove this code and retire this before 2025
        const providerQuestionnaires = await getLatestQuestionnaire(providerId);
        this.latestProviderQuestionnaireId =
          providerQuestionnaires?.selectedQuestionnaire?.id;
      } catch (e) {
        // This would only happen in the registrationPage where the invited provider does not have permission to
        // View their PQ yet
        if (e.response?.status === 403) {
          return;
        }
        throw e;
      }
    }
  };

  setProvider = (provider) => {
    this.provider = provider;
    this.canViewPaymentInfo =
      provider && (!provider.groupPracticeId || this.user.group_practice);
  };

  logout = () => {
    AuthApi.logout().finally(() => {
      this.user = null;
      this.userRoles = null;
      this.impersonatingUser = null;
      this.impersonatingUserRoles = null;
      this.provider = null;
      this.latestProviderQuestionnaireId = null;
    });
  };
}

export function initializeAuthStore() {
  if (store === null) {
    store = new Store();
  }
  return store;
}

export function useAuthStore() {
  return initializeAuthStore();
}
