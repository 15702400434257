import { CloseTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { RRule } from 'rrule';

import { parseRRule } from '@headway/shared/events/utils';
import { VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

interface EventHeaderProps {
  title: React.ReactChild;
  startDate: Date | string;
  recurrence?: string;
  onClose: () => void;
  eventHasContents?: boolean;
}

export const EventHeader: FC<React.PropsWithChildren<EventHeaderProps>> = ({
  title,
  startDate,
  recurrence,
  onClose,
  children,
  eventHasContents = true,
}) => {
  const getRecurrenceTypeString = () => {
    const [rule] = parseRRule(recurrence);
    const { options } = rule;

    if (options.freq === RRule.WEEKLY && options.interval === 1) {
      return 'Repeats weekly';
    } else if (options.freq === RRule.WEEKLY && options.interval === 2) {
      return 'Repeats every other week';
    } else if (options.freq === RRule.MONTHLY && options.interval === 1) {
      return 'Repeats monthly';
    } else if (options.freq === RRule.MONTHLY && options.interval === 3) {
      return 'Repeats every 3 months';
    } else {
      return `Repeats ${rule.toText()}`;
    }
  };

  return (
    <div
      css={{
        padding: theme.space.base,
        borderBottom: eventHasContents
          ? `1px solid ${theme.color.border}`
          : 'none',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          fontFamily: theme.fontFamily.postGrotesk,
        }}
      >
        <div>
          <h4 css={{ margin: `0 0 ${theme.space.xs2} 0` }}>{title}</h4>
          <div css={{ fontSize: theme.fontSize.sm }}>
            {moment(startDate).format('dddd [at] h:mma')}
          </div>
          {recurrence ? (
            <div css={{ fontSize: theme.fontSize.sm }}>
              {getRecurrenceTypeString()}
            </div>
          ) : null}
        </div>
        <IconButton
          onClick={onClose}
          css={{
            padding: theme.space.xs,
            margin: `-${theme.space.xs}`,
          }}
          aria-label="Close session details"
          data-testid="eventHeaderCloseButton"
          size="large"
        >
          <CloseTwoTone
            css={{
              width: 24,
              height: 24,
            }}
          />
          <VisuallyHidden>Close</VisuallyHidden>
        </IconButton>
      </div>
      {children}
    </div>
  );
};
