export interface DXCodeInfo {
  value: string;
  display: string;
}

const COMMON_DIAGNOSIS_CODES: DXCodeInfo[] = [
  {
    value: 'F10.20',
    display: `Alcohol Use Disorder, moderate`,
  },
  {
    value: 'F32.0',
    display: `Major depressive disorder, single episode, mild`,
  },
  {
    value: 'F32.1',
    display: `Major depressive disorder, single episode, moderate`,
  },
  {
    value: 'F32.9',
    display: `Unspecified depressive disorder`,
  },
  {
    value: 'F32.A',
    display: `Depression, unspecified; Depression NOS; Depression Disorder NOS`,
  },
  {
    value: 'F33.0',
    display: `Major depressive disorder, recurrent, mild`,
  },
  {
    value: 'F33.1',
    display: `Major depressive disorder, recurrent, moderate`,
  },
  {
    value: 'F33.2',
    display: `Major depressive disorder, recurrent severe without psychotic features`,
  },
  { value: 'F33.8', display: `Other recurrent depressive disorders` },
  { value: 'F34.1', display: `Dysthymic disorder` },
  { value: 'F39', display: `Unspecified mood [affective] disorder` },
  { value: 'F41.1', display: `Generalized anxiety disorder` },
  { value: 'F41.9', display: `Anxiety disorder, unspecified` },
  { value: 'F42.2', display: `Mixed obsessional thoughts and acts` },
  {
    value: 'F43.10',
    display: `Post-traumatic stress disorder, unspecified`,
  },
  {
    value: 'F43.11',
    display: `Post-traumatic stress disorder, acute`,
  },
  {
    value: 'F43.12',
    display: `Post-traumatic stress disorder, chronic`,
  },
  { value: 'F43.20', display: `Adjustment disorder, unspecified` },
  {
    value: 'F43.21',
    display: `Adjustment disorder with depressed mood`,
  },
  { value: 'F43.22', display: `Adjustment disorder with anxiety` },
  {
    value: 'F43.23',
    display: `Adjustment disorder with mixed anxiety and depressed mood`,
  },
  {
    value: 'F43.25',
    display: `Adjustment disorder with mixed disturbance of emotions and conduct`,
  },
  {
    value: 'F90.0',
    display: `Attention-deficit hyperactivity disorder, predominantly inattentive type`,
  },
  {
    value: 'F90.1',
    display: `Attention-deficit hyperactivity disorder, predominantly hyperactive type`,
  },
  {
    value: 'F90.2',
    display: `Attention-deficit hyperactivity disorder, combined type`,
  },
];

const LESS_COMMON_DIAGNOSIS_CODES: DXCodeInfo[] = [
  {
    value: 'E66.09',
    display: `Other obesity due to excess calories`,
  },
  {
    value: 'E66.9',
    display: `Obesity, unspecified`,
  },
  {
    value: 'F01.50',
    display: `Vascular dementia without behavioral disturbance`,
  },
  {
    value: 'F01.511',
    display: `Vascular dementia, unspecified severity, with agitation; Major neurocognitive disorder due to vascular disease, unspecified severity, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Major neurocognitive disorder due to vascular disease, unspecified severity, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence; Vascular dementia, unspecified severity, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Vascular dementia, unspecified severity, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence`,
  },
  {
    value: 'F01.518',
    display: `Vascular dementia, unspecified severity, with other behavioral disturbance; Major neurocognitive disorder due to vascular disease, unspecified severity, with behavioral disturbances such as sleep disturbance, social disinhibition, or sexual disinhibition: Vascular dementia, unspecified severity, with behavioral disturbances such as sleep disturbance, social disinhibition, or sexual disinhibition`,
  },
  {
    value: 'F02.80',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety; Dementia in other diseases classified elsewhere NOS; Major neurocognitive disorder in other diseases classified elsewhere NOS`,
  },
  {
    value: 'F02.811',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, with agitation; Dementia in other diseases classified elsewhere, unspecified severity, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking: Dementia in other diseases classified elsewhere, unspecified severity, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence; Major neurocognitive disorder in other diseases classified elsewhere, unspecified severity, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Major neurocognitive disorder in other diseases classified elsewhere, unspecified severity, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence`,
  },
  {
    value: 'F02.818',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance; Dementia in other diseases classified elsewhere with sleep disturbance, social disinhibition, or sexual disinhibition; Major neurocognitive disorder in other diseases classified elsewhere with sleep disturbance, social disinhibition, or sexual disinhibition`,
  },
  {
    value: 'F02.82',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance; Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance such as hallucinations, paranoia, suspiciousness, or delusional state; Major neurocognitive disorder in other diseases classified elsewhere, unspecified, with psychotic disturbance such as hallucinations, paranoia, suspiciousness, or delusional state`,
  },
  {
    value: 'F02.83',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance; Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance such as depression, apathy, or anhedonia; Major neurocognitive disorder in other diseases classified elsewhere unspecified severity, with mood disturbance such as with depression, apathy, or anhedonia`,
  },
  {
    value: 'F02.84',
    display: `Dementia in other diseases classified elsewhere, unspecified severity, with anxiety; Major neurocognitive disorder in other diseases classified elsewhere unspecified severity, with anxiety`,
  },
  {
    value: 'F02.A0',
    display: `Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety; Dementia in other diseases classified elsewhere, mild, NOS; Major neurocognitive disorder in other diseases classified elsewhere, mild, NOS`,
  },
  {
    value: 'F02.A11',
    display: `Dementia in other diseases classified elsewhere, mild, with agitation; Dementia in other diseases classified elsewhere, mild, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Dementia in other diseases classified elsewhere, mild, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence; Major neurocognitive disorder in other diseases classified elsewhere, mild, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Major neurocognitive disorder in other diseases classified elsewhere, mild, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence`,
  },
  {
    value: `F02.A18`,
    display: `Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance; Dementia in other diseases classified elsewhere, mild, with behavioral disturbances such as sleep disturbance, social disinhibition, or sexual disinhibition; Major neurocognitive disorder in other diseases classified elsewhere, mild, with behavioral disturbances such as sleep disturbance, social disinhibition, or sexual disinhibition`,
  },
  {
    value: `F02.A2`,
    display: `Dementia in other diseases classified elsewhere, mild, with psychotic disturbance; Dementia in other diseases classified elsewhere, mild, with psychotic disturbance such as hallucinations, paranoia, suspiciousness, or delusional state; Major neurocognitive disorder in other diseases classified elsewhere, mild, with psychotic disturbance such as hallucinations, paranoia, suspiciousness, or delusional state`,
  },
  {
    value: `F02.A3`,
    display: `Dementia in other diseases classified elsewhere, mild, with mood disturbance; Dementia in other diseases classified elsewhere, mild, with mood disturbance such as depression, apathy, or anhedonia; Major neurocognitive disorder in other diseases classified elsewhere, mild, with mood disturbance such as depression, apathy, or anhedonia`,
  },
  {
    value: `F02.A4`,
    display: `Dementia in other diseases classified elsewhere, mild, with anxiety; Major neurocognitive disorder in other diseases classified elsewhere, mild, with anxiety`,
  },
  {
    value: `F03.90`,
    display: `Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety: Dementia NOS`,
  },
  {
    value: `F03.911 `,
    display: `Unspecified dementia, unspecified severity, with agitation; Unspecified dementia, unspecified severity, with aberrant motor behavior such as restlessness, rocking, pacing, or exit-seeking; Unspecified dementia, unspecified severity, with verbal or physical behaviors such as profanity, shouting, threatening, anger, aggression, combativeness, or violence`,
  },
  {
    value: `F03.918`,
    display: `Unspecified dementia, unspecified severity, with other behavioral disturbance; Unspecified dementia, unspecified severity, with behavioral disturbances such as sleep disturbance, social disinhibition, or sexual disinhibition`,
  },
  {
    value: 'F04',
    display: `Amnestic disorder due to known physiological condition`,
  },
  {
    value: 'F05',
    display: `Delirium due to known physiological condition`,
  },
  {
    value: 'F06.0',
    display: `Psychotic disorder with hallucinations due to known physiological condition`,
  },
  {
    value: 'F06.1',
    display: `Catatonic disorder due to known physiological condition`,
  },
  {
    value: 'F06.2',
    display: `Psychotic disorder with delusions due to known physiological condition`,
  },
  {
    value: 'F06.30',
    display: `Mood disorder due to known physiological condition, unspecified`,
  },
  {
    value: 'F06.31',
    display: `Mood disorder due to known physiological condition with depressive features`,
  },
  {
    value: 'F06.32',
    display: `Mood disorder due to known physiological condition with major depressive-like episode`,
  },
  {
    value: 'F06.33',
    display: `Mood disorder due to known physiological condition with manic features`,
  },
  {
    value: 'F06.34',
    display: `Mood disorder due to known physiological condition with mixed features`,
  },
  {
    value: 'F06.4',
    display: `Anxiety disorder due to known physiological condition`,
  },
  {
    value: 'F06.8',
    display: `Other specified mental disorders due to known physiological condition`,
  },
  {
    value: 'F07.0',
    display: `Personality change due to known physiological condition`,
  },
  { value: 'F07.81', display: `Postconcussional syndrome` },
  {
    value: 'F07.89',
    display: `Other personality and behavioral disorders due to known physiological condition`,
  },
  {
    value: 'F07.9',
    display: `Unspecified personality and behavioral disorder due to known physiological condition`,
  },
  {
    value: 'F09',
    display: `Unspecified mental disorder due to known physiological condition`,
  },
  {
    value: `F10.10`,
    display: `Alcohol abuse, uncomplicated; Alcohol use disorder, mild`,
  },
  {
    value: `F10.11`,
    display: `Alcohol abuse, in remission; Alcohol use disorder, mild, in early remission; Alcohol use disorder, mild, in sustained remission`,
  },
  {
    value: 'F10.120',
    display: `Alcohol abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F10.121',
    display: `Alcohol abuse with intoxication delirium`,
  },
  {
    value: 'F10.129',
    display: `Alcohol abuse with intoxication, unspecified`,
  },
  {
    value: 'F10.14',
    display: `Alcohol abuse with alcohol-induced mood disorder`,
  },
  {
    value: 'F10.150',
    display: `Alcohol abuse with alcohol-induced psychotic disorder with delusions`,
  },
  {
    value: 'F10.151',
    display: `Alcohol abuse with alcohol-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F10.159',
    display: `Alcohol abuse with alcohol-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F10.180',
    display: `Alcohol abuse with alcohol-induced anxiety disorder`,
  },
  {
    value: 'F10.181',
    display: `Alcohol abuse with alcohol-induced sexual dysfunction`,
  },
  {
    value: 'F10.182',
    display: `Alcohol abuse with alcohol-induced sleep disorder`,
  },
  {
    value: 'F10.188',
    display: `Alcohol abuse with other alcohol-induced disorder`,
  },
  {
    value: 'F10.19',
    display: `Alcohol abuse with unspecified alcohol-induced disorder`,
  },
  {
    value: `F10.21`,
    display: `Alcohol dependence, in remission; Alcohol use disorder, moderate, in early remission; Alcohol use disorder, moderate, in sustained remission; Alcohol use disorder, severe, in early remission; Alcohol use disorder, severe, in sustained remission`,
  },
  {
    value: 'F10.220',
    display: `Alcohol dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F10.221',
    display: `Alcohol dependence with intoxication delirium`,
  },
  {
    value: 'F10.229',
    display: `Alcohol dependence with intoxication, unspecified`,
  },
  {
    value: 'F10.230',
    display: `Alcohol dependence with withdrawal, uncomplicated`,
  },
  {
    value: 'F10.231',
    display: `Alcohol dependence with withdrawal delirium`,
  },
  {
    value: 'F10.232',
    display: `Alcohol dependence with withdrawal with perceptual disturbance`,
  },
  {
    value: 'F10.239',
    display: `Alcohol dependence with withdrawal, unspecified`,
  },
  {
    value: 'F10.24',
    display: `Alcohol dependence with alcohol-induced mood disorder`,
  },
  {
    value: 'F10.250',
    display: `Alcohol dependence with alcohol-induced psychotic disorder with delusions`,
  },
  {
    value: 'F10.251',
    display: `Alcohol dependence with alcohol-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F10.259',
    display: `Alcohol dependence with alcohol-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F10.26',
    display: `Alcohol dependence with alcohol-induced persisting amnestic disorder`,
  },
  {
    value: 'F10.27',
    display: `Alcohol dependence with alcohol-induced persisting dementia`,
  },
  {
    value: 'F10.280',
    display: `Alcohol dependence with alcohol-induced anxiety disorder`,
  },
  {
    value: 'F10.281',
    display: `Alcohol dependence with alcohol-induced sexual dysfunction`,
  },
  {
    value: 'F10.282',
    display: `Alcohol dependence with alcohol-induced sleep disorder`,
  },
  {
    value: 'F10.288',
    display: `Alcohol dependence with other alcohol-induced disorder`,
  },
  {
    value: 'F10.29',
    display: `Alcohol dependence with unspecified alcohol-induced disorder`,
  },
  {
    value: `F10.90`,
    display: `Alcohol use, unspecified, uncomplicated`,
  },
  {
    value: `F10.91`,
    display: `Alcohol use, unspecified, in remission`,
  },
  {
    value: 'F10.920',
    display: `Alcohol use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F10.921',
    display: `Alcohol use, unspecified with intoxication delirium`,
  },
  {
    value: 'F10.929',
    display: `Alcohol use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F10.94',
    display: `Alcohol use, unspecified with alcohol-induced mood disorder`,
  },
  {
    value: 'F10.950',
    display: `Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions`,
  },
  {
    value: 'F10.951',
    display: `Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F10.959',
    display: `Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F10.96',
    display: `Alcohol use, unspecified with alcohol-induced persisting amnestic disorder`,
  },
  {
    value: 'F10.97',
    display: `Alcohol use, unspecified with alcohol-induced persisting dementia`,
  },
  {
    value: 'F10.980',
    display: `Alcohol use, unspecified with alcohol-induced anxiety disorder`,
  },
  {
    value: 'F10.981',
    display: `Alcohol use, unspecified with alcohol-induced sexual dysfunction`,
  },
  {
    value: 'F10.982',
    display: `Alcohol use, unspecified with alcohol-induced sleep disorder`,
  },
  {
    value: 'F10.988',
    display: `Alcohol use, unspecified with other alcohol-induced disorder`,
  },
  {
    value: 'F10.99',
    display: `Alcohol use, unspecified with unspecified alcohol-induced disorder`,
  },
  { value: 'F11.10', display: `Opioid abuse, uncomplicated` },
  { value: 'F11.11', display: `Opioid abuse, in remission` },
  {
    value: 'F11.120',
    display: `Opioid abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F11.121',
    display: `Opioid abuse with intoxication delirium`,
  },
  {
    value: 'F11.122',
    display: `Opioid abuse with intoxication with perceptual disturbance`,
  },
  {
    value: 'F11.129',
    display: `Opioid abuse with intoxication, unspecified`,
  },
  {
    value: 'F11.14',
    display: `Opioid abuse with opioid-induced mood disorder`,
  },
  {
    value: 'F11.150',
    display: `Opioid abuse with opioid-induced psychotic disorder with delusions`,
  },
  {
    value: 'F11.151',
    display: `Opioid abuse with opioid-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F11.159',
    display: `Opioid abuse with opioid-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F11.181',
    display: `Opioid abuse with opioid-induced sexual dysfunction`,
  },
  {
    value: 'F11.182',
    display: `Opioid abuse with opioid-induced sleep disorder`,
  },
  {
    value: 'F11.188',
    display: `Opioid abuse with other opioid-induced disorder`,
  },
  {
    value: 'F11.19',
    display: `Opioid abuse with unspecified opioid-induced disorder`,
  },
  { value: 'F11.20', display: `Opioid dependence, uncomplicated` },
  { value: 'F11.21', display: `Opioid dependence, in remission` },
  {
    value: 'F11.220',
    display: `Opioid dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F11.221',
    display: `Opioid dependence with intoxication delirium`,
  },
  {
    value: 'F11.222',
    display: `Opioid dependence with intoxication with perceptual disturbance`,
  },
  {
    value: 'F11.229',
    display: `Opioid dependence with intoxication, unspecified`,
  },
  { value: 'F11.23', display: `Opioid dependence with withdrawal` },
  {
    value: `F11.24`,
    display: `Opioid dependence with opioid-induced mood disorder; Opioid use disorder, moderate, with opioid induced depressive disorder`,
  },
  {
    value: 'F11.250',
    display: `Opioid dependence with opioid-induced psychotic disorder with delusions`,
  },
  {
    value: 'F11.251',
    display: `Opioid dependence with opioid-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F11.259',
    display: `Opioid dependence with opioid-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F11.281',
    display: `Opioid dependence with opioid-induced sexual dysfunction`,
  },
  {
    value: 'F11.282',
    display: `Opioid dependence with opioid-induced sleep disorder`,
  },
  {
    value: 'F11.288',
    display: `Opioid dependence with other opioid-induced disorder`,
  },
  {
    value: 'F11.29',
    display: `Opioid dependence with unspecified opioid-induced disorder`,
  },
  {
    value: 'F11.90',
    display: `Opioid use, unspecified, uncomplicated`,
  },
  {
    value: `F11.91`,
    display: `Opioid use, unspecified, in remission`,
  },
  {
    value: 'F11.920',
    display: `Opioid use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F11.921',
    display: `Opioid use, unspecified with intoxication delirium`,
  },
  {
    value: 'F11.922',
    display: `Opioid use, unspecified with intoxication with perceptual disturbance`,
  },
  {
    value: 'F11.929',
    display: `Opioid use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F11.93',
    display: `Opioid use, unspecified with withdrawal`,
  },
  {
    value: 'F11.94',
    display: `Opioid use, unspecified with opioid-induced mood disorder`,
  },
  {
    value: 'F11.950',
    display: `Opioid use, unspecified with opioid-induced psychotic disorder with delusions`,
  },
  {
    value: 'F11.951',
    display: `Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F11.959',
    display: `Opioid use, unspecified with opioid-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F11.981',
    display: `Opioid use, unspecified with opioid-induced sexual dysfunction`,
  },
  {
    value: 'F11.982',
    display: `Opioid use, unspecified with opioid-induced sleep disorder`,
  },
  {
    value: 'F11.988',
    display: `Opioid use, unspecified with other opioid-induced disorder`,
  },
  {
    value: 'F11.99',
    display: `Opioid use, unspecified with unspecified opioid-induced disorder`,
  },
  { value: 'F12.10', display: `Cannabis abuse, uncomplicated` },
  { value: 'F12.11', display: `Cannabis abuse, in remission` },
  {
    value: 'F12.120',
    display: `Cannabis abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F12.121',
    display: `Cannabis abuse with intoxication delirium`,
  },
  {
    value: 'F12.122',
    display: `Cannabis abuse with intoxication with perceptual disturbance`,
  },
  {
    value: 'F12.129',
    display: `Cannabis abuse with intoxication, unspecified`,
  },
  {
    value: 'F12.150',
    display: `Cannabis abuse with psychotic disorder with delusions`,
  },
  {
    value: 'F12.151',
    display: `Cannabis abuse with psychotic disorder with hallucinations`,
  },
  {
    value: 'F12.159',
    display: `Cannabis abuse with psychotic disorder, unspecified`,
  },
  {
    value: 'F12.180',
    display: `Cannabis abuse with cannabis-induced anxiety disorder`,
  },
  {
    value: 'F12.188',
    display: `Cannabis abuse with other cannabis-induced disorder`,
  },
  {
    value: 'F12.19',
    display: `Cannabis abuse with unspecified cannabis-induced disorder`,
  },
  { value: 'F12.20', display: `Cannabis dependence, uncomplicated` },
  { value: 'F12.21', display: `Cannabis dependence, in remission` },
  {
    value: 'F12.220',
    display: `Cannabis dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F12.221',
    display: `Cannabis dependence with intoxication delirium`,
  },
  {
    value: 'F12.222',
    display: `Cannabis dependence with intoxication with perceptual disturbance`,
  },
  {
    value: 'F12.229',
    display: `Cannabis dependence with intoxication, unspecified`,
  },
  { value: 'F12.23', display: `Cannabis dependence with withdrawal` },
  {
    value: 'F12.250',
    display: `Cannabis dependence with psychotic disorder with delusions`,
  },
  {
    value: 'F12.251',
    display: `Cannabis dependence with psychotic disorder with hallucinations`,
  },
  {
    value: 'F12.259',
    display: `Cannabis dependence with psychotic disorder, unspecified`,
  },
  {
    value: 'F12.280',
    display: `Cannabis dependence with cannabis-induced anxiety disorder`,
  },
  {
    value: 'F12.288',
    display: `Cannabis dependence with other cannabis-induced disorder`,
  },
  {
    value: 'F12.29',
    display: `Cannabis dependence with unspecified cannabis-induced disorder`,
  },
  {
    value: 'F12.90',
    display: `Cannabis use, unspecified, uncomplicated`,
  },
  {
    value: `F12.91`,
    display: `Cannabis use, unspecified, in remission`,
  },
  {
    value: 'F12.920',
    display: `Cannabis use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F12.921',
    display: `Cannabis use, unspecified with intoxication delirium`,
  },
  {
    value: 'F12.922',
    display: `Cannabis use, unspecified with intoxication with perceptual disturbance`,
  },
  {
    value: 'F12.929',
    display: `Cannabis use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F12.93',
    display: `Cannabis use, unspecified with withdrawal`,
  },
  {
    value: 'F12.950',
    display: `Cannabis use, unspecified with psychotic disorder with delusions`,
  },
  {
    value: 'F12.951',
    display: `Cannabis use, unspecified with psychotic disorder with hallucinations`,
  },
  {
    value: 'F12.959',
    display: `Cannabis use, unspecified with psychotic disorder, unspecified`,
  },
  {
    value: 'F12.980',
    display: `Cannabis use, unspecified with anxiety disorder`,
  },
  {
    value: 'F12.988',
    display: `Cannabis use, unspecified with other cannabis-induced disorder`,
  },
  {
    value: 'F12.99',
    display: `Cannabis use, unspecified with unspecified cannabis-induced disorder`,
  },
  {
    value: 'F13.10',
    display: `Sedative, hypnotic or anxiolytic abuse, uncomplicated`,
  },
  {
    value: 'F13.11',
    display: `Sedative, hypnotic or anxiolytic abuse, in remission`,
  },
  {
    value: 'F13.120',
    display: `Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F13.121',
    display: `Sedative, hypnotic or anxiolytic abuse with intoxication delirium`,
  },
  {
    value: 'F13.129',
    display: `Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified`,
  },
  {
    value: 'F13.14',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder`,
  },
  {
    value: 'F13.150',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions`,
  },
  {
    value: 'F13.151',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F13.159',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F13.180',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder`,
  },
  {
    value: 'F13.181',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction`,
  },
  {
    value: 'F13.182',
    display: `Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder`,
  },
  {
    value: 'F13.188',
    display: `Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder`,
  },
  {
    value: 'F13.19',
    display: `Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder`,
  },
  {
    value: 'F13.20',
    display: `Sedative, hypnotic or anxiolytic dependence, uncomplicated`,
  },
  {
    value: 'F13.21',
    display: `Sedative, hypnotic or anxiolytic dependence, in remission`,
  },
  {
    value: 'F13.220',
    display: `Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F13.221',
    display: `Sedative, hypnotic or anxiolytic dependence with intoxication delirium`,
  },
  {
    value: 'F13.229',
    display: `Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified`,
  },
  {
    value: 'F13.230',
    display: `Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated`,
  },
  {
    value: 'F13.231',
    display: `Sedative, hypnotic or anxiolytic dependence with withdrawal delirium`,
  },
  {
    value: 'F13.232',
    display: `Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance`,
  },
  {
    value: 'F13.239',
    display: `Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified`,
  },
  {
    value: 'F13.24',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder`,
  },
  {
    value: 'F13.250',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions`,
  },
  {
    value: 'F13.251',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F13.259',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F13.26',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder`,
  },
  {
    value: 'F13.27',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia`,
  },
  {
    value: 'F13.280',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder`,
  },
  {
    value: 'F13.281',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction`,
  },
  {
    value: 'F13.282',
    display: `Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder`,
  },
  {
    value: 'F13.288',
    display: `Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder`,
  },
  {
    value: 'F13.29',
    display: `Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder`,
  },
  {
    value: 'F13.90',
    display: `Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated`,
  },
  {
    value: `F13.91`,
    display: `Sedative, hypnotic or anxiolytic use, unspecified, in remission`,
  },
  {
    value: 'F13.920',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F13.921',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium`,
  },
  {
    value: 'F13.929',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F13.930',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated`,
  },
  {
    value: 'F13.931',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium`,
  },
  {
    value: 'F13.932',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances`,
  },
  {
    value: 'F13.939',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified`,
  },
  {
    value: 'F13.94',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder`,
  },
  {
    value: 'F13.950',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions`,
  },
  {
    value: 'F13.951',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F13.959',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F13.96',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder`,
  },
  {
    value: 'F13.97',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia`,
  },
  {
    value: 'F13.980',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder`,
  },
  {
    value: 'F13.981',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction`,
  },
  {
    value: 'F13.982',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder`,
  },
  {
    value: 'F13.988',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder`,
  },
  {
    value: 'F13.99',
    display: `Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder`,
  },
  { value: 'F14.10', display: `Cocaine abuse, uncomplicated` },
  { value: 'F14.11', display: `Cocaine abuse, in remission` },
  {
    value: 'F14.120',
    display: `Cocaine abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F14.121',
    display: `Cocaine abuse with intoxication with delirium`,
  },
  {
    value: 'F14.122',
    display: `Cocaine abuse with intoxication with perceptual disturbance`,
  },
  {
    value: 'F14.129',
    display: `Cocaine abuse with intoxication, unspecified`,
  },
  {
    value: 'F14.14',
    display: `Cocaine abuse with cocaine-induced mood disorder`,
  },
  {
    value: 'F14.150',
    display: `Cocaine abuse with cocaine-induced psychotic disorder with delusions`,
  },
  {
    value: 'F14.151',
    display: `Cocaine abuse with cocaine-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F14.159',
    display: `Cocaine abuse with cocaine-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F14.180',
    display: `Cocaine abuse with cocaine-induced anxiety disorder`,
  },
  {
    value: 'F14.181',
    display: `Cocaine abuse with cocaine-induced sexual dysfunction`,
  },
  {
    value: 'F14.182',
    display: `Cocaine abuse with cocaine-induced sleep disorder`,
  },
  {
    value: 'F14.188',
    display: `Cocaine abuse with other cocaine-induced disorder`,
  },
  {
    value: 'F14.19',
    display: `Cocaine abuse with unspecified cocaine-induced disorder`,
  },
  { value: 'F14.20', display: `Cocaine dependence, uncomplicated` },
  { value: 'F14.21', display: `Cocaine dependence, in remission` },
  {
    value: 'F14.220',
    display: `Cocaine dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F14.221',
    display: `Cocaine dependence with intoxication delirium`,
  },
  {
    value: 'F14.222',
    display: `Cocaine dependence with intoxication with perceptual disturbance`,
  },
  {
    value: 'F14.229',
    display: `Cocaine dependence with intoxication, unspecified`,
  },
  {
    value: `F14.23`,
    display: `Cocaine dependence with withdrawal`,
  },
  {
    value: 'F14.24',
    display: `Cocaine dependence with cocaine-induced mood disorder`,
  },
  {
    value: 'F14.250',
    display: `Cocaine dependence with cocaine-induced psychotic disorder with delusions`,
  },
  {
    value: 'F14.251',
    display: `Cocaine dependence with cocaine-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F14.259',
    display: `Cocaine dependence with cocaine-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F14.280',
    display: `Cocaine dependence with cocaine-induced anxiety disorder`,
  },
  {
    value: 'F14.281',
    display: `Cocaine dependence with cocaine-induced sexual dysfunction`,
  },
  {
    value: 'F14.282',
    display: `Cocaine dependence with cocaine-induced sleep disorder`,
  },
  {
    value: 'F14.288',
    display: `Cocaine dependence with other cocaine-induced disorder`,
  },
  {
    value: 'F14.29',
    display: `Cocaine dependence with unspecified cocaine-induced disorder`,
  },
  {
    value: 'F14.90',
    display: `Cocaine use, unspecified, uncomplicated`,
  },
  {
    value: `F14.91`,
    display: `Cocaine use, unspecified, in remission`,
  },
  {
    value: 'F14.920',
    display: `Cocaine use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F14.921',
    display: `Cocaine use, unspecified with intoxication delirium`,
  },
  {
    value: 'F14.922',
    display: `Cocaine use, unspecified with intoxication with perceptual disturbance`,
  },
  {
    value: 'F14.929',
    display: `Cocaine use, unspecified with intoxication, unspecified`,
  },
  {
    value: `F14.93`,
    display: `Cocaine use, unspecified with withdrawal`,
  },
  {
    value: 'F14.94',
    display: `Cocaine use, unspecified with cocaine-induced mood disorder`,
  },
  {
    value: 'F14.950',
    display: `Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions`,
  },
  {
    value: 'F14.951',
    display: `Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F14.959',
    display: `Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F14.980',
    display: `Cocaine use, unspecified with cocaine-induced anxiety disorder`,
  },
  {
    value: 'F14.981',
    display: `Cocaine use, unspecified with cocaine-induced sexual dysfunction`,
  },
  {
    value: 'F14.982',
    display: `Cocaine use, unspecified with cocaine-induced sleep disorder`,
  },
  {
    value: 'F14.988',
    display: `Cocaine use, unspecified with other cocaine-induced disorder`,
  },
  {
    value: 'F14.99',
    display: `Cocaine use, unspecified with unspecified cocaine-induced disorder`,
  },
  { value: 'F15.10', display: `Other stimulant abuse, uncomplicated` },
  { value: 'F15.11', display: `Other stimulant abuse, in remission` },
  {
    value: 'F15.120',
    display: `Other stimulant abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F15.121',
    display: `Other stimulant abuse with intoxication delirium`,
  },
  {
    value: 'F15.122',
    display: `Other stimulant abuse with intoxication with perceptual disturbance`,
  },
  {
    value: 'F15.129',
    display: `Other stimulant abuse with intoxication, unspecified`,
  },
  {
    value: `F15.13`,
    display: `Other stimulant abuse with withdrawal`,
  },
  {
    value: 'F15.14',
    display: `Other stimulant abuse with stimulant-induced mood disorder`,
  },
  {
    value: 'F15.150',
    display: `Other stimulant abuse with stimulant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F15.151',
    display: `Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F15.159',
    display: `Other stimulant abuse with stimulant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F15.180',
    display: `Other stimulant abuse with stimulant-induced anxiety disorder`,
  },
  {
    value: 'F15.181',
    display: `Other stimulant abuse with stimulant-induced sexual dysfunction`,
  },
  {
    value: 'F15.182',
    display: `Other stimulant abuse with stimulant-induced sleep disorder`,
  },
  {
    value: 'F15.188',
    display: `Other stimulant abuse with other stimulant-induced disorder`,
  },
  {
    value: 'F15.19',
    display: `Other stimulant abuse with unspecified stimulant-induced disorder`,
  },
  {
    value: 'F15.20',
    display: `Other stimulant dependence, uncomplicated`,
  },
  {
    value: 'F15.21',
    display: `Other stimulant dependence, in remission`,
  },
  {
    value: 'F15.220',
    display: `Other stimulant dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F15.221',
    display: `Other stimulant dependence with intoxication delirium`,
  },
  {
    value: 'F15.222',
    display: `Other stimulant dependence with intoxication with perceptual disturbance`,
  },
  {
    value: 'F15.229',
    display: `Other stimulant dependence with intoxication, unspecified`,
  },
  {
    value: 'F15.23',
    display: `Other stimulant dependence with withdrawal`,
  },
  {
    value: 'F15.24',
    display: `Other stimulant dependence with stimulant-induced mood disorder`,
  },
  {
    value: 'F15.250',
    display: `Other stimulant dependence with stimulant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F15.251',
    display: `Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F15.259',
    display: `Other stimulant dependence with stimulant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F15.280',
    display: `Other stimulant dependence with stimulant-induced anxiety disorder`,
  },
  {
    value: 'F15.281',
    display: `Other stimulant dependence with stimulant-induced sexual dysfunction`,
  },
  {
    value: 'F15.282',
    display: `Other stimulant dependence with stimulant-induced sleep disorder`,
  },
  {
    value: 'F15.288',
    display: `Other stimulant dependence with other stimulant-induced disorder`,
  },
  {
    value: 'F15.29',
    display: `Other stimulant dependence with unspecified stimulant-induced disorder`,
  },
  {
    value: 'F15.90',
    display: `Other stimulant use, unspecified, uncomplicated`,
  },
  {
    value: `F15.91`,
    display: `Other stimulant use, unspecified, in remission`,
  },
  {
    value: 'F15.920',
    display: `Other stimulant use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F15.921',
    display: `Other stimulant use, unspecified with intoxication delirium`,
  },
  {
    value: 'F15.922',
    display: `Other stimulant use, unspecified with intoxication with perceptual disturbance`,
  },
  {
    value: 'F15.929',
    display: `Other stimulant use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F15.93',
    display: `Other stimulant use, unspecified with withdrawal`,
  },
  {
    value: 'F15.94',
    display: `Other stimulant use, unspecified with stimulant-induced mood disorder`,
  },
  {
    value: 'F15.950',
    display: `Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F15.951',
    display: `Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F15.959',
    display: `Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F15.980',
    display: `Other stimulant use, unspecified with stimulant-induced anxiety disorder`,
  },
  {
    value: 'F15.981',
    display: `Other stimulant use, unspecified with stimulant-induced sexual dysfunction`,
  },
  {
    value: 'F15.982',
    display: `Other stimulant use, unspecified with stimulant-induced sleep disorder`,
  },
  {
    value: 'F15.988',
    display: `Other stimulant use, unspecified with other stimulant-induced disorder`,
  },
  {
    value: 'F15.99',
    display: `Other stimulant use, unspecified with unspecified stimulant-induced disorder`,
  },
  { value: 'F16.10', display: `Hallucinogen abuse, uncomplicated` },
  {
    value: `F16.11`,
    display: `Hallucinogen abuse, in remission; Other hallucinogen use disorder, mild, in early remission; Other hallucinogen use disorder, mild, in sustained remission; Phencyclidine use disorder, mild, in early remission; Phencyclidine use disorder, mild, in sustained remission`,
  },
  {
    value: 'F16.120',
    display: `Hallucinogen abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F16.121',
    display: `Hallucinogen abuse with intoxication with delirium`,
  },
  {
    value: 'F16.122',
    display: `Hallucinogen abuse with intoxication with perceptual disturbance`,
  },
  {
    value: 'F16.129',
    display: `Hallucinogen abuse with intoxication, unspecified`,
  },
  {
    value: 'F16.14',
    display: `Hallucinogen abuse with hallucinogen-induced mood disorder`,
  },
  {
    value: 'F16.150',
    display: `Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions`,
  },
  {
    value: 'F16.151',
    display: `Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F16.159',
    display: `Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F16.180',
    display: `Hallucinogen abuse with hallucinogen-induced anxiety disorder`,
  },
  {
    value: 'F16.183',
    display: `Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)`,
  },
  {
    value: 'F16.188',
    display: `Hallucinogen abuse with other hallucinogen-induced disorder`,
  },
  {
    value: 'F16.19',
    display: `Hallucinogen abuse with unspecified hallucinogen-induced disorder`,
  },
  {
    value: 'F16.20',
    display: `Hallucinogen dependence, uncomplicated`,
  },
  {
    value: 'F16.21',
    display: `Hallucinogen dependence, in remission`,
  },
  {
    value: 'F16.220',
    display: `Hallucinogen dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F16.221',
    display: `Hallucinogen dependence with intoxication with delirium`,
  },
  {
    value: 'F16.229',
    display: `Hallucinogen dependence with intoxication, unspecified`,
  },
  {
    value: 'F16.24',
    display: `Hallucinogen dependence with hallucinogen-induced mood disorder`,
  },
  {
    value: 'F16.250',
    display: `Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions`,
  },
  {
    value: 'F16.251',
    display: `Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F16.259',
    display: `Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F16.280',
    display: `Hallucinogen dependence with hallucinogen-induced anxiety disorder`,
  },
  {
    value: 'F16.283',
    display: `Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)`,
  },
  {
    value: 'F16.288',
    display: `Hallucinogen dependence with other hallucinogen-induced disorder`,
  },
  {
    value: 'F16.29',
    display: `Hallucinogen dependence with unspecified hallucinogen-induced disorder`,
  },
  {
    value: 'F16.90',
    display: `Hallucinogen use, unspecified, uncomplicated`,
  },
  {
    value: `F16.91`,
    display: `Hallucinogen use, unspecified, in remission`,
  },
  {
    value: 'F16.920',
    display: `Hallucinogen use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F16.921',
    display: `Hallucinogen use, unspecified with intoxication with delirium`,
  },
  {
    value: 'F16.929',
    display: `Hallucinogen use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F16.94',
    display: `Hallucinogen use, unspecified with hallucinogen-induced mood disorder`,
  },
  {
    value: 'F16.950',
    display: `Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions`,
  },
  {
    value: 'F16.951',
    display: `Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F16.959',
    display: `Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F16.980',
    display: `Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder`,
  },
  {
    value: 'F16.983',
    display: `Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)`,
  },
  {
    value: 'F16.988',
    display: `Hallucinogen use, unspecified with other hallucinogen-induced disorder`,
  },
  {
    value: 'F16.99',
    display: `Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder`,
  },
  {
    value: 'F17.200',
    display: `Nicotine dependence, unspecified, uncomplicated`,
  },
  {
    value: 'F17.201',
    display: `Nicotine dependence, unspecified, in remission`,
  },
  {
    value: 'F17.203',
    display: `Nicotine dependence unspecified, with withdrawal`,
  },
  {
    value: 'F17.208',
    display: `Nicotine dependence, unspecified, with other nicotine-induced disorders`,
  },
  {
    value: 'F17.209',
    display: `Nicotine dependence, unspecified, with unspecified nicotine-induced disorders`,
  },
  {
    value: 'F17.210',
    display: `Nicotine dependence, cigarettes, uncomplicated`,
  },
  {
    value: 'F17.211',
    display: `Nicotine dependence, cigarettes, in remission`,
  },
  {
    value: 'F17.213',
    display: `Nicotine dependence, cigarettes, with withdrawal`,
  },
  {
    value: 'F17.218',
    display: `Nicotine dependence, cigarettes, with other nicotine-induced disorders`,
  },
  {
    value: 'F17.219',
    display: `Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders`,
  },
  {
    value: 'F17.220',
    display: `Nicotine dependence, chewing tobacco, uncomplicated`,
  },
  {
    value: 'F17.221',
    display: `Nicotine dependence, chewing tobacco, in remission`,
  },
  {
    value: 'F17.223',
    display: `Nicotine dependence, chewing tobacco, with withdrawal`,
  },
  {
    value: 'F17.228',
    display: `Nicotine dependence, chewing tobacco, with other nicotine-induced disorders`,
  },
  {
    value: 'F17.229',
    display: `Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders`,
  },
  {
    value: 'F17.290',
    display: `Nicotine dependence, other tobacco product, uncomplicated`,
  },
  {
    value: 'F17.291',
    display: `Nicotine dependence, other tobacco product, in remission`,
  },
  {
    value: 'F17.293',
    display: `Nicotine dependence, other tobacco product, with withdrawal`,
  },
  {
    value: 'F17.298',
    display: `Nicotine dependence, other tobacco product, with other nicotine-induced disorders`,
  },
  {
    value: 'F17.299',
    display: `Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders`,
  },
  { value: 'F18.10', display: `Inhalant abuse, uncomplicated` },
  { value: 'F18.11', display: `Inhalant abuse, in remission` },
  {
    value: 'F18.120',
    display: `Inhalant abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F18.121',
    display: `Inhalant abuse with intoxication delirium`,
  },
  {
    value: 'F18.129',
    display: `Inhalant abuse with intoxication, unspecified`,
  },
  {
    value: 'F18.14',
    display: `Inhalant abuse with inhalant-induced mood disorder`,
  },
  {
    value: 'F18.150',
    display: `Inhalant abuse with inhalant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F18.151',
    display: `Inhalant abuse with inhalant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F18.159',
    display: `Inhalant abuse with inhalant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F18.17',
    display: `Inhalant abuse with inhalant-induced dementia`,
  },
  {
    value: 'F18.180',
    display: `Inhalant abuse with inhalant-induced anxiety disorder`,
  },
  {
    value: 'F18.188',
    display: `Inhalant abuse with other inhalant-induced disorder`,
  },
  {
    value: 'F18.19',
    display: `Inhalant abuse with unspecified inhalant-induced disorder`,
  },
  { value: 'F18.20', display: `Inhalant dependence, uncomplicated` },
  { value: 'F18.21', display: `Inhalant dependence, in remission` },
  {
    value: 'F18.220',
    display: `Inhalant dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F18.221',
    display: `Inhalant dependence with intoxication delirium`,
  },
  {
    value: 'F18.229',
    display: `Inhalant dependence with intoxication, unspecified`,
  },
  {
    value: 'F18.24',
    display: `Inhalant dependence with inhalant-induced mood disorder`,
  },
  {
    value: 'F18.250',
    display: `Inhalant dependence with inhalant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F18.251',
    display: `Inhalant dependence with inhalant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F18.259',
    display: `Inhalant dependence with inhalant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F18.27',
    display: `Inhalant dependence with inhalant-induced dementia`,
  },
  {
    value: 'F18.280',
    display: `Inhalant dependence with inhalant-induced anxiety disorder`,
  },
  {
    value: 'F18.288',
    display: `Inhalant dependence with other inhalant-induced disorder`,
  },
  {
    value: 'F18.29',
    display: `Inhalant dependence with unspecified inhalant-induced disorder`,
  },
  {
    value: 'F18.90',
    display: `Inhalant use, unspecified, uncomplicated`,
  },
  {
    value: `F18.91`,
    display: `Inhalant use, unspecified, in remission`,
  },
  {
    value: 'F18.920',
    display: `Inhalant use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F18.921',
    display: `Inhalant use, unspecified with intoxication with delirium`,
  },
  {
    value: 'F18.929',
    display: `Inhalant use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F18.94',
    display: `Inhalant use, unspecified with inhalant-induced mood disorder`,
  },
  {
    value: 'F18.950',
    display: `Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions`,
  },
  {
    value: 'F18.951',
    display: `Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F18.959',
    display: `Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F18.97',
    display: `Inhalant use, unspecified with inhalant-induced persisting dementia`,
  },
  {
    value: 'F18.980',
    display: `Inhalant use, unspecified with inhalant-induced anxiety disorder`,
  },
  {
    value: 'F18.988',
    display: `Inhalant use, unspecified with other inhalant-induced disorder`,
  },
  {
    value: 'F18.99',
    display: `Inhalant use, unspecified with unspecified inhalant-induced disorder`,
  },
  {
    value: 'F19.10',
    display: `Other psychoactive substance abuse, uncomplicated`,
  },
  {
    value: 'F19.11',
    display: `Other psychoactive substance abuse, in remission`,
  },
  {
    value: 'F19.120',
    display: `Other psychoactive substance abuse with intoxication, uncomplicated`,
  },
  {
    value: 'F19.121',
    display: `Other psychoactive substance abuse with intoxication delirium`,
  },
  {
    value: 'F19.122',
    display: `Other psychoactive substance abuse with intoxication with perceptual disturbances`,
  },
  {
    value: 'F19.129',
    display: `Other psychoactive substance abuse with intoxication, unspecified`,
  },
  {
    value: 'F19.14',
    display: `Other psychoactive substance abuse with psychoactive substance-induced mood disorder`,
  },
  {
    value: 'F19.150',
    display: `Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions`,
  },
  {
    value: 'F19.151',
    display: `Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F19.159',
    display: `Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F19.16',
    display: `Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder`,
  },
  {
    value: 'F19.17',
    display: `Other psychoactive substance abuse with psychoactive substance-induced persisting dementia`,
  },
  {
    value: 'F19.180',
    display: `Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder`,
  },
  {
    value: 'F19.181',
    display: `Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction`,
  },
  {
    value: 'F19.182',
    display: `Other psychoactive substance abuse with psychoactive substance-induced sleep disorder`,
  },
  {
    value: 'F19.188',
    display: `Other psychoactive substance abuse with other psychoactive substance-induced disorder`,
  },
  {
    value: 'F19.19',
    display: `Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder`,
  },
  {
    value: 'F19.20',
    display: `Other psychoactive substance dependence, uncomplicated`,
  },
  {
    value: 'F19.21',
    display: `Other psychoactive substance dependence, in remission`,
  },
  {
    value: 'F19.220',
    display: `Other psychoactive substance dependence with intoxication, uncomplicated`,
  },
  {
    value: 'F19.221',
    display: `Other psychoactive substance dependence with intoxication delirium`,
  },
  {
    value: 'F19.222',
    display: `Other psychoactive substance dependence with intoxication with perceptual disturbance`,
  },
  {
    value: 'F19.229',
    display: `Other psychoactive substance dependence with intoxication, unspecified`,
  },
  {
    value: 'F19.230',
    display: `Other psychoactive substance dependence with withdrawal, uncomplicated`,
  },
  {
    value: 'F19.231',
    display: `Other psychoactive substance dependence with withdrawal delirium`,
  },
  {
    value: 'F19.232',
    display: `Other psychoactive substance dependence with withdrawal with perceptual disturbance`,
  },
  {
    value: 'F19.239',
    display: `Other psychoactive substance dependence with withdrawal, unspecified`,
  },
  {
    value: 'F19.24',
    display: `Other psychoactive substance dependence with psychoactive substance-induced mood disorder`,
  },
  {
    value: 'F19.250',
    display: `Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions`,
  },
  {
    value: 'F19.251',
    display: `Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F19.259',
    display: `Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F19.26',
    display: `Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder`,
  },
  {
    value: 'F19.27',
    display: `Other psychoactive substance dependence with psychoactive substance-induced persisting dementia`,
  },
  {
    value: 'F19.280',
    display: `Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder`,
  },
  {
    value: 'F19.281',
    display: `Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction`,
  },
  {
    value: 'F19.282',
    display: `Other psychoactive substance dependence with psychoactive substance-induced sleep disorder`,
  },
  {
    value: 'F19.288',
    display: `Other psychoactive substance dependence with other psychoactive substance-induced disorder`,
  },
  {
    value: 'F19.29',
    display: `Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder`,
  },
  {
    value: 'F19.90',
    display: `Other psychoactive substance use, unspecified, uncomplicated`,
  },
  {
    value: `F19.91`,
    display: `Other psychoactive substance use, unspecified, in remission`,
  },
  {
    value: 'F19.920',
    display: `Other psychoactive substance use, unspecified with intoxication, uncomplicated`,
  },
  {
    value: 'F19.921',
    display: `Other psychoactive substance use, unspecified with intoxication with delirium`,
  },
  {
    value: 'F19.922',
    display: `Other psychoactive substance use, unspecified with intoxication with perceptual disturbance`,
  },
  {
    value: 'F19.929',
    display: `Other psychoactive substance use, unspecified with intoxication, unspecified`,
  },
  {
    value: 'F19.930',
    display: `Other psychoactive substance use, unspecified with withdrawal, uncomplicated`,
  },
  {
    value: 'F19.931',
    display: `Other psychoactive substance use, unspecified with withdrawal delirium`,
  },
  {
    value: 'F19.932',
    display: `Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance`,
  },
  {
    value: 'F19.939',
    display: `Other psychoactive substance use, unspecified with withdrawal, unspecified`,
  },
  {
    value: 'F19.94',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder`,
  },
  {
    value: 'F19.950',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions`,
  },
  {
    value: 'F19.951',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations`,
  },
  {
    value: 'F19.959',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified`,
  },
  {
    value: 'F19.96',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder`,
  },
  {
    value: 'F19.97',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia`,
  },
  {
    value: 'F19.980',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder`,
  },
  {
    value: 'F19.981',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction`,
  },
  {
    value: 'F19.982',
    display: `Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder`,
  },
  {
    value: 'F19.988',
    display: `Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder`,
  },
  {
    value: 'F19.99',
    display: `Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder`,
  },
  { value: 'F20.0', display: `Paranoid schizophrenia` },
  { value: 'F20.1', display: `Disorganized schizophrenia` },
  { value: 'F20.2', display: `Catatonic schizophrenia` },
  { value: 'F20.3', display: `Undifferentiated schizophrenia` },
  { value: 'F20.5', display: `Residual schizophrenia` },
  { value: 'F20.81', display: `Schizophreniform disorder` },
  { value: 'F20.89', display: `Other schizophrenia` },
  { value: 'F20.9', display: `Schizophrenia, unspecified` },
  { value: 'F21', display: `Schizotypal disorder` },
  { value: 'F22', display: `Delusional disorders` },
  { value: 'F23', display: `Brief psychotic disorder` },
  { value: 'F24', display: `Shared psychotic disorder` },
  { value: 'F25.0', display: `Schizoaffective disorder, bipolar type` },
  {
    value: 'F25.1',
    display: `Schizoaffective disorder, depressive type`,
  },
  { value: 'F25.8', display: `Other schizoaffective disorders` },
  { value: 'F25.9', display: `Schizoaffective disorder, unspecified` },
  {
    value: 'F28',
    display: `Other psychotic disorder not due to a substance or known physiological condition`,
  },
  {
    value: 'F29',
    display: `Unspecified psychosis not due to a substance or known physiological condition`,
  },
  {
    value: 'F30.10',
    display: `Manic episode without psychotic symptoms, unspecified`,
  },
  {
    value: 'F30.11',
    display: `Manic episode without psychotic symptoms, mild`,
  },
  {
    value: 'F30.12',
    display: `Manic episode without psychotic symptoms, moderate`,
  },
  {
    value: 'F30.13',
    display: `Manic episode, severe, without psychotic symptoms`,
  },
  {
    value: 'F30.2',
    display: `Manic episode, severe with psychotic symptoms`,
  },
  { value: 'F30.3', display: `Manic episode in partial remission` },
  { value: 'F30.4', display: `Manic episode in full remission` },
  { value: 'F30.8', display: `Other manic episodes` },
  { value: 'F30.9', display: `Manic episode, unspecified` },
  {
    value: 'F31.0',
    display: `Bipolar disorder, current episode hypomanic`,
  },
  {
    value: 'F31.10',
    display: `Bipolar disorder, current episode manic without psychotic features, unspecified`,
  },
  {
    value: 'F31.11',
    display: `Bipolar disorder, current episode manic without psychotic features, mild`,
  },
  {
    value: 'F31.12',
    display: `Bipolar disorder, current episode manic without psychotic features, moderate`,
  },
  {
    value: 'F31.13',
    display: `Bipolar disorder, current episode manic without psychotic features, severe`,
  },
  {
    value: 'F31.2',
    display: `Bipolar disorder, current episode manic severe with psychotic features`,
  },
  {
    value: 'F31.30',
    display: `Bipolar disorder, current episode depressed, mild or moderate severity, unspecified`,
  },
  {
    value: 'F31.31',
    display: `Bipolar disorder, current episode depressed, mild`,
  },
  {
    value: 'F31.32',
    display: `Bipolar disorder, current episode depressed, moderate`,
  },
  {
    value: 'F31.4',
    display: `Bipolar disorder, current episode depressed, severe, without psychotic features`,
  },
  {
    value: 'F31.5',
    display: `Bipolar disorder, current episode depressed, severe, with psychotic features`,
  },
  {
    value: 'F31.60',
    display: `Bipolar disorder, current episode mixed, unspecified`,
  },
  {
    value: 'F31.61',
    display: `Bipolar disorder, current episode mixed, mild`,
  },
  {
    value: 'F31.62',
    display: `Bipolar disorder, current episode mixed, moderate`,
  },
  {
    value: 'F31.63',
    display: `Bipolar disorder, current episode mixed, severe, without psychotic features`,
  },
  {
    value: 'F31.64',
    display: `Bipolar disorder, current episode mixed, severe, with psychotic features`,
  },
  {
    value: 'F31.70',
    display: `Bipolar disorder, currently in remission, most recent episode unspecified`,
  },
  {
    value: 'F31.71',
    display: `Bipolar disorder, in partial remission, most recent episode hypomanic`,
  },
  {
    value: 'F31.72',
    display: `Bipolar disorder, in full remission, most recent episode hypomanic`,
  },
  {
    value: 'F31.73',
    display: `Bipolar disorder, in partial remission, most recent episode manic`,
  },
  {
    value: 'F31.74',
    display: `Bipolar disorder, in full remission, most recent episode manic`,
  },
  {
    value: 'F31.75',
    display: `Bipolar disorder, in partial remission, most recent episode depressed`,
  },
  {
    value: 'F31.76',
    display: `Bipolar disorder, in full remission, most recent episode depressed`,
  },
  {
    value: 'F31.77',
    display: `Bipolar disorder, in partial remission, most recent episode mixed`,
  },
  {
    value: 'F31.78',
    display: `Bipolar disorder, in full remission, most recent episode mixed`,
  },
  { value: 'F31.81', display: `Bipolar II disorder` },
  { value: 'F31.89', display: `Other bipolar disorder` },
  { value: 'F31.9', display: `Bipolar disorder, unspecified` },
  {
    value: 'F32.2',
    display: `Major depressive disorder, single episode, severe without psychotic features`,
  },
  {
    value: 'F32.3',
    display: `Major depressive disorder, single episode, severe with psychotic features`,
  },
  {
    value: 'F32.4',
    display: `Major depressive disorder, single episode, in partial remission`,
  },
  {
    value: 'F32.5',
    display: `Major depressive disorder, single episode, in full remission`,
  },
  { value: 'F32.81', display: `Premenstrual dysphoric disorder` },
  { value: 'F32.89', display: `Other specified depressive episodes` },
  {
    value: 'F33.3',
    display: `Major depressive disorder, recurrent, severe with psychotic symptoms`,
  },
  {
    value: 'F33.40',
    display: `Major depressive disorder, recurrent, in remission, unspecified`,
  },
  {
    value: 'F33.41',
    display: `Major depressive disorder, recurrent, in partial remission`,
  },
  {
    value: 'F33.42',
    display: `Major depressive disorder, recurrent, in full remission`,
  },
  {
    value: 'F33.9',
    display: `Major depressive disorder, recurrent, unspecified`,
  },
  { value: 'F34.0', display: `Cyclothymic disorder` },
  {
    value: 'F34.81',
    display: `Disruptive mood dysregulation disorder`,
  },
  {
    value: 'F34.89',
    display: `Other specified persistent mood disorders`,
  },
  {
    value: 'F34.9',
    display: `Persistent mood [affective] disorder, unspecified`,
  },
  { value: 'F40.00', display: `Agoraphobia, unspecified` },
  { value: 'F40.01', display: `Agoraphobia with panic disorder` },
  { value: 'F40.02', display: `Agoraphobia without panic disorder` },
  { value: 'F40.10', display: `Social phobia, unspecified` },
  { value: 'F40.11', display: `Social phobia, generalized` },
  { value: 'F40.210', display: `Arachnophobia` },
  { value: 'F40.218', display: `Other animal type phobia` },
  { value: 'F40.220', display: `Fear of thunderstorms` },
  {
    value: 'F40.228',
    display: `Other natural environment type phobia`,
  },
  { value: 'F40.230', display: `Fear of blood` },
  {
    value: 'F40.231',
    display: `Fear of injections and transfusions`,
  },
  { value: 'F40.232', display: `Fear of other medical care` },
  { value: 'F40.233', display: `Fear of injury` },
  { value: 'F40.240', display: `Claustrophobia` },
  { value: 'F40.241', display: `Acrophobia` },
  { value: 'F40.242', display: `Fear of bridges` },
  { value: 'F40.243', display: `Fear of flying` },
  { value: 'F40.248', display: `Other situational type phobia` },
  { value: 'F40.290', display: `Androphobia` },
  { value: 'F40.291', display: `Gynephobia` },
  { value: 'F40.298', display: `Other specified phobia` },
  { value: 'F40.8', display: `Other phobic anxiety disorders` },
  { value: 'F40.9', display: `Phobic anxiety disorder, unspecified` },
  {
    value: `F41.0`,
    display: `Panic disorder [episodic paroxysmal anxiety]; Panic attack; Panic state`,
  },
  { value: 'F41.3', display: `Other mixed anxiety disorders` },
  { value: 'F41.8', display: `Other specified anxiety disorders` },
  { value: 'F42.3', display: `Hoarding disorder` },
  { value: 'F42.4', display: `Excoriation (skin-picking) disorder` },
  {
    value: `F42.8`,
    display: `Other obsessive-compulsive disorder; Anancastic neurosis; Obsessive-compulsive neurosis`,
  },
  {
    value: 'F42.9',
    display: `Obsessive-compulsive disorder, unspecified`,
  },
  { value: 'F43.0', display: `Acute stress reaction` },
  {
    value: 'F43.24',
    display: `Adjustment disorder with disturbance of conduct`,
  },
  {
    value: 'F43.29',
    display: `Adjustment disorder with other symptoms`,
  },
  {
    value: `F43.81`,
    display: `Prolonged grief disorder; Complicated grief; Complicated grief disorder; Persistent complex bereavement disorder`,
  },
  {
    value: 'F43.89',
    display: `Other reactions to severe stress`,
  },
  { value: 'F43.9', display: `Reaction to severe stress, unspecified` },
  { value: 'F44.0', display: `Dissociative amnesia` },
  { value: 'F44.1', display: `Dissociative fugue` },
  { value: 'F44.2', display: `Dissociative stupor` },
  {
    value: 'F44.4',
    display: `Conversion disorder with motor symptom or deficit`,
  },
  {
    value: 'F44.5',
    display: `Conversion disorder with seizures or convulsions`,
  },
  {
    value: 'F44.6',
    display: `Conversion disorder with sensory symptom or deficit`,
  },
  {
    value: 'F44.7',
    display: `Conversion disorder with mixed symptom presentation`,
  },
  { value: 'F44.81', display: `Dissociative identity disorder` },
  {
    value: 'F44.89',
    display: `Other dissociative and conversion disorders`,
  },
  {
    value: 'F44.9',
    display: `Dissociative and conversion disorder, unspecified`,
  },
  { value: 'F45.0', display: `Somatization disorder` },
  { value: 'F45.1', display: `Undifferentiated somatoform disorder` },
  {
    value: 'F45.20',
    display: `Hypochondriacal disorder, unspecified`,
  },
  { value: 'F45.21', display: `Hypochondriasis` },
  { value: 'F45.22', display: `Body dysmorphic disorder` },
  { value: 'F45.29', display: `Other hypochondriacal disorders` },
  {
    value: 'F45.41',
    display: `Pain disorder exclusively related to psychological factors`,
  },
  {
    value: 'F45.42',
    display: `Pain disorder with related psychological factors`,
  },
  {
    value: 'F45.8',
    display: `Other Somatoform disorders; Psychogenic dysmenorrhea; Psychogenic dysphagia, including 'globus hystericus'; Psychogenic pruritus; Psychogenic torticollis; Somatoform autonomic dysfunction; Teeth grinding`,
  },
  { value: 'F45.9', display: `Somatoform disorder, unspecified` },
  {
    value: 'F48.1',
    display: `Depersonalization-derealization syndrome`,
  },
  { value: 'F48.2', display: `Pseudobulbar affect` },
  {
    value: 'F48.8',
    display: `Other specified nonpsychotic mental disorders`,
  },
  {
    value: 'F48.9',
    display: `Nonpsychotic mental disorder, unspecified`,
  },
  { value: 'F50.00', display: `Anorexia nervosa, unspecified` },
  {
    value: 'F50.010',
    display:
      'Anorexia nervosa, restricting type, mild (Anorexia nervosa, restricting type, with BMI ≥ 17 kg/m2)',
  },
  {
    value: 'F50.011',
    display:
      'Anorexia nervosa, restricting type, moderate (Anorexia nervosa, restricting type, with BMI 16.0-16.99 kg/m2)',
  },
  {
    value: 'F50.012',
    display:
      'Anorexia nervosa, restricting type, severe (Anorexia nervosa, restricting type, with BMI 15.0-15.99 kg/m2)',
  },
  {
    value: 'F50.013',
    display:
      'Anorexia nervosa, restricting type, extreme (Anorexia nervosa, restricting type, with BMI less than 15.0 kg/m2)',
  },
  {
    value: 'F50.014',
    display:
      'Anorexia nervosa, restricting type, in remission (full or partial remission)',
  },
  {
    value: 'F50.019',
    display: 'Anorexia nervosa, restricting type, unspecified',
  },
  {
    value: 'F50.020',
    display:
      'Anorexia nervosa, binge eating/purging type, mild (BMI ≥ to 17 kg/m2)',
  },
  {
    value: 'F50.021',
    display:
      'Anorexia nervosa, binge eating/purging type, moderate (BMI 16.0-16.99 kg/m2)',
  },
  {
    value: 'F50.022',
    display:
      'Anorexia nervosa, binge eating/purging type, severe (BMI 15.0-15.99 kg/m2)',
  },
  {
    value: 'F50.023',
    display:
      'Anorexia nervosa, binge eating/purging type, extreme, (BMI < 15.0 kg/m2)',
  },
  {
    value: 'F50.024',
    display:
      'Anorexia nervosa, binge eating/purging type, in remission (full or partial)',
  },
  {
    value: 'F50.029',
    display: 'Anorexia nervosa, binge eating/purging type, unspecified',
  },
  {
    value: 'F50.20',
    display: 'Bulimia nervosa, unspecified',
  },
  {
    value: 'F50.21',
    display:
      'Bulimia nervosa, mild (1-3 episodes of inappropriate compensatory behavior/week)',
  },
  {
    value: 'F50.22',
    display:
      'Bulimia nervosa, moderate, (4-7 episodes of inappropriate compensatory behavior/week)',
  },
  {
    value: 'F50.23',
    display:
      'Bulimia nervosa, severe, (8-13 episodes of inappropriate compensatory behavior/week)',
  },
  {
    value: 'F50.24',
    display:
      'Bulimia nervosa, extreme (14+ episodes of inappropriate compensatory behavior/week)',
  },
  {
    value: 'F50.25',
    display: 'Bulimia nervosa, in remission (full or partial)',
  },
  {
    value: 'F50.810',
    display: 'Binge eating disorder, mild, (1-3 binge eating episodes/week)',
  },
  {
    value: 'F50.811',
    display:
      'Binge eating disorder, moderate, (4-7 binge eating episodes/week)',
  },
  {
    value: 'F50.812',
    display: 'Binge eating disorder, severe, (8-13 binge eating episodes/week)',
  },
  {
    value: 'F50.813',
    display: 'Binge eating disorder, extreme, (14+ eating episodes/week)',
  },
  {
    value: 'F50.814',
    display: 'Binge eating disorder, in remission (full or partial)',
  },
  {
    value: 'F50.819',
    display: 'Binge eating disorder, unspecified',
  },
  {
    value: 'F50.82',
    display: `Avoidant/restrictive food intake disorder`,
  },
  {
    value: 'F50.83',
    display: 'Pica in adults, Pica in adults, in remission',
  },
  {
    value: 'F50.84',
    display:
      'Rumination disorder in adults, Rumination disorder in adults, in remission',
  },
  { value: 'F50.89', display: `Other specified eating disorder` },
  { value: 'F50.9', display: `Eating disorder, unspecified` },
  { value: 'F51.01', display: `Primary insomnia` },
  { value: 'F51.02', display: `Adjustment insomnia` },
  { value: 'F51.03', display: `Paradoxical insomnia` },
  { value: 'F51.04', display: `Psychophysiologic insomnia` },
  {
    value: 'F51.05',
    display: `Insomnia due to other mental disorder`,
  },
  {
    value: 'F51.09',
    display: `Other insomnia not due to a substance or known physiological condition`,
  },
  { value: 'F51.11', display: `Primary hypersomnia` },
  { value: 'F51.12', display: `Insufficient sleep syndrome` },
  {
    value: 'F51.13',
    display: `Hypersomnia due to other mental disorder`,
  },
  {
    value: 'F51.19',
    display: `Other hypersomnia not due to a substance or known physiological condition`,
  },
  { value: 'F51.3', display: `Sleepwalking [somnambulism]` },
  { value: 'F51.4', display: `Sleep terrors [night terrors]` },
  { value: 'F51.5', display: `Nightmare disorder` },
  {
    value: 'F51.8',
    display: `Other sleep disorders not due to a substance or known physiological condition`,
  },
  {
    value: 'F51.9',
    display: `Sleep disorder not due to a substance or known physiological condition, unspecified`,
  },
  { value: 'F52.0', display: `Hypoactive sexual desire disorder` },
  { value: 'F52.1', display: `Sexual aversion disorder` },
  { value: 'F52.21', display: `Male erectile disorder` },
  { value: 'F52.22', display: `Female sexual arousal disorder` },
  { value: 'F52.31', display: `Female orgasmic disorder` },
  { value: 'F52.32', display: `Male orgasmic disorder` },
  { value: 'F52.4', display: `Premature ejaculation` },
  {
    value: 'F52.5',
    display: `Vaginismus not due to a substance or known physiological condition`,
  },
  {
    value: 'F52.6',
    display: `Dyspareunia not due to a substance or known physiological condition`,
  },
  {
    value: 'F52.8',
    display: `Other sexual dysfunction not due to a substance or known physiological condition`,
  },
  {
    value: 'F52.9',
    display: `Unspecified sexual dysfunction not due to a substance or known physiological condition`,
  },
  { value: 'F53.0', display: `Postpartum depression` },
  { value: 'F53.1', display: `Puerperal psychosis` },
  {
    value: 'F54',
    display: `Psychological and behavioral factors associated with disorders or diseases classified elsewhere; Psychological factors affecting physical conditions`,
  },
  { value: 'F55.0', display: `Abuse of antacids` },
  { value: 'F55.1', display: `Abuse of herbal or folk remedies` },
  { value: 'F55.2', display: `Abuse of laxatives` },
  { value: 'F55.3', display: `Abuse of steroids or hormones` },
  { value: 'F55.4', display: `Abuse of vitamins` },
  {
    value: 'F55.8',
    display: `Abuse of other non-psychoactive substances`,
  },
  {
    value: 'F59',
    display: `Unspecified behavioral syndromes associated with physiological disturbances and physical factors`,
  },
  { value: 'F60.0', display: `Paranoid personality disorder` },
  { value: 'F60.1', display: `Schizoid personality disorder` },
  { value: 'F60.2', display: `Antisocial personality disorder` },
  { value: 'F60.3', display: `Borderline personality disorder` },
  { value: 'F60.4', display: `Histrionic personality disorder` },
  {
    value: 'F60.5',
    display: `Obsessive-compulsive personality disorder`,
  },
  { value: 'F60.6', display: `Avoidant personality disorder` },
  { value: 'F60.7', display: `Dependent personality disorder` },
  { value: 'F60.81', display: `Narcissistic personality disorder` },
  { value: 'F60.89', display: `Other specific personality disorders` },
  { value: 'F60.9', display: `Personality disorder, unspecified` },
  { value: 'F63.0', display: `Pathological gambling` },
  { value: 'F63.1', display: `Pyromania` },
  { value: 'F63.2', display: `Kleptomania` },
  { value: 'F63.3', display: `Trichotillomania` },
  { value: 'F63.81', display: `Intermittent explosive disorder` },
  { value: 'F63.89', display: `Other impulse disorders` },
  { value: 'F63.9', display: `Impulse disorder, unspecified` },
  {
    value: 'F64.0',
    display: `Gender Identity Disorder in adolescence and adulthood`,
  },
  {
    value: 'F64.1',
    display: `Dual role transvestism`,
  },
  {
    value: 'F64.2',
    display: `Gender Identity disorder of childhood`,
  },
  { value: 'F64.8', display: `Other specified gender dysphoria` },
  {
    value: 'F64.9',
    display: `Unspecified gender dysphoria`,
  },
  { value: 'F65.0', display: `Fetishism` },
  { value: 'F65.1', display: `Transvestic fetishism` },
  { value: 'F65.2', display: `Exhibitionism` },
  { value: 'F65.3', display: `Voyeurism` },
  { value: 'F65.4', display: `Pedophilia` },
  { value: 'F65.50', display: `Sadomasochism, unspecified` },
  { value: 'F65.51', display: `Sexual masochism` },
  { value: 'F65.52', display: `Sexual sadism` },
  { value: 'F65.81', display: `Frotteurism` },
  { value: 'F65.89', display: `Other paraphilias` },
  { value: 'F65.9', display: `Paraphilia, unspecified` },
  { value: 'F66', display: `Other sexual disorders` },
  {
    value: 'F68.10',
    display: `Factitious disorder imposed on self, unspecified`,
  },
  {
    value: 'F68.11',
    display: `Factitious disorder imposed on self, with predominantly psychological signs and symptoms`,
  },
  {
    value: 'F68.12',
    display: `Factitious disorder imposed on self, with predominantly physical signs and symptoms`,
  },
  {
    value: 'F68.13',
    display: `Factitious disorder imposed on self, with combined psychological and physical signs and symptoms`,
  },
  {
    value: 'F68.8',
    display: `Other specified disorders of adult personality and behavior`,
  },
  { value: 'F68.A', display: `Factitious disorder imposed on another` },
  {
    value: 'F69',
    display: `Unspecified disorder of adult personality and behavior`,
  },
  { value: 'F70', display: `Mild intellectual disabilities` },
  { value: 'F71', display: `Moderate intellectual disabilities` },
  { value: 'F72', display: `Severe intellectual disabilities` },
  { value: 'F73', display: `Profound intellectual disabilities` },
  { value: 'F78', display: `Other intellectual disabilities` },
  { value: 'F79', display: `Unspecified intellectual disabilities` },
  { value: 'F80.0', display: `Phonological disorder` },
  { value: 'F80.1', display: `Expressive language disorder` },
  {
    value: 'F80.2',
    display: `Mixed receptive-expressive language disorder`,
  },
  {
    value: 'F80.4',
    display: `Speech and language development delay due to hearing loss`,
  },
  { value: 'F80.81', display: `Childhood onset fluency disorder` },
  {
    value: 'F80.82',
    display: `Social pragmatic communication disorder`,
  },
  {
    value: 'F80.89',
    display: `Other developmental disorders of speech and language`,
  },
  {
    value: 'F80.9',
    display: `Developmental disorder of speech and language, unspecified`,
  },
  { value: 'F81.0', display: `Specific reading disorder` },
  { value: 'F81.2', display: `Mathematics disorder` },
  { value: 'F81.81', display: `Disorder of written expression` },
  {
    value: 'F81.89',
    display: `Other developmental disorders of scholastic skills`,
  },
  {
    value: 'F81.9',
    display: `Developmental disorder of scholastic skills, unspecified`,
  },
  {
    value: 'F82',
    display: `Specific developmental disorder of motor function`,
  },
  { value: 'F84.0', display: `Autism Spectrum Disorder` },
  { value: 'F84.2', display: `Rett's syndrome` },
  {
    value: 'F84.3',
    display: `Other childhood disintegrative disorder`,
  },
  { value: 'F84.5', display: `Asperger's syndrome` },
  {
    value: 'F84.8',
    display: `Other pervasive developmental disorders`,
  },
  {
    value: 'F84.9',
    display: `Pervasive developmental disorder, unspecified`,
  },
  {
    value: 'F88',
    display: `Other disorders of psychological development`,
  },
  {
    value: 'F89',
    display: `Unspecified disorder of psychological development`,
  },
  {
    value: 'F90.8',
    display: `Attention-deficit hyperactivity disorder, other type`,
  },
  {
    value: 'F90.9',
    display: `Attention-deficit hyperactivity disorder, unspecified type`,
  },
  {
    value: 'F91.0',
    display: `Conduct disorder confined to family context`,
  },
  { value: 'F91.1', display: `Conduct disorder, childhood-onset type` },
  {
    value: 'F91.2',
    display: `Conduct disorder, adolescent-onset type`,
  },
  { value: 'F91.3', display: `Oppositional defiant disorder` },
  { value: 'F91.8', display: `Other conduct disorders` },
  { value: 'F91.9', display: `Conduct disorder, unspecified` },
  {
    value: 'F93.0',
    display: `Separation anxiety disorder of childhood`,
  },
  { value: 'F93.8', display: `Other childhood emotional disorders` },
  {
    value: 'F93.9',
    display: `Childhood emotional disorder, unspecified`,
  },
  { value: 'F94.0', display: `Selective mutism` },
  {
    value: 'F94.1',
    display: `Reactive attachment disorder of childhood`,
  },
  {
    value: 'F94.2',
    display: `Disinhibited attachment disorder of childhood`,
  },
  {
    value: 'F94.8',
    display: `Other childhood disorders of social functioning`,
  },
  {
    value: 'F94.9',
    display: `Childhood disorder of social functioning, unspecified`,
  },
  { value: 'F95.0', display: `Transient tic disorder` },
  { value: 'F95.1', display: `Chronic motor or vocal tic disorder` },
  { value: 'F95.2', display: `Tourette's disorder` },
  { value: 'F95.8', display: `Other tic disorders` },
  { value: 'F95.9', display: `Tic disorder, unspecified` },
  {
    value: 'F98.0',
    display: `Enuresis not due to a substance or known physiological condition`,
  },
  {
    value: 'F98.1',
    display: `Encopresis not due to a substance or known physiological condition`,
  },
  { value: 'F98.21', display: `Rumination disorder of infancy` },
  {
    value: 'F98.29',
    display: `Other feeding disorders of infancy and early childhood`,
  },
  { value: 'F98.3', display: `Pica of infancy and childhood` },
  { value: 'F98.4', display: `Stereotyped movement disorders` },
  { value: 'F98.5', display: `Adult onset fluency disorder` },
  {
    value: 'F98.8',
    display: `Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence`,
  },
  {
    value: 'F98.9',
    display: `Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence`,
  },
  { value: 'F99', display: `Mental disorder, not otherwise specified` },
  {
    value: 'O90.6',
    display: `Postpartum mood disturbance; Postpartum blues; Postpartum dysphoria, Postpartum sadness`,
  },
  { value: 'G47.00', display: `Insomnia, unspecified` },
  { value: 'G47.53', display: `Recurrent isolated sleep paralysis` },
  { value: 'G47.9', display: 'Sleep disorder, unspecified' },
  { value: 'G93.31', display: `Postviral fatigue syndrome` },
  {
    value: 'R41.82',
    display: `Altered mental status, unspecified; Change in mental status NOS`,
  },
  {
    value: 'R41.83',
    display: `Borderline intellectual functioning; IQ level 71 to 84`,
  },
  { value: 'R41.840', display: `Attention and concentration deficit` },
  {
    value: 'R41.841',
    display: `Cognitive communication deficit`,
  },
  {
    value: 'R41.842',
    display: `Visuospatial deficit`,
  },
  {
    value: 'R41.843',
    display: `Psychomotor deficit`,
  },
  {
    value: 'R41.844',
    display: `Frontal lobe and executive function deficit`,
  },
  {
    value: 'R41.89',
    display: `Other symptoms and signs involving cognitive functions and awareness; Anosognosia`,
  },
  { value: 'R45.2', display: 'Unhappiness' },
  { value: 'R45.88', display: 'Nonsuicidal self-harm' },
  {
    value: 'R52',
    display: `Pain, unspecified; Acute pain NOS; Generalized pain NOS; Pain NOS`,
  },
  { value: 'R63.0', display: `Anorexia` },
  { value: 'R63.2', display: `Polyphagia` },
  { value: 'R73.03', display: `Prediabetes` },
  { value: 'Z55.0', display: 'Illiteracy and low-level literacy' },
  { value: 'Z55.1', display: 'Schooling unavailable and unattainable' },
  { value: 'Z55.2', display: 'Failed school examinations' },
  { value: 'Z55.3', display: 'Underachievement in school' },
  {
    value: 'Z55.4',
    display:
      'Educational maladjustment and discord with teachers and classmates',
  },
  { value: 'Z55.5', display: 'Less than a high school diploma' },
  { value: 'Z55.6', display: 'Problems related to health literacy' },
  {
    value: 'Z55.8',
    display: 'Other problems related to education and literacy',
  },
  {
    value: 'Z55.9',
    display: 'Problems related to education and literacy, unspecified',
  },
  { value: 'Z56.0', display: 'Unemployment, unspecified' },
  { value: 'Z56.1', display: 'Change of job' },
  { value: 'Z56.2', display: 'Threat of job loss' },
  { value: 'Z56.3', display: 'Stressful work schedule' },
  { value: 'Z56.4', display: 'Discord with boss and workmates' },
  { value: 'Z56.5', display: 'Uncongenial work environment' },
  {
    value: 'Z56.6',
    display: 'Other physical and mental strain related to work',
  },
  { value: 'Z56.81', display: 'Sexual harassment on the job' },
  { value: 'Z56.82', display: 'Military deployment status' },
  { value: 'Z56.89', display: 'Other problems related to employment' },
  {
    value: 'Z56.9',
    display: `Unspecified problems related to employment`,
  },
  { value: 'Z57.0', display: 'Occupational exposure to noise' },
  { value: 'Z57.1', display: 'Occupational exposure to radiation' },
  { value: 'Z57.2', display: 'Occupational exposure to dust' },
  {
    value: 'Z57.31',
    display: 'Occupational exposure to environmental tobacco smoke',
  },
  {
    value: 'Z57.39',
    display: 'Occupational exposure to other air contaminants',
  },
  {
    value: 'Z57.4',
    display: 'Occupational exposure to toxic agents in agriculture',
  },
  {
    value: 'Z57.5',
    display: 'Occupational exposure to toxic agents in other industries',
  },
  { value: 'Z57.6', display: 'Occupational exposure to extreme temperature' },
  { value: 'Z57.7', display: 'Occupational exposure to vibration' },
  { value: 'Z57.8', display: 'Occupational exposure to other risk factors' },
  {
    value: 'Z57.9',
    display: 'Occupational exposure to unspecified risk factor',
  },
  { value: 'Z58.6', display: 'Inadequate drinking-water supply' },
  {
    value: 'Z58.81',
    display: 'Basic services unavailable in physical environment',
  },
  {
    value: 'Z58.89',
    display: 'Other problems related to physical environment',
  },
  { value: 'Z59.00', display: 'Homelessness unspecified' },
  { value: 'Z59.01', display: 'Sheltered homelessness' },
  { value: 'Z59.02', display: 'Unsheltered homelessness' },
  { value: 'Z59.10', display: 'Inadequate housing, unspecified' },
  { value: 'Z59.12', display: 'Inadequate housing utilities' },
  { value: 'Z59.19', display: 'Other inadequate housing' },
  { value: 'Z59.2', display: 'Discord with neighbors, lodgers and landlord' },
  {
    value: 'Z59.3',
    display: 'Problems related to living in residential institution',
  },
  { value: 'Z59.41', display: 'Food insecurity' },
  { value: 'Z59.48', display: 'Other specified lack of adequate food' },
  { value: 'Z59.5', display: 'Extreme poverty' },
  { value: 'Z59.6', display: 'Low income' },
  {
    value: 'Z59.7',
    display: 'Insufficient social insurance and welfare support',
  },
  {
    value: 'Z59.811',
    display: 'Housing instability, housed, with risk of homelessness',
  },
  {
    value: 'Z59.812',
    display: 'Housing instability, housed, homelessness in past 12 months',
  },
  { value: 'Z59.819', display: 'Housing instability, housed unspecified' },
  { value: 'Z59.82', display: 'Transportation insecurity' },
  { value: 'Z59.86', display: 'Financial insecurity' },
  {
    value: 'Z59.87',
    display:
      'Material hardship due to limited financial resources, not elsewhere classified',
  },
  {
    value: 'Z59.89',
    display: 'Other problems related to housing and economic circumstances',
  },
  {
    value: 'Z59.9',
    display:
      'Problem related to housing and economic circumstances, unspecified',
  },
  {
    value: 'Z60.0',
    display: `Problems of adjustment to life-cycle transitions`,
  },
  {
    value: 'Z60.2',
    display: `Problems related to living alone`,
  },
  {
    value: 'Z60.3',
    display: `Acculturation difficulty; Problem with migration; Problem with social transplantation`,
  },
  {
    value: 'Z60.4',
    display: `Social exclusion and rejection; Exclusion and rejection on the basis of personal characteristics, such as unusual physical appearance, illness or behavior.; Social isolation`,
  },
  {
    value: 'Z60.5',
    display: `Target of (perceived) adverse discrimination and persecution`,
  },
  {
    value: 'Z60.8',
    display: `Other problems related to social environment`,
  },
  {
    value: 'Z60.9',
    display: `Problem related to social environment, unspecified`,
  },
  {
    value: 'Z62.0',
    display: `Inadequate parental supervision and control`,
  },
  {
    value: 'Z62.1',
    display: `Parental overprotection`,
  },
  {
    value: 'Z62.21',
    display: `Child in welfare custody; Child in care of non-parental family member; Child in foster care`,
  },
  {
    value: 'Z62.22',
    display: `Institutional upbringing; Child living in orphanage or group home`,
  },
  {
    value: 'Z62.23',
    display: `Child in custody of non-parental relative`,
  },
  {
    value: 'Z62.24',
    display: `Child in custody of non-relative guardian`,
  },
  {
    value: 'Z62.29',
    display: `Other upbringing away from parents`,
  },
  {
    value: 'Z62.3',
    display: `Hostility towards and scapegoating of child`,
  },
  {
    value: 'Z62.6',
    display: `Inappropriate (excessive) parental pressure`,
  },
  {
    value: 'Z62.810',
    display: `Personal history of physical and sexual abuse in childhood`,
  },
  {
    value: 'Z62.811',
    display: `Personal history of psychological abuse in childhood`,
  },
  {
    value: 'Z62.812',
    display: `Personal history of neglect in childhood`,
  },
  {
    value: 'Z62.813',
    display: `Personal history of forced labor or sexual exploitation in childhood`,
  },
  {
    value: 'Z62.814',
    display: `Personal history of child financial abuse`,
  },
  {
    value: 'Z62.815',
    display: `Personal history of intimate partner abuse in childhood`,
  },
  {
    value: 'Z62.819',
    display: `Personal history of unspecified abuse in childhood`,
  },
  {
    value: 'Z62.820',
    display: `Parent-biological child conflict; Parent-child problem NOS`,
  },
  {
    value: 'Z62.821',
    display: `Parent-adopted child conflict`,
  },
  {
    value: 'Z62.822',
    display: `Parent-foster child conflict`,
  },
  {
    value: 'Z62.823',
    display: `Parent-step child conflict`,
  },
  {
    value: 'Z62.831',
    display: `Non-parental relative-child conflict`,
  },
  {
    value: 'Z62.832',
    display: `Non-relative guardian-child conflict`,
  },
  {
    value: 'Z62.833',
    display: `Group home staff-child conflict`,
  },
  {
    value: 'Z62.890',
    display: `Parent-child estrangement NEC`,
  },
  {
    value: 'Z62.891',
    display: `Sibling rivalry`,
  },
  {
    value: 'Z62.892',
    display: `Runaway [from current living environment]`,
  },
  {
    value: 'Z62.898',
    display: `Other specified problems related to upbringing`,
  },
  {
    value: 'Z62.9',
    display: `Problem related to upbringing, unspecified`,
  },
  {
    value: 'Z63.0',
    display: `Problems in relationship with spouse or partner`,
  },
  {
    value: 'Z63.1',
    display: `Problems in relationship with in-laws`,
  },
  {
    value: 'Z63.31',
    display: `Absence of family member due to military deployment; Individual or family affected by other family member being on military deployment`,
  },
  {
    value: 'Z63.32',
    display: `Other absence of family member`,
  },
  {
    value: 'Z63.4',
    display: `Disappearance and death of family member`,
  },
  {
    value: 'Z63.5',
    display: `Disruption of family by separation and divorce; Marital estrangement`,
  },
  {
    value: 'Z63.6',
    display: `Dependent relative needing care at home`,
  },
  {
    value: 'Z63.71',
    display: `Stress on family due to return of family member from military deployment; Individual or family affected by family member having returned from military deployment (current or past conflict)`,
  },
  {
    value: 'Z63.72',
    display: `Alcoholism and drug addiction in family`,
  },
  {
    value: 'Z63.79',
    display: `Other stressful life events affecting family and household; Anxiety (normal) about sick person in family; Health problems within family; Ill or disturbed family member; Isolated family`,
  },
  {
    value: 'Z63.8',
    display: `Other specified problems related to primary support group; Family discord NOS; Family estrangement NOS; High expressed emotional level within family; Inadequate family support NOS; Inadequate or distorted communication within family`,
  },
  {
    value: 'Z63.9',
    display: `Problem related to primary support group, unspecified; Relationship disorder NOS`,
  },
  { value: 'Z64.0', display: 'Problems related to unwanted pregnancy' },
  { value: 'Z64.1', display: 'Problems related to multiparity' },
  { value: 'Z64.4', display: 'Discord with counselors' },
  {
    value: 'Z65.0',
    display:
      'Conviction in civil and criminal proceedings without imprisonment',
  },
  { value: 'Z65.1', display: 'Imprisonment and other incarceration' },
  { value: 'Z65.2', display: 'Problems related to release from prison' },
  {
    value: 'Z65.3',
    display: `Problems related to other legal circumstances; arrest; child custody or support proceedings; litigation; prosecution`,
  },
  { value: 'Z65.4', display: 'Victim of crime and terrorism' },
  {
    value: 'Z65.5',
    display: 'Exposure to disaster, war and other hostilities',
  },
  {
    value: 'Z65.8',
    display: 'Other specified problems related to psychosocial circumstances',
  },
  {
    value: 'Z65.9',
    display: 'Problem related to unspecified psychosocial circumstances',
  },
  { value: 'Z68.1', display: `BMI 19.9 or less, adult` },
  { value: 'Z68.20', display: `BMI 20.0-20.9, adult` },
  { value: 'Z68.21', display: `BMI 21.0-21.9, adult` },
  { value: 'Z68.22', display: `BMI 22.0-22.9, adult` },
  { value: 'Z68.23', display: `BMI 23.0-239, adult` },
  { value: 'Z68.24', display: `BMI 24.0-24.9, adult` },
  { value: 'Z68.25', display: `BMI 25.0-25.9, adult` },
  { value: 'Z68.26', display: `BMI 26.0-26.9, adult` },
  { value: 'Z68.27', display: `BMI 27.0-27.9, adult` },
  { value: 'Z68.28', display: `BMI 28.0-28.9, adult` },
  { value: 'Z68.29', display: `BMI 29.0-29.9, adult` },
  { value: 'Z68.30', display: `BMI 30.0-30.9, adult` },
  { value: 'Z68.31', display: `BMI 31.0-31.9, adult` },
  { value: 'Z68.32', display: `BMI 32.0-32.9, adult` },
  { value: 'Z68.33', display: `BMI 33.0-33.9, adult` },
  { value: 'Z68.34', display: `BMI 34.0-34.9, adult` },
  { value: 'Z68.35', display: `BMI 35.0-35.9, adult` },
  { value: 'Z68.36', display: `BMI 36.0-36.9, adult` },
  { value: 'Z68.37', display: `BMI 37.0-37.9, adult` },
  { value: 'Z68.38', display: `BMI 38.0-38.9, adult` },
  { value: 'Z68.39', display: `BMI 39.0-39.9, adult` },
  { value: 'Z68.41', display: `BMI 40.0-44.9, adult` },
  { value: 'Z68.42', display: `BMI 45.0-49.9, adult` },
  { value: 'Z68.43', display: `BMI 50.0-59.9, adult` },
  { value: 'Z68.44', display: `BMI 60.0-69.9, adult` },
  { value: 'Z68.45', display: `BMI 70 or greateer, adult` },
  {
    value: 'Z68.51',
    display: `BMI pediatric, less than 5th percentile for age`,
  },
  {
    value: 'Z68.52',
    display: `BMI pediatric, 5th percentile to less than 85th percentile for age`,
  },
  {
    value: 'Z68.53',
    display: `BMI pediatric, 85th percentile to less than 95th percentile for age`,
  },
  {
    value: 'Z68.54',
    display: `BMI pediatric, greater than or equal to 95th percentile for age`,
  },
  {
    value: 'Z87.820',
    display: `Personal history of traumatic brain injury`,
  },
  {
    value: 'Z87.890',
    display: `Personal history of sex reassignment`,
  },
  {
    value: 'Z91.51',
    display: `Personal history of suicidal behavior`,
  },
  {
    value: 'Z91.52',
    display: `Personal History of Nonsuicidal Self-injury`,
  },
  {
    value: 'Z91.85',
    display: `Personal history of military service`,
  },
];

const PHYSIOLOGICAL_CODES: DXCodeInfo[] = [
  {
    value: 'A52.19',
    display: 'Other Symptomatic neurosyphilis; Syphilitic parkinsonism',
  },
  {
    value: 'A69.22',
    display:
      'Other Neurological disorders in Lyme Disease; Cranial neuritis; Meningoencephalitis; Polyneuropathy',
  },
  {
    value: 'A81.00',
    display:
      'Creutzfeldt-Jakob disease, unspecified; Jakob-Creutzfeldt disease, unspecified',
  },
  {
    value: 'A81.9',
    display:
      'Atypical virus infection of central nervous system, unspecified; Prion diseases of the central nervous system NOS',
  },
  { value: 'A82.9', display: 'Rabies, unspecified' },
  { value: 'B20', display: 'Human immunodeficiency virus [HIV] disease' },
  { value: 'B58.9', display: 'Toxoplasmosis, unspecified' },
  { value: 'B69.0', display: 'Cysticercosis of central nervous system' },
  {
    value: 'C50.919',
    display:
      'Malignant neoplasm of unspecified site of unspecified female breast',
  },
  {
    value: 'C50.929',
    display:
      'Malignant neoplasm of unspecified site of unspecified male breast',
  },
  {
    value: 'C71.9',
    display:
      'Malignant primary brain neoplasm NEC; Malignant neoplasm of brain, unspecified ',
  },
  { value: 'C79.31', display: 'Secondary malignant neoplasm of brain' },
  {
    value: 'C79.9',
    display:
      'Secondary malignant neoplasm of unspecified site; Metastatic cancer NOS; Metastatic disease NOS',
  },
  {
    value: 'C81.90',
    display: 'Hodgkin lymphoma, unspecified, unspecified site',
  },
  {
    value: 'C85.90',
    display: 'Non-Hodgkin lymphoma, unspecified, unspecified site',
  },
  {
    value: 'D32.9',
    display: 'Benign neoplasm of meninges, unspecified; Meningioma NOS',
  },
  { value: 'D33.2', display: 'Benign neoplasm of brain, unspecified' },
  {
    value: 'D43.2',
    display: 'Neoplasm of uncertain behavior of brain, unspecified',
  },
  { value: 'D49.6', display: 'Neoplasm of unspecified behavior of brain' },
  { value: 'E03.9', display: 'Hypothyroidism, unspecified; Myxedema NOS' },
  {
    value: 'E05.90',
    display:
      'Hyperthyroidism NOS, thyrotoxicosis, unspecified without thyrotoxic crisis or storm',
  },
  {
    value: 'E05.91',
    display:
      'Hyperthyroidism NOS, thyrotoxicosis, unspecified with thyrotoxic crisis or storm',
  },
  { value: 'E10.9', display: 'Type 1 diabetes mellitus without complications' },
  { value: 'E50.9', display: 'Vitamin A deficiency, unspecified' },
  { value: 'E51.9', display: 'Thiamine deficiency, unspecified' },
  {
    value: 'E52',
    display:
      'Niacin deficiency; Niacin (-tryptophan) deficiency;Nicotinamide deficiency; Pellagra (alcoholic)',
  },
  {
    value: 'E53.8',
    display:
      'Deficiency of other specified B group vitamins; Biotin deficiency; Folate deficiency; Folic Acid deficiency; Vitamin B12 deficiency',
  },
  { value: 'E54 ', display: 'Vitamin C deficiency, ascorbic acid deficiency' },
  { value: 'E55.9', display: 'Vitamin D deficiency, unspecified' },
  { value: 'E56.9 ', display: 'Vitamin deficiency, unspecified' },
  { value: 'E61.9 ', display: 'Deficiency of nutrient element, unspecified' },
  { value: 'E63.9', display: 'Nutritional deficiency, unspecified' },
  { value: 'E80.20', display: 'Porphyria NOS' },
  { value: 'E83.52', display: 'Hypercalcemia' },
  {
    value: 'F06.8',
    display:
      'Other specified mental disorders due to known physiological condition',
  },
  { value: 'F79.9', display: 'Secondary malignant neoplasm, site unspecified' },
  {
    value: 'G04.81',
    display: 'Noninfectious acute disseminated encephalomyelitis',
  },
  {
    value: 'G10',
    display: "Huntington's Disease; Huntington's chorea; Huntington's dementia",
  },
  { value: 'G12.21', display: 'Amyotrophic lateral sclerosis' },
  {
    value: 'G20',
    display:
      "Parkinson's Disease; Parkinsonism; Hemiparkinsonism; Paralysis agitans",
  },
  {
    value: 'G20.A1',
    display:
      "Parkinson's disease without dyskinesia, without mention of fluctuations",
  },
  {
    value: 'G20.A2',
    display: "Parkinson's disease without dyskinesia, with fluctuations",
  },
  {
    value: 'G20.B1',
    display:
      "Parkinson's disease with dyskinesia, without mention of fluctuations",
  },
  {
    value: 'G20.B2',
    display:
      "Parkinson's disease with dyskinesia, with mention of fluctuations",
  },
  { value: 'G20.C', display: 'Parkinsonism, unspecified' },
  { value: 'G25.71', display: 'Drug induced akathisia' },
  { value: 'G30.9', display: "Alzheimer's disease, unspecified" },
  {
    value: 'G31.01',
    display:
      "Pick's disease; Primary progressive aphasia; Progressive isolated aphasia",
  },
  {
    value: 'G31.09',
    display: 'Frontotemporal neurocognitive disorder; Frontal dementia',
  },
  {
    value: 'G31.83',
    display: 'Neurocognitive disorder with Lewy bodies; Lewy body dementia',
  },
  {
    value: 'G31.84',
    display:
      'Mild cognitive disorder NOS; Mild cognitive impairment of uncertain or unknown etiology ',
  },
  {
    value: 'G43.709',
    display:
      'Chronic migraine without aura, not intractable, without status migrainosus',
  },
  {
    value: 'G35',
    display:
      'Multiple sclerosis; disseminated; generalized; NOS; of brain stem; of cord',
  },
  { value: 'G47.21', display: 'Delayed sleep phase syndrome' },
  { value: 'G47.30', display: 'Sleep apnea NOS; Sleep apnea, unspecified' },
  {
    value: 'G47.419',
    display: 'Narcolepsy without cataplexy',
  },
  {
    value: 'G50.0',
    display:
      'Trigeminal neuralgia; Syndrome of paroxysmal facial pain; Tic douloureux',
  },
  {
    value: 'G61.81',
    display: 'Chronic inflammatory demyelinating polyneuritis',
  },
  { value: 'G71.00', display: 'Muscular dystrophy, unspecified' },
  { value: 'I48.91', display: 'Unspecified atrial fibrillation' },
  { value: 'I48.92', display: 'Unspecified atrial flutter' },
  { value: 'I49.9', display: 'Arrhythmia (cardiac) NOS' },
  {
    value: 'I50.9',
    display:
      'Heart failure, unspecified; congestive heart disease; cardiac, heart or myocardial failure NOS',
  },
  {
    value: 'I69.019',
    display:
      'Unspecified symptoms and signs involving cognitive functions following nontraumatic subarachnoid hemorrhage',
  },
  {
    value: 'I69.119',
    display:
      'Unspecified symptoms and signs involving cognitive functions following nontraumatic intracerebral hemorrhage',
  },
  {
    value: 'I69.319',
    display:
      'Unspecified symptoms and signs involving cognitive functions following cerebral infarction',
  },
  {
    value: 'I69.919',
    display:
      'Unspecified symptoms and signs involving cognitive functions following unspecified cerebrovascular disease',
  },
  { value: 'J45.901', display: 'Unspecified asthma with (acute) exacerbation' },
  { value: 'J45.902', display: 'Unspecified asthma with status asthmaticus' },
  { value: 'J45.990', display: 'Exercise induced bronchospasm' },
  { value: 'J45.998', display: 'Other asthma' },
  {
    value: 'K25.4 ',
    display: 'Chronic or unspecified gastric ulcer with hemorrhage',
  },
  {
    value: 'K25.5',
    display: 'Chronic or unspecified gastric ulcer with perforation',
  },
  {
    value: 'K25.9',
    display:
      'Gastric ulcer, unspecified as acute or chronic, without hemorrhage or perforation',
  },
  {
    value: 'K50.819',
    display:
      "Crohn's disease of both small and large intestine with unspecified complications",
  },
  {
    value: 'K51.819',
    display: 'Other ulcerative colitis with unspecified complications',
  },
  {
    value: 'K51.90',
    display: 'Ulcerative colitis, unspecified, without complications',
  },
  { value: 'K58.2', display: 'Mixed irritable bowel syndrome' },
  { value: 'K58.8', display: 'Other irritable bowel syndrome' },
  {
    value: 'K58.9',
    display:
      'Irritable bowel syndrome without diarrhea; irritable bowel syndrome, NOS',
  },
  {
    value: 'L23.9',
    display:
      'Allergic contact dermatitis, unspecified cause; Allergic contact eczema NOS',
  },
  {
    value: 'L24.9',
    display:
      'Irritiant contact dermatitis, unspecified cause; irritant contact eczema NOS',
  },
  {
    value: 'L25.9',
    display:
      'Unspecifed contact dermatitis, unspecified cause; Contact dermatitis (occupational) NOS; Contact eczema (occupational) NOS',
  },
  {
    value: 'L50.8',
    display: 'Other urticaria; Chronic urticaria; Recurrent periodic urticaria',
  },
  { value: 'L50.9', display: 'Urticaria, unspecified' },
  { value: 'M30.0', display: 'Polyarteritis nodosa' },
  {
    value: 'M32.9',
    display: 'Systemic lupus erythematosus, unspecified; SLE NOS',
  },
  {
    value: 'N17.9',
    display:
      'Acute kidney failure, unspecified; Acute kidney injury (nontraumatic)',
  },
  { value: 'N39.0', display: 'Urinary Tract Infection, site not specified' },
  { value: 'N97.9', display: 'Female infertility, unspecified' },
  {
    value: 'Q06.8',
    display: 'Other specified congenital malformations of spinal cord',
  },
  { value: 'Q85.1', display: 'Tuberous Sclerosis ' },
  { value: 'Q86.0', display: 'Fetal Alcohol Syndrome' },
  { value: 'Q90.9', display: 'Down Syndrome, unspecified; Trisomy 21 NOS' },
  { value: 'Q79.62', display: 'Hypermobile Ehlers-Danlos syndrome' },
  {
    value: 'R41.2',
    display: `Retrograde amnesia`,
  },
  { value: 'R44.0', display: `Auditory hallucinations` },
  { value: 'R44.1', display: `Visual hallucinations` },
  { value: 'R44.2', display: `Other hallucinations` },
  { value: 'R46.81', display: `Obsessive-compulsive behavior` },
  {
    value: 'S06.0X0A',
    display:
      'Intracranial injury, Concussion without loss of consciousness, initial encounter',
  },
  {
    value: 'S06.0X0D',
    display:
      'Intracranial injury, Concussion without loss of consciousness, subsequent encounter',
  },
  {
    value: 'S06.0X0S',
    display:
      'Intracranial injury, Concussion without loss of consciousness, sequela',
  },
  {
    value: 'S06.0X9A',
    display:
      'Intracranial injury, Concussion with loss of consciousness of unspecified duration, initial encounter',
  },
  {
    value: 'S06.0X9D',
    display:
      'Intracranial injury, Concussion with loss of consciousness of unspecified duration, subsequent encounter',
  },
  {
    value: 'S06.0X9S',
    display:
      'Intracranial injury, Concussion with loss of consciousness of unspecified duration, sequela',
  },
  { value: 'U07.1', display: 'COVID-19' },
];

export {
  COMMON_DIAGNOSIS_CODES,
  LESS_COMMON_DIAGNOSIS_CODES,
  PHYSIOLOGICAL_CODES,
};
