import React, { useContext } from 'react';

import { LicenseAndVerification } from '@headway/api/models/LicenseAndVerification';
import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';

import { OnBeforeSubmitError } from './OnBeforeSubmitErrorCard';

export type QuestionnaireV2ContextType = {
  provider: ProviderRead;
  specialties: SpecialtyRead[];
  modalities: ModalityRead[];
  providerQuestionnaire: ProviderQuestionnaireReadV2;
  refetchQuestionnaire: () => Promise<{}>;
  updateQuestionnaireWithoutAdvancingCurrentStep: (
    rawDataUpdate: ProviderQuestionnaireRawData
  ) => Promise<void>;
  refreshCaqhToIntakeMapping: (caqhNumber?: number) => Promise<{}>;
  isRefreshing: boolean;
  pqvs?: ProviderQuestionnaireVerificationRead[];
  licensesAndVerifications?: LicenseAndVerification[];
  malpracticeMinimums?: MalpracticeMinimums;
  recredSummary?: ProviderQuestionnaireRecredSummary;
  shouldUseVerifiable: boolean;
  shouldValidateCoiUploads: boolean;
  isRecredentialing: boolean;
  disableSubmit: boolean;
  setDisableSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  onBeforeSubmitError: OnBeforeSubmitError | null;
  setOnBeforeSubmitError: React.Dispatch<
    React.SetStateAction<OnBeforeSubmitError | null>
  >;
};

export const QuestionnaireV2Context =
  React.createContext<QuestionnaireV2ContextType>({
    provider: {} as QuestionnaireV2ContextType['provider'],
    specialties: [] as QuestionnaireV2ContextType['specialties'],
    modalities: [] as QuestionnaireV2ContextType['modalities'],
    providerQuestionnaire:
      {} as QuestionnaireV2ContextType['providerQuestionnaire'],
    refetchQuestionnaire:
      {} as QuestionnaireV2ContextType['refetchQuestionnaire'],
    updateQuestionnaireWithoutAdvancingCurrentStep:
      {} as QuestionnaireV2ContextType['updateQuestionnaireWithoutAdvancingCurrentStep'],
    refreshCaqhToIntakeMapping:
      {} as QuestionnaireV2ContextType['refreshCaqhToIntakeMapping'],
    isRefreshing: false,
    pqvs: [] as QuestionnaireV2ContextType['pqvs'],
    licensesAndVerifications:
      [] as QuestionnaireV2ContextType['licensesAndVerifications'],
    malpracticeMinimums:
      {} as QuestionnaireV2ContextType['malpracticeMinimums'],
    recredSummary: {} as QuestionnaireV2ContextType['recredSummary'],
    shouldUseVerifiable: false,
    shouldValidateCoiUploads: false,
    isRecredentialing: false,
    disableSubmit: {} as QuestionnaireV2ContextType['disableSubmit'],
    setDisableSubmit: {} as QuestionnaireV2ContextType['setDisableSubmit'],
    onBeforeSubmitError:
      {} as QuestionnaireV2ContextType['onBeforeSubmitError'],
    setOnBeforeSubmitError:
      {} as QuestionnaireV2ContextType['setOnBeforeSubmitError'],
  });

export const useQuestionnaireContext = () => useContext(QuestionnaireV2Context);
