import { diff } from 'deep-diff';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { toaster } from '@headway/ui/ToastManager';

import { AppointmentConfirmationModalFormV2Values } from './AppointmentConfirmationModalV2';
import { getTemplateIdAndVersionFromFormValue } from './Forms/ProgressNotes/ProgressNotesFormV2';
import { getTemplates } from './Forms/ProgressNotes/templateView/utils';
import { ProgressNoteComponentMetadata } from './Forms/ProgressNotes/types';
import { getTemplate } from './Forms/ProgressNotes/utils';
import { convertFormValuesToProviderEventUpdate } from './Forms/SessionDetails/SessionDetailsFormV2';
import {
  AppointmentContext,
  AppointmentState,
} from './stores/AppointmentContextV2';
import {
  createNoteJsonObject,
  ProgressNoteContext,
} from './stores/ProgressNotesContextV2';
import { getObjectPaths } from './util';

export interface AutoSaveListenerProps {
  isInitialized: boolean;
  setIsAutoSaving(value: boolean): void;
}

const AutoSaveListener = ({
  isInitialized,
  setIsAutoSaving,
}: AutoSaveListenerProps) => {
  const { updateAppointment, appointmentState } =
    useContext(AppointmentContext);
  const { eventVirtualId, createNote, updateNote, progressNotes, event } =
    useContext(ProgressNoteContext);
  const {
    getValues,
    formState: { isSubmitting },
    control,
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();
  const values = useWatch({
    control,
  }) as AppointmentConfirmationModalFormV2Values;
  const [lastSavedValues, setLastSavedValues] =
    useState<AppointmentConfirmationModalFormV2Values>(getValues());
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // Ref to track if values have already been initialized
  const hasInitializedValuesRef = useRef(false);

  useEffect(() => {
    if (isInitialized && !hasInitializedValuesRef.current) {
      setLastSavedValues(values);
      hasInitializedValuesRef.current = true;
    }
  }, [isInitialized, values, setLastSavedValues]);

  useEffect(() => {
    const checkForSessionDetailsChanges = async (changedFields: any[]) => {
      if (appointmentState !== AppointmentState.DETAILS_CONFIRMED) {
        // Only if not modifying a confirmed session (so no auto-save on MODIFY STATE)

        const sessionDetailPaths = getObjectPaths(values.sessionDetails);

        // Convert changedFields from array format to dot-separated strings
        const formattedChangedFields = changedFields.map((field: any) =>
          Array.isArray(field)
            ? field.filter((val) => val !== 'sessionDetails').join('.')
            : field
        );
        const fieldsInObject = formattedChangedFields.filter((field: string) =>
          sessionDetailPaths.find((item) => item.includes(field))
        );
        if (fieldsInObject.length > 0) {
          setIsAutoSaving(true);
          console.log('calling updateAppointment');
          await updateAppointment({
            eventIdOrVirtualId: eventVirtualId,
            update: convertFormValuesToProviderEventUpdate(
              values.sessionDetails
            ),
          });
        }
      }
    };

    const checkForProgressNoteChanges = async (changedFields: any[]) => {
      const progressNotePaths = getObjectPaths(values.progressNote);

      // Convert changedFields from array format to dot-separated strings
      const formattedChangedFields = changedFields.map((field: any) =>
        Array.isArray(field)
          ? field.filter((val) => val !== 'progressNote').join('.')
          : field
      );
      const fieldsInObject = formattedChangedFields.filter((field: string) =>
        progressNotePaths.find((item) => item.includes(field))
      );
      if (fieldsInObject.length > 0) {
        const templates = getTemplates<ProgressNoteComponentMetadata>();
        const selectedTemplate = getTemplate(
          templates,
          ...getTemplateIdAndVersionFromFormValue(values.progressNote.template)
        );
        if (
          selectedTemplate &&
          event &&
          event.providerAppointment &&
          event.patientUserId
        ) {
          const {
            template,
            progressNoteType,
            previousNote,
            lateEntryReason,
            lateEntryOtherReason,
            ...response
          } = values.progressNote;
          const noteJson = createNoteJsonObject(selectedTemplate, response);
          console.log('progressNoteType', progressNoteType);
          setIsAutoSaving(true);
          if (!progressNotes) {
            await createNote({
              providerId: event?.providerId,
              patientId: event?.patientUserId,
              providerAppointmentId: event?.providerAppointment?.id,
              noteJson:
                progressNoteType === ProgressNoteType.NONE ? {} : noteJson,
            });
          } else {
            await updateNote({
              noteJson:
                progressNoteType === ProgressNoteType.NONE ? {} : noteJson,
              lateEntryReason:
                lateEntryReason as ProviderProgressNoteLateEntryReason,
              lateEntryOtherReason,
            });
          }
        }
      }
    };

    const checkForChanges = async () => {
      const changes = diff(lastSavedValues, values);
      const hasChanges = changes !== undefined;
      if (hasChanges && !isSubmitting && isInitialized) {
        try {
          const changedFields = changes.map((diff: any) => diff.path);
          await checkForSessionDetailsChanges(changedFields);
          await checkForProgressNoteChanges(changedFields);
          // await checkForAttachmentsChanges();
          setLastSavedValues(values);
        } catch (error) {
          toaster.error(`Failed to save changes: ${error}`);
        } finally {
          setIsAutoSaving(false);
        }
      }
    };

    // Set up the interval to run the check every 2s
    timerRef.current = setInterval(checkForChanges, 2000);

    // Clear the interval when the component is unmounted or when values change
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [
    values,
    isSubmitting,
    lastSavedValues,
    updateAppointment,
    eventVirtualId,
    setIsAutoSaving,
    appointmentState,
    createNote,
    updateNote,
    progressNotes,
    event,
    isInitialized,
  ]);

  return null; // This component does not render anything
};

export default AutoSaveListener;
