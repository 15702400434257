import type { Styles } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { theme } from '@headway/helix/theme';

type Style = Styles[string];

export const combinePdfStyles = (
  baseStyles: Style,
  additionalStyles: Style | Style[]
): Style[] => [
  baseStyles,
  ...(Array.isArray(additionalStyles) ? additionalStyles : [additionalStyles]),
];

export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    gap: 16,
    padding: 24,
  },
  text: {
    fontSize: 10,
    fontFamily: 'PostGrotesk',
    color: theme.color.system.textBlack,
  },
});
