import { Gear } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconGear: Icon = forwardRef((props, ref) => (
  <IconBase
    phosphorIcon={Gear}
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    ref={ref}
  />
));

IconGear.variants = ['outlined', 'filled'];
IconGear.defaultVariant = 'outlined';
