import { useField } from 'formik';
import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';

import { MemberIdValidationGuidance } from '../MemberIdValidationGuidance';
import { FieldControlContext } from './FieldControl';
import { FieldInput } from './FieldInput';

interface FieldMemberIdAdvancedValidationProps {
  patient: UserRead;
  provider?: ProviderRead;
  frontEndCarrierId: number;
  inputProps?: React.ComponentProps<typeof FieldInput>['inputProps'];
}

export const FieldMemberIdAdvancedValidation: React.FC<
  FieldMemberIdAdvancedValidationProps
> = ({ patient, provider, frontEndCarrierId, inputProps = {}, ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const [field, meta] = useField(name);
  const memberId = field.value;

  return (
    <>
      <FieldInput
        data-testid="memberIdField"
        inputProps={{ ...inputProps, autoComplete: 'off' }}
        {...props}
      />
      {meta.touched && (
        <MemberIdValidationGuidance
          patient={patient}
          provider={provider}
          memberId={memberId}
          frontEndCarrierId={frontEndCarrierId}
        />
      )}
    </>
  );
};
