import { useCallback, useContext } from 'react';

import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';

import {
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';

export const SelectAssessments = ({ onDismiss }: { onDismiss: () => void }) => {
  const { setCurrentStep } = useContext(FillOutAssessmentModalContext);

  const goToNextStep = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.ATTESTATION);
  }, [setCurrentStep]);

  return (
    <>
      <ModalContent>
        Select assessments to fill out with client during their session
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onDismiss}>
          Cancel
        </Button>
        <Button variant="primary" onPress={goToNextStep}>
          Fill out assessments with client
        </Button>
      </ModalFooter>
    </>
  );
};
