import { View } from '@react-pdf/renderer';

import { theme } from '@headway/helix/theme';
import { COPYRIGHT_INFO, SOURCE_LABEL } from '@headway/ui/assessments/asrs';
import { PdfText } from '@headway/ui/pdf';

export const AssessmentResultPdfASRSFooterContent = () => {
  return (
    <View fixed style={{ fontSize: 8 }}>
      <PdfText style={{ fontWeight: 'medium' }}>{SOURCE_LABEL}</PdfText>
      <PdfText style={{ color: theme.color.system.gray }}>
        {COPYRIGHT_INFO}
      </PdfText>
    </View>
  );
};
