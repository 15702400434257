import { Button } from '@headway/helix/Button';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';

export interface DiscardUnsavedChangesModalProps {
  open: boolean;
  closeOnCancel(): void;
  closeOnSubmit(): void;
}

export const DiscardUnsavedChangesModal = ({
  open,
  closeOnCancel,
  closeOnSubmit,
}: DiscardUnsavedChangesModalProps) => {
  return (
    <Modal
      isOpen={open}
      onDismiss={closeOnCancel}
      title="Discard unsaved changes?"
    >
      <ModalContent>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing.x2,
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="secondary" onPress={closeOnCancel}>
            Cancel
          </Button>
          <Button onPress={closeOnSubmit}>Discard</Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
