import React from 'react';

import { Checkbox as HelixCheckbox } from '@headway/helix/Checkbox';

import { SchemaComponent } from '.';
import { FormFieldWrapper } from './wrapper/FormFieldWrapper';

const MemoizedCheckbox = React.memo(HelixCheckbox);

export const Checkbox = ({
  element,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title } = element;

  return (
    <FormFieldWrapper name={id} disabled={disabled}>
      {({ onChange, onBlur, value, validation, disabled }) => (
        <MemoizedCheckbox
          name={id}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          aria-label="Progress-notes checkbox"
          validation={validation}
          disabled={disabled}
        >
          {title}
        </MemoizedCheckbox>
      )}
    </FormFieldWrapper>
  );
};
