import React from 'react';

import { CaqhErrorType } from '@headway/api/models/CaqhErrorType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import UnitedStatesDisplayNames, {
  abbreviationToStateEnum,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { getStatesDisplayNames } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { convertListToSentence } from '@headway/shared/utils/strings';

import { getLicenseVerificationInstructions } from 'views/IntakeQuestionnaireV2/utils/LicenseStateSuggestions';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type ErrorInfo = {
  sectionTitle: string;
  errorMessageArr: (JSX.Element | string)[];
  videoLink?: string;
};

const isMalpracticeError = (error: CaqhError) => {
  return [
    CaqhErrorType.MALPRACTICE_MISSING,
    CaqhErrorType.MALPRACTICE_OCCURRENCE_LIMIT,
    CaqhErrorType.MALPRACTICE_AGGREGATE_LIMIT,
    CaqhErrorType.MALPRACTICE_CURRENT_MISSING,
    CaqhErrorType.MALPRACTICE_CURRENT_OCCURRENCE_LIMIT,
    CaqhErrorType.MALPRACTICE_CURRENT_AGGREGATE_LIMIT,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_MISSING,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_NOT_OVERLAPPING,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_OCCURRENCE_LIMIT,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_AGGREGATE_LIMIT,
    CaqhErrorType.MALPRACTICE_CHECKBOX,
  ].includes(error.type);
};
const getMalpracticeError = (caqhErrors: CaqhError[]) => {
  const malpracticeErrors = caqhErrors.filter((error) =>
    isMalpracticeError(error)
  );
  if (malpracticeErrors.length === 0) {
    return null;
  }

  const validFutureInsuranceMissingErrorTypes = new Set([
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_MISSING,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_NOT_OVERLAPPING,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_OCCURRENCE_LIMIT,
    CaqhErrorType.MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_AGGREGATE_LIMIT,
  ]);

  const errorMessages = malpracticeErrors
    .map((error) => {
      let accessInsuranceMsg =
        'You can do so under Profile Data > Professional Liability Insurance > Add.';
      let attestMsg =
        'Then, click "Save and Continue" at the bottom of the page, then click “Review & Attest” at the top of the page, and try again.';
      if (
        error.type === CaqhErrorType.MALPRACTICE_MISSING ||
        error.type === CaqhErrorType.MALPRACTICE_CURRENT_MISSING ||
        error.type === CaqhErrorType.MALPRACTICE_OCCURRENCE_LIMIT ||
        error.type === CaqhErrorType.MALPRACTICE_CURRENT_OCCURRENCE_LIMIT ||
        error.type === CaqhErrorType.MALPRACTICE_AGGREGATE_LIMIT ||
        error.type === CaqhErrorType.MALPRACTICE_CURRENT_AGGREGATE_LIMIT
      ) {
        return (
          <div>
            {`You must enter at least one malpractice policy into your CAQH account with a per occurrence coverage minimum of ${formatter.format(
              error.meta.occurrence_limit
            )} and an aggregate coverage minimum of ${formatter.format(
              error.meta.aggregate_limit
            )}.`}
            <ol>
              <li>{accessInsuranceMsg}</li>
              <li>{attestMsg}</li>
            </ol>
          </div>
        );
      } else if (validFutureInsuranceMissingErrorTypes.has(error.type)) {
        return (
          <div>
            {`Your current malpractice policy is going to expire within 21 days. Currently, you don't have a future policy in place with a per occurrence coverage minimum of ${formatter.format(
              error.meta.occurrence_limit
            )} and an aggregate coverage minimum of ${formatter.format(
              error.meta.aggregate_limit
            )}. Add a future policy to your CAQH account to ensure a smooth credentialing process. Please make sure there isn't a gap in coverage between your expiring and future policies.`}
            <ol css={{ padding: `0 ${theme.spacing.x5}`, margin: 0 }}>
              <li>{accessInsuranceMsg}</li>
              <li>{attestMsg}</li>
            </ol>
          </div>
        );
      } else if (error.type === CaqhErrorType.MALPRACTICE_CHECKBOX) {
        return 'It looks like you omitted a malpractice insurance policy in your CAQH account. As a reminder, professional liability coverage is required for your Headway credentialing. Please navigate to the Professional Liability Insurance section of your account and ensure that both checkboxes for “I am covered by FTCA” and “I am not insured” are NOT selected. Then, enter the details of your policy into your profile and re-attest your profile. Please make sure to also upload a copy of your malpractice insurance to the Documents section of CAQH.';
      } else {
        return '';
      }
    })
    .filter((msg) => !!msg);

  if (errorMessages.length === 0) {
    return null;
  }

  const isValidFutureInsuranceNeeded = malpracticeErrors.some((error) =>
    validFutureInsuranceMissingErrorTypes.has(error.type)
  );

  return {
    sectionTitle: isValidFutureInsuranceNeeded
      ? 'Your malpractice policy is expiring soon'
      : 'Add professional liability insurance to CAQH',
    errorMessageArr: errorMessages,
    videoLink: 'https://www.youtube.com/embed/7Lz1_8A1O1E?si=z1af53Xi1t9MGNKM',
  };
};

const getLicenseVerificationInstructionDetails = (states: UnitedStates[]) => {
  const instructions = getLicenseVerificationInstructions(states);
  return instructions && <div>{instructions}</div>;
};

const getLicenseMissingErrorInfo = (error: CaqhError) => {
  // TODO(justin): Remove this hack in 2-4 weeks when the enum to full state name migration is complete
  // Note that this is the only place where missing_states are UnitedStates (other places where it has missing
  // states are currently stored as UnitedStatesAbbreviations)
  const missingStates = (error.meta.missing_states || [
    abbreviationToStateEnum[error.meta.missing_state],
  ]) as UnitedStates[];
  const missingStatesStr = getStatesDisplayNames(missingStates);
  const instructions = getLicenseVerificationInstructionDetails(missingStates);
  return {
    sectionTitle: `Add licenses`,
    errorMessageArr: [
      <ol
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x1,
        }}
      >
        <li>
          Under Profile Data {'>'} Professional IDs, add licenses for the
          following states: <strong>{missingStatesStr}</strong>.
          <div className="mb-2 mt-2">
            {instructions && (
              <GuidanceCard variant="neutral">{instructions}</GuidanceCard>
            )}
          </div>
        </li>
        <li>Click “Save” at the bottom of the page.</li>
      </ol>,
    ],
    videoLink: 'https://www.youtube.com/embed/4oIAjYg9K5I?si=fHjaVo-zWjd2VuLV',
  };
};

const getMissingIntakeStateErrorInfo = (error: CaqhError) => {
  const missingStates = (error.meta.missing_states || [
    abbreviationToStateEnum[error.meta.missing_state],
  ]) as UnitedStates[];
  const missingStatesStr = getDisplayStatesFromAbbreviations(error);
  return {
    sectionTitle: `Add practice states`,
    errorMessageArr: [
      <ol
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x1,
        }}
      >
        <li>
          Under Profile Data {'>'} Personal Information, add the following
          states: <strong>{missingStatesStr}</strong>.
        </li>
        <li>
          If you are adding more than 1 state, you can add additional states
          under “Additional practice states”.
        </li>
        <li>Click “Save” at the bottom of the page.</li>
        <li>
          Please note after adding new practice states, CAQH may prompt you for
          some additional information because each state has different
          requirements.
        </li>
      </ol>,
    ],
    videoLink: 'https://www.youtube.com/embed/zUIeeJRDY6Y?si=NWZLUrU3hzO3ba-N',
  };
};

const SaveAndAttestInstructions = () => (
  <>
    <li>Click "Save and Continue" at the bottom of the page.</li>
    <li>Reattest your CAQH profile.</li>
    <li>Refresh this page.</li>
  </>
);

const getDisplayStatesFromAbbreviations = (error: CaqhError) => {
  let newMissingStatesList = undefined;
  if (error.meta.missing_states) {
    newMissingStatesList = (error.meta.missing_states as string[]).map(
      (state) => abbreviationToStateEnum[state]
    );
  }
  // TODO(justin): Remove this hack in 2-4 weeks when the enum to full state name migration is complete
  const missingStates = (newMissingStatesList || [
    abbreviationToStateEnum[error.meta.missing_state],
  ]) as UnitedStates[];
  return missingStates
    .map((state) => UnitedStatesDisplayNames[state])
    .join(', ');
};
const getStandardFormattedErrors = (caqhErrors: CaqhError[]) => {
  const nonMalpracticeErrors: ErrorInfo[] = [];

  caqhErrors
    .filter((error) => !isMalpracticeError(error))
    .forEach((error) => {
      if (error.type === CaqhErrorType.PROFILE_MISSING_INTAKE_STATE) {
        nonMalpracticeErrors.push(getMissingIntakeStateErrorInfo(error));
      } else if (error.type === CaqhErrorType.LICENSE_MISSING_INTAKE_STATE) {
        nonMalpracticeErrors.push(getLicenseMissingErrorInfo(error));
      } else if (error.type === CaqhErrorType.BOARD_CERT_MISSING_ANCC) {
        nonMalpracticeErrors.push({
          sectionTitle: 'Add Board Certification to CAQH',
          errorMessageArr: [
            'All nurse practitioner license types must be board certified in Psychiatry by the ANCC. ',
            <>
              <b>If you are board certified in Psychiatry by the ANCC</b>,
              <ol css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
                <li>
                  On the CAQH website, go to Profile Data {'>'} Personal
                  Information and verify that your NUCC Grouping is marked as
                  “Physician Assistants & Advanced Practice Nursing Providers.”
                </li>
                <li>Select the provider type that applies to you below</li>
                <li>
                  Scroll to the bottom of the page and select “Save and
                  Continue.”
                </li>
                <li>
                  Go to Profile Data {'>'} Specialties and select the
                  appropriate psychiatric/mental health speciality from the
                  dropdown menu
                </li>
                <li>Select “Yes” under “Board Certified?”</li>
                <li>
                  Select “American Nurses Credentialing Center” in the dropdown
                  menu under “Name of Certifying Board and complete the rest of
                  the required information on the page
                </li>
                <li>Click “Save and Continue.”</li>
                <li>Re-attest your profile.</li>
              </ol>
            </>,
            <b>
              If you are not board certified in Psychiatry by the ANCC, please
              reach out to your Provider Growth Associate for assistance.
            </b>,
          ],
        });
      } else if (
        error.type === CaqhErrorType.BOARD_CERT_MISSING_EXPIRATION_DATE
      ) {
        nonMalpracticeErrors.push({
          sectionTitle: 'Add ANCC board certification expiration dates',
          errorMessageArr: [
            <>
              For approval, all ANCC board certifications must have valid
              expiration dates. To update these dates:
              <ol css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
                <li>
                  On the CAQH website, go to Profile Data {'>'} Specialties and
                  find the relevant specialty
                </li>
                <li>
                  Select “Yes” under “Does your board certification have an
                  expiration date?”
                </li>
                <li>
                  Enter the expiration date under "Does your board certification
                  have an expiration date?"
                </li>
                <li>Click “Save and Continue.”</li>
                <li>Re-attest your profile.</li>
              </ol>
            </>,
          ],
        });
      } else if (error.type === CaqhErrorType.BOARD_CERT_EXPIRED) {
        nonMalpracticeErrors.push({
          sectionTitle: 'Your ANCC board certification date is expired',
          errorMessageArr: [
            <>
              For approval, renew your ANCC board certifications or update the
              expirations dates. To do so:
              <ol css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
                <li>
                  On the CAQH website, go to Profile Data {'>'} Specialties and
                  find the relevant specialty
                </li>
                <li>
                  Update the expiration date under "Does your board
                  certification have an expiration date?"
                </li>
                <li>Click “Save and Continue.”</li>
                <li>Re-attest your profile.</li>
              </ol>
            </>,
          ],
        });
      } else if (
        error.type === CaqhErrorType.EDUCATION_MISSING_DEGREE_MASTERS_OR_HIGHER
      ) {
        nonMalpracticeErrors.push({
          sectionTitle: 'Add Education to CAQH',
          errorMessageArr: [
            `You haven’t added your highest level of education to CAQH. On CAQH, navigate to Profile Data > Education & Professional Training, then add your education information under “Add an education record.” Then, reattest your CAQH profile and try again.`,
          ],
        });
      } else if (
        error.type === CaqhErrorType.EMPLOYMENT_MISSING_WORK_GAP_RECORD
      ) {
        nonMalpracticeErrors.push({
          sectionTitle: 'Update Employment Information in CAQH',
          errorMessageArr: [
            'Add your employent information to CAQH for the following time period:',
            `${error.meta.start_date} to ${error.meta.end_date}`,
            'You can add this information under Profile Data > Employment Information.',
            'Once you have added this, click "Save and Continue" at the bottom of the page, reattest your CAQH profile, and try again.',
          ],
        });

        // TODO: Add in employment gap logic when we have it
      } else if (
        error.type === CaqhErrorType.DISCLOSURE_CO_ABLE_TO_PERFORM_NO
      ) {
        // double check this question wording
        nonMalpracticeErrors.push({
          sectionTitle: 'Update Answer To Disclosure Question In CAQH',
          errorMessageArr: [
            `You answered “No” to the disclosure question:`,
            'Are you able to perform all the essential functions of the position for which you are applying, safely and according to accepted standards of performance, with or without reasonable accommodation?',
            'If you can practice without accommodations: Select “yes” and type “No accommodations required” in the explanation section.',
            'If you require accommodations to practice: Select “yes” and type “I am able to perform all essential functions using accommodations” in the explanation section.',
            'Once you have addressed this, click "Save and Continue" at the bottom of the page, reattest your CAQH profile, and try again.',
          ],
        });
      } else if (
        error.type === CaqhErrorType.DISCLOSURE_GA_ABLE_TO_PERFORM_NO
      ) {
        // double check this question wording
        nonMalpracticeErrors.push({
          sectionTitle: 'Update Answer To Disclosure Question In CAQH',
          errorMessageArr: [
            `You answered “No” to the disclosure question:`,
            'Are you able to perform all the essential functions of the position for which you are applying, safely and according to accepted standards of performance, with or without reasonable accommodation?',
            'If you can practice without accommodations: Select “yes” and type “No accommodations required” in the explanation section.',
            'If you require accommodations to practice: Select “yes” and type “I am able to perform all essential functions using accommodations” in the explanation section.',
            'Once you have addressed this, click "Save and Continue" at the bottom of the page, reattest your CAQH profile, and try again.',
          ],
        });
      } else if (error.type === CaqhErrorType.PRACTICE_INVALID_ADDRESS) {
        const renamedInvalidAddressFields =
          error.meta.invalid_address_fields.map((field: string) => {
            switch (field) {
              case 'administrative_area_level_1':
                return 'state';
              case 'locality':
                return 'city';
              case 'route':
              case 'street1':
                return 'street name';
              case 'subpremise':
                return 'apartment, suite, or unit number';
              case 'zip':
                return 'postal code';
              default:
                return field.replaceAll('_', ' ');
            }
          });
        const invalidAddressFieldsCopy = convertListToSentence(
          renamedInvalidAddressFields
        );
        nonMalpracticeErrors.push({
          sectionTitle: 'Update Practice Location',
          errorMessageArr: [
            <>
              It looks like your practice address is invalid. Update{' '}
              {invalidAddressFieldsCopy.length > 0 ? (
                <>
                  your <b>{invalidAddressFieldsCopy}</b>
                </>
              ) : (
                <>it</>
              )}{' '}
              so Headway can file claims on your behalf and get you paid.
            </>,
            <>
              {error.meta.invalid_address.street1}
              {error.meta.invalid_address.street2 &&
                `, ${error.meta.invalid_address.street2}`}
              <br />
              {error.meta.invalid_address.city}
              {error.meta.invalid_address.city && ', '}
              {error.meta.invalid_address.state}{' '}
              {error.meta.invalid_address.zip}
            </>,
            <>
              Steps to update:
              <br />
              <ol css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
                <li>
                  On the CAQH website, go to Profile Data {'>'} Practice
                  Locations, and update to a valid practice address.
                </li>
                <SaveAndAttestInstructions />
              </ol>
            </>,
          ],
        });
      }
    });

  const malPracticeError: ErrorInfo | null = getMalpracticeError(caqhErrors);
  if (malPracticeError === null) {
    return nonMalpracticeErrors;
  }

  return nonMalpracticeErrors.concat([malPracticeError]);
};

type CaqhError = { type: CaqhErrorType; meta: any };

interface CaqhErrorProps {
  errors?: CaqhError[];
}

const StandardCaqhErrors: React.FC<React.PropsWithChildren<CaqhErrorProps>> = ({
  errors,
}) => {
  if (!errors) return null;
  const standardFormattedErrors = getStandardFormattedErrors(errors);
  return (
    <div
      css={{
        ...theme.stack.vertical,
      }}
    >
      {standardFormattedErrors.map((formattedError, i) => (
        <div
          css={{
            ...theme.stack.vertical,
            borderTop:
              i == 0 ? `1px solid ${theme.color.system.borderGray}` : '',
            borderBottom: `1px solid ${theme.color.system.borderGray}`,
            padding: theme.spacing.x4,
            paddingBottom: theme.spacing.x6,
            paddingTop: theme.spacing.x6,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing.x6,
          }}
        >
          <div
            css={{
              width: formattedError.videoLink ? '50%' : '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing.x2,
            }}
          >
            <div>
              <SectionHeader>{formattedError.sectionTitle}</SectionHeader>
            </div>

            {formattedError.errorMessageArr.map((errorMessage) => (
              <SubBodyText>{errorMessage}</SubBodyText>
            ))}
          </div>

          {formattedError.videoLink && (
            <iframe
              width="560"
              height="315"
              src={formattedError.videoLink}
              title="YouTube video player"
              frameBorder="0"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export const CaqhErrorSections: React.FC<
  React.PropsWithChildren<CaqhErrorProps>
> = ({ errors }) => (
  <div>
    <StandardCaqhErrors errors={errors} />
  </div>
);
