import { Box, Skeleton } from '@mui/material';
import React, { ReactNode, useState } from 'react';

import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useIsPanelabilityDeletePFECsEnabled } from '@headway/shared/hooks/useIsPanelabilityIntakeFormEnabled';
import { joinWithOxfordComma } from '@headway/shared/utils/stringFormatting';

import { isGroupAdmin } from 'utils/access';
import {
  ConsolidatedProviderPanelabilityEvaluation,
  consolidateProviderPanelabilityEvaluation,
} from 'views/Panelability/consolidateProviderPanelabilityEvaluation';
import { PanelabilityExplanationModalAndButton } from 'views/Panelability/PanelabilityExplanationModalAndButton';
import { PanelabilityIconHeaderFromStatus } from 'views/Panelability/PanelabilityIconHeaders';
import { PanelableCarriersSelectionButtonAndModal } from 'views/Panelability/PanelableCarriersSelectionButtonAndModal';

export const PanelabilitySummary = ({
  provider,
  user,
  providerQuestionnaire,
  providerPanelabilityEvaluation,
  isProviderPanelabilityEvaluationLoading,
}: {
  provider: ProviderRead;
  user: UserRead;
  providerQuestionnaire: ProviderQuestionnaireReadV2;
  providerPanelabilityEvaluation?: ProviderPanelabilityEvaluation | null;
  isProviderPanelabilityEvaluationLoading: boolean;
}) => {
  const deselectedPfecsIds = providerQuestionnaire.rawData?.deselectedPfecIds;
  const deselectedPfecs =
    provider.providerFrontEndCarriers?.filter(
      (pfec) => !!deselectedPfecsIds && deselectedPfecsIds.includes(pfec.id)
    ) || [];
  const providerSelectedPracticeStates =
    providerQuestionnaire.rawData?.providerSelectedPracticeStates;
  const isPanelabilityDeletePFECsEnabled = useIsPanelabilityDeletePFECsEnabled(
    providerSelectedPracticeStates,
    provider,
    providerQuestionnaire
  );
  const { carriersById, isCarriersLoading } = useFrontEndCarriers();
  const isLoading =
    isCarriersLoading || isProviderPanelabilityEvaluationLoading;
  if (isLoading) {
    return (
      <div className="mb-4">
        <Skeleton variant="rectangular" width={1000} height={250} />
      </div>
    );
  }
  if (
    !providerPanelabilityEvaluation
      ?.stateInsuranceCarrierPanelabilityEvaluations?.length
  ) {
    return null;
  }
  const consolidatedPanelabilityEvaluation =
    consolidateProviderPanelabilityEvaluation({
      panelabilityEvaluation: providerPanelabilityEvaluation,
      carriersById,
      deselectedPfecs,
    });
  return (
    <>
      <div
        className="items-left flex flex-col p-4 "
        css={{
          backgroundColor: theme.color.system.backgroundGray,
          gap: theme.spacing.x4,
          padding: theme.spacing.x5,
        }}
      >
        <div className="flex">
          <div
            className="flex flex-col"
            css={{
              gap: theme.spacing.x4,
            }}
          >
            <SectionHeader>
              {`Great news: ${
                isGroupAdmin(user)
                  ? `${provider.displayFirstName} is`
                  : `You're`
              } verified with ${
                consolidatedPanelabilityEvaluation.panelingInfoForPayers.filter(
                  (evaluation) =>
                    evaluation.status == PanelabilityStatus.PANELABLE
                ).length
              }+ plans on Headway`}
            </SectionHeader>
            <BodyText>
              {`Based on what we’ve learned about you and your practice, you are eligible for the following plans in ${joinWithOxfordComma(
                providerQuestionnaire?.rawData?.providerSelectedPracticeStates?.map(
                  (state) => statesToDisplayNames[state]
                ) ?? []
              )}.`}
              {isPanelabilityDeletePFECsEnabled &&
                providerPanelabilityEvaluation &&
                ' You can opt out of plans if you do not want to get credentialed with them.'}
            </BodyText>
          </div>
          {isPanelabilityDeletePFECsEnabled &&
            providerPanelabilityEvaluation && (
              <div css={{ flexGrow: 'false' }}>
                <PanelableCarriersSelectionButtonAndModal
                  provider={provider}
                  providerQuestionnaire={providerQuestionnaire}
                  panelabilityEvaluation={providerPanelabilityEvaluation}
                />
              </div>
            )}
        </div>
        <div css={{ flexGrow: 'false' }}>
          <PanelabilityExplanationModalAndButton
            consolidatedPanelabilityEvaluation={
              consolidatedPanelabilityEvaluation
            }
          />
        </div>
        <PanelabilityPlanBreakdownSummary
          consolidatedPanelabilityEvaluation={
            consolidatedPanelabilityEvaluation
          }
          panelabilityStatus={PanelabilityStatus.PANELABLE}
          cardStyleProps={{
            backgroundColor: theme.color.system.white,
            textColor: theme.color.system.textBlack,
          }}
        />
        <PanelabilityPlanBreakdownSummary
          consolidatedPanelabilityEvaluation={
            consolidatedPanelabilityEvaluation
          }
          panelabilityStatus={PanelabilityStatus.MAYBE_PANELABLE}
          cardStyleProps={{
            backgroundColor: theme.color.system.white,
            textColor: theme.color.system.gray,
          }}
        />
        <PanelabilityPlanBreakdownSummary
          consolidatedPanelabilityEvaluation={
            consolidatedPanelabilityEvaluation
          }
          panelabilityStatus={PanelabilityStatus.NOT_PANELABLE}
          cardStyleProps={{
            backgroundColor: theme.color.system.backgroundGray,
            textColor: theme.color.system.gray,
          }}
        />
      </div>
    </>
  );
};

const PanelabilityPlanBreakdownSummary = ({
  consolidatedPanelabilityEvaluation,
  panelabilityStatus,
  cardStyleProps,
}: {
  consolidatedPanelabilityEvaluation: ConsolidatedProviderPanelabilityEvaluation;
  panelabilityStatus: PanelabilityStatus;
  cardStyleProps: {
    backgroundColor: string;
    textColor: string;
  };
}) => {
  const panelabilityEvaluationsWithStatus =
    consolidatedPanelabilityEvaluation.panelingInfoForPayers.filter(
      (evaluation) => evaluation.status == panelabilityStatus
    );
  if (!panelabilityEvaluationsWithStatus?.length) {
    return null;
  }
  return (
    <div css={{ flexDirection: 'column', gap: theme.spacing.x4 }}>
      <PanelabilityIconHeaderFromStatus status={panelabilityStatus} />
      <Box height={theme.spacing.x4} />
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: theme.spacing.x2,
        }}
      >
        {panelabilityEvaluationsWithStatus.map((evaluation) => (
          <PlanPanelabilityCard cardStyleProps={cardStyleProps}>
            {evaluation.displayName}
          </PlanPanelabilityCard>
        ))}
      </div>
    </div>
  );
};

const PlanPanelabilityCard = ({
  children,
  cardStyleProps,
}: {
  children: ReactNode;
  cardStyleProps: {
    backgroundColor: string;
    textColor: string;
  };
}) => {
  return (
    <div
      css={{
        display: 'flex',
        padding: '7px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: cardStyleProps.backgroundColor,
        borderRadius: '4px',
        border: '1px solid',
        borderColor: theme.color.system.borderGray,
      }}
    >
      <div
        className={'hlx-typography-brand-content'}
        css={{ color: cardStyleProps.textColor }}
      >
        {children}
      </div>
    </div>
  );
};
