import moment from 'moment';
import { useMemo } from 'react';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { SHORT_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  UseAssessmentResultPdfBaseParams,
  useAssessmentResultsPdf,
} from './useAssessmentResultsPdf';

type UseSingleAssessmentPdfParams = UseAssessmentResultPdfBaseParams & {
  assessment?: PatientAssessmentRead;
};

const SINGLE_ASSESSMENT_PDF_MESSAGES = {
  loading: "Preparing your download... please don't refresh the page",
  downloading: 'Your assessment is downloading',
  error:
    'Error when preparing download. Please refresh the page and try again.',
};

export function useSingleAssessmentPdf({
  assessment,
  ...params
}: UseSingleAssessmentPdfParams) {
  const filename = useMemo(() => {
    if (!assessment || !params.client) {
      return;
    }

    const { assessmentType, completedOn } = assessment;

    const formattedName = formatPatientName(params.client).replaceAll(' ', '_');
    const formattedType = SHORT_ASSESSMENT_NAMES[assessmentType];
    const formattedDate = moment(completedOn).format('YYYY_MM_DD');

    return `${[formattedName, formattedType, formattedDate].join('_')}.pdf`;
  }, [assessment, params.client]);

  return useAssessmentResultsPdf({
    ...params,
    assessments: assessment ? [assessment] : undefined,
    filename,
    toastMessages: SINGLE_ASSESSMENT_PDF_MESSAGES,
  });
}
