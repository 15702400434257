import { ErrorCode } from '@headway/api/models/ErrorCode';

export enum LookupErrorCategory {
  NAME,
  DATE_OF_BIRTH,
  MEMBER_ID,
  GENERIC,
  SPECIFIC,
}

export function categorizeLookupError(
  errorCode: ErrorCode
): LookupErrorCategory {
  switch (errorCode) {
    case ErrorCode.INVALID_OR_MISSING_PATIENT_NAME:
    case ErrorCode.INVALID_OR_MISSING_SUBSCRIBER_OR_INSURED_NAME:
      return LookupErrorCategory.NAME;
    case ErrorCode.INVALID_OR_MISSING_DOB:
    case ErrorCode.DATE_OF_BIRTH_FOLLOWS_DATES_OF_SERVICE:
    case ErrorCode.PATIENT_DOB_DOES_NOT_MATCH_PATIENT_ON_DATABASE:
      return LookupErrorCategory.DATE_OF_BIRTH;
    case ErrorCode.MISSING_OR_INVALID_SUBSCRIBER_OR_INSURED_ID:
    case ErrorCode.DUPLICATE_SUBSCRIBER_OR_INSURED_ID_NUMBER:
      return LookupErrorCategory.MEMBER_ID;
    default:
      return LookupErrorCategory.GENERIC;
  }
}

export function shouldShowLookupErrors({
  otherLookupErrors,
  lookupErrorCodes,
  formikErrors,
  newHumanInputErrorExperienceEnabled,
}: {
  otherLookupErrors: string[];
  lookupErrorCodes: ErrorCode[];
  formikErrors: Record<string, string>;
  newHumanInputErrorExperienceEnabled: boolean;
}): Record<LookupErrorCategory, boolean> {
  const showSpecificLookupError = otherLookupErrors.length > 0;
  const showGenericError =
    !showSpecificLookupError &&
    (lookupErrorCodes.length > 1 ||
      (lookupErrorCodes.length === 1 &&
        categorizeLookupError(lookupErrorCodes[0]) ===
          LookupErrorCategory.GENERIC));

  const categoryToFormikErrorsExist: Record<LookupErrorCategory, boolean> = {
    [LookupErrorCategory.DATE_OF_BIRTH]: !!formikErrors.dob,
    [LookupErrorCategory.NAME]:
      !!formikErrors.firstName || !!formikErrors.lastName,
    [LookupErrorCategory.MEMBER_ID]: !!formikErrors.memberId,
    [LookupErrorCategory.GENERIC]: false,
    [LookupErrorCategory.SPECIFIC]: false,
  };

  const result: Record<LookupErrorCategory, boolean> = {} as Record<
    LookupErrorCategory,
    boolean
  >;

  (Object.values(LookupErrorCategory) as LookupErrorCategory[]).forEach(
    (category) => {
      const has_formik_error = categoryToFormikErrorsExist[category];
      const has_lookup_error = lookupErrorCodes.some(
        (errorCode) => categorizeLookupError(errorCode) === category
      );
      const ff_on = newHumanInputErrorExperienceEnabled;
      const already_showing_generic = showGenericError;
      const already_showing_specific = showSpecificLookupError;
      result[category] =
        ff_on &&
        !has_formik_error &&
        has_lookup_error &&
        !already_showing_generic &&
        !already_showing_specific;
    }
  );

  result[LookupErrorCategory.GENERIC] = showGenericError;
  result[LookupErrorCategory.SPECIFIC] = showSpecificLookupError;

  return result;
}

export function getErrorMessageFromCode(
  errorCode: ErrorCode,
  carrierName?: string
): string {
  const baseMessage =
    "Please double check that what you entered matches exactly what's written on your insurance card.";

  switch (categorizeLookupError(errorCode)) {
    case LookupErrorCategory.MEMBER_ID:
      return `We can't find a plan that matches this member ID. ${baseMessage}`;
    case LookupErrorCategory.DATE_OF_BIRTH:
      return `We can't find a plan that matches this date of birth. ${baseMessage}`;
    case LookupErrorCategory.NAME:
      return `We can't find a plan that matches this name. ${baseMessage}`;
    case LookupErrorCategory.GENERIC:
    default:
      return getGenericLookupErrorMessage(carrierName);
  }
}

export function getErrorMessageFromString(errorString: string): string {
  const baseMessage =
    "We can't find a plan that matches the info you provided. ";

  const specificMappings: { [key: string]: string } = {
    'Member ID (Loop 2010BA, NM109) must contain a prefix of 3 letters or numbers followed by 9 numbers.':
      baseMessage +
      'Please make sure your member ID has a prefix with 3 letters or numbers that is followed by 9 numbers.',
    'Member ID must be 8 numeric characters.':
      baseMessage + 'Please make sure your member ID has 8 numbers.',
    'Member ID must contain 9 to 14 alphanumeric characters.':
      baseMessage +
      'Please make sure your member ID has between 9 - 14 characters, and contains both numbers and letters.',
    'Patient ID must be a string of 11 characters in length that only contain digits and the characters A or a.':
      baseMessage +
      'Please make sure your member ID is 11 characters long and contains numbers, as well as the letters "A" or "a".',
    'Subscriber ID (loop 2100C, NM109) must use one of these formats: a three character alpha-numeric prefix (no zeros or ones) followed by a maximum of 14 alpha-numeric characters, an R followed by exactly 8 numbers, or an H followed by exactly 8 or 10 numbers.':
      baseMessage +
      'Please make sure your member ID matches one of these formats:\n- 14 characters, containing both numbers and letters\n- 8 numbers starting with an R\n- 8-10 numbers starting with an H',
    'Subscriber IDs cannot include an alpha-prefix that begins with JLX, JYN, XOD, XOJ, YDJ, YDL, YDV, YID, YIJ, YUB, YUW, YUX, ZGD, ZGJ, or ZZT.':
      baseMessage +
      'Please make sure your member ID does not start with one of these prefixes: JLX, JYN, XOD, XOJ, YDJ, YDL, YDV, YID, YIJ, YUB, YUW, YUX, ZGD, ZGJ, or ZZT.',
  };

  if (errorString in specificMappings) {
    return specificMappings[errorString];
  }

  return getGenericLookupErrorMessage();
}

export function getGenericLookupErrorMessage(carrierName?: string): string {
  return `We couldn't find a plan that matches the information you provided${
    carrierName ? ` for ${carrierName}` : ''
  }. Please double check that what you entered matches exactly what's written on your insurance card.`;
}
