import { NumberCircleFive } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNumberCircleFive: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={NumberCircleFive}
    defaultVariant="filled"
    ref={ref}
  />
));

IconNumberCircleFive.variants = ['filled'];
IconNumberCircleFive.defaultVariant = 'filled';
