import { NumberCircleNine } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNumberCircleNine: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={NumberCircleNine}
    defaultVariant="filled"
    ref={ref}
  />
));

IconNumberCircleNine.variants = ['filled'];
IconNumberCircleNine.defaultVariant = 'filled';
