import { Skeleton } from '@mui/material';
import { FormikErrors } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Banner } from '@headway/helix/Banner';
import { BodyText } from '@headway/helix/BodyText';
import { ComboBox, Item } from '@headway/helix/ComboBox';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  DEA_INTAKE_FORM_UPDATES,
  MULTI_STATE_CREDENTIALING_BETA,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useRegionalRates } from '@headway/shared/hooks/useRegionalRates';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import {
  getSupportedStates,
  isExistingProvider,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { initializeAuthStore, useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';
import { getListFormatter } from 'utils/formatters';
import {
  filterStatesByCdsRequirement,
  YesNo,
} from 'utils/providerQuestionnaire';
import { useQuestionnaireContext } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';
import { QuestionnaireV2Step } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Step';
import { CustomComponentProps } from 'views/IntakeQuestionnaireV2/utils/CustomComponentProps';
import {
  addProviderLicenseStatesAndPFECs,
  removePFECsAndTerminatePLSs,
} from 'views/IntakeQuestionnaireV2/utils/WelcomeStepHandlers';

import { PrescriberRequirements } from '../PrescriberRequirements';
import {
  CREDENTIALING_REQUIREMENTS_ZENDESK_ARTICLE,
  PRESCRIBER_REGISTRATION_REQUIREMENTS_ZENDESK_ARTICLE,
} from '../utils/intakeQuestionnaireConstants';
import { YesNoQuestion } from '../utils/YesNoQuestion';
import { MSCGuardrailApplicationModal } from './MSCGuardrailApplicationModal';

const convertStateListToString = (states: UnitedStates[]) => {
  if (states.length === 0) {
    return '';
  }

  if (states.length === 1) {
    return statesToDisplayNames[states[0]];
  }

  const stateReadableNames = states.map((state) => statesToDisplayNames[state]);
  const stateAnd = stateReadableNames.pop();

  return `${stateReadableNames.join(', ')} and ${stateAnd}`;
};

const getAllLiveStates = (provider: ProviderRead) =>
  provider.activeProviderLicenseStates
    .filter((pls) => pls.liveOn)
    .map((pls) => pls.state);

export const PracticeStatesStepContent = ({
  formikHelpers,
}: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const isDEAIntakeUpdatesEnabled = useFlag(DEA_INTAKE_FORM_UPDATES, false);
  const authStore = useAuthStore();
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const listFormatter = getListFormatter('en-US');

  const liveStates = getAllLiveStates(provider);

  const selectableStates = Object.entries(statesToDisplayNames).filter(
    ([key]) => {
      // Existing providers: Can select from any state they aren't yet live in
      if (isExistingProvider(provider))
        return !liveStates?.includes(key as UnitedStates);

      // New providers: Can only select from activePLS
      return getSupportedStates(provider).includes(key as UnitedStates);
    }
  );

  const { setFieldValue, values, errors } = formikHelpers;
  const isAddingNewStates =
    !isExistingProvider(provider) || values?.isAddingNewStates === 'true';

  const showMSCGuardrailModal =
    isExistingProvider(provider) &&
    values?.isAddingNewStates === 'true' &&
    !isMSCEnabled &&
    isMSCGuardrailWarning &&
    !!restrictionDate;
  const { carriersById } = useFrontEndCarriers();
  const { data, isLoading, isRefetching } = useRegionalRates(
    {
      regionalRateRequests: (values.providerSelectedPracticeStates ?? []).map(
        (state) => ({
          state,
        })
      ),
      carriersById,
      providerType: provider.providerType!,
      isLiveProvider:
        !!provider.earliestActiveLiveOn &&
        new Date(provider.earliestActiveLiveOn).getTime() < Date.now(),
    },
    {
      enabled: isExistingProvider(provider),
    }
  );

  const fetchingRateInformation = isLoading || isRefetching;

  const providerSelectedPracticeStatesError = (
    errors as FormikErrors<{
      providerSelectedPracticeStates: string;
    }>
  )?.providerSelectedPracticeStates;

  const carriersWithSupportedStates = useMemo(() => {
    if (
      !isExistingProvider(provider) &&
      provider.providerFrontEndCarriers &&
      provider.providerFrontEndCarriers?.length > 0
    ) {
      return provider.providerFrontEndCarriers.reduce(
        (carrierWithStatesMap, pfec) => {
          const state = pfec.providerLicenseState.state;
          if (carrierWithStatesMap[pfec.frontEndCarrierId]) {
            carrierWithStatesMap[pfec.frontEndCarrierId].push(state);
          } else {
            carrierWithStatesMap[pfec.frontEndCarrierId] = [state];
          }

          return carrierWithStatesMap;
        },
        {} as { [key: number]: UnitedStates[] }
      );
    }

    if (!data?.getStateWithSupportedCarriers) {
      return [];
    }

    const stateWithSupportedCarriers = data.getStateWithSupportedCarriers();
    setFieldValue(
      'providerSelectedCarriersByState',
      Object.entries(stateWithSupportedCarriers).reduce(
        (providerSelectedCarriersByState, value) => {
          providerSelectedCarriersByState[value[0]] = Array.from(value[1]);
          return providerSelectedCarriersByState;
        },
        {} as Required<ProviderQuestionnaireRawData>['providerSelectedCarriersByState']
      )
    );

    const carrierWithStates = Object.entries(stateWithSupportedCarriers).reduce(
      (carrierWithStatesMap, value) => {
        const state = value[0] as UnitedStates;
        const carriers = value[1];

        carriers.forEach((carrier) => {
          if (carrierWithStatesMap[carrier]) {
            carrierWithStatesMap[carrier].push(state);
          } else {
            carrierWithStatesMap[carrier] = [state];
          }
        });

        return carrierWithStatesMap;
      },
      {} as { [key: number]: UnitedStates[] }
    );

    return carrierWithStates;
  }, [fetchingRateInformation]);

  const hasBcbsCarrier =
    carriersById && Object.entries(carriersWithSupportedStates).length > 0
      ? Object.entries(carriersWithSupportedStates)
          .map((value) => value[0])
          .find((frontEndCarrierId) =>
            isFrontEndCarrierIdBcbs(parseInt(frontEndCarrierId))
          )
      : false;

  const currentStatesFormatted = listFormatter.format(
    liveStates.map((state) => statesToDisplayNames[state])
  );

  const shouldRenderCarriersTable = useMemo(() => {
    const hasFetchedCarriersForSelectedStates = !!(
      carriersById &&
      Object.entries(carriersWithSupportedStates).length > 0 &&
      !fetchingRateInformation
    );

    if (!isDEAIntakeUpdatesEnabled) return hasFetchedCarriersForSelectedStates;

    const {
      providerSelectedPracticeStates,
      isLicensedInSelectedPracticeStates,
      hasDeaInSelectedPracticeStates,
      hasCdsInSelectedPracticeStates,
    } = values;
    const isCDSRequired =
      providerSelectedPracticeStates &&
      !!filterStatesByCdsRequirement(
        providerSelectedPracticeStates,
        provider.licenseType
      ).length;
    const isMissingPrescriberRegistrationResponse =
      provider.isPrescriber &&
      (hasDeaInSelectedPracticeStates !== YesNo.YES ||
        (isCDSRequired && hasCdsInSelectedPracticeStates !== YesNo.YES));

    // Don't render carriers until provider has confirmed they are:
    // 1. Licensed in all states
    // 2. Have DEA in all states
    // 3. Have CDS in applicable states
    if (
      !hasFetchedCarriersForSelectedStates ||
      isLicensedInSelectedPracticeStates !== YesNo.YES ||
      isMissingPrescriberRegistrationResponse
    )
      return false;

    return true;
  }, [
    isDEAIntakeUpdatesEnabled,
    provider,
    values,
    carriersById,
    carriersWithSupportedStates,
    fetchingRateInformation,
  ]);

  return (
    <>
      <MSCGuardrailApplicationModal
        restrictionDate={restrictionDate}
        showModal={showMSCGuardrailModal}
      />

      {isExistingProvider(provider) && (
        <>
          <div className="mt-1 flex max-w-[950px] flex-col gap-1">
            <SubBodyText>
              <strong>You’re currently credentialed in</strong>
            </SubBodyText>
            <SectionHeader>{currentStatesFormatted}</SectionHeader>
          </div>
          <div className="max-w-[950x] gap-2">
            <FormControl
              component={BigRadioGroup}
              name="isAddingNewStates"
              onChange={(value) => {
                const newIsAddingNewStates = value === 'true';
                if (!newIsAddingNewStates) {
                  formikHelpers.setFieldValue(
                    'providerSelectedPracticeStates',
                    []
                  );
                }
              }}
              label={
                <BodyText>
                  <strong>
                    Do you want to get credentialed in new states?
                  </strong>
                </BodyText>
              }
            >
              <BigRadio value="true">
                <BodyText>
                  <strong>Get credentialed in new states</strong>
                </BodyText>
              </BigRadio>
              <BigRadio value="false">
                <BodyText>
                  <strong>Get re-credentialed only</strong>
                </BodyText>
              </BigRadio>
            </FormControl>
          </div>
        </>
      )}
      {isAddingNewStates && (
        <div className="max-w-[950px] pr-1">
          <ComboBox
            name="providerSelectedPracticeStates"
            disabled={!hasRateAccess(provider, authStore.user)}
            selectionMode="multiple"
            label={
              <BodyText>
                <strong>Where do you want to get credentialed?</strong>
              </BodyText>
            }
            instructionalText={
              <BodyText>
                You must have a state license for every state you want to get
                credentialed in.
              </BodyText>
            }
            helpText={
              isExistingProvider(provider)
                ? 'Select all the states you want to add now; you won’t be able to submit another application until this one is approved.'
                : 'If you want to add another state, contact your practice consultant.'
            }
            items={selectableStates}
            selectedKeys={values.providerSelectedPracticeStates ?? []}
            onSelectionChange={(keys) => {
              setFieldValue('providerSelectedPracticeStates', Array.from(keys));
              setFieldValue('providerSelectedCarriersByState', undefined);
            }}
            validation={
              providerSelectedPracticeStatesError
                ? {
                    validity: 'invalid',
                    message: providerSelectedPracticeStatesError,
                  }
                : {
                    validity: 'valid',
                  }
            }
          >
            {(item) => {
              const key = (item as [string, string])[0];
              const value = (item as [string, string])[1];
              return (
                <Item key={key} textValue={value}>
                  {value}
                </Item>
              );
            }}
          </ComboBox>
        </div>
      )}
      {isExistingProvider(provider) && (
        <div className="flex flex-col gap-1">
          <div>
            <BodyText>
              <strong>
                We'll {isAddingNewStates ? ' also ' : ''} get you
                re-credentialed in {currentStatesFormatted}:{' '}
              </strong>
              {isAddingNewStates
                ? 'We do this to ensure that going forward your credentialing in all states will be' +
                  ' renewed at the same time. '
                : ''}{' '}
              Don’t worry — this will not interrupt your ability to provide care
              to clients in these states.
            </BodyText>
          </div>
        </div>
      )}
      {isDEAIntakeUpdatesEnabled &&
        isAddingNewStates &&
        !!values.providerSelectedPracticeStates?.length && (
          <YesNoQuestion
            name="isLicensedInSelectedPracticeStates"
            label="Are you licensed in each of the selected states?"
          />
        )}
      {isDEAIntakeUpdatesEnabled &&
        isAddingNewStates &&
        !!values.providerSelectedPracticeStates?.length && (
          <PrescriberRequirements values={values} />
        )}
      {shouldRenderCarriersTable ? (
        <div className="mb-4 grid max-w-[950px]">
          <Table aria-label="Insurance carriers by state">
            <TableHeader>
              <Column>In these states, we partner with...</Column>
              <Column>&nbsp;</Column>
            </TableHeader>
            <TableBody>
              {Object.entries(carriersWithSupportedStates).map(
                ([id, states]) => {
                  return (
                    <Row>
                      <Cell>{carriersById[parseInt(id)].name}</Cell>
                      <Cell>{convertStateListToString(states)}</Cell>
                    </Row>
                  );
                }
              )}
            </TableBody>
          </Table>
          {hasBcbsCarrier && (
            <div className="mt-6">
              <Banner variant="neutral">
                Note: Some carriers in these states have additional requirements
                for eligibility. Our credentialing team will only credential you
                with carriers you are eligible for.
              </Banner>
            </div>
          )}
        </div>
      ) : !isDEAIntakeUpdatesEnabled &&
        !!values?.providerSelectedPracticeStates?.length ? (
        <LoadingSkeleton />
      ) : (
        <></>
      )}
    </>
  );
};

export const onBeforePracticeStatesSubmit: QuestionnaireV2Step['onBeforeSubmit'] =
  async (
    values,
    setBeforeSubmitError,
    _setShowConfirmationModal,
    context,
    setFieldError,
    user
  ) => {
    const { provider } = context;
    const selectedStates = values.providerSelectedPracticeStates ?? [];

    try {
      // If a provider is new then they already have their PFECs created,
      // so we return early
      if (!isExistingProvider(provider)) {
        // We create this property using the providers PFECs, since it could be used
        // downstream in the intake form
        const providerSelectedCarriersByState =
          provider?.providerFrontEndCarriers?.reduce(
            (carriersByState, pfec) => {
              const state = pfec.providerLicenseState.state;

              if (carriersByState[state]) {
                carriersByState[state].push(pfec.frontEndCarrierId);
              } else {
                carriersByState[state] = [pfec.frontEndCarrierId];
              }

              return carriersByState;
            },
            {} as Required<ProviderQuestionnaireRawData>['providerSelectedCarriersByState']
          );

        return Object.assign(context.providerQuestionnaire.rawData ?? {}, {
          providerSelectedPracticeStates: values.providerSelectedPracticeStates,
          isAddingNewStates: 'false',
          providerSelectedCarriersByState: providerSelectedCarriersByState,
          isLicensedInSelectedPracticeStates:
            values.isLicensedInSelectedPracticeStates,
          hasDeaInSelectedPracticeStates: values.hasDeaInSelectedPracticeStates,
          hasCdsInSelectedPracticeStates: values.hasCdsInSelectedPracticeStates,
        });
      }

      // This beforeSubmit processing is to add/remove pls and pfecs
      // based on the state selection. Non-admins won't have this option.
      if (!hasRateAccess(provider, user)) {
        return true;
      }
      const selectedCarriers = values.providerSelectedCarriersByState;
      const authStore = initializeAuthStore();

      if (!selectedCarriers) {
        return false;
      }

      // Figure out which states were added and removed
      const existingStateList = provider.activeProviderLicenseStates.map(
        (pls: ProviderLicenseStateRead) => pls.state
      );
      const addedStates = selectedStates.filter(
        (state) => !existingStateList.includes(state)
      );
      const removeStatePLSs: ProviderLicenseStateRead[] = existingStateList
        .filter((state: UnitedStates) => !selectedStates.includes(state))
        .map((state: UnitedStates) =>
          provider.activeProviderLicenseStates.find(
            (pls: ProviderLicenseStateRead) => pls.state === state
          )
        )
        .filter((pls) => !!pls) as ProviderLicenseStateRead[];

      // Update the providers PLS and PFEC rows, tracking any errors that
      // happen for a state or carrier
      let stateErrorsAdd, stateErrorsRemove: UnitedStates[] | undefined;
      let carrierErrorsAdd, carrierErrorsRemove: string[] | undefined;

      if (addedStates.length > 0) {
        const addErrors = await addProviderLicenseStatesAndPFECs(
          provider,
          addedStates,
          selectedCarriers
        );
        stateErrorsAdd = addErrors.stateErrors.length
          ? addErrors.stateErrors
          : undefined;
        carrierErrorsAdd = addErrors.carrierErrors.length
          ? addErrors.carrierErrors
          : undefined;
      }

      if (removeStatePLSs.length > 0) {
        const removeErrors = await removePFECsAndTerminatePLSs(
          provider,
          removeStatePLSs
        );
        stateErrorsAdd = removeErrors.stateErrors.length
          ? removeErrors.stateErrors
          : undefined;
        carrierErrorsAdd = removeErrors.carrierErrors.length
          ? removeErrors.carrierErrors
          : undefined;
      }

      if (
        stateErrorsAdd ||
        stateErrorsRemove ||
        carrierErrorsAdd ||
        carrierErrorsRemove
      ) {
        if (stateErrorsAdd) {
          setFieldError(
            'providerSelectedPracticeStates',
            `Error in adding ${convertStateListToString(
              stateErrorsAdd
            )}, please talk to your practice consultant`
          );
        }

        if (stateErrorsRemove) {
          setFieldError(
            'providerSelectedPracticeStates',
            `Error in removing ${convertStateListToString(stateErrorsRemove)}`
          );
        }

        if (carrierErrorsAdd) {
          setFieldError(
            'providerSelectedPracticeStates',
            `Error in adding ${carrierErrorsAdd.join(', ')}`
          );
        }

        if (carrierErrorsRemove) {
          setFieldError(
            'providerSelectedPracticeStates',
            `Error in adding ${carrierErrorsRemove.join(', ')}`
          );
        }

        return false;
      }

      await authStore.fetchMe();
    } catch (e) {
      return false;
    }

    return Object.assign(context.providerQuestionnaire.rawData ?? {}, {
      providerSelectedPracticeStates: [
        ...getAllLiveStates(provider),
        ...selectedStates,
      ],
      isAddingNewStates: values.isAddingNewStates,
      providerSelectedCarriersByState: values.providerSelectedCarriersByState,
      isLicensedInSelectedPracticeStates:
        values.isLicensedInSelectedPracticeStates,
      hasDeaInSelectedPracticeStates: values.hasDeaInSelectedPracticeStates,
      hasCdsInSelectedPracticeStates: values.hasCdsInSelectedPracticeStates,
    });
  };

export const practiceStatesFormMeta: QuestionnaireV2Step['getFormMeta'] = (
  { provider, providerQuestionnaire, recredSummary },
  flags
) => {
  const { isPrescriber } = provider;
  const validationSchema = Yup.object().shape({
    isAddingNewStates: isExistingProvider(provider)
      ? Yup.string()
          .required('Please select an option')
          .oneOf(
            recredSummary?.isEligibleForRecred ? ['false', 'true'] : ['true'],
            'You are not currently eligible to be re-credentialed only. If you are not adding new states at this time, you do not need to complete this form yet'
          )
      : Yup.string(),
    providerSelectedPracticeStates: Yup.array()
      .of(Yup.string())
      .ensure()
      .when(
        'isAddingNewStates',
        (isAddingNewStates: string, schema: Yup.ArraySchema<string>) => {
          return isAddingNewStates === 'true'
            ? schema.min(
                1,
                isExistingProvider(provider)
                  ? 'Please add at least 1 new state or select "Get re-credentialed only"'
                  : 'Please add at least 1 state'
              )
            : schema;
        }
      ),
    isLicensedInSelectedPracticeStates: Yup.string().when(
      'providerSelectedPracticeStates',
      (providerSelectedPracticeStates: UnitedStates[], schema) =>
        flags?.deaIntakeFormUpdates && !!providerSelectedPracticeStates.length
          ? schema.required('This question is required.').oneOf([YesNo.YES], '')
          : schema
    ),
    hasDeaInSelectedPracticeStates: Yup.string().when(
      ['providerSelectedPracticeStates', 'isLicensedInSelectedPracticeStates'],
      (
        providerSelectedPracticeStates: UnitedStates[],
        isLicensedInSelectedPracticeStates,
        schema
      ) =>
        flags?.deaIntakeFormUpdates &&
        isPrescriber &&
        isLicensedInSelectedPracticeStates === YesNo.YES
          ? schema.required('This question is required.').oneOf([YesNo.YES], '')
          : schema
    ),
    hasCdsInSelectedPracticeStates: Yup.string().when(
      ['providerSelectedPracticeStates', 'isLicensedInSelectedPracticeStates'],
      (
        providerSelectedPracticeStates: UnitedStates[],
        isLicensedInSelectedPracticeStates,
        schema
      ) => {
        const isCdsRequired = !!filterStatesByCdsRequirement(
          providerSelectedPracticeStates,
          provider.licenseType
        ).length;

        return flags?.deaIntakeFormUpdates &&
          isPrescriber &&
          isLicensedInSelectedPracticeStates === YesNo.YES &&
          isCdsRequired
          ? schema.required('This question is required.').oneOf([YesNo.YES], '')
          : schema;
      }
    ),
  });
  const liveStates = getAllLiveStates(provider);

  const initialValue = Object.assign(providerQuestionnaire.rawData ?? {}, {
    isAddingNewStates:
      flags?.deaIntakeFormUpdates && !isExistingProvider(provider)
        ? true
        : providerQuestionnaire.rawData?.isAddingNewStates,
    providerSelectedPracticeStates: getSupportedStates(provider).filter(
      (state) => !liveStates.includes(state)
    ),
  });

  return {
    initialValue,
    validationSchema,
  };
};

const PracticeStatesStepConfig: QuestionnaireV2Step = {
  title: 'Practice States',
  description:
    'In this section we ask for any new states you may want to practice in.',
  Component: PracticeStatesStepContent,
  getFormMeta: practiceStatesFormMeta,
  onBeforeSubmit: onBeforePracticeStatesSubmit,
};

export default PracticeStatesStepConfig;

const LoadingSkeleton = ({ height = 500 }: { height?: number }) => (
  <div className={`h-[${height}px] max-w-[950px]`}>
    <Skeleton variant="rectangular" height={height} />
  </div>
);

export const LicenseErrorGuidanceCard = () => (
  <GuidanceCard variant="error" layout="vertical">
    <div>
      <strong>You are not eligible to get credentialed in these states.</strong>
      <p>
        You are required have a state license for every state where you want to
        get credentialed. Remove the states you are not licensed in to continue
        to the credentialing application.
      </p>
      <LinkButton
        variant="link"
        href={CREDENTIALING_REQUIREMENTS_ZENDESK_ARTICLE}
        target="_blank"
        rel="noreferrer"
      >
        Learn more about credentialing requirements
      </LinkButton>
    </div>
  </GuidanceCard>
);

export const DeaCdsErrorGuidanceCard = () => (
  <GuidanceCard variant="error" layout="vertical">
    <div>
      <strong>
        You are not eligible to join Headway as a prescriber in these states.
      </strong>
      <p>
        Remove the states you do not have a DEA or CDS registration in to
        continue to the credentialing application. If you are not a prescriber,{' '}
        <Link href="http://headway.co/contact" target="_blank">
          {' '}
          contact support
        </Link>
        .
      </p>
      <LinkButton
        variant="link"
        href={PRESCRIBER_REGISTRATION_REQUIREMENTS_ZENDESK_ARTICLE}
        target="_blank"
        rel="noreferrer"
      >
        Learn more about prescriber requirements
      </LinkButton>
    </div>
  </GuidanceCard>
);
