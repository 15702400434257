import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import { FrontEndCarrierReadByUser } from '@headway/api/models/FrontEndCarrierReadByUser';
import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

const KNOWN_FRONT_END_CARRIER_IDS_LOCAL_STORAGE_KEY = 'knownFrontEndCarrierIds';
const SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY = 'searchable-front-end-carriers';

class NonArrayJsonValueError extends Error {
  actual: unknown;
  constructor(message: string, actual: unknown) {
    super(message);

    this.actual = actual;
  }
}

const getUniqueNumericValuesFromJsonArray = (
  jsonArray: string
): Array<number> => {
  const parsed: unknown | undefined = JSON.parse(jsonArray);

  if (!Array.isArray(parsed)) {
    throw new NonArrayJsonValueError(
      'Expected JSON value to be an array',
      parsed
    );
  }

  return uniq(
    parsed.filter(
      (value) => typeof value === 'number' && Number.isFinite(value)
    )
  );
};

const useStoredKnownFrontEndCarrierIds = (): [
  Array<number>,
  (id: number) => void,
] => {
  const [existingKnownFECsStr, setInLocalStorage] = useLocalStorage(
    KNOWN_FRONT_END_CARRIER_IDS_LOCAL_STORAGE_KEY
  );

  const existingKnownFECs: Array<number> = existingKnownFECsStr
    ? getUniqueNumericValuesFromJsonArray(existingKnownFECsStr)
    : [];

  const addKnownFrontEndCarrierId = (id: number): void => {
    if (existingKnownFECs.includes(id)) {
      return;
    }

    const newKnownFrontEndCarrierIds = [...existingKnownFECs, id];
    setInLocalStorage(JSON.stringify(newKnownFrontEndCarrierIds));
  };

  return [existingKnownFECs, addKnownFrontEndCarrierId];
};

type GetSearchableFrontEndCarriersQueryParams = Parameters<
  typeof FrontEndCarrierApi.getSearchableFrontEndCarriers
>[0];
type QueryOptions = UseQueryOptions<
  Array<FrontEndCarrierReadByUser>,
  unknown,
  Array<FrontEndCarrierReadByUser>,
  [
    typeof SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY,
    GetSearchableFrontEndCarriersQueryParams,
  ]
>;

function useSearchableFrontEndCarriersQuery(
  returnAllUnlistedCarriers?: boolean,
  options?: QueryOptions
) {
  const [knownFrontEndCarrierIds] = useStoredKnownFrontEndCarrierIds();
  const queryParams: GetSearchableFrontEndCarriersQueryParams = {};

  if (knownFrontEndCarrierIds) {
    queryParams.known_front_end_carrier_ids = knownFrontEndCarrierIds;
  }
  if (returnAllUnlistedCarriers) {
    queryParams.return_all_unlisted_carriers = returnAllUnlistedCarriers;
  }
  const query = useQuery(
    [SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY, queryParams],
    () => FrontEndCarrierApi.getSearchableFrontEndCarriers(queryParams),
    { staleTime: Infinity, ...options }
  );

  const carriersById = useMemo(
    () => (query.data ? keyBy(query.data, 'id') : {}),
    [query.data]
  );
  return {
    frontEndCarriers: query.data ?? [],
    carriersById: carriersById,
    isCarriersLoading: query.isLoading,
    query,
  };
}

export { useSearchableFrontEndCarriersQuery };
