import { getLocalTimeZone } from '@internationalized/date';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import React, { useState } from 'react';

import { GetSessionsByProviderSessionSummary } from '@headway/api/models/GetSessionsByProviderSessionSummary';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { IconButton } from '@headway/helix/IconButton';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconCaretRight } from '@headway/helix/icons/CaretRight';
import { IconCheck } from '@headway/helix/icons/Check';
import { IconDotsThree } from '@headway/helix/icons/DotsThree';
import { Link } from '@headway/helix/Link';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { VisuallyHidden } from '@headway/helix/utils';
import { getProviderDisplayFirstAndLastWithPrenomial } from '@headway/shared/utils/providers';

import { ProviderNameData } from './types';

interface SessionDisclosureProps {
  provider: ProviderNameData;
  sessions: GetSessionsByProviderSessionSummary[];
}

const SHOW_MORE_LIMIT = 3;

const timeFormatter = Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: getLocalTimeZone(),
});

export const SessionDisclosure = ({
  provider,
  sessions,
}: SessionDisclosureProps) => {
  const [contentId] = useState(uniqueId('session-disclosure-'));
  const [isShowingSessions, setIsShowingSessions] = useState(true);
  const [isShowingMore, setIsShowingMore] = useState(false);

  const showMoreButtonVisible = sessions.length > SHOW_MORE_LIMIT;
  const numUnconfirmedSessions = sessions.filter(
    (s) => s.providerAppointmentStatus === ProviderAppointmentStatus.SCHEDULED
  ).length;

  return (
    <section className="mx-auto rounded border border-solid border-border-primary">
      <div className="flex items-center gap-2 whitespace-nowrap bg-background-secondary px-5 py-2">
        <button
          aria-expanded={isShowingSessions}
          aria-controls={contentId}
          onClick={() =>
            setIsShowingSessions((isShowingSessions) => !isShowingSessions)
          }
          className="-mx-1 flex cursor-pointer items-center gap-2 rounded border-0 bg-background-secondary p-0 px-1 ring-border-focus focus:ring-2"
        >
          {isShowingSessions ? <IconCaretDown /> : <IconCaretRight />}
          <h2>
            <ContentText variant="body-large/medium">
              {getProviderDisplayFirstAndLastWithPrenomial(provider)}
            </ContentText>
          </h2>
        </button>
        <ContentText variant="body-small">
          {sessions.length} session{sessions.length === 1 ? '' : 's'}
        </ContentText>
        <div className="ml-auto">
          {numUnconfirmedSessions > 0 ? (
            <Button variant="secondary">
              {/** TODO: Add button handler */}
              Confirm scheduled sessions ({numUnconfirmedSessions})
            </Button>
          ) : (
            <ContentText
              variant="body-small/medium"
              color="foreground/compliance"
            >
              <span className="flex items-end gap-1">
                <IconCheck />
                All scheduled sessions confirmed
              </span>
            </ContentText>
          )}
        </div>
      </div>
      {isShowingSessions && (
        <div
          id={contentId}
          className={clsx(
            'flex flex-col',
            !showMoreButtonVisible && '-mb-[1px]'
          )}
        >
          <Table
            aria-label={`Sessions for ${getProviderDisplayFirstAndLastWithPrenomial(
              provider
            )}`}
          >
            <TableHeader>
              <Column width={300}>Sessions</Column>
              <Column width={134}>Time</Column>
              <Column>Status</Column>
              <Column align="right">
                <VisuallyHidden>Actions</VisuallyHidden>
              </Column>
            </TableHeader>
            <TableBody>
              {sessions
                .slice(0, isShowingMore ? undefined : 3)
                .map((session) => (
                  <Row key={session.providerEventVirtualId}>
                    <Cell>
                      {/** TODO: Add link to client detail page */}
                      Session with <Link>{session.patientFullName}</Link>
                    </Cell>
                    <Cell>
                      {timeFormatter.format(new Date(session.startDate))}
                    </Cell>
                    <Cell>
                      <Badge variant="negative">TODO</Badge>
                    </Cell>
                    <Cell>
                      <div className="flex flex-row-reverse">
                        <div className="-my-2">
                          <MenuTrigger>
                            <IconButton
                              variant="transparent"
                              aria-label="More actions"
                            >
                              <IconDotsThree />
                            </IconButton>
                            <Menu>
                              <MenuItem>TODO</MenuItem>
                            </Menu>
                          </MenuTrigger>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                ))}
            </TableBody>
          </Table>
          {showMoreButtonVisible && (
            <div className="grid h-[46px]">
              <Button
                variant="link"
                onPress={() =>
                  setIsShowingMore((isShowingMore) => !isShowingMore)
                }
              >
                {isShowingMore ? 'Show less' : 'Show more'}
              </Button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
