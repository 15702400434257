import type { IconWeight } from '@phosphor-icons/react';
import React, { forwardRef, ReactElement } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path d="M184.597 124.256a39.467 39.467 0 0 0 5.771 -20.587A39.712 39.712 0 0 0 150.699 64a39.712 39.712 0 0 0 -39.68 39.68c0 7.52 2.123 14.56 5.781 20.576a75.84 75.84 0 0 0 -41.376 63.648 7.253 7.253 0 0 0 7.296 7.637H218.667a7.328 7.328 0 0 0 7.296 -7.637 75.84 75.84 0 0 0 -41.376 -63.648zm-33.899 -52.288a31.755 31.755 0 0 1 31.712 31.712 31.755 31.755 0 0 1 -31.712 31.723 31.755 31.755 0 0 1 -31.712 -31.723 31.755 31.755 0 0 1 31.712 -31.712m-67.296 115.627a67.84 67.84 0 0 1 38.293 -56.885 39.595 39.595 0 0 0 58.005 0 67.84 67.84 0 0 1 38.293 56.875zm-5.013 -56.885a67.307 67.307 0 0 0 -38.421 60.864 3.989 3.989 0 0 1 -7.968 0 75.307 75.307 0 0 1 41.493 -67.328 39.467 39.467 0 0 1 -5.803 -20.565 39.712 39.712 0 0 1 39.669 -39.669 3.989 3.989 0 0 1 0 7.968 31.755 31.755 0 0 0 -31.712 31.723c0 11.115 5.952 21.568 15.52 27.275a3.968 3.968 0 1 1 -4.075 6.827 39.467 39.467 0 0 1 -8.725 -7.083z" />,
  ],
]);
export const IconLoyalClients: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} viewBox="39 36 180 195" {...props} weights={weights} />
));

IconLoyalClients.variants = ['outlined'];
IconLoyalClients.defaultVariant = 'outlined';
