import { ChatCircleText } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconChatCircleText: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={ChatCircleText}
    ref={ref}
  />
));

IconChatCircleText.variants = ['outlined', 'filled'];
IconChatCircleText.defaultVariant = 'outlined';
