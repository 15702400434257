import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Link } from '@headway/helix/Link';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useIntakeCompletionStatus } from '@headway/shared/hooks/useIntakeCompletionStatus';
import {
  formatDate,
  hasIncompletePayerQuestionnaire,
} from '@headway/shared/utils/insuranceStatus';
import { getIsProviderCredentialedWithFrontEndCarrier } from '@headway/shared/utils/postIntakeInfoCollection';
import { PayerTimelineItem } from '@headway/ui/providers/PayerTimelineItem';
import { PayerTimelineItemStatus } from '@headway/ui/providers/PayerTimelineItemStatus';

interface ApplyTimelineItemProps {
  payerName: string;
  appliedDate?: Date;
  payerQuestionnaire: PayerQuestionnaire | undefined;
  provider: ProviderRead;
}

export function ApplyTimelineItem({
  payerName,
  appliedDate,
  payerQuestionnaire,
  provider,
}: ApplyTimelineItemProps) {
  const shouldSkipIntake = useFlag('skipIntake');
  const { isIntakeIncomplete } = useIntakeCompletionStatus(provider.id);
  const payerQuestionnaireUrl = `/payer-questionnaire/${payerQuestionnaire?.response.id}`;
  const isProviderCredentialed = getIsProviderCredentialedWithFrontEndCarrier(
    provider,
    payerQuestionnaire?.template.frontEndCarrierId
  );

  let detail;

  if (
    !isProviderCredentialed &&
    !shouldSkipIntake &&
    hasIncompletePayerQuestionnaire(payerQuestionnaire)
  ) {
    detail = isIntakeIncomplete ? (
      <div className="flex w-[60%] flex-col gap-2">
        <SubBodyText>
          Finish your application via your{' '}
          <Link rel="noreferrer" href={payerQuestionnaireUrl}>
            in-progress credential form
          </Link>
          .
        </SubBodyText>
      </div>
    ) : (
      <Link rel="noreferrer" href={payerQuestionnaireUrl}>
        Finish your application
      </Link>
    );
  } else if (!shouldSkipIntake && appliedDate) {
    detail = `Applied ${formatDate(appliedDate)}`;
  }

  return (
    <PayerTimelineItem
      bigTitle
      status={
        shouldSkipIntake ||
        (hasIncompletePayerQuestionnaire(payerQuestionnaire) &&
          !isProviderCredentialed)
          ? PayerTimelineItemStatus.IN_PROGRESS
          : PayerTimelineItemStatus.COMPLETE
      }
      title={
        hasIncompletePayerQuestionnaire(payerQuestionnaire) &&
        !isProviderCredentialed
          ? `Apply to ${payerName}`
          : `Applied to ${payerName}`
      }
      body={
        hasIncompletePayerQuestionnaire(payerQuestionnaire) &&
        !isProviderCredentialed
          ? ''
          : 'Submit your application'
      }
      detail={detail}
    />
  );
}
