import React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  OverlayTriggerStateContext,
  Popover,
} from 'react-aria-components';
import type { MenuItemProps, MenuProps } from 'react-aria-components';

import { IconCaretDown } from './icons/CaretDown';

type SplitButtonProps = {
  size?: 'medium' | 'large';
  variant?: 'brand' | 'primary' | 'secondary';
  children: [React.ReactElement, React.ReactElement];
};

function SplitButton({
  size = 'large',
  variant = 'primary',
  children,
}: SplitButtonProps) {
  const [cta, menu] = React.Children.toArray(children);

  return (
    <div
      className="hlx-split-button hlx-split-button-root"
      data-hlx-size={size}
      data-hlx-variant={variant}
    >
      {React.cloneElement(cta as React.ReactElement, {
        variant,
      })}
      <MenuTrigger>
        <SplitButtonTrigger />
        <Popover placement="bottom right" className="hlx-popover" offset={4}>
          {menu}
        </Popover>
      </MenuTrigger>
    </div>
  );
}

function SplitButtonTrigger() {
  const { open } = React.useContext(OverlayTriggerStateContext);

  return (
    <Button
      className="hlx-split-button-trigger"
      onFocus={(event) => {
        const from = event.relatedTarget as HTMLElement;

        // Don't open the menu if focus is moving from within the menu
        // back to the trigger button.
        if (from == null || from.closest('.hlx-split-button-menu')) {
          if (event.target instanceof HTMLElement) {
            event.target.blur();
          }
          return;
        }

        open();
      }}
    >
      <IconCaretDown size={16} />
    </Button>
  );
}

function SplitButtonMenu<T extends object>(props: MenuProps<T>) {
  return (
    <Menu
      className="hlx-menu hlx-split-button-menu"
      {...props}
      autoFocus="first"
    />
  );
}
function SplitButtonMenuItem(props: MenuItemProps) {
  return <MenuItem className="hlx-menu-item" {...props} />;
}

export { SplitButton, SplitButtonMenu, SplitButtonMenuItem };
