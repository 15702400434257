import pick from 'lodash/pick';
import { useMemo } from 'react';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskStatus } from '@headway/api/models/ProviderTaskStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { WrapNetwork } from '@headway/api/models/WrapNetwork';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { ProviderTaskApi } from '@headway/api/resources/ProviderTaskApi';
import { usePayerQuestionnaires } from '@headway/shared/hooks/usePayerQuestionnaires';
import { useQuery } from '@headway/shared/react-query';
import { PayerStatus, PayerStatusMap } from '@headway/shared/types/payerStatus';
import {
  getTaskStatusMap,
  hasIncompletePayerQuestionnaire,
  isDateInPast,
  parseDate,
  TaskStatusMap,
} from '@headway/shared/utils/insuranceStatus';

export interface PayerTimelineData {
  id: number;
  payerStatus: PayerStatus;
  frontEndCarrierId: number;
  payerName: string;
  appliedDate?: Date;
  appointmentReadyDate?: Date;
  paymentReadyDate?: Date;
  firstPaymentEligibleDate?: Date;
  estimatedAppointmentReadyDate?: Date;
  wrapNetwork?: WrapNetwork | null | undefined;
  payerQuestionnaire?: PayerQuestionnaire | undefined;
  state: UnitedStates;
}
// data that is shared in common between multiple grouped payer timelines
export type PayerTimelineGroupSharedData = Pick<
  PayerTimelineData,
  | 'payerStatus'
  | 'frontEndCarrierId'
  | 'payerName'
  | 'appliedDate'
  | 'appointmentReadyDate'
  | 'paymentReadyDate'
  | 'firstPaymentEligibleDate'
  | 'estimatedAppointmentReadyDate'
  | 'wrapNetwork'
  | 'payerQuestionnaire'
>;
export type PayerTimelineGroup = {
  sharedData: PayerTimelineGroupSharedData;
  payerTimelines: PayerTimelineData[];
};
export type PayerTimelineGroupsByPayer = Map<
  string, // key is payer name
  PayerTimelineGroup[]
>;

function hasTaskWithStatusInStateOrAllStates(
  taskStatusMap: TaskStatusMap,
  statuses: ProviderTaskStatus[],
  providerLicenseStateId: number,
  frontEndCarrierId: number
): boolean {
  const tasksWithStatus: ProviderTaskRead[] = statuses.reduce(
    (tasks, status) => tasks.concat(taskStatusMap[status] ?? []),
    [] as ProviderTaskRead[]
  );
  return (
    !!tasksWithStatus &&
    tasksWithStatus.some(
      (task) =>
        !task.providerLicenseStateId || // null/undefined means the task applies to all states
        task.providerLicenseStateId === providerLicenseStateId
    ) &&
    tasksWithStatus.some(
      (task) =>
        !task.frontEndCarrierIds?.length ||
        task.frontEndCarrierIds.includes(frontEndCarrierId)
    )
  );
}

function getPayerStatus(
  payerQuestionnaire: undefined | PayerQuestionnaire,
  firstPaymentEligibleDate: undefined | Date,
  paymentReadyDate: undefined | Date,
  appointmentReadyDate: undefined | Date,
  estimatedAppointmentReadyDate: undefined | Date,
  internalTargetAppointmentReadyDate: undefined | Date,
  credentialedOn: undefined | Date,
  providerLicenseStateId: number,
  frontEndCarrierId: number,
  taskStatusMap?: TaskStatusMap
) {
  const isDelayed =
    estimatedAppointmentReadyDate &&
    internalTargetAppointmentReadyDate &&
    estimatedAppointmentReadyDate > internalTargetAppointmentReadyDate;

  const hasActionNeededTask =
    taskStatusMap &&
    hasTaskWithStatusInStateOrAllStates(
      taskStatusMap,
      [
        ProviderTaskStatus.ACTION_NEEDED_HIGH_PRIORITY,
        ProviderTaskStatus.ACTION_NEEDED,
      ],
      providerLicenseStateId,
      frontEndCarrierId
    );

  if (isDateInPast(firstPaymentEligibleDate)) {
    return PayerStatus.COMPLETE;
  } else if (isDateInPast(paymentReadyDate)) {
    return PayerStatus.PAYMENT_READY;
  } else if (isDateInPast(appointmentReadyDate)) {
    return PayerStatus.APPOINTMENT_READY;
  } else if (credentialedOn) {
    return PayerStatus.CREDENTIALED;
  } else if (hasIncompletePayerQuestionnaire(payerQuestionnaire)) {
    return PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED;
  } else if (hasActionNeededTask) {
    return PayerStatus.CREDENTIALING_DELAYED_ACTION_NEEDED;
  } else if (isDelayed) {
    return PayerStatus.CREDENTIALING_DELAYED;
  } else {
    return PayerStatus.APPLIED;
  }
}

function sortPayerTimelinesByStatus(payerTimelines: PayerTimelineData[]) {
  const timelineOrder = [
    PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED,
    PayerStatus.CREDENTIALING_DELAYED_ACTION_NEEDED,
    PayerStatus.CREDENTIALING_DELAYED,
    PayerStatus.APPLIED,
    PayerStatus.CREDENTIALED,
    PayerStatus.APPOINTMENT_READY,
    PayerStatus.PAYMENT_READY,
    PayerStatus.COMPLETE,
  ];

  return timelineOrder
    .map((status) => {
      return payerTimelines.filter(
        (timeline) => timeline.payerStatus === status
      );
    })
    .flat();
}

export function getSharedDataFromPayerTimeline(
  payerTimeline: PayerTimelineData
): PayerTimelineGroupSharedData {
  return pick(
    payerTimeline,
    'payerStatus',
    'frontEndCarrierId',
    'payerName',
    'appliedDate',
    'appointmentReadyDate',
    'paymentReadyDate',
    'firstPaymentEligibleDate',
    'estimatedAppointmentReadyDate',
    'wrapNetwork',
    'payerQuestionnaire'
  );
}

function groupPayerTimelinesByPayerAndTimelineAndSortByStatus(
  payerTimelines: PayerTimelineData[]
): PayerTimelineGroupsByPayer {
  // maps respect insertion order, so we sort timelines by status first to guarantee the grouped order is correct
  const sortedTimelines = sortPayerTimelinesByStatus(payerTimelines);
  // intermediary map to aggregate timelines for PayerTimelineGroups
  const groupedTimelines: Map<
    string,
    Map<string, PayerTimelineData[]>
  > = new Map();
  sortedTimelines.forEach((timeline) => {
    const {
      payerName,
      payerStatus,
      appliedDate,
      appointmentReadyDate,
      estimatedAppointmentReadyDate,
      paymentReadyDate,
      firstPaymentEligibleDate,
    } = timeline;
    // this key is based on the timeline and status data that we use in the PayerTimeline component. we group states
    // with the same timeline/status together
    const timelineKey = `${payerStatus}:${appliedDate}:${appointmentReadyDate}:${estimatedAppointmentReadyDate}:${paymentReadyDate}:${firstPaymentEligibleDate}`;
    if (groupedTimelines.has(payerName)) {
      const payerStatesMap = groupedTimelines.get(payerName)!;
      if (payerStatesMap.has(timelineKey)) {
        payerStatesMap.get(timelineKey)!.push(timeline);
      } else {
        payerStatesMap.set(timelineKey, [timeline]);
      }
    } else {
      groupedTimelines.set(payerName, new Map([[timelineKey, [timeline]]]));
    }
  });
  // transform to GroupedPayerTimelineMap
  const groupedPayerTimelineMap: PayerTimelineGroupsByPayer = new Map();
  groupedTimelines.forEach((timelineKeyToTimelinesMap, payerName) => {
    const payerTimelineGroups: PayerTimelineGroup[] = [];
    timelineKeyToTimelinesMap.forEach((payerTimelines) => {
      payerTimelineGroups.push({
        sharedData: getSharedDataFromPayerTimeline(
          payerTimelines[0] // this data is the same in each timeline, so we can just use the first one
        ),
        payerTimelines,
      });
    });
    groupedPayerTimelineMap.set(payerName, payerTimelineGroups);
  });
  return groupedPayerTimelineMap;
}

export function useInsuranceStatus(provider: ProviderRead) {
  const {
    isLoading: pfecIsLoading,
    isError: pfecIsError,
    data: pfecs,
  } = useQuery(['providerFrontEndCarriers', provider.id], async () => {
    const pfecs = await ProviderFrontEndCarrierApi.getProviderFrontEndCarriers({
      provider_id: provider.id,
    });
    return pfecs.filter(
      (pfec) => pfec.providerLicenseState.questionnaireCompletedOn
    );
  });

  const {
    isLoading: tasksIsLoading,
    isError: tasksIsError,
    data: tasks,
    refetch: refetchTasks,
  } = useQuery(['providerTasks', provider.id], async () => {
    const tasks = await ProviderTaskApi.getProviderTasks({
      provider_id: provider.id,
    });
    return {
      taskStatusMap: getTaskStatusMap(tasks),
    };
  });

  const {
    data: payerQuestionnaires,
    isLoading: payerQuestionnairesIsLoading,
    isError: payerQuestionnairesIsError,
  } = usePayerQuestionnaires({
    provider,
    liveOnly: true,
  });

  const providerLicenseStateById: Map<number, ProviderLicenseStateRead> =
    new Map();
  provider.activeProviderLicenseStates.forEach((providerLicenseState) => {
    providerLicenseStateById.set(providerLicenseState.id, providerLicenseState);
  });

  const { payerStatusMap, groupedPayerTimelines, sortedPayerTimelines } =
    useMemo(() => {
      const statusesMap: PayerStatusMap = new Map();
      const payerTimelines: PayerTimelineData[] = [];

      pfecs?.forEach((pfec) => {
        const payerQuestionnaire = payerQuestionnaires?.find(
          (questionnaire: PayerQuestionnaire) =>
            questionnaire.template.frontEndCarrierId === pfec.frontEndCarrierId
        );
        const appliedDate = payerQuestionnaire
          ? parseDate(payerQuestionnaire.response.completedOn)
          : parseDate(pfec.firstAppliedDate);
        const appointmentReadyDate = parseDate(pfec.appointmentReadyDate);
        const paymentReadyDate = parseDate(pfec.paymentReadyDate);
        const firstPaymentEligibleDate = parseDate(
          pfec.firstPaymentEligibleDate
        );
        const credentialedOn = parseDate(pfec.credentialedOn);
        const estimatedAppointmentReadyDate = parseDate(
          pfec.estimatedAppointmentReadyDate
        );
        const internalTargetAppointmentReadyDate = parseDate(
          pfec.internalTargetAppointmentReadyDate
        );

        const payerName = pfec.frontEndCarrier.name;

        const wrapNetwork = pfec.wrapNetwork;

        let payerStatus = getPayerStatus(
          payerQuestionnaire,
          firstPaymentEligibleDate,
          paymentReadyDate,
          appointmentReadyDate,
          estimatedAppointmentReadyDate,
          internalTargetAppointmentReadyDate,
          credentialedOn,
          pfec.providerLicenseStateId,
          pfec.frontEndCarrierId,
          tasks?.taskStatusMap
        );

        const state = providerLicenseStateById.get(
          pfec.providerLicenseStateId
        )!.state;

        const timeline = {
          id: pfec.id,
          frontEndCarrierId: pfec.frontEndCarrierId,
          payerName,
          payerStatus,
          appliedDate,
          appointmentReadyDate,
          estimatedAppointmentReadyDate,
          paymentReadyDate,
          firstPaymentEligibleDate,
          wrapNetwork,
          payerQuestionnaire,
          state,
        };

        payerTimelines.push(timeline);

        if (statusesMap.has(payerStatus)) {
          const payerStatesMap = statusesMap.get(payerStatus)!;
          if (payerStatesMap.has(payerName)) {
            payerStatesMap.get(payerName)!.push(state);
          } else {
            payerStatesMap.set(payerName, [state]);
          }
        } else {
          statusesMap.set(payerStatus, new Map([[payerName, [state]]]));
        }
      });

      const groupedPayerTimelines =
        groupPayerTimelinesByPayerAndTimelineAndSortByStatus(payerTimelines);
      const sortedPayerTimelines = sortPayerTimelinesByStatus(payerTimelines);

      return {
        payerStatusMap: statusesMap,
        groupedPayerTimelines,
        sortedPayerTimelines,
      };
    }, [pfecs, tasks, payerQuestionnaires]);

  const isLoading =
    pfecIsLoading || tasksIsLoading || payerQuestionnairesIsLoading;
  const isError = pfecIsError || tasksIsError || payerQuestionnairesIsError;
  return {
    tasks,
    payerStatusMap,
    payerTimelines: sortedPayerTimelines,
    groupedPayerTimelines,
    payerQuestionnaires,
    refetchTasks,
    isLoading,
    isError,
  };
}
