import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useValidProviderProgressNotesQueryKeyArgs {
  providerAppointmentId: number;
}

export const getUseValidProviderProgressNotesQueryKey = ({
  providerAppointmentId,
}: useValidProviderProgressNotesQueryKeyArgs): [string, number] => [
  'get-valid-provider-progress-notes',
  providerAppointmentId,
];

const {
  useSingleQuery: useValidProviderProgressNotes,
  useListQuery: useValidProviderProgressNotesList,
  useCachedQuery: useValidProviderProgressNotesCache,
} = createBasicApiHooks(
  getUseValidProviderProgressNotesQueryKey,
  ({ providerAppointmentId }) =>
    ProviderProgressNotesApi.getValidProviderProgressNotes(
      providerAppointmentId
    ),
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch the valid provider progress notes for this appointment'
);

export {
  useValidProviderProgressNotes,
  useValidProviderProgressNotesList,
  useValidProviderProgressNotesCache,
};
