import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';

export type PostIntakeInfoCollectionFormState = {
  userId: number;
  providerId: number;
  name: string;
  email: string;
  helloSignEmbedded: any;
  environment?: string;
  helloSignSdkClientId?: string;
};

//TODO(revatir): we should add a `is_medicare_advantage` column to the FEC table and update this method to check if True
export const isMedicareAdvantage = (frontEndCarrierName: string) =>
  frontEndCarrierName.includes('Medicare Advantage');

export const isNonOptumManagedMedicaid = (frontEndCarrierName: string) =>
  !frontEndCarrierName.includes('Optum') &&
  frontEndCarrierName.includes('Medicaid');

export const isOptumManagedMedicaid = (frontEndCarrierName: string) =>
  frontEndCarrierName.includes('Optum Medicaid');

export const MEDICARE_ADVANTAGE_PARAM = 'medicareAdvantage';
export const NON_OPTUM_MEDICAID_PARAM = 'nonOptumMedicaid';
export const OPTUM_MANAGED_MEDICAID_PARAM = 'optumManagedMedicaid';

export const getIsProviderCredentialedWithFrontEndCarrier = (
  provider: ProviderRead,
  frontEndCarrierId: number | undefined
): boolean => {
  if (!frontEndCarrierId) return false;

  const providerFrontEndCarrier = provider.providerFrontEndCarriers?.find(
    (carrier: ProviderFrontEndCarrierRead) =>
      carrier.frontEndCarrierId === frontEndCarrierId
  );

  return !!providerFrontEndCarrier?.credentialedOn;
};
