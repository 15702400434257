import {
  ArrowForward,
  CheckCircleTwoTone,
  CloseTwoTone,
} from '@mui/icons-material';
import { ButtonProps, IconButton } from '@mui/material';
import moment from 'moment';
import React from 'react';

import { GetSessionsByProviderSessionSummary } from '@headway/api/models/GetSessionsByProviderSessionSummary';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Button, VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { DetailPopover, DetailPopoverProps } from './DetailPopover';

type TaskDetailProps = ButtonProps & {
  sessionSummary: GetSessionsByProviderSessionSummary;
  handleTaskCancelClick: (providerEventVirtualId: string) => void;
  handleTaskConfirmDetailsClick: (providerEventVirtualId: string) => void;
};

const TaskDetail: React.FC<React.PropsWithChildren<TaskDetailProps>> = ({
  sessionSummary,
  handleTaskCancelClick,
  handleTaskConfirmDetailsClick,
}) => {
  return (
    <div
      css={{
        borderTop: `1px solid ${theme.color.border}`,
        padding: theme.space.base,
      }}
    >
      <div>
        <h4
          css={{
            fontFamily: theme.fontFamily.canelaText,
            color: theme.color.primary,
            fontSize: theme.fontSize.lg,
            margin: `0 0 ${theme.space.xs2} 0`,
          }}
        >
          {sessionSummary.patientFullName}
        </h4>
        <div
          css={{
            fontFamily: theme.fontFamily.postGrotesk,

            fontSize: theme.fontSize.sm,
          }}
        >
          {moment(sessionSummary.startDate).format('dddd, MMMM Do [at] h:mma')}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: theme.space.base,
        }}
      >
        <Button
          color="gray"
          onClick={() => {
            handleTaskConfirmDetailsClick(
              sessionSummary.providerEventVirtualId
            );
            trackEvent({
              name: `Task - Confirm Session Details Clicked`,
              properties: {
                providerId: sessionSummary.providerId,
                patientUserId: sessionSummary.patientUserId,
                providerAppointmentId: sessionSummary.providerAppointmentId,
              },
            });
          }}
          variant="outlined"
          css={{ marginRight: theme.space.xs }}
          data-testid="calendarTaskConfirmDetailsButton"
          endIcon={<ArrowForward />}
        >
          Confirm session details
        </Button>
        <Button
          color="gray"
          onClick={() =>
            handleTaskCancelClick(sessionSummary.providerEventVirtualId)
          }
          variant="outlined"
          data-testid="calendarTaskCancelButton"
          endIcon={<ArrowForward />}
        >
          Cancel session
        </Button>
      </div>
    </div>
  );
};

export type TaskPopoverProps = DetailPopoverProps & {
  sessionSummaries: GetSessionsByProviderSessionSummary[];
  handleTaskCancelClick: TaskDetailProps['handleTaskCancelClick'];
  handleTaskConfirmDetailsClick: TaskDetailProps['handleTaskConfirmDetailsClick'];
  onClose: () => void;
};

export const TaskPopover: React.FC<
  React.PropsWithChildren<TaskPopoverProps>
> = ({
  open,
  onClose,
  anchorEl,
  sessionSummaries,
  handleTaskConfirmDetailsClick,
  handleTaskCancelClick,
}) => {
  return (
    <DetailPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        style: {
          minWidth: 382,
        },
      }}
    >
      <div
        css={{
          padding: theme.space.base,
          borderBottom: `1px solid ${theme.color.border}`,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: theme.fontFamily.postGrotesk,
          }}
        >
          <h4 css={{ margin: 0 }}>Tasks</h4>
          <IconButton
            onClick={onClose}
            css={{
              margin: `-${theme.space.xs}`,
            }}
            aria-label="Close calendar tasks"
            data-testid="calendarTaskCloseButton"
            size="large"
          >
            <CloseTwoTone
              css={{
                width: 24,
                height: 24,
              }}
            />
            <VisuallyHidden>Close</VisuallyHidden>
          </IconButton>
        </div>
      </div>
      <div
        css={{
          marginTop: -1,
          [theme.media.medium]: { maxHeight: 420, overflowY: 'scroll' },
        }}
      >
        {sessionSummaries.length ? (
          sessionSummaries.map((sessionSummary) => {
            return (
              <TaskDetail
                key={sessionSummary.providerEventVirtualId}
                sessionSummary={sessionSummary}
                handleTaskConfirmDetailsClick={(providerEventVirtualId) => {
                  handleTaskConfirmDetailsClick(providerEventVirtualId);
                  onClose();
                }}
                handleTaskCancelClick={handleTaskCancelClick}
              />
            );
          })
        ) : (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: theme.space.xl,
            }}
          >
            <CheckCircleTwoTone
              css={{
                width: 50,
                height: 50,
                color: theme.color.success,
                marginBottom: theme.space.base,
              }}
            />
            <div
              css={{
                fontSize: theme.fontSize.base,
                fontFamily: theme.fontFamily.postGrotesk,
              }}
              data-testid="calendarTaskAllDone"
            >
              All done!
            </div>
          </div>
        )}
      </div>
    </DetailPopover>
  );
};
