import { Clock } from '@phosphor-icons/react';
import { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconClock: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    defaultVariant="outlined"
    variants={['outlined', 'filled']}
    phosphorIcon={Clock}
    ref={ref}
  />
));

IconClock.variants = ['outlined', 'filled'];
IconClock.defaultVariant = 'outlined';
