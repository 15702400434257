import { PiggyBank } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconPiggyBank: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={PiggyBank}
    ref={ref}
  />
));

IconPiggyBank.variants = ['outlined', 'filled'];
IconPiggyBank.defaultVariant = 'outlined';
