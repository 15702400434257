import { View } from '@react-pdf/renderer';
import moment from 'moment';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { FULL_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import { SHORT_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import { PdfAssessmentRenderer } from '@headway/ui/assessments/renderer';
import { PdfPage, PdfText } from '@headway/ui/pdf';

import { AssessmentResultPdfFooterContent } from './AssessmentResultsPdfFooterContent';
import {
  AssessmentResultsPdfHeader,
  AssessmentResultsPdfHeaderProps,
} from './AssessmentResultsPdfHeader';
import { AssessmentResultsPdfInsights } from './AssessmentResultsPdfInsights';

type AssessmentResultsPdfPageProps = AssessmentResultsPdfHeaderProps & {
  assessment: PatientAssessmentRead;
};

export const AssessmentResultsPdfPage = ({
  assessment: {
    assessmentType,
    completedOn,
    score,
    subscores,
    scorableResponseJson,
    nonScorableResponseJson,
    safetyRisk,
  },
  ...headerProps
}: AssessmentResultsPdfPageProps) => {
  if (!scorableResponseJson) {
    throw new Error('Cannot generate PDF for incomplete assessment');
  }

  return (
    <PdfPage
      footerContent={
        <AssessmentResultPdfFooterContent assessmentType={assessmentType} />
      }
    >
      <AssessmentResultsPdfHeader {...headerProps} />
      <View style={{ flexDirection: 'column', gap: 4 }}>
        <PdfText style={{ fontSize: 13, fontWeight: 'medium' }}>
          {FULL_ASSESSMENT_NAMES[assessmentType]} |{' '}
          {SHORT_ASSESSMENT_NAMES[assessmentType]}
        </PdfText>
        <PdfText style={{ fontWeight: 'light' }}>
          {moment(completedOn).format('MMMM D, YYYY')}
        </PdfText>
      </View>
      <AssessmentResultsPdfInsights
        assessmentType={assessmentType}
        score={score}
        subscores={subscores}
        safetyRisk={safetyRisk}
      />
      <PdfAssessmentRenderer
        assessmentType={assessmentType}
        response={{ scorableResponseJson, nonScorableResponseJson }}
      />
    </PdfPage>
  );
};
