import React from 'react';
import { RequireExactlyOne } from 'type-fest';

import { theme } from './theme';

export interface AvatarProps {
  label?: string;
  'aria-hidden'?: boolean;
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  backgroundColor?: React.CSSProperties['backgroundColor'];
}

type AvatarAriaProps = RequireExactlyOne<AvatarProps, 'label' | 'aria-hidden'>;

function Avatar(props: AvatarAriaProps) {
  return (
    <div
      className="hlx-avatar"
      data-hlx-size={props.size}
      style={{
        backgroundColor: props.backgroundColor ?? 'transparent',
        color: props.backgroundColor
          ? theme.color.foreground.primary
          : theme.color.foreground.compliance,
        border: props.backgroundColor
          ? 'none'
          : `1px solid ${theme.color.border.primary}`,
      }}
      aria-hidden={props['aria-hidden']}
      aria-label={props.label}
    >
      {props.children}
    </div>
  );
}

export { Avatar };
