import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { cloneDeep, Dictionary, maxBy, minBy, remove, sortBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { AgeGroupRead } from '@headway/api/models/AgeGroupRead';
import { FilterType } from '@headway/api/models/FilterType';
import { ProviderAgeGroupRead } from '@headway/api/models/ProviderAgeGroupRead';
import { ProviderFilterRead } from '@headway/api/models/ProviderFilterRead';
import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderUpdate } from '@headway/api/models/ProviderUpdate';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { AgeGroupApi } from '@headway/api/resources/AgeGroupApi';
import { ProviderAgeGroupApi } from '@headway/api/resources/ProviderAgeGroupApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderFilterApi } from '@headway/api/resources/ProviderFilterApi';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { Form, FormStickyFooter } from '@headway/helix/Form';
import { FormControl, validity } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { Switch } from '@headway/helix/Switch';
import { MULTI_STATE_CREDENTIALING_SEARCH } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackPageView } from '@headway/shared/utils/analytics';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { PFECCheckboxTree } from 'components/PFECCheckboxTree';
import {
  getCanUpdateShownInSearch,
  getShownInSearch,
} from 'utils/shownInSearch';

const fiveMinuteIntervals = Array.from(Array(22)).map((_, index) => {
  return { key: index * 5 + 15 };
});

const minimumNoticeWindowHours = Array.from(Array(10)).map((_, index) => {
  if (index === 0) {
    return { key: 0, name: 'None' };
  } else {
    return { key: (index + 1) * 24, name: index + 1 + ' days' };
  }
});

const referralsInfoSchema = Yup.object().shape({
  soonestBookingCutoffHours: Yup.number()
    .nullable()
    .required('Required advanced notice is required.'),
  intakeSessionLength: Yup.number().required(
    'Intake session length is required.'
  ),
});

interface ReferralsProps {
  AuthStore: any;
  isPayerLevelReferralsEnabled: boolean;
  isMscSearchEnabled: boolean;
}

interface ReferralsState {
  isLoaded: boolean;
  ageGroups: AgeGroupRead[];
  providerAgeGroups: ProviderAgeGroupRead[];
  bookableIssuesFilters?: Dictionary<ProviderFilterRead>;
}

interface ReferralsFormValues {
  intakeSessionLength: number;
  shownInSearch: boolean;
  bookableIssuesFilters: Dictionary<ProviderFilterRead>;
  requiresIntakeCall: boolean;
  urgentReferralsEnabled: boolean;
  ageGroups: string[];
  providerFunction: ProviderFunction;
  soonestBookingCutoffHours: number;
  visibleCarriers: string[];
  visiblePFECs: number[];
}

const ReferralsImpl = inject('AuthStore')(
  observer(
    class ReferralsImpl extends React.Component<
      ReferralsProps,
      ReferralsState
    > {
      state: ReferralsState = {
        isLoaded: false,
        ageGroups: [],
        providerAgeGroups: [],
      };

      handleProviderAgeGroupsUpdate = async (
        initialProviderAgeGroups: ProviderAgeGroupRead[],
        ageGroupsSelected: AgeGroupRead[]
      ) => {
        const results = cloneDeep(initialProviderAgeGroups);
        const providerAgeGroupsToRemove: ProviderAgeGroupRead[] = [];
        const existingByAgeGroupId = keyBy(
          initialProviderAgeGroups,
          'ageGroupId'
        );

        // We want to leverage Promise.all() for this to reduce time spent waiting
        // on network calls.
        const ageGroupsCreates = ageGroupsSelected
          .filter((ageGroup) => {
            const exists = existingByAgeGroupId[ageGroup.id];
            return !exists;
          })
          .map((ageGroup) => {
            return ProviderAgeGroupApi.createProviderAgeGroup({
              providerId: this.props.AuthStore.provider.id,
              ageGroupId: ageGroup.id,
            });
          });

        const deletes = initialProviderAgeGroups
          .filter((providerAgeGroup) => {
            const isSelectedAgeGroup = ageGroupsSelected.find(
              (s) => s.id === providerAgeGroup.ageGroupId
            );

            return !isSelectedAgeGroup;
          })
          .map((providerAgeGroup) => {
            providerAgeGroupsToRemove.push(providerAgeGroup);
            return ProviderAgeGroupApi.deleteProviderAgeGroup(
              providerAgeGroup.id
            );
          });

        const [ageGroupResult] = await Promise.all([
          Promise.all(ageGroupsCreates),
          Promise.all(deletes),
        ]);

        ageGroupResult.forEach((providerAgeGroup) =>
          results.push(providerAgeGroup)
        );

        providerAgeGroupsToRemove.forEach((providerAgeGroup) => {
          remove(results, function (currentObject) {
            return currentObject.id === providerAgeGroup.id;
          });
        });

        return results;
      };

      getPrimaryStatePFECs = () => {
        const provider: ProviderRead = this.props.AuthStore.provider;
        return provider.providerFrontEndCarriers?.filter(
          (pfec) =>
            pfec.providerLicenseStateId === provider.providerLicenseState.id
        );
      };

      /**
       * Returns an array of PFEC id for the list of PFECs that are currently shown on their profile
       */
      getSelectedPFECs = (): number[] => {
        const provider: ProviderRead = this.props.AuthStore.provider;
        const pfecs = provider.providerFrontEndCarriers;

        if (!pfecs) {
          return [];
        }

        return pfecs
          .filter((pfec) => !pfec.hiddenFromProfile)
          .map((pfec) => pfec.id);
      };

      /**
       * Bulk update PFEC hiddenFromProfile based on which ones are set as visible
       */
      handleAllStatesProviderFrontEndCarriersUpdate = (
        visiblePFECs: number[]
      ) => {
        const provider: ProviderRead = this.props.AuthStore.provider;

        if (!provider.providerFrontEndCarriers) {
          return;
        }

        return ProviderFrontEndCarrierApi.bulkUpdateProviderFrontEndCarrierVisibility(
          provider.providerFrontEndCarriers.map((carrier) => ({
            id: carrier.id,
            hiddenFromProfile: visiblePFECs.indexOf(carrier.id) === -1,
          }))
        );
      };

      handleProviderFrontEndCarriersUpdate = (selectedCarrierIds: number[]) => {
        const primaryStatePFECs = this.getPrimaryStatePFECs();
        return (
          primaryStatePFECs &&
          ProviderFrontEndCarrierApi.bulkUpdateProviderFrontEndCarrierVisibility(
            primaryStatePFECs.map((carrier) => ({
              id: carrier.id,
              hiddenFromProfile: !selectedCarrierIds.includes(carrier.id),
            }))
          )
        );
      };

      savePreferences = async (
        values: ReferralsFormValues,
        provider: ProviderRead,
        isMscSearchEnabled: boolean
      ) => {
        try {
          const providerAgeGroups = await this.handleProviderAgeGroupsUpdate(
            this.state.providerAgeGroups,
            this.state.ageGroups.filter((item) =>
              values.ageGroups.includes(item.displayName)
            )
          );

          if (isMscSearchEnabled) {
            await this.handleAllStatesProviderFrontEndCarriersUpdate(
              values.visiblePFECs
            );
          } else {
            await this.handleProviderFrontEndCarriersUpdate(
              values.visibleCarriers.map((id) => parseInt(id))
            );
          }

          let minClientAge = null;
          let maxClientAge = null;

          if (providerAgeGroups.length) {
            minClientAge = minBy(
              providerAgeGroups.map((item) => item.ageGroup),
              'minAge'
            )!.minAge;
            maxClientAge = maxBy(
              providerAgeGroups.map((item) => item.ageGroup),
              'maxAge'
            )!.maxAge;
          }

          const providerUpdatePayload: ProviderUpdate = {
            intakeSessionLengthMinutes: values.intakeSessionLength,
            minClientAge: minClientAge,
            maxClientAge: maxClientAge,
            providerFunction: values.providerFunction,
            urgentReferralsEnabled: values.urgentReferralsEnabled,
            soonestBookingCutoffHours: values.soonestBookingCutoffHours,
          };
          const providerLicenseStateUpdatePayload = {
            shownInSearch: values.shownInSearch,
          };

          providerUpdatePayload.requiresIntakeCall = values.requiresIntakeCall;

          let shownInSearchUpdatePromise;
          if (isMscSearchEnabled) {
            shownInSearchUpdatePromise = [
              ProviderApi.updateProviderShownInSearch(provider.id, {
                shownInSearch: values.shownInSearch,
              }),
            ];
          } else {
            shownInSearchUpdatePromise = [
              ProviderLicenseStateApi.updateProviderLicenseState(
                provider.providerLicenseState.id,
                providerLicenseStateUpdatePayload
              ),
            ];
          }

          await Promise.all([
            ...shownInSearchUpdatePromise,
            ...Object.values(values.bookableIssuesFilters)
              .filter(
                ({ id, bookingPreference }) =>
                  this.state.bookableIssuesFilters![id].bookingPreference !==
                  bookingPreference
              )
              .map(({ id, bookingPreference }) =>
                ProviderFilterApi.updateProviderFilter(id, {
                  bookingPreference,
                })
              ),
            ,
          ]);

          const updatedProvider = await ProviderApi.updateProvider(
            provider.id,
            providerUpdatePayload
          );

          this.props.AuthStore.setProvider(updatedProvider);
          this.setState({
            providerAgeGroups,
          });
          notifySuccess('Your settings have been updated.');
        } catch (err: any) {
          notifyError(err.toString());
        }
      };

      componentDidMount = async () => {
        try {
          const ageGroups = await AgeGroupApi.getAgeGroups();
          const providerAgeGroups =
            await ProviderAgeGroupApi.getProviderAgeGroups({
              provider_id: this.props.AuthStore.provider.id,
            });
          const providerFilters = await ProviderFilterApi.getProviderFilters({
            provider_id: this.props.AuthStore.provider.id,
            filter_types: [FilterType.BOOKABLE_ISSUE],
          });

          this.setState({
            ageGroups: sortBy(ageGroups, [(ageGroup) => ageGroup.minAge]),
            providerAgeGroups,
            bookableIssuesFilters: keyBy(
              providerFilters,
              (filter) => filter.id
            ),
            isLoaded: true,
          });
        } catch (err: any) {
          notifyError(err.toString());
        }
      };

      render() {
        const { ageGroups, providerAgeGroups } = this.state;
        const provider: ProviderRead = this.props.AuthStore.provider;
        const isMscSearchEnabled = this.props.isMscSearchEnabled;
        const { providerLicenseState } = provider;

        const shownInSearch = getShownInSearch(
          provider,
          providerLicenseState,
          isMscSearchEnabled
        );

        const canUpdateShownInSearch = getCanUpdateShownInSearch(
          provider,
          providerLicenseState,
          isMscSearchEnabled
        );

        const primaryStatePFECs = this.getPrimaryStatePFECs();
        const visiblePFECs = this.getSelectedPFECs();

        return (
          <Formik<ReferralsFormValues>
            initialValues={{
              intakeSessionLength:
                this.props.AuthStore.provider.intakeSessionLengthMinutes,
              shownInSearch: shownInSearch,
              bookableIssuesFilters: this.state.bookableIssuesFilters || {},
              requiresIntakeCall: provider.requiresIntakeCall ?? false,
              urgentReferralsEnabled: provider.urgentReferralsEnabled!,
              ageGroups: providerAgeGroups.map(
                (item) => item.ageGroup.displayName
              ),
              providerFunction: provider.providerFunction!,
              soonestBookingCutoffHours:
                this.props.AuthStore.provider.soonestBookingCutoffHours || 0,
              visibleCarriers: (primaryStatePFECs || [])
                .filter((carrier) => !carrier.hiddenFromProfile)
                .map((carrier) => String(carrier.id)),
              visiblePFECs: visiblePFECs,
            }}
            validationSchema={referralsInfoSchema}
            enableReinitialize={true}
            onSubmit={(values) =>
              this.savePreferences(values, provider, isMscSearchEnabled)
            }
            render={(formik) => {
              const { values, setFieldValue, dirty, isSubmitting } = formik;
              return (
                <Form>
                  {canUpdateShownInSearch ? (
                    <>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Search visibility</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <span id="show-in-search-subtext">
                              <BodyText>
                                Let us know whether or not you’re currently
                                accepting new clients. You will only show up in
                                search results on headway.co if you indicate you
                                are accepting new clients.
                                <br />
                                <br />
                                If you’re not accepting new clients through
                                search, you can still share your Headway profile
                                URL directly for booking.
                              </BodyText>
                            </span>
                          </PageSectionSubText>
                        </div>
                        <div>
                          <FormControl
                            name="shownInSearch"
                            component={Switch}
                            labelPosition="right"
                            aria-describedby="show-in-search-subtext"
                          >
                            Accept new clients
                          </FormControl>
                        </div>
                      </PageSection>
                      {primaryStatePFECs &&
                        this.props.isPayerLevelReferralsEnabled && (
                          <PageSection layout="grid.two-column">
                            <div>
                              <h2>
                                <SectionHeader>
                                  Insurer preference
                                </SectionHeader>
                              </h2>
                              <PageSectionSubText>
                                <span id="show-in-search-subtext">
                                  <BodyText>
                                    Choose which client referrals to accept
                                    based on insurance plan.
                                    <br />
                                    <br />
                                    <strong>Note:</strong> If you don't intend
                                    to accept a payer again in the future,{' '}
                                    <Link
                                      href={`${process.env.REACT_APP_MAIN_URL}/contact?primaryIssueType=pit_credentialing___payer_networks&secondaryIssueType=carrier_depanel_or_opt_out_request`}
                                      target="_blank"
                                    >
                                      please request to depanel here.
                                    </Link>
                                  </BodyText>
                                </span>
                              </PageSectionSubText>
                            </div>
                            <div>
                              {this.props.isMscSearchEnabled ? (
                                <PFECCheckboxTree
                                  ariaLabel="Payer Search Preference Selection"
                                  provider={provider}
                                  selectedPFECs={values.visiblePFECs}
                                  onSelectedPFECsChange={(selectedPFECs) => {
                                    setFieldValue(
                                      'visiblePFECs',
                                      selectedPFECs
                                    );
                                  }}
                                />
                              ) : (
                                <FormControl
                                  name="visibleCarriers"
                                  component={CheckboxGroup}
                                  aria-labelledby="age-groups-subtext"
                                >
                                  {sortBy(
                                    primaryStatePFECs,
                                    (carrier) => carrier.frontEndCarrier.name
                                  ).map((carrier, index) => {
                                    return (
                                      <Checkbox
                                        key={`key-carrier-${carrier.id}`}
                                        value={String(carrier.id)}
                                      >
                                        {carrier.frontEndCarrier.name}
                                      </Checkbox>
                                    );
                                  })}
                                </FormControl>
                              )}
                            </div>
                          </PageSection>
                        )}
                    </>
                  ) : null}

                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>
                          Client booking preferences
                        </SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <span id="client-booking-subtext">
                          <BodyText>
                            Require new clients to have an introductory
                            consultation call (15 min non-billable conversation)
                            before booking a full session.
                          </BodyText>
                        </span>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <FormControl
                        name="requiresIntakeCall"
                        component={Switch}
                        labelPosition="right"
                        aria-describedby="client-booking-subtext"
                      >
                        Require consultation call
                      </FormControl>
                    </div>
                  </PageSection>
                  <PageSection layout="grid.two-column">
                    <div id="advanced-booking-window">
                      <h2>
                        <SectionHeader>
                          Advanced booking preference
                        </SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <BodyText>
                          Choose how much time you need before new clients can
                          book your open slots.
                        </BodyText>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <Select
                        name="soonestBookingCutoffHours"
                        label={<>Advanced booking window</>}
                        items={minimumNoticeWindowHours}
                        selectionMode="single"
                        selectedKeys={
                          values.soonestBookingCutoffHours != null
                            ? [values.soonestBookingCutoffHours]
                            : []
                        }
                        validation={validity(
                          'soonestBookingCutoffHours',
                          formik
                        )}
                        onSelectionChange={([value]) => {
                          setFieldValue('soonestBookingCutoffHours', value);
                        }}
                      >
                        {(item) => <Item>{item.name}</Item>}
                      </Select>
                    </div>
                  </PageSection>
                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>48 hour referrals</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <span id="urgent-referral-subtext">
                          <BodyText>
                            Let us know if our team can reach out to you with
                            referrals for clients looking for care within 48
                            hours.
                          </BodyText>
                        </span>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <FormControl
                        name="urgentReferralsEnabled"
                        component={Switch}
                        labelPosition="right"
                        aria-describedby="urgent-referral-subtext"
                      >
                        Allow urgent referrals
                      </FormControl>
                    </div>
                  </PageSection>

                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>Intake session length</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <BodyText>
                          Length in minutes of openings for intake sessions on
                          your calendar.
                        </BodyText>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <Select
                        name="intakeSessionLength"
                        label={<>Intake length</>}
                        items={fiveMinuteIntervals}
                        selectionMode="single"
                        selectedKeys={
                          values.intakeSessionLength
                            ? [values.intakeSessionLength]
                            : []
                        }
                        validation={validity('intakeSessionLength', formik)}
                        onSelectionChange={([value]) => {
                          setFieldValue('intakeSessionLength', value);
                        }}
                      >
                        {(item) => <Item>{item.key}</Item>}
                      </Select>
                    </div>
                  </PageSection>

                  {provider.isLicensedToPrescribe ? (
                    <React.Fragment>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Offerings</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <span id="provider-function-subtext">
                              <BodyText>
                                Let us know if you’re currently accepting new
                                clients for medication management, talk therapy,
                                or both.
                              </BodyText>
                            </span>
                          </PageSectionSubText>
                        </div>
                        <div>
                          <FormControl
                            name="providerFunction"
                            component={RadioGroup}
                            aria-labelledby="provider-function-subtext"
                          >
                            <Radio
                              value={ProviderFunction.MEDICATION_MANAGEMENT}
                            >
                              Medication management only
                            </Radio>
                            <Radio value={ProviderFunction.TALK_THERAPY}>
                              Talk therapy only
                            </Radio>
                            <Radio
                              value={
                                ProviderFunction.TALK_THERAPY_AND_MEDICATION_MANAGEMENT
                              }
                            >
                              Both medication management and talk therapy
                            </Radio>
                          </FormControl>
                        </div>
                      </PageSection>
                    </React.Fragment>
                  ) : null}

                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>Client age range</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <span id="age-groups-subtext">
                          <BodyText>
                            Select the age groups that you support in your
                            practice.
                          </BodyText>
                        </span>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <FormControl
                        name="ageGroups"
                        component={CheckboxGroup}
                        aria-labelledby="age-groups-subtext"
                      >
                        {ageGroups.map((ageGroup, index) => {
                          return (
                            <Checkbox
                              key={`key-age-group-${ageGroup.id}`}
                              value={ageGroup.displayName}
                            >{`${ageGroup.displayName} (${ageGroup.minAge}${
                              index === ageGroups.length - 1
                                ? `+`
                                : `-${ageGroup.maxAge}`
                            })`}</Checkbox>
                          );
                        })}
                      </FormControl>
                    </div>
                  </PageSection>

                  <FormStickyFooter>
                    <Button
                      type="submit"
                      disabled={isSubmitting || !dirty}
                      size="large"
                      variant="primary"
                    >
                      Save
                    </Button>
                  </FormStickyFooter>
                </Form>
              );
            }}
          />
        );
      }
    }
  )
);

const Referrals = (
  props: Omit<
    ReferralsProps,
    'isPayerLevelReferralsEnabled' | 'isMscSearchEnabled'
  >
) => {
  const isPayerLevelReferralsEnabled = useFlag('payerLevelReferrals', false);
  const isMscSearchEnabled = useFlag(MULTI_STATE_CREDENTIALING_SEARCH, false);
  const provider = useProvider();
  const canUpdateShownInSearch =
    provider.providerLicenseState.canUpdateShownInSearch;

  useEffect(() => {
    trackPageView({
      name: 'Provider Referral Settings Page Viewed',
      properties: {
        providerId: provider.id,
        isInsurerPreferenceVisible: !!(
          isPayerLevelReferralsEnabled && canUpdateShownInSearch
        ),
      },
    });
  }, [provider.id, isPayerLevelReferralsEnabled, canUpdateShownInSearch]);

  return (
    <ReferralsImpl
      {...props}
      isPayerLevelReferralsEnabled={isPayerLevelReferralsEnabled}
      isMscSearchEnabled={isMscSearchEnabled}
    />
  );
};

export { Referrals };
