import { AriaLabelingProps } from '@react-types/shared';
import { HelpTextProps, Validation } from '@react-types/shared';
import React from 'react';
import { useRef } from 'react';
import {
  AriaButtonProps,
  FocusRing,
  mergeProps,
  useButton,
  useSearchField,
} from 'react-aria';
import { useSearchFieldState } from 'react-stately';
import { SearchFieldProps as StatelySearchFieldProps } from 'react-stately';
import { Simplify } from 'type-fest';

import { IconMagnifyingGlass } from './icons/MagnifyingGlass';
import { IconXCircle } from './icons/XCircle';
import { InteractionProps, useInteraction } from './useInteraction';

export type SearchFieldProps = Simplify<
  Omit<
    StatelySearchFieldProps,
    keyof HelpTextProps | keyof Validation<any> | 'isReadonly' | 'isDisabled'
  > &
    AriaLabelingProps &
    Omit<InteractionProps, 'isTextInput'> & {
      name?: string;
    }
>;
export const SearchField = (props: SearchFieldProps) => {
  const { focusProps, hoverProps, rootProps, ariaProps } =
    useInteraction(props);
  const state = useSearchFieldState({ ...props, ...ariaProps });
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps, clearButtonProps, labelProps } = useSearchField(
    { ...props, ...ariaProps },
    state,
    inputRef
  );

  return (
    <div className="hlx-search-field-root" {...rootProps}>
      {props.label && (
        <label className="hlx-search-field-label" {...labelProps}>
          {props.label}
        </label>
      )}
      <div className="hlx-search-field-control-group">
        <FocusRing
          focusClass="focused"
          focusRingClass="focus-ring"
          isTextInput={true}
          autoFocus={props.autoFocus}
        >
          <input
            className="hlx-search-field-control"
            {...mergeProps(inputProps, focusProps, hoverProps)}
            ref={inputRef}
          />
        </FocusRing>
        <div className="hlx-icon">
          <IconMagnifyingGlass size={16} />
        </div>
        {state.value !== '' && <ClearButton {...clearButtonProps} />}
      </div>
    </div>
  );
};

export const ClearButton = (props: AriaButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <button className="hlx-clear-field-trigger" {...buttonProps} ref={ref}>
      <IconXCircle size={16} />
    </button>
  );
};
