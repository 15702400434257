import type { apiObject, apiOptions } from 'rudder-sdk-js';

type RudderStackSdk = Awaited<typeof import('rudder-sdk-js')>;

export type RudderStack = {
  alias: RudderStackSdk['alias'];
  getAnonymousId: RudderStackSdk['getAnonymousId'];
  group: RudderStackSdk['group'];
  identify: RudderStackSdk['identify'];
  load: RudderStackSdk['load'];
  page: RudderStackSdk['page'];
  ready: RudderStackSdk['ready'];
  reset: RudderStackSdk['reset'];
  setAnonymousId: RudderStackSdk['setAnonymousId'];
  track: RudderStackSdk['track'];
};

// update the window object to include the types for the rudder-sdk
declare global {
  interface Window {
    rudderanalytics?: RudderStack;
  }
}

const getRudderStack = () => {
  if (typeof window !== 'undefined' && !!window.rudderanalytics) {
    return window.rudderanalytics;
  }
};

const identifyUser = (
  id?: string,
  traits?: apiObject,
  options: apiOptions = {},
  onComplete?: () => void
) => {
  const rudderStackSdk = getRudderStack();
  if (!rudderStackSdk) return;

  if (typeof id !== 'undefined') {
    rudderStackSdk.identify(id, traits, options, onComplete);
  } else if (traits) {
    rudderStackSdk.identify(traits, options, onComplete);
  }
};

/**
 * Set a blank user id so logged out users will continue to be identified
 * by their existing RudderStack anonymous id
 * https://www.rudderstack.com/docs/archive/javascript-sdk/1.1/supported-api/#setting-a-blank-user-id
 */
const identifyLoggedOutUser = () => {
  identifyUser('', { isLoggedIn: false });
};

/**
 * Merge different identities of a known user. Rudderstack automatically passes
 * the user's anonymous id as the old id.
 * https://www.rudderstack.com/docs/rudderstack-api/api-specification/rudderstack-spec/alias/
 */
const aliasUser = (
  newId: string,
  oldId?: string,
  options?: apiOptions,
  onComplete?: () => void
) => {
  const rudderStackSdk = getRudderStack();
  if (!rudderStackSdk) return;

  rudderStackSdk.alias(newId, oldId, options, onComplete);
};

const trackEvent = (
  name: string,
  properties?: apiObject,
  options?: apiOptions,
  onComplete?: () => void
) => {
  const rudderStackSdk = getRudderStack();
  if (!rudderStackSdk) return;

  rudderStackSdk.track(name, properties, options, onComplete);
};

const trackPage = (
  name: string,
  properties?: apiObject,
  options?: apiOptions,
  onComplete?: () => void
) => {
  const rudderStackSdk = getRudderStack();
  if (!rudderStackSdk) return;

  rudderStackSdk.page(name, properties, options, onComplete);
};

export {
  aliasUser,
  identifyUser,
  identifyLoggedOutUser,
  trackEvent,
  trackPage,
};
