import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { usePatientAppointmentReadinessCutoffDate } from '@headway/shared/hooks/usePatientAppointmentReadinessCutoffDate';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getExternalPlatformChannelForEventChannel } from '@headway/shared/utils/externalPlatforms';

import { useProviderEvent } from 'hooks/useProviderEvent';

import {
  generatePatientUnreadinessReminderMessagePrefillParameter,
  MessagePrefillTemplateId,
} from '../../../../Messages/utils';

export interface MissingInfoNextStepsProps {
  clientId: number;
  eventId: number | string;
}

export const MissingInfoNextSteps = ({
  clientId,
  eventId,
}: MissingInfoNextStepsProps) => {
  const { data: client } = useUser({
    userId: clientId,
  });
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventId,
  });
  const { cancellationDate, isLoading: isCancellationDateLoading } =
    usePatientAppointmentReadinessCutoffDate(eventId);

  if (!event || !client) {
    return null;
  }

  let formattedAutoCancellationDate;
  if (event && cancellationDate) {
    const cancellationDateObj = new Date(cancellationDate);
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formattedAutoCancellationDate = cancellationDateObj.toLocaleString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: systemTimezone,
      }
    );
  }

  const clientFirstName = client?.firstName;
  const clientPhoneNumber = client?.phone;

  const isExternalPlatformAppointment =
    !!getExternalPlatformChannelForEventChannel(event.channel);
  const prefillTemplateId = isExternalPlatformAppointment
    ? MessagePrefillTemplateId.EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER
    : MessagePrefillTemplateId.PATIENT_UNREADINESS_REMINDER;

  const messagePrefillParameter =
    generatePatientUnreadinessReminderMessagePrefillParameter(
      eventId,
      prefillTemplateId
    );

  return !isCancellationDateLoading ? (
    <>
      <div css={{ marginTop: theme.spacing.x3 }}>
        <b>What can you do in the meantime?</b>
        <ol css={{ paddingLeft: '1rem', marginTop: theme.spacing.x2 }}>
          <li>Resend the Headway invite email.</li>
          {clientPhoneNumber && (
            <li>
              Call or text {clientFirstName} at{' '}
              <strong>{clientPhoneNumber}</strong> and remind them to look out
              for an email from <strong>noreply@e.headway.co</strong>
            </li>
          )}
          <li>
            Send a reminder on the{' '}
            <Link
              to={`/messages?${messagePrefillParameter}&patient=${client?.id}&archived_filter=true`}
              component={RouterLink}
              elementType="a"
              onClick={() => {
                trackEvent({
                  name: 'Appointment Details Modal Messaging Button Clicked',
                  properties: {
                    patientUserId: clientId,
                    providerAppointmentId: event.providerAppointment?.id!,
                    providerId: event.providerId!,
                  },
                });
              }}
            >
              <strong>Messages page</strong>
            </Link>
            .
          </li>
        </ol>
      </div>
      <div>
        <b>What if they don't complete these tasks?</b>
        {formattedAutoCancellationDate ? (
          <div>
            We'll cancel the appointment on{' '}
            <strong>{formattedAutoCancellationDate}</strong> and email them to
            reschedule. They won't be charged any cancellation fees.
          </div>
        ) : (
          <div>
            You can choose to hold this session but you won't be able to confirm
            the details or receive your fee until these tasks are complete.
          </div>
        )}
      </div>
    </>
  ) : null;
};
