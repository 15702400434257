import { AriaLabelingProps, CollectionChildren } from '@react-types/shared';
import React from 'react';

import type { Icon } from './icons/base/types';

export interface BadgeProps extends AriaLabelingProps {
  id?: string;
  children: React.ReactNode;
  variant: 'neutral' | 'info' | 'positive' | 'warning' | 'negative';
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | Icon;
}

function Badge(props: BadgeProps) {
  const Icon = props.icon;
  return (
    <div
      id={props.id}
      className="hlx-badge"
      data-hlx-variant={props.variant}
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      aria-describedby={props['aria-describedby']}
      aria-details={props['aria-details']}
    >
      {Icon && <Icon className="hlx-badge-icon" aria-hidden={true} />}
      <span className="hlx-badge-content">{props.children}</span>
    </div>
  );
}

export { Badge };
