import { Document, Page, PageProps, View } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

import { registerPdfFonts } from '../fonts';
import { combinePdfStyles, pdfStyles } from '../style';
import { PdfLogo } from './PdfLogo';
import { PdfText } from './typography';

export const PdfFooter = ({ children }: PropsWithChildren) => {
  return (
    <View fixed style={{ flexDirection: 'column' }}>
      <View style={{ alignSelf: 'flex-start' }}>{children}</View>
      <View style={{ alignSelf: 'flex-end' }}>
        <PdfText render={({ pageNumber }) => `Page ${pageNumber}`} />
      </View>
    </View>
  );
};

interface PdfPageProps extends PropsWithChildren<PageProps> {
  footerContent: React.ReactNode;
}

export const PdfPage = ({
  children,
  footerContent,
  style = {},
}: PdfPageProps) => (
  <Page style={combinePdfStyles(pdfStyles.page, style)}>
    <View fixed>
      <PdfLogo />
    </View>
    <View style={{ flexGrow: 1, flexDirection: 'column', gap: 16 }}>
      {children}
    </View>
    <PdfFooter>{footerContent}</PdfFooter>
  </Page>
);

export const PdfDocument = ({ children }: PropsWithChildren) => {
  registerPdfFonts();

  return <Document>{children}</Document>;
};
