import { clamp, mergeProps, useResizeObserver } from '@react-aria/utils';
import { CollectionFactory } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';
import {
  AriaLabelingProps,
  Collection,
  CollectionChildren,
  Node,
} from '@react-types/shared';
import clsx from 'clsx';
import React, {
  Key,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AriaTabPanelProps, useTab, useTabList, useTabPanel } from 'react-aria';
import { TabListState, useCollection, useTabListState } from 'react-stately';

import { DATA } from './consts';
import { IconButton } from './IconButton';
import { IconCaretLeft } from './icons/CaretLeft';
import { IconCaretRight } from './icons/CaretRight';
import { useInteraction } from './useInteraction';

export interface TabsContext<T> {
  tabProps: TabsProps<T>;
  tabState: {
    tabListState?: TabListState<T>;
    setTabListState: (state: TabListState<T>) => void;
    selectedTab?: HTMLElement;
  };
  refs: {
    tablistRef: RefObject<HTMLElement>;
    tablistWrapperRef: RefObject<HTMLElement>;
  };
}

export const TabContext = React.createContext<TabsContext<any> | null>(null);

interface TabsProps<T> extends AriaLabelingProps {
  children: ReactNode;
  disabledKeys?: Iterable<Key>;
  items?: Iterable<T>;
  isDisabled?: boolean;
  onSelectionChange?: (key: React.Key) => void;
  selectedKey?: React.Key;
}

export function Tabs<T extends object>(props: TabsProps<T>) {
  let tablistRef = useRef<HTMLElement>(null);
  let tablistWrapperRef = useRef<HTMLElement>(null);
  let [selectedTab, setSelectedTab] = useState<HTMLElement>();
  const [tabListState, setTabListState] = useState<TabListState<T> | undefined>(
    undefined
  );

  useEffect(() => {
    if (tablistRef.current) {
      let selectedTab = tablistRef.current.querySelector(
        `[data-key="${tabListState?.selectedKey}"]`
      );
      if (selectedTab != null) {
        setSelectedTab(selectedTab as HTMLElement);
      }
    }
  }, [props.children, tabListState?.selectedKey, tablistRef]);

  return (
    <TabContext.Provider
      value={{
        tabProps: { ...props },
        tabState: { tabListState, setTabListState, selectedTab },
        refs: { tablistRef, tablistWrapperRef },
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
}

export interface TabListProps<T> {
  children: CollectionChildren<T>;
}

// When tab list is scrollable, this is the width of an icon button + gradient fade.
const SCROLL_BUFFER = 64;

/**
 * A TabList is used within Tabs to group tabs that a user can switch between.
 * The keys of the items within the <TabList> must match up with a corresponding item inside the <TabPanels>.
 */
export function TabList<T extends object>(props: TabListProps<T>) {
  const tabContext = useContext(TabContext) as TabsContext<T>;
  const scrollLeftButtonRef = useRef<HTMLButtonElement>(null);
  const scrollRightButtonRef = useRef<HTMLButtonElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);
  const { refs, tabState, tabProps } = tabContext;
  const { setTabListState } = tabState;
  const { tablistRef, tablistWrapperRef } = refs;
  // Pass original Tab props but override children to create the collection.
  const state = useTabListState({ ...tabProps, children: props.children });

  const { tabListProps } = useTabList(
    { ...tabProps, ...props },
    state,
    tablistRef
  );

  useEffect(() => {
    // Passing back to root as useTabPanel needs the TabListState
    setTabListState(state);
  }, [
    state.disabledKeys,
    state.selectedItem,
    state.selectedKey,
    props.children,
  ]);

  const updateScrollButtons = useCallback(() => {
    const tablist = tablistRef.current;
    const wrapper = tablistWrapperRef.current;
    if (!tablist || !wrapper) {
      return;
    }
    const tabs = wrapper.querySelectorAll('[role="tab"]');
    if (!tabs.length) {
      return;
    }
    const farEdgeLastTab = tabs[tabs.length - 1].getBoundingClientRect().right;
    const farEdgeWrapper = wrapper.getBoundingClientRect().right;
    // Extra +1s here are to give wiggle room for sub-pixel differences in widths.
    const isOverflowing = farEdgeLastTab + 1 > farEdgeWrapper;
    const newCanScrollLeft = isOverflowing && tablist.scrollLeft > 1;
    const newCanScrollRight =
      isOverflowing &&
      tablist.scrollLeft + tablist.clientWidth + 1 < tablist.scrollWidth;

    // Return focus to tablist if a focused button disappears
    setCanScrollLeft(newCanScrollLeft);
    if (
      !newCanScrollLeft &&
      scrollLeftButtonRef.current &&
      scrollLeftButtonRef.current.contains(document.activeElement)
    ) {
      wrapper.focus();
    }

    setCanScrollRight(newCanScrollRight);
    if (
      !newCanScrollRight &&
      scrollRightButtonRef.current &&
      scrollRightButtonRef.current.contains(document.activeElement)
    ) {
      wrapper.focus();
    }
  }, [tablistRef, tablistWrapperRef]);

  useEffect(() => {
    updateScrollButtons();
  }, [state.collection, updateScrollButtons]);

  useResizeObserver({ ref: tablistWrapperRef, onResize: updateScrollButtons });

  const handleScrollLeft = () => {
    const tabs = tablistRef.current;
    if (!tabs) {
      return;
    }
    const scrollIncrement = tabs.clientWidth - SCROLL_BUFFER;
    tabs.scroll({
      left: Math.max(0, tabs.scrollLeft - scrollIncrement),
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    const tabs = tablistRef.current;
    if (!tabs) {
      return;
    }
    const scrollIncrement = tabs.clientWidth - SCROLL_BUFFER;
    tabs.scroll({
      left: Math.min(
        tabs.scrollWidth - tabs.clientWidth,
        tabs.scrollLeft + scrollIncrement
      ),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const selectedTab = tabState.selectedTab;
    const tabs = tablistRef.current;
    if (!selectedTab || !tabs) {
      return;
    }
    const tabLeftEdge = selectedTab.offsetLeft;
    const tabRightEdge = selectedTab.offsetLeft + selectedTab.offsetWidth;
    const scrollportRightEdge = tabs.scrollLeft + tabs.clientWidth;
    // If the tab extends past the left end of the scrollport, try to move it to the right end.
    if (tabs.scrollLeft > tabLeftEdge - SCROLL_BUFFER) {
      tabs.scroll({
        left: Math.max(0, tabRightEdge - tabs.clientWidth + SCROLL_BUFFER),
        behavior: 'smooth',
      });
    }
    // If the tab extends past the right end of the scrollport, try to move it to the left end.
    if (tabRightEdge + SCROLL_BUFFER > scrollportRightEdge) {
      tabs.scroll({
        left: Math.min(
          tabs.scrollWidth - tabs.clientWidth,
          tabLeftEdge - SCROLL_BUFFER
        ),
        behavior: 'smooth',
      });
    }
  }, [tabState.selectedTab]);

  return (
    <div className="hlx-tab-list-wrapper" ref={tablistWrapperRef as any}>
      {canScrollLeft && (
        <div className="hlx-tab-left-button-wrapper">
          <IconButton
            variant="transparent"
            onPress={handleScrollLeft}
            excludeFromTabOrder
            aria-hidden
            ref={scrollLeftButtonRef}
          >
            <IconCaretLeft size={16} />
          </IconButton>
        </div>
      )}
      <div
        {...tabListProps}
        ref={tablistRef as any}
        className="hlx-tab-list"
        onScroll={updateScrollButtons}
      >
        {[...state.collection].map((item) => (
          <Tab key={item.key} item={item} state={state} />
        ))}
      </div>
      {canScrollRight && (
        <div className="hlx-tab-right-button-wrapper">
          <IconButton
            variant="transparent"
            onPress={handleScrollRight}
            excludeFromTabOrder
            aria-hidden
            ref={scrollRightButtonRef}
          >
            <IconCaretRight size={16} />
          </IconButton>
        </div>
      )}
    </div>
  );
}

interface TabProps<T> {
  item: Node<T>;
  state: TabListState<T>;
}

function Tab<T>(props: TabProps<T>) {
  let { item, state } = props;
  let { key, rendered } = item;

  let ref = React.useRef<HTMLDivElement>(null);

  let { tabProps, isSelected, isDisabled, isPressed } = useTab(
    { key },
    state,
    ref
  );
  const { hoverProps, focusProps, isHovered, isFocusVisible } = useInteraction({
    disabled: isDisabled,
  });

  return (
    <div
      {...mergeProps(tabProps, focusProps, hoverProps, {
        [DATA.HOVERED]: isHovered,
        'data-hlx-selected': isSelected,
        'data-hlx-pressed': isPressed,
        [DATA.DISABLED]: isDisabled,
      })}
      ref={ref}
      className={clsx('hlx-tab', {
        'focus-ring': isFocusVisible,
      })}
    >
      {rendered}
    </div>
  );
}

export interface TabPanelsProps<T> extends AriaTabPanelProps {
  children: CollectionChildren<T>;
}

/**
 * TabPanels is used within Tabs as a container for the content of each tab.
 * The keys of the items within the <TabPanels> must match up with a corresponding item inside the <TabList>.
 */
export function TabPanels<T extends object>(props: TabPanelsProps<T>) {
  const { tabState, tabProps } = useContext(TabContext) as TabsContext<T>;
  const { tabListState } = tabState;

  const factory: CollectionFactory<T, Collection<Node<T>>> = (nodes) =>
    new ListCollection(nodes);
  const collection = useCollection(
    { items: tabProps.items, ...props },
    factory
  );

  const selectedItem = tabListState
    ? collection.getItem(tabListState.selectedKey)
    : null;

  return (
    <TabPanel {...props} key={tabListState?.selectedKey}>
      {selectedItem && selectedItem.props.children}
    </TabPanel>
  );
}

function TabPanel<T>(props: TabPanelsProps<T>) {
  const { tabState } = useContext(TabContext) as TabsContext<T>;
  const { tabListState } = tabState;
  let ref = useRef<HTMLDivElement>(null);
  const { tabPanelProps } = useTabPanel(
    props,
    tabListState as TabListState<T>,
    ref
  );

  const { hoverProps, focusProps, isFocusVisible } = useInteraction({
    disabled: false,
  });

  return (
    <div
      {...mergeProps(tabPanelProps, focusProps, hoverProps)}
      ref={ref}
      className={clsx('hlx-tab-panel', {
        'focus-ring': isFocusVisible,
      })}
    >
      {/* @ts-expect-error */}
      {props.children}
    </div>
  );
}
