import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderTaskStatus } from '@headway/api/models/ProviderTaskStatus';
import { Badge } from '@headway/helix/Badge';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import {
  Navigation as HelixNavigation,
  NavigationLink as HelixNavigationLink,
  NavigationDivider,
  NavigationIconAcademy,
  NavigationIconCalendar,
  NavigationIconCEU,
  NavigationIconClients,
  NavigationIconCredentialForm,
  NavigationIconDashboard,
  NavigationIconHelpCenter,
  NavigationIconInsuranceStatus,
  NavigationIconMessages,
  NavigationIconPayments,
  NavigationIconPerson,
  NavigationIconReferral,
  NavigationIconSettings,
} from '@headway/helix/Navigation';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { usePayerQuestionnaires } from '@headway/shared/hooks/usePayerQuestionnaires';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getTaskStatusMap } from '@headway/shared/utils/insuranceStatus';
import { getIsProviderCredentialedWithFrontEndCarrier } from '@headway/shared/utils/postIntakeInfoCollection';
import { useReferralProgramIncentiveAmount } from '@headway/ui/hooks/extole';
import { ProviderTaskContext } from '@headway/ui/providers/ProviderTaskProvider';
import { SolvvyController } from '@headway/ui/Solvvy';
import { theme as legacyTheme } from '@headway/ui/theme';

import { useAuthStore } from 'stores/AuthStore';
import {
  hasCustomBilling,
  hasRateAccess,
  isOffshoreAgentImpersonatingProviderUser,
} from 'utils/access';

import { ProviderMessagesBadgeDot } from './ProviderMessagesBadgeDot';

interface NavigationLinkProps {
  icon: React.ComponentType;
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  'data-testid'?: string;
  'aria-label'?: string;
  disabled?: boolean;
}

export const NavigationLink = ({
  icon,
  to,
  children,
  onClick,
  ...props
}: NavigationLinkProps) => {
  const location = useLocation();
  const isCurrent = location.pathname.indexOf(to) === 0;
  return (
    <HelixNavigationLink
      to={to}
      elementType="a"
      component={Link}
      icon={icon}
      aria-current={isCurrent ? 'page' : false}
      onClick={onClick}
      {...props}
    >
      {children}
    </HelixNavigationLink>
  );
};

interface NavigationProps {
  showCredForm: boolean;
  showPayerQuestionnaire: boolean;
  solvvyController?: SolvvyController;
  handleDrawerToggle: () => void;
  fullWidth?: boolean;
}

export const Navigation = ({
  showCredForm,
  showPayerQuestionnaire,
  solvvyController,
  handleDrawerToggle,
  fullWidth,
}: NavigationProps) => {
  const AuthStore = useAuthStore();
  const providerTaskContext = useContext(ProviderTaskContext);
  const actionRequiredInsuranceTasksExist = providerTaskContext.isLoading
    ? false
    : !!getTaskStatusMap(providerTaskContext.providerTasks)[
        ProviderTaskStatus.ACTION_NEEDED
      ];
  const { data: incompletePayerQuestionnaires } = usePayerQuestionnaires({
    provider: AuthStore.provider,
    incompleteOnly: true,
  });
  const { carriersById } = useFrontEndCarriers();

  const showOffshoreBrokenLinks = !isOffshoreAgentImpersonatingProviderUser(
    AuthStore.impersonatingUserRoles
  );

  const isUnderMedium = useMediaQuery(
    `(max-width: ${legacyTheme.breakpoints.medium})px`
  );

  return (
    <HelixNavigation layout={fullWidth ? 'full-width' : 'default'}>
      <NavigationLink
        to="/home"
        icon={NavigationIconDashboard}
        disabled={!showOffshoreBrokenLinks}
      >
        Home
      </NavigationLink>
      <NavigationLink
        to="/calendar"
        icon={NavigationIconCalendar}
        data-testid="calendar-link"
        disabled={!showOffshoreBrokenLinks}
      >
        Calendar
      </NavigationLink>
      <NavigationLink
        to="/clients"
        icon={NavigationIconClients}
        data-testid="clients-link"
        disabled={!showOffshoreBrokenLinks}
      >
        Clients
      </NavigationLink>
      {hasRateAccess(AuthStore.provider, AuthStore.user) && (
        <NavigationLink
          to="/payments"
          icon={NavigationIconPayments}
          disabled={!showOffshoreBrokenLinks}
        >
          Payments
        </NavigationLink>
      )}
      {!hasCustomBilling(AuthStore.provider) && (
        <NavigationLink
          to="/messages"
          icon={NavigationIconMessages}
          data-testid="messagesSidebar"
          disabled={!showOffshoreBrokenLinks}
        >
          Messages
          <ProviderMessagesBadgeDot />
        </NavigationLink>
      )}
      <NavigationLink
        to="/insurance-status"
        icon={NavigationIconInsuranceStatus}
        data-testid="insuranceStatusSidebar"
      >
        <span css={navigationCss.insuranceStatus}>
          Insurance status
          {actionRequiredInsuranceTasksExist && <IconWarningCircle />}
        </span>
      </NavigationLink>
      <NavigationLink to="/settings" icon={NavigationIconSettings}>
        Settings
      </NavigationLink>
      <NavigationDivider />
      {showCredForm && (
        <NavigationLink
          to="/credentials"
          icon={NavigationIconCredentialForm}
          onClick={() => {
            trackEvent({
              name: 'Credentialed Navigation Bar Button Clicked',
              properties: {
                providerId: AuthStore.provider.id,
              },
            });
          }}
        >
          Credential form
        </NavigationLink>
      )}
      {showPayerQuestionnaire &&
        !!incompletePayerQuestionnaires?.length &&
        incompletePayerQuestionnaires.map(
          (incompletePayerQuestionnaire: PayerQuestionnaire) => {
            const isProviderCredentialed =
              getIsProviderCredentialedWithFrontEndCarrier(
                AuthStore.provider,
                incompletePayerQuestionnaire.template.frontEndCarrierId
              );
            const carrierName =
              carriersById &&
              carriersById[
                incompletePayerQuestionnaire?.template?.frontEndCarrierId
              ]?.name;

            if (!carrierName) return null;

            return (
              <NavigationLink
                to={`/payer-questionnaire/${incompletePayerQuestionnaire.response.id}`}
                icon={NavigationIconCredentialForm}
              >
                {isProviderCredentialed
                  ? `Action required: ${carrierName}`
                  : `${carrierName} credential application`}
              </NavigationLink>
            );
          }
        )}
      {solvvyController && isUnderMedium && (
        <HelixNavigationLink
          icon={NavigationIconPerson}
          component={(props: any) => (
            <button css={navigationCss.solvvy} {...props} />
          )}
          onPress={() => {
            solvvyController.open();
            handleDrawerToggle();
          }}
        >
          Get Help
        </HelixNavigationLink>
      )}
      <HelixNavigationLink
        href="https://findheadway.zendesk.com/hc"
        icon={NavigationIconHelpCenter}
        target="_blank"
      >
        Help center
      </HelixNavigationLink>
      <HelixNavigationLink
        href="https://headway-university.northpass.com/app"
        icon={NavigationIconAcademy}
        target="_blank"
        onClick={() => {
          trackEvent({
            name: 'Headway Academy Navigation Bar Button Clicked',
            properties: {
              providerId: AuthStore.provider.id,
            },
          });
        }}
      >
        Headway Academy
      </HelixNavigationLink>
      <HelixNavigationLink
        href="https://headway-university.northpass.com/app/continuing-education"
        icon={NavigationIconCEU}
        target="_blank"
        onClick={() => {
          trackEvent({
            name: 'CEUs Navigation Bar Button Clicked',
            properties: {
              providerId: AuthStore.provider.id,
            },
          });
        }}
      >
        CE/CME
        <Badge variant="neutral">Free</Badge>
      </HelixNavigationLink>
      <NavigationLink
        to="/refer-a-provider"
        icon={NavigationIconReferral}
        aria-label="Refer a provider"
        onClick={() => {
          trackEvent({
            name: 'Refer a Provider Navigation Bar Button Clicked',
            properties: {
              providerId: AuthStore.provider.id,
            },
          });
        }}
      >
        <span css={navigationCss.referProvider}>Refer a provider</span>
        <Badge
          variant="neutral"
          aria-label={`Earn $${useReferralProgramIncentiveAmount()} by referring a provider`}
        >
          ${useReferralProgramIncentiveAmount()}
        </Badge>
      </NavigationLink>
    </HelixNavigation>
  );
};

const navigationCss = {
  solvvy: css({
    cursor: 'pointer',
    width: '100%',
    border: 'none',
  }),
  referProvider: css({
    whiteSpace: 'nowrap',
  }),
  insuranceStatus: css({
    display: 'flex',
    gap: theme.spacing.x3,
    svg: {
      color: theme.color.primary.red,
    },
  }),
};
