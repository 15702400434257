import { NumberCircleSix } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconNumberCircleSix: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={NumberCircleSix}
    defaultVariant="filled"
    ref={ref}
  />
));

IconNumberCircleSix.variants = ['filled'];
IconNumberCircleSix.defaultVariant = 'filled';
