import { Heart } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconHeart: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    variants={['outlined', 'filled']}
    defaultVariant="outlined"
    phosphorIcon={Heart}
    ref={ref}
  />
));

IconHeart.variants = ['outlined', 'filled'];
IconHeart.defaultVariant = 'outlined';
