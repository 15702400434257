import { keyframes } from '@emotion/react';
import React, { useMemo } from 'react';

import { ContactInfo } from '@headway/api/models/ContactInfo';
import { DataWarningType } from '@headway/api/models/DataWarningType';
import { SourceAttribution } from '@headway/api/models/SourceAttribution';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconSpinnerGap } from '@headway/helix/icons/SpinnerGap';
import { Link } from '@headway/helix/Link';
import { ListHeader } from '@headway/helix/ListHeader';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { logException } from '@headway/shared/utils/sentry';
import { notifyWarning } from '@headway/ui/utils/notify';

import { useAuthStore } from 'stores/AuthStore';

import { ContactInformationApi } from './resources/ContactInformationApi';
import { ImportStep } from './steps/ImportStep';
import { NotifyStep } from './steps/NotifyStep';
import { VerifyStep } from './steps/VerifyStep';

const totalSteps = 3;
const analyticsEventName = 'Add Patient Step Viewed';
const DEFAULT_NEXT_TEXT = 'Next';

export const formatDisplayName = (client: ContactInfo) => {
  // If have both preferred names set, return those
  if (client.displayFirstName && client.displayLastName) {
    return `${client.displayFirstName} ${client.displayLastName}`;
  }
  // If just first or last preferred name, use legal name for missing name
  if (client.displayFirstName) {
    return `${client.displayFirstName} ${client.lastName}`;
  }
  if (client.displayLastName) {
    return `${client.firstName} ${client.displayLastName}`;
  }
  // If no preferred name, return null
  return null;
};

export const BulkPatientPortingWizard = ({
  onClose,
  onClientsAdded,
  onClientsNotified,
}: {
  onClose: () => void;
  onClientsAdded: () => void;
  onClientsNotified: (notifiedClients: UserRead[]) => void;
}) => {
  const auth = useAuthStore();

  const [currentStep, setCurrentStep] = React.useState(0);

  const [userFiles, setUserFiles] = React.useState<File[]>([]);
  const [clients, setClients] = React.useState<ContactInfo[]>([]);
  const [clientsToNotify, setClientsToNotify] = React.useState<ContactInfo[]>(
    []
  );
  const [savedUsers, setSavedUsers] = React.useState<UserRead[]>([]);

  const [isAddUserSaving, setIsAddUserSaving] = React.useState(false);

  const [selectedProviderPatientIds, setSelectedProviderPatientIds] =
    React.useState<readonly number[]>([]);

  const handleBack = () => setCurrentStep(currentStep - 1);
  const handleNext = () => {
    if (currentStep + 1 === totalSteps) {
      onClose();
      return;
    } else {
      trackAnalyticsEvent('continued', true);
    }

    setCurrentStep(currentStep + 1);
  };

  const FILE_TYPE_ERROR_MESSAGE =
    'Please upload your contacts as a .vcf, .csv, or .xlsx file.';

  const onUserFileDrop = (data: any[]) => {
    if (data.length === 0) {
      notifyWarning(FILE_TYPE_ERROR_MESSAGE);
      return;
    }
    setUserFiles([...userFiles, ...data]);
    trackEvent({
      name: analyticsEventName,
      properties: {
        screenName: `Add Patient: Step ${currentStep + 1}`,
        stepName: 'file_uploaded',
      },
    });
  };

  const onImportStep = async () => {
    if (userFiles.length === 0) {
      notifyWarning(FILE_TYPE_ERROR_MESSAGE);
      return;
    }

    try {
      const parsedFileData = await ContactInformationApi.parseFile(
        userFiles,
        auth.provider.id
      );
      setClients(parsedFileData);
      handleNext();
    } catch (error: AnyTS4TryCatchUnknownError) {
      if (error.response) {
        notifyWarning(error.response.data.detail);
      } else {
        notifyWarning('Please check the format of your contact files.');
      }
    }
  };

  const saveUsersStep = async () => {
    if (savedUsers.length > 0) {
      return;
    }

    setIsAddUserSaving(true);

    const addPatient = async (userId: number) => {
      const providerLicenseStateId = auth.provider
        ? auth.provider.providerLicenseState.id
        : undefined;

      const providerPatientPayload = {
        providerId: auth.provider.id,
        userId: userId,
        providerLicenseStateId,
        sourceAttribution: SourceAttribution.BULK_IMPORT,
      };

      try {
        const providerPatientRecord =
          await ProviderPatientApi.createProviderPatient(
            providerPatientPayload
          );
        return providerPatientRecord.id;
      } catch (e) {
        console.error(e);
        return -1;
      }
    };

    const addUser = async (user: ContactInfo) => {
      try {
        return await UserApi.createUser({
          firstName: user.firstName,
          lastName: user.lastName,
          displayFirstName: user.displayFirstName,
          displayLastName: user.displayLastName,
          email: user.email,
          ...(!user.dataWarningTypes?.includes(
            DataWarningType.INVALID_PHONE
          ) && { phone: user.phone }),
          inviteChannel: UserInviteChannel.PROVIDER_PORTAL,
        });
      } catch (e) {
        console.error(e);
        return undefined;
      }
    };

    const getUser = async (userId: number) => {
      try {
        return await UserApi.getUser(userId);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    };

    let contactInfoList: ContactInfo[] = [];
    let providerPatientIds: number[] = [];
    let savedUserList: UserRead[] = [];
    for (let client of clients) {
      // Don't save clients with a data warning type (except for duplicate emails)
      if (
        !(
          client.dataWarningTypes?.includes(DataWarningType.INVALID_EMAIL) ||
          client.dataWarningTypes?.includes(DataWarningType.VERIFIED_CLIENT)
        )
      ) {
        if (client.userId) {
          // Existing user already associated with this provider
          if (client.providerPatientId) {
            contactInfoList.push(client);
            providerPatientIds.push(client.providerPatientId);
          } else {
            // Existing user not associated with provider
            const providerPatientId = await addPatient(client.userId);
            if (providerPatientId !== -1) {
              client.providerPatientId = providerPatientId;
              contactInfoList.push(client);
              providerPatientIds.push(providerPatientId);
              const existingUser = await getUser(client.userId);
              if (existingUser) {
                savedUserList.push(existingUser);
              }
            }
          }
        } else {
          const newUser = await addUser(client);
          if (newUser) {
            savedUserList.push(newUser);
            const providerPatientId = await addPatient(newUser.id);
            client.userId = newUser.id;
            if (providerPatientId !== -1) {
              client.providerPatientId = providerPatientId;
              contactInfoList.push(client);
              providerPatientIds.push(providerPatientId);
            }
          }
        }
      }
    }
    setSavedUsers(savedUserList);
    setClientsToNotify(contactInfoList);
    setSelectedProviderPatientIds(providerPatientIds);
    setIsAddUserSaving(false);
    trackEvent({
      name: analyticsEventName,
      properties: {
        screenName: `Add Patient: Step ${currentStep + 1}`,
        stepName: 'continued',
        ctaButtonCopy: verifyNextText,
        numPatientsSaved: savedUserList.length,
        numInvalidRows: clients.length - contactInfoList.length,
        patientsSaved: savedUserList.map((user) => user.id),
      },
    });
  };

  const notifyUsersStep = async () => {
    let notifiedUsers: UserRead[] = [];
    const sendAccountInviteEmail = (clientToNotify: ContactInfo) => {
      return () =>
        new Promise<void>(async (resolve, reject) => {
          try {
            if (clientToNotify.providerPatientId && clientToNotify.userId) {
              await ProviderPatientApi.sendProviderPatientAccountInvite(
                clientToNotify.providerPatientId
              );
              notifiedUsers.push(
                await UserApi.updateUser(clientToNotify.userId, {
                  isInvited: true,
                })
              );
              resolve();
            }
            reject();
          } catch (e) {
            reject(e);
          }
        });
    };

    const mutations: (() => Promise<void>)[] = [
      ...clientsToNotify
        .filter(
          (client) =>
            client.providerPatientId &&
            selectedProviderPatientIds.includes(client.providerPatientId)
        )
        .map((client) => sendAccountInviteEmail(client)),
    ];

    const closePendingToast = toasts.add('Sending invites...', {
      timeout: Infinity,
    });
    const failures = [];
    for (let mutation of mutations) {
      try {
        await mutation();
      } catch (e) {
        failures.push(e);
      }
    }
    closePendingToast();
    if (failures.length > 0) {
      toasts.add(
        `Error notifying ${failures.length} out of ${mutations.length} clients.`,
        {
          variant: 'negative',
        }
      );
    } else if (mutations.length > 0) {
      toasts.add(
        `${mutations.length} ${
          mutations.length > 1 ? 'clients have' : 'client has'
        } been added and invited to setup their accounts.`,
        {
          variant: 'positive',
        }
      );
    }

    onClientsNotified(notifiedUsers);

    const nameMatchList = notifiedUsers.map((user) => {
      return (
        (user.displayFirstName === '' && user.displayLastName === '') ||
        (user.displayFirstName === user.firstName &&
          user.displayLastName === user.lastName)
      );
    });
    trackEvent({
      name: `Add Patient Completed`,
      properties: {
        numPatientsInvited: mutations.length - failures.length,
        patientsInvited: notifiedUsers.map((user) => user.id),
        nameMatch: nameMatchList,
      },
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedProviderPatientIds.length > 0) {
        setSelectedProviderPatientIds([]);
      } else {
        let newSelecteds: number[] = [];
        clientsToNotify.forEach((client) => {
          if (client.providerPatientId) {
            newSelecteds.push(client.providerPatientId);
          }
        });
        setSelectedProviderPatientIds(newSelecteds);
      }
      return;
    }
    setSelectedProviderPatientIds([]);
  };

  const handleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    providerPatientId: number | undefined
  ) => {
    if (providerPatientId === undefined) {
      return;
    }

    const selectedClientsIndex =
      selectedProviderPatientIds.indexOf(providerPatientId);
    let newSelected: readonly number[] = [];

    if (selectedClientsIndex === -1) {
      newSelected = newSelected.concat(
        selectedProviderPatientIds,
        providerPatientId
      );
    } else if (selectedClientsIndex === 0) {
      newSelected = newSelected.concat(selectedProviderPatientIds.slice(1));
    } else if (selectedClientsIndex === selectedProviderPatientIds.length - 1) {
      newSelected = newSelected.concat(selectedProviderPatientIds.slice(0, -1));
    } else if (selectedClientsIndex > 0) {
      newSelected = newSelected.concat(
        selectedProviderPatientIds.slice(0, selectedClientsIndex),
        selectedProviderPatientIds.slice(selectedClientsIndex + 1)
      );
    }

    setSelectedProviderPatientIds(newSelected);
  };

  const isSelected = (providerPatientId: number | undefined) =>
    !providerPatientId ||
    selectedProviderPatientIds.indexOf(providerPatientId) !== -1;

  const hasDataWarnings =
    clients.find(
      (client) => client.dataWarningTypes && client.dataWarningTypes.length > 0
    ) != null;

  const showUserDisplayName: boolean = useMemo(() => {
    return clients.some((client) => formatDisplayName(client));
  }, [clients]);

  const verifyNextText =
    savedUsers.length === 0 ? 'Save Clients' : DEFAULT_NEXT_TEXT;
  const notifyNextText =
    selectedProviderPatientIds.length > 0
      ? 'Notify Selected Now'
      : 'Close without notifying';

  const trackAnalyticsEvent = (stepName: string, isProgressing: boolean) => {
    const trackedStep = currentStep + 1;
    if (trackedStep === 1) {
      trackEvent({
        name: analyticsEventName,
        properties: {
          screenName: `Add Patient: Step ${trackedStep}`,
          stepName: stepName,
          ...(isProgressing && { ctaButtonCopy: DEFAULT_NEXT_TEXT }),
        },
      });
    } else if (trackedStep === 2 && verifyNextText === DEFAULT_NEXT_TEXT) {
      trackEvent({
        name: analyticsEventName,
        properties: {
          screenName: `Add Patient: Step ${trackedStep}`,
          stepName: stepName,
          ...(isProgressing && { ctaButtonCopy: verifyNextText }),
        },
      });
    } else if (trackedStep === 3 && !isProgressing) {
      trackEvent({
        name: analyticsEventName,
        properties: {
          screenName: `Add Patient: Step ${trackedStep}`,
          stepName: stepName,
        },
      });
    }
  };

  return (
    <Modal
      title={
        currentStep === 3
          ? 'Preview of Headway setup invitation'
          : 'Add multiple clients'
      }
      aria-labelledby="add-multiple-clients-step-title"
      isOpen={true}
      onDismiss={() => {
        if (savedUsers.length > 0) {
          onClientsAdded();
        }
        onClose();
        trackAnalyticsEvent('exited', false);
      }}
    >
      {currentStep === 0 && (
        <>
          <ModalContent>
            <h2 id="add-multiple-clients-step-title">
              <ListHeader>
                Step 1 of 3: Import your client’s details.
              </ListHeader>
            </h2>
            <ImportStep userFiles={userFiles} onFileDrop={onUserFileDrop} />
          </ModalContent>
          <ModalFooter>
            <Button
              variant="primary"
              onPress={async () => {
                await onImportStep();
              }}
            >
              Next
            </Button>
          </ModalFooter>
        </>
      )}

      {currentStep === 1 && (
        <>
          <ModalContent>
            <h2 id="add-multiple-clients-step-title">
              <ListHeader>Step 2 of 3: Verify your client’s details</ListHeader>
            </h2>
            <VerifyStep
              clients={clients}
              hasDataWarnings={hasDataWarnings}
              showUserDisplayName={showUserDisplayName}
            />
          </ModalContent>
          <ModalFooter>
            <Button
              variant="secondary"
              onPress={() => {
                handleBack();
              }}
            >
              Go back
            </Button>
            <Button
              variant="primary"
              onPress={async () => {
                await saveUsersStep();
                handleNext();
              }}
              disabled={isAddUserSaving}
            >
              {verifyNextText}
            </Button>
          </ModalFooter>
        </>
      )}

      {currentStep === 2 && (
        <>
          <ModalContent>
            <h2 id="add-multiple-clients-step-title">
              <ListHeader>
                Step 3 of 3: Invite your clients to Headway
              </ListHeader>
            </h2>
            <NotifyStep
              clients={clientsToNotify}
              uploadedClientCount={clients.length}
              selectedProviderPatientIds={selectedProviderPatientIds}
              isSelected={isSelected}
              onSelectClick={handleCheckboxClick}
              onSelectAllClick={handleSelectAllClick}
              onPreviewEmail={() => {
                trackEvent({
                  name: 'Bulk New Client Invite Email Preview Button Clicked',
                });
                setCurrentStep(3);
              }}
              showUserDisplayName={showUserDisplayName}
            />
          </ModalContent>
          <ModalFooter>
            <Button
              variant="secondary"
              onPress={() => {
                handleBack();
              }}
            >
              Go back
            </Button>
            <Button
              variant="primary"
              onPress={() => {
                notifyUsersStep();
                onClientsAdded();
                handleNext();
              }}
              disabled={isAddUserSaving}
            >
              {notifyNextText}
            </Button>
          </ModalFooter>
        </>
      )}
      {currentStep === 3 && (
        <>
          <ModalContent>
            <EmailPreviewStep patient={clientsToNotify[0]} />
          </ModalContent>
          <ModalFooter>
            <Button
              variant="primary"
              onPress={async () => {
                setCurrentStep(2);
              }}
            >
              Back
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

interface EmailPreviewStepProps {
  patient: ContactInfo;
}
function EmailPreviewStep(props: EmailPreviewStepProps) {
  const emailPreviewQuery = useQuery({
    queryKey: [
      'provider-patient-account-invite-email-preview',
      props.patient.providerPatientId,
    ],
    queryFn: async () => {
      return ProviderPatientApi.getPatientInvitationEmailPreview(
        props.patient.providerPatientId!
      );
    },
    enabled: !!props.patient.providerPatientId,
    onError: (e) => {
      logException(e);
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <div>
      <div>
        <BodyText>
          <strong>
            Below is a preview of the setup invitation for{' '}
            {props.patient.firstName} to set up their Headway account to
            complete required tasks:
          </strong>
        </BodyText>
      </div>
      <div>
        <ul
          css={{
            paddingLeft: theme.spacing.x6,
          }}
        >
          <li>
            Agree to our required{' '}
            <Link
              href={`${process.env.REACT_APP_MAIN_URL}/legal/privacy-practices`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href={`${process.env.REACT_APP_MAIN_URL}/legal/responsibility`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Consent Forms
            </Link>
          </li>
          <li>Add billing and insurance information</li>
        </ul>
      </div>
      {!emailPreviewQuery.isError && (
        <section
          css={{
            backgroundColor: theme.color.system.backgroundGray,
            padding: theme.spacing.x6,
            ...theme.reset,
          }}
        >
          <h3>
            <BodyText>
              <strong>Preview of invitation:</strong>
            </BodyText>
          </h3>
          <div>
            <BodyText>
              If your client doesn’t receive the email, please ask them to check
              their spam or promotions folder:
            </BodyText>
          </div>
          {emailPreviewQuery.isSuccess ? (
            <div
              css={{ marginTop: theme.spacing.x3 }}
              dangerouslySetInnerHTML={{
                __html: sanitize(emailPreviewQuery.data?.html || ''),
              }}
            />
          ) : (
            <div
              css={{
                display: 'grid',
                placeItems: 'center',
                color: theme.color.system.textBlack,
                padding: theme.spacing.x6,
                '& > *': {
                  animation: `${rotate} 2s infinite linear`,
                },
              }}
            >
              <IconSpinnerGap />
            </div>
          )}
        </section>
      )}
    </div>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
