import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderConfirmableSessionsQueryKeyArgs {
  providerId: number;
}

export const { useSingleQuery: useProviderConfirmableSessions } =
  createBasicApiHooks(
    ({
      providerId,
    }: UseProviderConfirmableSessionsQueryKeyArgs): [string, number] => [
      'getConfirmableSessions',
      providerId,
    ],
    ({ providerId }) =>
      ProviderEventApi.getProviderConfirmableSessions(providerId),
    ({ providerId }) => !!providerId,
    () => 'Failed to fetch confirmable sessions'
  );
