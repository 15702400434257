import { FormHelperText, MenuItem } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { LookupSource } from '@headway/api/models/LookupSource';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { Button } from '@headway/helix/Button';
import { CARRIERS_REQUIRING_AUTHORIZATION } from '@headway/shared/constants/carrierIds';
import { ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useSearchableFrontEndCarriersQuery } from '@headway/shared/hooks/useSearchableFrontEndCarriersQuery';
import {
  convertCarrierIdToPatientSearchableCarrierId,
  convertProviderCarriersToSearchableCarriers,
} from '@headway/shared/utils/carriers';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { FormRow } from '@headway/ui/form';
import {
  FieldControl,
  FieldDatePicker,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
  FieldMemberIdWithValidation,
  FieldSelect,
} from '@headway/ui/form';
import { FieldMemberIdAdvancedValidation } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import {
  ProviderFrontEndCarrierContext,
  ProviderFrontEndCarrierContextState,
} from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { theme } from '@headway/ui/theme';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { InsuranceNeedsAuthzGuidanceCard } from 'views/Patients/InsuranceNeedsAuthzGuidanceCard';

import { useProvider } from '../../hooks';

export type InsuranceFormUpdate = {
  userRead: UserRead;
  userInsurance: UserInsuranceRead | undefined;
  eligibilityLookup?: EligibilityLookupRead;
};

export interface PatientInsuranceFormProps {
  onUpdateSuccess: (insuranceFormUpdate: InsuranceFormUpdate) => void;
  showAuthorizationInstructionsModal: (
    carrier: FrontEndCarrierRead | FrontEndCarrierNested
  ) => void;
  onCancel: () => void;
  patient: UserRead;
  providerFrontEndCarrierContext: ProviderFrontEndCarrierContextState;
  currentEligibility?: EligibilityLookupRead;
}

export const insuranceFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dob: Yup.string().required('Date of birth is required'),
  carrierId: Yup.number().required('Insurance carrier is required'),
  memberId: Yup.string().required('Member ID is required'),
  groupNumber: Yup.string(),
});

export interface InsuranceFormValues {
  firstName: string;
  lastName: string;
  dob: string;
  carrierId: number | string;
  memberId: string;
  groupNumber: string;
}

export const insuranceFormInitialValues = (
  patient: UserRead,
  anthemPatientFacingCarrierConsolidationEnabled: boolean
) => ({
  firstName: patient.activeUserInsurance?.firstName || patient.firstName || '',
  lastName: patient.activeUserInsurance?.lastName || patient.lastName || '',
  dob: patient.activeUserInsurance?.dob ?? '',
  carrierId: patient.activeUserInsurance?.frontEndCarrierId
    ? convertCarrierIdToPatientSearchableCarrierId(
        patient.activeUserInsurance.frontEndCarrierId,
        anthemPatientFacingCarrierConsolidationEnabled
      )
    : '',
  memberId: patient.activeUserInsurance?.memberId ?? '',
  groupNumber: patient.activeUserInsurance?.groupNumber ?? '',
});

const performEligibilityLookup = async (
  patient: UserRead,
  userId: number,
  userInsuranceId: number
) => {
  try {
    return await EligibilityLookupApi.refreshUserEligibilityLookup(
      userId,
      userInsuranceId,
      { lookup_source: LookupSource.PROVIDER_INSURANCE_UPLOAD }
    );
  } catch (err) {
    notifyWarning(
      `There was a problem verifying ${formatPatientName(patient, {
        firstNameOnly: true,
      })}'s insurance coverage`
    );
    logException(err);
  }
};

export const patientInsuranceFormSubmit = async (
  values: InsuranceFormValues,
  patient: UserRead,
  frontEndCarriersById: { [index: string]: FrontEndCarrierRead },
  onUpdateSuccess: PatientInsuranceFormProps['onUpdateSuccess']
) => {
  const isAuthorizationRequired =
    !!values.carrierId &&
    CARRIERS_REQUIRING_AUTHORIZATION.includes(Number(values.carrierId));
  if (isAuthorizationRequired) {
    return;
  }
  const { firstName, lastName, memberId, groupNumber, carrierId, dob } = values;

  try {
    const newInsurance = await UserInsuranceApi.createUserInsurance({
      userId: patient?.id,
      firstName,
      lastName,
      memberId,
      groupNumber,
      frontEndCarrierId: carrierId! as number,
      frontEndCarrierName: frontEndCarriersById[carrierId! as number].name!,
      dob: moment(dob).format('YYYY-MM-DD'),
    });

    const lookup = await performEligibilityLookup(
      patient,
      patient.id,
      newInsurance.id
    );

    // This is deliberately being called after the eligibility lookup so that the state is set correctly
    const updatedUser = await UserApi.updateUser(patient.id, {
      activeUserInsuranceId: newInsurance.id,
    });

    onUpdateSuccess({
      userRead: updatedUser,
      userInsurance: updatedUser.activeUserInsurance,
      eligibilityLookup: lookup,
    });
  } catch (err) {
    notifyError(
      `There was a problem saving ${formatPatientName(patient, {
        firstNameOnly: true,
      })}'s insurance`
    );
    logException(err);
  }
};

export type PatientInsuranceFormFieldsProps = Pick<
  PatientInsuranceFormProps,
  'onCancel'
> & {
  cancelBtnText?: string;
  showAuthorizationInstructionsModal: (
    carrier: FrontEndCarrierRead | FrontEndCarrierNested
  ) => void;
};

export const PatientInsuranceFormFields = <T extends InsuranceFormValues>({
  patient,
  providerFrontEndCarrierContext,
  searchableFrontEndCarriers,
  values,
  showAuthorizationInstructionsModal,
  isSubmitting,
  onCancel,
  cancelBtnText = 'Cancel',
}: FormikProps<T> & {
  patient: UserRead;
  providerFrontEndCarrierContext: ProviderFrontEndCarrierContextState;
  searchableFrontEndCarriers: FrontEndCarrierRead[];
} & PatientInsuranceFormFieldsProps) => {
  const provider = useProvider();
  const anthemPatientFacingCarrierConsolidationEnabled = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION,
    false
  );
  const isAuthorizationRequired =
    values.carrierId &&
    CARRIERS_REQUIRING_AUTHORIZATION.includes(Number(values.carrierId));
  const pfec: ProviderFrontEndCarrierRead | undefined =
    providerFrontEndCarrierContext.providerFrontEndCarriersById[
      Number(values.carrierId)
    ];
  if (!searchableFrontEndCarriers?.length) {
    return <></>;
  }

  return (
    <>
      <>
        <FormRow>
          <FieldControl name="firstName" fullWidth={true}>
            <FieldInputLabel>Legal first name</FieldInputLabel>
            <FieldInput />
            <FieldErrorText />
          </FieldControl>
          <FieldControl name="lastName" fullWidth={true}>
            <FieldInputLabel>Legal last name</FieldInputLabel>
            <FieldInput />
            <FieldErrorText />
          </FieldControl>
        </FormRow>
        <FormHelperText>
          Enter the name as it’s listed on your client’s insurance card.
        </FormHelperText>
      </>
      <FieldControl name="dob" fullWidth={true}>
        <FieldDatePicker
          label="Patient date of birth"
          inputFormat="MM/DD/YYYY"
          maxDate={moment()}
          placeholder="MM/DD/YYYY"
        />
        <FieldErrorText />
      </FieldControl>
      <FieldControl name="carrierId" fullWidth={true}>
        <FieldSelect label="Insurance carrier">
          {searchableFrontEndCarriers.length &&
            convertProviderCarriersToSearchableCarriers(
              providerFrontEndCarrierContext.providerFrontEndCarriers,
              searchableFrontEndCarriers,
              anthemPatientFacingCarrierConsolidationEnabled
            )
              .concat(
                providerFrontEndCarrierContext.providerFrontEndCarriers.some(
                  (pfec) => isFrontEndCarrierIdBcbs(pfec.frontEndCarrierId)
                )
                  ? searchableFrontEndCarriers.filter((fec) =>
                      isFrontEndCarrierIdBcbs(fec.id)
                    )
                  : []
              )
              .reduce(
                (carriers: FrontEndCarrierNested[], pfec) =>
                  carriers.concat(
                    pfec,
                    searchableFrontEndCarriers.filter(
                      (fec) => fec.parentFrontEndCarrierId === pfec.id
                    )
                  ),
                []
              )
              .sort((fec1, fec2) => fec1.name.localeCompare(fec2.name))
              .filter(
                (carrier, idx, array) =>
                  array.findIndex((c) => c.id === carrier.id) === idx
              ) // take out duplicate values
              .map((carrier: any, idx: number) => {
                return (
                  <MenuItem
                    data-testid={`patientInsuranceCarrierOption-${carrier.name}`}
                    key={idx}
                    value={carrier.id}
                  >
                    {carrier.name}
                  </MenuItem>
                );
              })}
        </FieldSelect>
        <FieldErrorText />
      </FieldControl>
      {isAuthorizationRequired && values.carrierId ? (
        <InsuranceNeedsAuthzGuidanceCard
          firstName={values.firstName}
          carrier={pfec.frontEndCarrier}
          showInsuranceAuthorizationInstructionsModal={
            showAuthorizationInstructionsModal
          }
        />
      ) : (
        <>
          <FieldControl name="memberId">
            <FieldInputLabel>Member ID</FieldInputLabel>
            <FieldMemberIdAdvancedValidation
              patient={patient}
              provider={provider}
              frontEndCarrierId={Number(values.carrierId)}
            />
            <FieldErrorText />
          </FieldControl>
          <FieldControl name="groupNumber">
            <FieldInputLabel>Group number</FieldInputLabel>
            <FieldInput />
            <FieldErrorText />
          </FieldControl>
        </>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xl,
          gap: theme.space.xs,
        }}
      >
        <Button
          variant="secondary"
          disabled={isSubmitting}
          onPress={() => {
            onCancel();
          }}
        >
          {cancelBtnText}
        </Button>
        <Button variant="primary" disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Saving and verifying...' : 'Save and verify'}
        </Button>
      </div>
    </>
  );
};

export const PatientInsuranceForm = ({
  patient,
  onUpdateSuccess,
  onCancel,
  currentEligibility,
  showAuthorizationInstructionsModal,
}: PatientInsuranceFormProps) => {
  const {
    frontEndCarriers: searchableFrontEndCarriers,
    carriersById: frontEndCarriersById,
  } = useSearchableFrontEndCarriersQuery(true);
  const providerFrontEndCarrierContext = React.useContext(
    ProviderFrontEndCarrierContext
  );
  const anthemPatientFacingCarrierConsolidationEnabled = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION,
    false
  );

  return (
    <>
      {currentEligibility?.manualOverride && (
        <>
          <Alert severity="warning">
            {formatPatientName(patient, {
              firstNameOnly: true,
            })}
            ’s insurance details were manually updated by our team to reflect
            the correct benefits. Editing this will reset{' '}
            {formatPatientName(patient, {
              firstNameOnly: true,
            })}
            ’s benefits and may impact their session cost.
          </Alert>
          <br />
        </>
      )}
      <Formik<InsuranceFormValues>
        initialValues={insuranceFormInitialValues(
          patient,
          anthemPatientFacingCarrierConsolidationEnabled
        )}
        enableReinitialize={true}
        validationSchema={insuranceFormValidationSchema}
        onSubmit={async (values) =>
          await patientInsuranceFormSubmit(
            values,
            patient,
            frontEndCarriersById,
            onUpdateSuccess
          )
        }
      >
        {({ isSubmitting, values, ...props }) => {
          return (
            <SafeFormikForm>
              <PatientInsuranceFormFields
                patient={patient}
                searchableFrontEndCarriers={searchableFrontEndCarriers}
                providerFrontEndCarrierContext={providerFrontEndCarrierContext}
                showAuthorizationInstructionsModal={
                  showAuthorizationInstructionsModal
                }
                onCancel={onCancel}
                values={values}
                isSubmitting={isSubmitting}
                {...props}
              />
            </SafeFormikForm>
          );
        }}
      </Formik>
    </>
  );
};
