import { MagnifyingGlass } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconMagnifyingGlass: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={MagnifyingGlass}
    variants={['filled', 'outlined']}
    defaultVariant="outlined"
    ref={ref}
  />
));

IconMagnifyingGlass.variants = ['filled', 'outlined'];
IconMagnifyingGlass.defaultVariant = 'outlined';
