/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum UnitedStates {
    ALASKA = 'ALASKA',
    ALABAMA = 'ALABAMA',
    ARKANSAS = 'ARKANSAS',
    ARIZONA = 'ARIZONA',
    CALIFORNIA = 'CALIFORNIA',
    COLORADO = 'COLORADO',
    CONNECTICUT = 'CONNECTICUT',
    DISTRICT_OF_COLUMBIA = 'DISTRICT_OF_COLUMBIA',
    DELAWARE = 'DELAWARE',
    FLORIDA = 'FLORIDA',
    GEORGIA = 'GEORGIA',
    HAWAII = 'HAWAII',
    IOWA = 'IOWA',
    IDAHO = 'IDAHO',
    ILLINOIS = 'ILLINOIS',
    INDIANA = 'INDIANA',
    KANSAS = 'KANSAS',
    KENTUCKY = 'KENTUCKY',
    LOUISIANA = 'LOUISIANA',
    MASSACHUSETTS = 'MASSACHUSETTS',
    MARYLAND = 'MARYLAND',
    MAINE = 'MAINE',
    MICHIGAN = 'MICHIGAN',
    MINNESOTA = 'MINNESOTA',
    MISSOURI = 'MISSOURI',
    MISSISSIPPI = 'MISSISSIPPI',
    MONTANA = 'MONTANA',
    NORTH_CAROLINA = 'NORTH_CAROLINA',
    NORTH_DAKOTA = 'NORTH_DAKOTA',
    NEBRASKA = 'NEBRASKA',
    NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
    NEW_JERSEY = 'NEW_JERSEY',
    NEW_MEXICO = 'NEW_MEXICO',
    NEVADA = 'NEVADA',
    NEW_YORK = 'NEW_YORK',
    OHIO = 'OHIO',
    OKLAHOMA = 'OKLAHOMA',
    OREGON = 'OREGON',
    PENNSYLVANIA = 'PENNSYLVANIA',
    PUERTO_RICO = 'PUERTO_RICO',
    RHODE_ISLAND = 'RHODE_ISLAND',
    SOUTH_CAROLINA = 'SOUTH_CAROLINA',
    SOUTH_DAKOTA = 'SOUTH_DAKOTA',
    TENNESSEE = 'TENNESSEE',
    TEXAS = 'TEXAS',
    UTAH = 'UTAH',
    VIRGIN_ISLANDS = 'VIRGIN_ISLANDS',
    VIRGINIA = 'VIRGINIA',
    VERMONT = 'VERMONT',
    WASHINGTON = 'WASHINGTON',
    WISCONSIN = 'WISCONSIN',
    WEST_VIRGINIA = 'WEST_VIRGINIA',
    WYOMING = 'WYOMING'
}
