import { useCallback, useState } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { usePatientAssessments } from '@headway/ui/hooks/usePatientAssessments';

export const useAllPatientAssessments = (
  providerPatient: ProviderPatientRead | undefined,
  defaultEnabled = false
) => {
  const [enabled, setEnabled] = useState(defaultEnabled);

  const {
    data: { data: assessments } = {},
    refetch,
    ...rest
  } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      completed_on_date_range_start: new Date(0).toISOString(),
    },
    {
      enabled: enabled && providerPatient?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const trigger = useCallback(() => {
    if (enabled) {
      refetch();
    } else {
      setEnabled(true);
    }
  }, [enabled, refetch]);

  return [{ assessments, ...rest }, trigger] as const;
};
