import {
  ManageTeamContent,
  useManageTeamDataFromReactQuery,
} from '~/legacy/views/Practice/ManageTeam';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

import { useSetSelectedProvider } from 'utils/practice';

export const ManageTeamModal = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const setSelectedProvider = useSetSelectedProvider();
  const handleProviderSelect = (provider: ProviderRead) => {
    setSelectedProvider(provider);
    onDismiss();
  };
  const { providers, toggleArchivedMutation } =
    useManageTeamDataFromReactQuery();

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title="Manage team">
      <ModalContent>
        <ManageTeamContent
          providers={providers || []}
          onProviderSelect={handleProviderSelect}
          onToggleArchived={(provider) =>
            toggleArchivedMutation.mutate({ provider })
          }
        />
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
