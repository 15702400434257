import { IconComplianceShield } from '@headway/helix/icons/ComplianceShield';
import { IconInfo } from '@headway/helix/icons/Info';
import { IconInsight } from '@headway/helix/icons/Insight';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { theme } from '@headway/ui/theme';

const iconByVariant = {
  info: IconInfo,
  warning: IconWarningCircle,
  error: IconWarningCircle,
  neutral: IconInfo,
  positive: IconInfo,
  compliance: IconComplianceShield,
  insight: IconInsight,
} as const;

export const ExpirationDataField = ({
  fieldName,
  fieldContent,
  errorMsg,
}: {
  fieldName: string;
  fieldContent: string;
  errorMsg: string;
}) => {
  const Icon = iconByVariant['warning'];
  return (
    <div>
      <div
        css={{
          fontSize: theme.fontSize.sm,
        }}
      >
        <b>{fieldName}</b>
      </div>
      <div
        css={{
          fontSize: theme.fontSize.base,
        }}
      >
        <div
          css={{
            alignItems: 'flex-start',
            display: 'flex',
          }}
        >
          <span className="inline-flex">
            <div
              css={{
                color: 'var(--hlx-color-foreground-warning-secondary)',
                aspectRatio: '1',
                alignSelf: 'flex-start',
                minWidth: '16px',
                display: 'block',
              }}
            >
              {<Icon aria-hidden={true} />}
            </div>
            &nbsp;
            <span>{' ' + fieldContent}</span>
          </span>
        </div>
      </div>
      <div
        css={{
          fontSize: theme.fontSize.sm,
          color: 'var(--hlx-color-foreground-warning-secondary)',
        }}
      >
        <b>{errorMsg}</b>
      </div>
    </div>
  );
};

export const DataField = ({
  fieldName,
  fieldContent,
}: {
  fieldName: string;
  fieldContent: string;
}) => {
  return (
    <div>
      <div
        css={{
          fontSize: theme.fontSize.sm,
        }}
      >
        <b>{fieldName}</b>
      </div>
      <div
        css={{
          fontSize: theme.fontSize.base,
        }}
      >
        {fieldContent}
      </div>
    </div>
  );
};

export const ErrorDataField = ({
  fieldName,
  fieldContent,
  errorMsg,
}: {
  fieldName: string;
  fieldContent: string;
  errorMsg: string;
}) => {
  const Icon = iconByVariant['error'];
  return (
    <div>
      {errorMsg ? (
        <div>
          <div
            css={{
              fontSize: theme.fontSize.sm,
            }}
          >
            <b>{fieldName}</b>
          </div>
          <div
            css={{
              fontSize: theme.fontSize.base,
            }}
          >
            <div
              css={{
                alignItems: 'flex-start',
                display: 'flex',
              }}
            >
              <span className="inline-flex">
                {
                  <div
                    css={{
                      color: 'var(--hlx-color-foreground-danger-secondary)',
                      aspectRatio: '1',
                      alignSelf: 'flex-start',
                      minWidth: '16px',
                      display: 'block',
                    }}
                  >
                    <Icon aria-hidden={true} />
                  </div>
                }
                &nbsp;
                <span>
                  {fieldContent !== '' ? (
                    ' ' + fieldContent
                  ) : (
                    <div
                      css={{
                        fontSize: theme.fontSize.base,
                        color: theme.color.error,
                      }}
                    >
                      Missing
                    </div>
                  )}
                </span>
              </span>
            </div>
          </div>
          <div
            css={{
              fontSize: theme.fontSize.sm,
              color: theme.color.error,
            }}
          >
            <b>{errorMsg}</b>
          </div>
        </div>
      ) : (
        <div>
          <div
            css={{
              fontSize: theme.fontSize.sm,
            }}
          >
            <b>{fieldName}</b>
          </div>
          <div
            css={{
              fontSize: theme.fontSize.base,
            }}
          >
            {fieldContent}
          </div>
        </div>
      )}
    </div>
  );
};
