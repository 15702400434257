/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { BillingType } from '../models/BillingType';
import { EstimatedPrice } from '../models/EstimatedPrice';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderReferralProgramResponse } from '../models/ProviderReferralProgramResponse';
import { Role } from '../models/Role';
import { SearchUsersResponse } from '../models/SearchUsersResponse';
import { SendUserVerificationRequest } from '../models/SendUserVerificationRequest';
import { UserAppointmentReadiness } from '../models/UserAppointmentReadiness';
import { UserClaimReadinessResponse } from '../models/UserClaimReadinessResponse';
import { UserCreate } from '../models/UserCreate';
import { UserRead } from '../models/UserRead';
import { UserResetPassword } from '../models/UserResetPassword';
import { UserRoleData } from '../models/UserRoleData';
import { UserUpdate } from '../models/UserUpdate';
import { UserUpdateEmail } from '../models/UserUpdateEmail';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class UserApiResource {

    /**
     * Create Referral Link
     * 
     * @param userId 
     
     */
    public createReferralLink(userId: number, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/{user_id}/referral-link';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create User
     * 
     * @param userCreate 
     
     */
    public createUser(body: UserCreate, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Disable User Mfa
     * 
     * @param userId 
     
     */
    public disableUserMfa(userId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/{user_id}/disable-mfa';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Enable User Mfa
     * 
     * @param userId 
     
     */
    public enableUserMfa(userId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/{user_id}/enable-mfa';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Users
     * 
     * @param email 
     * @param ids 
     * @param admin 
     
     */
    public findUsers(query?: { email?: string, ids?: Array<number>, admin?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/user';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Force Logout
     * 
     * @param userId 
     
     */
    public forceLogout(userId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/{user_id}/force-logout';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Claim Readiness
     * 
     * @param userId 
     * @param providerAddressId 
     
     */
    public getClaimReadiness(userId: number, query?: { provider_address_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<UserClaimReadinessResponse> {
        const apiRoute = '/user/{user_id}/claim-readiness';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get General Price Estimate
     * 
     * @param userId 
     * @param providerId 
     
     */
    public getGeneralPriceEstimate(userId: number, query?: { provider_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<EstimatedPrice> {
        const apiRoute = '/user/{user_id}/general-price-estimate';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Legacy Roles
     * 
     * @param userId 
     
     */
    public getLegacyRoles(userId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<Role>> {
        const apiRoute = '/user/{user_id}/legacy-roles';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Original User Me
     * 
     
     */
    public getOriginalUserMe(axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/original-me';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Referral Portal
     * 
     * @param userId 
     
     */
    public getReferralPortal(userId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderReferralProgramResponse> {
        const apiRoute = '/user/{user_id}/referral-portal';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Roles
     * 
     * @param userId 
     
     */
    public getRoles(userId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<Role>> {
        const apiRoute = '/user/{user_id}/roles';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User
     * 
     * @param userId 
     
     */
    public getUser(userId: number, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User Appointment Readiness
     * 
     * @param userId 
     * @param providerId 
     
     */
    public getUserAppointmentReadiness(userId: number, query?: { provider_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<UserAppointmentReadiness> {
        const apiRoute = '/user/{user_id}/appointment-readiness';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User By Email
     * 
     * @param emailAddress 
     
     */
    public getUserByEmail(emailAddress: string, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/email/{email_address}';
        const reqPath = apiRoute
                    .replace('{' + 'email_address}', String(emailAddress));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User Me
     * 
     * @param ref 
     * @param gclid 
     * @param fbclid 
     * @param campaign 
     
     */
    public getUserMe(query?: { ref?: string, gclid?: string, fbclid?: string, campaign?: string }, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/me';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Reset Password
     * 
     * @param userId 
     * @param userResetPassword 
     
     */
    public resetPassword(userId: number, body: UserResetPassword, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/{user_id}/update-password';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Search Users
     * 
     * @param email 
     * @param id 
     * @param dob 
     * @param firstName 
     * @param lastName 
     * @param initials 
     * @param phoneNumber 
     * @param insuranceMemberId 
     * @param providerFirstName 
     * @param providerLastName 
     
     */
    public searchUsers(query?: { email?: string, id?: number, dob?: string, first_name?: string, last_name?: string, initials?: string, phone_number?: string, insurance_member_id?: string, provider_first_name?: string, provider_last_name?: string }, axiosConfig?: AxiosRequestConfig): Promise<SearchUsersResponse> {
        const apiRoute = '/user-search';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Complete Admin Created Account Email
     * 
     * @param userId 
     
     */
    public sendCompleteAdminCreatedAccountEmail(userId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/user/{user_id}/send-account-invitation';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Verification
     * 
     * @param userId 
     * @param sendUserVerificationRequest 
     
     */
    public sendVerification(userId: number, body?: SendUserVerificationRequest, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/{user_id}/send-verification';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Welcome Sms
     * 
     * @param userId 
     
     */
    public sendWelcomeSms(userId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/user/{user_id}/send-welcome-sms';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Set Invite Channel To Bofa
     * used specifically for our bank of america / anthem EAP offering updates user invite channel to BofA if the user landed on that landing page
     
     */
    public setInviteChannelToBofa(axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/set-bofa-invite-channel';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Should Show Anthem Eap Experience
     * 
     * @param userId 
     * @param providerId 
     * @param appointmentBillingType 
     
     */
    public shouldShowAnthemEapExperience(userId: number, query?: { provider_id?: number, appointment_billing_type?: BillingType }, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
        const apiRoute = '/user/{user_id}/should_show_anthem_eap_experience';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Email In Sigmund
     * This endpoint allows users in Sigmund to change their OWN emails. Impersonation is not allowed, and they\&#39;re required to have a password check first.
     * @param userId 
     * @param userUpdateEmail 
     
     */
    public updateEmailInSigmund(userId: number, body: UserUpdateEmail, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/{user_id}/sigmund-update-email';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Legacy Roles
     * 
     * @param userId 
     * @param userRoleData 
     
     */
    public updateLegacyRoles(userId: number, body: Array<UserRoleData>, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user/{user_id}/legacy-roles';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update User
     * 
     * @param userId 
     * @param userUpdate 
     
     */
    public updateUser(userId: number, body: UserUpdate, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/user/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const UserApi = new UserApiResource();
