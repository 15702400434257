import moment from 'moment';

import { UserRead } from '@headway/api/models/UserRead';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { formatPatientName } from '@headway/shared/utils/patient';

export interface SessionDetailsHeaderProps {
  patient: UserRead;
}

export const SessionDetailsHeader = ({
  patient,
}: SessionDetailsHeaderProps) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing.x5,
      }}
    >
      <SectionHeader>{formatPatientName(patient)}</SectionHeader>
      <div css={{ marginTop: '5px' }}>
        <SubBodyText>
          {patient?.dob
            ? `${moment(patient.dob).format('MM/DD/YY')} (${moment().diff(
                moment(patient.dob),
                'years'
              )})`
            : ''}
        </SubBodyText>
      </div>
    </div>
  );
};
