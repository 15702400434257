import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';

import { UseFindProviderEventsQueryKeyArgs } from './useFindProviderEvents';

export type UseFindPastSessionsQueryKeyArgs = {
  provider_id: number;
  patient_user_id: number;
  limit?: number;
};

export const getUseFindPastSessionsQueryKey = (
  queryKeyArgs: UseFindPastSessionsQueryKeyArgs
): UseFindProviderEventsQueryKeyArgs => ({
  expand_estimated_prices: false,
  event_types: [ProviderEventType.APPOINTMENT],
  appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
  order_by: 'start_date',
  order: 'desc',
  source: 'past_sessions',
  ...queryKeyArgs,
});

export const useFindPastSessionsQueryKeyArgs = ({
  providerId,
  clientId,
  limit,
}: {
  providerId: number;
  clientId: number;
  limit?: number;
}) => {
  return getUseFindPastSessionsQueryKey({
    provider_id: providerId,
    patient_user_id: clientId,
    limit,
  });
};
