import { UserPlus } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';

import { IconBase } from './base/IconBase';
import type { Icon } from './base/types';

export const IconUserPlus: Icon = forwardRef((props, ref) => (
  <IconBase
    {...props}
    phosphorIcon={UserPlus}
    variants={['filled', 'outlined']}
    defaultVariant="outlined"
    ref={ref}
  />
));

IconUserPlus.variants = ['filled', 'outlined'];
IconUserPlus.defaultVariant = 'outlined';
