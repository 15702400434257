import { css } from '@emotion/react';
import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ListHeader } from '@headway/helix/ListHeader';
import { ProgressBar } from '@headway/helix/Progress';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { PaginatedConcreteProviderEventRead } from 'utils/types';
import { ComponentMap } from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/components';
import {
  Component,
  ComponentTypes,
  Section,
} from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/versions/2/types';
import { NoteJson } from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/versions/types';
import {
  getTemplates,
  isElementASection,
} from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/utils';

import { PreviewCard } from '../Components/PreviewCard';
import { CancelTreatmentPlanModal } from '../Modals/CancelTreatmentPlanModal';
import { SaveAndExitTreatmentPlanModal } from '../Modals/SaveAndExitTreatmentPlanModal';
import { useTreatmentPlanRequirements } from '../Requirements/TreatmentPlanRequirements';
import {
  createJsonFromComponent,
  createJsonFromTemplate,
  useTemplate,
} from '../Template';
import { stepHasErrors } from '../Template/errors';
import { PlanJson, Step, TreatmentPlanMetadata } from '../Template/types';
import {
  trackBackButtonClickedEvent,
  trackNextButtonClickedEvent,
  trackSaveAndExitButtonClickedEvent,
} from '../TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from '../TreatmentPlanContext';
import {
  getActiveTreatmentPlan,
  getLatestAttestedTreatmentPlan,
  getPrefillTreatmentPlanFromIntake,
  Path,
  pathToString,
  stepHasAllValues,
  stepHasDates,
} from '../TreatmentPlanUtils';
import { Page } from './Page';

const InputOrGroup = ({
  path,
  component,
}: {
  path: Path;
  component: Section<TreatmentPlanMetadata> | Component<TreatmentPlanMetadata>;
}) => {
  const { steps, currentStep } = useContext(TreatmentPlanContext);
  const { validateForm } = useFormikContext();

  if (isElementASection(component)) {
    return (
      <FieldArray
        name={pathToString(path)}
        render={({ form, push, remove }) => {
          let multipleList = [];

          if (component.allowMultiple) {
            multipleList = getIn(form.values, pathToString(path)) ?? [];
          }

          return component.allowMultiple ? (
            <div
              css={[
                componentListCss,
                component.metadata?.indented ? indentedCss : null,
              ]}
            >
              {multipleList.map((item: any, index: number) => (
                <div key={index} css={groupCss}>
                  <div
                    css={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <BodyText>
                      <strong>
                        {component.header}
                        {multipleList.length > 1 ? ` ${index + 1}` : ''}
                      </strong>
                    </BodyText>
                    {multipleList.length > 1 && (
                      <Button
                        variant="secondary"
                        onPress={async () => {
                          remove(index);
                          await validateForm();
                        }}
                      >
                        Remove {component.header.toLowerCase()}
                      </Button>
                    )}
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing.x5,
                    }}
                  >
                    {component.components.map((child) => (
                      <InputOrGroup
                        key={child.id}
                        component={child}
                        path={[...path, index, child.id!]}
                      />
                    ))}
                  </div>
                </div>
              ))}
              <div>
                <Button
                  variant="secondary"
                  onPress={async () => {
                    push(createJsonFromComponent(component)[component.id!][0]);
                    await validateForm();
                  }}
                >
                  Add {component.header.toLowerCase()}
                </Button>
              </div>
            </div>
          ) : null;
        }}
      />
    );
  } else {
    return (
      <div css={component.metadata?.indented ? indentedCss : undefined}>
        {React.createElement(
          ComponentMap[component.type as `${ComponentTypes}`],
          {
            element: {
              ...component,
              id: pathToString(path),
            },
            template: steps?.[currentStep]?.components,
          }
        )}
      </div>
    );
  }
};

const indentedCss = css({
  borderLeft: `1px solid ${theme.color.system.borderGray}`,
  paddingLeft: theme.spacing.x5,
});

const groupCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.x1,
});

const componentListCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.x5,
});

const TemplateForm = ({ step }: { step: Step }) => {
  const { prefillTreatmentPlan } = useContext(TreatmentPlanContext);
  const stepHasReviewDate = stepHasDates(step).includes('targetDate');

  return (
    <SafeFormikForm>
      <div>
        <SectionHeader>{step.pageHeader}</SectionHeader>
      </div>
      {step.description && (
        <div>
          <BodyText>{step.description}</BodyText>
        </div>
      )}
      {prefillTreatmentPlan && stepHasReviewDate && (
        <div css={{ marginTop: theme.spacing.x8 }}>
          <GuidanceCard variant="info">
            For quality documentation, please update the review date.
          </GuidanceCard>
        </div>
      )}
      <div css={formCss}>
        {step.components.map((component) => (
          <InputOrGroup
            key={component.id}
            component={component}
            path={[component.id!]}
          />
        ))}
      </div>
    </SafeFormikForm>
  );
};

const formCss = css({
  marginTop: theme.spacing.x8,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.x5,
});

export interface TemplatePageProps {
  closeModal: () => void;
}

export const TemplatePage = ({ closeModal }: TemplatePageProps) => {
  const {
    steps,
    currentStep,
    previousStep,
    nextStep,
    treatmentPlan,
    isNewTreatmentPlan,
    setPage,
    setTreatmentPlan,
    providerPatient,
    hasUnsavedChanges,
    initializeTemplateForm,
    prefillTreatmentPlan,
    setPrefillTreatmentPlan,
    setIsNewTreatmentPlan,
    setInitialValues,
    page,
    treatmentPlans,
  } = useContext(TreatmentPlanContext);
  const { values, submitForm } = useFormikContext<any>();
  const { saveTreatmentPlan, deleteTreatmentPlan, createTreatmentPlan } =
    useProviderTreatmentPlan(providerPatient!.userId);

  const [goBackModalOpen, setGoBackModalOpen] = useState<boolean>(false);
  const [saveAndExitModalOpen, setSaveAndExitModalOpen] =
    useState<boolean>(false);
  const [
    hasConfirmedIntakeNoteApptWithIntakeTherapyTemplate,
    setHasConfirmedIntakeNoteApptWithIntakeTherapyTemplate,
  ] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.mediaQuery.mobile);

  const numberOfCompleteSteps =
    steps?.filter(
      (step) => !stepHasErrors(values, step) && stepHasAllValues(values, step)
    ).length ?? 0;

  const { isTreatmentPlanRequired } = useTreatmentPlanRequirements(
    providerPatient?.userId
  );

  const template = useTemplate(providerPatient?.userId);

  const hasPriorActiveTreatmentPlan = getActiveTreatmentPlan(
    treatmentPlans,
    isTreatmentPlanRequired
  )
    ? true
    : false;

  const attestedPreviousTreatmentPlans = treatmentPlans.filter(
    (item) => item.attestedOn
  );

  useEffect(() => {
    const initialize = async () => {
      if (isTreatmentPlanRequired) {
        if (!treatmentPlan) {
          if (
            !isNewTreatmentPlan &&
            currentStep === 0 &&
            page === TREATMENT_PLAN_PAGES.TEMPLATE
          ) {
            const planJson =
              prefillTreatmentPlan &&
              (prefillTreatmentPlan?.planJson as PlanJson)?.template
                ? (prefillTreatmentPlan.planJson as PlanJson)
                : {
                    template: createJsonFromTemplate(template.template),
                  };
            initializeTemplateForm(planJson.template);
            setTreatmentPlan(
              await createTreatmentPlan({
                values: planJson.template,
                planType: TreatmentPlanType.TEMPLATE,
                templateVersion: template.version,
                shouldAttestPlan: false,
              })
            );
            setIsNewTreatmentPlan(true);

            const confirmedEvents = (await ProviderEventApi.getEvents({
              provider_id: providerPatient?.providerId,
              patient_user_id: providerPatient?.userId,
              event_types: [ProviderEventType.APPOINTMENT],
              appointment_statuses: [
                ProviderAppointmentStatus.DETAILS_CONFIRMED,
              ],
              expand_estimated_prices: false,
              source: 'treatment_plan_confirmed_events',
            })) as PaginatedConcreteProviderEventRead;

            const confirmedIntakeNote = confirmedEvents.data.find(
              (event) => event.providerAppointment?.cptCodes?.includes('90791')
            );

            const intakeTherapyNotesForIntakeNoteAppt = confirmedIntakeNote
              ? await ProviderProgressNotesApi.findProviderProgressNotes({
                  template_id: 29,
                  provider_id: providerPatient?.providerId,
                  patient_id: providerPatient?.userId,
                  appointment_id: confirmedIntakeNote?.providerAppointment?.id,
                })
              : undefined;

            const attestedOnIntakeTherapyNote =
              intakeTherapyNotesForIntakeNoteAppt?.find(
                (item) => item.attestedOn
              );

            setHasConfirmedIntakeNoteApptWithIntakeTherapyTemplate(
              !!attestedOnIntakeTherapyNote
            );

            const requestedTemplateId = (
              attestedOnIntakeTherapyNote?.noteJson as NoteJson
            )?.templateInfo.id;
            const requestedTemplateVersion = (
              attestedOnIntakeTherapyNote?.noteJson as NoteJson
            )?.templateInfo.version;
            const progressNoteTemplateSchema = (await getTemplates()).find(
              (t) => {
                return (
                  t.templateInfo.id === requestedTemplateId &&
                  t.templateInfo.version === requestedTemplateVersion
                );
              }
            );

            if (
              attestedOnIntakeTherapyNote &&
              progressNoteTemplateSchema &&
              !hasPriorActiveTreatmentPlan
            ) {
              const prefillPlan = getPrefillTreatmentPlanFromIntake(
                providerPatient?.id!,
                confirmedIntakeNote?.providerAppointment?.diagnosisCodes,
                attestedOnIntakeTherapyNote,
                progressNoteTemplateSchema
              );

              if (prefillPlan) {
                setInitialValues((prefillPlan?.planJson as PlanJson)?.template);
              }
            }
          }
        } else {
          initializeTemplateForm(
            (treatmentPlan.planJson as PlanJson)?.template
          );
        }
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTreatmentPlanRequired]);

  const handlePreviousTreatmentPlanChange = (
    previousPlan?: ProviderTreatmentPlanRead
  ) => {
    if (previousPlan) {
      setPrefillTreatmentPlan(previousPlan);
      setInitialValues((previousPlan?.planJson as PlanJson)?.template);
    } else {
      if (hasUnsavedChanges()) {
        const planJson = {
          template: createJsonFromTemplate(template.template),
        };
        setPrefillTreatmentPlan(undefined);
        setInitialValues(planJson.template);
      }
    }
  };

  const save = async () =>
    setTreatmentPlan(
      await saveTreatmentPlan({
        values,
        planType: TreatmentPlanType.TEMPLATE,
        treatmentPlanId: treatmentPlan?.id,
        shouldAttestPlan: false,
      })
    );

  return (
    <>
      <div css={templatePageCss.templateContainer}>
        <Page
          buttons={[
            !(isTreatmentPlanRequired && currentStep === 0) ? (
              <Button
                key="back"
                variant="secondary"
                type="submit"
                onPress={async () => {
                  trackBackButtonClickedEvent({
                    providerPatient: providerPatient!,
                    pageHeader: steps![currentStep].pageHeader,
                  });
                  if (currentStep === 0) {
                    isNewTreatmentPlan
                      ? setGoBackModalOpen(true)
                      : setSaveAndExitModalOpen(true);
                  } else {
                    await submitForm();
                    await save();
                    previousStep();
                  }
                }}
              >
                Back
              </Button>
            ) : null,
            <Button
              key="next"
              variant="primary"
              type="submit"
              onPress={async () => {
                trackNextButtonClickedEvent({
                  providerPatient: providerPatient!,
                  pageHeader: steps![currentStep].pageHeader,
                });
                await submitForm();
                await save();
                nextStep();
              }}
            >
              Next
            </Button>,
            <div
              key="saveAndExit"
              css={{ marginLeft: 'auto', display: 'flex' }}
            >
              <Button
                variant="link"
                onPress={async () => {
                  trackSaveAndExitButtonClickedEvent(providerPatient!);
                  await save();
                  closeModal();
                }}
                disabled={!hasUnsavedChanges(values)}
              >
                Save and Exit
              </Button>
            </div>,
          ]}
          hint={
            steps &&
            `Up next: ${
              currentStep === steps.length - 1
                ? 'Summary'
                : steps[currentStep + 1].title
            }`
          }
        >
          {steps && steps.length > 0 && (
            <>
              <div
                css={{
                  marginTop: theme.spacing.x8,
                  marginBottom: theme.spacing.x8,
                }}
              >
                <ProgressBar
                  label={`Step ${currentStep + 1} of ${steps.length}`}
                  valueLabel={`${numberOfCompleteSteps}/${steps.length} complete`}
                  value={numberOfCompleteSteps}
                  minValue={0}
                  maxValue={steps.length}
                />
              </div>
              {isTreatmentPlanRequired ? (
                attestedPreviousTreatmentPlans?.length! > 0 &&
                currentStep === 0 ? (
                  <div css={{ marginBottom: theme.spacing.x8 }}>
                    <Select
                      name="previousTreatmentPlan"
                      label="Prefill from treatment plan"
                      placeholder="Select a previous treatment plan"
                      selectionMode="single"
                      menuWidth="stretch"
                      optionalityText="Optional"
                      items={attestedPreviousTreatmentPlans}
                      onSelectionChange={(keys) => {
                        const [value] = keys;
                        handlePreviousTreatmentPlanChange(
                          attestedPreviousTreatmentPlans?.find(
                            (plan) => plan.id === parseInt(value)
                          )
                        );
                      }}
                    >
                      {(treatmentPlan) => {
                        const date = new Date(treatmentPlan?.attestedOn!);
                        return (
                          <Item>
                            {`${date.toLocaleDateString([], {
                              year: '2-digit',
                              month: 'numeric',
                              day: 'numeric',
                            })} at ${date.toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: 'numeric',
                            })}`}
                          </Item>
                        );
                      }}
                    </Select>
                  </div>
                ) : hasConfirmedIntakeNoteApptWithIntakeTherapyTemplate &&
                  currentStep === 0 ? (
                  <div css={{ marginBottom: theme.spacing.x3 }}>
                    <GuidanceCard variant="info">
                      Good news: we’ve prefilled part of your plan based on your
                      intake note{' '}
                    </GuidanceCard>
                  </div>
                ) : null
              ) : null}
              {isTreatmentPlanRequired &&
                !getLatestAttestedTreatmentPlan(treatmentPlans) &&
                currentStep === 1 && (
                  <div css={{ marginBottom: theme.spacing.x3 }}>
                    <GuidanceCard variant="info">
                      Still getting to know your client? Keep in mind that
                      starting with just one treatment goal meets compliance
                      standards. You can still update with a new plan in the
                      future as needed.
                    </GuidanceCard>
                  </div>
                )}
              <TemplateForm step={steps[currentStep]} />
            </>
          )}
          <CancelTreatmentPlanModal
            open={goBackModalOpen}
            onClose={() => setGoBackModalOpen(false)}
            onCancel={async () => {
              if (isNewTreatmentPlan && treatmentPlan) {
                await deleteTreatmentPlan(treatmentPlan.id);
                setTreatmentPlan(undefined);
                setPage(TREATMENT_PLAN_PAGES.START);
              } else {
                closeModal();
              }
            }}
          />
          <SaveAndExitTreatmentPlanModal
            open={saveAndExitModalOpen}
            onClose={() => setSaveAndExitModalOpen(false)}
            onSaveAndExit={async () => {
              await save();
              closeModal();
            }}
          />
        </Page>
      </div>
      {!isMobile && (
        <div css={templatePageCss.previewContainer}>
          <div css={templatePageCss.previewHeader}>
            <ListHeader>Plan preview:</ListHeader>
          </div>
          <PreviewCard providerPatient={providerPatient!} />
        </div>
      )}
    </>
  );
};

const templatePageCss = {
  templateContainer: css({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    overflowY: 'auto',
  }),
  previewContainer: css({
    padding: theme.spacing.x9,
    backgroundColor: theme.color.system.backgroundGray,
    borderLeft: `1px solid ${theme.color.system.borderGray}`,
    h2: {
      marginTop: 0,
      marginBottom: theme.spacing.x5,
    },
    width: 500,
    flexShrink: 0,
    overflowY: 'auto',
  }),
  previewHeader: css({
    marginBottom: theme.spacing.x5,
  }),
};
