import { useMemo } from 'react';

import { AppointmentConfirmationModalFormV2Values } from '../AppointmentConfirmationModalV2';
import { useProgressNotesInitialValues } from './initialValues/useProgressNotesInitialValues';
import { useSessionDetailsInitialValues } from './initialValues/useSessionDetailsInitialValues';

export const useInitialValues = () => {
  const {
    isInitialized: areSessionDetailsInitialized,
    initialValues: sessionDetailsInitialValues,
  } = useSessionDetailsInitialValues();
  const {
    isInitialized: areProgressNotesInitialized,
    initialValues: progressNotesInitialValues,
  } = useProgressNotesInitialValues();

  const initialValues: AppointmentConfirmationModalFormV2Values = useMemo(
    () => ({
      sessionDetails: sessionDetailsInitialValues,
      progressNote: progressNotesInitialValues,
      addendums: {} as any,
      attachments: [],
      attestation: false,
    }),
    [sessionDetailsInitialValues, progressNotesInitialValues]
  );

  return {
    isInitialized: areSessionDetailsInitialized && areProgressNotesInitialized,
    initialValues: initialValues,
  };
};
