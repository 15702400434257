import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

import { ProviderFeeScheduleApi } from 'api/ProviderFeeScheduleApi';

export interface UseProviderFeeScheduleQueryKeyArgs {
  providerId: number | undefined;
  frontEndCarrierId: number | undefined;
  eligibilityLookup?: EligibilityLookupRead;
  insuranceStatus?: PatientInsuranceOrEAPStatus;
}
export const getUseProviderFeeScheduleQueryKey = ({
  providerId,
  frontEndCarrierId,
  eligibilityLookup,
}: UseProviderFeeScheduleQueryKeyArgs) => [
  'provider-fee-schedule',
  providerId,
  frontEndCarrierId,
  eligibilityLookup?.id,
];

const {
  useSingleQuery: useProviderFeeSchedule,
  useListQuery: useProviderFeeScheduleList,
} = createBasicApiHooks(
  getUseProviderFeeScheduleQueryKey,
  ({ providerId, frontEndCarrierId, eligibilityLookup, insuranceStatus }) => {
    const isInNoDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_OUTAGE;
    const isInOldDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_OUTAGE;
    return ProviderFeeScheduleApi.findProviderFeeSchedules({
      provider_id: providerId,
      front_end_carrier_ids: [frontEndCarrierId!],
      only_most_recent: true,
      plan_types:
        isInNoDataOutage || isInOldDataOutage
          ? []
          : [eligibilityLookup?.ratesPlanType!],
    });
  },
  ({ providerId, frontEndCarrierId, eligibilityLookup, insuranceStatus }) => {
    const isInNoDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_OUTAGE;
    const isInOldDataOutage =
      insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_OUTAGE;
    return (
      !!(eligibilityLookup?.id, providerId, frontEndCarrierId) ||
      isInNoDataOutage ||
      isInOldDataOutage
    );
  },
  () => 'Failed to fetch provider fee schedule'
);

export { useProviderFeeSchedule, useProviderFeeScheduleList };
