import React, { ReactNode } from 'react';

import { ContentText } from './ContentText';

export interface PageHeaderProps {
  children: ReactNode;
}

/**
 * @deprecated
 * Use ContentText variants instead
 */
export const PageHeader = ({ children }: PageHeaderProps) => {
  return <ContentText variant="page-title">{children}</ContentText>;
};
